import { MemberUser } from '../../models/member-user';
import { AuthActionTypes, All } from '../actions/member.auth.actions';

export interface State {
  isAuthenticated: boolean;
  user: MemberUser | null;
  errorMessage: string | null;
  b2bPhoneVerified: Boolean | null;
}
 
//set the initial state with localStorage
export const initialState: State = {
  isAuthenticated: null,
  user: null,
  errorMessage: null,
  b2bPhoneVerified: null,
};
export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case AuthActionTypes.MEMBER_LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token,
          email: action.payload.email,
          id:action.payload.id,
          phone: action.payload.phone,
          b2bPhoneVerified: action.payload.b2bPhoneVerified,
          isTempPassword:action.payload.isTempPassword,
          role:action.payload.role
        },
        errorMessage: null
      };
    }
    case AuthActionTypes.MEMBER_LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: 'Incorrect email and/or password.'
      };
    }
    case AuthActionTypes.MEMBER_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
