import { Component, OnInit,OnDestroy } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
} from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import {
  teamsQuery,
  teamsRemoveMemberMutation,
  getmembersByIdQuery,
  teamsAddMemberMutation,
  membersBasicQuery,
  updateMemberMutation,
  addMemberMutation,
  mergeUserMutation
} from '../../../services/admin.gql'
import { TranslateService } from '@ngx-translate/core'
import { HelperService } from '../../../services/helper.service'
import { makeManagerMutation } from '../../teams/team.gql'

@Component({
  selector: 'app-create-member',
  templateUrl: './create-member.component.html',
  styleUrls: ['./create-member.component.scss']
})
export class CreateMemberComponent implements OnInit,OnDestroy {
  private query: QueryRef<any>
  private queryTm: QueryRef<any>
  private query$: any
  private queryTm$: any
  
  
  public memberForm: FormGroup
  public userId: number
  public removedIds: any = []
  public userData: any = []
  public teams: any = []
  submitted1: boolean = false
  loading: boolean = false
  public isOpen = false
  enableAutoCountrySelect:boolean = true
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  constructor (
    private apollo: Apollo,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    private helperService:HelperService
  ) {
    // alert()
    this.isAdmin = this.helperService.isAdmin()

    this.memberForm = this.formBuilder.group({
      first_Name: ['', Validators.required],
      lastName: ['', Validators.required],
      // email: ['', [Validators.email]],
      email: ['', [Validators.required, Validators.email]],
      // phone: [undefined, [Validators.required]],
      phone: [undefined],
      ngxTeamValue: [[]],
      role:['MEMBER',Validators.required],
      teamManagers:   this.formBuilder.array([{}])
    })
  }
  teamManagers(): FormArray {
    return this.memberForm.get('teamManagers') as FormArray;
  };
  ngOnInit () {
    this.getTeams()
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.userId = params.id
        this.getUserDetails(params.id)
      }
      else{
        this.enableAutoCountrySelect = false
      }
    })
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
       this.permissions = this.helperService.getPermissions(data)
    })
  }
  getTeams () {
    // querying the teams list
    this.queryTm = this.apollo.watchQuery({
      query: teamsQuery,
      fetchPolicy:'network-only',
      variables: {}
    })

    this.queryTm$ = this.queryTm.valueChanges.subscribe(result => {
      this.teams = result.data.teams
      //console.log(this.teams)
    })
  }
  get f () {
    return this.memberForm.controls
  }

  addMoreTeams(){
    this.translate
      .get('MESSAGES.CHANGES_THAT_YOU_MADE_MAY_NOT_BE_SAVED')
      .subscribe((res: string) => {
        if (!confirm(res)) {
          return false
        }
        
        this.router.navigate(['/teams'], {
          state: {
            toggleAddTeam: true
          }
        })

      }) 
  }
  saveMember1 () {
    this.submitted1 = true
    const controls = this.memberForm.controls
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name != 'phone') {
          return
        }
      }
    }

    let firstName = this.f.first_Name.value
    let lastName = this.f.lastName.value
    let email = this.f.email.value ? this.f.email.value.toLowerCase() : ''
    let phone = this.f.phone.value
    let countryCode = ''
    let b2bPhone = ''
    if(phone && phone.internationalNumber)
    {
      countryCode =  phone.countryCode.toUpperCase()
      phone.number = phone.internationalNumber.split('-').join('')
      phone.number = phone.number.split(' ').join('')
      phone = phone.number
      b2bPhone = phone
    }
    else{
      phone = ''
    }
  
    //  return
    let memberObj: any = { firstName, lastName, email, countryCode,b2bPhone }
      memberObj.role = this.f.role.value
    if (this.userId) {
      if(phone)
      {
        memberObj.phone = ""
        memberObj.b2bPhone = phone
      }
      memberObj.userId = this.userId
      this.updateMember(memberObj)
      return
    }
    memberObj.phone = ""
    this.createMember(memberObj)
  }

  getUserDetails (userId) {
    // alert(4)
    this.query = this.apollo.watchQuery({
      query: getmembersByIdQuery,
      variables: {
        userIds: [userId]
      },
      fetchPolicy:"no-cache"
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      //console.log(data.users[0])
      if (data.users[0]) {
        this.userData = data.users[0]
        let teamIds = this.userData.teams.map(item => {
          return item.teamId
        })
        
        this.memberForm.patchValue({
          phone: this.userData.b2BPhone,
          first_Name: this.userData.firstName,
          lastName: this.userData.lastName,
          email: this.userData.email,
          ngxTeamValue: teamIds,
          role:this.userData.role
        })
        if(this.userData.countryCode =='RU')
          {
            setTimeout(()=>{
            $(".selected-flag .iti-flag").removeClass("kz")
            $(".selected-flag .iti-flag").removeClass("us")
          },10)

          }
          if(this.userData.countryCode =='RU')
            {
              setTimeout(()=>{
                $(".selected-flag .iti-flag").removeClass("kz")
                $(".selected-flag .iti-flag").removeClass("us")
                $(".selected-flag .iti-flag").addClass("ru")
              },400)
             
            }


         setTimeout(()=>{
			this.enableAutoCountrySelect = false
		},2000)
      }
    })
  }
  updateMember (memberObj) {
    this.loading = true

    this.clearAllTeamIds()
    let selectedTeamIds: any = this.f.ngxTeamValue.value
    this.apollo
      .mutate({
        mutation: updateMemberMutation,
        variables: memberObj,
        update: (proxy, { data: { QSGHAXEPKC } }: any) => {
          // Read the data from our cache for this query.
          try {
            let data: any = proxy.readQuery({
              query: getmembersByIdQuery,
              variables: { userIds: [memberObj.userId] }
            })
            //console.log(data)
            data.users[0] = QSGHAXEPKC
            proxy.writeQuery({ query: getmembersByIdQuery, data })
            data = proxy.readQuery({
              query: membersBasicQuery,
              variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
            })
            if (data) {
              const foundIndex = data.users.findIndex(
                x => x.userId == memberObj.userId
              )
              data.users[foundIndex] = QSGHAXEPKC
              proxy.writeQuery({
                query: membersBasicQuery,
                variables: { limit: 5, offset: 0, sortByDate: 'DESC' },
                data
              })
            }
          } catch (e) {
            //  let  data:any = proxy.readQuery({ query:getmembersByIdQuery,variables:{userIds: [memberObj.userId]} });
            //  console.log(e)
            //   data.users.push(QSGHAXEPKC)
            //   proxy.writeQuery({ query: getmembersByIdQuery, data });
          }
        }
      })
      .subscribe(
        ({ data, loading }: any) => {
          // console.log(error)
          this.loading = false
          if (data.QSGHAXEPKC) {
            //this.toastr.info("Member updated successfully.");
            //this.router.navigate(['/members'])
            this.saveMemberToTeam(memberObj.userId, selectedTeamIds, 2)
          }
        },
        error => {
          this.loading = false

          this.translate
            .get('MESSAGES.ALREADY_EMAIL_PHONE_NUMBER_EXISTS')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
          console.log('there was an error sending the mutation', error)
        }
      )
  }

  createMember (memberObj) {
    this.loading = true
    let selectedTeamIds: any = this.f.ngxTeamValue.value
    this.apollo
      .mutate({
        mutation: addMemberMutation,
        variables: memberObj,
        update: (proxy, { data: { ERSNMYWSHP } }: any) => {
          const data: any = proxy.readQuery({
            query: membersBasicQuery,
            variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
          })
          data.users.unshift(ERSNMYWSHP[0])
          proxy.writeQuery({
            query: membersBasicQuery,
            variables: { limit: 5, offset: 0, sortByDate: 'DESC' },
            data
          })
        },
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          // alert(1)
          this.loading = false
        
          if (data && data.ERSNMYWSHP[0]) {
            this.saveMemberToTeam(
              data.ERSNMYWSHP[0].userId,
              selectedTeamIds,
              1
            )
          }
          if(errors && errors[0].message == "USER_ALREADY_EXISTS")
          {
            
              // if(confirm("Do you want to merge the account?"))
              // {
                  this.mergeAccount(memberObj.phone)
              // }
          }
          else if(errors && errors[0].message == "B2B_USER_EXISTS"){
            this.translate
            .get('MESSAGES.ALREADY_EMAIL_PHONE_NUMBER_EXISTS')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
          }
          else if(errors && errors[0].message == "INVALID_DOMAIN"){
              // todo need to implement translation
             this.toastr.info("You're not authorised to add users outside your corporate domain");
          } else if(errors && errors[0].message == "USER_LIMIT_REACHED"){
             // todo need to implement translation
             this.toastr.info("Your account reached maximum users limit, please contact corporate@staytouch.com");
          }
          // this.router.navigate(['/members'])
        },
        error => {
          // alert(2)
          console.log(error)
          this.loading = false
          this.translate
            .get('MESSAGES.ALREADY_EMAIL_PHONE_NUMBER_EXISTS')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  mergeAccount(phone)
  { 
    this.loading = true
    let userInfo = {
      email: this.f.email.value,
      phone: phone,
      account:false
    }
    this.apollo
    .mutate({
      mutation: mergeUserMutation,
      variables: {
        user:userInfo,
        code:""
      }
    })
    .subscribe(
      ({ data,errors }:any) => {
        this.loading = false
       if(data.mergeUser)
       {
        // alert("Successfully completes the migration process..")
        this.translate
        .get('MESSAGES.MEMBER_CREATED_SUCCESSFULLY')
        .subscribe((res: string) => {
          this.toastr.info(res)
        })
        this.router.navigate(['/members'])
       }
       else if(errors && errors[0].message == "CODE_VERIFICATION_FAILED"){
        alert("Invalid verification code.")
      }
       else{
         alert("Error while merge the account.")
       }
        //console.log('got data', data);
      },
      error => {
        this.loading = false

        alert("Error while merge the account.")
        console.log('there was an error sending the mutation', error)
      }
    )

  }
  saveMemberToTeam (userId, selectedTeamIds, type) {
    
    // alert()
    //  console.log(this.f.ngxTeamValue,'dfsdfsd')
    //   return;
    // let selectedTeamIds:any = this.f.ngxTeamValue.value
    if (selectedTeamIds.length > 0) {
      selectedTeamIds.forEach((item, i) => {
        let teamObj = {
          teamId: item,
          memberIds: userId
        }
        this.apollo
          .mutate({
            mutation: teamsAddMemberMutation,
            variables: teamObj,
            update: (proxy, { data: { MPYVMDLKYI } }: any) => {
              // Read the data from our cache for this query.
              const data: any = proxy.readQuery({ query: teamsQuery })
              const foundIndex = data.teams.findIndex(
                x => x.teamId == teamObj.teamId
              )
              data.teams[foundIndex] = MPYVMDLKYI
              proxy.writeQuery({ query: teamsQuery, data })
            }
          })
          .subscribe(
            ({ data }) => {
              if (i == selectedTeamIds.length - 1) {
                if(!this.userId)
                {
                  this.translate
                  .get('MESSAGES.MEMBER_CREATED_SUCCESSFULLY')
                  .subscribe((res: string) => {
                    this.toastr.info(res)
                  })
                }
                else{
                  this.translate
                  .get('MESSAGES.MEMBER_UPDATED_SUCCESSFULLY')
                  .subscribe((res: string) => {
                    this.toastr.info(res)
                  })
                }

                if (type == 1) {
                  this.router.navigate(['/official-profile', userId,3])
                } else {
                  this.router.navigate(['/members'])
                }
              }
              //console.log('got data', data);
            },
            error => {
              console.log('there was an error sending the mutation', error)
            }
          )
      })
    } else {
      if(!this.userId)
      {
        this.translate
        .get('MESSAGES.MEMBER_CREATED_SUCCESSFULLY')
        .subscribe((res: string) => {
          this.toastr.info(res)
        })
      }
      else{
        this.translate
        .get('MESSAGES.MEMBER_UPDATED_SUCCESSFULLY')
        .subscribe((res: string) => {
          this.toastr.info(res)
        })
      }
     
      if (type == 1) {
        this.router.navigate(['/official-profile', userId,3])
      } else {
        this.router.navigate(['/members'])
      }
    }
  }
  removeMemberAdd (ev) {
    this.removedIds.push(ev)
    return
  }

  clearAllTeamIds () {
    this.removedIds.forEach(teamId => {
      let teamObj = {
        teamId: teamId,
        memberIds: this.userId
      }
      this.apollo
        .mutate({
          mutation: teamsRemoveMemberMutation,
          variables: teamObj,
          update: (proxy, { data: { SSYEYCOLZJ } }: any) => {
            // Read the data from our cache for this query.
            const data: any = proxy.readQuery({ query: teamsQuery })
            const foundIndex = data.teams.findIndex(
              x => x.teamId == teamObj.teamId
            )
            data.teams[foundIndex] = SSYEYCOLZJ
            proxy.writeQuery({ query: teamsQuery, data })
          }
        })
        .subscribe(
          ({ data }) => {
            // console.log('got data', data);
          },
          error => {
            console.log('there was an error sending the mutation', error)
          }
        )
    })
  }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : ""; 
    this.queryTm$ ?this.queryTm$.unsubscribe() : "" ;
    }
    teamsAdd(ev){
      const elmnt1:any = document.getElementsByClassName(`ngx-select__sea rch`)[0] as HTMLElement
      elmnt1.placeholder = "Select team name";
    }

    checkManagerOrNot(teamId){
      // return true
      let value = this.teams.some(element => {
        if(element.teamId == teamId){
          // console.log(element.managers.includes(this.userId))
          return element.managers.includes(this.userId)
        }
      });
      // console.log(value)
      return value
    }
    makeManager(event,teamId,i){
   
      let manager = false
      if(event.target.value == 'Manager')
      {
        manager = true
      }
      // return
      this.loading = true
      this.apollo
      .mutate({
        mutation: makeManagerMutation,
        variables: {
          teamId: teamId,
          memberId:this.userId,
          manager:manager
        }
      })
      .subscribe(
        ({ data }) => {
          this.loading = false
          if(manager){
            this.teams[i].managers.push(this.userId)
          }
          else{
            this.teams[i].managers = this.teams[i].managers.splice(i,1)
          }
          // console.log(this.teams[i].managers)
          this.toastr.info("Updated the user role.")
  
        },
        error => {
          this.loading = false
        }
      )
    } 
    onSearchChange(eve){
      if((eve.key=="0" || eve.which ==48) && eve.target.value.length == 0){
        return false
      }
     return true
    }
    
}
