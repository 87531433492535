import { Component, OnInit } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'
import { Router, ActivatedRoute, Params } from '@angular/router'
import {
  convertToAppContactMutation,
  createcontactMutation,
  contactDetailsQuery,
  contactsQuery
} from '../../../services/contacts.gql'
import {
  getmembersByIdQuery,
  teamsQuery,
  teamsAddMemberMutation
} from '../../../services/admin.gql'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'
import { createMeetingMutation } from '../../../services/meeting.gql'
import { profilesQuery } from '../../../services/user.gql'
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
import { HelperService } from '../../../services/helper.service'

const moment = require('moment-timezone')

@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss']
})
export class ViewAccountComponent implements OnInit {
  private query: QueryRef<any>
  private query$: any
  private queryTm$: any
  private queryTm: QueryRef<any>

  userData:any
  userId:any
  loading:boolean = true
  userProfile:any
  public accountInfo :any
  public isOpen = false
  type:any=""
  profileId: any
  private queryPr: QueryRef<any>
  private queryPr$: any
  modalReference: any;
  teams:any = []
  selectedTeams:any
  isAdmin:boolean = false
  loginUserId:any 
  projectId:any
  permissionsRes:any ={}
  constructor( private apollo: Apollo,private activatedRoute: ActivatedRoute,    private translate: TranslateService,    private router: Router, private toastr: ToastrService,
    private modalService: NgbModal,private helperService:HelperService
    ) {
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      // console.log(data)
      this.loading = false
      this.loginUserId = data.accountInformation.id
      //alert(this.userId)
      
    })
  }

  ngOnInit() {
    this.isAdmin = this.helperService.isAdmin()
    this.queryPr = this.apollo.watchQuery({
      query: profilesQuery,
      fetchPolicy:"network-only"
    })
    this.queryPr$ = this.queryPr.valueChanges.subscribe(({ data }) => {
      if (data.profiles[0]) {
        this.profileId = data.profiles[0].id
      }
    })
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.getUserDetails(params.id)
        this.userId = params.id
      }
      if (params.type) {
        this.type = params.type
      }
      this.projectId = params.projectId 
    })
    this.getTeams()
  }

  getUserDetails (userId) {
    // alert(4)
    this.query = this.apollo.watchQuery({
      query: getmembersByIdQuery,
      variables: {
        userIds: [userId]
      },
      fetchPolicy:"no-cache"
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      //console.log(data.users[0])
      this.loading = false
      if (data.users[0]) {
        this.userData = data.users[0]
        if(data.users[0].profiles.length>0)
        {
          console.log(data.users)
          this.userProfile = data.users[0].profiles[0]
          const [emails, phones, links, socialLinks, location,department,title, company,education,custom] =   this.userProfile.data.reduce((result, element) => {
            if(element.format=='EMAIL')
            {
              result[0].push(element)
            }
            if(element.format=='PHONE_NUMBER')
            {
              result[1].push(element)
            }
            if(element.format=='LINK')
            {
              result[2].push(element)
            }
            if(element.format=='SOCIAL_MEDIA')
            {
              
              result[3].push(element)
            }
            if(element.format=='LOCATION')
            {
              element.value = element.value.split('$$$').filter(Boolean).join(", ");
              result[4].push(element)
            }
            if(element.format=='DEPARTMENT')
            {
              result[5]=element
            } 
            if(element.format=='JOB_TITLE')
            {
              result[6]=element
            }
            if(element.format == 'ORGANIZATION')
            {
              result[7]=element
            }
            if(element.format == 'UNIVERSITY')
            {
              // console.log(element.value.split('$$$'))
              let arrayInfo = element.value.split('$$$')
              // let duration
              // if(arrayInfo[4] && arrayInfo[5] ){
              //   duration = `${arrayInfo[4]} - ${arrayInfo[5}`
              // }
              element.value = element.value.split('$$$').filter(Boolean).join(" ");
              result[8]=element
            }
            if(element.format == 'GENERIC')
            {
              result[9].push(element)
            }
    
            // result[8].push(element)
            // result[element <= 10 ? 0 : 1].push(element); // Determine and push to small/large arr
            return result;
          },
          [[], [],[], [],[], {},{},{},{},[]]);  
          let filterData = {emails, phones, links, socialLinks, location,department,title, company,education,custom}
          this.userProfile = {...this.userProfile,...filterData}
          // console.log( this.userData ,' this.userData ')
        }
      }
    },err=>{
      this.loading = false
    })
  }
  isManger(managers,userId){
    return managers.includes(userId)
  }
  getValidUrl = (url = '') => {
    let myWindow = window as any
    let newUrl = myWindow.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, '')

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`
    }

    return newUrl
  }
  connectMember(member){
    console.log(member)
    let {firstName,lastName} = member
    let data
    let email
    let phone
    member.profiles.forEach((item,j)=>{
      if(item.type == "OFFICIAL")
      {
         data =  item.data.map((e)=>{

            delete e.__typename
            if (e.format == 'EMAIL') {
              email = e.value
            }
            if(e.format == 'PHONE_NUMBER')
            {
              phone = e.value
            }
            return e
          })
      }
    })
    let memberObj = {
      firstName: firstName,
      lastName : lastName,
      rating:0,
      favorite: false,
      data: data
    }
    console.log(memberObj,'memberObj',member,email,phone)
    this.createContact(memberObj,email,phone)
  }

  createContact (memberObj,email,phone) {
    console.log(memberObj,email,phone)
    this.loading = true
    this.apollo
      .mutate({
        mutation: createcontactMutation,
        variables: memberObj,
        update: (proxy, { data: { XBVCWTVILM } }: any) => {
          try {
            let data: any = proxy.readQuery({
              query: contactsQuery,
              variables: { limit: 5, offset: 0, orderByName :"ASC"}
            })
           data.contacts.push(XBVCWTVILM[0])
			    data.contacts = data.contacts.sort((a, b) => a.firstName.localeCompare(b.firstName))
            proxy.writeQuery({
              query: contactsQuery,
              variables: { limit: 5, offset: 0, orderByName :"ASC"},
              data
            })
           
          } catch (e) {
            console.log(e)
          }
        },
        refetchQueries: [
          {
            query: contactDetailsQuery,
            variables: { limit: 3, offset: 0 }
          }
        ]
      })
      .subscribe(
        ({ data }: any) => {
          this.loading = false
          if (data.XBVCWTVILM[0]) {
            this.createMeeting(data.XBVCWTVILM[0].id,memberObj)
            this.convertToAppContact(data.XBVCWTVILM[0].id,memberObj,email,phone)
            let {
              
              id
            } = data.XBVCWTVILM[0]
          } else {
            this.translate
              .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
              .subscribe((res: string) => {
                this.toastr.error(res)
              })
          }
        },
        error => {
          this.loading = false
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  convertToAppContact(id,shareValue,email,phone){
    if (!this.profileId) {
      alert('Please create your Corporate profile to share.')
      return
    }
    let obj:any ={

    }
    console.log(email)
    console.log(phone)
    // if(this.shareType ==1)
    // {
      obj.shareByEmail = email
      obj.shareBySMS = phone
    // }
    // else  if(this.shareType ==2){
    //   obj.shareBySMS = shareValue
    // }
    this.apollo
      .mutate({
        mutation: convertToAppContactMutation,
        variables: {
          sharedProfileIds: this.profileId,
          nonAppContactId:id,
          ...obj
        }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.FIQMDLMJOO) {
            let {id,userIdOfContact,nonAppContact,sent} = data.FIQMDLMJOO
            this.router.navigate(['/contact-details',userIdOfContact])
          } 
        },
        error => {
          this.translate
            .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  createMeeting (contactId,memberObj) {
    let startTime = moment().format('YYYY-MM-DD HH:mm')
  let endTime =  moment().add(10,'minutes').format('YYYY-MM-DD HH:mm')
  startTime = moment
  .tz(startTime,moment.tz.guess())
  .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  endTime = moment
  .tz(endTime,moment.tz.guess())
  .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  this.loading = true
  this.apollo
    .mutate({
      mutation: createMeetingMutation,
      variables: {
        title: `Added ${memberObj.firstName} ${memberObj.lastName} to contact book`,
        location: '',
        participantIds: [contactId],
        startTime: startTime,
        endTime: endTime,
        isConference: false,
        description:  `Added ${memberObj.firstName} ${memberObj.lastName} to contact book`,
         type: "BLUETOOTH_CREATED",
         conferenceDetails:"",
         adminValidation: false,
        onlyOriginalParticipantsCanJoin:true,
        isPrivate:false
      }
    })
    .subscribe(
      ({ data }) => {
        this.loading = false
        this.translate
          .get('MESSAGES.CONTACT_CREATED_SUCCESSFULLY')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
      },
      error => {
        this.loading = false
        this.translate
          .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
          .subscribe((res: string) => {
            this.toastr.info(res, null, {
              closeButton: true,
              disableTimeOut: false
            })
          })
        console.log('there was an error sending the mutation', error)
      }
    )
}

openTeamModal(id,content){
  let ngbModalOptions: NgbModalOptions = {
    // backdrop: 'static',
     // keyboard: false
     // centered:true
   }
   this.modalReference = this.modalService.open(content, ngbModalOptions)
   this.modalReference.result.then((result) => {
    // this.closeResult = `Closed with: ${result}`;
    if(result!=0){
      this.saveMemberToTeam(this.selectedTeams)
    }
  }, (reason) => {
    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
getTeams () {
  // querying the teams list
  this.queryTm = this.apollo.watchQuery({
    query: teamsQuery,
    fetchPolicy:'network-only',
    variables: {}
  })

  this.queryTm$ = this.queryTm.valueChanges.subscribe(result => {
    this.teams = result.data.teams
    //console.log(this.teams)
  })
}
saveMemberToTeam ( selectedTeamIds) {
    
  // alert()
  //  console.log(this.f.ngxTeamValue,'dfsdfsd')
  //   return;
  // let selectedTeamIds:any = this.f.ngxTeamValue.value
  if (selectedTeamIds.length > 0) {
    selectedTeamIds.forEach((item, i) => {
      let teamObj = {
        teamId: item,
        memberIds: this.userId
      }
      this.apollo
        .mutate({
          mutation: teamsAddMemberMutation,
          variables: teamObj,
          update: (proxy, { data: { MPYVMDLKYI } }: any) => {
            // Read the data from our cache for this query.
            const data: any = proxy.readQuery({ query: teamsQuery })
            const foundIndex = data.teams.findIndex(
              x => x.teamId == teamObj.teamId
            )
            data.teams[foundIndex] = MPYVMDLKYI
            proxy.writeQuery({ query: teamsQuery, data })
          }
        })
        .subscribe(
          ({ data }) => {
            if (i == selectedTeamIds.length - 1) {
              this.toastr.info("Successfully added member to team")
            }
            //console.log('got data', data);
          },
          error => {
            console.log('there was an error sending the mutation', error)
          }
        )
    })
  } 
}

}
