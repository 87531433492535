import { Component, OnInit,OnDestroy,ChangeDetectionStrategy,Input,ChangeDetectorRef } from '@angular/core'
import { createCategoryMutution,updateCategoryMutution, categoryQuery,deleteCategoryMutution} from "../../../services/products.gql"
import { ToastrService } from 'ngx-toastr';

import { Apollo, QueryRef } from 'apollo-angular'
import { Router } from '@angular/router';
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import {TranslateService} from '@ngx-translate/core';

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  private query: QueryRef<any>
  private query1: QueryRef<any>

  private query$: any
  private query1$: any
  categories:any=[]
  contactInfo:any
  groupInfo:any
  id:number
  groupId:number=0
  isOpen:boolean = false
  getState: Observable<any>
  loading:boolean = false
  actionType:any = 0
  // to avoid ng build errors in prod
  isRemainderOpen: boolean
  isShareOpen: boolean
  currentRate = 0;
  selectedCategory:any = []
  tempCategories:any = []
  isFilter:boolean = false
  loadingText:boolean = false
  filterText:string = ""
  categoryName:any
  totalItems:any
  page:any =1
  itemsPerPage = 10 ;
p:any
  private modalReference: NgbModalRef; 
  constructor(private apollo:Apollo, private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,    
    private translate: TranslateService,
    private cd: ChangeDetectorRef
) { 

    
  }

  ngOnInit() {
  
    this.query1 = this.apollo.watchQuery({
      query:categoryQuery,
      variables:{
        // userId: this.id
      },
      fetchPolicy:"network-only"
      
    })
    // /* Get the contact groups */
     this.getPage(1)
     this.getAllCategories()
   
  }
  clearFilter(){
    this.isFilter = false
    this.categories = this.tempCategories
    this.filterText = ""
    
  }
  // getCategories(){
  //   this.loading = true
  //   this.query1$ =this.query1.valueChanges.subscribe(result => {
  //     this.loading = false
  //     if(result.data.categories)
  //     {
  //       this.categories = result.data.categories
  //       this.tempCategories  = this.categories
  //     }
      
  //     },error => {
  //       this.loading = false
  //     })
  // }
  getMessage(event){
    this.isOpen = false
    this.getPage(1)
  }
  
  
  getGroupContactInfo(id) {
    // alert(contactIds.length)
    if(id.length == 0)
    {
      this.loadingText = false
      return
    }
    this.loadingText = true
    this.query = this.apollo.watchQuery({
      query: categoryQuery,
      variables:{
        id:id
      },
      errorPolicy: "all",

    })
    this.query$ = this.query.valueChanges.subscribe(result => {
      this.loadingText = false
        if(result.data.contacts){
          let contactsInfo = result.data.contacts;

        }
    },error => {
      this.loading = false
    })
  }
deleteCategory(id,index) {

  
      if(!confirm("Are you sure do you want to delete this category?"))
      {
        return false
      }
  
  this.apollo.mutate({
    mutation: deleteCategoryMutution,
    variables:  {
      ids: id
    },
   
  }).subscribe(({ data,loading,error }:any) => {
     if(data.deleteCategory){
       this.categories.splice(index,1)
       this.totalItems = this.categories.length
       this.cd.detectChanges()
	      this.toastr.info("Category deleted successfully.");
     }
     else {
      this.toastr.info("Unable to delete successfully.");
     }
      
  },(error) => {
   // this.toastr.info("Already email/phone number exists.");
    console.log('there was an error sending the mutation', error);
   this.translate.get('MESSAGES.UNABLE_TO_DELETE_THE_CONTACT').subscribe((res: string) => {
	this.toastr.info(res);
});
  });
}
filterContactGroups(ev)
{
  console.log(ev.target.value)
  let {value:string} = ev.target
  console.log(string)
  if(!ev.target.value.trim()){
    this.categories = this.tempCategories
    return false
  }
  // this.contactGroups =this.contactGroups.filter((item,i)=>{
  //     Object.keys(item).some(k => item[k].toLowerCase().includes(string.toLowerCase())));
  // })
  this.isFilter = true
  this.categories = this.categories.filter(o =>
  {
    // console.log(o)
      return o.name.toLowerCase().includes(string.toLowerCase())
    
  });
}


confirmDeleteGroup(contactGroupId)
{
  this.translate
  .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_THIS_GROUP')
  .subscribe((res: string) => {
    if (!confirm(res)) {
      return false
    }
    else{
      this.deleteGroup (contactGroupId) 
    }
  })
}
deleteGroup (contactGroupId) {
 
  this.apollo
    .mutate({
      mutation: deleteCategoryMutution,
      variables: {
        id: parseInt(contactGroupId)
      },
      update: (proxy, { data: { deleteContactGroup } }: any) => {
        // Read the data from our cache for this query.
        const data: any = proxy.readQuery({ query: categoryQuery })
        console.log(data)
        var index = data.contact_groups
          .map(function (x) {
            return x.contactGroupId
          })
          .indexOf(contactGroupId)
        console.log(index)
        // Write our data back to the cache.
        data.contact_groups.splice(index, 1)
        proxy.writeQuery({ query: categoryQuery, data })
      }
    })
    .subscribe(
      ({ data }: any) => {
        this.translate
          .get('MESSAGES.CONTACT_GROUP_DELETE_SUCCESSFULLY')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
      },
      error => {
        this.translate
          .get('MESSAGES.ERROR_WHILE_DELETING_THE_GROUP')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
        console.log('there was an error sending the mutation', error)
      }
    )
}

createCategory(categoryName) {
 
  this.apollo
    .mutate({
      mutation: createCategoryMutution,
      variables: {
        name: categoryName
      }
    })
    .subscribe(
      ({ data }: any) => {
        if(data.createCategory){
          this.toastr.info("Category created successufully.")
          // this.categories.unshift(data.createCategory)
          // this.loading = true
          // this.totalItems = this.categories.length
          this.getPage(1)
          // this.cd.detectChanges()
          // this.getAllCategories()
          // this.getPage(1)
          // this.cd.detectChanges()
        }
      },
      error => {
        this.toastr.info("Unable to create category .")
      }
    )
}
updateCategory(categoryName,id) {
 
  this.apollo
    .mutate({
      mutation: updateCategoryMutution,
      variables: {
        name: categoryName,
        id:id
      }
    })
    .subscribe(
      ({ data }: any) => {
        if(data.updateCategory){
          this.toastr.info("Category updated successufully.")
         this.categories =  this.categories.map((item)=>{
            if(item.id == id){
              item.name= categoryName
            }
            return item
          })
        }
      },
      error => {
        this.toastr.info("Unable to update the category .")
      }
    )
}

openModal(content,type,selected=null,code=null,languageModal=null){
  let ngbModalOptions: NgbModalOptions = {
    // backdrop: 'static',
    // keyboard: false
    centered:true
  }
  this.modalReference = this.modalService.open(content, ngbModalOptions)
  this.modalReference.result.then(
    async result => {
      console.log(result)
      
      if(result){
        if(this.selectedCategory && this.selectedCategory.id){
          this.updateCategory(result,this.selectedCategory.id)
        }
        else{
          this.createCategory(result)
        }
        
      }
    },
    reason => {
      
      let closeResult = `Dismissed ${this.getDismissReason(reason)}`
    }
  )
}
getPage (page) {
  this.loading = true
  this.page = page
  this.query1 = this.apollo.watchQuery({
    query: categoryQuery  ,
    fetchPolicy:"no-cache"
  })

  this.query$ = this.query1.valueChanges.subscribe(({ data }: any) => {
    this.loading = false
    this.categories = data.categories 
  },err=>{
	this.loading= false
  })
}
getAllCategories () {
  this.query = this.apollo.watchQuery({
    query:   categoryQuery  ,
    fetchPolicy:"no-cache",
    variables:{
      status:status
    }
  })
  this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
    this.totalItems = data.categories.length
    this.cd.detectChanges();
  })
}
private getDismissReason (reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC'
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop'
  } else {
    return `with: ${reason}`
  }
}
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe()  : ""  
    this.query1$ ? this.query1$.unsubscribe() : ""
  }
}
