<div class="row justify-content-center " *ngIf="member">
  <app-loading *ngIf="loading"></app-loading>
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card customCard   w-100 my-3">
        <div class="memberBlock">
        
          <div class="dropdown  p-absolute"  style="top: 44px;">
            <a data-toggle="dropdown" class="">
              <img id="blah" src="assets/dots.svg" alt="dots" />
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-arrow  "
            >
              <ul class="list-inline">
                <!--
                        <li class="pt-2 p-2 border-cs"> <a class=" text-decoration-none   editContact" (click)="inviteUsers()"  >Invite users</a></li>
-->
                <li class="pt-2  p-2 ">
                  <a
                    class=" text-decoration-none   editContact"
                    [routerLink]="['/update-account']"
                  >
                    <!-- {{ 'MEMBERS_PAGE.UPDATE_ACCOUNT' | translate }} -->
                    Account Info
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="d-block d-md-flex  justify-content-between pl-lg-5 px-3  pt-5 pb-5	 cursor-pointer col-md-12 align-items-center pr-sm-0 flex-wrap"
            
          >
          <h5 class=" text-defaultClr default-color pb-4 w-100">
            My Account
           </h5>
            <div class="d-flex col-lg-12 align-items-start py-2">
              <div class="userBg " [routerLink]="['/update-account']">
                <!-- <div class="bg-text">{{contact.firstName | shortName}} {{contact.lastName | shortName}}</div> -->
                <!-- <img
                *ngIf="url!=''"
                  [src]="url"
                  class="position-absolute bg-pos"
                  onerror="this.style.display='none'"
                /> -->
                <img
                *ngIf="url!=''"
                [src]="url"
                class="position-absolute bg-pos"
                  onerror="this.style.display='none'"
                 />
                
              </div>
              <div class="pl-3 " style="width:calc(100% - 80px)" [routerLink]="['/update-account']">
                <div class="d-flex align-items-center">
                  <h4 class="text-ecllipsis text-capitalize">
                    {{ member.firstName }} {{ member.lastName }},
                    <small *ngIf="member.title || member.department" class="m-0" [routerLink]="['/update-account']">
                      {{ member.title }}<span *ngIf="member.title && member.department">,</span>
                      {{ member.department }}
                    </small>
                  </h4>
              
                </div>
                <div class="infoCls  col-md-12 px-0" [routerLink]="['/update-account']">
                  <p class="text-ecllipsis m-0">
                    <span> <img src="assets/icon_email.svg?x=1" alt="email" class="pr-1" /> {{ member.email }}</span>
                    <span  *ngIf="member.b2bPhone">, <img src="assets/icon_cell.svg?x=1" alt="Phone" class="pr-1 pl-2" /> {{ member.b2bPhone }}
                    </span>
                  </p>
                </div>
             

                <!-- <p *ngIf="member.title || member.department" class="m-0" [routerLink]="['/update-account']">
                  {{ member.title }}<span *ngIf="member.title && member.department">,</span>
                  {{ member.department }}
                </p> -->
                <div class="text-left w-100 px-0 pt-1" >  
                  <!-- <a *ngIf="noPublicProfile"
                  [routerLink]="['/public-profile']">Create Public Profile?</a>  -->
                  <ng-container *ngIf="!noPublicProfile">
                  <a target="_blank" href="https://www.staytouch.com/{{publicProfile.username}}">
                    <img _ngcontent-aur-c13="" width="28px" alt="website" class="pr-2" src="assets/website.svg?x=1">
                    https://www.staytouch.com/{{publicProfile.username}}</a>
                   <a
                  [routerLink]="['/public-profile']" class="pl-2" > <img  class="cursor-pointer ng-star-inserted" height="auto" src="assets/edit-profile.png" width="30px"></a> 
                  </ng-container>
                  
                </div>
              </div>
            </div>
<!-- 
            <div class="infoCls col-lg-3 col-md-6 px-0" [routerLink]="['/update-account']">
              <p class="text-ecllipsis m-0">
                <img src="assets/icon_email.svg?x=1" alt="email" class="pr-1" />
                {{ member.email }}
              </p>
            </div>
            <div class="infoCls col-lg-3 col-md-6" [routerLink]="['/update-account']">
              <h4 class="d-none d-md-block">&ensp;</h4>
              <p class="m-0 text-ecllipsis" *ngIf="member.b2bPhone">
                <img src="assets/icon_cell.svg?x=1" alt="Phone" class="pr-1" />
                {{ member.b2bPhone }}
              </p>
             
            </div> -->
            <div class="col-lg-6">

            </div>
            <div class="text-left w-100 px-0 col-lg-6" >  
              <a *ngIf="noPublicProfile"
              [routerLink]="['/public-profile']">Create Public Profile?</a> 
              <!-- <ng-container *ngIf="!noPublicProfile">
              <a target="_blank" href="https://www.staytouch.com/{{publicProfile.username}}">
                <img _ngcontent-aur-c13="" width="28px" alt="website" class="pr-2" src="assets/website.svg?x=1">
                https://www.staytouch.com/{{publicProfile.username}}</a>
               <a
              [routerLink]="['/public-profile']" class="pl-2" > <img  class="cursor-pointer ng-star-inserted" height="auto" src="assets/edit-profile.png" width="30px"></a> 
              </ng-container> -->
              
            </div>
            
             
          </div>
          <hr class="col-md-11 col-8 mt-0" />
          <div
            class="d-block d-md-flex  py-3 justify-content-between position-relative"
          >
            <div class="col-md-6 pl-5">
              <h5 class=" text-defaultClr default-color pb-3">
                {{ 'MEMBERS_PAGE.PERFORMANCE_TO_DATE' | translate }}
              </h5>
              <div class="d-sm-flex d-block align-items-end flex-wrap">
                <div
                  class="rate-cs d-flex justify-content-around text-center align-items-end"
                >
                <ng-container
                *ngIf="!attendedGraph && scheduleCount == 0"
                >                  <p  class="p-3">{{'MEMBERS_PAGE.NO_MEETINGS_ARE_SCHEDULED_YET' | translate}}</p>
               </ng-container>
               <ng-container
               *ngIf="attendedGraph && attendCount == 0"
               >               
                <p  class="p-3"> {{'MEMBERS_PAGE.NO_MEETINGS_ARE_ATTENDED_YET' | translate}}</p>
              </ng-container>
                  <ng-container *ngIf="attendedGraph">
                    <!-- <div *ngFor="let item of attendedMeetings | keyvalue">
                     
                      <div>
                        {{ item.value
                        }}<span
                          [ngStyle]="{
                            background:
                              'linear-gradient(to top, #8ceeff, #2183ed ' +
                              (item.value / attendCount) * 100 +
                              '%, #ffffff ' +
                              (item.value / attendCount) * 100 +
                              '%, #ffffff 100%)'
                          }"
                        ></span
                        ><i class="tiny-text"> {{ item.key }} </i>
                      </div>
                    </div> -->

                    <div *ngFor="let item of attendedMeetings | keyvalue:returnZero">
                     
                      <div>
                        
                        <!-- <span *ngIf="item.value!=0">
                        <i [ngStyle]="{
                          background:
                            'linear-gradient(to top, #8ceeff, #2183ed ' +
                            (item.value / attendCount) * 100 +
                            '%, #ffffff ' +
                            (item.value / attendCount) * 100 +
                            '%, #ffffff 100%)'
                        }">

                        </i>
                        </span> -->
                        <i *ngIf="item.value !=0" style="font-style: normal;"> {{ item.value }}</i>
                        <span class="d-block1">
                            <i  class="d-block custom-bar"  [ngStyle]="{'height': calculateHeight(item.value,attendCount) + 'px'}"></i>
                        </span>
                        <i class="tiny-text"> {{ item.key }} </i>
                      </div>
                    </div>
                  </ng-container>
                  
                  <ng-container
                    *ngIf="!attendedGraph && scheduleCount>0 "
                  >
                    <div *ngFor="let item of scheduleMeetings | keyvalue:returnZero">
                      <div>
                        <!-- <i *ngIf="item.value !=0" style="font-style: normal;"> {{ item.value }}</i> -->

                        <!-- <span class="d-block1">
                            <i  class="d-block custom-bar" [ngStyle]="{
                              height: (78/(scheduleCount/item.value) + 'px)'}"></i>
                        </span> -->
                        <i *ngIf="item.value !=0" style="font-style: normal;"> {{ item.value }}</i>
                        <span class="d-block1">
                            <i  class="d-block custom-bar"  [ngStyle]="{'height': calculateHeight(item.value,scheduleCount) + 'px'}"></i>
                        </span>
                        <i class="tiny-text"> {{ item.key }} </i>
                      </div>
                    </div>
                  </ng-container>

                  <!-- <div >18<span style="height:{{attendedMeetings.Mar}}" ></span><i class="tiny-text">{{attendedMeetings.Mar}}</i></div> -->
                  <!-- <div>12<span style="height:70px;"></span><i class="tiny-text">SEP</i></div>
                           <div>10<span style="height:50px;"></span><i class="tiny-text">OCT</i></div>
                           <div>21<span style="height:100px;"></span><i class="tiny-text">NOV</i></div>
                           <div>17<span style="height:70px;"></span><i class="tiny-text">DEC</i></div>
                           <div>14<span style="height:60px;"></span><i class="tiny-text">JAN</i></div>
                           <div>10<span style="height:50px;"></span><i class="tiny-text">FEB</i></div> -->
                </div>
                <div class="p-3 pl-1">
                  <label class="container-radio text-gray-app">
                    {{ 'MEMBERS_PAGE.ATTENDED_MEETINGS' | translate }}
                    <input
                      type="radio"
                      name="radio{{ member.id }}"
                      checked
                      (change)="attendedGraph = true"
                      [value]="true" [(ngModel)]="attendedGraph"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label
                    class="container-radio text-gray-app"
                    (change)="attendedGraph = false"
                  >
                    {{ 'MEMBERS_PAGE.SCHEDULED_MEETINGS' | translate }}
                    <input type="radio" name="radio{{ member.id }}" [value]="false" [(ngModel)]="attendedGraph" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          
          
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap flex-lg-nowrap px-2">
      <div class="card customCard  mb-3 w-100 mr-lg-3 ">
        <div class="d-flex1 p-md-5 py-5 p-3 h-100">
          <div class="w-100 d-flex flex-wrap flex-column h-100">
            <h5 class=" default-color pb-3 w-100">
              {{ 'COMMON.CONTACTS' | translate }}
            </h5>

            <div class="d-flex  justify-content-between w-100">
              <span class="tiny-font cursor-pointer"
              (click)="filterByRating(false)" >{{ 'COMMON.CONTACTS' | translate }} &nbsp;<img
                  src="assets/arr.svg?x=1" class="arrImg"
                  [src]="!isRating ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
              />
              <sub>  (Latest contacts)</sub>
            </span>
              <!-- <span class="text-gray2 tiny-font"
                >{{ 'COMMON.COMPANY' | translate }} &nbsp;<img
                  src="assets/arrr1.png"
              /></span> -->
              <span class="text-gray2 tiny-font cursor-pointer" (click)="filterByRating(true)"
                >{{ 'COMMON.RATINGS' | translate }} &nbsp;<img class="arrImg"
                [src]="isRating ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"

              /></span>
            </div>
            <div  class="flex-1 w-100" *ngIf="contacts.length >0">
              <div *ngFor="let contact of contacts" class="w-100 " >
                <div class="contact-list py-3 cursor-pointer w-100"   [routerLink]="['/contact-details',contact.nonAppContact? contact.id : contact.userIdOfContact]"  >
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="col-md-9 px-0 text-ecllipsis m-0 text-capitalize">
                      {{ contact.firstName }} {{ contact.lastName }}
                    </h4>
                    <ngb-rating
                      max="5"
                      class="col-md-3 px-0 text-right d-flex justify-content-end"
                      [(rate)]="contact.rating"
                      [readonly]="true"
                    ></ngb-rating>
                  </div>
                  <p class="m-0">
                    {{ contact.title }}<span *ngIf="contact.title && contact.company">, </span>
                    {{ contact.company }} 
                  </p>
                  <div
                    class="infoCls d-sm-flex d-block align-items-end content-width"
                  >
                    <a *ngIf="contact.email[0]" class="py-2 pr-3">
                      <img
                        src="assets/icon_email.svg?x=1"
                        alt="email"
                        title="{{ contact.email[0].value }}"
                        class="pr-1"
                      />&ensp;
                      {{ contact.email[0].value }}
                    </a>
                    <a *ngIf="contact.phone[0]" class="py-2 ">
                      <img
                        src="assets/icon_cell.svg?x=1"
                        alt="Phone"
                        title="{{ contact.phone[0].value }}"
                        class="pr-1"
                      />
                      {{ contact.phone[0].value }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
           
            <h5
              class=" py-5  my-4 text-center w-100 "
              *ngIf="!loading && contacts && contacts.length == 0"
            >
              No contacts.
            </h5>
            <div class="text-right w-100">
              <a
                [routerLink]="['/all-contacts']"
                style="text-decoration: underline;align-self: flex-end;"
                *ngIf="contacts.length != 0"
                >{{ 'COMMON.ALL_CONTACTS' | translate }}</a
              >
            </div>
            <!-- <div class="contact-list py-3">
                        <h4>Carlos Castro</h4>
                        <p>Director of Sales, Annenberg Foundation </p>
                        <div class="infoCls d-sm-flex d-block align-items-end content-width">
                           <img  src="assets/icon_email.png" alt="email"  class="pr-1" />&ensp;
                           <img  src="assets/icon_cell.png" alt="Phone"  class="pr-1" />
                        </div>
                     </div>
                     <div class="contact-list py-3">
                        <h4>Sarah McGill</h4>
                        <p>Global Analyst, Annenberg Foundation </p>
                        <div class="infoCls d-sm-flex d-block align-items-end content-width">
                           <img  src="assets/icon_email.png" alt="email"  class="pr-1" />&ensp;
                           <img  src="assets/icon_cell.png" alt="Phone"  class="pr-1" />
                        </div>
                     </div> -->
          </div>
        </div>
      </div>
      <div class="card customCard  w-100 mb-3  ml-lg-3 ">
        <div
          class="d-block d-md-flex my-sm-5 ml-sm-5 mr-sm-4 m-3 justify-content-between 
          "
          

        >
          <div class="w-100 " >
            <h5 class=" default-color pb-3 d-flex justify-content-between">
              {{ 'MEMBERS_PAGE.DAILY_PLANNER' | translate }} 
              <small> {{todayDate | amDateFormat: 'DD MMM, YYYY'}}</small>
            </h5>
            <div class="w-100 ps search-results ps-show-always " style="max-height: 370px"
            [perfectScrollbar]="config" >
              <div *ngIf="dailyPlanner.length>0">
                <ng-container *ngFor="let meeting of dailyPlanner">
                  <div class="list-block d-md-flex d-block py-3 cursor-pointer flex-wrap align-items-center" (click)="showMeetingInfo(meeting)"
                  > 
                    <div class="date-cs text-center">
                      <!-- <h5 >
                        {{ meeting.startTime  | amDateFormat: 'DD' }}
                      </h5>
                      <h5>
                        {{ meeting.startTime  | amDateFormat: 'MMM' }}
                      </h5> -->
                      <img src="assets/FPO_Navi_Calendar.png" height="15px" width="auto" />
                      <h6 >  {{
                        meeting.startTime  | amDateFormat: 'h:mm A'
                      }}</h6>
                      <!-- - {{ meeting.endTime  | amDateFormat: 'h:mm A' }} -->
                    </div>
                    <div class="meet-tile px-md-4 px-0 flex-1 " style="    word-break: break-all;" [ngClass]="meeting.formatedParticipants? ' col-md-12': ' col-md-12'">
                      <h6 class="m-0 text-capitalize" >{{ meeting.title }}</h6>
                      <!-- <p class="m-0 m-b">
                        {{
                          meeting.startTime  | amDateFormat: 'h:mm A'
                        }}
                        - {{ meeting.endTime  | amDateFormat: 'h:mm A' }}
                      </p> -->
                      <p class="m-0 m-b text-gray" *ngIf="meeting.location != 'undefined'">{{ meeting.location }}</p>
                  
                      <div *ngIf="meeting.formatedParticipants" class="col-md-12 p-0">
                        <!-- <h6 class="text-gray italicCls pt-md-0 pt-3">
                          {{ 'COMMON.PARTICIPANTS' | translate }}:
                        </h6> -->
                        <!-- <ng-container
                          *ngFor="let contact of meeting.formatedParticipants;let j=index"
                        >
                          <div class="pb-2" *ngIf="contact && j<2">
                            <p class="m-0 m-h text-capitalize" >
                              {{ contact.firstName }} {{ contact.lastName }}
                            </p>
                            <span class="m-b" *ngIf="contact.title"
                              >{{ contact.title }}</span><span *ngIf="contact.title && contact.company"
                              >, </span><i class="m-f">{{ contact.company }}</i>
                          </div>
                          <div *ngIf="j == 2" class="text-italic along-with ">
                            <a
                              data-toggle="modal"
                              data-target="#participantsModal"
                              class="theme-color"
                              (click)="isOpen = !isOpen; meetingId = meeting.id"
                            >
                              <small class="ds-font"
                                >{{ 'MEETING.ALONG_WITH' | translate }}
                                <b>{{ meeting.participants.length - 2 }}</b>
                                {{ 'COMMON.PARTICIPANTS' | translate }}
                              </small>
                            </a>
                          </div>
                        </ng-container>
       -->
                        <!-- <div class="pb-3">
                                                <p class="m-0 m-h">Sarah McGill</p>
                                                <span class="m-b">Global Analyst,</span><br/>
                                                <i class="m-f">Annenberg Foundation</i>
                                            </div> -->
                      </div>
                    </div>
                  </div>
                </ng-container>
                
              </div>
              <ng-container *ngFor="let info of remindersData">
                <div class="list-block d-md-flex  align-items-start1 d-block py-3 align-items-center cursor-pointer" [routerLink]="['/contact-details',info.nonAppContact? info.id : info.userIdOfContact]">
                  <div class="date-cs text-center">
                   
                    <img src="assets/reminders.svg" height="19px" width="auto" />
                    <h6 >  {{
                      info.reminderDate  | amDateFormat: 'h:mm A'
                    }}</h6>
                  </div>
                  
                  <!-- <img src="assets/reminders.png" class="pr-2" />  -->
                <span class="pl-4"> Today you have a {{ info.reminderMessage }} with   <span class="text-capitalize">{{ info.firstName }}</span> . </span>
                </div>
              </ng-container>
              <h5
                class="text-center pt-5 mt-5"
                *ngIf="
                  dailyPlanner &&
                  dailyPlanner.length == 0 &&
                  remindersData.length == 0
                "
              >
                {{ 'COMMON.NO_MEETINGS_ARE_SCHEDULED' | translate }}.
              </h5>
            </div> 
            <!-- <div class="list-block d-md-flex d-block py-3 " >
                        <div class="date-cs">
                           <h3>22</h3>
                           <h5>NOV</h5>
                        </div>
                        <div class="meet-tile px-md-4 px-0 ">
                           <h5>Coffee Chat</h5>
                           <p class="m-0 m-b">10 AM - 11:30 AM</p>
                           <p class="m-0 m-b">Los Angeles, CA</p>
                        </div>
                        <div>
                           <h5 class="text-gray italicCls pt-md-0 pt-3">Participants:</h5>
                           <div>
                              <p class="m-0 m-h">Carlos Castro</p>
                              <span class="m-b">Director of Sales,</span><br/>
                              <i class="m-f">Annenberg Foundation</i>
                           </div>
                        </div>
                     </div>
                  -->
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex card p-sm-5 p-3 py-5 mx-2">
      <h5 class=" default-color pb-3">
        {{ 'COMMON.MEETINGS_SUMMARY' | translate }}
      </h5>
      <div class="d-block d-md-flex pb-2 " *ngIf="(meetingsSummary| keyvalue | json) != '[]'">
        <div class="   p-0"  style="width: 70px;" >
          <span class="font-weight-bold"
           >{{ 'COMMON.DATE' | translate }} &nbsp;
            <!-- <img src="assets/arr.png"
          /> -->
        </span>
        <span class="themeBrd"></span>
        </div>
        <div class="col-lg-10 d-flex pl-4 text-left ">
          <div class="col-md-7 text-gray2 tiny-font1 pr-0 d1-none d-md1-none d-md1-block font-weight-bold">
            {{ 'CONTACT.TITLE' | translate }} &nbsp;
            <span class="themeBrd"></span>
            <!-- <img
              src="assets/arrr1.png"
            /> -->
          </div>
          <div class="col-md-5 text-gray2 tiny-font1 p-0 d-none d-md-block text-left font-weight-bold">
            {{ 'TEAMSPAGE.PARTICIPANTS' | translate }} &nbsp;
            <span class="themeBrd"></span>
            <!-- <img
              src="assets/arrr1.png"
            /> -->
          </div>
        </div>
      </div>
     
      <div
      class="list-block d-md-flex  d-block pt-2 "
      *ngFor="let meeting1 of meetingsSummary  | keyvalue:returnZero "
    >      
      <ng-container *ngIf="meeting1.key != 0">
        <div class="date-cs-bg  pl-0 pt-3">
          <div class="d-flex justify-content-between  text-center   pb-4">
            <div>
              <h2>
                {{
                  meeting1.key | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD'
                }}
              </h2>

              <h5 class="text-uppercase">
                {{
                  meeting1.key | amParse: 'DD-MM-YYYY' | amDateFormat: 'MMM'
                }}
              </h5>
            </div>
            <!-- <span class="px-2 " style="font-size: 25px;">-</span>
            <div>
              <h2>
                {{
                  meeting1.key | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD'
                }}
              </h2>
              <h5>  
                {{
                  meeting1.key | amParse: 'DD-MM-YYYY' | amDateFormat: 'MMM'
                }}
              </h5>
            </div> -->
          </div>
        </div>


        <div class="col-lg-10 px-md-4 px-0 w-100 pt-2" *ngIf="meeting1">
          <div
            class="w-100 border-bottom py-1"
            *ngFor="let meeting of meeting1.value "
          >
            <!-- {{meeting | json}} -->
            <div  (click)="showMeetingInfo(meeting)"
              class="d-md-flex justify-content-between summary-list mx-md-3 m-0  d-block cursor-pointer align-items-center1"
            >
            <div  class="col-md-7 pl-md-0 my-1 pr-0 pb-0">
              <h5 class=" text-capitalize ">
                {{meeting.title}}        
                </h5>
                <p class="text-gray m-0">{{
                  meeting.startTime  | amDateFormat: 'h:mm A'
                }}
                - {{ meeting.endTime  | amDateFormat: 'h:mm A' }}</p>
                <p class="text-gray m-0" *ngIf="meeting.location != 'undefined'">  {{meeting.location}}  </p>
                
            </div>
        
              <div class="col-md-5  pl-2 m-0  pb-2 flex-column d-flex" >
                <h6 *ngIf="meeting.participants.length == 0" class="text-weight-bold pt-2">No participants</h6>

                <ng-container *ngFor="let contact of meeting.participants; let j = index">
                  <h6 class="my-1" *ngIf="j < 2">
                    <span >
                      <span class="text-capitalize"> {{ contact.firstName }} {{ contact.lastName }}</span>
                      <i *ngIf="contact.title" class="ds-font">, {{ contact.title }}</i>
                      <span *ngIf=" contact.company">, </span>
                      <i class="ds-font">{{ contact.company }}</i>
                        <!-- <span *ngIf="contact.department && contact.title"
                          >, </span
                        >
                        <span>{{ contact.department }}</span>
                        <span *ngIf="(contact.department || contact.title) && contact.company"
                          >, </span
                        >
                        <span class="ds-font">{{ contact.company }}</span> -->
                    </span>
                  </h6>
                  <div *ngIf="j == 2" class="text-italic along-with ">
                    <a
                      data-toggle="modal"
                      data-target="#participantsModal"
                      class="theme-color"
                      (click)="isOpen = !isOpen; meetingId = meeting.id"
                    >
                      <small class="ds-font"
                        >{{ 'MEETING.ALONG_WITH' | translate }}
                        <b>{{ meeting.participants.length - 2 }}</b>
                        {{ 'COMMON.PARTICIPANTS' | translate }}
                      </small>
                    </a>
                  </div>
                </ng-container>
                
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
      <!-- {{meetingsSummary | keyvalue|json}} -->
     
      <h5  *ngIf="(meetingsSummary| keyvalue | json) == '[]'" class="py-3 text-center">{{ 'COMMON.NO_MEETINGS_ARE_SCHEDULED' | translate }}.</h5>
    </div>
  </div>
</div>

<app-participants-list
  *ngIf="isOpen"
  [meetingId]="meetingId"
></app-participants-list>
