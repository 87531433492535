<div class="modal-header py-3">
  <h5 class="modal-title">StayTouch</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
	<span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-4">
  <h6 class="pb-2">Would you like to transcribe the audio?</h6>
  <div class="d-flex ">
		<button type="button" class="btn btn-primary"  (click)="passBack(1)">Yes</button>
    <button type="button" class="btn btn-primary ml-2"  (click)="passBack(2)">No</button>

  </div>
</div>


