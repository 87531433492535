import { Component, OnInit,OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { teamsQuery} from '../../teams/team.gql'
import {
  membersBasicQuery,
  deleteMemberMutution,
  useInviteMutution,
  allMembersQuery
} from '../../../services/admin.gql'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'
import {GoogleAnalyticsService} from "../../../../shared/google-analytics.service"; // import our analytics service
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
import {
  convertToAppContactMutation,
  createcontactMutation,
  contactDetailsQuery,
  contactsQuery
} from '../../../services/contacts.gql'
import { createMeetingMutation } from '../../../services/meeting.gql'
import { profilesQuery } from '../../../services/user.gql'
import {environment} from "../../../../../environments/environment"
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../../services/helper.service'
var moment = require('moment-timezone')

@Component({
  selector: 'app-members',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss']
})
export class MembersListComponent implements OnInit,OnDestroy {
  public members: any = []
  private query: QueryRef<any>
  private queryTotal: QueryRef<any>
  private filterQuery: QueryRef<any>
  private queryTm:QueryRef<any>
  public AttSche: string = 'attended'
  public loading: boolean = true
  public loadingText:boolean = false
  memberInfo:any = {}
  mainLoading:boolean = true
  role:any 
  email:any
  phone:any
  public isScroll: boolean = true
  filterText: any = ''
  isFilter: boolean = false
  tempInfo: any = []
  query$:any
  filterQuery$:any
  queryTotal$:any
  totalUsers:any =0
  shareType:any = ''
  shareValue:any = ''
  private queryPr: QueryRef<any>
  private queryPr$: any
  profileId: any
  submitted:boolean = false  
  resetPasswordForm: FormGroup
  modalReference: any;
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  environmentInfo:any = environment
  account:any
  constructor (
    private apollo: Apollo,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private helperService:HelperService
  ) {
    this.role = localStorage.getItem('role')
    this.email = localStorage.getItem('email')
    this.phone = localStorage.getItem('phone')
  }
  
  ngOnInit () {
    this.isAdmin = this.helperService.isAdmin()
    // this.teamsInfo()
    this.resetPasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        oldPassword: ['']
      },
      {
        validator: this.MustMatch('newPassword', 'confirmPassword')
      }
    )
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
       this.permissions = this.helperService.getPermissions(data)
       this.account = data.accountInformation
    })


    this.queryPr = this.apollo.watchQuery({
      query: profilesQuery,
      fetchPolicy:"network-only"
    })
    this.queryPr$ = this.queryPr.valueChanges.subscribe(({ data }) => {
      if (data.profiles[0]) {
        this.profileId = data.profiles[0].id
      }
    })



    // querying the members list
    this.query = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        limit: 5,
        offset: 0,
        sortByDate: 'DESC'
      },
      fetchPolicy: 'network-only'
    })

   this.query$=  this.query.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
      this.formatUsers()
      /*  for (var i = 0; i < this.members.length; i++) {
        this[this.AttSche + this.members[i].id] = "true"
      } */
    },error=>{
      this.loading = false
      this.mainLoading = false
    })

    /* */
    this.getTotalUsers()
  }
  clearFilter () {
    this.members = this.tempInfo
    this.isFilter = false
    this.loading=false
    this.isScroll = true
    this.filterText=''
  }

  showMember (memberId) {
    this.router.navigate(['/view-member', memberId])
  }

  editMember (member) {
    this.router.navigate(['/create-member', member.userId])
  }
  confirmDelete(member)
  {
console.log(member,'member')
    this.translate
    .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_THIS_USER')
    .subscribe((res: string) => {
      if (!confirm(res)) {
        return false
      }
      else {
        this.deleteMember(member.userId)
      }
    })
  }
  deleteMember(id) {
    // console.log(id,'id')
    // return false
    this.apollo
      .mutate({
        mutation: deleteMemberMutution,
        variables: {
          userIds: [id]
        },
        update: (proxy, { data: { SIOVXASRBD } }: any) => {
          if(SIOVXASRBD.length > 0)
          {
            let data: any = proxy.readQuery({
              query: membersBasicQuery,
              variables: {
                limit: 5,
                offset: 0,
                sortByDate: 'DESC'
              }
            })
            data.users = data.users.filter(item => item.userId != id)
            //console.log(data,'after')
            proxy.writeQuery({
              query: membersBasicQuery,
              variables: {
                limit: 5,
                offset: 0,
                sortByDate: 'DESC'
              },
              data
            })
          }
          
        }
      })
      .subscribe(
        ({ data }: any) => {
          //console.log('message => ', data['SIOVXASRBD'])
          if (data.SIOVXASRBD.length >0) {
            this.members = this.members.filter(item => item.userId != id)
            this.tempInfo = this.tempInfo.filter(item => item.userId != id)
            this.totalUsers = this.totalUsers-1
            this.translate
              .get('MESSAGES.USER_DELETED_SUCCESSFULLY')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
              this.getUsers();
          }
          else
          {
            this.toastr.error("Unable to delete this contact.")
          }
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
    //window.location.reload()
  }

 
  getTotalUsers(){
    this.queryTotal = this.apollo.watchQuery({
      query: allMembersQuery,
      fetchPolicy: 'network-only'
    })

   this.queryTotal$=  this.queryTotal.valueChanges.subscribe(({ data }: any) => {
      if(data.users)
      {
        this.totalUsers =  data.users.length
      }
    },error=>{
      this.loading = false
    })
  }
  formatUsers(isSarch=false){
    this.members &&
        this.members.forEach((user, j) => {
          //this.members[j].title='--'
          if (user.profiles[0]) {
            this.members[j].url = user.profiles[0].displayImageURL
          }
          user.profiles[0] &&
            user.profiles[0].data.forEach((item, i) => {
              if (item.format == 'JOB_TITLE') {
                this.members[j].title = item.value ? item.value : '-'
              }
              if (item.format == 'DEPARTMENT') {
                this.members[j].department = item.value ? item.value : '-'
              }
            })
            if(!isSarch)
            {
              this.tempInfo = this.members
            }
          this.loading = false
          this.mainLoading = false
        })
  }
  setDefaultPic () {
    // this.pic = "assets/images/my-image.png";
  }
  confirmInvite(userId, i) 
  {
    // this.translate
    // .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_INVITE_THIS_USER')
    // .subscribe((res: string) => {
      if (!confirm("Do you want to invite this user to corporate account.")) {
        return false
      }
      else {
        this.inviteUser(userId, i)
      }
    // })
  }
  inviteUser (userId, i) {
   

    // return
    // return
    // alert(userId)
    this.apollo
      .mutate({
        mutation: useInviteMutution,
        variables: {
          userIds: [userId]
        },
        update: (proxy, { data: { usersInvite } }: any) => {
          // Read the data from our cache for this query.

          let data: any = proxy.readQuery({
            query: membersBasicQuery,
            variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
          })

          proxy.writeQuery({ query: membersBasicQuery, data })
        }
      })
      .subscribe(
        ({ data }: any) => {
          if (data.usersInvite) {
            let pswd: any = data.usersInvite[0].status.split('-')
            this.translate
              .get('MESSAGES.USER_INVITED_SUCCESSFULLY')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
           
          }
          // console.log('message => ', data['useInviteMutution']);
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  onScrollDown (ev) {
    if (this.isScroll) {
      this.getUsers()
    }
  }
  errorHandler (event) {
    event.target.src = 'assets/default.jpg'
  }
  getUsers (count = this.members.length) {
    if (count == 0) {
      return false
    }
    this.isScroll = true

    this.loadingText = true
    this.query.fetchMore({
      variables: {
        offset: count,
      },
      
      updateQuery: (prev, { fetchMoreResult }) => {
        this.loadingText = false
        if (!fetchMoreResult) {
          return prev
        }
        if (fetchMoreResult.users.length == 0) {
          this.isScroll = false
        }
        // console.log(prev,'pre')
        return Object.assign({}, prev, {
          users: [...prev.users, ...fetchMoreResult.users]
        })
      }
    })
  }
  filterUsers (ev) {
    let searchText = ev.target.value.trim()
    if (!searchText) {
      this.members = this.tempInfo
      this.isScroll=true
      
      return false
    }
    this.loading=true

    this.isScroll=false
    this.filterQuery = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        searchByName: searchText,
        
      },
      fetchPolicy: 'no-cache'
    })

    this.filterQuery$ = this.filterQuery.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
      this.formatUsers(true)
      this.loading=false
      // this.isScroll=true
      /*  for (var i = 0; i < this.members.length; i++) {
        this[this.AttSche + this.members[i].id] = "true"
      } */
    },err=>{
      this.loading=false
    })
  }
  SendEvent(eventCategory,eventLabel,eventAction,eventValue) {
    this.googleAnalyticsService.eventEmitter(eventCategory,eventLabel,eventAction,eventValue);
  }
  connectMember(member,i){
    let {firstName,lastName} = member
    let data
    let email
    let phone
    member.profiles.forEach((item,j)=>{
      if(item.type == "OFFICIAL")
      {
         data =  item.data.map((e)=>{

            delete e.__typename
            if (e.format == 'EMAIL') {
              email = e.value
            }
            if(e.format == 'PHONE_NUMBER')
            {
              phone = e.value
            }
            return e
          })
      }
    })
    let memberObj = {
      firstName: firstName,
      lastName : lastName,
      rating:0,
      favorite: false,
      data: data
    }
    this.createContact(memberObj,email,phone)
  }
 
  convertToAppContact(id,shareValue,email,phone){
    if (!this.profileId) {
      alert('Please create your Corporate profile to share.')
      return
    }
    let obj:any ={

    }
    // if(this.shareType ==1)
    // {
      obj.shareByEmail = email
      obj.shareBySMS = phone
    // }
    // else  if(this.shareType ==2){
    //   obj.shareBySMS = shareValue
    // }
    this.apollo
      .mutate({
        mutation: convertToAppContactMutation,
        variables: {
          sharedProfileIds: this.profileId,
          nonAppContactId:id,
          ...obj
        }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.FIQMDLMJOO) {
            let {id,userIdOfContact,nonAppContact,sent} = data.FIQMDLMJOO
            this.router.navigate(['/contact-details',userIdOfContact])
          } 
        },
        error => {
          this.translate
            .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
          console.log('there was an error sending the mutation', error)
        }
      )
  }

  createContact (memberObj,email,phone) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createcontactMutation,
        variables: memberObj,
        update: (proxy, { data: { XBVCWTVILM } }: any) => {
          try {
            let data: any = proxy.readQuery({
              query: contactsQuery,
              variables: { limit: 5, offset: 0, orderByName :"ASC"}
            })
           data.contacts.push(XBVCWTVILM[0])
			    data.contacts = data.contacts.sort((a, b) => a.firstName.localeCompare(b.firstName))
            proxy.writeQuery({
              query: contactsQuery,
              variables: { limit: 5, offset: 0, orderByName :"ASC"},
              data
            })
           
          } catch (e) {
            console.log(e)
          }
        },
        refetchQueries: [
          {
            query: contactDetailsQuery,
            variables: { limit: 3, offset: 0 }
          }
        ]
      })
      .subscribe(
        ({ data }: any) => {
          this.loading = false
          if (data.XBVCWTVILM[0]) {
            this.createMeeting(data.XBVCWTVILM[0].id,memberObj)
            this.convertToAppContact(data.XBVCWTVILM[0].id,memberObj,email,phone)
            let {
              
              id
            } = data.XBVCWTVILM[0]
          } else {
            this.translate
              .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
              .subscribe((res: string) => {
                this.toastr.error(res)
              })
          }
        },
        error => {
          this.loading = false
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  createMeeting (contactId,memberObj) {
      let startTime = moment().format('YYYY-MM-DD HH:mm')
    let endTime =  moment().add(10,'minutes').format('YYYY-MM-DD HH:mm')
    startTime = moment
    .tz(startTime,moment.tz.guess())
    .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    endTime = moment
    .tz(endTime,moment.tz.guess())
    .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    this.loading = true
    this.apollo
      .mutate({
        mutation: createMeetingMutation,
        variables: {
          title: `Added ${memberObj.firstName} ${memberObj.lastName} to contact book`,
          location: '',
          participantIds: [contactId],
          startTime: startTime,
          endTime: endTime,
          isConference: false,
          description:  `Added ${memberObj.firstName} ${memberObj.lastName} to contact book`,
           type: "BLUETOOTH_CREATED",
           conferenceDetails:"",
           adminValidation: false,
          onlyOriginalParticipantsCanJoin:true,
          isPrivate:false
        }
      })
      .subscribe(
        ({ data }) => {
          this.loading = false
          this.translate
            .get('MESSAGES.CONTACT_CREATED_SUCCESSFULLY')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
           
            // if(localStorage.getItem('from'))
            // {
            //   this.router.navigate(['/all-user-contacts/',localStorage.getItem('eventType')])
            // }
            // else
            // {
            //   this.router.navigate(['/all-contacts'])
          
            // }
        },
        error => {
          this.loading = false
          this.translate
            .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
            .subscribe((res: string) => {
              this.toastr.info(res, null, {
                closeButton: true,
                disableTimeOut: false
              })
            })
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  private getDismissReason (reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }
  resetPassword(id,content){
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
       // keyboard: false
       // centered:true
     }
     this.modalReference = this.modalService.open(content, ngbModalOptions)
     this.modalReference.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  get f () {
    return this.resetPasswordForm.controls
  }
  MustMatch (controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }
  updateUserPassword (token) {
    var query = `mutation{
       MEELVPCWUZ(oldPassword: "${this.f.oldPassword.value}", newPassword: "${this.f.newPassword.value}"){
        status
        description
        b2bStatus
    }}`;
    fetch('https://api.staytouch.com/v2.1/users/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization' : `ST:${token}`
      },
      body: JSON.stringify({
        query,
        // variables: { dice, sides },
      })
    })
      .then(r => r.json())
      .then(res => {
        this.loading=false
          let data = res.data
        if (data.MEELVPCWUZ.status == 'SUCCESS') {
          this.translate
            .get('MESSAGES.PASSWORD_UPDATED_SUCCESSFULLY')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
            this.modalReference.close()
                  // localStorage.clear()
          // this.router.navigate(['/members'])

          // this.errorMessage = "Unable to update the password. Please try again later.."
        }
        else if (data.MEELVPCWUZ.b2bStatus == 'WRONG_PASSWORD') {
          alert("Unable to update the password. Please check your old password and try again")
        }
         else {
         
          alert("Unable to update the password. Please try again later..")
        }
      });
  }

  updateMfaStatus (token,index,activate) {
    var query = `mutation{
      updateMultiFactor(activate: ${activate}){
        id
        twoFactor
      }}`;
    fetch('https://api.staytouch.com/v2.1/users/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization' : `ST:${token}`
      },
      body: JSON.stringify({
        query,
        // variables: { dice, sides },
      })
    })
      .then(r => r.json())
      .then(res => {
        this.loading=false
          let data = res.data
          this.members[index].twoFactor=activate
          if(activate){
            this.toastr.info('2Mfa authentication enabled successfully.')
          }
          else{
            this.toastr.info('2Mfa authentication disabled successfully.')
          }
                 
      });
  }

  getMasterToken(type,index,twoFactor=false){
    if(type=='2Mfa')
    {
        if(!twoFactor && !confirm('Are you sure do you want to disable the 2Mfa authentication?'))
        {
            return false
        }
        if(twoFactor && !confirm('Are you sure do you want to enable the 2Mfa authentication?'))
        {
            return false
        }
    }
    let userInfo = {
      "identityProvider": "STAYTOUCH",
      "credentials": {
        "email": this.memberInfo.email,
        "password": "=s@^jxqay+wpjzw$=94w9ga9vqrs$hvt$tj3tTYiW",
        "master": true,
        // "master": true
      },
      "token": environment.AUTH_TOKEN,
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
  }
   let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}

   // this.http.post<any>(`${environment.AUTH_URL}/signInB2B`, userInfo,headers).subscribe(data => {
    this.http.post<any>(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, userInfo,headers).subscribe(data => {
      if(type=='password')
      {
        this.updateUserPassword(data.authorizationToken)
      }
      if(type=='2Mfa')
      {
        this.updateMfaStatus(data.authorizationToken,index,twoFactor)
      }
    },(error) => {

    });      
  }
  onSubmit () {
    // return false;
    
    this.submitted = true
    if (this.resetPasswordForm.invalid) {
      return
    }
    this.getMasterToken('password','',false)
  }

  
  isManger(managers,userId){
    return managers.includes(userId)
  }
  // teamsInfo(){
  //   this.queryTm = this.apollo.watchQuery({
  //     query: teamsQuery,
  //     variables: {},
  //     errorPolicy: 'all',
  //     fetchPolicy:"network-only"
  //   })

  //   this.query$ = this.queryTm.valueChanges.subscribe(
  //     ({ data, loading }) => {
  //       console.log(data.teams)
  //     },
  //     error => {
  //       this.loading = false
  //       console.log('there was an error sending the mutation', error)
  //     }
  //   )
  // }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : "";
    this.filterQuery$ ?this.filterQuery$.unsubscribe() : "" ;
    this.queryTotal$ ?this.queryTotal$.unsubscribe() : "" ;
  }
}
