import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError, of } from 'rxjs'
import { Apollo } from 'apollo-angular'
import gql from 'graphql-tag'
import { Store } from '@ngrx/store'

import { AppState } from '../store/app.states'
import {MemberLogInFailure } from '../store/actions/member.auth.actions'
import { environment} from '../../../environments/environment'
const keypair = require('keypair')


// const userLoginMutation = gql`
// mutation memberLogin($username: String, $password: String) {
//   memberLogin(username: $username, password: $password) {
//     token
//     email   
//     id 
//   }
// }
// `;

@Injectable({
  providedIn: 'root'
})

export class MemberAuthService {
  loginCount:any =0

  constructor(private http: HttpClient, private apollo: Apollo, private store: Store<AppState>) {
  }

  getToken(): string {
    return localStorage.getItem('token');
  }


  logIn(email: string, password: string,phone: string): Observable<any> {
  const pair = keypair();

    this.loginCount = this.loginCount + 1
      let userInfo = {
        "identityProvider": "STAYTOUCH",
        "credentials": {
          "email": email,
          "password": password,
          "loginCount": this.loginCount 
          // "master": true
        },
        "token": environment.AUTH_TOKEN,
        "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee",
    }
    return Observable.create(observer => {
      let headers = {}
      let loginUser = localStorage.getItem("email")
      if(loginUser == 'ramya@krify.net')
      {
         headers = {
          headers: {
            // 'xframetoken': environment.xframetoken
          }
        }
      }
      //this.http.post<any>(`${environment.AUTH_URL}/signInB2B`, userInfo,
      // headers).subscribe(data => {
      this.http.post<any>(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, userInfo,
      headers).subscribe(data => {
        
        if (data.error) {
            this.store.dispatch(new MemberLogInFailure({ error: 'Invalid Username/Password' }))
          }
          window.localStorage.removeItem('forgotEmail');
           window.localStorage.setItem('kToken',pair.public);
          data.tempInfo = password
          observer.next(data)
          observer.complete()
      },(error) => {
         console.log('there was an error sending the query', error);
        if(error=="INVALID_AUTHORIZATION")
        {
          this.store.dispatch(new MemberLogInFailure({ error: 'Invalid Username/Password' }))
        }
        this.store.dispatch(new MemberLogInFailure({ error:  'Something went wrong' }))
      });
    })

    // return Observable.create(observer => {
    //   this.apollo.mutate({
    //   mutation: userLoginMutation,
    //     variables: {
    //       username: email,
    //       password: password
    //     }
    //   }).subscribe(({ data }) => {
    //     // console.log(data['memberLogin'])
    //     if (data['memberLogin'] == null) {
    //       this.store.dispatch(new MemberLogInFailure({ error: 'Invalid Username/Password' }))
    //     } 
    //     observer.next(data['memberLogin'])
    //     observer.complete()
    //   },(error) => {
    //     console.log('there was an error sending the query', error);
    //     return of({error: 'Something went wrong'})
    //   });
    // });
  }
}
