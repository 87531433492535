<footer class="w-100 mnt--footer footer-white" id="footer">
  <div class="col-12 mnt--footer-container">
   
    <div class="row col-sm-12 py-2 m-auto">
      <div class="col copywrite">
        © <span>{{crYear | date: 'yyyy'}}</span>, {{ 'FOOTER.COPYRIGHT_MSG' | translate }}
      </div>
    </div>
  </div>
</footer>
