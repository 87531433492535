<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card p-md-5 py-5 my-3 ">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a  [routerLink]="['/members']">{{ 'SUBMENU.MEMBERS' | translate }}</a></li>
            <li class="breadcrumb-item active"  aria-current="page">Corporate Profile</li>

            <li class="breadcrumb-item active text-capitalize" aria-current="page">{{userInfo && userInfo.firstName }} {{userInfo && userInfo.lastName }}</li>

            <li
           class="rightIcon ml-auto" [routerLink]="['/create-member', userId]" *ngIf="userId"
            ><img src="assets/left-arrow.svg?x=1" class="pl-2 arrowIcon" /> Back </li
          >
          </ol>
        
        </nav>

        <div *ngIf="!loading &&  !userData.data" class="text-center"> 
            <h3>No profile info found.</h3>
        </div>
        <div class="d-flex1 userData-details" *ngIf="!loading && userData.data">
          <div class="text-right stickyHeader">
            <!-- <a  [routerLink]="['/update-profile']"> <img src="assets/editGroup.svg" /></a> -->
            <a   [routerLink]="['/official-profile', userId,2]" class="theme-btn"> Edit Corporate Profile</a>
          </div>
          <div class="d-flex justify-content-center">
            <div class="col-md-41 m-auto imgBlock text-center">
              <!-- <img
                src="{{ userData.displayImageURL }}"
                (error)="img.src = 'assets/default.jpg'"
                style="height: 250px;object-fit: cover;"
                
                height="auto"
                #img
              /> -->
              <div class="imgBg">
                <img
                *ngIf="userData.displayImageURL"
                src="{{ userData.displayImageURL }}"
                  class="position-absolute bg-pos"
                  onerror="this.style.display='none'"
                  style="object-fit: cover;overflow: hidden;"
                  height="auto"
                />
              </div>
             
              <div class="socialMedia py-2 text-center" *ngIf="userData && userData.socialLinks">
                <ng-container *ngFor="let data of userData.socialLinks">
                  <a
                    href="https://facebook.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Facebook'"
                    ><img src="assets/fb.svg" /> </a
                  >&ensp;
                  <a
                    href="https://twitter.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Twitter'"
                    ><img src="assets/tw.svg" /> </a
                  >&ensp;
                  <a
                    href="{{ getValidUrl(data.value) }}"
                    target="_blank"
                    *ngIf="data.title == 'Linked In'"
                    ><img src="assets/linkedin.svg" /> </a
                  >&ensp;
                  <a
                    href="https://instagram.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Instagram'"
                    ><img src="assets/ins.svg" /> </a
                  >&ensp;
                  <a
                    href="{{ getValidUrl(data.value) }}"
                    target="_blank"
                    *ngIf="data.title == 'TikTok'"
                    ><img src="assets/tictok.svg" /> </a
                  >&ensp;
                </ng-container>
              </div>
            </div>
           
          </div>
          <div class="col-md-12">
            <div class="text-center">
              <div class="w-100 pl-0" *ngIf="userData">
                <h4 *ngIf="accountInfo" class="text-capitalize">
                  {{ accountInfo.firstName }} {{ accountInfo.lastName }}
                </h4>
                <!-- <p class="linkClr m-0" >
                  <span *ngIf="userData.title">  {{ userData.title.value }}</span>
                  <span *ngIf="userData.title && userData.department">,</span>
                  <span *ngIf="userData.department">  {{ userData.department.value }}</span>
                  <span *ngIf="(userData.title || userData.department) && userData.company">,</span>
                  <span *ngIf="userData.company"> {{ userData.company.value }}</span>
                  <span *ng></span>
                </p> -->
              
              </div>
             
            </div>

            <div
              class="d-flex justify-content-between1 pt-1 flex-wrap"
              *ngIf="userData"
            >
                <!-- <div class="col-lg-12 pl-0 d-flex"> -->
                  <ng-container *ngIf="userData.title">

                  <div class="col-lg-4 py-3" *ngIf="userData.title.value">
                    <div class="d-flex align-items-center">
                      <img src="assets/title.svg?x=1" alt="title" class="pr-2" />
                      <div class="pl-2">
                        <p class="text-gray m-0">{{ userData.title.title }}</p>
                        {{ userData.title.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="userData.department">
                  <div class="col-lg-4 py-3" *ngIf="userData.department.value">
                    <div class="d-flex align-items-center">
                      <img src="assets/company.svg?x=1" alt="company" class="pr-2" />
                      <div class="pl-2">
                        <p class="text-gray m-0">{{ userData.department.title }}</p>
                        {{ userData.department.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="userData.company">
                  <div class="col-lg-4 py-3" *ngIf="userData.company.value">
                    <div class="d-flex align-items-center">
                      <img src="assets/company.svg?x=1" alt="company" class="pr-2" />
                      <div class="pl-2">
                        <p class="text-gray m-0">{{ userData.company.title }}</p>
                        {{ userData.company.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="userData.company">
                  <div class="col-lg-4 col-sm-6 py-3" *ngIf="userData.university.value">
                    <div class="d-flex align-items-center">
                      <img src="assets/company.svg?x=1" alt="phone" class="pr-2" />
                      <div class="pl-2">
                        <p class="text-gray m-0">{{ userData.university.title }}</p>
                        {{ userData.university.value }}
                      </div>
                    </div>
                  </div>
                </ng-container>

                <!-- </div> -->
              <!---Email ---->
              <ng-container *ngIf="userData.emails && userData.emails.length>0">
                <div class="col-lg-4 py-3"  *ngFor="let data of userData.emails">
                  <div class="w-100" *ngIf="data.format == 'EMAIL'">
                    <div class="d-flex align-items-center">
                      <img src="assets/mail.svg?x=1" alt="mail" class="pr-2" />
                      <div class="pl-2">
                        <p class="text-gray m-0">{{ data.title }}</p>
                        {{ data.value }}
                      </div>
                    </div>
                  </div>
              </div>
            </ng-container>
              <!---Phone ---->
              <ng-container *ngIf="userData.phones && userData.phones.length>0">
              <div  class="col-lg-4 py-3"  *ngFor="let data of userData.phones">
                <div
                class="w-100"
                *ngIf="data.format == 'PHONE_NUMBER'"
                >
                <div class="d-flex align-items-center">
                  <img src="assets/phone.svg?x=1" alt="phone" class="pr-2" />
                  <div class="pl-2">
                  <p class="text-gray m-0">{{ data.title }}</p>
                
                  {{ data.value }}
                </div>
                </div>
                </div>
              
              </div>
            </ng-container>
        <!---Address ---->
               <!-- {{userData.location | json}} -->
               <ng-container   *ngIf="userData.location && userData.location.length>0" >
                <div class="col-lg-4 py-3" *ngFor="let data of userData.location">
                 <div
                   class="w-100"
                   *ngIf="data.format == 'LOCATION'"
                 >
                 <div class="d-flex align-items-center">
                   <img src="assets/location.svg?x=1" alt="location" class="pr-2" />
                   <div class="pl-2">
                       <p class="text-gray m-0">{{ data.title }}</p>
                       {{ data.value }}
                     </div>
                   </div>
                 </div>
              
               </div>
              </ng-container>

         <!---Education ---->
         <ng-container  *ngIf="userData.education" >
          <div class="col-lg-4 py-3"   *ngIf="userData.education.value">
            <div
            class="w-100"
            *ngIf="userData.education.format == 'UNIVERSITY'"
          >
            <div class="d-flex align-items-center">

                <img src="assets/education.svg" alt="education" class="pr-2" />
                <div class="pl-2">
                  <p class="text-gray m-0">{{ userData.education.title }}</p>
                {{ userData.education.value }}
                </div>
            </div>
          </div>
           
          </div>
        </ng-container>
  <!---Link ---->
  <ng-container *ngIf="userData.links && userData.links.length>0">
    <div class="col-lg-4 py-3" *ngFor="let data of userData.links">
      <div class="w-100" *ngIf="data.format == 'LINK'">
        <a href="{{ getValidUrl(data.value) }}" class="linkClr d-flex align-items-center">
          <img
            src="assets/website.svg?x=1"
            alt="website"
            class="pr-2"
          />
          <div class="pl-2">
            <p class="text-gray m-0">{{ data.title }}</p>
            {{ data.value }}
          </div>
        </a>
      </div>
    </div>
  </ng-container>

     <!---Link ---->
     <ng-container *ngIf="userData.custom && userData.custom.length>0">
      <div class="col-lg-4 py-3" *ngFor="let data of userData.custom">
      <div class="w-100" *ngIf="data.format == 'GENERIC'">
        <a href="{{ getValidUrl(data.value) }}" class="linkClr d-flex align-items-center">
          <img
            src="assets/dots.svg"
            alt="website"
            class="pr-2"
          />
          <div class="pl-2">
            <p class="text-gray m-0">{{ data.title }}</p>
            {{ data.value }}
          </div>
        </a>
      </div>
    </div>
  </ng-container>

            </div>
          
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
