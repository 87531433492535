import { Component, OnInit,ChangeDetectorRef,ViewRef } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'
import {
  productSuppliersQuery
} from '../../../services/products.gql'
import { Router, ActivatedRoute, Params } from '@angular/router'

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {
  loading:boolean = false
  itemsPerPage:number = 10
  page:number =1
  query$:any
  customers:any =[]
  totalItems:number =0
  suppliers:any =[]
  p:any
  searchText:any
  timeout: any = null;
  tempData:any=[]
  tempCount:number =0
  private query: QueryRef<any>

  constructor(
    private apollo: Apollo,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { 
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {startDate,endDate,page,supplierName} = this.router.getCurrentNavigation().extras.state
      this.page = page
    }
    this.getPage(this.page)
  }

  ngOnInit() {
  }

  getPage(page) {
    this.loading = true
    this.page = page
    let obj:any ={
      sortByColumn: "supplier_name",
      sortByPosition: "ASC",
      supplierMode: true,
      limit:this.itemsPerPage,
      offset:(this.page*this.itemsPerPage)-this.itemsPerPage,
    }
    if(this.searchText){
      obj.filterColumnValue = this.searchText.toLowerCase()
      obj.filterByColumn = "supplier_name"
    }
    this.query = this.apollo.watchQuery({
      query:   productSuppliersQuery  ,
      fetchPolicy:"network-only",
      variables:obj
    })
    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.suppliers = data.products
      // const unique = [];
      // this.suppliers.map(x => unique.filter(a => a.supplierName == x.supplierName ).length > 0 ? null : unique.push(x));

// console.log(unique);
      // this.suppliers = unique
      // this.tempData = this.suppliers
      if(this.suppliers.length > 0){
        // this.totalItems = data.products[0].totalProductsCount
        this.totalItems = data.products[0].totalProductsCount
        // this.tempCount = this.totalItems
      }
      else{
        this.totalItems =0
      }
      this.loading = false
      if (this.cd !== null && this.cd !== undefined && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
    },err=>{
      console.log(err)
      this.loading = false
    })
  }

  searchOrders(eve){
    // this.searchText = ""
    this.page=1
    clearTimeout(this.timeout);
    this.timeout = setTimeout( () =>{
      if(eve.target.value.length >0){
        let searchText = eve.target.value
        this.searchText = searchText
        // let searchKey = searchText.toLowerCase()
        this.getPage(1)
        // this.suppliers = this.tempData.filter(item => {
        //   return item.supplierName.toLowerCase().includes(searchKey)
        // });
        // this.totalItems = this.suppliers.length
        if (this.cd !== null && this.cd !== undefined && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }else if(eve.target.value==0){
        this.suppliers = this.tempData
        this.totalItems = this.tempCount
        this.searchText = ""
      }
     
    },1000)
  }
  clearFilter(){
    this.searchText =''
    this.getPage(1)
    // this.suppliers = this.tempData
    // this.totalItems = this.tempCount
  }
  viewDetails(customer){
    let {supplierName} = customer
    this.router.navigate(['/product-enquires',this.page,1], {
      state: {
        supplierName:supplierName,
        page:this.page,
        from:'suppliers-list'
      }
    })
  }

}
