<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
<div class="col-md-12 p-2 py-4 ">
<div class="card py-md-3 py-3 px-3 ">
   <div  class=" d-flex justify-content-between text-center pt-2 pb-3  grid-st-center1 align-items-center">
      <h5>Cart List</h5>
   </div>
   <div class="d-flex justify-content-between align-items-end pb-2 flex-wrap">
      <div class="table-responsive">
         <table class="table table-bordered m-0">
            <thead>
               <tr>
                  <!-- Set columns width -->
                  <th class=" py-3 px-4" style="min-width: 300px;">Product Name</th>
                  <th class=" py-3 px-4" style="width: 200px;">Price</th>
                  <th class=" py-3 px-4" style="width: 120px;">Quantity</th>
                  <th class=" py-3 px-4" style="width: 120px;" >Total</th>
                  <th class=" py-3 px-4" >Final Selling Price</th>

                  <th class=" align-middle py-3 px-0" style="width: 50px;"><a href="#" class="shop-tooltip float-none text-light" title="" data-original-title="Clear cart"><i class="ino ion-md-trash"></i></a></th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let product of products;let i=index; ">
                  <td class="p-2">
                     <div class="media align-items-center">
                        <img onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;'" class="img-fluid1 mx-auto d-block img-p" src="{{product.photos[0] && product.photos[0].signedGetURL}}" width="70px" height="70px" style="object-fit: contain;" />
                        <div class="media-body px-2">
                           <a href="#" class="d-block text-dark">{{product.productName}}</a>
                           <small>
                           </small>
                        </div>
                     </div>
                  </td>
                  <td class=" font-weight-semibold align-middle p-2">{{product.displayedSellingPrice}} MAD</td>
                  <td class="align-middle p-2">
                     <input type="text"  class="form-control text-center" (input)="changeQuantity(product,i)" [(ngModel)]
                     ="product.quantity" >
                  </td>
                  <td class=" font-weight-semibold align-middle p-2">{{product.quantity * product.displayedSellingPrice}} MAD</td>
                  <td class="align-middle p-2">
                     <input type="text" class="form-control text-center" (input)="changePrice()" value="{{products[i].finalPrice}}" [(ngModel)]="products[i].finalPrice" >
                  </td>
                  <td class="text-center align-middle px-2"><a (click)="removeProduct(i)" class="shop-tooltip close float-none text-danger" title="" data-original-title="Remove">×</a></td>
               </tr>
               <tr *ngIf="!loading && products.length ==0">
                  <td  colspan="5" class="text-center">
                     No cart info.
                  </td>
               </tr>
            </tbody>
            <tfoot>
               <tr>
                  <td colspan="3">Total</td>
                  
                  <td colspan="1">{{totalAmount}} MAD</td>
                  <td colspan="2">{{totalFinalSellingAmount}} MAD</td>
               </tr>
            </tfoot>
         </table>
      </div>
     
            <!-- / Shopping cart table -->
            <form  [formGroup]="orderForm"
            (ngSubmit)="saveForm()" class="d-flex flex-wrap small-picker pt-3" *ngIf="products && products.length > 0">
            <div class="col-md-12 m-auto  py-0" >
               <h5>Payment Details</h5>
              <div class="col-md-12 px-0 d-flex flex-wrap">
                 <div class="divBlk col-md-6" >
                    <label for="firstName"> First Name<span class="man-star text-danger">*</span></label>
                    <input type="text" maxlength="150"  id="firstName" formControlName="firstName" name="firstName" class="form-control custominp"/>
                    <div *ngIf="submitted && !f.firstName.value"  class="d-inline-block text-danger">
                     <div *ngIf="!f.firstName.value">
                        First name is required
                     </div>
                  </div>
                 </div>
                 <div class="divBlk col-md-6">
                    <label for="lastName"> Last name</label>
                    <input type="text" maxlength="150"  id="lastName" formControlName="lastName" name="lastName" class="form-control custominp"/>
                 </div>
                 <div class="divBlk col-md-6">
                    <label for="phone"> Phone Number<span class="man-star text-danger">*</span></label>
                    <input type="text" maxlength="150" id="phone"  formControlName="phone" name="phone" class="form-control custominp"/>
                    <div *ngIf="submitted && !f.phone.value"  class="d-inline-block text-danger">
                     <div *ngIf="!f.phone.value">
                       Phone is required
                     </div>
                  </div>
                 </div>
                 <div class="divBlk col-md-6">
                    <label for="email"> Email<span class="man-star text-danger d-none">*</span></label>
                    <input type="text" maxlength="150" id="email" formControlName="email" name="email" class="form-control custominp"/>
                    <!-- <div *ngIf="submitted && !f.email.value"  class="d-inline-block text-danger">
                     <div *ngIf="!f.email.value">
                        Email is required
                     </div>
                  </div> -->
                 </div>
                 <div class="divBlk col-md-6">
                    <label for="city">City<span class="man-star d-none">*</span></label>
                    <input type="text" maxlength="50" id="city" formControlName="city" name="city" class="form-control custominp"/>
                 </div>
                 <div class="divBlk col-md-6">
                    <label for="ringSize"> Size<span class="man-star d-none">*</span></label>
                    <input type="text" maxlength="50" id="ringSize" formControlName="ringSize" name="ringSize" class="form-control custominp"/>
                    <div *ngIf="submitted && !f.ringSize.value"  class="d-inline-block text-danger">
                     <div *ngIf="!f.ringSize.value">
                         Size is required
                     </div>
                  </div>
                 </div>
                 <div class="divBlk col-md-6">
                  <label for="color">Gold Color<span class="man-star d-none">*</span></label>
                  <select class="form-control" formControlName="goldColor">
                     <option value="">Select Color</option>
                     <option value="{{category}}" *ngFor="let category of goldColors">{{category}}</option>
                  </select>
                  <div *ngIf="submitted && !f.goldColor.value"  class="d-inline-block text-danger">
                     <div *ngIf="!f.goldColor.value">
                        Gold color is required
                     </div>
                  </div>
               </div>
                 <div class="divBlk col-md-6">
                    <label for="actualSellingPrice">Actual selling price (MAD)<span class="man-star d-none">*</span></label>
                    <input type="text" maxlength="50" id="actualSellingPrice" (input)="setFinalSellingPrice()" formControlName="actualSellingPrice" name="actualSellingPrice" class="form-control custominp"/>
                 </div>
                 <div class="divBlk col-md-6">
                  <label for="heldAmount">Avoir / Held Amount<span class="man-star d-none">*</span></label>
                  <input type="number" maxlength="50" id="heldAmount" (input)="updateAmoutCollect()" formControlName="heldAmount" name="heldAmount" class="form-control custominp"/>
               </div>
               <div class="divBlk col-md-6">
                  <label for="amountToCollect">Amount to be collected<span class="man-star d-none">*</span></label>
                  <input type="number" maxlength="50" id="amountToCollect" readonly formControlName="amountToCollect" name="amountToCollect" class="form-control custominp"/>
               </div>
                 <div class="divBlk col-md-6" >
                    <label for="goldColor"> Payment method<span class="man-star d-none">*</span></label>
                    <select class="form-control" formControlName="paymentMethod" (change)="changePaymentMethod($event)">
                       <option value="0">Select Payment Method</option>
                       <option value="{{paymentType.id}}" *ngFor="let paymentType of paymentTypes">{{paymentType.name}}</option>
                    </select>
                 </div>
                 <ng-container>
                    <div class="divBlk col-md-6" *ngIf="f.paymentMethod.value == 'CASH' || f.paymentMethod.value == 'CHEQUE_AND_CASH'">
                       <label for="cashAmount">Cash Amount<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="cashAmount" formControlName="cashAmount" name="cashAmount" class="form-control custominp"/>
                    </div>
                    <div class="divBlk col-md-6" *ngIf="f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH'">
                       <label for="numberOfCheques"> Number of cheques<span class="man-star d-none">*</span></label>
                       <select class="form-control" formControlName="numberOfCheques">
                          <option value="0">Select</option>
                          <option value="{{count}}" *ngFor="let count of chequestCount">{{count}}</option>
                       </select>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >0 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate1">  Cheque Date - 1<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate1"
                          formControlName="chequeDate1"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate1Amount">Cheque Amount - 1<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate1Amount" formControlName="chequeDate1Amount" name="chequeDate1Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >1 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate2">  Cheque Date - 2<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate2"
                          formControlName="chequeDate2"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate2Amount">Cheque Amount - 2<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate1Amount" formControlName="chequeDate2Amount" name="chequeDate2Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >2 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate3">  Cheque Date - 3<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate3"
                          formControlName="chequeDate3"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate3Amount">Cheque Amount - 3<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate3Amount" formControlName="chequeDate3Amount" name="chequeDate3Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >3 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate4">  Cheque Date - 4<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate4"
                          formControlName="chequeDate4"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate4Amount">Cheque Amount - 4<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate4Amount" formControlName="chequeDate4Amount" name="chequeDate4Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >4 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate5">  Cheque Date - 5<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate5"
                          formControlName="chequeDate5"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate5Amount">Cheque Amount - 5<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate5Amount" formControlName="chequeDate5Amount" name="chequeDate5Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >5 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate6">  Cheque Date - 6<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate6"
                          formControlName="chequeDate6"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate6Amount">Cheque Amount - 6<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate6Amount" formControlName="chequeDate6Amount" name="chequeDate6Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >6 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate7">  Cheque Date - 7<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate7"
                          formControlName="chequeDate7"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate7Amount">Cheque Amount - 7<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate7Amount" formControlName="chequeDate7Amount" name="chequeDate7Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >7 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate8">  Cheque Date - 8<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate8"
                          formControlName="chequeDate8"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate8Amount">Cheque Amount - 8<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate8Amount" formControlName="chequeDate8Amount" name="chequeDate8Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value >8 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate9">  Cheque Date - 9<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate9"
                          formControlName="chequeDate9"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate9Amount">Cheque Amount - 9<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate9Amount" formControlName="chequeDate9Amount" name="chequeDate9Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <ng-container *ngIf="f.numberOfCheques.value==10 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate10">  Cheque Date - 10<span class="man-star d-none">*</span></label>
                       <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                          <input
                          class=" form-control w-100"
                          type="text"
                          value=""
                          id="chequeDate10"
                          formControlName="chequeDate10"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          [placement]="placement"
                          [positionTarget]="buttonEl"
                          (click)="d2.toggle()"
                          />
                       </div>
                    </div>
                    <div class="divBlk col-md-6" >
                       <label for="chequeDate10Amount">Cheque Amount - 10<span class="man-star d-none">*</span></label>
                       <input type="text" maxlength="50" id="chequeDate10Amount" formControlName="chequeDate10Amount" name="chequeDate10Amount" class="form-control custominp"/>
                    </div>
                 </ng-container>
                 <div class="form-group col-md-6 ">
                    <label for="additionalDiscount">Additional Discount</label>
                    <input class="form-control" formControlName="additionalDiscount" (input)="setFinalSellingPrice()" name="additionalDiscount" id="additionalDiscount" type="text" value="" />
                    <div class="error text-danger"></div>
                 </div>
                 <div class="form-group col-md-6 ">
                    <label for="effectiveSalesPrice">Final Selling Price</label>
                    <input class="form-control" formControlName="effectiveSalesPrice" name="effectiveSalesPrice" id="effectiveSalesPrice" type="text" value="" (input)="checkFinalSellingPrice()" />
                    <div class="error text-danger"></div>
                 </div>
                 <div class="form-group col-md-6 ">
                  <label for="effectiveSalesPrice">Additional Information</label>
                  <textarea class="form-control" formControlName="additionalInformation" name="additionalInformation" id="additionalInformation" ></textarea>
                  <div class="error text-danger"></div>
               </div>
                 <div class="text-center col-md-6 m-auto ">
                    <button class="btn btn-primary cs-btn mx-1 mb-2 mb-md-0" id="buySubmit" type="submit">Proceed</button>
                    <button class="btn btn-primary cs-btn mx-1 mb-2 mb-md-0" (click)="clearCart()" type="button">Clear Cart</button>
                    <div>
                    </div>
                 </div>
              </div>
            </div>

          </form>
            
      </div>
   </div>
</div>
</div>
