import gql from 'graphql-tag';

export const membersBasicQuery = gql`
{
  users{
    userId
    firstName
    lastName
    email
    phone
    contactPresent
    role
    teams {
      teamId
      name
      memberIds
     
    }
    profile {
      displayImageURL
      jobTitle
    }
  }
}
`;
export const updateNewsletterMutation = gql `
mutation LHJRFLCWLE($value: String!,$key: String!){
LHJRFLCWLE(settings: [{key:$key,value:$value},{key : "CONTACT_SHARES_PROFILE",value : "true"},
{
  key : "CONTACT_UPDATE_PROFILE",
  value : "false"
},
{
  key : "MEETING_CREATES",
  value : "true"
},
{
  key : "MEETING_REMINDER",
  value : "true"
},
{
  key : "EMAILS_UPDATES_ABOUT_FEATURES",
  value : "true"
},
{
  key : "EXCHANGE_VCARD_BLUETOOTH",
  value : "true"
},
{
  key : "EMAILS_MANUAL_CONTACT_REQUESTS",
  value : "true"
},
{
  key : "COUNTRY_CODE",
  value : "IN"
} ]){
    key
    value
  }
}
`
export const accountInfo = gql`
query accountInformation{
  accountInformation{
    id
    email
    firstName
    lastName
    phone
    organizationName
    organizationAddress
    organizationWebsite
    organizationId
    role
    settings{
      key
      value
    }
    teams{
      teamId
      name
      memberIds
      managers
    }
    b2bPhone 
    organizationType
    zoomAccessToken
    zoomRefreshToken
    zoomAuthorizationToken 
    webexAccessToken
    webexRefreshToken
    webexAuthorizationToken
    contractStatus
    organizationPlan
    organizationUsers
    accountStatus
    permissions
    outlook
    outlookMultiSync
    googleSync
    googleMultiSync
    hubspotSync
    teamsSync
    salesforceSync
    twoFactor
  }
}`
export const updatePasswordMutation = gql `  
mutation MEELVPCWUZ($oldPassword:String!, $newPassword: String!){
  MEELVPCWUZ(oldPassword: $oldPassword, newPassword: $newPassword ){
    status
    description
    b2bStatus
  }
}`

export const updateMemberMutation = gql`
mutation QSGHAXEPKC( $firstName: String!,$lastName: String!, $email: String!, $phone: String!,$userId:ID!){
  QSGHAXEPKC(user: {firstName: $firstName, lastName: $lastName, email: $email, phone: $phone,userId:$userId}) {
    userId
    firstName
    lastName
    phone
    email
    teams {
      teamId
      name
      memberIds
    }
    
  }
}
`;
export const profilesQuery = gql`
  query{
    profiles(type:OFFICIAL){
      id
      type
      title
      displayImageURL
      displayImagePutURL
      data{
        key
        value
        title
        format
        admin
      }
      sharedWith
      latestVersion
      colorCode
      referralLink
      accessLevel
      encryptedId
      profileId
  }
}`

export const publicProfilesQuery = gql`
    query profiles($id:[ID],$type:[ProfileType]) {
    profiles(id:$id,type:$type){
      id
      type
      title
      displayImageURL
      displayImagePutURL
      data{
        key
        value
        title
        format
      }
      sharedWith
      latestVersion
      colorCode
      referralLink
      accessLevel
      encryptedId
      profileId
  }
}`


export const createProfileMemberMutution = gql`
mutation YFvPnwQbVm( 
    
    $data: [UserDataInput!]!,
  )
  {
    YFvPnwQbVm( profile:{
      colorCode: "#7273DB,#56AAFE",
      data: $data,
      type:OFFICIAL,
      title: "Official"

    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
        }
        colorCode
        referralLink
        profileId
    
  }
}
`

export const createPublicProfileMemberMutution = gql`
mutation YFvPnwQbVm( 
    $data: [UserDataInput!]!,
    $title: String!
  )
  {
    YFvPnwQbVm( profile:{
      colorCode: "#7273DB,#56AAFE",
      data: $data,
      type:CUSTOM,
	  title: $title

    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
        }
        colorCode
        referralLink
        profileId
    
  }
}
`
export const updateProfileMemberMutution = gql`
mutation dDJrSRUutB( 
    $data: [UserDataInput],
    $profileId:ID!,
    $title: String
  )
  {
    dDJrSRUutB( profile:{
     id:$profileId,
      colorCode: "#7273DB,#56AAFE",
      data: $data,
      title: $title
    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
        }
        colorCode
        referralLink
        profileId
    
  }
}`

export const updateZoomMutution = gql`
mutation PPIFPTYZTO( 
    $refreshToken: String,
    $authorizationToken:String,
    $accessToken: String
  )
  {
    PPIFPTYZTO( 
     refreshToken:$refreshToken,
     authorizationToken: $authorizationToken,
     accessToken: $accessToken,
    )
      {
        b2bStatus
        status
        description
    
  }
}`
export const updateWebexMutution = gql`
mutation TAUVLWHYAR( 
    $refreshToken: String,
    $authorizationToken:String,
    $accessToken: String
  )
  {
    TAUVLWHYAR( 
     refreshToken:$refreshToken,
     authorizationToken: $authorizationToken,
     accessToken: $accessToken,
    )
      {
        b2bStatus
        status
        description
    
  }
}`

export const updateSyncSettingsMutation = gql`
mutation NCIYUUFGJF( 
  $serviceProvider: ServiceProviderType!,
  $syncStatus: SyncStatusType,
  $credentials: Credentials,
  $attachment: Boolean
)
{
  NCIYUUFGJF( settings:{
    serviceProvider: $serviceProvider,
  syncStatus: $syncStatus,
  credentials: $credentials,
  attachment: $attachment
  
  }
   
  )
    {
    serviceProvider
    syncStatus
    username
    accessToken
    refreshToken
    multiSync
    resourceId
    channelId
    calendar
    contact
      
  }
}`

export const syncAllFieldsMutation = gql`
mutation ZCUJNCFIBD( 
  $mappedData: String!,
 
)
{
  ZCUJNCFIBD( mappedData:$mappedData)
}`

export const syncSettings= gql`
query{
  syncSettings{
    serviceProvider
    syncStatus
    username
    accessToken
    refreshToken
    multiSync
    channelId
    resourceId
    calendar
    contact
  }
}`

export const accountAnalyticsQuery = gql`
query{
  accountAnalytics{
    userId
    fullName
    contactCount
    spaceInKb
    spacePercentage
    mostUsedFeature
  }
}`


export const publicProfileQuery = gql`
query publicProfile($id:ID){
  publicProfile(id:$id){
    profileId
    username
    fullName
    userId
    publicLink
    status
    description
  }
}`

export const performanceAnalyticsQuery = gql`
query performanceAnalytics($limit:Int, $sortByName:SortOrder, $userIds :[ID],$all:Boolean,$member:Boolean) {
  performanceAnalytics(limit:$limit,sortByName:$sortByName,userIds: $userIds,all:$all, member:$member){
    userId
    companyName
    numberofMeetings
    numberOfContacts
    lastMeetingTitle
    lastMeetingStartTime
    lastMeetingEndTime
    lastMeetingId
    lastMeetingLocation
    lastEventParticipants {
      userId
      firstName
      lastName
      nonAppId
    }
    connectionsCount
    connectionIds
  }
}`



export const accountPermissionsInfo = gql`
query accountInformation{
  accountInformation{
    id
    permissions
    role
    twoFactor
    organizationId
    cart
    teams{
      teamId
      managers
      memberIds
    }
  }
}`

export const updateOrganizationMutation = gql `  
mutation AVOZDXMLVP($outlookMultiSync:Boolean, $googleMultiSync: Boolean){
    AVOZDXMLVP(org: {outlookMultiSync: $outlookMultiSync, googleMultiSync: $googleMultiSync}) 
}`

export const updateMultiFactorMutation = gql `  
mutation KGYQIJQTWB($activate:Boolean){
  KGYQIJQTWB(activate: $activate){
    id
    twoFactor
  }
}`

export const appUserConnectionsMutation = gql `  
query appUserConnections($company:String!,$userIds:[ID],$meetingWith:ID){
  appUserConnections(company: $company,userIds:$userIds,meetingWith:$meetingWith){
    connectionCount
    meetingCount
    connectionIds
    firstName
    lastName
    userId
    teams
    teamIds
    profiles {
      profileId
      title
      displayImageURL
      data {
        title
        key
        value
        format
      }
  }
  company
   lastMeetingId
    lastMeetingTitle
    lastMeetingStartTime
    lastMeetingEndTime
    lastEventParticipants{
      userId
      nonAppId
      firstName
      lastName
    }
    lastMeetingLocation
}
}`
export const nonAppConnectionsMutation = gql `  
query nonAppConnections($company:String!,$nonAppIds:[ID],$meetingWith:ID){
  nonAppConnections(company: $company,nonAppIds:$nonAppIds,meetingWith:$meetingWith){
    connectionCount
    meetingCount
    connectionIds
    firstName
    lastName
    nonAppId
    lastMeetingId
    lastMeetingTitle
    lastMeetingStartTime
    lastMeetingEndTime
    lastEventParticipants{
      userId
      nonAppId
      firstName
      lastName
    }
    lastMeetingLocation
    data{
      key
      value
      title
      format
    }
}
}`
export const setPublicProfileMutution = gql`
mutation gOGvJiJdYl( 
    $profileId: ID!,
    $username:String!,
    $status: String,
    $description:String,
    $userId:ID
  )
  {
    gOGvJiJdYl( userId:$userId,publicProfile:{
      profileId:$profileId
      username:$username
      description :$description 
      status:$status
    }
    )
      {
        profileId
        username
        fullName
        userId
        publicLink
        status
        description
    
  }
}`


export const generateBranchLink = gql`
mutation kbtsEXRVpv($profileId: ID!)
  {
    kbtsEXRVpv(profileId:$profileId)
      {
        userId
        profileId
        link    
  }
}`