import { Action } from '@ngrx/store';

export enum ProfileActionTypes {
  PROFILE_UPDATE = '[Profle] Update',
}

export class ProfileUpdate implements Action {
  readonly type = ProfileActionTypes.PROFILE_UPDATE;
  constructor(public payload: any) {}
}


export type All =
  | ProfileUpdate
