import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgxGalleryOptions} from 'ngx-gallery';
import {NgxGalleryImage} from 'ngx-gallery';
import {NgxGalleryAnimation} from 'ngx-gallery';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Apollo, QueryRef } from 'apollo-angular'
import {productsQuery} from '../../../services/products.gql'
import { param } from 'jquery';
import { HelperService } from '../../../services/helper.service'
const moment = require('moment-timezone')

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  isUserAllow:boolean = true

  productId:any;
  private query2: QueryRef<any>
  permissionsRes:any ={}
    productData:any=[];
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    loading:boolean = true
    productInfo:any 
    routeType =1
    page:number =1
    column:any={}
    role:any =''
    columns:any
    accountId:any
    filterByColumn:any
    filterColumn :any
    filterColumnValue:any
    minValue:any
    maxValue:any
    searchText:any
    itemsPerPage:any
    constructor(private activatedRoute: ActivatedRoute,private cdr: ChangeDetectorRef,private router: Router,   private apollo: Apollo,private helperService:HelperService) { 

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.productId=params.id;
        this.productDetails()
      }
      if(params.type){
        this.routeType = params.type
      }
      if(params.page){
        this.page = params.page
      }
    })
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
    //  console.log(data.accountInformation.role,'data')
      this.role = data.accountInformation.role 
      this.accountId = data.accountInformation.id
      if(this.role == 'SALES'){
        this.isUserAllow = false
        if(this.checkUserIds()){
          this.isUserAllow = true
        }
       }
    })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {maxValue,minValue,filterColumnValue,filterColumn,columns,filterByColumn,searchText,itemsPerPage} = this.router.getCurrentNavigation().extras.state
      // console.log(this.router.getCurrentNavigation().extras.state,'this.router.getCurrentNavigation().extras.state')
      this.column=columns
      this.filterByColumn =filterByColumn
      this.filterColumn = filterColumn
       this.filterColumnValue = filterColumnValue
       this.minValue=minValue?minValue:0
       this.maxValue=maxValue?maxValue:0
       this.searchText = searchText
       this.itemsPerPage = itemsPerPage
      }
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

   
  }
  public productDetails()
  {
      this.loading = true
      this.query2 = this.apollo.watchQuery({
        query: productsQuery,
        fetchPolicy:'network-only',
        variables:{
          id:this.productId
        }
      })
  
       this.query2.valueChanges.subscribe(({ data }: any) => {
         this.loading = false
       this.productInfo = data.products[0]
       this.galleryImages = this.productInfo.photos.map((media)=>{
        return {
          small: media.signedGetURL,
          medium: media.signedGetURL,
          big: media.signedGetURL
        }
    })
    if(this.galleryImages.length ==0){
      this.galleryImages.push({
        small: 'assets/product.png',
        medium: 'assets/product.png',
        big: 'assets/product.png'
      })
    }
    // console.log(this.galleryImages,'galleryImages')

      },err=>{
        this.loading = false
      })
    
  }                                                   
  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }
 deleteProduct(){
    if(confirm("Are you sure do you want to delete this product?"))
    {
        // this.productService.deleteVariant(this.productId).subscribe((data: any)=>{
        //   if(data.status == 'success')
        //   {
        //     alert("Product deleted successfully. ")
        //     this.router.navigate(['/admin/product-list'])
        //   }
        // })
    }
  }
  round(number){
    return Math.round(number)
      }
      fixedTo(number){
        return number ?number.toFixed(3) : number
      }
      roundToNear(number){
        return Math.ceil(number/100)*100

    
      }
      pageBack(page){
        // if( this.column && this.column.sortType!=''){
        //   if(this.column.sortType == 'DESC'){
        //     this.column.sortType = 'ASC'
        //   }else{
        //     this.column.sortType = 'DESC'
        //   }
          
        // }
        console.log( {
          columns: this.column,
          searchText:this.searchText,
          filterByColumn:this.filterByColumn,
          filterColumn:this.filterColumn,
          filterColumnValue : this.filterColumnValue,
          minValue : this.minValue,
          maxValue : this.maxValue
        })
        this.router.navigate(['/products',page], {
          state: {
            columns: this.column,
            searchText:this.searchText,
            filterByColumn:this.filterByColumn,
            filterColumn:this.filterColumn,
            filterColumnValue : this.filterColumnValue,
            minValue : this.minValue,
            maxValue : this.maxValue,
            itemsPerPage:this.itemsPerPage
          }
        })
      }
      
       checkUserIds(){
    return [
       "160721",
       "160875",
       "160873",
       "160720",
       "160731","158530"].includes(this.accountId)
   }
}
