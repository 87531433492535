import { Component, OnInit,OnDestroy,ChangeDetectionStrategy,Input,ChangeDetectorRef,ViewRef } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'

import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr'

import {
  customersQuery,customersOnlyIdQuery,deleteCustomerMutution,updateProductMutution,updateCustomerMutution
} from '../../../services/products.gql'

import { TranslateService } from '@ngx-translate/core'
import { HelperService } from '../../../services/helper.service'
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../../environments/environment'
import { MoveOrderToSalesComponent } from './move-order-to-sales/move-order-to-sales.component'
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
const moment = require('moment-timezone')


interface IServerResponse {
  items: string[];
  total: number;
}
@Component({
  selector: 'app-products-enquiries',
  templateUrl: './products-enquiries.component.html',
  styleUrls: ['./products-enquiries.component.scss']
})
export class ProductsEnquiriesComponent implements OnInit {

  @Input('data') meals: string[] = [];
  asyncMeals: Observable<string[]>;
    isUserAllow:boolean = true

  p: number ;
  total: number;
  cPage: number = 1;
  role:any=""
  columnType:any
  private query: QueryRef<any>
  private queryAll: QueryRef<any>
  private query$: any
  private queryTm$: any
  searchText:any =""
  isFilter:boolean = false
  
  public teams: any = []
  submitted1: boolean = false
  loading: boolean = true
  public isOpen = false
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  selectedCategory:any = 'ACTIVE'
  collection = [];
  customers:any = []
  allCustomers:any = []
  totalItems:any
  page:any =1
  userId:any
  selectedType:any =1
  orgId:any=''
  itemsPerPage = 10;
  selectAll:boolean= false
  showCheckboxes:boolean = false
selectedOrderIds:any =[]
actionValue:any = 0
filterByColumn:any
  filterColumnValue:any
  timeout: any = null;
  selectedOrders:any =[]
supplierInfo:any
  constructor (
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    private helperService:HelperService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {
    
    this.isAdmin = this.helperService.isAdmin()
    this.userId = localStorage.getItem("id")
    
    this.setColumType()
   
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {filterByColumn,filterColumnValue,searchText,from,supplierName} = this.router.getCurrentNavigation().extras.state
      if(from == 'suppliers-list'){
        this.filterByColumn = "supplier_name"
        this.filterColumnValue = supplierName
        this.supplierInfo ={
          from,
          supplierName
        }
      }
      else{
        if(searchText){
          this.searchText = searchText
        }
        if(filterByColumn && filterColumnValue){
          this.filterByColumn = filterByColumn
          this.filterColumnValue = filterColumnValue
        }
      }
      

    }
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
      
      }
      if(params.page){
        this.page = params.page
        this.p = params.page
      }
      if(params.type){
        this.selectedType = params.type
      }
      this.getPage(this.page)
      window.history.pushState({}, document.title, "/product-enquires" );

    })
  }

  ngOnInit () {
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
       this.permissions = this.helperService.getPermissions(data)
       this.userId = data.accountInformation.id
       this.orgId = data.accountInformation.organizationId
       this.role = data.accountInformation.role 
       if(this.role == 'SALES'){
        this.isUserAllow = false
        if(this.checkUserIds()){
          this.isUserAllow = true
        }
       }
      //  alert(this.role)

    })
  }
  getAllData() {
    const url = `https://api.instantwebtools.net/v1/passenger?page=${0}&size=${this.itemsPerPage}`;
  this.http.get(url).subscribe((data: any) => {
    this.loading= false
    this.collection =  data;
    this.totalItems = data.totalPassengers;
    this.customers = data.data
  })
}
viewOrder(id){
  this.router.navigate(['/order-details',id,this.page], {
    state: {
      searchText:this.searchText,
      filterByColumn:this.filterByColumn,
      filterColumnValue : this.filterColumnValue,
     
    }
  })
}

getAllCustomers () {
    this.queryAll = this.apollo.watchQuery({
      query: customersOnlyIdQuery  ,
      variables:{
        actionType: this.selectedType == 1 ? 'BUY' : 'ORDER'
      },
      fetchPolicy:"no-cache"
    })

     this.queryAll.valueChanges.subscribe(({ data }: any) => {
      this.totalItems = data.customers.length
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
        }
    })
  }
 
  

  getPage(page) {
    this.loading = true
    this.page = page
    this.p = this.page
    // this.cd.detectChanges();
    // console.log(this.filterByColumn,this.filterColumnValue)
    let obj:any ={
      limit:this.itemsPerPage,
      offset:(this.page*this.itemsPerPage)-this.itemsPerPage,
      actionType: this.selectedType == 1 ? 'BUY' : 'ORDER'
    }
    if(this.filterColumnValue && this.filterByColumn){
      
      if(this.filterByColumn == 'taj_number' || this.filterByColumn == 'displayed_selling_price' || this.filterByColumn =='product_name' || this.filterByColumn =='category' || this.filterByColumn =='supplier_name'){
        obj.filterByProductColumn =  this.filterByColumn
        obj.filterByProductColumnValue = this.filterColumnValue ? this.filterColumnValue.trim() : ''
      }
     else if(this.filterByColumn == 'updated_at'){
        // obj.filterByProductColumn =  this.filterByColumn
        let data =  moment(this.filterColumnValue,'DD-MM-YYYY').format('YYYY-MM-DD HH:mm')
        obj.startDate = moment.tz(data, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')

        obj.endDate = moment.tz(data, moment.tz.guess()).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      }
      else{
        obj.filterColumnValue = this.filterColumnValue ? this.filterColumnValue.trim() : ''
      obj.filterByColumn = this.filterByColumn

      }



      if(this.filterByColumn == 'remaining_due_amount' || this.filterByColumn == 'advance_amount' ||this.filterByColumn == 'actual_selling_price'){
        // obj.filterColumnValue =  parseFloat(obj.filterColumnValue)
      }
    }
    if(this.searchText ){
      obj.search = this.searchText ? this.searchText.trim() : ''
    
    }
    this.query = this.apollo.watchQuery({
      query:   customersQuery  ,
      fetchPolicy:"network-only",
      variables:obj
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      data.customers = data.customers.map((item:any)=>{
		item ={
		 ...item,
		 paymentMethodValue:item.paymentMethod ? item.paymentMethod.split('_').join(' ') : item.paymentMethod
		}
		return item  
	  })
      this.customers = data.customers
      if(this.customers.length > 0){
        this.totalItems = data.customers[0].totalCustomersCount
      }
      else{
        this.totalItems =0
      }
      this.loading = false
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
        }
    
    },err=>{
      console.log(err)
      this.loading = false
    })
  }

  confirmDelete(customer)
  {
      let textMsg:any = "Do you want to delete this order?"
      if (!confirm(textMsg)) {
        return false
      }
      else {
      this.removeOrder(customer) 
      }
  }
  showOrders(type){ 
    this.selectedType = type
    this.setColumType()
    this.getPage(1)
  }
  exportCustomers(){
    if(this.orgId){
      let getText = ``
      if(this.selectedOrderIds.length > 0){
          let oderIds = this.selectedOrderIds.toString()
          getText = `?orderIds=${oderIds}`
      }
      if(this.selectedType == 2){
        window.location.href=`${environment.AUTH_URL}/exportCustomer/${this.orgId}/ORDER${getText}`
      }
      else{
        window.location.href=`${environment.AUTH_URL}/exportCustomer/${this.orgId}/BUY${getText}`
      }
    }
   // this.actionValue = 0
 
  }


  removeOrder(customer,bulk=false,actionType=''){
    let obj = {

    }
    if(bulk){
     if(this.selectedOrderIds.length > 0){
      obj = {
        ids: this.selectedOrderIds,
      }
    }
      else{
        alert("Please select atleast one order.");
       }
    }
    else{
      obj = {
        ids: customer.id,
      }
    }
    this.apollo
      .mutate({
        mutation: deleteCustomerMutution,
        variables: obj
      })
      .subscribe(({ data }: any) => {
      console.log(data)
        if(data.deleteCustomer){
        this.toastr.info("order deleted successufully.")
        if(customer.actionType =="BUY" || actionType =='BUY'){
          if(bulk){
          
            this.selectedOrders.forEach(element => {
              this.updateProduct(element.product)  
            });
          }
          else{
            this.updateProduct(customer.product)
          }
        }
        this.getPage(1)
        if(bulk){
          this.totalItems = this.totalItems -this.selectedOrderIds.length
        }
        else{
          this.totalItems = this.totalItems -1
        }

		  this.selectedOrderIds =[]
		  this.selectedOrders =[]
      this.selectAll = false
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
		  this.cd.detectChanges();
        }
        }
        else{
        
          alert("You don't have access to delete this order.")
        }
        
      }, error => {
       alert("You don't have access to delete this order.")
          console.log(error)
          this.loading= false
        })
  } 
  deleteOrder(customer){
      this.loading = true
      let textMsg:any = "Are you sure do you want to delete the order?"
      if (!confirm(textMsg)) {
        return false
      }
      else {
        this.removeOrder(customer) 
      }
  }


  updateProduct (product) {
    this.loading = true
    let productObj:any ={
      stockQuantity: parseInt(product.stockQuantity)+1,
      id: product.id,
      category:product.productCategory.name
    }
    this.apollo
      .mutate({
        mutation: updateProductMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
        },
        error => {
          console.log(error)
          this.loading= false
        }
      )
  }
  selectAction(event){
    let selectVal = event.target.value
    // alert(event.target.value)
    if(selectVal==1)
    {
      this.showCheckboxes=true
    }
    else if(selectVal==2)
    {
      this.checkUncheckAll(true)
    }
    else if(selectVal==3)
    {
      this.checkUncheckAll(false)
    }
    else if(selectVal==6)
    {
      this.showCheckboxes=false
      this.resetOrders()
    }
    else if(selectVal==7)
    {
      this.showCheckboxes=true
    }
    else if(selectVal==4)
    {

      let textMsg:any = "Are you sure do you want to delete the orders?"
      if (!confirm(textMsg)) {
        return false
      }
      else {
        let actionType = this.selectedType == 1 ? 'BUY' : 'ORDER'
        this.removeOrder(this.selectedOrderIds,true,actionType) 
      }

    }
    else if(selectVal==5)
    {
      this.exportCustomers()
      this.showCheckboxes = false
      this.resetOrders()
	  setTimeout(()=>{
		this.actionValue = 0	 
	  },1000)
    }
    

  }
  checkUncheckAll (ev) {
    this.selectedOrderIds = []
    this.selectedOrders =[]
    this.customers.length > 0 &&
      this.customers.forEach((x, i) => {
        x.isSelected = ev
        if (ev) {
          x.isSelected = ev
          this.selectedOrderIds.push(x.id)
          this.selectedOrders.push(x)
        }
        
      })
      this.selectAll = !this.selectAll


  }
  isAllChecked () {
    return this.customers.every(item => item.isSelected)
  }
  selectCheckbox(ev,customer,index){
    if (ev.target.checked) {
      this.selectedOrderIds.push(customer.id)
      this.selectedOrders.push(customer)
    }
    else{
      this.selectedOrderIds.splice(index,1)
      this.selectedOrders.push(customer)
    }
  }
  resetOrders(){
    this.selectedOrderIds = []
    this.selectAll = false
    this.customers.forEach((x, i) => {
      this.customers[i].isSelected = false
    });
  }
  filterOrders(){
    // console.log(this.filterColumnValue,'this.filterColumnValue')
    // console.log(this.filterByColumn,'this.filterColumnValue')
    clearTimeout(this.timeout);
    this.timeout = setTimeout( () =>{
      if(this.filterColumnValue && this.filterByColumn){
        this.getPage(this.page)
      }
    },1000)
  }
  filterBy(event){
    this.filterByColumn = event.target.value
  }
  clearFilterData(){
   
    this.filterColumnValue ="" 
    this.filterByColumn =undefined
    this.searchText =''
    this.isFilter = false
    this.getPage(1)

  }
  setColumType(){
    this.columnType =[
      {
        name:"First Name",
        filterName:"first_name",
      },
      {
        name:"Last name",
        filterName:"last_name",
      },
      {
        name:"Email",
        filterName:"email",
      },
      {
        name:"Phone Number",
        filterName:"phone",
      },
      {
        name:"City",
        filterName:"city",
      },{
        name:"Size",
        filterName:"ring_size",
      },
      {
        name:"Actual selling price (MAD)",
        filterName:"actual_selling_price",
      },{
        name:"Payment method",
        filterName:"payment_method",
      },
      {
        name:"Advance amount in MAD",
        filterName:"advance_amount",
      },
      {
        name:"Implied remaining amount due",
        filterName:"remaining_due_amount",
      },
      {
        name:"Description",
        filterName:"description",
      },
      {
        name:this.selectedType == 1 ? 'Sale number' : 'Order number',
        filterName:"customer_order_number",
      },
      {
        name:"Taj number",
        filterName:"taj_number",
      },
      {
        name:"Gold color",
        filterName:"gold_color",
      },
      {
        name:"Displayed Selling Price	",
        filterName:"displayed_selling_price",
      },
      {
        name:"Product Category",
        filterName:"category",
      },
      {
      name:"Product Name",
      filterName:"product_name",
     },
     {
       name:"Date",
       filterName:"updated_at"
     },
     {
      name:"Additional Information",
      filterName:"additional_information"
      },
      {
        name:"Supplier name",
        filterName:"supplier_name"
        }
    ]
  }
  clearFilter(){
    this.searchText = ""
    this.isFilter= false
    this.page=1 
    this.getPage(1)
  }
  searchOrders(eve){
    
    this.searchText = ""
    this.page=1
    clearTimeout(this.timeout);
    this.timeout = setTimeout( () =>{
    if(eve.target.value.length >0){
      let searchText = eve.target.value
      this.searchText = searchText
      this.getPage(this.page)
      // this.getAllProducts()
      
    }else if(eve.target.value==0){
     
      this.getPage(1)
      this.isFilter=false
    }
  },1000)
  }
  gotoProduct(id){
    // alert(this.page)
    
    
    this.router.navigate(['/update-order',id,this.page], {
      state: {
        searchText:this.searchText,
        filterByColumn:this.filterByColumn,
        filterColumnValue : this.filterColumnValue,
        
      }
    })
  }
  moveToSale(customer){
    let modalRef = this.modalService.open(MoveOrderToSalesComponent, { windowClass: 'move-modal' });
    modalRef.componentInstance.propsData = {
      "customer":customer
    }

    modalRef.result.then((reason) => {
      if(reason == 1){
        this.selectedType = 1
        this.getPage(1)
        this.toastr.info("Successfully move to the sales.")
      }
      //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       
     })
    // this.updateCustomer (customer)
  }
  updateCustomer (customer) {
    let {id, productId,firstName } = customer
    let obj = {
      id,
      productId,
      actionType:"BUY",
      firstName
    }
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateCustomerMutution,
        variables: obj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          this.toastr.info("Successfully move to the sales.")
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }

  getDisplayedSellingPrice(customer){
    let purchasedProducts = customer.purchasedProducts
    let cart = customer.cart
    let cartArr = []
    if(purchasedProducts && purchasedProducts.length > 0){
      if(cart){
        let cartString = cart.replace(/'/g, '"')
         cartString = JSON.parse(cartString)
         cartArr= cartString
      }
      
      let count = 0
      purchasedProducts.forEach((item,i)=>{
        let currendElement = cartArr.find(element => element.pid == item.id)
        let quantity = (currendElement && currendElement.qty) ? currendElement.qty :1
        count = count + (item.displayedSellingPrice * parseInt(quantity))
      })
      return count
    }
    // console.log(customer.product.displayedSellingPrice)

    return customer.product.displayedSellingPrice
   
    
  }
   checkUserIds(){
    return [
       "160721",
       "160875",
       "160873",
       "160720",
       "160731","158530"].includes(this.userId)
   }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : ""; 
    this.queryTm$ ?this.queryTm$.unsubscribe() : "" ;
  }

}
