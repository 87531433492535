<!-- <app-user-header></app-user-header> -->
<!-- <div class="grayBg container-cs aboutBlk ">
   <h2 class="font-weight-bold pb-3 px-2" *ngIf="isAuthenticated"> {{ title  | translate}}</h2>
   <app-sub-menu *ngIf="isAuthenticated"></app-sub-menu>
   <div class="px-3">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
   </div>
</div> -->
<!-- <app-user-footer></app-user-footer> -->

 <!-- Page Wrapper -->
 <div id="wrapper">

   <!-- Sidebar -->
   <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"  *ngIf="isAuthenticated"
   [ngClass]="{'toggled': sidebarToggle, 'mobile-nav': toggle }"  id="accordionSidebar">

     <!-- Sidebar - Brand -->
     <a class="sidebar-brand d-flex align-items-center justify-content-center" target="_blank" href="https://www.staytouch.com/">
       
       <div class="sidebar-brand-icon">
        <img src="assets/small_logo.svg" alt="Logo" width="70px" />
<!--
         <i class="fas fa-laugh-wink"></i>
-->
       </div>
       <div class="sidebar-brand-text mx-3">
        <img src="assets/logo.svg?x=1" alt="Logo" width="200" height="auto"/>
        
      </div>
     </a>
     
     <!-- Nav Item - Pages Collapse Menu -->
     <li class="nav-item" *ngIf="userRole != 'MEMBER' && (userRole == 'MANAGER' || userRole == 'ADMIN' ||  (permissions && (permissions.company.create_teams_and_departments == 'true' || permissions.company.add_members_to_teams == 'true')))">
       <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages1" aria-expanded="true" aria-controls="collapsePages">
         <i class="fas fa-fw fa-users"></i>
        <span *ngIf="userRole == 'ADMIN' || isAdmin"> Admin Console</span>
        <span *ngIf="userRole == 'MANAGER'"> Manager Console</span>
       </a>
       <div id="collapsePages1" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
         <div class="bg-white py-2 collapse-inner rounded">
          
           <a class="collapse-item " [routerLink]="['/teams']"
           routerLinkActive="active-link" *ngIf="isAdmin || (permissions && (permissions.company.create_teams_and_departments == 'true' ))">Internal Team 
          </a>
           <a class="collapse-item "  routerLinkActive="active-link"
           [routerLink]="['/members']"
           [class.active-link-m]="
             isLinkActive(['/create-member', '/official-profile','/view-profile','/view-member'])
            " *ngIf="isAdmin || (permissions && (permissions.company.add_members_to_teams == 'true'))">Team Members 
            </a>
         </div>
       </div>
     </li>
   
     <hr class="sidebar-divider d-none d-md-block">
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item meetings-menu" >
       <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages-p" aria-expanded="true" aria-controls="collapsePages">
         <i class="fas fa-fw fa-list-alt"></i>
         <span> Products	</span>
       </a>
       <div id="collapsePages-p" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
         <div class="bg-white py-2 collapse-inner rounded">
          <a class="collapse-item "   [routerLink]="['/products']" routerLinkActive="active-link" >
            <span> Products List</span>
           </a>
           <a class="collapse-item "   [routerLink]="['/archive-products']" routerLinkActive="active-link" >
            <span> Archived Products</span>
           </a>
           <a class="collapse-item "   [routerLink]="['/product-enquires']" routerLinkActive="active-link" >
            <span>Product Enquiries</span>
           </a>
           <a class="collapse-item "   [routerLink]="['/categories']" routerLinkActive="active-link" >
            <span>Categories</span>
           </a>
           
          </div>
       </div>
     </li>
     <hr class="sidebar-divider d-none d-md-block">
     <li class="nav-item meetings-menu"  *ngIf="isUserAllow">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages-s" aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-list-alt"></i>
        <span> Suppliers	</span>
      </a>
      <div id="collapsePages-s" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
         <a class="collapse-item "   [routerLink]="['/suppliers-list']" routerLinkActive="active-link" >
           <span>  Supplier List</span>
          </a>
          <a class="collapse-item "   [routerLink]="['/orders-analytics']" routerLinkActive="active-link" >
           <span> Orders Analytics</span>
          </a>
         
          
         </div>
      </div>
    </li>
    <hr class="sidebar-divider d-none d-md-block">
   <!-- Divider -->
   <li class="nav-item nav-item-cs">
    <a class="nav-link"   [routerLink]="['/search']" >
      <i class="fas fa-fw fa-shopping-cart"></i>
      <span> Cart </span></a>
  </li>

     <hr class="sidebar-divider d-none d-md-block">
     <li class="nav-item nav-item-cs">
      <a class="nav-link"   [routerLink]="['/update-account']" [class.active-link-a]="
      isLinkActive([
        '/update-account',
        '/reset-password',
        '/my-profile',
        '/my-teams',
        '/merge-account',
        '/zoom-connect'
      ])
    ">
        <i class="fas fa-fw fa-user-circle"></i>
        <span> {{ 'HEADER.ACCOUNT' | translate }} </span></a>
    </li>
 
     <!-- Sidebar Toggler (Sidebar) -->
     <div class="text-center d-none d-md-inline">
       <button class="rounded-circle border-0" id="sidebarToggle" (click)="sidebarToggle=!sidebarToggle"></button>
     </div>

   </ul>
   <!-- End of Sidebar -->

   <!-- Content Wrapper -->
   <div id="content-wrapper" class="d-flex flex-column" *ngIf="isAuthenticated" [ngClass]="sidebarToggle? 'halfWd':'fullWd' "  #target>

     <!-- Main Content -->
     <div id="content">

       <!-- Topbar -->
       <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

         <!-- Sidebar Toggle (Topbar) -->
        
   

       <app-new-header class="w-100" [accountInfo]="accountInfo" [toggle]="toggle"  (toggleChanged)="toggleChanged($event)"></app-new-header>
       </nav>

       <div class="px-md-4 px-3 w-100 flex-1" style="    padding-top: 4.375rem;">
        <h3 class=" pt-4 px-0 text-gray mb-0" *ngIf="isAuthenticated"> {{ title  | translate}}

          <small *ngIf="idtype=='e'" placement="bottom"  ngbTooltip="Create private meetings. You can also sync your meetings you create here with other calendars. Go to your account and check Syncrhonizations"  tooltipClass="my-custom-class"> <i class="fa fa-info-circle" aria-hidden="true"   ></i></small>
          <small *ngIf="idtype=='c'" placement="bottom"  ngbTooltip="Open conference are meetings or conferences which allows wider audience and any one can join with a meeting ID. Private meetings are used for secure meetings which can be accessed only by approval from the host" class="px-2 " tooltipClass="my-custom-class"> <i class="fa fa-info-circle" aria-hidden="true"   ></i></small>
          <small *ngIf="idtype=='t'" placement="bottom"  ngbTooltip="You can create different teams and group your internal team members accordingly" class="px-2 " tooltipClass="my-custom-class"> <i class="fa fa-info-circle" aria-hidden="true"   ></i></small>
          <small *ngIf="idtype=='m'" placement="bottom"  ngbTooltip="Add your corproate team and invite them to join corporate account. You can see here all members of your company. It is corproate facebook. You can also connect with a fellow member using connect function next to the member"> <i class="fa fa-info-circle" aria-hidden="true"   ></i></small>
        </h3>
       
        <router-outlet (activate)="onActivate($event)" [permissions]="permissions"></router-outlet>
      </div>
        <!-- Footer -->
      <footer class="sticky-footer bg-white">
         <div class="container my-auto">
           <div class="copyright text-center my-auto">
             <span>© <span>{{crYear | date: 'yyyy'}}</span>, StayTouch, GG Technologies Inc. All Rights Reserved.</span>
           </div>
         </div>
       </footer>
       <!-- End of Footer -->
 
     </div>
     <!-- End of Content Wrapper -->
 
   </div>
   <div class="w-100 d-flex align-items-center grayBg"  *ngIf="!isAuthenticated">
     <div class="w-100 ">
      <router-outlet (activate)="onActivate($event)" ></router-outlet>
     </div>
</div>
