import { Component, OnInit } from '@angular/core'
// import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input'
import { environment } from '../../../../environments/environment'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  forgotForm: FormGroup
  errorMessage: string | null
  withEmail: boolean = true
  separateDialCode = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  submitted: boolean = false

  constructor (
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit () {
    this.forgotForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        otpCode: ['', Validators.required]
      },
      {
        validator: this.MustMatch('newPassword', 'confirmPassword')
      }
    )
  }

  get f () {
    return this.forgotForm.controls
  }
  MustMatch (controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }
  fogotPassword () {
    this.submitted = true
    // stop here if form is invalid

    if (this.forgotForm.invalid) {
      return
    }

    let userInfo: any = {
      code: this.f.otpCode.value,
      password: this.f.newPassword.value,
      token: environment.AUTH_TOKEN
    }
    if (localStorage.getItem('forgotPhone')) {
      userInfo.phoneNumber = localStorage.getItem('forgotPhone')
    } else if (localStorage.getItem('forgotEmail')) {
      userInfo.email = localStorage.getItem('forgotEmail')
    } else {
      this.router.navigate(['/forgot-password'])
    }
    let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			   'xsecuritytoken': environment.xsecuritytoken,
			  'xframetoken': environment.xframetoken
			}
		  }
		}
		//forgotPassword
    this.http
      .post<any>(`${environment.AUTH_URL}/b50bafc56c5b9d279ad2c2d3da7c40c7`, userInfo,headers)
      .subscribe(
        data => {
          //console.log(data, 'data')
          if (data.status == 'OK') {
            this.toastr.info(
              'Password updated successfuly. Please login to continue.',
              null,
              {
                closeButton: false
              }
            )
            this.router.navigate(['/login'])
            window.localStorage.removeItem('forgotEmail')
          } else {
            //console.log('coming')
            this.toastr.info('Please enter valid OTP.', null, {
              closeButton: false
            })
          }
        },
        error => {
          //console.log('there was an error sending the query', error)
          if (error == 'INVALID_AUTHORIZATION') {
          }
        }
      )
  }

  resendCode () {
    let userInfo: any = {
      token: environment.AUTH_TOKEN,
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
    }
    if (localStorage.getItem('forgotPhone')) {
      userInfo.phoneNumber = localStorage.getItem('forgotPhone')
    } else if (localStorage.getItem('forgotEmail')) {
      userInfo.email = localStorage.getItem('forgotEmail')
    } else {
      this.router.navigate(['/forgot-password'])
    }
 let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			   'xsecuritytoken': environment.xsecuritytoken,
			  'xframetoken': environment.xframetoken
			}
		  }
		}
		//forgot
    this.http
      .post<any>(`${environment.AUTH_URL}/b50bafc56c5b9d279ad2c2d3da7c40c7`, userInfo,headers)
      .subscribe(
        data => {
          //console.log(data, 'data')
          if (data.status == 'OK') {
            this.toastr.info(
              'Verification code sent, You will receive verification code either by email or sms.',
              null,
              {
                closeButton: false
              }
            )
          } else {
            this.toastr.error('Unable to send the verification code.', null, {
              closeButton: false
            })
          }
        },
        error => {
          this.toastr.error('Unable to send the verification code.', null, {
            closeButton: false
          })
          //console.log('there was an error sending the query', error)
          if (error == 'INVALID_AUTHORIZATION') {
          }
        }
      )
  }
}
