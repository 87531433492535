import { Component, OnInit, OnDestroy } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import { updateNewsletterMutation, accountInfo } from '../../services/user.gql'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit,OnDestroy {
  private query: QueryRef<any>
  private query$ : any
  constructor (
    private apollo: Apollo,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}
  public settings: any = {
    newsletter: 'false'
  }
  ngOnInit () {
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      variables: {}
    })
    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      // console.log(data.accountInformation.settings[0].value)
      let index = data.accountInformation.settings.findIndex(
        item => item.key == 'EMAILS_NEWSLETTERS'
      )
      // alert(index>-1)
      if (index > -1) {
        if (data.accountInformation.settings.length > 0) {
          this.settings.newsletter = data.accountInformation.settings[0].value
        }
      }
    })
  }
  updateNewsletter (eve) {
    let settingArray = [
      {
        key: 'CONTACT_SHARES_PROFILE',
        value: 'true'
      },
      {
        key: 'CONTACT_UPDATE_PROFILE',
        value: 'false'
      },
      {
        key: 'MEETING_CREATES',
        value: 'true'
      },
      {
        key: 'MEETING_REMINDER',
        value: 'true'
      },
      {
        key: 'EMAILS_NEWSLETTERS',
        value: 'true'
      },
      {
        key: 'EMAILS_UPDATES_ABOUT_FEATURES',
        value: 'true'
      },
      {
        key: 'EXCHANGE_VCARD_BLUETOOTH',
        value: 'true'
      },
      {
        key: 'EMAILS_MANUAL_CONTACT_REQUESTS',
        value: 'true'
      },
      {
        key: 'COUNTRY_CODE',
        value: ''
      }
    ]
    //  let index= settingArray.findIndex(item => item.key == "EMAILS_NEWSLETTERS")
    //   settingArray[index].value =  eve.target.checked.toString()
    this.apollo
      .mutate({
        mutation: updateNewsletterMutation,
        variables: {
          key: 'EMAILS_NEWSLETTERS',
          value: eve.target.checked.toString()
        },
        update: (proxy, { data: { LHJRFLCWLE } }: any) => {
          // Read the data from our cache for this query.
          let data: any = proxy.readQuery({ query: accountInfo })
          //console.log(data)
          let accountInformation = data.accountInformation

          accountInformation.settings = LHJRFLCWLE
          data.accountInformation = accountInformation
          //console.log(data)
          proxy.writeQuery({ query: accountInfo, data })
        }
      })
      .subscribe(
        ({ data }) => {
          this.translate
            .get('MESSAGES.STATUS_UPDATED_SUCCESSFULLY')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : ""
  }
}
