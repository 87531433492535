import { Component , ChangeDetectorRef,AfterContentChecked} from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
import{Router, NavigationEnd} from '@angular/router';
import { MemberUser } from '../app/user/models/member-user'
import { AppState, selectMemberAuth } from '../app/user/store/app.states'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent  implements  AfterContentChecked {
  title = 'staytouch'
  user: MemberUser = null
  authicatedUser:any  = false
  getState: Observable<any>
  items = [
    {id: 1, name: 'Python'},
    {id: 2, name: 'Node Js'},
    {id: 3, name: 'Java'},
    {id: 4, name: 'PHP', disabled: true},
    {id: 5, name: 'Django'},
    {id: 6, name: 'Angular'},
    {id: 7, name: 'Vue'},
    {id: 8, name: 'ReactJs'},
  ];
  selected = [
    {id: 2, name: 'Node Js'},
    {id: 8, name: 'ReactJs'}
  ];

  constructor(private translate: TranslateService,public router: Router,private store: Store<AppState>,private cdRef : ChangeDetectorRef) {
    this.getState = this.store.select(selectMemberAuth)
    if(['/verifyAndMerge','/company-verification'].includes(window.location.pathname))
    {
      this.authicatedUser = false ;
    }
   else  if(localStorage.getItem('token'))
    {
      this.authicatedUser = true
    }
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    const browserLang = localStorage.getItem('lang')?localStorage.getItem('lang') : translate.getBrowserLang();


    translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }
    })
  }
  ngOnInit(){
  
    // this.getState.subscribe((state) => {
    //   this.authicatedUser = state.memberAuth.isAuthenticated;
    // })
  }
 
  ngAfterContentChecked() : void {
    this.getState.subscribe((state) => {
      if(['/verifyAndMerge','/company-verification'].includes(window.location.pathname))
      {
        this.authicatedUser = false ;
      }
      else{
        this.authicatedUser = state.memberAuth.isAuthenticated;
      }
      
    })
    this.cdRef.detectChanges();

  }
 
}
