

<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3 py-5 my-3  ">
        <app-account></app-account>
        <!-- <h4 class="pb-5 innerTitle">Update Account</h4> -->
       
        <div class="col-md-12 d-flex pt-4 px-3  justify-content-between1 align-items-center">
          <h5 style="    font-size: 18px;" class="mb-0 col-md-6 col-lg-4"> <img src="assets/ZoomLogo.png" width="100px" height="auto" />  </h5>

           <div class="d-flex">
          <div  class="1" [ngClass]="{'grayClr': zoomConnectStatus}">
          <a (click)="setLoginType('zoom')" [ngClass]="{
            'btnVisible': zoomConnectStatus
            }" class="btn btn-primary btn-size" href="https://zoom.us/oauth/authorize?response_type=code&client_id=oUIn5MWISpCjLjgWpCHAlA&redirect_uri=https://corporate.staytouch.com/zoom-connect
          ">Connect </a>
         </div>
         
          <div class="2" [ngClass]="{'grayClr': !zoomConnectStatus}">
                        <a    [ngClass]="{
              'btnVisible': !zoomConnectStatus
              }"  class="text-white btn btn-primary btn-size ml-2 " (click)="disconnect('zoom')">Disconnect</a>
          </div>
         
          </div>

        </div>
        <hr class="mx-3 my-4"/>
        <div  class="col-md-12  d-flex  px-3 pt-0  justify-content-between1 align-items-center ">
          <h5 style="    font-size: 18px;" class="mb-0 col-md-6 col-lg-4"> <img src="assets/webex.png" height="auto" />  </h5>
           <div class="d-flex">
            <div  class="1" [ngClass]="{'grayClr': webexConnectStatus}">
              <a (click)="codeClick()" [ngClass]="{'btnVisible': webexConnectStatus }" class="btn btn-primary btn-size">Connect </a>
            </div>
          
            <div class="2" [ngClass]="{'grayClr': !webexConnectStatus}">
              <a  [ngClass]="{'btnVisible': !webexConnectStatus}"  class="text-white btn btn-primary btn-size ml-2 " (click)="disconnect('webex')">Disconnect</a>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </div>
</div>


