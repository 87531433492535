<!-- <div class="grayBg container-cs aboutBlk"> -->
<!-- <h2 class="font-weight-bold pb-3">{{ 'SUBMENU.MEMBERS' | translate }}</h2> -->
<!-- <app-sub-menu></app-sub-menu> -->
<app-loading *ngIf="loading"></app-loading>
<div class="d-flex justify-content-between align-items-center">
  <div>
    <span >Total Users - {{ totalUsers }}</span>
  </div>
  <div class="position-relative col-md-6 mx-auto my-3">
    <input
      type="text"
      placeholder="Search Users"
      (input)="isFilter=true;filterUsers($event)"
      id="searchMeetings"
      class="theme-input"
      [(ngModel)]="filterText"
      style="background:transparent;"
    />
    <img
      id="blah"
      src="assets/icon_search.svg"
      alt="search"
      *ngIf="!isFilter"
      class="searchIcon"
    />
    <img
      id="blah"
      src="assets/icon_X.png"
      alt="search"
      (click)="clearFilter()"
      
      *ngIf="isFilter"
      class="searchIcon cursor-pointer"
    />
  </div>
  <div>
  <a [routerLink]="['/create-member']" class="theme-btn"  (click)="SendEvent('createMember','teams','click',1)" >{{
    'MESSAGES.CREATE_MEMBER' | translate
  }} </a></div>
</div>
<div class="row justify-content-center">
  <div
    class="col-md-12 p-2"
    infinite-scroll
    [infiniteScrollDistance]="'2'"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScrollDown($event)"
  >
  <ng-container  *ngFor="let member of members; let i = index">
    <div
      class="card customCard mb-3 cursor-pointer"
      
    >
      <div class="memberBlock row m-0" >
        <div class="dropdown  p-absolute">
          <a data-toggle="dropdown" class="firs" id="firs">
            <img id="blah" src="assets/dots.png" alt="dots" />
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
            <a
              class="text-decoration-none border-cs p-2"
              (click)="editMember(member)"
              >{{ 'CREATE_MEMBER.EDIT_ACCOUNT' | translate }}</a
            >
            <a
              class="d-block text-decoration-none p-2 border-cs"
              (click)="confirmDelete(member.userId)"
              >{{ 'MEMBERS_PAGE.DELETE_MEMBER' | translate }}</a
            >
            <a *ngIf="member.profiles.length != 0"
              class="d-block text-decoration-none p-2"
              [routerLink]="['/official-profile', member.userId]"
              >{{ 'MEMBERS_PAGE.EDIT_OFFICIAL_PROFILE' | translate }}</a
            >
            <a *ngIf="member.profiles.length == 0"
            class="d-block text-decoration-none p-2"
            [routerLink]="['/official-profile', member.userId]"
            >{{ 'TEAMSPAGE.CREATE_PROFILE' | translate }}</a
          >
          </div>
        </div>
        <div
          class="d-block d-sm-flex justify-content-between1 pl-sm-5 py-4 col-md-10 align-items-center cursor-pointer"
          (click)="showMember(member.userId)"
        >
          <!-- <img
              [src]="member.image"
              alt="your image12"
              class="borderImage"
              src="assets/default.jpg"
            *ngIf="member.profile && member.profile.displayImageURL"
            /> -->
          <!-- <img
              [src]="member.url"
              alt="your image"
              class="borderImage"
              (error)="errorHandler($event)"

            /> -->

          <div class="col-md-5 d-flex align-items-center">
            <div class="userBg mr-3">
              <!-- <div class="bg-text">{{contact.firstName | shortName}} {{contact.lastName | shortName}}</div> -->
              <img
                src="{{ member.url }}"
                class="position-absolute bg-pos"
                onerror="this.style.display='none'"
              />
            </div>
            <div style="width:calc(100% - 90px)">
              <a
                ><h4 class="text-ecllipsis text-capitalize">
                  {{ member.firstName }} {{ member.lastName }}
                </h4></a
              >
              <p *ngIf="member.department || member.title" class="mb-0">
                {{ member.title }}<span *ngIf="member.department && member.title">,</span>
                {{ member.department }} &ensp;
              </p>
            </div>
          </div>
          <div class="infoCls col-md-4">
            <p class="d-none d-md-block">&ensp;</p>
            <p class="text-ecllipsis">
              <img src="assets/icon_email.png?x=1" alt="email" class="pr-1" />
              {{ member.email }}
            </p>
          </div>
          <div class="infoCls col-md-3">
            <p class="d-none d-md-block">&ensp;</p>
            <p>
              <img src="assets/icon_cell.png?x=1" alt="Phone" class="pr-1" />
              {{ member.phone }}
            </p>
          </div>
        </div>
        <div class="col-md-2 mt-3" style="align-self: center;" *ngIf="email !=  member.email && phone != member.phone">
          <div class="text-left position-abso" *ngIf="member.firstLoginPending">
            <a (click)="confirmInvite(member.userId, i)" class="theme-btn"
              >{{ 'MEMBERS_PAGE.INVITE' | translate }}
            </a>
          </div>
        </div>
        <hr class="col-md-11 col-8 my-0" />
        <div class="d-block d-md-flex p-sm-3 pt-3  w-100  position-relative">
          <!-- <div class="dropdown  p-absolute">
              <a data-toggle="dropdown" class="">
                <img id="blah" src="assets/dots.png" alt="dots" />
              </a>
            </div> -->
          <div class="col-md-6 d-none">
            <h5 class="text-uppercase text-defaultClr pb-3 pt-3 pt-md-0">
              {{ 'MEMBERS_PAGE.PERFORMANCE_TO_DATE' | translate }}
            </h5>
            <h6 *ngIf="!member.attended">
              {{ 'MEMBERS_PAGE.NO_MEETINGS_ARE_SCHEDULED_YET' | translate }}
            </h6>
            <div class="d-block d-md-flex align-items-end">
              <div
                class="rate-cs d-flex justify-content-around text-center align-items-end"
              >
                <ng-container *ngIf="this[AttSche + member.id]">
                  <div *ngFor="let h of member.attended">
                    {{ h.meetingsCount
                    }}<span [ngStyle]="{ 'height.px': h.height }"></span>
                    <i class="tiny-text">{{ h.month | translate }}</i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!this[AttSche + member.id]">
                  <div *ngFor="let h of member.scheduled">
                    {{ h.meetingsCount
                    }}<span [ngStyle]="{ 'height.px': h.height }"></span>
                    <i class="tiny-text">{{ h.month | translate }}</i>
                  </div>
                </ng-container>
              </div>
              <div class="p-3 pl-5 ">
                <label class="container-radio text-gray-app">
                  {{ 'MEMBERS_PAGE.ATTENDED_MEETINGS' | translate }}
                  <input
                    type="radio"
                    checked="checked"
                    name="radio{{ member.id }}"
                    (change)="this[AttSche + member.id] = true"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container-radio text-gray-app">
                  {{ 'MEMBERS_PAGE.SCHEDULED_MEETINGS' | translate }}
                  <input
                    type="radio"
                    name="radio{{ member.id }}"
                    (change)="this[AttSche + member.id] = false"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-5 pl-5">
            <h5 class="text-uppercase text-defaultClr default-color pb-1">
              {{ 'SUBMENU.TEAMS' | translate }}
            </h5>
            <ul class="m-0 p-0 temsBlk">
              <li
                class="d-sm-flex justify-content-between"
                *ngFor="let team of member.teams"
                [routerLink]="['/teams', team.teamId]"
              >
                <p>{{ team.name }}</p>
                <span class="text-gray-app"
                  >{{ team.memberIds.length }}
                  {{ 'SUBMENU.MEMBERS' | translate }}</span
                >
              </li>
            </ul>
            <p *ngIf="member.teams.length == 0">
              {{ 'TEAMSPAGE.NO_TEAMS_ARE_FOUND' | translate }}
            </p>
          </div>
          <div class="col-md-7" *ngIf="member.profiles.length == 0">
            <div class="pb-2   text-danger">  There is no Corporate profile associated with this user please create</div>
            <a class="default-color text-defaultClr mt-2" [routerLink]="['/official-profile', member.userId]">Create profile for user ?</a>
          </div>
        </div>
      </div>
    </div>
</ng-container>
    <app-text-loading *ngIf="loadingText"></app-text-loading>

    <div *ngIf="!loading && members.length == 0"  class="card customCard mb-3 cursor-pointer text-center py-5">
      <h5>{{"MESSAGES.NO_USERS_ARE_FOUND"| translate }}</h5>
    </div>
  </div>
</div>
<!-- </div> -->
