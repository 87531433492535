import { Component, OnDestroy, OnInit } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'

import {  Subject } from 'rxjs';
// import 'rxjs/add/operator/map';
import { accountInfo, accountAnalyticsQuery,performanceAnalyticsQuery } from '../../services/user.gql'
import { Router} from '@angular/router'
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { HelperService } from '../../services/helper.service'

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnDestroy, OnInit  {
  dtOptions: DataTables.Settings = {};
 // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
   dtTrigger = new Subject<any>();
  loading:boolean = false
  private query: QueryRef<any>
  private queryAnalytics: QueryRef<any>
  account:any
  analytics:any
  totalSpaceInKb:any=0
  totalSpaceInMb:any=0
  totalSpaceInGb:any=0
  totalspacePercentage:any=0
  totalContactsCount:any=0
  totalSpaceAllocated:any =0
  totalNumberOfUsers:any =0
  members:any =[
  ]
  performanceInfo:any
  permissionsRes:any ={}
  permissions:any = ""
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { 
      xAxes: [{}], 
      yAxes: [{
        ticks: {
          // precision:0,
          beginAtZero: true,
          // suggestedMin:1
          callback: function(label, index, labels):any {
            // when the floored value is the same as the value we have a whole number
            let _label:any =label
            if(Number.isInteger(_label)){
              return label;
            } // true

            // return label;
        },

          
          // precision:0,
      }
    }] },

  };
  public isAdmin: boolean = false

  public barChartLabels: any[] = [];
  // barChartLabels: Label[] = ['2013', '2014', '2015', '2016', '2017', '2018'];

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [];
  // barChartData: ChartDataSets[] = [
  //   { data: [2500, 5900, 6000, 8100, 8600, 8050, 1200], label: 'Company A' },
  //   { data: [2800, 4800, 4000, 7900, 9600, 8870, 1400], label: 'Company B' }
  // ];

  constructor (private apollo: Apollo, public router: Router, private helperService:HelperService){
    this.isAdmin = this.helperService.isAdmin()

  }

  ngOnInit() {
    this.dtOptions = {
      // pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getAccountDetails()
    this.getAnalyticsDetails()
    this.performanceAnalytics()
   
    
  
      // Calling the DT trigger to manually render the table
 
  }
 

  // private extractData(res: Response) {
  //   const body = res.json();
  //   return body.data || {};
  // }
/** Account Details **/
  getAccountDetails () {
    this.loading = true
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      fetchPolicy:"no-cache"
    })
    this.query.valueChanges.subscribe(result => {
      this.account = result.data.accountInformation
      this.loading = false
      this.permissions = this.helperService.getPermissions(result.data)

    },error => {
      this.loading = false
    })
  }

/** Analytics Details **/
  getAnalyticsDetails () {
    this.loading = true
    this.queryAnalytics = this.apollo.watchQuery({
      query: accountAnalyticsQuery,
      fetchPolicy:"no-cache"
    })
    this.queryAnalytics.valueChanges.subscribe(result => {
      this.members = result.data.accountAnalytics
      this.totalNumberOfUsers = this.members.length
      this.members.forEach(element => {
        this.totalSpaceInKb =Number(this.totalSpaceInKb) +Number(element.spaceInKb)
        this.totalspacePercentage = Number(this.totalspacePercentage) + Number(element.spacePercentage)
        this.totalContactsCount = this.totalContactsCount + Number(element.contactCount)

      });
      this.totalContactsCount = this.totalContactsCount/this.totalNumberOfUsers
      this.totalContactsCount = parseInt(this.totalContactsCount)
      let totalUsers = this.members.length

      this.totalSpaceInGb = this.totalSpaceInKb *0.000001 //GB
      this.totalSpaceInGb = this.totalSpaceInGb.toFixed(2) //GB
      this.totalspacePercentage = this.totalspacePercentage/totalUsers;
      if(this.totalspacePercentage <= 0.9)
      {
        this.totalspacePercentage = this.totalspacePercentage.toFixed(1)
      }
      else{
        this.totalspacePercentage = Math.round(this.totalspacePercentage)
      }
     
      
      this.dtTrigger.next();
      this.totalSpaceAllocated = totalUsers //GB
      // this.loading = false
    },error => {
      // this.loading = false
    })
  }
  roundTheValue(number){
    // console.log(number)
    return number
    // return number.toFixed(2)
  }
  performanceAnalytics(){
    this.loading
    let contactsArray:any =[]
    let meetingsArray:any = []
    this.query = this.apollo.watchQuery({
      query: performanceAnalyticsQuery,
    })
    this.query.valueChanges.subscribe(result => {
      this.performanceInfo = result.data.performanceAnalytics
      this.performanceInfo.forEach(element => {
        this.barChartLabels.push(element.companyName)
        contactsArray.push(element.numberOfContacts)
        meetingsArray.push(element.numberofMeetings)
        // meetingsArray.push(8)
      });
      this.barChartData = [
        { data: meetingsArray, label: 'Meetings' },
        { data: contactsArray, label: 'Contacts' }
      ];

      this.loading = false
    },error => {
      this.loading = false
    })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
