<!-- <div class="grayBg container-cs aboutBlk"> -->
<!-- <h2 class="font-weight-bold pb-3">{{ 'SUBMENU.MEMBERS' | translate }}</h2> -->
<!-- <app-sub-menu></app-sub-menu> -->
<app-loading *ngIf="loading"></app-loading>
<div class="d-flex justify-content-between align-items-center">
  <div>
    <span >Total Users - {{ totalUsers }}</span>
  </div>
  <div class="position-relative col-md-6 mx-auto my-3">
    <input
      type="text"
      placeholder="Search Users"
      (input)="isFilter=true;filterUsers($event)"
      id="searchMeetings"
      class="theme-input"
      [(ngModel)]="filterText"
      style="background:transparent;"
    />
    <img
      id="blah"
      src="assets/icon_search.svg"
      alt="search"
      *ngIf="!isFilter"
      class="searchIcon"
    />
    <img
      id="blah"
      src="assets/icon_X.png"
      alt="search"
      (click)="clearFilter()"
      
      *ngIf="isFilter"
      class="searchIcon cursor-pointer"
    />
  </div>
  <div>
  <a [routerLink]="['/create-member']"  class="theme-btn"  (click)="SendEvent('createMember','teams','click',1)" *ngIf="isAdmin || (permissions && permissions.company.create_admin_manager_member == 'true')" >Add Member</a></div>
</div>
<div class="row justify-content-center">
  <div
    class="col-md-12 p-2"
    infinite-scroll
    [infiniteScrollDistance]="'2'"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScrollDown($event)"
    [infiniteScrollContainer]="'#content-wrapper'"
    [fromRoot]="true"
  >
<ng-container *ngFor="let member of members; let i = index">
  
    <div
      class="card customCard mb-3 cursor-pointer"
      *ngIf="!member.isOwner || email ==  member.email"
    >
      <div class="memberBlock row m-0" >
        <div class="dropdown  p-absolute" *ngIf="isAdmin || (permissions && (permissions.company.create_admin_manager_member == 'true' || permissions.company.reset_password_for_everyone=='true'))" >
          <a data-toggle="dropdown" class="firs" id="firs">
            <img id="blah" src="assets/dots.svg" alt="dots" />
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
            <ng-container *ngIf="isAdmin || (permissions && permissions.company.create_admin_manager_member == 'true')">
              <a
              class="text-decoration-none border-cs p-2"
              *ngIf="account && environmentInfo.CWG_ID == account.organizationId && member.publicProfiles.length ==0"
              [routerLink]="['/official-profile', member.userId,1]"
              >Copy Profile to Public</a>
            <a
              class="text-decoration-none border-cs p-2"
              (click)="editMember(member)" 
              >{{ 'CREATE_MEMBER.EDIT_ACCOUNT' | translate }}</a>
             
            <a *ngIf="member.profiles.length != 0"
              class="d-block text-decoration-none p-2 border-cs"
              [routerLink]="['/official-profile', member.userId,1]"
              >Edit Corporate Profile</a>
            <a *ngIf="member.profiles.length == 0"
            class="d-block text-decoration-none p-2 border-cs"
            [routerLink]="['/official-profile', member.userId,1]"
            >{{ 'TEAMSPAGE.CREATE_PROFILE' | translate }}</a>
          </ng-container>
          <ng-container >
            <!-- <a 
            class="d-block text-decoration-none p-2 border-cs"
            [routerLink]="['/meetings', member.userId]"
            >View meetings</a> -->
          <!-- <a 
          class="d-block text-decoration-none p-2 border-cs"
          [routerLink]="['/all-contacts', member.userId]"
          >View contacts</a> -->
          </ng-container>
          <ng-container *ngIf="isAdmin || (permissions && permissions.company.reset_password_for_everyone=='true')">
            <a 
            class="d-block text-decoration-none p-2 border-cs "
            (click)="resetPassword(member.id,ContactGroupModal); memberInfo = member;"
            >Change password</a>
          </ng-container>
          <ng-container *ngIf="isAdmin || (permissions && permissions.company.disable_mfa=='true')">
            <a class="d-block text-decoration-none p-2 border-cs" *ngIf="member.twoFactor" (click)="memberInfo = member;getMasterToken('2Mfa',i,false);">
              Disable 2Mfa authentication</a>
            <a class="d-block text-decoration-none p-2 border-cs" *ngIf="!member.twoFactor" (click)="memberInfo = member;getMasterToken('2Mfa',i,true);">
                Enable 2Mfa authentication</a>
              
          </ng-container>
          <a class="d-block text-decoration-none p-2 "  (click)="confirmDelete(member);">
            Delete Account</a>
          </div>
        </div>
        <div
          class="d-block d-sm-flex justify-content-between1 pl-sm-5 py-4 col-md-10 align-items-center cursor-pointer"
          (click)="showMember(member.userId)"
        >
          <!-- <img
              [src]="member.image"
              alt="your image12"
              class="borderImage"
              src="assets/default.jpg"
            *ngIf="member.profile && member.profile.displayImageURL"
            /> -->
          <!-- <img
              [src]="member.url"
              alt="your image"
              class="borderImage"
              (error)="errorHandler($event)"

            /> -->

          <div class="col-md-6 d-flex align-items-center">
            <div class="userBg mr-3">
              <!-- <div class="bg-text">{{contact.firstName | shortName}} {{contact.lastName | shortName}}</div> -->
              <img
                src="{{ member.url }}"
                class="position-absolute bg-pos"
                onerror="this.style.display='none'"
              />
            </div>
            <div style="width:calc(100% - 90px)">
              <a class="d-flex align-items-center"
                ><h4 class="text-ecllipsis text-capitalize">
                  {{ member.firstName }} {{ member.lastName }} 
                </h4>
                <ng-container *ngIf="false">
                  <small class="font-weight-bold text-uppercase" *ngIf="member.role">({{member.role}})</small>
                  <small class="font-weight-bold text-uppercase" *ngIf="!member.role && !member.isOwner">(Member)</small>
                  <small class="font-weight-bold text-uppercase" *ngIf="member.isOwner">(Admin)</small>

                </ng-container>
                </a
              >
              <p *ngIf="member.department || member.title" class="mb-0">
                {{ member.title }}<span *ngIf="member.department && member.title">,</span>
                {{ member.department }} &ensp;
              </p>
            </div>
          </div>
          <div class="infoCls col-md-4">
            <p class="d-none d-md-block">&ensp;</p>
            <p class="text-ecllipsis">
              <img src="assets/icon_email.svg?x=1" alt="email" class="pr-1" />
              {{ member.email }}
            </p>
          </div>
          <div class="infoCls col-md-3" *ngIf="member.b2BPhone">
            <p class="d-none d-md-block">&ensp;</p>
            <p class="text-ecllipsis">
              <img src="assets/icon_cell.svg?x=1" alt="Phone" class="pr-1" />
              {{ member.b2BPhone }}
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-md-12  " style="align-self: center;" *ngIf="email !=  member.email && !member.isOwner">
          <div class="text-center position-abso textRight" *ngIf="member.firstLoginPending">
            <a (click)="confirmInvite(member.userId, i)" class="theme-btn"
              >{{ 'MEMBERS_PAGE.INVITE' | translate }}
            </a>
          </div>
          <!-- <div class="text-center position-abso textRight" *ngIf="!member.firstLoginPending && !member.contactPresent">
             <a (click)="connectMember(member, i)" class="theme-btn" style="padding: 7px 15px;"
              >Connect
            </a>
          </div>  -->
        </div>
        
        <div class="text-danger mergeCls"  *ngIf="member.joinPending">Pending for Account Merge</div>   
        <hr class="col-md-11 col-8 my-0" />
        <div class="d-block d-md-flex p-sm-3 pt-3  w-100  position-relative">
          <!-- <div class="dropdown  p-absolute">
              <a data-toggle="dropdown" class="">
                <img id="blah" src="assets/dots.png" alt="dots" />
              </a>
            </div> -->
          <div class="col-md-6 d-none">
            <h5 class="text-uppercase text-defaultClr pb-3 pt-3 pt-md-0">
              {{ 'MEMBERS_PAGE.PERFORMANCE_TO_DATE' | translate }}
            </h5>
            <h6 *ngIf="!member.attended">
              {{ 'MEMBERS_PAGE.NO_MEETINGS_ARE_SCHEDULED_YET' | translate }}
            </h6>
            <div class="d-block d-md-flex align-items-end">
              <div
                class="rate-cs d-flex justify-content-around text-center align-items-end"
              >
                <ng-container *ngIf="this[AttSche + member.id]">
                  <div *ngFor="let h of member.attended">
                    {{ h.meetingsCount
                    }}<span [ngStyle]="{ 'height.px': h.height }"></span>
                    <i class="tiny-text">{{ h.month | translate }}</i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!this[AttSche + member.id]">
                  <div *ngFor="let h of member.scheduled">
                    {{ h.meetingsCount
                    }}<span [ngStyle]="{ 'height.px': h.height }"></span>
                    <i class="tiny-text">{{ h.month | translate }}</i>
                  </div>
                </ng-container>
              </div>
              <div class="p-3 pl-5 ">
                <label class="container-radio text-gray-app">
                  {{ 'MEMBERS_PAGE.ATTENDED_MEETINGS' | translate }}
                  <input
                    type="radio"
                    checked="checked"
                    name="radio{{ member.id }}"
                    (change)="this[AttSche + member.id] = true"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="container-radio text-gray-app">
                  {{ 'MEMBERS_PAGE.SCHEDULED_MEETINGS' | translate }}
                  <input
                    type="radio"
                    name="radio{{ member.id }}"
                    (change)="this[AttSche + member.id] = false"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6 pl-5">
            <div class="d-flex">
              <h5 class=" text-defaultClr1 default-color pb-1 pl-0 col-md-4">
                {{ 'SUBMENU.TEAMS' | translate }}
              </h5>
              <h5 class=" text-defaultClr1 default-color pb-1 col-md-4" *ngIf="member.teams.length > 0">
               Members
              </h5>
              <h5 class=" text-defaultClr1 default-color pb-1 col-md-3" *ngIf="member.teams.length > 0">
                 Role
              </h5>
            </div>
           
            <ul class="m-0 p-0 temsBlk">
              <li
                class="d-sm-flex "
                *ngFor="let team of member.teams"
                [routerLink]="['/teams', team.teamId]"
              >
                <p class="col-md-4 pl-0 text-gray-app" >{{ team.name }}</p>
                <span class="text-gray-app col-md-4 ">{{ team.memberIds.length }}</span>
                <span class="col-md-3 text-gray-app"> {{isManger(team.managers,member.userId) ? 'Manager' : 'Member' }}</span>
              </li>
            </ul>
            
            <p *ngIf="member.teams.length == 0">
              {{ 'TEAMSPAGE.NO_TEAMS_ARE_FOUND' | translate }}
            </p>
          </div>

          <div class="col-md-6 text-right ">
            
            <ng-container *ngIf="member.profiles.length == 0">
              <div class="pb-2   text-danger">  There is no Corporate profile associated with this user please create</div>
              <a class="default-color text-defaultClr mt-2" [routerLink]="['/official-profile', member.userId,1]">Create profile for user ?</a>
            </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!mainLoading && members.length == 0"  class="card customCard mb-3 cursor-pointer text-center py-5">
      <h5 >{{"MESSAGES.NO_USERS_ARE_FOUND"| translate }}</h5>
    </div>
  </div>
</ng-container>
  <app-text-loading *ngIf="loadingText"></app-text-loading>

</div>
<!-- </div> -->


<!-- <div class="modal" id="deleteModal"  #deleteModal> -->
  <ng-template #ContactGroupModal let-modal>
    <div class="">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 id="modal-basic-title">
           Reset Password for {{memberInfo.firstName}} {{memberInfo.lastName}}
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
               -->
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss(0)"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pt-2 px-5 remindersBlk">
          <form
          class="form-signin d-flex flex-wrap col-lg-12 col-md-12 mx-auto"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="onSubmit()"
        >

         
          <div class="form-label-group1 col-md-12 my-3 px-0">
            <label _ngcontent-aru-c9="" for="inputEmail">{{ 'HEADER.NEW_PASSWORD' | translate }}</label>
            <div class="position-relative">
            <input
              type="password"
              id="newPassword"
              formControlName="newPassword"
              class="form-control custominp1"
              autocomplete="off"
              appAppPassword
            />
            </div>
            <!-- <label for="inputEmail">Email address</label> -->
            <div *ngIf="submitted && f.newPassword.errors" class="text-danger pl-3 pt-1">
              <div *ngIf="f.newPassword.errors.required">
                {{ 'HEADER.PASSWORD_IS_REQUIRED' | translate }}
              </div>
              <div *ngIf="f.newPassword.errors.minlength">
                {{
                  'HEADER.PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate
                }}
              </div>
            </div>
          </div>
         
          <div class="form-label-group1 col-md-12 px-0">
            <label _ngcontent-aru-c9="" for="inputEmail">{{ 'HEADER.CONFIRM_PASSWORD' | translate }}</label>
            <div class="position-relative">
            <input
              type="password"
              id="confirmPassword"
              formControlName="confirmPassword"
              class="form-control custominp1"
              appAppPassword
            />
            </div>
            <!-- <label for="inputPassword">Password</label> -->
            <div
            *ngIf="submitted && f.confirmPassword.errors"
            class="text-danger pl-3 pt-1"
          >
            <div *ngIf="f.confirmPassword.errors.required">
              {{ 'HEADER.CONFIRM_PASSWORD_IS_REQUIRED' | translate }}
            </div>
            <div *ngIf="f.confirmPassword.errors.mustMatch">
              {{ 'HEADER.PASSWORDS_MUST_MATCH' | translate }}
            </div>
          </div>
          </div>
           <div class="text-center col-md-12 px-0 py-3">
            <button
              class="btn btn-lg btn-primary btn-block "
              type="submit"
            >
              {{ 'HEADER.UPDATE_PASSWORD' | translate }}
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  </ng-template>
