import gql from 'graphql-tag'
export const membersBasicQuery = gql`
{
  users{
    userId
    firstName
    lastName
    teams {
      teamId
    }
    profile {
      displayImageURL
      jobTitle
    }
  }
}
`;

export const membersQuery = gql`
{
  users{
    userId
    firstName
    lastName
    phone
    email
    teams {
      teamId
      name
    }
    profile {
      displayImageURL
      profileId
      department
      jobTitle
      title
      socialProfiles {
        username
        service
      }
    }
  }
}
`;
export const addTeamMutation = gql`
mutation XTFPGBIOGK(
        $name: String!,
         $memberIds:[ID!]
){
    XTFPGBIOGK(team:{name:$name, memberIds: $memberIds}) {
    teamId
    name
    memberIds
    updatedAt
    managers
  }
}`

export const teamsQuery = gql`
query teams($teamId: ID){
    teams(teamId:$teamId){
      teamId
      name
      memberIds
      updatedAt
      managers
    }
}
`;

export const deleteTeamMutation = gql`mutation HVVJQFSOQA($teamIds:[ID!]!){
  HVVJQFSOQA(teamIds: $teamIds)
}`

export const updateTeamMutation = gql`
mutation AFFOUFBOXK(
        $name: String!,
         $teamId:ID!
){
    AFFOUFBOXK(team:{name:$name, teamId: $teamId}) {
      teamId
    name
    memberIds
    updatedAt
    managers
  }
}`
export const teamsRemoveMemberMutation = gql`
mutation SSYEYCOLZJ(
        $memberIds: [ID!]!,
         $teamId:ID!
){
    SSYEYCOLZJ(team:{teamId:$teamId, memberIds: $memberIds}) {
    teamId
    name
    memberIds
    updatedAt
  }
}`
export const teamsAddMemberMutation = gql`
mutation MPYVMDLKYI(
        $memberIds: [ID!]!,
         $teamId:ID!
){
    MPYVMDLKYI(team:{teamId:$teamId, memberIds: $memberIds}) {
      teamId
    name
    memberIds
    updatedAt
  }
}`

export const makeManagerMutation = gql`
mutation DBKPTSHJIX(
        $teamId: ID!,,
         $memberId:ID!,
         $manager: Boolean
){
  DBKPTSHJIX(teamId:$teamId, memberId: $memberId,manager:$manager)
}`

export const getMembersBasicQuery = gql`

  query users($userIds:[ID!] ) {
  users(userIds:$userIds){
    userId
    firstName
    lastName
    email
    phone
  }
}
`;
