import { Component, OnInit,OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  membersBasicQuery,
  deleteMemberMutution,
  useInviteMutution,
  membersFilterBasicQuery,
  allMembersQuery
} from '../../../services/admin.gql'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'
import {GoogleAnalyticsService} from "../../../../shared/google-analytics.service"; // import our analytics service
@Component({
  selector: 'app-pending-members',
  templateUrl: './pending-members.component.html',
  styleUrls: ['./pending-members.component.scss']
})
export class PendingMembersComponent implements OnInit {

  public members: any = []
  private query: QueryRef<any>
  private queryTotal: QueryRef<any>
  private filterQuery: QueryRef<any>
  public AttSche: string = 'attended'
  public loading: boolean = true
  public loadingText:boolean = false
  role:any 
  email:any
  phone:any
  public isScroll: boolean = true
  filterText: any = ''
  isFilter: boolean = false
  tempInfo: any = []
  query$:any
  filterQuery$:any
  queryTotal$:any
  totalUsers:any =0
  constructor (
    private apollo: Apollo,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.role = localStorage.getItem('role')
    this.email = localStorage.getItem('email')
    this.phone = localStorage.getItem('phone')

  }
  clearFilter () {
    this.members = this.tempInfo
    this.isFilter = false
    this.loading=false
    this.isScroll = true
    this.filterText=''
  }

  showMember (memberId) {
    this.router.navigate(['/official-profile', memberId])
  }

  editMember (member) {
    this.router.navigate(['/create-member', member.userId])
  }
  confirmDelete(id)
  {
    this.translate
    .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_THIS_USER')
    .subscribe((res: string) => {
      if (!confirm(res)) {
        return false
      }
      else {
        this.deleteMember(id)
      }
    })
  }
  deleteMember(id) {
    // return false
    this.apollo
      .mutate({
        mutation: deleteMemberMutution,
        variables: {
          userIds: [id]
        },
        update: (proxy, { data: { SIOVXASRBD } }: any) => {
          let data: any = proxy.readQuery({
            query: membersBasicQuery,
            variables: {
              limit: 5,
              offset: 0,
              sortByDate: 'DESC'
            }
          })
          //console.log(data,'before')
          data.users = data.users.filter(item => item.userId != id)
          //console.log(data,'after')
          proxy.writeQuery({
            query: membersBasicQuery,
            variables: {
              limit: 5,
              offset: 0,
              sortByDate: 'DESC'
            },
            data
          })
        }
      })
      .subscribe(
        ({ data }: any) => {
          //console.log('message => ', data['SIOVXASRBD'])
          if (data.SIOVXASRBD) {
            this.members = this.members.filter(item => item.userId != id)
            this.tempInfo = this.tempInfo.filter(item => item.userId != id)
            this.translate
              .get('MESSAGES.USER_DELETED_SUCCESSFULLY')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
          }
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
    //window.location.reload()
  }

  ngOnInit () {
    // querying the members list
    this.query = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        limit: 5,
        offset: 0,
        sortByDate: 'DESC'
      },
      fetchPolicy: 'network-only'
    })

   this.query$=  this.query.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
      this.formatUsers()
      /*  for (var i = 0; i < this.members.length; i++) {
        this[this.AttSche + this.members[i].id] = "true"
      } */
    },error=>{
      this.loading = false
    })

    /* */
    this.getTotalUsers()
  }
  getTotalUsers(){
    this.queryTotal = this.apollo.watchQuery({
      query: allMembersQuery,
      fetchPolicy: 'network-only'
    })

   this.queryTotal$=  this.queryTotal.valueChanges.subscribe(({ data }: any) => {
      if(data.users)
      {
        this.totalUsers =  data.users.length
      }
    },error=>{
      this.loading = false
    })
  }
  formatUsers(isSarch=false){
    this.members &&
        this.members.forEach((user, j) => {
          //this.members[j].title='--'
          if (user.profiles[0]) {
            this.members[j].url = user.profiles[0].displayImageURL
          }
          user.profiles[0] &&
            user.profiles[0].data.forEach((item, i) => {
              if (item.format == 'JOB_TITLE') {
                this.members[j].title = item.value ? item.value : '-'
              }
              if (item.format == 'DEPARTMENT') {
                this.members[j].department = item.value ? item.value : '-'
              }
            })
            if(!isSarch)
            {
              this.tempInfo = this.members
            }
          this.loading = false
        })
  }
  setDefaultPic () {
    // this.pic = "assets/images/my-image.png";
  }
  confirmInvite(userId, i) 
  {
    this.translate
    .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_INVITE_THIS_USER')
    .subscribe((res: string) => {
      if (!confirm(res)) {
        return false
      }
      else {
        this.inviteUser(userId, i)
      }
    })
  }
  inviteUser (userId, i) {
   

    // return
    // return
    // alert(userId)
    this.apollo
      .mutate({
        mutation: useInviteMutution,
        variables: {
          userIds: [userId]
        },
        update: (proxy, { data: { usersInvite } }: any) => {
          // Read the data from our cache for this query.

          let data: any = proxy.readQuery({
            query: membersBasicQuery,
            variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
          })

          proxy.writeQuery({ query: membersBasicQuery, data })
        }
      })
      .subscribe(
        ({ data }: any) => {
          if (data.usersInvite) {
            let pswd: any = data.usersInvite[0].status.split('-')
            this.translate
              .get('MESSAGES.USER_INVITED_SUCCESSFULLY')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
            // this.toastr.info((USER_INVITED_SUCCESSFULLY | translate ),null,{
            //   closeButton: true,
            //   disableTimeOut: true,
            //   tapToDismiss:false
            //   });
          }
          // console.log('message => ', data['useInviteMutution']);
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  onScrollDown (ev) {
    if (this.isScroll) {
      this.getUsers()
    }
  }
  errorHandler (event) {
    event.target.src = 'assets/default.jpg'
  }
  getUsers (count = this.members.length) {
    if (count == 0) {
      return false
    }
    this.isScroll = true

    this.loadingText = true
    this.query.fetchMore({
      variables: {
        offset: count,
      },
      
      updateQuery: (prev, { fetchMoreResult }) => {
        this.loadingText = false
        if (!fetchMoreResult) {
          return prev
        }
        if (fetchMoreResult.users.length == 0) {
          this.isScroll = false
        }
        // console.log(prev,'pre')
        return Object.assign({}, prev, {
          users: [...prev.users, ...fetchMoreResult.users]
        })
      }
    })
  }
  filterUsers (ev) {
    console.log(ev.target.value)
    let searchText = ev.target.value.trim()
    if (!searchText) {
      this.members = this.tempInfo
      console.log(this.members)
      this.isScroll=true
      
      return false
    }
    this.loading=true

    this.isScroll=false
    this.filterQuery = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        searchByName: searchText,
        
      },
      fetchPolicy: 'no-cache'
    })

    this.filterQuery$ = this.filterQuery.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
      this.formatUsers(true)
      this.loading=false
      // this.isScroll=true
      /*  for (var i = 0; i < this.members.length; i++) {
        this[this.AttSche + this.members[i].id] = "true"
      } */
    },err=>{
      this.loading=false
    })
  }
  SendEvent(eventCategory,eventLabel,eventAction,eventValue) {
    this.googleAnalyticsService.eventEmitter(eventCategory,eventLabel,eventAction,eventValue);
  }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : "";
    this.filterQuery$ ?this.filterQuery$.unsubscribe() : "" ;
    this.queryTotal$ ?this.queryTotal$.unsubscribe() : "" ;
  }
}
