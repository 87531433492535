import { Component, OnInit,ChangeDetectorRef,ViewRef } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'
import {
  customersQuery,customersOnlyIdQuery,deleteCustomerMutution,updateProductMutution,productSuppliersQuery
} from '../../../../services/products.gql'
import { Router, ActivatedRoute, Params } from '@angular/router'

const moment = require('moment-timezone')

@Component({
  selector: 'app-order-analytics',
  templateUrl: './order-analytics.component.html',
  styleUrls: ['./order-analytics.component.scss']
})
export class OrderAnalyticsComponent implements OnInit {
  loading:boolean = false
  itemsPerPage:number = 10
  page:number =1
  query$:any
  sQuery$:any
  customers:any =[]
  totalItems:number =0
  suppliersList:any =[]
  model:any
  p:any
  filterByColumn:any
  selectedType:any=1
  startDate:any
  endDate:any
  supplierName:any
  private query: QueryRef<any>
  private sQuery: QueryRef<any>

  constructor(
    private apollo: Apollo,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) { 
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {startDate,endDate,page,supplierName} = this.router.getCurrentNavigation().extras.state
      this.startDate = startDate
      this.endDate = endDate
      this.page = page
      this.supplierName = supplierName
    }
    this.getSuppliers()
    // this.getPage(1)
  }

  ngOnInit() {
  }
  getSuppliers(){
    let obj:any ={
      sortByColumn: "supplier_name",
      sortByPosition: "ASC",
      supplierMode: true,
     
    }
    this.sQuery = this.apollo.watchQuery({
      query:   productSuppliersQuery  ,
      fetchPolicy:"network-only",
      variables:obj
    })
    this.sQuery$ = this.sQuery.valueChanges.subscribe(({ data }: any) => {
      this.suppliersList = data.products
      if( this.supplierName ){
        this.getPage(this.page)
      }
      // const unique = [];
      // this.suppliersList.map(x => unique.filter(a => a.supplierName == x.supplierName ).length > 0 ? null : unique.push(x));
      // this.suppliersList = unique
    })
  }

  getPage(page) {
    this.loading = true
    this.page = page
    let obj:any ={
      limit:this.itemsPerPage,
      offset:(this.page*this.itemsPerPage)-this.itemsPerPage,
      actionType: this.selectedType == 1 ? 'BUY' : 'ORDER'
    }
    if(this.supplierName){
      obj.filterByProductColumn =  "supplier_name"
      obj.filterByProductColumnValue = this.supplierName
    }
    if(this.startDate){
      let startDate = `${this.startDate.year}-${this.startDate.month}-${this.startDate.day}`
      startDate = moment(startDate,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      startDate = moment.tz(startDate, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      obj.startDate = startDate
    }
    if(this.startDate){
      let endDate = `${this.endDate.year}-${this.endDate.month}-${this.endDate.day}`
      endDate = moment(endDate,'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm')
      endDate = moment.tz(endDate, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      obj.endDate = endDate
    }

    
    this.query = this.apollo.watchQuery({
      query:   customersQuery  ,
      fetchPolicy:"network-only",
      variables:obj
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      
      this.customers = data.customers
      if(this.customers.length > 0){
        this.totalItems = data.customers[0].totalCustomersCount
      }
      else{
        this.totalItems =0
      }
      this.loading = false
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
        }
    
    },err=>{
      console.log(err)
      this.loading = false
    })
  }
  filterData(){
    if(this.supplierName){
      this.loading = true
      
      this.getPage(1)
    }
  }
  viewOrder(id){
    this.router.navigate(['/order-details',id,this.page], {
      state: {
        supplierName:this.supplierName,
        startDate:this.startDate,
        endDate : this.endDate,
        page:this.page,
        from:'suppliers'
      }
    })
  }
  showOrders(type){ 
    if(this.supplierName){
      this.selectedType = type
      this.getPage(1)
    }
    else{
      alert("Please select supplier name to continue")
    }
  }
}
