<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3  pb-5 my-3 ">
        <app-account></app-account>
        <!-- <h4 class="pb-5 innerTitle">Update Account</h4> -->
        <ng-container *ngIf="isAdmin || (permissions && permissions.company.mfa_compulsory == 'true')" >
          <div *ngIf="account && !account.twoFactor">
            <h5 class="pt-3 text-center w-100">Your account  doesn't have Two factor authentication enabled, click the button below to enable it.</h5>
            <div class="text-center w-100 pt-2">
              <a class="theme-btn" [routerLink]="['/mfa']">2Mfa Authentication</a>
            </div>
          </div>
          <div *ngIf="account && account.twoFactor">
            <div class="text-right w-100 pt-2 pr-4">
              <a class="theme-btn" [routerLink]="['/mfa']">Scan  2Mfa code</a>
            </div>
          </div>

        </ng-container>
       
       
        <div class="d-flex justify-content-center flex-wrap pt-3">
		  <div class=" d-flex justify-content-around1 pt-3 flex-wrap col-md-12">
          <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4">
            <!-- <img src="assets/user.png?x=1" alt="phone" class="pr-2"  /> -->
            <img src="assets/union.svg?x=1" alt="phone" class="pr-2"  />
            <div class="pl-2">
              <p class="text-gray m-0">   {{ 'PROFILE.NAME' | translate }}</p>
       <span class="text-capitalize">{{ f.firstName.value }} {{ f.lastName.value }}</span> 

            </div>
          </div>
          <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4 ">
            <img src="assets/mail.svg?x=1" alt="phone" class="pr-2" />
            <div class="pl-2">
              <p class="text-gray m-0">   {{ 'PROFILE.EMAIL' | translate }}</p>
              {{ f.email.value }}
            </div>
          </div>
          <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account && account.b2bPhone">
            <img src="assets/phone.svg?x=1" alt="phone" class="pr-2" />
            <div class="pl-2">
              <p class="text-gray m-0">   {{'CREATE_MEMBER.PHONE' | translate}}</p>
              {{account && account.b2bPhone }}
            </div>
          </div>
      
  <!-- 
          </div>
        <div class=" d-flex justify-content-around1 pt-3 flex-wrap col-md-12"> -->


		<div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account">
				<img src="assets/company.svg?x=1" alt="phone" class="pr-2" />
				<div class="pl-2" >
				  <p class="text-gray m-0">   Company Name</p>
				  {{account && account.organizationName }}
				</div>
			  </div>
			  <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account">
				<img src="assets/location.svg?x=1" alt="phone" class="pr-2" />
				<div class="pl-2">
				  <p class="text-gray m-0">   Company Address</p>
				  {{account && account.organizationAddress }}
				</div>
			  </div>
			  <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account">
				<img src="assets/website.svg?x=1" alt="phone" class="pr-2" />
				<div class="pl-2">
				  <p class="text-gray m-0">   Company Website</p>
				  <a href="{{account.organizationWebsite }}" target="_blank">{{account.organizationWebsite }}</a>
				</div>
        </div>
        <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account && isAdmin || (permissions && permissions.company.type_of_contract == 'true')">
          <img src="assets/plan.png?x=1" alt="current plan" width="35px" class="pr-2" />
          <div class="pl-2">
            <p class="text-gray m-0"> Current Plan</p>
            {{account.organizationPlan}}
          </div>
        </div>
        <div class="d-flex align-items-center col-lg-4 col-sm-6 pb-4" *ngIf="account">
          <img src="assets/plan.png?x=1" alt="account status" width="25px" class="" />
          <div class="pl-2">
            <p class="text-gray m-0"> Account Status</p>
            {{account.accountStatus}}
          </div>
        </div>
			</div>

		</div>
		
         
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Hello, World!</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
  </div>
</ng-template>
