<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2 py-4 ">
    <div class="card py-md-3 py-3 px-3 ">
        <div  class=" d-flex   pt-2 pb-3  grid-st-center1 align-items-center flex-wrap">
            <h5 class="col-md-3">Suppliers List</h5>
            <div class="d-flex justify-content-between align-items-end pb-2 col-md-6 flex-wrap">
                <div class="position-relative  mx-auto pl-0 pb-2 w-100">
                  <input
                    type="text"
                    placeholder="Search Suppliers"
                    (input)="searchOrders($event)"
                    id="searchMeetings"
                    class="theme-input"
                    [(ngModel)]="searchText"
                    style="background:transparent;"
                  />
                  <img
                    id="blah"
                    src="assets/icon_search.svg"
                    alt="search"
                    *ngIf="!searchText"
                    class="searchIcon"
                  />
                  <img
                    id="blah"
                    src="assets/icon_X.png"
                    alt="search"
                    (click)="clearFilter()"
                    *ngIf="searchText"
                    class="searchIcon cursor-pointer"
                  />
                 </div>
             
               
      
            </div>         
        </div>
       
        <div class="table-responsive py-2">
            <table class="table table-hover js-basic-example dataTable table-custom m-b-0">
                <thead>
                    <tr>    
                      
                        <th >Supplier name</th>
                        <th >Supplier invoice number</th>
                       
                    </tr>
                </thead>
                <tbody >
                   <tr class="cursor-pointer" *ngFor="let customer of suppliers| paginate: { itemsPerPage: itemsPerPage , currentPage: p, totalItems: totalItems } ;let i=index "  (click)="viewDetails(customer)">
                       <ng-container *ngIf="customer.supplierName">
                        <td  >
                            {{customer.supplierName}} 
                        </td>
                        <td >
                            {{customer.supplierInvoiceNumber}} 
                        </td>
                    </ng-container>
                        
                    </tr>
                    <tr *ngIf="!loading && suppliers.length==0">
                        <td colspan="10" class="text-center">
                            No suppliers.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
        </div>
        
    </div>
  </div>
</div>

