<div class="col-sm-9 col-md-7 col-lg-4 mx-auto pt-5">
  <div class="card card-signin my-5">
    <div class="card-body">
      <h5 class="card-title text-center">
        {{ 'HEADER.RESET_PASSWORD' | translate }}
      </h5>
      <div *ngIf="errorMessage">
        <div class="text-danger p-3" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <form
        class="form-signin"
        [formGroup]="forgotForm"
        (ngSubmit)="fogotPassword()"
        autocomplete="off"
      >
        <div
          class="form-label-group1 d-flex align-items-center justify-content-center"
        >
          <label class="text-center  mb-0"
            >{{ 'MESSAGES.ENTER_THE_CODE' | translate }}
          </label>
          <input
            type="text"
            maxlength="6"
            id="otpCode"
            formControlName="otpCode"
            class="otpInput"
          />
          <!-- <label for="inputEmail">Email address</label> -->
        </div>
        <div *ngIf="submitted && f.otpCode.errors" class="text-danger">
          <div *ngIf="f.otpCode.errors.required">
            {{ 'HEADER.otpCode' | translate }}
          </div>
          <div *ngIf="f.otpCode.errors.minlength">
            {{ 'HEADER.otpCode' | translate }}
          </div>
        </div>
        <div class="form-label-group1 position-relative">
          <input
            type="password"
            id="newPassword"
            formControlName="newPassword"
            class="form-control custominp"
            placeholder="{{ 'HEADER.NEW_PASSWORD' | translate }}"
            autocomplete="off"
            appAppPassword
          />
          <!-- <label for="inputEmail">Email address</label> -->
        </div>
        <div *ngIf="submitted && f.newPassword.errors" class="text-danger">
          <div *ngIf="f.newPassword.errors.required">
            {{ 'HEADER.PASSWORD_IS_REQUIRED' | translate }}
          </div>
          <div *ngIf="f.newPassword.errors.minlength">
            {{ 'HEADER.PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate }}
          </div>
        </div>
        <div class="form-label-group1 position-relative my-3">
          <input
            type="password"
            id="confirmPassword"
            formControlName="confirmPassword"
            class="form-control custominp"
            placeholder="{{ 'HEADER.CONFIRM_PASSWORD' | translate }}"
            appAppPassword
            autocomplete="new-password"
          />
          <!-- <label for="inputPassword">Password</label> -->
        </div>
        <div *ngIf="submitted && f.confirmPassword.errors" class="text-danger">
          <div *ngIf="f.confirmPassword.errors.required">
            {{ 'HEADER.CONFIRM_PASSWORD_IS_REQUIRED' | translate }}
          </div>
          <div *ngIf="f.confirmPassword.errors.mustMatch">
            {{ 'HEADER.PASSWORDS_MUST_MATCH' | translate }}
          </div>
        </div>

        <button
          class="btn btn-lg btn-primary btn-block text-uppercase"
          type="submit"
          [disabled]="!forgotForm.valid"
        >
          {{ 'COMMON.SUBMIT' | translate }}
        </button>
        <div class="text-center pt-2 d-flex justify-content-between">
          <!-- <a *ngIf="!withEmail" (click)="withEmail=true" class="">Signin with email </a>
              <a *ngIf="withEmail" (click)="withEmail=false">Signin with phone number</a> -->

          <a [routerLink]="['/forgot-password']">{{
            'COMMON.BACK' | translate
          }}</a>
          <a (click)="resendCode()">{{
            'MESSAGES.RESEND_THE_CODE' | translate
          }}</a>
        </div>
      </form>
    </div>
  </div>
</div>
