import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appAppPassword]'
})
export class AppPasswordDirective {
private _shown = true;
constructor(private el: ElementRef) {
    this.setup();
  }
toggle(span: HTMLElement) {
    
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = '<i class="fa fa-eye-slash position-absolute cursor-pointer" aria-hidden="true" style="top:10px;right:15px"></i>';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = '<i class="fa fa-eye position-absolute" aria-hidden="true" style="top:10px;right:15px"></i>';
    }
    this._shown = !this._shown;
  }
setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.innerHTML = `<i class="fa fa-eye position-absolute cursor-pointer" aria-hidden="true" style="top:10px;right:15px"></i>`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
