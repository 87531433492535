<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
    <div class="col-md-12  px-0">
      <div class="col-md-12 p-2">
        <div class="card px-md-3 py-3 my-3 ">
          <!-- <h4 class="pb-5 innerTitle">My Teams</h4> -->
        
       
          <!---Import outlook contacts-->
          <!-- Nav tabs -->
            <ul class="nav nav-tabs">
            
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab"  href="#csv" (click)="isExcelTab=false;"> Import Products</a>
              </li>
            	<li
				class="rightIcon ml-auto"  (click)="goBack()"
				 ><img src="assets/back.svg?x=1" class="arrowIconn"  /> 
				 Back </li>
            
            </ul>

            <!-- Tab panes -->
            <div class="tab-content pt-3">
            
              <div class="tab-pane container"  [ngClass]="{ 'active':activeTab=='csv'}"  id="csv">
                <ng-container *ngIf="!csvImportStatus && !isExcelTab">
                  <h6>Please select .xls file to import product data</h6>
                  <div class="py-2">
                    <input type="file" accept=".csv" class="upload" (change)="changeListener($event.target.files,'csv')">
                  </div>
                </ng-container>
                <ng-container *ngIf="!excelImportStatus && isExcelTab">
                  <h6>Please select .excel file to import products data</h6>
                  <div class="py-2">
                    <input type="file" accept=".xls,.xlsx" class="upload" (change)="changeListener($event.target.files,'excel')">
                  </div>
                </ng-container>
              
                <div class="text-center" *ngIf="csvImportStatus || excelImportStatus">
                  <img src="assets/loading.gif" width="150px" height="auto" />
                  <h5 class="text-center">Importing contacts. we are processing your request. You can continue to use StayTouch.</h5>
                </div>

                <div class="col-md-12 px-0 pt-3">
                  <button class="btn btn-primary" *ngIf="showTable" (click)="syncAllFields()"  [disabled]="buttonStatus==true">Import Products</button>
                  <ng-container *ngIf="false">
                    <a class="btn btn-primary mb-3 float-right" (click)="addQuantity()" *ngIf="showTable">Add Field Mapping</a>
                    <div class="d-flex col-md-12 px-0">
                     
                      <form [formGroup]="syncForm" *ngIf="showTable" class="w-100">
        
                      <table class="table table-bordered" formArrayName="quantities">
                        <tr class="col-md-12">
                          <th>StayTouch Fields</th>
                          <th>Csv Fields</th>
                          <th width="150px">
                            Action
                          </th>
                        </tr>
                        <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
                          <td style="width: 250px;vertical-align: middle;">
                            <ngx-select
                      [items]="contactFields"
                      optionValueField="name"
                      optionTextField="displayName"
                      [placeholder]	= "'Select Field'"
                      formControlName="stField" 
                      class="theme-select team-select"
                      >
                      <ng-template
                        ngx-select-option
                        ngx-select-option-selected
                        let-option
                        let-text="text"
                      >
                        <div class="d-flex dropdown-custom py-2">
                        <div class="pl-2">
                          <div class="text-gray11 optionClr">
                          {{ option.data.displayName }}
                          </div>
                        </div>
                        </div>
                      </ng-template>
        
                      <ng-template ngx-select-option-not-found let-input>
                        {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
                      </ng-template>
                      </ngx-select>
                          </td>
                          <td style="width: 250px;vertical-align: middle;">
                          <ngx-select
                            [items]="csvobjFields"
                            optionValueField="name"
                            optionTextField="name"
                            [placeholder]	= "'Select Field'"
                            formControlName="sfField"
                            class="theme-select team-select"
                          >
                            <ng-template
                            ngx-select-option
                            ngx-select-option-selected
                            let-option
                            let-text="text"
                            >
                            <div class="d-flex dropdown-custom py-2">
                              <div class="pl-2">
                              <div class="text-gray11 optionClr">
                                {{ option.data.name }}
                              </div>
                              </div>
                            </div>
                            </ng-template>
              
                            <ng-template ngx-select-option-not-found let-input>
                            {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
                            </ng-template>
                          </ngx-select>
                          </td>
                          <td >
                          <button (click)="removeQuantity(i)" class="btn btn-danger">Remove</button>
                          </td>
                        </tr>
                        </table>
                        <div class="text-center" *ngIf="f.quantities.value.length>0">
                        
                        </div>
                       
                    </form>
                    </div>
                  </ng-container>
                   
                </div>
              </div>
              
              
            
              
            </div>
        </div>
      </div>
    </div>
  </div>
  
 