import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Apollo, QueryRef } from 'apollo-angular'
import { TranslateService } from '@ngx-translate/core'
const moment = require('moment-timezone')

import {
createProductMutution,categoryQuery,updateProductMutution,createProductImageMutution,productsQuery,deleteAttachmentMutution,customersQuery,updateCustomerMutution
} from '../../../services/products.gql'
import { HelperService } from '../../../services/helper.service'
@Component({
  selector: 'app-update-order',
  templateUrl: './update-order.component.html',
  styleUrls: ['./update-order.component.scss']
})
export class UpdateOrderComponent implements OnInit {
  isUserAllow:boolean = true
accountId:any
  categoriesList:any =[]
  public members: any
  private query: QueryRef<any>
  private query2: QueryRef<any,any>
  permissionsRes:any ={}
  submitted:boolean=false;
  loading:boolean = false
  buttonEl: any
  placement = 'bottom-left'
  public orderForm: FormGroup
  customerId:any
  variantImages:any =[]
  selectedVarientIndexForImages:any=0
  customerInfo:any = {}
  page:any =1
  type:any=1
  p:any
  role:any = ''
  totalAmount:any =0
  products:any=[]
  cartList:any = []
  paymentTypes:any =[
    {
      id:"CARD",
      name:"Card",
    },
    {
      id:"CHEQUE",
      name:"Cheque",
    },
    {
      id:"CASH",
      name:"Cash",
    },
    {
      id:"CHEQUE_AND_CASH",
      name:"Cheque & Cash",
    },
    {
      id:"A_RECUPERER",
      name:"A Recuperer",
    },
    {
      id:"BANK_TRANSFER",
      name:"Bank Transfer",
    },
  ]
  chequestCount:any =[]
  searchText:any =""
  filterByColumn:any
  filterColumnValue:any
  goldColors:any =[
    "ROSE", "WHITE", "YELLOW","MIXED"
  ]
  totalFinalSellingAmount:any =0
  constructor(
    private apollo: Apollo,
    private translate: TranslateService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private toastr: ToastrService,private router: Router,private helperService:HelperService,  private el: ElementRef
    ) { 
      for(let i=1;i<=10;i++){
       this.chequestCount.push(i)
      }   
       this.orderForm = this.formBuilder.group({
        productCategory: [0],
        firstName: [''],
        lastName: [''],
        email:['' ],
        phone: [''],
        description:[''],
        city: [''],
        ringSize: [''],
        goldColor: [''],
        quantity: [''],
        additionalInformation: [''],

        actualSellingPrice:[''],
        paymentMethod:[0],
        numberOfCheques:[0],
        chequeDate1:[],
        chequeDate2:[],
        chequeDate3:[],
        chequeDate4:[],
        chequeDate5:[],
        chequeDate6:[],
        chequeDate7:[],
        chequeDate8:[],
        chequeDate9:[],
        chequeDate10:[],
        cashAmount:[''],
        chequeDate1Amount:[''],
        chequeDate2Amount:[''],
        chequeDate3Amount:[''],
        chequeDate4Amount:[''],
        chequeDate5Amount:[''],
        chequeDate6Amount:[''],
        chequeDate7Amount:[''],
        chequeDate8Amount:[''],
        chequeDate9Amount:[''],
        chequeDate10Amount:[''],
        actionType:[''],
        sellingPrice:[''],
        advanceAmount:[''],
        remainingDueAmount:[''],
        timezone: [moment.tz.guess()],
        additionalDiscount:[''],
        effectiveSalesPrice:[''],
        heldAmount:['0'],
        amountToCollect:['0']

       
    })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {filterByColumn,filterColumnValue,searchText} :any= this.router.getCurrentNavigation().extras.state
      if(searchText){
        this.searchText = searchText
      }
      if(filterByColumn && filterColumnValue){
        this.filterByColumn = filterByColumn
        this.filterColumnValue = filterColumnValue
      }
    }
   }

  ngOnInit() {
    // this.orderForm.patchValue({
    //   numberOfCheques:0,
    //   paymentMethod:0
    // })
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.customerId = params.id
        // this.getProjectInfo()
      }
      if(params.page){
        this.page = params.page
      }
      this.orderInfo()

    })
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
     this.accountId = data.accountInformation.id
      this.role = data.accountInformation.role 
      if(this.role == 'SALES' && !this.checkUserIds() ){
        this.router.navigate(['/product-enquires',this.page], {
          
        })
      }
    })
   
  }



  saveForm () {
    this.submitted = true
    const firstName = this.f.firstName.value
    const lastName = this.f.lastName.value
    const email = this.f.email.value
    const phone = this.f.phone.value
    const description = this.f.description.value
    const additionalInformation = this.f.additionalInformation.value
    const city = this.f.city.value
    const ringSize = this.f.ringSize.value
    const goldColor = this.f.goldColor.value
    const paymentMethod = this.f.paymentMethod.value
    const actualSellingPrice = parseFloat(this.f.actualSellingPrice.value)
    const chequeDate1Amount = parseFloat(this.f.chequeDate1Amount.value)
    const chequeDate2Amount = parseFloat(this.f.chequeDate2Amount.value)
    const chequeDate3Amount = parseFloat(this.f.chequeDate3Amount.value)
    const chequeDate4Amount = parseFloat(this.f.chequeDate4Amount.value)
    const chequeDate5Amount = parseFloat(this.f.chequeDate5Amount.value)
    const chequeDate6Amount = parseFloat(this.f.chequeDate6Amount.value)
    const chequeDate7Amount = parseFloat(this.f.chequeDate7Amount.value)
    const chequeDate8Amount = parseFloat(this.f.chequeDate8Amount.value)
    const chequeDate9Amount = parseFloat(this.f.chequeDate9Amount.value)
    const chequeDate10Amount = parseFloat(this.f.chequeDate10Amount.value)
    const actionType = this.f.actionType.value
    const sellingPrice = this.f.sellingPrice.value
    const advanceAmount = parseFloat(this.f.advanceAmount.value)
    const remainingDueAmount = parseFloat(this.f.remainingDueAmount.value)
    const cashAmount = parseFloat(this.f.cashAmount.value)
    const numberOfCheques = parseInt(this.f.numberOfCheques.value)
    const additionalDiscount= this.f.additionalDiscount.value ? this.f.additionalDiscount.value.toString() : undefined
    const effectiveSalesPrice= this.f.effectiveSalesPrice.value ? this.f.effectiveSalesPrice.value.toString() : undefined
    let chequeDate1 = this.f.chequeDate1.value
    if(chequeDate1){
      
      chequeDate1 = `${chequeDate1.year}-${chequeDate1.month}-${chequeDate1.day}`
        chequeDate1 = moment(chequeDate1,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
        chequeDate1 = moment
      .tz(chequeDate1, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate2 = this.f.chequeDate2.value

    if(chequeDate2){
      chequeDate2 = `${chequeDate2.year}-${chequeDate2.month}-${chequeDate2.day}`

      chequeDate2 = moment(chequeDate2,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')

      chequeDate2 = moment
      .tz(chequeDate2, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate3 = this.f.chequeDate3.value
    if(chequeDate3){
      chequeDate3 = `${chequeDate3.year}-${chequeDate3.month}-${chequeDate3.day}`
      chequeDate3 = moment(chequeDate3,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate3 = moment
      .tz(chequeDate3, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate4 = this.f.chequeDate4.value
    if(chequeDate4){
      chequeDate4 = `${chequeDate4.year}-${chequeDate4.month}-${chequeDate4.day}`
      chequeDate4 = moment(chequeDate4,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate4 = moment
      .tz(chequeDate4, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate5 = this.f.chequeDate5.value
    if(chequeDate5){
      chequeDate5 = `${chequeDate5.year}-${chequeDate5.month}-${chequeDate5.day}`
      chequeDate5 = moment(chequeDate5,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
        chequeDate5 = moment
      .tz(chequeDate5, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate6 = this.f.chequeDate6.value
    if(chequeDate6){
      chequeDate6 = `${chequeDate6.year}-${chequeDate6.month}-${chequeDate6.day}`
      chequeDate6 = moment(chequeDate6,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate6 = moment
      .tz(chequeDate6, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate7 = this.f.chequeDate7.value
    if(chequeDate7){
      chequeDate7 = `${chequeDate7.year}-${chequeDate7.month}-${chequeDate7.day}`
      chequeDate7 = moment(chequeDate7,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate7 = moment
      .tz(chequeDate7, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate8 = this.f.chequeDate8.value
    if(chequeDate8){
      chequeDate8 = `${chequeDate8.year}-${chequeDate8.month}-${chequeDate8.day}`
      chequeDate8 = moment(chequeDate8,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate8 = moment
      .tz(chequeDate8, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate9 = this.f.chequeDate9.value
    if(chequeDate9){
      chequeDate9 = `${chequeDate9.year}-${chequeDate9.month}-${chequeDate9.day}`
      chequeDate9 = moment(chequeDate9,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate9 = moment
      .tz(chequeDate9, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate10 = this.f.chequeDate10.value
    if(chequeDate10){
      chequeDate10 = `${chequeDate10.year}-${chequeDate10.month}-${chequeDate10.day}`
      chequeDate10 = moment(chequeDate10,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate10 = moment
      .tz(chequeDate10, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    
    const invalid = [];
    const controls = this.orderForm.controls;

    for (const key of Object.keys(this.orderForm.controls)) {
		if (this.orderForm.controls[key].invalid) {
			const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
			// invalidControl.focus();
			// break;
		 }
	}
  const heldAmount = this.f.heldAmount.value ? this.f.heldAmount.value.toString() : "0"
  const amountToCollect = this.f.amountToCollect.value ? this.f.amountToCollect.value.toString() : "0"
    let productObj:any ={
      firstName,
      lastName,
      phone,
      email,
      description,
      ringSize,
      city,
      paymentMethod,
      actualSellingPrice,
      chequeDate1Amount,
      chequeDate2Amount,
      chequeDate3Amount,
      chequeDate4Amount,
      chequeDate5Amount,
      chequeDate7Amount,
      chequeDate6Amount,
      chequeDate8Amount,
      chequeDate9Amount,
      chequeDate10Amount,
      actionType,
      sellingPrice,
      advanceAmount,
      remainingDueAmount,
      numberOfCheques,
      cashAmount,
      chequeDate10,
      chequeDate9,
      chequeDate8,
      chequeDate7,
      chequeDate6,
      chequeDate5,
      chequeDate4,
      chequeDate3,
      chequeDate2,
      chequeDate1,
      goldColor,
      additionalInformation,
      heldAmount,
      amountToCollect
    }
  
    productObj = Object.entries(productObj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    // if(chequeDate1Amount != null && chequeDate1Amount){
    //   productObj.chequeDate1Amount = chequeDate1Amount 
    // }
    // if(chequeDate2Amount != null && chequeDate2Amount){
    //   productObj.chequeDate2Amount = chequeDate2Amount
    // }
    // if(chequeDate3Amount != null && chequeDate3Amount){
    //   productObj.chequeDate3Amount = chequeDate3Amount
    // }
    // if(chequeDate4Amount != null && chequeDate4Amount){
    //   productObj.chequeDate4Amount = chequeDate4Amount
    // }
    // if(chequeDate5Amount != null && chequeDate5Amount){
    //   productObj.chequeDate5Amount = chequeDate5Amount
    // }
    // if(chequeDate6Amount != null && chequeDate6Amount){
    //   productObj.chequeDate6Amount = chequeDate6Amount
    // }
    // if(chequeDate7Amount != null && chequeDate7Amount){
    //   productObj.chequeDate7Amount = chequeDate7Amount
    // }
    // if(chequeDate8Amount != null && chequeDate8Amount){
    //   productObj.chequeDate8Amount = chequeDate8Amount
    // }
    // if(chequeDate9Amount != null && chequeDate9Amount){
    //   productObj.chequeDate9Amount = chequeDate9Amount
    // }
    // if(chequeDate10Amount != null && chequeDate10Amount){
    //   productObj.chequeDate10Amount = chequeDate10Amount
    // }
    // if(cashAmount != null && cashAmount){
    //   productObj.cashAmount = cashAmount
    // }
    // if(actualSellingPrice != null && actualSellingPrice){
    //   productObj.actualSellingPrice = actualSellingPrice
    // }
    productObj.cashAmount = cashAmount ? cashAmount :0
    productObj.actualSellingPrice = actualSellingPrice ? actualSellingPrice :0
    if(chequeDate1){
      
      productObj.chequeDate1Amount = productObj.chequeDate1Amount ? productObj.chequeDate1Amount :0
    }
    if(chequeDate2){
      
      productObj.chequeDate2Amount = productObj.chequeDate2Amount ? productObj.chequeDate2Amount :0
    }
    if(chequeDate3){
      
      productObj.chequeDate3Amount = productObj.chequeDate3Amount ? productObj.chequeDate3Amount :0
    }
    if(chequeDate4){
      
      productObj.chequeDate4Amount = productObj.chequeDate4Amount ? productObj.chequeDate4Amount :0
    }
    if(chequeDate5){
      
      productObj.chequeDate5Amount = productObj.chequeDate5Amount ? productObj.chequeDate5Amount :0
    }
    if(chequeDate6){
      
      productObj.chequeDate6Amount = productObj.chequeDate6Amount ? productObj.chequeDate6Amount :0
    }
    if(chequeDate7){
      
      productObj.chequeDate7Amount = productObj.chequeDate7Amount ? productObj.chequeDate7Amount :0
    }
    if(chequeDate8){
      
      productObj.chequeDate8Amount = productObj.chequeDate8Amount ? productObj.chequeDate8Amount :0
    }
    if(chequeDate9){
      
      productObj.chequeDate9Amount = productObj.chequeDate9Amount ? productObj.chequeDate9Amount :0
    }
    if(chequeDate10){
      productObj.chequeDate10Amount = productObj.chequeDate10Amount ? productObj.chequeDate10Amount :0
    }

   
    if(additionalDiscount){
      productObj.additionalDiscount = additionalDiscount
    }
    if(effectiveSalesPrice){
      productObj.effectiveSalesPrice = effectiveSalesPrice
    }
    
    let cartProductIds:any = []
    let quanitiesArray:any = []
    this.products.forEach((element,j) => {
      let i =0;
      while(i<element.quantity){
         cartProductIds.push(element.id)
        i++;
      }
      quanitiesArray.push({
        pid: element.id,
        qty:element.quantity,
        finalSellingPrice : element.finalPrice
      })
    });
    productObj.purchasedProducts = cartProductIds.toString()
    productObj.cart = JSON.stringify(quanitiesArray).replace(/"/g, "'");


    // return
    if(this.customerId){
      this.updateCustomer(productObj)
    }
   
   
   
  }

  updateCustomer (productObj) {
    productObj.id= this.customerId
    productObj.productId = this.customerInfo.product.id
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateCustomerMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          
          this.toastr.info("order details updated successfully.")
          this.router.navigate(['/product-enquires',this.page,this.type], {
            state: {
              searchText:this.searchText,
              filterByColumn:this.filterByColumn,
              filterColumnValue : this.filterColumnValue,
             
            }
          })
          
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }


  get f () {
    return this.orderForm.controls
  }
  setSellingPrice(){
    let margin = this.f.margin.value ? this.f.margin.value : 0
    let displayedSellingPrice = parseFloat(this.f.costMAD.value) / (1- (margin/100))

    this.orderForm.patchValue({
      displayedSellingPriceInMAD: Math.round(displayedSellingPrice)
    })
    this.getDiscountPrice()
  }
  getDiscountPrice(){
    let discuountPrice = this.f.displayedSellingPriceInMAD.value - (this.f.displayedSellingPriceInMAD.value * this.f.maximumDiscountAllowed.value / 100)
    this.orderForm.patchValue({
      minimumSellingPriceInMAD: Math.round(discuountPrice)
    })
  }

  
  

  orderInfo () {

    this.loading = true
    this.query2 = this.apollo.watchQuery({
      query: customersQuery,
      fetchPolicy:'network-only',
      variables:{
        id:this.customerId
      }
    })

     this.query2.valueChanges.subscribe(({ data }: any) => {
      this.loading = false
     this.customerInfo = data.customers[0]
     let cartString:any
     if(this.customerInfo.cart){
         cartString = this.customerInfo.cart.replace(/'/g, '"')
        cartString = JSON.parse(cartString)
        this.cartList =cartString
     }

     if(this.customerInfo.purchasedProducts && this.customerInfo.purchasedProducts.length > 0){
      let arr2 :any= [];
    
      let key ="id"
      this.customerInfo.purchasedProducts.forEach((x)=>{
           
        // Checking if there is any object in arr2
        // which contains the key value
         if(arr2.some((val)=>{ return val[key] == x[key] })){
             
           // If yes! then increase the occurrence by 1
           arr2.forEach((k)=>{
             if(k[key] === x[key]){ 
               k["occurrence"]++
             }
          })
             
         }else{
           // If not! Then create a new object initialize 
           // it with the present iteration key's value and 
           // set the occurrence to 1
           let a :any= {}
           a =x
           a[key] = x[key]
           a["occurrence"] = 1
           arr2.push(a);
         }
      })
      //  
      this.products = arr2
      this.products.forEach((item,k)=>{
          if(cartString){
            let matchObj = cartString.find(item2=>item2.pid == item.id)
            this.products[k].quantity = matchObj.qty
            this.products[k].finalPrice = matchObj.finalSellingPrice
            this.totalAmount = this.totalAmount+ item.displayedSellingPrice
            this.totalFinalSellingAmount = this.totalFinalSellingAmount + parseInt(this.products[k].finalPrice)
          }
         
       
      })    
    }    

    let {firstName,lastName,email,phone,
      description,
      chequeDate1,
      chequeDate2,
      chequeDate3,
      chequeDate4,
      chequeDate5,
      chequeDate6,
      chequeDate7,
      chequeDate8,
      chequeDate9,
      chequeDate10,
      numberOfCheques,
      chequeDate1Amount,
      chequeDate2Amount,
      chequeDate3Amount,
      chequeDate4Amount,
      chequeDate5Amount,
      chequeDate6Amount,
      chequeDate7Amount,
      chequeDate8Amount,
      chequeDate9Amount,
      chequeDate10Amount,
      actionType,
      sellingPrice,
      advanceAmount,
      remainingDueAmount,
      ringSize,
      city,
      paymentMethod,
      actualSellingPrice,
      cashAmount,
      additionalInformation,
      goldColor,
      effectiveSalesPrice,
      additionalDiscount,
      heldAmount,
      amountToCollect
      
    } = this.customerInfo
    if(actualSellingPrice){
      heldAmount = heldAmount ? heldAmount : 0
      amountToCollect = actualSellingPrice - heldAmount
    }
    if(actionType=='BUY'){
      this.type=1
    }
    else{
      this.type=2
    }
    // let startDate = moment(createdAt)

      
        if(chequeDate1){
          this.setChequeDate(chequeDate1,"chequeDate1")
         }
         if(chequeDate2){
          this.setChequeDate(chequeDate2,"chequeDate2")
         }
         if(chequeDate3){
          this.setChequeDate(chequeDate3,"chequeDate3")
         }
         if(chequeDate4){
          this.setChequeDate(chequeDate4,"chequeDate4")
         }
         if(chequeDate5){
          this.setChequeDate(chequeDate5,"chequeDate5")
         }
         if(chequeDate6){
          this.setChequeDate(chequeDate6,"chequeDate6")
         }
         if(chequeDate7){
          this.setChequeDate(chequeDate7,"chequeDate7")
         }
         if(chequeDate8){
          this.setChequeDate(chequeDate8,"chequeDate8")
         }
         if(chequeDate9){
          this.setChequeDate(chequeDate9,"chequeDate9")
         }
         if(chequeDate10){
          this.setChequeDate(chequeDate10,"chequeDate10")
         }
         description = this.replaceST(description)
        this.orderForm.patchValue({
          firstName:firstName,
          description: description,
          lastName: lastName,
          email:email,
          phone:phone,
          ringSize:ringSize,
          city:city,
          chequeDate1Amount:chequeDate1Amount,
          chequeDate2Amount:chequeDate2Amount,
          chequeDate3Amount:chequeDate3Amount,
          chequeDate4Amount:chequeDate4Amount,
          chequeDate5Amount:chequeDate5Amount,
          chequeDate6Amount:chequeDate6Amount,
          chequeDate7Amount:chequeDate7Amount,
          chequeDate8Amount:chequeDate8Amount,
          chequeDate9Amount:chequeDate9Amount,
          chequeDate10Amount:chequeDate10Amount,
          actualSellingPrice:actualSellingPrice,
          paymentMethod:paymentMethod ? paymentMethod:0,
          numberOfCheques:numberOfCheques ? numberOfCheques:0,
          sellingPrice:sellingPrice,
          advanceAmount:advanceAmount,
          remainingDueAmount:remainingDueAmount,
          cashAmount:cashAmount,
          actionType:actionType,
          additionalInformation:additionalInformation,
          goldColor:goldColor,
          effectiveSalesPrice:effectiveSalesPrice,
          additionalDiscount:additionalDiscount,
          heldAmount:heldAmount,
          amountToCollect:amountToCollect
         
        })
      
        this.loading = false
      
    },err=>{
      this.loading = false
    })
  }
  
 
  setChequeDate(date,name){
    date = moment(date)

    this.orderForm.patchValue({
      [name]: {
      year: parseInt(date.format('YYYY')),
      month: parseInt(date.format('M')),
      day: parseInt(date.format('D'))
      }
    })
  }

  counter(i: number) {
    return new Array(i);
  }
  changePaymentMethod(event){
    if(event.target.value!='CHEQUE' && event.target.value!='CHEQUE_AND_CASH'){
      this.orderForm.patchValue({
        numberOfCheques:0
      })
      this.orderForm.patchValue({
        chequeDate1:"",
        chequeDate2:"",
        chequeDate3:"",
        chequeDate4:"",
        chequeDate5:"",
        chequeDate6:"",
        chequeDate7:"",
        chequeDate8:"",
        chequeDate9:"",
        chequeDate10:"",
        cashAmount:[''],
        chequeDate1Amount:"",
        chequeDate2Amount:"",
        chequeDate3Amount:"",
        chequeDate4Amount:"",
        chequeDate5Amount:"",
        chequeDate6Amount:"",
        chequeDate7Amount:"",
        chequeDate8Amount:"",
        chequeDate9Amount:"",
        chequeDate10Amount:"",
      })
    }
     if(event.target.value!='CASH' && event.target.value!='CHEQUE_AND_CASH'){
      this.orderForm.patchValue({
        cashAmount:""
      })
    }

  }
  gotoOrders(){
    this.router.navigate(['/product-enquires',this.page,this.type], {
      state: {
        searchText:this.searchText,
        filterByColumn:this.filterByColumn,
        filterColumnValue : this.filterColumnValue,
       
      }
    })
  }

  replaceST(des){
    return des.split("_ST_NL_").join("\n");
   }
   changeQuantity(product,k){
    if(product.stockQuantity < product.quantity && product.quantity != this.cartList[k].qty){ 
      alert('The quantity is higher than the maximum allowed quantity of '+ product.stockQuantity)
      if(this.products[k]){
        setTimeout(() => {
          this.products[k].quantity = product.stockQuantity > this.cartList[k].qty ? product.stockQuantity : this.cartList[k].qty
        }, 1);
      }
    }
    setTimeout(() => {
      let totalAmount = 0
      let totalFinalSellingAmount = 0
      this.products.forEach((element,j) => {
        let quantityPrice = element.displayedSellingPrice * element.quantity
        totalAmount = totalAmount+ quantityPrice
        this.products[j].quantity = element.quantity
        let totalSellingPrice = element.displayedSellingPrice * element.quantity
        this.products[j].finalPrice = totalSellingPrice
        totalFinalSellingAmount = totalFinalSellingAmount + parseInt(this.products[j].finalPrice)
      });
      this.totalAmount = totalAmount
      this.totalFinalSellingAmount = totalFinalSellingAmount
      let discountAmount = parseInt(this.f.additionalDiscount.value) ? parseInt(this.f.additionalDiscount.value) : 0

      this.orderForm.patchValue({
        actualSellingPrice:totalFinalSellingAmount,
        effectiveSalesPrice: totalFinalSellingAmount - discountAmount
      })
    },2)
  }
  changePrice(){
    let totalAmount = 0
    let totalFinalSellingAmount:number = 0
    this.products.forEach((element,j) => {
      let quantityPrice = element.displayedSellingPrice * element.quantity
      totalAmount = totalAmount+ quantityPrice
      this.products[j].quantity = element.quantity
      let totalSellingPrice = element.finalPrice 
      // this.products[j].finalPrice = totalSellingPrice
      totalFinalSellingAmount =totalFinalSellingAmount + parseInt(totalSellingPrice)
    });
    let discountAmount = parseInt(this.f.additionalDiscount.value) ? parseInt(this.f.additionalDiscount.value) : 0

    this.totalAmount = totalAmount
    this.totalFinalSellingAmount = totalFinalSellingAmount
    this.orderForm.patchValue({
      actualSellingPrice:totalFinalSellingAmount,
      effectiveSalesPrice: totalFinalSellingAmount - discountAmount
    })
  }
   updateRemainingAmount(){
     let remainingDueAmount = this.f.actualSellingPrice.value - this.f.advanceAmount.value
     let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value
     this.orderForm.patchValue({
      remainingDueAmount:remainingDueAmount,
      amountToCollect:amountToCollect
     })
   }
   updateAmoutCollect(){
     if(this.f.heldAmount.value > this.f.actualSellingPrice.value){
		this.orderForm.patchValue({
			heldAmount:0
		})
       alert("Held amount cannot be higher than actual selling price")
       return false
     }
    let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value
    this.orderForm.patchValue({
     amountToCollect:amountToCollect
    })
   }
   setFinalSellingPrice(){
    let discountAmount = this.f.additionalDiscount.value ? this.f.additionalDiscount.value : 0
    this.orderForm.patchValue({
      effectiveSalesPrice : this.f.actualSellingPrice.value - discountAmount
    })
  }
     checkUserIds(){
    return [
       "160721",
       "160875",
       "160873",
       "160720",
       "160731","158530"].includes(this.accountId)
   }
   
}
