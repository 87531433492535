<div class="d-sm-flex justify-content-between w-100 mb-0">
  <ul
    class="list-inline listInline py-2 pl-0 d-flex mb-3   col-md-12 nav nav-tabs acSec"
  >
    <!-- <li class="nav-item" ><a class="textColor active" data-toggle="tab"  href="#myAccount">My Account</a></li> -->
    <li class="nav-item px-3">
      <a
        class="textColor active"
        routerLinkActive="active-account"
        [routerLink]="['/update-account']"
      >
        {{ 'ACCOUNT.MY_ACCOUNT' | translate }}
      </a>
    </li>
    <li class="nav-item px-3">
      <a
        class="textColor "
        routerLinkActive="active-account"
        [routerLink]="['/my-profile']"
        [ngClass]="{'active-account': activeCls}"
      >
        {{ 'ACCOUNT.MY_PROFILE' | translate }}
      </a>
    </li>
    <li class="nav-item px-3">
      <a
        class="textColor"
        routerLinkActive="active-account"
        [routerLink]="['/my-teams']"
      >
        {{ 'ACCOUNT.MY_TEAMS' | translate }}
      </a>
    </li>
    <li class="nav-item px-3" >
      <a
        class="textColor"
        routerLinkActive="active-account"
        [routerLink]="['/reset-password']"
      >
        {{ 'HEADER.RESET_PASSWORD' | translate }}
      </a>
    </li>
    <li class="nav-item px-3">
      <a
        class="textColor"
        routerLinkActive="active-account"
        [routerLink]="['/merge-account']"
      >
      StayTouch App
      </a>
    </li>
    <li class="nav-item px-3" *ngIf="specificUser">
      <a
        class="textColor"
        routerLinkActive="active-account"
        [routerLink]="['/zoom-connect']"
      >
      Integrations
      </a>
    </li>
   
    <li class="ml-auto">
		<a [routerLink]="[currentPath]" > 
			<img src="assets/back.svg?x=1" class="arrowIconn" /> {{'COMMON.BACK' | translate}}
		</a>
    </li>
  </ul>
</div>
