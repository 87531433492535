import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'

import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  productsQuery, productsAllQuery, deleteProductMutution, bulkProductUpdateMutution, updateProductMutution, archiveProductMutution, updateCartMutution
} from '../../../services/products.gql'
import { HelperService } from '../../../services/helper.service'
import { ToastrService } from 'ngx-toastr'
import { appUserConnectionsMutation } from '../../../services/user.gql';
import {
  getMembersBasicQuery,
} from '../../../services/admin.gql'
// keyboard: false
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap'
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.component.html',
  styleUrls: ['./search-products.component.scss']
})
export class SearchProductsComponent implements OnInit {
  // value: number = 100;
  minValue: any = 0
  maxValue: any = 0
  selecteAction: any = 0
  showBlock: boolean = true
  options: Options = {
    floor: 0,
    ceil: 50000,
    step: 100,
    enforceStep: false,
    enforceRange: false,
  };
  role: any = ''
  cartObj: any
  column: any =
    {
      sortType: 'ASC',
      name: 'Taj number',
      sortName: 'taj_number'
    }


  private query: QueryRef<any>
  private queryAll: QueryRef<any>
  private query$: any
  isQROpen: boolean = false
  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger = new Subject<any>();
  productList: any = [];
  profileInfo: any = [];
  categoriesList: any = []
  subCategoriesList: any = []
  subSubCategoriesList: any = []
  availabilitySizes: any = []
  productAreas: any = []
  selectedType: any
  selectedLevel2: any = {}
  selectedLevel3: any = {}
  selectedArea: any = {}
  selectedSize: any = {}
  selectedColor: any = {}
  selectedSortType: any
  loading: boolean = false
  isGrid: boolean = true
  showCheckboxes: boolean = false
  selectedProducts: any = []
  selectAll: boolean = false
  asyncMeals: Observable<string[]>;
  page: number = 1;
  total: number;
  pageSize: number = 8
  myAngularxQrCode: string | number = 'Your QR code data string';
  products: any = []
  allProducts: any = []
  totalItems: any
  itemsPerPage = 10;
  collection = [];
  searchText: any
  permissionsRes: any = {}
  permissions: any = ""
  organizationId: any
  members: any = []
  users: any = []
  accessValue: boolean
  selectProduct: any
  selectedLink: any = ''
  sortByColumn: any
  sortByPosition: any
  isFilter: boolean = false
  p: any
  isAdmin: boolean = false
  accountId: any
  type: any
  selectedProductIds: any = []
  filterColumnValue: any
  filterColumn: any
  showFilter: boolean = false
  timeout: any = null;

  theadArray = [
    {
      sortType: '',
      name: 'Category',
      sortName: 'category'
    },
    {
      sortType: '',
      name: 'Name',
      sortName: 'product_name'
    },
    {
      sortType: '',
      name: 'Inventory',
      sortName: 'stock_quantity'
    },
    {
      sortType: '',
      name: 'Taj number',
      sortName: 'taj_number'
    }

    , {
      sortType: '',
      name: 'Displayed selling price (MAD)',
      sortName: 'displayed_selling_price'
    }

  ]

  columnType: any = [


    {
      name: "Product category",
      filterName: "category",
    },
    {
      name: "Product Name",
      filterName: "product_name",
    },
    {
      name: "Supplier invoice date",
      filterName: "supplier_invoice_date",
    },
    {
      name: "Supplier name",
      filterName: "supplier_name",
    },
    {
      name: "Supplier invoice number",
      filterName: "supplier_invoice_number",
    },
    {
      name: "Design number",
      filterName: "design_number",
    },
    {
      name: "Taj number",
      filterName: "taj_number",
    },
    {
      name: "Cost in USD",
      filterName: "cost_usd",
    },
    {
      name: "USD/AED FX",
      filterName: "usd_aed_fx",
    },
    {
      name: "Cost in AED",
      filterName: "cost_aed",
    },
    {
      name: "AED/MAD FX",
      filterName: "aed_mad_fx",
    },
    {
      name: "Cost in MAD",
      filterName: "cost_mad",
    }
    , {
      name: "Margin",
      filterName: "margin",
    },
    {
      name: "Displayed selling price",
      filterName: "displayed_selling_price",
    },
    {
      name: "Maximum discount allowed",
      filterName: "max_allowed",
    },
    {
      name: "Price after discount",
      filterName: "min_selling_price",
    },
    {
      name: "Margin after discount",
      filterName: "selling_price_margin",
    },
    {
      name: "Number of items",
      filterName: "stock_quantity",
    },

    // {
    //   name:"Product",
    //   filterName:"date_import",
    // },
    {
      name: "Gold Color",
      filterName: "gold_color",
    },

    {
      name: "Gold weight",
      filterName: "gold_weight",
    },

    {
      name: "Gold Price",
      filterName: "gold_price_usd",
    },
    {
      name: "Gold Invoice Number",
      filterName: "gold_invoice_number",
    },
    {
      name: "Diamond weight",
      filterName: "diamond_weight",
    },

    {
      name: "Number of diamond stones",
      filterName: "no_diamond_stones",
    },

    {
      name: "Diamond Price",
      filterName: "diamond_price_usd",
    },

    {
      name: "Colored stones type 1",
      filterName: "colored_stones_type_one",
    },
    {
      name: "Colored stones weight 1",
      filterName: "colored_stones_weight_one",
    },

    {
      name: "Colored stones type 2",
      filterName: "colored_stones_type_two",
    },
    {
      name: "Colored stones weight 2",
      filterName: "colored_stones_weight_two",
    },
    {
      name: "Colored stones type 3",
      filterName: "colored_stones_type_3",
    },
    {
      name: "Colored stones weight 3",
      filterName: "colored_stones_weight_3",
    },
    {
      name: "Colored Stones Price ",
      filterName: "colored_stones_price_usd",
    },
    {
      name: "Making Charges",
      filterName: "making_charges_usd",
    },
    {
      name: "Product Size",
      filterName: "size",
    },
    {
      name: "Comment",
      filterName: "comment",
    },
    {
      name: "Product Location",
      filterName: "product_location",
    }
  ]
  private modalReference: NgbModalRef;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private cd: ChangeDetectorRef, private apollo: Apollo, private helperService: HelperService, private router: Router, private toastr: ToastrService, private modalService: NgbModal,) {



    this.permissionsRes = this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      this.organizationId = data.accountInformation.organizationId
      this.accountId = data.accountInformation.id
      this.role = data.accountInformation.role
      let cartInfo = data.accountInformation.cart
      if (cartInfo && cartInfo != '') {
        //  cartInfo = cartInfo.replace(/'/g, '"')
        cartInfo = decodeURIComponent(cartInfo)

        cartInfo = cartInfo.replace(/###/g, "'")
        cartInfo = JSON.parse(cartInfo)
        this.cartObj = cartInfo

      }


      this.getUserDetails()
    })
    this.isAdmin = this.helperService.isAdmin()
  }
  sortOptions = [

    {
      id: "product_name-desc",
      name: "Name -- DESC"
    },
    {
      id: "product_name-asc",
      name: "Name -- ASC"
    },
    {
      id: "stock_quantity-asc",
      name: "Quantity -- Low to High"
    },
    {
      id: "stock_quantity-desc",
      name: "Quantity -- High to Low"
    },
    // {
    //   id:"product_category-desc",
    //   name:"Category -- DESC"
    // },
    // {
    //   id:"product_category-asc",
    //   name:"Category -- ASC"
    // },
    {
      id: "cost-asc",
      name: "price -- Low to High"
    },
    {
      id: "cost-desc",
      name: "price -- High to Low"
    },

  ]
  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 10
    };
    // this.getProfileInfo()
    // this.categories()
    // this.getAllData()
    // this.getProductAreas()



  }
  filterChanged(eve) {
    this.sortByPosition = ''
    this.sortByColumn = ''
    if (eve) {
      let sortArr = eve.split("-")
      let sortColumn = sortArr[0]
      let sortPosition = sortArr[1].toUpperCase()
      this.sortByColumn = sortColumn
      this.sortByPosition = sortPosition
      this.getPage(this.page, '', sortColumn, sortPosition)
      // this.getAllProductList()

    }

    this.selectedSortType = eve
  }
  public getAllProductList() {
    this.loading = true
    const inputData: any = {
      "business_userId": this.profileInfo.id,
      "offset": (this.page * this.pageSize) - this.pageSize,
      "limit": this.pageSize,
      "filters": {
        "cat_1": this.selectedType ? [this.selectedType] : [],
        "cat_2": this.selectedLevel2.id ? [this.selectedLevel2.id] : [],
        "cat_3": this.selectedLevel3.id ? [this.selectedLevel3.id] : [],
        "sizes": this.selectedSize.id ? [this.selectedSize.id] : [],
        "area": this.selectedArea.id ? [this.selectedArea.id] : [],
        "colors": this.selectedColor.id ? [this.selectedColor.id] : [],
      },
      "sorting": this.selectedSortType ? this.selectedSortType : "newest"
    };

  }
  public getProfileInfo() {

  }
  public categories(level = 'level1', id = '') {

  }

  public getSubCategories(event, level = 'level1', id = '') {
    if (event) {
      event.preventDefault();
    }





  }
  public getAvailabilitySizes(id) {
    // return false
    // this.productService.getProductSizes(id).subscribe((data: any)=>{
    //   if(data.status == 'success')
    //   {
    //     this.availabilitySizes= data.data
    //   }
    // })
  }
  productDetails(id, page) {
    return
    let flag = 0;

    let column = this.theadArray.filter(item => {
      if (item.sortType != "") {
        flag = 1;
        return item
      }
    })

    if (flag == 0) {
      column = this.theadArray
    }

    this.router.navigate(['/product-details', id, page], {
      state: {
        columns: column[0],
        searchText: this.searchText,
        filterColumn: this.filterColumn,
        filterColumnValue: this.filterColumnValue,
        minValue: this.minValue,
        maxValue: this.maxValue
      }
    })
  }
  public getProductAreas() {

  }
  onCheckboxChange(e, type = '', selectedValue = "") {
    if (type == 'level2') {
      this.selectedLevel2 = selectedValue
    }
    if (type == 'level3') {
      this.selectedLevel3 = selectedValue
      // this.getAllProductList()

    }
    if (type == 'size') {
      this.selectedSize = selectedValue
      // this.getAllProductList()
    }
    if (type == 'area') {
      this.selectedArea = selectedValue
      // this.getAllProductList()
    }

  }
  editProducts() {
    this.showCheckboxes = true
  }
  checkUncheckAll(ev) {
    this.selectedProducts = []
    this.selectedProductIds = []
    this.products.length > 0 &&
      this.products.forEach((x, i) => {
        x.isSelected = ev
        if (ev) {
          x.isSelected = ev
          this.selectedProducts.push(x)
          this.selectedProductIds.push(x.id)
        }

      })
    this.selectAll = !this.selectAll


  }
  isAllChecked() {
    return this.products.every(item => item.isSelected)
  }
  selectCheckbox(ev, product, index) {
    if (ev.target.checked) {
      this.selectedProducts.push(product)
      this.selectedProductIds.push(product.id)
    }
    else {
      this.selectedProducts.splice(index, 1)
      this.selectedProductIds.splice(index, 1)
    }
  }
  deleteProducts() {
    let productIds = this.selectedProducts.map(item => item.id)
    if (this.selectedProducts.length > 0) {
      this.loading = true
      let textMsg: any = "Are you sure do you want to delete the product?"
      if (!confirm(textMsg)) {
        return false
      }
      else {
        this.removeProduct(productIds)
      }
      // let ids = ttotalItemshis.selectedProducts.join(',')

    }
    else {
      alert("Please select atleast one product.");
    }


  }
  selectAction(event, content) {
    let selectVal = event.target.value
    // alert(event.target.value)
    if (selectVal == 1) {
      if (this.selectedProducts.length == 0) {
        alert("Please select products to add cart");
        this.showBlock = false

        this.selecteAction = 0
        setTimeout(() => {
          this.showBlock = true
        }, 10)

      }
      else {
        this.addToCart()
      }
    }
    else if (selectVal == 2) {
      this.checkUncheckAll(false)
    }
    else if (selectVal == 3) {
      this.bulkProductUpdate(true)
    }
    else if (selectVal == 4) {
      this.bulkProductUpdate(false)
    }
    else if (selectVal == 5) {
      this.deleteProducts()
      this.showCheckboxes = false
      this.selectAll = false

    }
    else if (selectVal == 6) {
      this.showCheckboxes = false
      this.resetProducts()
    }
    else if (selectVal == 7) {
      this.showCheckboxes = true
    }
    else if (selectVal == 9) {
      this.openModal(content)
    }
    else if (selectVal == 8) {
      let getText = ``
      if (this.selectedProductIds.length > 0) {
        let oderIds = this.selectedProductIds.toString()
        getText = `?productIds=${oderIds}`
      }
      window.location.href = `https://api.staytouch.com/v2.1/public/exportProduct/${this.organizationId}${getText}`
      this.resetProducts()
    }
    else if (selectVal == 10) {
      let productIds = this.selectedProducts.map(item => item.id)
      let productIdsString = productIds.toString();
      window.location.href = `https://api.staytouch.com/v2.1/public/exportProduct/${this.organizationId}?productIds=${productIdsString}`
    }

  }
  updateProducts(status) {
    if (this.selectedProducts.length > 0) {
      let ids = this.selectedProductIds.join(',')
      const inputData: any = {
        "is_stock_available": status ? 1 : 0,
        "ids": ids,
      };
      this.loading = true

    }
    else {
      alert("Please select atleast one product.");
      this.selecteAction = 0
    }
  }
  handlePageChange(event) {
    this.page = event;
    // this.getAllProductList()
  }



  print(product): void {
    this.selectedLink = this.getLink(product.id)
    let price = product.minSellingPrice && product.minSellingPrice != null ? product.minSellingPrice : this.roundToNear(product.displayedSellingPrice)
    setTimeout(() => {
      let printContents, popupWin
      printContents = document.getElementById('print-section').innerHTML
      let print1 = document.getElementById('data').innerHTML
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
      popupWin.document.open()
      popupWin.document.write(`
          <html>
            <head>
              <title>StayTouch</title>
              <style>
              //........Customized style.......
              </style>
            </head>
        <body onload="window.print();" style="text-align:center;margin:auto;">${print1} 
        <h4 style="margin:0px;font-weight:100;">Design Number: <span style="font-weight:bold">${product.designNumber}</span></h4>
        <h4 style="margin:0px;font-weight:100;">Taj Number: <span style="font-weight:bold">${product.tajNumber}</span></h4>
        <h4 style="margin:2px;font-weight:100;">Price: <span style="font-weight:bold">${this.round(price)} MAD</span></h4>
        <h4 style="margin:2px;font-weight:100;">Displayed selling price: <span style="font-weight:bold">${this.roundToNear(product.displayedSellingPrice)} MAD</span></h4>
        <h4 style="margin:2px;font-weight:100;">Gold Weight: <span style="font-weight:bold">${product.goldWeight} grams</span></h4>
        <h4 style="margin:2px;font-weight:100;">Diamond Weight: <span style="font-weight:bold">${product.diamondWeight} ct</span></h4>
        <h3 style="text-transform: capitalize;">${product.productName}</h3>
        <!--p style="padding:10px 50px;">${product.description}.</p-->
      
        </body>
          </html>`)
      popupWin.document.close()
    }, 100)

  }
  filterProjects(eve) {
    // if(!this.searchText){
    //   alert()
    //   this.page=1
    // }
    this.searchText = ""
    this.page = 1

    // if(!eve.target.value || eve.target.value.length < 3){
    //   this.page=1
    // }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (eve.target.value.length > 0) {
        let searchText = eve.target.value
        this.searchText = searchText
        this.getPage(this.page, searchText)
        // this.getAllProducts()

      } else if (eve.target.value == 0) {
        this.getPage(1)
        this.isFilter = false
      }
    }, 1000)
  }


  getAllProducts() {
    let obj: any = {

    }
    // if(!this.isAdmin){
    //   obj.userId = this.accountId
    // }   
    if (this.searchText) {
      obj.search = this.searchText
    }
    this.queryAll = this.apollo.watchQuery({
      query: productsAllQuery,
      variables: obj,
      fetchPolicy: "no-cache"
    })

    this.queryAll.valueChanges.subscribe(({ data }: any) => {
      // this.products = data.products
      // this.cd.detectChanges();
      this.totalItems = data.products.length


    })
  }

  getPage(page, searchText = '', sortColumn = '', sortPosition = '') {
    this.loading = true
    this.page = page
    let obj: any = {
      limit: this.itemsPerPage,
      offset: (this.page * this.itemsPerPage) - this.itemsPerPage,
      stockQty: 1,
      sortByColumn:"taj_number",
      sortByPosition:"DESC"
    }
    if (this.searchText) {
      obj.search = this.searchText
    }
    if (this.sortByColumn) {
      obj.sortByColumn = this.sortByColumn
    }
    if (this.sortByPosition) {
      obj.sortByPosition = this.sortByPosition
    }

    if (this.filterColumnValue && this.filterColumn) {
      obj.filterColumnValue = this.filterColumnValue
      obj.filterByColumn = this.filterColumn
      if (this.filterColumn == 'making_charges_usd' || this.filterColumn == 'colored_stones_price_usd' || this.filterColumn == 'colored_stones_weight_two' || this.filterColumn == 'colored_stones_weight_one' || this.filterColumn == 'diamond_price_usd' || this.filterColumn == 'diamond_weight' || this.filterColumn == 'gold_price_usd' || this.filterColumn == 'min_selling_price' || this.filterColumn == 'displayed_selling_price' || this.filterColumn == 'cost_mad' || this.filterColumn == 'cost_usd' || this.filterColumn == 'cost_aed') {
        // obj.filterColumnValue  = parseFloat(obj.filterColumnValue)

      }

    }

    if (this.minValue) {
      obj.displayPriceStart = parseInt(this.minValue)
      if (!obj.displayPriceStart) {
        obj.displayPriceStart = 0
      }
    }
    if (this.maxValue) {
      if (!obj.displayPriceStart) {
        obj.displayPriceStart = 0
      }
      obj.displayPriceEnd = parseInt(this.maxValue)
    }

    this.loading = true
    this.query = this.apollo.watchQuery({
      query: productsQuery,
      fetchPolicy: "no-cache",
      variables: obj
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      // this.products = data.products
      this.products = data.products.map(element => {
        if (this.selectedProductIds.includes(element.id)) {
          element.isSelected = true
        }
        else {
          element.isSelected = false
        }
        return element
      });
      if (this.products.length > 0) {
        this.totalItems = this.products[0].totalProductsCount
      }
      else {
        this.totalItems = 0
      }
      // this.totalItems =
      this.loading = false

      this.cd.detectChanges();
    }, err => {
      this.loading = false
    })
  }
  confirmDelete(product) {
    let textMsg: any = "Are you sure do you want to delete the product?"
    if (!confirm(textMsg)) {
      return false
    }
    else {
      this.removeProduct(product.id)
    }
  }

  removeProduct(ids) {
    this.apollo
      .mutate({
        mutation: archiveProductMutution,
        variables: {
          ids: ids,
          status: true
        }
      })
      .subscribe(({ data }: any) => {

        if (ids.length > 1) {
          this.toastr.info("products deleted successufully.")
        }
        else {
          this.toastr.info("product deleted successufully.")
        }
        this.getPage(1)
        // this.getAllProducts()

      })
    this.showCheckboxes = false

  }

  bulkProductUpdate(status, users = [], removeAll = false) {
    let productIds = this.selectedProducts.map(item => item.id)
    this.apollo
      .mutate({
        mutation: bulkProductUpdateMutution,
        variables: {
          productIds: productIds,
          status: status,
          removeAll: Boolean(removeAll),
          users: users
          // users:appUserConnectionsMutation,
        }
      })
      .subscribe(({ data }: any) => {
        this.products.forEach((element, j) => {
          this.selectedProducts.map(item => {
            if (element.id == item.id) {
              this.products[j].status = status
            }
          })
        });

        this.toastr.info("products status updated successufully.")
        // console.log(data)
        // if(ids.length > 1){
        //   this.toastr.info("products deleted successufully.")
        // }
        // else{
        //   this.toastr.info("product deleted successufully.")
        // }

      })

  }
  genearteKey(id) {
    return window.btoa(`productId-${id}`)
  }
  getUserDetails() {
    this.query = this.apollo.watchQuery({
      query: getMembersBasicQuery,
      fetchPolicy: 'network-only'
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
      this.members = data.users.map(element => {
        element.name = `${element.firstName} ${element.lastName}`
        return element
      });
    })
  }

  openModal(content) {
    let ngbModalOptions: NgbModalOptions = {
      centered: true
    }
    this.modalReference = this.modalService.open(content, ngbModalOptions)
    this.modalReference.result.then(
      async result => {
        this.bulkProductUpdate(true, this.users, this.accessValue)
        this.accessValue = false
        this.users = []
      },
      reason => {

        let closeResult = `Dismissed ${this.getDismissReason(reason)}`
      }
    )
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }
  getLink(id) {
    return `https://www.staytouch.com/p/index.php?token=${this.genearteKey(id)}`
  }
  clearFilter() {
    this.searchText = ""
    this.isFilter = false
    // this.getAllProducts()
    this.page = 1

    this.getPage(1)
  }
  round(number) {
    return Math.round(number)
  }
  roundToNear(number) {
    return Math.ceil(number / 100) * 100


  }
  sortBy(column, noapicall = false) {
    this.column = column
    column = { ...column }
    this.sortByPosition = column.sortType
    if (!noapicall) {
      if (column && (column.sortType == 'ASC' || column.sortType == '')) {
        this.sortByPosition = 'DESC'
        column.sortType = 'DESC'
      }
      else {
        this.sortByPosition = 'ASC'
        column.sortType = 'ASC'
      }

    }
    let theadArray: any = this.theadArray.map((item) => {
      item.sortType = ''
      return item
    })

    setTimeout(() => {
      this.theadArray = theadArray.map((item) => {
        // console.log(column,'column4',this.sortByPosition)
        if (item.sortName == column.sortName) {
          item.sortType = this.sortByPosition
        }

        return item
      })

    }, 5)


    this.sortByColumn = column.sortName
    // this.page=1
    if (!noapicall) {
      this.getPage(this.page)
    }

    // this.getAllProducts()
  }
  filterBy(event) {
    // this.filterByColumn = event.target.value
  }
  filterProducts() {
    this.showFilter = false

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.filterColumnValue && this.filterColumn) {
        this.getPage(1)
      }
    }, 1000)
  }
  clearFilterData() {
    this.searchText = ""
    this.isFilter = false
    this.maxValue = 0
    this.minValue = 0
    this.filterColumn = undefined
    this.filterColumnValue = ""
    this.getPage(1)

  }
  rangeChange(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getPage(1)

    }, 1000)
  }
  resetProducts() {
    this.selectedProductIds = []
    this.selectedProducts = []
    this.selectAll = false

    this.products.forEach((x, i) => {
      this.products[i].isSelected = false
    });
  }
  addProduct(id = "") {
    if (id) {
      this.router.navigate(['/create-product', id, this.page], {
        state: {
          columns: this.column,
          searchText: this.searchText,
          filterColumn: this.filterColumn,
          filterColumnValue: this.filterColumnValue,
          minValue: this.minValue,
          maxValue: this.maxValue
        }
      })
    }
    else {
      this.router.navigate(['/create-product'], {
        state: {
          columns: this.column,
          searchText: this.searchText,
          filterColumn: this.filterColumn,
          filterColumnValue: this.filterColumnValue,
          minValue: this.minValue,
          maxValue: this.maxValue
        }
      })
    }


  }
  addToCart() {
    if (this.selectedProducts.length == 0) {
      alert("Please select products to add cart");
      return false

    }

    if (this.selectedProducts && this.selectedProducts.length > 0) {
      this.loading = true
      // this.cartObj = {
      //   customer:{
      //     firstName:this.customerInfo.firstName,
      //     lastName:this.customerInfo.lastName,
      //     phone:this.customerInfo.phone,
      //     emile:this.customerInfo.emile,
      //     customerId:this.customerInfo.id
      //   },
      //   products:[]
      // }
      if (this.cartObj && this.cartObj.products) {
        // this.cartObj.products.push(this.selectedProducts)
        this.cartObj.products = this.cartObj.products.concat(this.selectedProducts)
        this.cartObj.products = this.cartObj.products.map((item) => {
          if (item.productCategory && item.productCategory.name) {
            item.productCategory.name = item.productCategory.name.replace(/'/g, '###')
          }
          return item
        })
        this.cartObj.products = Array.from(new Map(this.cartObj.products.map(e => [e.id, e])).values());

        // this.cartObj.pr
        // const dataArrWithSet = new Set(this.cartObj.products);

        // this.cartObj.products = [...dataArrWithSet];

        this.showCheckboxes = false
        this.selectAll = false
        this.selecteAction = 0
        this.showBlock = false

        this.selecteAction = 0
        setTimeout(() => {
          this.showBlock = true
        }, 10)
        // return
        // let cartObj2 = JSON.stringify(this.cartObj).replace(/"/g, "'");
        let cartObj2 = encodeURIComponent(JSON.stringify(this.cartObj))
        this.apollo
          .mutate({
            mutation: updateCartMutution,
            variables: {
              cart: `${cartObj2}`
            },
            errorPolicy: "all"
          })
          .subscribe(
            ({ data, loading, errors }: any) => {
              this.loading = false
              document.getElementById("cartCount").innerHTML = this.cartObj.products.length
              this.toastr.info("products added to cart successufully.")
            },
            error => {
              console.log(error)

              this.loading = false

            }
          )
      }
      else {
        this.loading = false
      }

    }
    // this.router.navigate(['/search',this.genearteKey(id)])
  }

}
