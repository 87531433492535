
<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2 py-4 ">
    <div class="card p-md-3 py-3  ">
        <div  class=" d-flex justify-content-between text-center pt-2 pb-3  grid-st-center1">
          <h5>Categories</h5>
         <a   (click)="openModal(categoryModal,1);categoryName='';selectedCategory=null" class="theme-btn" >Create Category</a>
         </div>
        <div class="table-responsive">
            <table class="table table-hover js-basic-example dataTable table-custom m-b-0">
                <thead>
                    <tr>                                            
                        <th>S.no</th>
                        <th>Category Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody >
                    <tr class="cursor-pointer" *ngFor="let category of categories| paginate: { itemsPerPage: itemsPerPage , currentPage: p, totalItems: totalItems };let i = index;  ">
                        <td>{{i+1+itemsPerPage*(page-1)}}</td>
                        <td class="position-relative" >
                           {{category.name}}
                        </td>
                       
                        <td class="project-actions">
                           <a  class="cursor-pointers" title="Edit" (click)="openModal(categoryModal,1);selectedCategory=category;categoryName=category.name">
                            <img src="assets/edit.png" />

                           </a>
                           <a  class="cursor-pointer px-2" title="Delete" (click)="deleteCategory(category.id,i)">
                            <img src="assets/delete.png" />
                           </a>
                        </td>
                    </tr>
                   <tr *ngIf="!loading && categories.length==0">
                       <td colspan="6" class="text-center">
                           No categories Info.
                       </td>
                   </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
          <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
      </div>
    </div>
  </div>
</div>






<ng-template #categoryModal let-modal>
   <div class="">
     <div class="modal-content p-4">
       <!-- Modal Header -->
       <div class="modal-header border-0 p-0 grid-st">
         <div></div>
         <p id="modal-basic-title" class="text-uppercase font-weight-bold text-center">
           {{ selectedCategory && selectedCategory.id?  'Update Category' : 'Create Category'}}
         </p>
           <span aria-hidden="true" class="cursor-pointer text-right default-color
           " (click)="modal.dismiss(0)"><img src="assets/icon_X.svg" /></span>
       </div>
       <!-- Modal body -->
       <div class="modal-body pt-2">
        
         <form class="row justify-content-center m-0"   >
       <div class="pb-4">
         <img src="assets/category.svg"  />
       </div>
           <div class="w-100">
             <div class=" py-4 st-md-border1">
                 <input type="text" class="theme-input" name="categoryName" [(ngModel)]="categoryName" />
             </div>
             <div class="text-center pt-4">
               <a
               class="theme-btn-lg"
               (click)="modal.close(categoryName)"
             >
               Save
             </a>
             </div>
           </div>
         </form>
       </div>
     </div>
   </div>
 </ng-template>
