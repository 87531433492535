
<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2">
    <div class="card p-md-5 py-5 my-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" *ngIf="type"><a  [routerLink]="['/facebook']">{{ 'SUBMENU.MEMBERS' | translate }}</a></li>
          <li class="breadcrumb-item" *ngIf="!type"><a  [routerLink]="['/members']">{{ 'SUBMENU.MEMBERS' | translate }}</a></li>
          <li class="breadcrumb-item active"  aria-current="page">View Member</li>
          <li class="breadcrumb-item active" aria-current="page">{{userData && userData.firstName}} {{userData && userData.lastName}}</li>
    
        <li
        class="rightIcon ml-auto" *ngIf="type=='project'" [routerLink]="['/view-project',projectId]" 
         ><img src="assets/back.svg" class="arrowIconn"  /> Back </li
       >
       <li
       class="rightIcon ml-auto" *ngIf="type=='team'" [routerLink]="['/facebook']" 
        ><img src="assets/back.svg" class="arrowIconn"  /> Back </li
      >
       <li
        class="rightIcon ml-auto" *ngIf="!type" [routerLink]="['/members']" 
         ><img src="assets/back.svg" class="arrowIconn"  /> Back </li
       >
        </ol>
        
      </nav>
      <div class="d-flex justify-content-center1 flex-wrap">
        <div class="d-flex justify-content-center w-100" *ngIf="userProfile">
            <div class="col-md-6 col-md-6 col-lg-41 m-auto imgBlock text-center">
            
              <div class="imgBg m-auto">
                <img
                *ngIf="userProfile.displayImageURL"
                src="{{ userProfile.displayImageURL }}"
                  class="position-absolute bg-pos"
                  onerror="this.style.display='none'"
                  style="object-fit: cover;overflow: hidden;"
                  height="auto"
                />
              </div>
             
              <div class="socialMedia py-2 text-center" *ngIf="userProfile && userProfile.socialLinks">
                <ng-container *ngFor="let data of userProfile.socialLinks">
                  <a
                    href="https://facebook.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Facebook'"
                    ><img src="assets/fb.png" /> </a
                  >&ensp;
                  <a
                    href="https://twitter.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Twitter'"
                    ><img src="assets/tw.png" /> </a
                  >&ensp;
                  <a
                    href="{{ getValidUrl(data.value) }}"
                    target="_blank"
                    *ngIf="data.title == 'Linked In'"
                    ><img src="assets/linkedin.png" /> </a
                  >&ensp;
                  <a
                    href="https://instagram.com/{{ data.value }}"
                    target="_blank"
                    *ngIf="data.title == 'Instagram'"
                    ><img src="assets/ins.png" /> </a
                  >&ensp;
                  <a
                    href="{{ getValidUrl(data.value) }}"
                    target="_blank"
                    *ngIf="data.title == 'TikTok'"
                    ><img src="assets/tictok.png" /> </a
                  >&ensp;
                  <a
                  href="{{ getValidUrl(data.value) }}"
                  target="_blank"
                  *ngIf="data.title == 'Snapchat'"
                  ><img src="assets/snapchat.png" /> </a
                >&ensp;
                </ng-container>
              </div>
            </div>
           
          </div>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h5 class=" px-4 mb-0">Account Info</h5>
            <a   [routerLink]="['/create-member', userId]" *ngIf="!type" class="theme-btn"> Edit Account</a>
            <ng-container *ngIf="type && loginUserId != userId">
              <div>
                <a   *ngIf="userData && !userData.firstLoginPending && !userData.contactPresent"  (click)="connectMember(userData)"  class="theme-btn " > Connect</a> 
                <a class="theme-btn white-btn ml-2" *ngIf="isAdmin" (click)="openTeamModal(userData.id,teamModal)">Add To Internal Team</a>
              </div>
              
            </ng-container>
          </div>
       

        <div class=" d-flex justify-content-around1 pt-3 flex-wrap col-md-12">
        <div class="d-flex align-items-center col-md-6 col-md-6 col-lg-4" *ngIf="userData">
          <img src="assets/user.svg?x=1" alt="phone" class="pr-2"  />
          <div class="pl-2">
            <p class="text-gray m-0">   {{ 'PROFILE.NAME' | translate }}</p>
     <span class="text-capitalize">{{ userData.firstName }}  {{ userData.lastName }} <span class="default-color">({{userData.role ? userData.role : 'Member' }})</span>
    </span> 

          </div>
        </div>
        <div class="d-flex align-items-center col-md-6 col-md-6 col-lg-4 " *ngIf="userData && userData.email">
          <img src="assets/mail.svg?x=1" alt="phone" class="pr-2" />
          <div class="pl-2">
            <p class="text-gray m-0">   {{ 'PROFILE.EMAIL' | translate }}</p>
            {{ userData.email }} 
          </div>
        </div>
        <div class="d-flex align-items-center col-md-6 col-md-6 col-lg-4" *ngIf="userData && userData.b2BPhone">
          <img src="assets/phone.svg?x=1" alt="phone" class="pr-2" />
          <div class="pl-2">
            <p class="text-gray m-0">   {{'CREATE_MEMBER.PHONE' | translate}}</p>
            {{ userData.b2BPhone }} 
          </div>
        </div>
    
    
    </div>
    <hr class="col-md-11" />
    <div class=" col-md-12 pr-0">
        <div class="d-flex justify-content-between w-100 align-items-center pr-0">
                    <h5 class=" mb-3 pl-2">Profile Info</h5>
        
            <!-- <a  [routerLink]="['/update-profile']"> <img src="assets/editGroup.svg" /></a> -->
            <ng-container *ngIf="!type">
              <a  *ngIf="!loading && userData &&  userData.profiles.length ==0"  [routerLink]="['/official-profile', userId,2]" class="theme-btn"> Create Profile</a>
              <a   *ngIf="!loading && userData &&  userData.profiles.length > 0" [routerLink]="['/official-profile', userId,2]" class="theme-btn"> Edit Profile</a>
            </ng-container>
          
          
          </div>
    <div *ngIf="!loading && userData &&  userData.profiles.length ==0" class="text-left"> 
        <h6 class="px-2">No profile info found.</h6 >
    </div>
    <div class="d-flex1 userProfile-details" *ngIf="!loading && userData && userData.profiles.length >0">
        
       
        <div class="col-md-12 px-0">
          <div class="text-center">
            <div class="w-100 pl-0" *ngIf="userProfile">
              <h4 *ngIf="accountInfo" class="text-capitalize">
                {{ accountInfo.firstName }} {{ accountInfo.lastName }}
              </h4>
            
            
            </div>
           
          </div>
  
          <div
            class="d-flex justify-content-between1 pt-1 flex-wrap"
            *ngIf="userProfile"
          >
              <!-- <div class="col-lg-12 pl-0 d-flex"> -->
                <ng-container *ngIf="userProfile.title">
  
                <div class="col-md-6 col-lg-4 py-3" *ngIf="userProfile.title.value">
                  <div class="d-flex align-items-center">
                    <img src="assets/title.svg?x=1" alt="phone" class="pr-2" />
                    <div class="pl-2">
                      <p class="text-gray m-0">{{ userProfile.title.title }}</p>
                      {{ userProfile.title.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="userProfile.department">
                <div class="col-md-6 col-lg-4 py-3" *ngIf="userProfile.department.value">
                  <div class="d-flex align-items-center">
                    <img src="assets/company.svg?x=1" alt="phone" class="pr-2" />
                    <div class="pl-2">
                      <p class="text-gray m-0">{{ userProfile.department.title }}</p>
                      {{ userProfile.department.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="userProfile.company">
                <div class="col-md-6 col-lg-4 py-3" *ngIf="userProfile.company.value">
                  <div class="d-flex align-items-center">
                    <img src="assets/company.svg?x=1" alt="phone" class="pr-2" />
                    <div class="pl-2">
                      <p class="text-gray m-0">{{ userProfile.company.title }}</p>
                      {{ userProfile.company.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
  
              <!-- </div> -->
            <!---Email ---->
            <ng-container *ngIf="userProfile.emails && userProfile.emails.length>0">
              <div class="col-md-6 col-lg-4 py-3"  *ngFor="let data of userProfile.emails">
                <div class="w-100" *ngIf="data.format == 'EMAIL'">
                  <div class="d-flex align-items-center">
                    <img src="assets/mail.svg?x=1" alt="phone" class="pr-2" />
                    <div class="pl-2">
                      <p class="text-gray m-0">{{ data.title }}</p>
                      {{ data.value }}
                    </div>
                  </div>
                </div>
            </div>
          </ng-container>
            <!---Phone ---->
            <ng-container *ngIf="userProfile.phones && userProfile.phones.length>0">
            <div  class="col-md-6 col-lg-4 py-3"  *ngFor="let data of userProfile.phones">
              <div
              class="w-100"
              *ngIf="data.format == 'PHONE_NUMBER'"
              >
              <div class="d-flex align-items-center">
                <img src="assets/phone.svg?x=1" alt="email" class="pr-2" />
                <div class="pl-2">
                <p class="text-gray m-0">{{ data.title }}</p>
              
                {{ data.value }}
              </div>
              </div>
              </div>
            
            </div>
          </ng-container>
      <!---Address ---->
             <!-- {{userProfile.location | json}} -->
             <ng-container   *ngIf="userProfile.location && userProfile.location.length>0" >
              <div class="col-md-6 col-lg-4 py-3" *ngFor="let data of userProfile.location">
               <div
                 class="w-100"
                 *ngIf="data.format == 'LOCATION'"
               >
               <div class="d-flex align-items-center">
                 <img src="assets/location.svg?x=1" alt="location" class="pr-2" />
                 <div class="pl-2">
                     <p class="text-gray m-0">{{ data.title }}</p>
                     {{ data.value }}
                   </div>
                 </div>
               </div>
            
             </div>
            </ng-container>
  
       <!---Education ---->
       <ng-container  *ngIf="userProfile.education" >
        <div class="col-md-6 col-lg-4 py-3"   *ngIf="userProfile.education.value">
          <div
          class="w-100"
          *ngIf="userProfile.education.format == 'UNIVERSITY'"
        >
          <div class="d-flex align-items-center">
  
              <img src="assets/education.svg" alt="education" class="pr-2" />
              <div class="pl-2">
                <p class="text-gray m-0">{{ userProfile.education.title }}</p>
              {{ userProfile.education.value }}
              </div>
          </div>
        </div>
         
        </div>
      </ng-container>
  <!---Link ---->
  <ng-container *ngIf="userProfile.links && userProfile.links.length>0">
  <div class="col-md-6 col-lg-4 py-3 word-break" *ngFor="let data of userProfile.links">
    <div class="w-100" *ngIf="data.format == 'LINK'">
      <a href="{{ getValidUrl(data.value) }}" class="linkClr d-flex align-items-center">
        <img
          src="assets/website.svg?x=1"
          alt="website"
          class="pr-2"
        />
        <div class="pl-2">
          <p class="text-gray m-0">{{ data.title }}</p>
          {{ data.value }}
        </div>
      </a>
    </div>
  </div>
  </ng-container>
  
   <!---Link ---->
   <ng-container *ngIf="userProfile.custom && userProfile.custom.length>0">
    <div class="col-md-6 col-lg-4 py-3 word-break" *ngFor="let data of userProfile.custom">
    <div class="w-100" *ngIf="data.format == 'GENERIC'">
      <a href="{{ getValidUrl(data.value) }}" class="linkClr d-flex align-items-center">
        <img
          src="assets/dots.svg"
          alt="website"
          class="pr-2"
        />
        <div class="pl-2">
          <p class="text-gray m-0">{{ data.title }}</p>
          {{ data.value }}
        </div>
      </a>
    </div>
  </div>
  </ng-container>
  
          </div>
        
        </div>
      </div>
    </div>
    <hr class="col-md-11" />

        <div class=" col-md-12 pl-1">
            <h5 class="col-md-12 mb-3 ">Team Info</h5>
            <ng-container *ngIf="userData && userData.teams">
                <ul class="m-0 temsBlk col-md-6" *ngFor="let team of userData.teams">
                    <li class="d-sm-flex ">
                      <p class="pr-2">{{ team.name }}  <span class="default-color" *ngIf="isManger(team.managers,userData.userId)">(Manager)</span>
                     - </p>
                      <span class="text-gray-app font-weight-500">
                        {{
                          'TEAMSPAGE.N_MEMBERS'
                            | translate: { members_count: team.memberIds.length }
                        }}
                      </span>
                    </li>
                  </ul>
            </ng-container>
            <ng-container *ngIf="userData && userData.teams.length ==0 ">
                <h6 class="col-md-12 mb-3 ">No teams found.</h6>
            </ng-container>
        </div>  
    </div>
  </div>
</div>
<!-- </div> -->
<app-create-new-label *ngIf="isOpen"></app-create-new-label>







<!-- <div class="modal" id="deleteModal"  #deleteModal> -->
  <ng-template #teamModal let-modal>
    <div class="">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 id="modal-basic-title">
          Add Member To Internal Team
          </h5>
          <!-- <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
               -->
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss(0)"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body pt-2 px-5 remindersBlk">
          <label>
            Select Team
          </label>
          <ngx-select
          name="participants"
          [allowClear]="true"
          [multiple]="true"
          [keepSelectedItems]="false"
          [items]="teams"
          [(ngModel)]="selectedTeams"
          optionValueField="teamId"
          optionTextField="name"
          placeholder="{{ 'SUBMENU.TEAMS' | translate }}"
          class="theme-select team-select"
        >
          <ng-template
            ngx-select-option
            ngx-select-option-selected
            let-option
            let-text="text"
            let-i = index
          >
          <!-- <h6 *ngIf="i==0" class="selectedCls"> Selected Teams</h6> -->
          <!--  -->
            <div class="d-flex dropdown-custom align-items-center flex-wrap w-100">
              
              <div class="d-flex col-md-12 px-0">
                <div class="pl-2 col-md-6">
                  <!-- <p [innerHtml]="text" class="mb-0"></p> -->
                  <div class="text-gray1 optionClr">
                    {{ option.data.name }}
                  </div>
                </div>
               
              </div>
             
            
            </div>
          </ng-template>

          <ng-template ngx-select-option-not-found let-input>
            {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
          </ng-template>
        </ngx-select>
        <div class="text-center pt-3">
          <button
          type="button"
          class="btn btn-outline-dark"
          (click)="modal.close(selectedTeams)"
        >
          Save
        </button>
        </div>
        </div>
      </div>
    </div>
  </ng-template>
