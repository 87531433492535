import { NgModule } from '@angular/core'
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLinkModule, HttpLink}  from 'apollo-angular-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink as myHttpLink } from 'apollo-link-http'
import { environment } from '../../environments/environment';
import { onError } from 'apollo-link-error';
import { Observable, ApolloLink } from 'apollo-link'; // <-- Add Observable
import { HttpClient } from '@angular/common/http'
import {RefreshTokenService} from '../shared/refresh-token.service'
const keypair = require('keypair')

import * as CryptoJS from 'crypto-js';  
// import * as CryptoJS from 'crypto-js';

let logoutStatus = true


const requestRefreshToken =(token:any) => {
 let userInfo:any = {
  "token": environment.AUTH_TOKEN,
  "identityProvider": "STAYTOUCH",
  "credentials": {
  "oldToken": localStorage.getItem('token'),
  "email":localStorage.getItem('email'),
//  "password":localStorage.getItem('tempInfo'),
  "password":cryptDEKey(localStorage.getItem('tempInfo')),
  },
    "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"

}
  return new Promise((resolve, reject) => {
    //fetch(`${environment.AUTH_URL}/signInB2B`, {
    fetch(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userInfo),
      })
      .then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
            if(response.status == 401 && logoutStatus)
            {
              response.json().then(function(data) {
                logoutStatus= false
			        	 window.localStorage.clear();
                 window.location.href="/login"
                 console.log("401 error")
                if(data.error == 'TOKEN_EXPIRED'){
                  
                  alert("Invalid Token, please try again")
                  
                }
                else{
                  alert("Something went wrong, please try again")
                }
              });
            }
            if(response.status == 503 && logoutStatus)
            {
              logoutStatus= false
              console.log("503 error")
              window.localStorage.clear();
              window.location.href="/login"
              alert("Yikes, our system decided to take a break. Please try again later.")
            }
           
          return false;
        }
  
        // Examine the text in the response
        response.json().then(function(data) {
          resolve(data)
        });
    }).catch((error) => {
        console.log(error.status)

        console.log(error)

        resolve(1)
      });
  })
   
}
const testRequest =() => {
test()
}
const errorLink = onError(({ graphQLErrors, networkError,operation,forward }:any) => {
  if (graphQLErrors) 
    graphQLErrors.map(({ message, locations, path }) =>
    {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    } 
    );

    if (networkError) { 
        let msg:any=networkError
        if(msg == 'ServerParseError: Unexpected token I in JSON at position 0')
        {
          window.location.href="/login"
           window.localStorage.clear();
        }
        

        if(!localStorage.getItem('token') || localStorage.getItem('token')== 'undefined' || localStorage.getItem('email')== 'undefined' || localStorage.getItem('phone')== 'undefined')
        {
          if(logoutStatus)
          {
            window.localStorage.clear();
            window.location.href="/login"
            logoutStatus= false

          }         
        }
        else
        {

          return new Observable( (observer) => {
            requestRefreshToken(token).then((value:any) =>{
              if(value.error && logoutStatus)
              {
                logoutStatus = false
                window.localStorage.clear();
                window.location.href="/login"
                alert("Yikes, our system decided to take a break. Please try again later.")
                return false
              }
              else if(!value.authorizationToken && logoutStatus)
              {
			        	logoutStatus = false
                window.localStorage.clear();
				        window.location.href="/login"
                alert("Yikes, our system decided to take a break. Please try again later.")
                return false
              }
            else{
              const headers = operation.getContext().headers;
              localStorage.setItem("token",value.authorizationToken)
              operation.setContext({
                headers: {
                  "device-key": "000",
                  authorization: `ST:${value.authorizationToken}`,
                  ...operation.getContext().headers,
                  "refreshToken":"TRUE"
                  // "ST-HEADER-TRANSCRIPTION": "TRUE"
                }
              });
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
        
            return forward(operation).subscribe(subscriber);   
            }
                
            });
            
           });
        }
       
        
      // }
     
    }
  });
 
const uri = environment.BASE_URL; // <-- add the URL of the GraphQL server here
let token 
if(localStorage.getItem('token')){
  token = localStorage.getItem('token')
}
else {
  token = localStorage.getItem('token')
}
const currentToken = () =>{
  return localStorage.getItem('token')
}


const LINK: myHttpLink = new myHttpLink({
  uri: environment.BASE_URL,

});
const cryptKey = () =>{

  const data = [{email: localStorage.getItem('email')}, {id: localStorage.getItem('id')}, {firstName: localStorage.getItem('firstName')}, {lastName: localStorage.getItem('lastName')}]

  // Encrypt
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), currentToken()).toString();

  // // Decrypt
  // const bytes  = CryptoJS.AES.decrypt(ciphertext, currentToken());
  //  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return ciphertext
}
const cryptDEKey = (ciphertext) =>{
  //
const bytes= CryptoJS.AES.decrypt(ciphertext, environment.secretkey);
return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

}
const authMiddleware = new ApolloLink((operation, forward) => {


 if(operation.getContext().headers && operation.getContext().headers.refreshToken == 'TRUE'){

  delete operation.getContext().headers.refreshToken
  operation.setContext({
    headers: { 
      ...operation.getContext().headers,
      "authorization":`ST:${currentToken()}`,
      "device-key": "000",
      "ST-LOGGED-ENCRYPTED":`${cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(keypair().public)  
    }
  });
}
else if(operation.operationName == 'accountInformation')
{

  operation.setContext({
    headers: { 
      ...operation.getContext().headers,
      "authorization":`ST:${currentToken()}`,
      "device-key": "000",
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(keypair().public) 
      // "ST-HEADER-TRANSCRIPTION": "TRUE"
    }
  });
}
else{

  operation.setContext({
    headers: { 
      ...operation.getContext().headers,
      "authorization":operation.getContext().headers && operation.getContext().headers.authorization ? operation.getContext().headers.authorization : `ST:${currentToken()}`,
      "device-key": "000",
      "ST-LOGGED-ENCRYPTED":`${cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(keypair().public)  
    }
  });
}

  return forward(operation);
});


// httpLink.create({uri}),
const setLink = authMiddleware.concat(LINK) 
export function createApollo(httpLink: HttpLink) {
  return {
    link: errorLink.concat(setLink),
    cache: new InMemoryCache(),
  };
}

export function test() {
  return 1
}


@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    RefreshTokenService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
      
    },
  ],
})
export class GraphQLModule {

  constructor(public http:HttpClient){

  }
}
