import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-product-qr-code-generator',
  templateUrl: './product-qr-code-generator.component.html',
  styleUrls: ['./product-qr-code-generator.component.scss']
})
export class ProductQrCodeGeneratorComponent implements OnInit {
  myAngularxQrCode:string | number = 'Your QR code data string';
  constructor() { }
  @Input() id: any;
  @Input() token: any;
  @Input() userName: any;
  @Input() isConference: any;
  ngOnInit() {
    // this.myAngularxQrCode =''
    this.myAngularxQrCode=  `https://www.staytouch.com/p/index.php?token=${this.genearteKey(this.id)}`
    console.log(this.myAngularxQrCode)
  }
  public ngOnChanges() {
  
    this.userName = this.userName ? this.userName : "@";

    this.myAngularxQrCode=  `https://www.staytouch.com/p/index.php?token=${this.genearteKey(this.id)}` 
    console.log(this.myAngularxQrCode)
  } 
  print (): void {
  
    let printContents, popupWin
    printContents = document.getElementById('print-section').innerHTML
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
    popupWin.document.open()
    popupWin.document.write(`
    <html>
          <head>
            <title>Print tab</title>
            <style>
            body{
            }
            //........Customized style.......
            @media print {
              body{
              display:block !important;
              }
            </style>

          </head>
      <body onload="window.print();" style="text-align:center"><div id="print-section" class="p-3">
      <h3>QR Code</h3>
      <div class="row justify-content-center  m-0" style="text-align:center">
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png" width="200px" />
    </div>
    <p style="width:400px;margin:auto">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
       industry's standard dummy text ever since the 1500s,</p>
   </div></body>
        </html>`)
    popupWin.document.close()
  }
  genearteKey(id){
    // return  CryptoJS.AES.encrypt(id, 'product id');  
    console.log(window.btoa(`productId-${id}`)) 
    return window.btoa(`productId-${id}`) 
  }  
 
}
