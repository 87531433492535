<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
   <div class="col-md-12  px-0">
      <div class="col-md-12 p-2">
         <div class="card px-md-3 py-5 my-3  ">
            <app-account></app-account>
            <div class="col-md-12 d-flex pt-0 text-gray1  flex-wrap justify-content-between1 align-items-center minHeight" *ngIf="true || accountInformation.outlook || accountInformation.outlookMultiSync">
               <h4  class="mb-0 col-md-6 col-lg-4" >           
				   <img src="assets/outlook.svg"  class="outlookImg"    /> 
                  <span class="default-color pl-1 cursor-pointer" (click)="openModal(microsoftModal)" style="font-size:16px;" *ngIf="outlookObj && outlookObj.username && outlookObj.username!='null'">({{outlookObj.username}})<br/>
				
				</span> 
               </h4>
			  
               <div class="d-flex" *ngIf="outlookObj">
                  <!-- <div  class="1" (click)="openModal(microsoftModal)" [ngClass]="{'grayClr': outlookObj.syncStatus == 'ENABLED' }"> -->
					<div  class="1" (click)="getmicrosofAccessToken(12345)" [ngClass]="{'grayClr': outlookObj.syncStatus == 'ENABLED' }">

                     <!-- <a  href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                     client_id=361adb98-77c8-44b4-8f15-23e845ba80c2
                     &response_type=code
                     &redirect_uri={{redirectionURL}}
                     &response_mode=query
                     &scope=openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite
                     &state=12345" 
                     [ngClass]="{
                     'btnVisible': outlookObj.syncStatus == 'ENABLED'
                     }" (click)="setLoginType('outlook')" class="text-white btn btn-primary btn-size ">Connect</a> -->
					 <a  
                     [ngClass]="{
                     'btnVisible': outlookObj.syncStatus == 'ENABLED' 
                     }"   class="text-white btn btn-primary btn-size ">Connect</a>
                  </div>
				  <div class="2" [ngClass]="{'grayClr': outlookObj.syncStatus == 'DISABLED'}">
					<a  [ngClass]="{
						'btnVisible': outlookObj.syncStatus == 'DISABLED'
						}" class="text-white btn btn-primary btn-size ml-2 " (click)="disconnectSync('OUTLOOK')">Disconnect</a>
					</div>
                 
			   </div>
			   <small class="w-100 pl-3" *ngIf="outlookObj && outlookObj.username && outlookObj.username!='null'">	(*click here to enable and disable the outlook synchronisation)</small>
			   <!-- <div class="col-md-12 pt-4 d-flex align-items-center" *ngIf="accountInformation.outlookMultiSync">
				   <label class="pl-0 col-md-6 col-lg-4">Enable Outlook Meeting Two way Sync</label>
				<label class="switch custom-switch">
					<input type="checkbox" (change)="updateMultiSync($event,'OUTLOOK')" [checked]="outlookObj.multiSync==true">
					<span class="slider round"></span>
				  </label>
			  </div> -->
			</div>
			<hr class="mx-3" *ngIf="accountInformation.googleSync || accountInformation.googleMultiSync "/>
			<div class="col-md-12 d-flex flex-wrap  text-gray1  justify-content-between1 align-items-center minHeight" *ngIf="accountInformation.googleSync ||  accountInformation.googleMultiSync">
				<h4  class="mb-0 col-md-6 col-lg-4">           <img src="assets/gsuit.svg"   class="gsuitImg"   /> 
				   <span class="default-color pl-1" style="font-size:16px;" *ngIf="googleObj && googleObj.username">({{googleObj.username}})</span> 
				</h4>
					<div class="d-flex" *ngIf="googleObj">
						<div  class="1" [ngClass]="{'grayClr': googleObj.syncStatus == 'ENABLED'}">
						<a  
						[ngClass]="{
						'btnVisible': googleObj.syncStatus == 'ENABLED'
						}" (click)="oauthGoogleSignIn('GOOGLE')" class="text-white btn btn-primary btn-size ">Connect</a>
						</div>
						<div class="2" [ngClass]="{'grayClr': googleObj.syncStatus == 'DISABLED'}">
						<a [ngClass]="{
						'btnVisible': googleObj.syncStatus == 'DISABLED'
						}"  class="text-white btn btn-primary btn-size ml-2 " (click)="disconnectSync('GOOGLE')">Disconnect</a>
						</div>
						
					</div>
					<div class="col-md-12 pt-4 d-flex align-items-center" *ngIf="false && accountInformation.googleMultiSync">
							<label class="pl-0 col-md-6 col-lg-4">Enable Google Meeting Two way Sync</label>
						 <label class="switch custom-switch">
							 <input type="checkbox" (change)="updateMultiSync($event,'GOOGLE')" [checked]="googleObj.multiSync==true">
							 <span class="slider round"></span>
						   </label>
					   </div>
		  </div>
		  <hr  class="mx-3" *ngIf="accountInformation.teamsSync" />
			<div   class="col-md-12 d-flex  text-gray1  justify-content-between1 align-items-center minHeight" *ngIf="accountInformation.teamsSync">
				<h4  class="mb-0 col-md-6 col-lg-4"> 
				   <img src="assets/images2.png"  style="width: 250px;"   /> 
				   <span class="default-color pl-1" style="font-size:16px;" *ngIf="teamObj && teamObj.username">
					({{teamObj.username}})</span> 
				</h4>
				<div class="d-flex" *ngIf="teamObj">
					<div  class="1" [ngClass]="{'grayClr': teamObj.syncStatus == 'ENABLED'}">
					<a  [ngClass]="{
						'btnVisible': teamObj.syncStatus == 'ENABLED'
						}"
					  class="text-white btn btn-primary btn-size " (click)="oauthTeamsSignIn()">Connect</a>
					</div>
					<div class="2" [ngClass]="{'grayClr': teamObj.syncStatus == 'DISABLED'}">
					<a  [ngClass]="{
						'btnVisible': teamObj.syncStatus == 'DISABLED'
						}" class="text-white btn btn-primary btn-size ml-2 " (click)="disconnectSync('TEAMS')">Disconnect</a>
					</div>
				</div>
		  </div>
		  <hr   class="mx-3" *ngIf="accountInformation.hubspotSync" />
			<div   class="col-md-12 d-flex  text-gray1  justify-content-between1 align-items-center minHeight" *ngIf="accountInformation.hubspotSync">
				<h4  class="mb-0 col-md-6 col-lg-4"> 
				   <img src="assets/hubspot.png"  style="width: 180px;"   /> 
				   <span class="default-color pl-1" style="font-size:16px;" *ngIf="hubspotObj && hubspotObj.username">
					({{hubspotObj.username}})</span> 
				</h4>
				<div class="d-flex" *ngIf="hubspotObj" >
					<div class="1" [ngClass]="{'grayClr': hubspotObj.syncStatus == 'ENABLED'}" >
					<a  [ngClass]="{
						'btnVisible': hubspotObj.syncStatus == 'ENABLED'
						}"
					  class="text-white btn btn-primary btn-size " (click)="oauthHubspotSignIn()">Connect</a>
					</div>
					<div class="2" [ngClass]="{'grayClr': hubspotObj.syncStatus == 'DISABLED'}">
					<a [ngClass]="{
						'btnVisible': hubspotObj.syncStatus == 'DISABLED'
						}" class="text-white btn btn-primary btn-size ml-2 " (click)="disconnectSync('HUBSPOT')">Disconnect</a>
					</div>
				</div>
		  </div>
            <hr class="mx-3" *ngIf="accountInformation.salesforceSync"/>
             <div class="col-md-12 d-flex pt-0 px-3  justify-content-between1 align-items-center d-none" *ngIf="accountInformation.salesforceSync">
               <h4  class="mb-0 col-md-6 col-lg-4">           <img src="assets/salesforce.png"   class="salesforceImg"  /> 
                  <span class="default-color d-block pl-3 pt-2 " style="font-size:16px;" *ngIf="salesforceObj && salesforceObj.username">({{salesforceObj.username}})</span> 
               </h4>
               <div class="d-flex" *ngIf="salesforceObj">
                  <div  class="1" [ngClass]="{'grayClr': salesforceObj.syncStatus == 'ENABLED'}">
                   
                     <a  href="https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9n_HvETGhr3CpZckzpwErBPu1_JZ1yK7JkQIayUhVMix6ZqmXB972EVhDMl5u9pjeg5l99hdNV9f9ST4l&redirect_uri={{redirectionURL}}&response_type=code&state=2" 
                     [ngClass]="{
                     'btnVisible': salesforceObj.syncStatus == 'ENABLED'
                     }" (click)="setLoginType('salesforce')" class="text-white btn btn-primary btn-size ">Connect</a>
                  </div>
                  <div class="2" [ngClass]="{'grayClr': salesforceObj.syncStatus == 'DISABLED'}">
                     <a    [ngClass]="{
                     'btnVisible': salesforceObj.syncStatus == 'DISABLED'
                     }"  class="text-white btn btn-primary btn-size ml-2 " (click)="disconnectSync('SALESFORCE')">Disconnect</a>
                  </div>
               </div>
			</div>
			<div class="px-5 " *ngIf=" salesforceObj && salesforceObj.syncStatus == 'ENABLED'" >
               <div class="custom-control custom-checkbox pt-4">
				 <input type="checkbox" class="custom-control-input" id="meetings" [checked]="showList==true" (change)="showList=!showList;getSalesforceObjects()" name="meetings" />
				 <label class="custom-control-label pl-2" for="meetings">would you like to map custom objects ?</label>
			  </div>
				<div *ngIf="showList">
					  <div class="py-4 col-lg-6 pr-lg-5 d-flex align-items-center ">
						<label class="text-gray11 col-md-3 pl-0 border-bottom1 noWeight">Staytouch Object</label>
						<div class="w-100">
						  <ngx-select
							name="timezone"
							[items]="staytouchObjects"
							optionValueField="id"
							optionTextField="name"
							class="theme-select team-select form-control"
							[(ngModel)]="stObjId"
							[placeholder]	= "'Select object type'"
							(select)="showTable=false;"
						  >
							<ng-template
							  ngx-select-option
							  ngx-select-option-selected
							  let-option
							  let-text="text"
							>
							  <div class="d-flex dropdown-custom">
								<div class="pl-2">
								  <div class="text-gray11 optionClr">
									{{ option.data.name }}
								  </div>
								</div>
							  </div>
							</ng-template>

							<ng-template ngx-select-option-not-found let-input>
							  {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
							</ng-template>
						  </ngx-select>
						</div>
					  </div>

 					<div class="py-4 col-lg-6 pr-lg-5 d-flex align-items-center ">
						<label class="text-gray11 col-md-3 pl-0 border-bottom1 noWeight">Salesforce Object:</label>
						<div class="w-100">
						  <ngx-select
							name="timezone"
							[items]="salesforceObjects"
							optionValueField="name"
							optionTextField="name"
							class="theme-select team-select"
							[(ngModel)]="sfObjId"
							[placeholder]	= "'Select object type'"
							(select)="showTable=false;getFiledInfo()"
						  >
							<ng-template
							  ngx-select-option
							  ngx-select-option-selected
							  let-option
							  let-text="text"
							>
							  <div class="d-flex dropdown-custom">
								<div class="pl-2">
								  <div class="text-gray11 optionClr">
									{{ option.data.name }}
									
								  </div>
								</div>
							  </div>
							</ng-template>

							<ng-template ngx-select-option-not-found let-input>
							  {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
							</ng-template>
						  </ngx-select>
						</div>
					  </div>
						  <a class="btn btn-primary mb-3 float-right" (click)="addQuantity()" *ngIf="showTable">Add Field Mapping</a>
						  <form [formGroup]="syncForm" *ngIf="showTable" >

							<table class="table table-bordered" formArrayName="quantities">
								<tr class="col-md-12">
								  <th>Staytouch Fields</th>
								  <th>Salesforce Fields</th>
								  <th width="150px">
									  Action
									</th>
								</tr>
								<tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
								  <td style="width: 200px;vertical-align: middle;">
									  <ngx-select
							[items]="stobjFields"
							optionValueField="name"
							optionTextField="name"
							[placeholder]	= "'Select Field'"
							formControlName="stField" 
							class="theme-select team-select"
						  >
							<ng-template
							  ngx-select-option
							  ngx-select-option-selected
							  let-option
							  let-text="text"
							>
							  <div class="d-flex dropdown-custom py-2">
								<div class="pl-2">
								  <div class="text-gray11 optionClr">
									{{ option.data.name }}
								  </div>
								</div>
							  </div>
							</ng-template>

							<ng-template ngx-select-option-not-found let-input>
							  {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
							</ng-template>
						  </ngx-select>
								  </td>
								  <td style="width: 200px;vertical-align: middle;">
									<ngx-select
									  [items]="sfobjFields"
									  optionValueField="name"
									  optionTextField="name"
									  [placeholder]	= "'Select Field'"
									  formControlName="sfField"
									  class="theme-select team-select"
									>
									  <ng-template
										ngx-select-option
										ngx-select-option-selected
										let-option
										let-text="text"
									  >
										<div class="d-flex dropdown-custom py-2">
										  <div class="pl-2">
											<div class="text-gray11 optionClr">
											  {{ option.data.name }}
											</div>
										  </div>
										</div>
									  </ng-template>
		  
									  <ng-template ngx-select-option-not-found let-input>
										{{ 'FORM.NOT_FOUND' | translate: { input: input } }}
									  </ng-template>
									</ngx-select>
								  </td>
								  <td style="width: 200px;">
									<button (click)="removeQuantity(i)" class="btn btn-danger">Remove</button>
								  </td>
								</tr>
							  </table>
							  <div class="text-center" *ngIf="f.quantities.value.length>0">
								<a class="btn btn-primary" (click)="syncAllFields()">Add Fieldmap</a>
							  </div>
							 
					  </form>
				</div>
				
			</div>
			<hr class="mx-3"/>
</div>
</div>
</div>
</div>
<div class="myModal ">
<ng-template #microsoftModal let-modal>
	<div >
	  <div class="modal-content sync-modal">
		<!-- Modal Header -->
		<div class="modal-header justify-content-center border-0 pt-3">
		  <h3 id="modal-basic-title text-center font-weight-bold pt-4">
			Sync your contact and meetings
		  </h3>
		  <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss(0)"
        >
          <span aria-hidden="true">×</span>
        </button>
		</div>
		<!-- Modal body -->
		<div class="modal-body pt-2  remindersBlk border-0">
		  <!-- <h5 class="text-center pb-4">
			Choose the account to sync your contacts and meetings
		  </h5> -->
		  
		  <form class="row justify-content-center m-0 pb-4 pt-2">
			<div class="w-100 d-flex flex-wrap">
				<div class="px-2 col-md-12 text-center" >
					<img src="assets/outlook.svg"  class="outlookImg cursor-pointer"  (click)="getmicrosofAccessToken(12345)"  /> <br/>
			<!-- <ng-container *ngIf="outlookObj.syncStatus == 'ENABLED'">
<div class="col-md-12 pt-4 d-flex align-items-center" >
	<label class="pl-0 ">Contacts sync - StayTouch to Outlook </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('contact',$event,'OUTLOOK',1)" [checked]="outlookObj.contact == true">
	 <span class="slider round"></span>
   </label>
</div>
<div class="col-md-12 pt-4 d-flex align-items-center" >
	<label class="pl-0 ">Calendar sync - StayTouch to Outlook </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('calendar',$event,'OUTLOOK',1)" [checked]="outlookObj.calendar == true">
	 <span class="slider round"></span>
   </label>
</div>
</ng-container>
				</div>
				<div class="px-2 col-md-6 text-center " >
					<img src="assets/office.png"  class="outlookImg cursor-pointer" (click)="getmicrosofAccessToken(7)"    /> <br/> -->
					
					<!-- <small>(If you need two way sychronisation connect with your Microsoft 365 account)</small> -->
					<ng-container *ngIf="outlookObj.syncStatus == 'ENABLED'">
<div class="col-md-12 pt-5 d-flex align-items-center justify-content-between" >
	<label class="pl-0 "> Contacts sync - StayTouch to Outlook </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('contact',$event,'OFFICE365',1)" [checked]="outlookObj.contact==true ">
	 <span class="slider round"></span>
   </label>
</div>
<div class="col-md-12 pt-4 d-flex align-items-center justify-content-between" >
	<label class="pl-0 ">Calendar sync - StayTouch to Outlook </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('calendar',$event,'OFFICE365',1)" [checked]="outlookObj.calendar==true">
	 <span class="slider round"></span>
   </label>
</div>
<div class="col-md-12 pt-4 d-flex align-items-center justify-content-between" >
	<label class="pl-0 "> Contacts sync - Outlook to Staytouch </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('contact',$event,'OFFICE365',2)" [checked]="outlookObj.resourceId!=''">
	 <span class="slider round"></span>
   </label>
</div>
<div class="col-md-12 pt-4 d-flex align-items-center justify-content-between" >
	<label class="pl-0 ">Calendar sync - Outlook to Staytouch </label>
 <label class="switch custom-switch">
	 <input type="checkbox" (change)="updateOutlookSync('calendar',$event,'OFFICE365',2)" [checked]="outlookObj.channelId!=''">
	 <span class="slider round"></span>
   </label>
</div>
</ng-container>
				</div>
			  <!-- <div class="custom-radio pt-2">
				<label class="radio-container">
				  Outlook
				  <input
					type="radio"
					name="radio"
					value="OUTLOOK"
					[(ngModel)]="microsoftAccountType"
				  />
				  <span class="checkmark"></span>
				</label>
				<label class="radio-container"
				  >Office 365
				  <input
					type="radio"
					name="radio"
					value="OFFICE_365"
					[(ngModel)]="microsoftAccountType"
				  />
				  <span class="checkmark"></span>
				</label>
			  </div>
			  <button
				type="button"
				class="btn btn-outline-dark"
				(click)="modal.close(deleteType)"
			  >
				Continue
			  </button> -->
			</div>
		  </form>
		</div>
	  </div>
	</div>
  </ng-template>
</div>
  <style>
	 
  </style>