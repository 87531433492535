<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2 py-4 ">
    <div class="card py-md-3 py-3 px-3 ">
      <div class=" d-flex justify-content-between text-center pt-2 pb-3  grid-st-center1 align-items-center flex-wrap">
        <h5>Product Enquiries</h5>
        <!-- <a
       class="rightIcon ml-auto theme-btn" (click)="exportCustomers()"
        >Export</a> -->
        <div class="text-center ">
          <ul class="tab-block-cs list-inline d-inline-block">
            <li class="list-inline-item mr-0" (click)="showOrders(1)">
              <a [ngClass]="selectedType==1? 'active-link-tab':''" class="">Sold</a>
            </li>
            <li class="list-inline-item mr-0" (click)="showOrders(2)"><a
                [ngClass]="selectedType==2? 'active-link-tab':''">Orders</a></li>
          </ul>
        </div>
        
        <div class="dropdown text-right pt-3 px-0 col-md-2">
          <select (change)="selectAction($event)" class="rounded-0 theme-select" [(ngModel)]="actionValue">
            <option value="0">Select Action</option>
            <!-- <option value="1" *ngIf="!showCheckboxes && isUserAllow">Edit</option> -->
            <!-- <option value="2" *ngIf="showCheckboxes && !selectAll">Check All</option> -->
            <!-- <option value="3" *ngIf="showCheckboxes && selectAll" >Un Check All</option> -->
            <option value="5">Excel Export</option>
            <option value="4">Delete</option>
            <option value="6" *ngIf="showCheckboxes">Cancel</option>
          </select>
        </div>

      </div>
      <div class="d-flex justify-content-between align-items-end pb-2 flex-wrap">
        <div class="position-relative col-md-4 pl-0 pb-2">
          <input type="text" placeholder="Search Orders" (input)="isFilter=true;searchOrders($event)"
            id="searchMeetings" class="theme-input" [(ngModel)]="searchText" style="background:transparent;" />
          <img id="blah" src="assets/icon_search.svg" alt="search" *ngIf="!isFilter" class="searchIcon" />
          <img id="blah" src="assets/icon_X.png" alt="search" (click)="clearFilter()" *ngIf="isFilter"
            class="searchIcon cursor-pointer" />
        </div>

        <div class="dropdown col-md-8 pt-3 pr-0 pl-0 pl-md-2">

          <div class="d-flex align-items-end cust-theme justify-content-between ml-md-3 flex-wrap">
            <!-- <select (change)="filterBy($event)" class="theme-select col-md-4" style="border-radius: 0px;" [(ngModel)]="filterColumn" (change)="filterOrders()" >
                  <option value="0">Filter by:</option>
                  <option *ngFor="let opt of columnType" [value]="opt.filterName" >{{opt.name}}</option>
               </select> -->
            <ng-select [items]="columnType" bindLabel="name" x bindValue="filterName"
              class="theme-select col-md-4 pr-0 mb-2" [clearable]="false" [(ngModel)]="filterByColumn"
              placeholder="Search or Enter Filter name" (change)="filterOrders()">

            </ng-select>
            <div class=" col-md-5 pb-2 px-md-2 px-0">
              <small *ngIf="filterByColumn=='updated_at'"> (*date format should be DD-MM-YYYY)</small>

              <input type="text" class="theme-input" placeholder="Column Value" [(ngModel)]="filterColumnValue"
                (input)="filterOrders()" />
            </div>
            <a (click)="clearFilterData()" class="theme-btn mb-2" style="    min-width: 91px !important;
               font-size: 15px;
               padding: 4px 15px;
               text-align: center;">Clear Filter</a>

          </div>
        </div>


      </div>

      <div class="table-responsive py-2">
        <table class="table table-hover js-basic-example dataTable table-custom m-b-0">
          <thead>
            <tr>
              <th style="vertical-align: initial;">
                <div class="form-check pb-3">
                  <label class="form-check-label">

                    <input (change)="checkUncheckAll(!selectAll)" [checked]="selectAll" type="checkbox"
                      class="form-check-input" value="">
                  </label>
                </div>
              </th>
              <th>Image</th>
              <th>Category</th>
              <!-- <th>Product Name</th> -->
              <th style="width: 100px;">Taj Number</th>
              <th>Displayed Selling Price </th>
              <!-- <th>Actual Selling Price</th> -->
              <th>Final Selling Price</th>
              <th style="width: 100px;">Payment Method</th>
<!--
              <th>Gold Color</th>
-->
              <th  style="width: 80px;">City</th>
              <th> Size</th>
              <th>Customer Name</th>
              <th>{{ selectedType==1 ? 'Sale Number' :'Order Number' }}</th>
              <!-- <th >Last Name</th> -->
              <!-- <th>Email</th> -->
              <th>Phone</th>
              <!-- <th>Quantity</th> -->
              <th style="width: 75px;">Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer"
              *ngFor="let customer of customers| paginate: { itemsPerPage: itemsPerPage , currentPage: p, totalItems: totalItems } ;let i=index " [ngClass]="customer.paymentMethod=='A_RECUPERER' ? 'st-blue-bg':''">
              <td style="vertical-align: initial;width: 40px;" >
                <div class="form-check">
                  <label class="form-check-label">
                    <input (change)="selectCheckbox($event,customer,i)" [(ngModel)]="customer.isSelected"
                      type="checkbox" class="form-check-input" value="">
                  </label>
                </div>
              </td>
              <td style="width: 40px;" (click)="viewOrder(customer.id)">
                <img width="40px" style="object-fit: contain;" height="40px"
                  onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 40px;object-fit: contain;'"
                  class="img-fluid1 mx-auto d-block img-p"
                  src="{{customer.product.photos[0] && customer.product.photos[0].signedGetURL}}" alt="">
              </td>
              <td (click)="viewOrder(customer.id)">{{customer.product.productCategory.name}}</td>
              <td (click)="viewOrder(customer.id)" class="project-title">
                {{customer.product.tajNumber}}
              </td>
              <td class="project-title" (click)="viewOrder(customer.id)">

                {{getDisplayedSellingPrice(customer)}}
              </td>
              <td class="project-title" (click)="viewOrder(customer.id)">
                {{customer.effectiveSalesPrice ? customer.effectiveSalesPrice : customer.actualSellingPrice}}
              </td>
              
              <td (click)="viewOrder(customer.id)" style="width:100px;">{{customer.paymentMethodValue |  titlecase}}</td>
              <td (click)="viewOrder(customer.id)" style="width:80px">{{customer.city}}</td>
              <td (click)="viewOrder(customer.id)">{{customer.ringSize}}</td>
              <td (click)="viewOrder(customer.id)">
                {{customer.firstName}} {{customer.lastName}}
              </td>
              <td (click)="viewOrder(customer.id)">
                {{ customer.actionType == 'ORDER' ? customer.customerOrderNumber : customer.customerOrderNumber }}
              </td>
              <!-- <td class="position-relative"   (click)="viewOrder(customer.id)">
                            {{customer.email}}
                        </td> -->
              <td (click)="viewOrder(customer.id)">
                {{customer.phone}}
              </td>
              <!-- <td  >
                            {{product.quantity}}
                        </td> -->
              <td class="project-actions" style="width: 75px;" (click)="viewOrder(customer.id)">
                {{ customer.updatedAt | amDateFormat: 'DD-MM-YY' }}
              </td>
              <td class="project-actions">
                <a data-toggle="dropdown" class="ml-0" style="border-bottom:0px;">
                  <img _ngcontent-mfd-c8="" alt="dots" id="blah" src="assets/dots.svg"
                    style="transform: rotate(90deg);" style="max-width:none;"></a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow ">
                  <a class="text-decoration-none p-2 ml-0 d-block border-bottom" *ngIf="selectedType==2"  data-toggle="modal"
                    style="border-bottom:0px;" (click)="moveToSale(customer)">Move to Sold</a>
                  <ng-container *ngIf="isUserAllow">
                    <a class="text-decoration-none p-2 ml-0 d-block border-bottom" data-toggle="modal"
                      style="border-bottom:0px;" (click)="gotoProduct(customer.id)" *ngIf="isUserAllow">Edit</a>
                    <a class="text-decoration-none p-2 ml-0 d-block border-bottom" data-toggle="modal"
                      style="border-bottom:0px;" (click)="confirmDelete(customer)" *ngIf="isUserAllow">Delete
                    </a>
                  </ng-container>


                </div>
              </td>

            </tr>
            <tr *ngIf="!loading && customers.length==0">
              <td colspan="12" class="text-center">
                <p class="text-center py-2" style="font-size: 18px;">No product enquires.</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
      </div>

    </div>
  </div>
</div>
