import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Component, OnInit, Input,EventEmitter, Output } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { MemberUser } from '../../../user/models/member-user'

import { AppState, selectMemberAuth,profileState } from '../../../user/store/app.states'
import {
  MemberLogIn,
  MemberLogOut,
  MemberStatus
} from '../../../user/store/actions/member.auth.actions'
import { I18n, CustomDatepickerI18n } from '../../../user/services/datepicker-i18n'
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap'
import {profilesQuery} from '../../../user/services/user.gql'
import { Apollo, QueryRef } from 'apollo-angular'
import { filter, map } from 'rxjs/operators';
import { pendingContactRequestsQuery } from '../../../user/services/contacts.gql'
import { pendingMeetingsQuery, pendingConferenQuery } from '../../../user/services/meeting.gql'
import { updateMultiFactorMutation } from '../../../user/services/user.gql'

const languages = [
  {
    id: 'en',
    title: 'EN',
    image: 'assets/i18n/us.svg'
  },
  {
    id: 'fr',
    title: 'FR',
    image: 'assets/i18n/fr.svg'
  }
]
@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class NewHeaderComponent implements OnInit {
  @Input() accountInfo: any
  @Input() toggle: any
  @Output() toggleChanged: EventEmitter<boolean> =   new EventEmitter();
cartCount:number =0
  languagesList= languages
  loginForm = null
  getState: Observable<any>
  getProfie:Observable<any>
  isAuthenticated: boolean = null
  errorMessage: string | null
  user: MemberUser = null
  selectedLang = 'en'
  imageUrl:any = ""
  random:any =0
  private queryPr: QueryRef<any>
  private queryContacts: QueryRef<any>
  private queryMeetings: QueryRef<any>
  private queryConference: QueryRef<any>
  totalNotifications:any =0
  loading:boolean = false
  notificationsData:any={
    contacts:0,
    meetings:0,
    conference:0
  }

  getLoginDetails() {
  
  	this.user = new MemberUser({id: 1, email: this.loginEmail.value, password: this.loginPassword.value})
  	const payload = {
      email: this.user.email,
      password: this.user.password
    };
    this.store.dispatch(new MemberLogIn(payload))
  }

  constructor (private apollo: Apollo,private store: Store<AppState>, public translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute,) {
    this.selectedLang = localStorage.getItem('lang') ? localStorage.getItem('lang'): 'en'
  	this.getState = this.store.select(selectMemberAuth)
  	this.getProfie = this.store.select(profileState)
    this.store.dispatch(new MemberStatus)
  }

  changeLanguage(lang) {
    localStorage.setItem('lang',lang);
    this.translate.use(lang)
  }

  ngOnInit () {
  
    this.random = Math.random();
  // console.log(3432)
    /** Profile Info **/
  	this.loginForm = new FormGroup({
	  	loginEmail: new FormControl('', [Validators.required, Validators.email]),
	  	loginPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
	  	isRememberMe: new FormControl(false)
    })
    // this.getProfie.subscribe((state) => {
    //   // console.log(state,'state profile')
    // })
    // this.getNotificationCounts()
    this.getState.subscribe((state) => {
      // console.log(state,'state profile2')

     
      // console.log('coming')
      this.isAuthenticated = state.memberAuth.isAuthenticated;
      this.user = state.memberAuth.user;
      this.errorMessage = state.memberAuth.errorMessage;
      this.getProfileInfo()
      setTimeout(()=>{
        if(state.profilePic.profilePic)
        {
          // console.log(state.profilePic.profilePic,'state profile3')
          this.imageUrl=state.profilePic.profilePic
        }
      },1000)
      

    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
    .subscribe(() => {
      var rt = this.getChild(this.activatedRoute)
      rt.data.subscribe(data => {
      //  this.getNotificationCounts()
      })

    }
    )     
    

  }
  getProfileInfo(){
    if(localStorage.getItem('token'))
    {
      this.queryPr = this.apollo.watchQuery({
        query: profilesQuery,
         fetchPolicy:"network-only"
      });
      this.queryPr.valueChanges.subscribe(({data}) => {
        // console.log('home')
        if(data.profiles[0])
        {
        this.imageUrl = data.profiles[0].displayImageURL
        localStorage.setItem('forgotEmail', this.loginEmail.value)
        }
      })
    }
  
  }

  logout() {
    this.loading = true
    window.localStorage.clear()
    window.location.href = '/login'
    this.store.dispatch(new MemberLogOut);
  }

  get loginEmail() { return this.loginForm.get('loginEmail') }
  get loginPassword() { return this.loginForm.get('loginPassword') }
  
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  getNotificationCounts(){
    let contactRequests= this.contactRequests()
    let meetingRequests= this.meetingRequests()
    let conferenceRequests= this.conferenceRequests()
    Promise.all([contactRequests, meetingRequests, conferenceRequests])
    .then((results: any) => {
      this.notificationsData={
        contacts: results[0],
        meetings: results[1],
        conference: results[2]
      }      
      this.totalNotifications = results.reduce((v, i) => (v + i));
    })
    .catch(err => console.log(err))
  }
  contactRequests(){
    return new Promise((resolve, reject) => {
      this.queryContacts = this.apollo.watchQuery({
        query: pendingContactRequestsQuery,
        fetchPolicy:"network-only"
      })
      this.queryContacts.valueChanges.subscribe(
        result => {
          resolve(result.data.contacts.length)
        },
        error => {
          resolve(0)
        }
      )
    })
  }
  meetingRequests(){
    return new Promise((resolve, reject) => {
      this.queryMeetings = this.apollo.watchQuery({
        query: pendingMeetingsQuery,
        fetchPolicy:"network-only"
      })
      this.queryMeetings.valueChanges.subscribe(
        result => {
          resolve(result.data.events.length)
        },
        error => {
          resolve(0)
        }
      )
    })
  }
  conferenceRequests(){
    return new Promise((resolve, reject) => {
      this.queryConference = this.apollo.watchQuery({
        query: pendingConferenQuery,
        fetchPolicy:"network-only"
      })
      this.queryConference.valueChanges.subscribe(
        result => {
          resolve(result.data.events.length)
        },
        error => {
          resolve(0)
        }
      )
    })
  }
  updateMfaStatus(activate){
    this.apollo
    .mutate({
      mutation: updateMultiFactorMutation,
      variables: {
        activate: activate,
      }
    })
    .subscribe(({ data }: any) => {
      this.router.navigate(['/update-account'])

  })
  }
  disable2Mfa(){
    if(confirm("Are you sure do you want disable the 2Mfa authentication?")){
      this.updateMfaStatus(false)
    }
  }
  toggleAction() {
    this.toggle =  !this.toggle
    this.toggleChanged.emit(this.toggle)
  }
  public ngOnChanges () {
    if(this.accountInfo.cart && this.accountInfo.cart != ""){
      let cartInfo = this.accountInfo.cart
      // cartInfo = cartInfo.replace(/'/g, '"')
      // console.log(cartInfo)
      cartInfo = decodeURIComponent(cartInfo)

      cartInfo = cartInfo.replace(/###/g, "'")
      cartInfo = JSON.parse(cartInfo)

      if( cartInfo.products && cartInfo.products.length > 0){
          this.cartCount = cartInfo.products.length
      }
    }
  }
}
