<div class="col-sm-9 col-md-7 col-lg-4 mx-auto pt-5">
  <div class="card card-signin my-5">
    <div class="card-body">
      <h5 class="card-title text-center">
        {{ 'HEADER.FORGOT_PASSWORD' | translate }}
      </h5>
      <div *ngIf="errorMessage">
        <div class="text-danger p-3" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <form
        class="form-signin"
        [formGroup]="forgotForm"
        (ngSubmit)="fogotPassword()"
      >
        <div *ngIf="withEmail">
          <div class="form-label-group1 py-3">
            <input
              type="email"
              id="inputEmail"
              formControlName="loginEmail"
              class="form-control custominp"
              placeholder="{{ 'HEADER.EMAIL_ADDRESS' | translate }}"
              autocomplete="off"
            />
            <!-- <label for="inputEmail">Email address</label> -->
          </div>
          <div
            *ngIf="submitted && loginEmail.value == ''"
            class="alert alert-danger"
          >
            {{ 'CONTACT.EMAIL_IS_REQUIRED' | translate }}
          </div>
          <div
            *ngIf="
              loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)
            "
            class="alert alert-danger"
          >
            <div *ngIf="loginEmail.errors.required">
              {{ 'CONTACT.EMAIL_IS_REQUIRED' | translate }}
            </div>
            <div *ngIf="loginEmail.errors.email">
              {{ 'CONTACT.EMAIL_IS_NOT_VALID' | translate }}
            </div>
          </div>
        </div>

        <div class="form-label-group1 py-3" *ngIf="!withEmail">
          <ngx-intl-tel-input
            [cssClass]="'form-control custominp'"
            [preferredCountries]="['us', 'gb']"
            [enablePlaceholder]="false"
            [enableAutoCountrySelect]="false"
            name="loginPhone"
            id="loginPhone"
            [searchCountryFlag]="true"
            [separateDialCode]="'false'"
            formControlName="loginPhone"
            [maxLength]="15"
            [phoneValidation]="true"
          >
          </ngx-intl-tel-input>
        </div>
        <div>
          <div
            class="alert alert-danger"
            *ngIf="
              submitted &&
              loginPhone.value &&
              loginPhone.value.number.length < 10
            "
          >
            {{ 'MESSAGES.PHONE_NUMBER_MINIMUM' | translate }}
          </div>
          <div
            class="alert alert-danger"
            *ngIf="
              submitted &&
              loginPhone.value &&
              loginPhone.value.number.length > 15
            "
          >
            {{ 'MESSAGES.PHONE_NUMBER_MAXMIMUM' | translate }}
          </div>
        </div>

        <button
          class="btn btn-lg btn-primary btn-block text-uppercase"
          type="submit"
          [disabled]="!forgotForm.valid"
        >
          {{ 'COMMON.SUBMIT' | translate }}
        </button>
        <div class="d-flex justify-content-end pt-2">
          <a *ngIf="!withEmail" (click)="withEmail = true" class="">
            {{ 'MESSAGES.WITH_EMAIL' | translate }}</a
          >
          <!-- <a *ngIf="withEmail" (click)="withEmail = false">
            {{ 'MESSAGES.WITH_PHONE_NUMBER' | translate }}</a
          > -->
          <a [routerLink]="['/login']">{{ 'HEADER.LOGIN' | translate }}?</a>
        </div>
      </form>
    </div>
  </div>
</div>
