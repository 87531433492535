import { Component, OnInit, ElementRef, OnDestroy,ChangeDetectorRef } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  membersBasicQuery,
  createProfileMemberMutution,
  updateProfileMemberMutution,
} from '../../../services/admin.gql'
import {
 accountInfo,
 setPublicProfileMutution,
 publicProfileQuery,generateBranchLink
} from '../../../services/user.gql'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CreateNewLabelComponent } from '../../account/official-profile/create-new-label/create-new-label.component'
import {
  addressTypes,
  linkTypes,
  customTypes,
  emailTypes,
  phoneTypes
} from '../create-member/staticLabels'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { memberHelper } from '../create-member/member-helper'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import * as data from '../../../../shared/country.json'
import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input'
import { TranslateService } from '@ngx-translate/core'
import {
  getCompanyQuery,createCompanyMutation
} from '../../../services/contacts.gql'
import {environment} from '../../../../../environments/environment'

@Component({
  selector: 'app-admin-created-profiles',
  templateUrl: './admin-created-profiles.component.html',
  styleUrls: ['./admin-created-profiles.component.scss']
})
export class AdminCreatedProfilesComponent implements OnInit, OnDestroy {
  public countryData: any = data
  private query: QueryRef<any>
  private publicProfileQueryM: QueryRef<any>
  private query$:any
  public profileForm: FormGroup
  public years: any[] = []
  public userId: number
  public addressTypes: any[] = addressTypes
  public linkTypes: any[] = linkTypes
  public phoneTypes: any[] = phoneTypes
  public emailTypes: any[] = emailTypes
  public customTypes: any[] = customTypes
  public userData: any = []
  url: any = 'assets/default.jpg'
  public countryList: any
  public isOpen: false
  private companyQuery: QueryRef<any>

  separateDialCode = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  profilePic: any = ''
  loading:boolean = false
  socialType:any =1
  account:any
  enableAutoCountrySelect:boolean = true
  fromValue:any
  companiesArray:any =[]
  allCompanies:any =[]
  environmentInfo:any = environment
  publicProfileExists:boolean = true
  publicProfileId:any
  userProfileId:any
  timeout: any = null;
  constructor (
    private apollo: Apollo,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private helper: memberHelper,
    private http: HttpClient,
    private elementRef: ElementRef,
    private translate: TranslateService,
    private cd: ChangeDetectorRef

  ) {
    this.countryList = this.countryData ? this.countryData.default : []
    const year = new Date().getFullYear()
    for (var i = 1960; i <= year; i++) {
      this.years.push({
        id: i + 1,
        text: i + 1
      })
    }
    this.getTheCompanyValue1('',true)
  }
  onClick (event) {
    //console.log(event)
  }
  getAccountDetails (id) {
    this.loading = true
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      fetchPolicy:"no-cache"
    })
    this.query$ = this.query.valueChanges.subscribe(result => {
      this.account = result.data.accountInformation
      this.getUserDetails(id)
      if(this.account.organizationType == 'UNIVERSITY')
      { 
        this.profileForm.patchValue({
          university:this.account.organizationName
        })
      }
      else{
        this.profileForm.patchValue({
          company:this.account.organizationName
        })
      }
     
     
      this.loading = false
    },error => {
      this.loading = false
    })
  }
  ngOnInit () {
   
    this.profileForm = this.formBuilder.group({
      jobTitle: [''],
      company: [''],
      university: [''],
      firstName: [''],
      lastName: [''],
      department: [''],
      facebook: this.formBuilder.array([this.helper.createFacebookFormGroup()]),
      twitter: this.formBuilder.array([this.helper.createTwitterFormGroup()]),
      ticktok: this.formBuilder.array([this.helper.createTikTokFormGroup()]),
      linkedIn: this.formBuilder.array([this.helper.createLinkedInFormGroup()]),
      instagram: this.formBuilder.array([this.helper.createInstagramFormGroup()]),
      snapchat: this.formBuilder.array([this.helper.createSnapchatFormGroup()]),
      // facebook: [''],
      // twitter: [''],
      // ticktok: [''],
      // linkedIn: [''],
      // instagram: [''],
      // snapchat: [''],

      education: this.formBuilder.array([
        this.helper.createEducationFormGroup()
      ]),
      address: this.formBuilder.array([this.helper.createAddressFormGroup()]),
      custom: this.formBuilder.array([this.helper.createCustomFromGroup()]),
      emails: this.formBuilder.array(
        [this.helper.createEmailFormGroup()],
        [Validators.email]
      ),
      phones: this.formBuilder.array([this.helper.createPhoneFormGroup()]),
      links: this.formBuilder.array([this.helper.createLinkFormGroup()]),
      description: [''],
      username: [''],
      // description: [''],
    })
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.userId = params.id
        this.getAccountDetails(params.id)
        
        this.fromValue = params.from
      }
    })
  
    this.getTheCompanyValue1('',true)
  }
  checkNumber () {
    //this.elementRef.nativeElement.querySelector('.selected-flag').addEventListener('click', this.onClick.bind(this))
  }
  saveMember () {
    // console.log(123)
    let memberObj: any = {}
    let dataArray: any = []
    if (
      this.f.education.value[0].startYear > this.f.education.value[0].endYear
    ) {
      return false
    }
    if (this.f.jobTitle.value != '') {
      const jobTitle = {
        title: 'Position',
        value: this.f.jobTitle.value,
        format: 'JOB_TITLE',
        admin:true
      }
      dataArray.push(jobTitle)
    }
    if (this.f.company.value != '' && this.f.company.value != null) {
      const company = {
        title: 'Company',
        value: this.f.company.value,
        format: 'ORGANIZATION',
        admin:true
      }
      dataArray.push(company)
    }
    if (this.f.university.value != '') {
      const university = {
        title: 'University',
        value: this.f.university.value,
        format: 'ORGANIZATION',
        admin:true
      }
      dataArray.push(university)
    }
    // if (this.f.facebook.value != '') {
    //   dataArray.push({
    //     title: 'Facebook',
    //     value: this.f.facebook.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.twitter.value != '') {
    //   dataArray.push({
    //     title: 'Twitter',
    //     value: this.f.twitter.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.linkedIn.value != '') {
    //   dataArray.push({
    //     title: 'Linked In',
    //     value: this.f.linkedIn.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.ticktok.value != '') {
    //   dataArray.push({
    //     title: 'TikTok',
    //     value: this.f.ticktok.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.instagram.value != '') {
    //   dataArray.push({
    //     title: 'Instagram',
    //     value: this.f.instagram.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.snapchat.value != '') {
    //   dataArray.push({
    //     title: 'Snapchat',
    //     value: this.f.snapchat.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    if (this.f.department.value != '') {
      dataArray.push({
        title: 'Department',
        value: this.f.department.value,
        format: 'DEPARTMENT',
        admin:true
      })
    }

    this.f.address.value.forEach(function (address, i) {
      if (
        address.street1 != '' ||
        address.street != '' ||
        address.city != '' ||
        address.state != ''
      ) {
        let addValue = `${address.street}$$$${address.street1}$$$${address.postCode}$$$${address.city}$$$${address.state}$$$${address.country}`
        dataArray.push({
          title: address.title,
          value: addValue,
          format: 'LOCATION',
          admin:true
        })
      }
    })
    this.f.education.value.forEach(function (edu, i) {
      if (
        edu.startYear != '' ||
        edu.endYear != '' ||
        edu.study != '' ||
        edu.university != '' ||
        edu.degree != ''
      ) {
        let eduValue = `${edu.university}$$$${edu.degree}$$$${edu.study}$$$${edu.startYear}$$$${edu.endYear}`
        dataArray.push({
          title: 'Education',
          value: eduValue,
          format: 'UNIVERSITY',
          admin:true
        })
      }
    })
    this.f.phones.value.forEach(function (phone1, i) {
      if (phone1.value != '' && phone1.title != '' && phone1.value != null) {
        if (phone1.value.number) {
          console.log(phone1.value)
          //phone1.value.number = phone1.value.number.replace(phone1.value.dialCode,"");
          //phone1.key= phone1.value.dialCode
          //phone1.value= phone1.value.number
          let key = phone1.value.countryCode
          let number = phone1.value.internationalNumber.split('-').join('')
          number = number.split(' ').join('')
          //console.log(phone1.value.internationalNumber)
          console.log(number)
          dataArray.push({
            title: phone1.title,
            key: key.toUpperCase(),
            value: number,
            format: phone1.format,
            admin:true
          })
          //dataArray.push(phone1)
        }
      }
    })
    this.f.emails.value.forEach(function (email, i) {
      if (email.value != '') {
        email.admin =true
      email.value= email.value.toLocaleLowerCase()
        dataArray.push(email)
      }
    })
    this.f.links.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin =true
        dataArray.push(link)
      }
    })
    this.f.custom.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        cutm.admin = true
        dataArray.push(cutm)
      }
    })

     this.f.facebook.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = true
        dataArray.push(link)
      }
    })
    this.f.twitter.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = true
        dataArray.push(link)
      }
    })
    this.f.ticktok.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = true
        dataArray.push(link)
      }
    })
    this.f.linkedIn.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = true
        dataArray.push(link)
      }
    })

    this.f.instagram.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
       
        dataArray.push(cutm)
      }
    })
    this.f.snapchat.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        cutm.admin = true
        dataArray.push(cutm)
      }
    })
    console.log(this.f.company.value,'this.f.company.value')
    if(this.f.company.value && !this.allCompanies.some(x => x.name.toLowerCase() == this.f.company.value.toLowerCase()))
    {
      this.createNewCompany(this.f.company.value)
    }

    // dataArray= dataArray.concat(this.f.emails.value)
    //dataArray =dataArray.concat(this.f.phones.value)
    //dataArray= dataArray.concat(this.f.custom.value)
    //  dataArray =dataArray.concat(this.f.links.value)
    memberObj.profileId = this.userData.profiles[0]
      ? this.userData.profiles[0].profileId
      : ''
    memberObj.userId = this.userId
    memberObj.data = dataArray
    memberObj.type ="OFFICIAL"
    memberObj.title=this.f.username.value
    if (memberObj.profileId) {
      this.updateMember(memberObj)
      return
    }
    this.createOfficialProfile(memberObj)
  }

  getUserDetails (userId) {
    this.loading = true
    this.query = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        userIds: [userId]
      },
      fetchPolicy:"no-cache"
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }) => {
      this.loading = false
      this.userData = data.users[0]
      let { profiles, email,b2BPhone: phone } = this.userData
      
      if (profiles.length == 0) {
        this.getData('emails').push(
          this.formBuilder.group({
            value: [email, [Validators.required, Validators.email]],
            format: ['EMAIL'],
            title: ['Professional'],
            key: [''],
            admin:[true]
          })
        )
        this.getData('emails').removeAt(0)
        //console.log(phone)
        if(phone){
          this.getData('phones').push(
            this.formBuilder.group({
              value: ['+' + phone],
              format: ['PHONE_NUMBER'],
              title: ['Professional'],
              key: [''],
              admin:[true]
            })
          )
          this.getData('phones').removeAt(0)
        }

       
      }
      let emailArr = []

      if (this.userData.profiles[0]) {
        this.url = this.userData.profiles[0].displayImageURL
        this.publicProfileExists = (environment.CWG_ID == this.account.organizationId || environment.HAVEA_ID == this.account.organizationId) && this.userData.publicProfiles.length > 0 ? true : false 
        // console.log(this.userData.publicProfiles,this.userData.publicProfiles[0])
        this.publicProfileId = this.userData.publicProfiles.length > 0 ? this.userData.publicProfiles[0].profileId : ''
        this.userProfileId = this.userData.profiles[0].profileId

        if(this.publicProfileId && this.publicProfileId == this.userProfileId){
          // this.getMasterToken()
          //  this.getPublicprofile(this.userProfileId)
          if(this.userData.publicProfiles && this.userData.publicProfiles[0]){
            let username = this.userData.publicProfiles[0].username.replace("@","")
            this.profileForm.patchValue({
              username:`${username}`,
              description:`${this.userData.publicProfiles[0].description}`,
            })
          }
         
        }

      }
      let isEmail, isAddress, isLink, isCustom, isPhone,isFacebook, isTwitter, isSnapchat, isInstagram, isLinkedIn, isTickTok
      this.userData.profiles[0] &&
        this.userData.profiles[0].data.forEach((item, index) => {
          delete item.__typename
          // console.log(item)
          if (item.format == 'EMAIL') {
            const found = emailTypes.some(el => el.id === item.title)
            if (!found) emailTypes.push({ id: item.title, value: item.title })
            this.getData('emails').push(
              this.formBuilder.group({
                value: [item.value, [Validators.required, Validators.email]],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isEmail = true
          } else if (item.format == 'PHONE_NUMBER') {
            let moblie = item.value
            const found = phoneTypes.some(el => el.id === item.title)
            if (!found) phoneTypes.push({ id: item.title, value: item.title })
            this.getData('phones').push(
              this.formBuilder.group({
                value: [moblie],
                format: [item.format],
                title: [item.title],
                key: [item.key]
              })
            )
            if(item.key =='RU')
            {
              setTimeout(()=>{
              $(".selected-flag .iti-flag").removeClass("kz")
            },100)

            }
            if(item.key =='RU')
            {
              setTimeout(()=>{
                $(".selected-flag .iti-flag").removeClass("kz")
                $(".selected-flag .iti-flag").removeClass("us")
                $(".selected-flag .iti-flag").addClass("ru")
              },1000)
             
            }
            isPhone = true
          } else if (item.format == 'LINK') {
            const found = linkTypes.some(el => el.id === item.title)
            if (!found) linkTypes.push({ id: item.title, value: item.title })
            this.getData('links').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isLink = true
          } else if (item.format == 'UNIVERSITY') {
            this.getData('education').removeAt(0)

            let education = item.value.split('$$$')
            this.getData('education').push(
              this.formBuilder.group({
                admin:[item.admin],
                format: [item.format],
                title: [item.title], // if (item.title == 'Facebook') {
          //   this.profileForm.patchValue({
          //     facebook: item.value
          //   })
          // }
          // if (item.title == 'Twitter') {
          //   this.profileForm.patchValue({
          //     twitter: item.value
          //   })
          // }
          // if (item.title == 'Linked In') {
          //   this.profileForm.patchValue({
          //     linkedIn: item.value
          //   })
          // }
          // if (item.title == 'TikTok') {
          //   this.profileForm.patchValue({
          //     ticktok: item.value
          //   })
          // }
          // if (item.title == 'Instagram') {
          //   this.profileForm.patchValue({
          //     instagram: item.value
          //   })
          // }
          // if (item.title == 'Snapchat') {
          //   this.profileForm.patchValue({
          //     snapchat: item.value
          //   })
          // }
                key: [''],
                university: [education[0]],
                degree: [education[1]],
                study: [education[2]],
                startYear: [education[3]],
                endYear: [education[4]]
              })
            )
          } else if (item.format == 'GENERIC') {
            const found = customTypes.some(el => el.id === item.title)
            if (!found) customTypes.push({ id: item.title, value: item.title })
            if(item.title != "PROFILE_NAME" && item.title != "BIO"){
              this.getData('custom').push(
                this.formBuilder.group({
                  value: [item.value],
                  format: [item.format],
                  title: [item.title],
                  key: [''],
                  admin:[item.admin]
                })
              )
              isCustom = true
              }
          } else if (item.format == 'LOCATION') {
            const found = addressTypes.some(el => el.id === item.title)
            if (!found) addressTypes.push({ id: item.title, value: item.title })
            let location = item.value.split('$$$')
            this.getData('address').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                street: [location[0]],
                street1: [location[1]],
                city: [location[3]],
                state: [location[4]],
                country: [location[5]],
                postCode: [location[2]],
                key: [''],
                admin:[item.admin]
              })
            )
            isAddress = true
          }

          if (item.format == 'JOB_TITLE') {
            this.profileForm.patchValue({
              jobTitle: item.value
            })
          }
          if (item.format == 'ORGANIZATION'  && item.title.toLowerCase()=='company') {
            this.profileForm.patchValue({
              company: item.value
            })
          }
          if (item.format == 'ORGANIZATION'  && item.title.toLowerCase()=='university') {
            this.profileForm.patchValue({
              university: item.value
            })
          }
          if (item.title == 'Facebook') {
            // this.profileForm.patchValue({
            //   facebook: item.value
            // })
            // const found = linkTypes.some(el => el.id === item.title)
            // if (!found) linkTypes.push({ id: item.title, value: item.title })
            
            this.getData('facebook').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isFacebook = true
          }
          if (item.title == 'Twitter') {
            // this.profileForm.patchValue({
            //   twitter: item.value
            // })
            this.getData('twitter').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isTwitter = true
          }
          if (item.title == 'Linked In') {
            // this.profileForm.patchValue({
            //   linkedIn: item.value
            // })
               // })
               this.getData('linkedIn').push(
                this.formBuilder.group({
                  value: [item.value],
                  format: [item.format],
                  title: [item.title],
                  key: [''],
                  admin:[item.admin]
                })
              )
              isLinkedIn = true
          }
          if (item.title == 'TikTok') {
            // this.profileForm.patchValue({
            //   ticktok: item.value
            // })
            this.getData('ticktok').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isTickTok = true
          }
          if (item.title == 'Instagram') {
            // this.profileForm.patchValue({
            //   instagram: item.value
            // })
            this.getData('instagram').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isInstagram = true
          }
          if (item.title == 'Snapchat') {
            // this.profileForm.patchValue({
            //   snapchat: item.value
            // })
            this.getData('snapchat').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isSnapchat = true
          }
          if (item.format == 'DEPARTMENT') {
            this.profileForm.patchValue({
              department: item.value
            })
          }
         
        })
      if (isEmail) {
        this.getData('emails').removeAt(0)
      }
      if (isPhone) {
        this.getData('phones').removeAt(0)
      }
      if (isLink) {
        this.getData('links').removeAt(0)
      }
      if (isCustom) {
        this.getData('custom').removeAt(0)
      }
      if (isAddress) {
        this.getData('address').removeAt(0)
      }
      if (isLinkedIn) {
        this.getData('linkedIn').removeAt(0)
      }
      if (isInstagram) {
        this.getData('instagram').removeAt(0)
      }
      if (isSnapchat) {
        this.getData('snapchat').removeAt(0)
      }
      if (isTwitter) {
        this.getData('twitter').removeAt(0)
      }
      if (isTickTok) {
        this.getData('ticktok').removeAt(0)
      }
      if (isFacebook) {
        this.getData('facebook').removeAt(0)
      }
      this.profileForm.patchValue({
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
      })
      if(!this.f.username.value){
        let fullName =`${this.userData.firstName}${this.userData.lastName}`
        fullName = fullName.replace(/\s/g, "");
        this.profileForm.patchValue({
          username:`${fullName}`,
          })
      }
     
      setTimeout(()=>{
			this.enableAutoCountrySelect = false
		},2000)
    },err=>{
      alert(err)
      this.loading =false
    })
  }
  //   get emails(): FormArray {
  //     return this.profileForm.get('emails') as FormArray;
  //  }
  updateMember (memberObj) {
    memberObj.userId = this.userId
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateProfileMemberMutution,
        variables: memberObj,
        update: (proxy, { data: { LQQHPRFSLK } }: any) => {
          try {
            //  let data: any = proxy.readQuery({ query:membersBasicQuery,variables:{userIds: [memberObj.userId]} });
            let data: any = proxy.readQuery({
              query: membersBasicQuery,
              variables: { userIds: [memberObj.userId] }
            })
            data.users[0].profiles[0] = LQQHPRFSLK
            //proxy.writeQuery({ query: membersBasicQuery, data });

            data = proxy.readQuery({
              query: membersBasicQuery,
              variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
            })
            const foundIndex = data.users.findIndex(
              x => x.userId == memberObj.userId
            )
            data.users[foundIndex].profiles.push(LQQHPRFSLK)
            proxy.writeQuery({
              query: membersBasicQuery,
              variables: { limit: 5, offset: 0, sortByDate: 'DESC' },
              data
            })
          } catch (e) {}
        }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false

          if (data.LQQHPRFSLK) {
            if (this.profilePic) {
              this.fileUploadToServer(data.LQQHPRFSLK.displayImagePutURL)
            } else {
              if( this.publicProfileId == memberObj.profileId || !this.publicProfileId){
                  this.updatePublicProfileInfo(memberObj.profileId)
              }
              else{
                this.translate
                .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
                this.router.navigate(['/view-member',this.userId])
              }
            }
           
          } else {
            this.translate
              .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
          }
        },
        error => {
          this.loading = false

          this.translate
          .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  createEmpFormGroup () {
    return this.formBuilder.group({
      empName: ['', [Validators.required]],
      age: ['', [Validators.required, Validators.min(21)]],
      skill: ['', [Validators.required]]
    })
  }

  removeBlock (idx: number, type) {
    this.getData(type).removeAt(idx)
  }

  createOfficialProfile (memberObj) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createProfileMemberMutution,
        variables: memberObj,
        update: (proxy, { data: { QJTHNWOMEA } }: any) => {
          try {
            //  let data: any = proxy.readQuery({ query:membersBasicQuery,variables:{userIds: [memberObj.userId]} });
            let data: any = proxy.readQuery({
              query: membersBasicQuery,
              variables: { limit: 5, offset: 0, sortByDate: 'DESC' }
            })
            const foundIndex = data.users.findIndex(
              x => x.userId == memberObj.userId
            )
            data.users[foundIndex].profiles.push(QJTHNWOMEA[0])
            proxy.writeQuery({ query: membersBasicQuery, data })
            data = proxy.readQuery({
              query: membersBasicQuery,
              variables: { userIds: [memberObj.userId] }
            })

            data.users[foundIndex].profiles.push(QJTHNWOMEA[0])
            proxy.writeQuery({
              query: membersBasicQuery,
              variables: { limit: 5, offset: 0, sortByDate: 'DESC' },
              data
            })
          } catch (e) {}
        }
      })
      .subscribe(
        ({ data }: any) => {
          this.loading = false

          if (data.QJTHNWOMEA[0]) {
            let profileId = data.QJTHNWOMEA[0].profileId
            // this.createBranchLink(profileId)
            if (this.profilePic) {
              this.fileUploadToServer(data.QJTHNWOMEA[0].displayImagePutURL)
            } else {
              
              if(this.publicProfileId == memberObj.profileId || !this.publicProfileId){
                this.updatePublicProfileInfo(profileId)
              }
              else{
                this.translate
                .get('MESSAGES.PROFILE_CREATED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
                this.router.navigate(['/members'])
              }
              
            }

          } else {
            this.translate
              .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })
          }
        },
        error => {
          this.loading = false

          console.log('there was an error sending the mutation', error)
        }
      )
  }

  openModal (type, index: number) {
    const modalRef = this.modalService.open(CreateNewLabelComponent)
    modalRef.componentInstance.newLabel = ''
    modalRef.result.then(
      result => {
        if (result) {
          if (type === 'address') {
            if (result != 'cross-click') {
              addressTypes.push({
                id: result,
                value: result
              })
            } else {
              result = addressTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'links') {
            if (result != 'cross-click') {
              linkTypes.push({
                id: result,
                value: result
              })
            } else {
              result = linkTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }

          if (type === 'custom') {
            if (result != 'cross-click') {
              customTypes.push({
                id: result,
                value: result
              })
            } else {
              result = customTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'phones') {
            if (result != 'cross-click') {
              phoneTypes.push({
                id: result,
                value: result
              })
            } else {
              result = phoneTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'emails') {
            if (result != 'cross-click') {
              emailTypes.push({
                id: result,
                value: result
              })
            } else {
              result = emailTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
        }
      },
      error => {
        // alert(234)
      }
    )
  }

  get f () {
    return this.profileForm.controls
  }
  get emails (): FormArray {
    return this.profileForm.get('emails') as FormArray
  }

  getData (type): FormArray {
    return this.profileForm.get(type) as FormArray
  }
  addEmails () {
    let fg = this.helper.createEmailFormGroup()
    this.getData('emails').push(fg)
  }
  addPhones () {
    const total = this.f.phones.value.length
    if (this.f.phones.value[total - 1].value != '') {
      let fg = this.helper.createPhoneFormGroup()
      this.getData('phones').push(fg)
    }
  }

  addAddress () {
    let fg = this.helper.createAddressFormGroup()
    this.getData('address').push(fg)
  }
  addLinks () {
    let fg = this.helper.createLinkFormGroup()
    this.getData('links').push(fg)
  }
  addCustom () {
    let fg = this.helper.createCustomFromGroup()
    this.getData('custom').push(fg)
  }
  addCustomeLabel (eve, type, index) {
    eve.srcElement.blur()
    eve.preventDefault()
    // return
    if (eve.target.value == 'new') {
      this.openModal(type, index)
    }
  }
  onSelectFile (event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0]) // read file as data url
      // console.log(this.url)

      this.profilePic = event.target.files[0]
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result
        // console.log(this.url)
      }
    }
  }

  ngOnDestroy () {
    this.query$ ? this.query$.unsubscribe() : ""
    // alert()
    // this.profileForm.reset()
  }

  fileUploadToServer (url) {
    let headers = new HttpHeaders({
      'Content-Type': 'image/jpeg'
    })
    let options = {
      headers: headers
    }
    this.http.put(url, this.profilePic, options).subscribe(
      res => {
        // handle success
        //reset file input
        this.translate
          .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
        // this.router.navigate(['/members'])
        window.location.href = '/members';
      },
      err => {}
    )
  }

  getValidUrl = (url = '') => {
    let myWindow = window as any
    let newUrl = myWindow.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, '')

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`
    }

    return newUrl
  }
  goToBack()
  {
    // this.router.navigate(['/view-member',this.userId])
    if(this.fromValue ==2){
      this.router.navigate(['/view-member',this.userId])
    }
    else if(this.fromValue ==2){
      this.router.navigate(['/create-member',this.userId])
    }
    else{
      this.router.navigate(['/members'])
    } 
  }
  

  getTheCompanyValue1($event,all=false){
    let inputObj:any = {
      name:$event
    }
    if(all){
      inputObj = {}
    }
    this.companyQuery = this.apollo.watchQuery({
      query: getCompanyQuery,
      fetchPolicy:"network-only",
      variables: inputObj,
    })
     this.companyQuery.valueChanges.subscribe(({ data }) => {
      if(all){
        this.allCompanies = data.getCompany
      }
      else{
        this.companiesArray = data.getCompany
      }
      let uniq = {}

      this.companiesArray=this.companiesArray.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
      if(all){
        this.allCompanies=this.allCompanies.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
        this.allCompanies = this.allCompanies.map(element => {
          element.name =element.name.toLowerCase()
          element.name = element.name.replace(/['"]+/g, '')
          element.name = element.name.replace(/["']+/g, '')
          return element
        });
        
      } 
      if (!this.cd['destroyed']) {
         this.cd.detectChanges();
      }
    })
  }
  getTheCompanyValue(term){
      if(!term){
        return false
      }
      this.http
      .get<any>(`https://api.opencorporates.com/v0.4/companies/search?q=${term}&format=json`)
      .subscribe((data: any) => {
        // console.log(data.results.companies)
        this.companiesArray = data.results.companies.map(element => {
           element.name =element.company.name.toLowerCase()
           element.name = element.name.replace(/['"]+/g, '')
           element.name = element.name.replace(/["']+/g, '')
           return element
        });
        console.log(this.companiesArray)
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
       }
      },err=>{
        this.getTheCompanyValue1(term)
      })
    // }
  
  }
   onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        console.log(event)
        $this.getTheCompanyValue(event);
      }
    }, 500);
  }

  createNewCompany(name){
    this.apollo.mutate({
      mutation: createCompanyMutation,
      variables:  {
        name:name,
        orgId:this.account.organizationId
      }
    }).subscribe(({ data,loading,error }:any) => {
  
       },
       err => {
          console.log(err)
       }
    );
  }
  
  addTagPromise(name) {
    return new Promise((resolve) => {
        this.loading = true;
        // Simulate backend call.
        setTimeout(() => {

            // resolve({ id: this.companiesArray+1, name: name, valid: true });
            resolve(name);
            this.loading = false;
        }, 1000);

    })
}
  onSearchChange(eve){
    if((eve.key=="0" || eve.which ==48) && eve.target.value.length == 0){
      return false
    }
  return true
  }
  addFacebook () {
    let fg = this.helper.createFacebookFormGroup()
    this.getData('facebook').push(fg)
  }
  addTwitter () {
    let fg = this.helper.createTwitterFormGroup()
    this.getData('twitter').push(fg)
  }
  addSnapchat () {
    let fg = this.helper.createSnapchatFormGroup()
    this.getData('snapchat').push(fg)
  }
  addInstagram () {
    let fg = this.helper.createInstagramFormGroup()
    this.getData('instagram').push(fg)
  }
  addTiktok () {
    let fg = this.helper.createTikTokFormGroup()
    this.getData('ticktok').push(fg)
  }
  addLinkedIn () {
    let fg = this.helper.createLinkedInFormGroup()
    this.getData('linkedIn').push(fg)
  }
  updatePublicProfileInfo(id){
    this.apollo
    .mutate({
      mutation: setPublicProfileMutution,
      variables:{
        profileId:id,
        username:this.f.username.value,
        status:'',
        description: this.f.description.value,
        userId:this.userId
      },
      update: (proxy, { data: { gOGvJiJdYl } }: any) => {
        
      }
    })
    .subscribe(
      ({ data }:any) => {
        if(data.gOGvJiJdYl){
          this.router.navigate(['/view-member',this.userId])
        } 
        else{
          alert("Username already exists. Please choose another.")
        }

  },error => {
    this.loading = false
    alert("Username already exists. Please choose another.")
  })
  }
  getPublicprofile(title){
    this.loading = true
    this.publicProfileQueryM =  this.apollo.watchQuery({
      query: publicProfileQuery,
      variables:{
        id:this.userId
      },
      fetchPolicy:"network-only"
    })
    this.publicProfileQueryM.valueChanges.subscribe(result => {
      if (result.data.publicProfile) {
        // alert()
        let {username,description,title} = result.data.publicProfile
        this.profileForm.patchValue({
          description:description
        })
        
      }

      this.loading = false
    },error => {
      this.loading = false
    })
    // this.http
    //   .get<any>(`https://api.staytouch.com/v2.1/public/profile/@${title}`)
    //   .subscribe((event: any) => {
    //     this.loading = false
    //     if(event && event.description){
    //       this.profileForm.patchValue({
    //         description:event.description
    //         })
    //     }
          
        
    //   },err=> {
    //     this.loading=false
    //   })
  }
  createBranchLink(profileId){
    this.apollo
      .mutate({
        mutation: generateBranchLink,
        variables: {
          profileId:profileId
        }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.kbtsEXRVpv) {
            //link generated successfully.
          } 
        },
        error => {
          this.loading = false
        }
      )
  }
  getMasterToken(){
    let userInfo = {
      "identityProvider": "STAYTOUCH",
      "credentials": {
        "email": this.f.email.value,
        "password": "=s@^jxqay+wpjzw$=94w9ga9vqrs$hvt$tj3tTYiW",
        "master": true,
        // "master": true
      },
      "token": environment.AUTH_TOKEN,
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
    }
   let headers = {}


    this.http.post<any>(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, userInfo,headers).subscribe(data => {
      
    },(error) => {

    });      
  }
}
