import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login-service';
import { updateMultiFactorMutation } from '../../../services/user.gql'
import { Apollo } from 'apollo-angular'
import { Router, Params } from '@angular/router'

@Component({
  selector: 'app-verify-mfa',
  templateUrl: './verify-mfa.component.html',
  styleUrls: ['./verify-mfa.component.scss']
})
export class VerifyMfaComponent implements OnInit {

  tfa: any = {};
  authcode: string = "";
  errorMessage: string = null;

  constructor(private _loginService: LoginService,private apollo: Apollo,public router: Router) {
    this.getAuthDetails();
  }

  ngOnInit() {
  }

  getAuthDetails() {
    this._loginService.getAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        console.log(result);
        if (result == null) {
          this.setup();
        } else {
          this.tfa = result;
        }
      }
    });
  }

  setup() {
    this._loginService.setupAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        console.log(result);
        this.tfa = result;
      }
    });
  }

  confirm() {
    this._loginService.verifyAuth(this.authcode).subscribe((data) => {
      const result = data.body
      if (result['status'] === 200) {
        console.log(result);
        // this.errorMessage = null;
        // this.tfa.secret = this.tfa.tempSecret;
        // this.tfa.tempSecret = "";
        this.updateMfaStatus(true)
      } else {
        this.errorMessage = result['message'];
      }
    });
  }

  disabledTfa() {
    this._loginService.deleteAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        console.log(result);
        this.authcode = "";
        this.getAuthDetails();
      }
    });
  }
  updateMfaStatus(activate){
    console.log(activate)
    this.apollo
    .mutate({
      mutation: updateMultiFactorMutation,
      variables: {
        activate: activate,
      }
    })
    .subscribe(({ data }: any) => {
      this.router.navigate(['/update-account'])

  })
  }
}


