import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  MEMBER_LOGIN = '[Auth] Member Login',
  MEMBER_LOGIN_SUCCESS = '[Auth] Member Login Success',
  MEMBER_LOGIN_FAILURE = '[Auth] Member login Failure',
  MEMBER_LOGOUT = '[Auth] Member Logout',
  MEMBER_STATUS = '[Auth] Member Status',
}

export class MemberLogIn implements Action {
  readonly type = AuthActionTypes.MEMBER_LOGIN;
  constructor(public payload: any) {}
}

export class MemberLogInSuccess implements Action {
  readonly type = AuthActionTypes.MEMBER_LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class MemberLogInFailure implements Action {
  readonly type = AuthActionTypes.MEMBER_LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class MemberLogOut implements Action {
  readonly type = AuthActionTypes.MEMBER_LOGOUT;
}

export class MemberStatus implements Action {
  readonly type = AuthActionTypes.MEMBER_STATUS;
}

export type All =
  | MemberLogIn
  | MemberLogInSuccess
  | MemberLogInFailure
  | MemberLogOut
  | MemberStatus;
