export let addressTypes = [
  { id: 'Address', value: 'Address' },
  { id: 'Home', value: 'Home' },
  { id: 'Office', value: 'Office' },
  { id: 'new', value: 'Create a custom label' }
]
export let linkTypes = [
  { id: 'Link', value: 'Link' },
  { id: 'new', value: 'Create a custom label' }
]
export let customTypes = [
  { id: 'Custom', value: 'Custom' },
  { id: 'new', value: 'Create a custom label' }
]
export let emailTypes = [
  { id: 'Professional', value: 'Professional' },
  { id: 'Personal', value: 'Personal' },
  { id: 'Other', value: 'Other' },
  { id: 'new', value: 'Create a custom label' }
]
export let phoneTypes = [
  { id: 'Professional', value: 'Professional' },
  { id: 'Personal', value: 'Personal' },
  { id: 'Office', value: 'Office' },
  { id: 'Fix', value: 'Fix' },
  { id: 'Other', value: 'Other' },
  { id: 'new', value: 'Create a custom label' }
]
