<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">{{'MEMBERS_PAGE.CREATE_CUSTOM_LABEL' | translate}}</h5>
    <button type="button" class="close" aria-label="Close"  (click)="activeModal.close('cross-click')" >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
	  <div class="d-flex">
      <label  class="">{{'MEMBERS_PAGE.LABEL_NAME' | translate}}</label>
      <div class="w-100 flex-1 pl-3">
        <input type="text" [(ngModel)]="newLabel" class="theme-input" />
        <div *ngIf="error" class="text-danger">
          {{error}}
        </div>
      </div>
     
	  </div>
   
    <div class="text-center pt-3">
		<button  type="submit" class="theme-btn" (click)="passBack()">{{'MEMBERS_PAGE.SAVE' | translate}}</button>
	</div>
  </div>
