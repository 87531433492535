<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
   <div class="col-md-12 p-2  ">
      <div class="card py-md-3 py-3 px-3 ">
        <!-- <h5 class=" ">Search User & Add Products</h5> -->

         <div class="d-flex justify-content-between align-items-start pb-2 flex-wrap">
            <div class=" col-md-6  pt-3 pr-0 pl-md-2 pl-0">

               <div class="d- text-left py-3">
                  <label>Enter  Phone number</label>
                <div class="d-flex align-items-center  ">
                    <input type="tel" class="form-control col-md-8 col-7" maxlength="10" placeholder="Enter here" (keypress)="numberOnly($event)" [(ngModel)]="phone"  />
                    <div class="pl-3 col-6 col-md-4">
                      <a class="theme-btn text-center  " (click)="filterCustomer()"> Search</a>
                    </div>
                </div>
                 
               </div>

             
            </div>
            
            <div class="col-md-12 pt-2 pb-2 px-0 px-md-2  "  *ngIf="customerInfo">
                
                <h5 class="">User Info</h5>
                <table class="table table-bordered" >
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone number</th>
                    </tr>
                    <tr>
                        <td>{{customerInfo.firstName}} {{customerInfo.lastName}}</td>

                        <td>{{customerInfo.email}} </td>
                        <td>{{customerInfo.phone}} </td>
                    </tr>
                   
                </table>
            </div>
            <div class="col-md-12 pt-4 pb-2 px-md-2 px-0 "  *ngIf="!customerInfo && isFitler">
                
              <h5 class="">No user info found. <a (click)="createCustomer()" class="default-color cursor-pointer">Create Customer</a></h5>
          
          </div>
		

            <ng-container *ngIf="customerInfo">
                <div class="mt-3 mb-3" style="width: 500px;" id="reader" *ngIf="false && customerInfo && qrCodeScan"></div>
              <div id="video-container" *ngIf="customerInfo && qrCodeScan ">

            <video id="qr-video" width="100%" style="background: black;"></video>
        </div>
				<div class=" pt-3 pb-3" style="width: 500px;max-width: 100%;text-align: center;" > <a class="theme-btn text-center" (click)="stopScan()" *ngIf="customerInfo && qrCodeScan">Stop Scanner</a></div>
              <div class="text-center w-100 pt-2 d-flex flex-wrap justify-content-center" *ngIf="!qrCodeScan" >
                <a class="theme-btn m-1" (click)="searChProducts(1)" >Search Products</a>
                <a class="theme-btn m-1" (click)="qrCodeScan=true;searChProducts(2);" > Qr Code Scan</a>
            </div>

            <div class="col-md-12 pt-4" *ngIf="products && products.length > 0">
                <div class="d-flex justify-content-between pb-3">
                    <h5 class="">Product details</h5>
                </div>
                
                <table class="border table table-bordered">
                    <tr  *ngFor="let product of products;let j= index">
                        <td width="70"> <img  width="70px" style="object-fit: contain;" height="auto" onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;object-fit: contain;'" class="img-fluid1 " src="{{product.photos[0] && product.photos[0].signedGetURL}}" alt=""></td>
                        <td>
                            <div class="pl-3">
                                <h5> {{product.productName}}</h5>
                                <h6>{{product.displayedSellingPrice}} MAD</h6>
                             </div>
                        </td>
                        <td class="text-center pt-4">
                            <a class="text-danger" (click)="removeFromCart(j)"><i class="fa fa-trash"></i>
                            </a>
                        </td>
                    </tr>
                </table>
                <div class="text-center">
                    <a [routerLink]="['/cart-list']" class="theme-btn">  Checkout</a>
                </div>
                
                <div class="d-flex align-items-center " >
                   
                 
                    <div class="text-right ml-auto">
                        <!-- <a class="theme-btn" (click)="addToCart(product)">Add to cart</a> -->
                    </div>
                </div>
            </div>

           
            </ng-container>
            

          


         </div>
      </div>
   </div>
</div>
