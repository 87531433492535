<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
   <div class="col-md-12  px-0">
  
      <div class="col-md-12 p-2">
         <div class="card py-3 ">
<div class="px-4">
            <div class="row   pb-1 pt-2">
               <!-- Types-->
               <!-- Types-->
               <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
                  <h5 class="col-md-2 px-0">Products List</h5>
                  <div class="dropdown col-md-5 col-10 pl-0 px-md-2">
                     <div class="position-relative ">
                       <input
                         type="text"
                         placeholder="Search Products"
                         (input)="isFilter=true;filterProjects($event)"
                         id="searchMeetings"
                         class="theme-input"
                         [(ngModel)]="searchText"
                         style="background:transparent;"
                       />
                       <img
                         id="blah"
                         src="assets/icon_search.svg"
                         alt="search"
                         *ngIf="!isFilter"
                         class="searchIcon"
                       />
                       <img
                         id="blah"
                         src="assets/icon_X.png"
                         alt="search"
                         (click)="clearFilter()"
                         
                         *ngIf="isFilter"
                         class="searchIcon cursor-pointer"
                       />
                      </div>
                  
                  </div> 
                  <div class="col-md-3 d-flex align-items-center">
                    <span>Show</span> 
                     <input class="input theme-input mx-2" [(ngModel)]="itemsPerPage"  min="0" type="number" 
                     (input)="getProducts()"
                     /> 
                     <span>entries</span>
                  </div>
                  <div class="dropdown  col-md-1 text-right px-0 col-2"   >
                     <ng-container *ngIf="isUserAllow">
                        <a data-toggle="dropdown" class="">
                           <i class="fa fa-plus circle-icon" style="    color: #fff;"></i>
                        </a>
                        <div
                           class="dropdown-menu dropdown-menu-right dropdown-menu-arrow  "
                        >
                           <ul class="list-inline minWidth" style="min-width: 170px;">
                           <li class="pt-2  p-2  border-bottom ">
                              <a (click)="addProduct()"   class=" text-decoration-none   editContact"   >Add Product</a>
                           </li>
                           <li class="pt-2  p-2 ">
                              <a
                              class=" text-decoration-none   editContact"
                              [routerLink]="['/import-product']"
                              >
                              Import Product
                              </a>
                           </li>
                           </ul>
                        </div>
                     </ng-container>
                    
                  </div>
                  <div class="dropdown col-md-7 pt-3 pl-0 cust-theme px-0 px-md-2">
                     <!--<select (change)="filterChanged($event.target.value)" style="border-radius: 0px;">
                        <option>Sort by:</option>
                        <option *ngFor="let opt of sortOptions" [value]="opt.id" >{{opt.name}}</option>
                     </select>-->
                     <div class="d-flex align-items-end flex-md-nowrap flex-wrap">
                        <!-- <select (change)="filterBy($event)" class="theme-select col-md-6" [(ngModel)]="filterColumn"  (input)="filterProducts()">
                           <option value="0">Filter by:</option>
                           <option *ngFor="let opt of columnType" [value]="opt.filterName" >{{opt.name}}</option>
                        </select> -->
                        <ng-select [items]="columnType"
            bindLabel="name"
            bindValue="filterName"
            class="theme-select col-md-6 col-12 mb-2 mb-md-0"
            [clearable]="false"
            [(ngModel)]="filterColumn"
            placeholder ="Search or Enter Filter name"
            (change)="filterProducts()"
            >
            </ng-select>

                        <div  class="ml-md-2 ml-0 col-md-6 col-12 px-md-2 px-0 mb-2 mb-md-0"  >
                           <input type="text" class="theme-input" placeholder="Column Value" [(ngModel)]="filterColumnValue" (input)="filterProducts()" />
                        </div>

                     </div>
                  </div>

                     <div class="dropdown col-md-4 text-right pt-3 pr-0 px-0 px-md-2 mb-2 mb-md-0">
                        <select (change)="selectAction($event,memberModal)" class="rounded-0 theme-select">
                            <option value="0">Select Action</option>
                           <!--<option value="7" *ngIf="!showCheckboxes && role != 'SALES'">Edit</option>
                           <option value="1" *ngIf="showCheckboxes && !selectAll">Check All</option> -->
                           <!-- <option value="2"  >Un Check All</option> -->
                           <!-- <option value="3" *ngIf="showCheckboxes">Active</option> -->
                           <!-- <option value="4"  *ngIf="showCheckboxes">Inactive</option> -->
                           <option value="5" *ngIf="isUserAllow">Delete</option>
                           <option value="8" >Excel Export</option>
                           <!-- <option value="9"  >Product Access</option> -->
                           <!-- <option value="10" *ngIf="showCheckboxes" >Export Select</option> -->
                           <option value="6" *ngIf="showCheckboxes">Cancel</option>
                        </select>
                     </div> 


               </div>
             
            </div>
            <div class="d-flex1 w-100 align-items-end pb-2 pt-3 ">
               <div class=" px-0 pb-2 d-flex w-100 justify-content-between align-items-center flex-wrap">
                  <label class="font-weight-bold">Price</label>
                  <div class="col-md-4">
                     <ngx-slider  [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                     (valueChange)="rangeChange($event)"
   
                     ></ngx-slider>
                  </div>
                 
                  <div class="d-flex justify-content-between pt-2 col-md-4">
                     <input type="number" [(ngModel)]="minValue" class="theme-input" name="minValue" placeholder="Min" style="width: 80px" />
                     <span class="px-3">to</span>
                     <input type="number" [(ngModel)]="maxValue" class="theme-input" name="maxValue" placeholder="Max" style="width: 80px" />
                  </div>
                  <div class="py-md-2 px-0 pt-3">
                     <a (click)="clearFilterData()" class="theme-btn">Clear Filter</a>
                  </div>
               </div>
              
            </div>
            </div>
                            <!-- Content Row -->
        
        
                            <div class="row col-md-12 px-0  mx-0 h-100">
                
                  <div class="col-md-12  col-lg-12">
                    
                     <div class="container-fluid">
                      
                      
                        
                        

                          <!-----------List View-------------------->
                        <div class="row" >
                          
                           <div class="col-md-12 table-responsive px-0">
                              <table class="table table-hover js-basic-example dataTable table-custom m-b-0" style="min-height: 250px;">

                                 <thead >
                                    <tr >
                                      
                                       <th  style="vertical-align: middle;">
                                          <div class="form-check pb-3">
                                             <label class="form-check-label">

                                               <input (change)="checkUncheckAll(!selectAll)" [checked]="selectAll"  type="checkbox" class="form-check-input" value="">
                                             </label>
                                           </div>
                                       </th>
                                       <th >Image
                                          <div class="text-right">
                                             <i style="visibility: hidden;" class="fa fa-sort"
                                             
                                             ></i>
                                             </div>

                                       </th>
                                       <th *ngFor="let column of theadArray" class="cursor-pointer"  (click)="sortBy(column)">
                                         
                                          {{column.name}}
                                          <div class="text-right">
                                             <i class="fa fa-sort-down"
                                             [ngClass]="{ 'fa-sort-up': column.sortType == 'ASC','fa-sort-down': column.sortType == 'DESC',
                                             'fa-sort': column.sortType == ''
                                           }"
                                             ></i>
                                          </div>
                                         
                                       </th>
                                       <!-- <th (click)="sortBy('category')">Category
                                          
                                       </th>

                                       <th (click)="sortBy('product_name')"> Name
                                          <i class="fa fa-sort"></i>
                                       </th>
                                       <th (click)="sortBy('stock_quantity')">Inventory
                                          <i class="fa fa-sort-down"></i> </th>
                                       <th (click)="sortBy('taj_number')"> Taj number</th>

                                       <th (click)="sortBy('gold_color')" >Gold color </th>
                                       <th (click)="sortBy('gold_weight')" >Gold weight<br/>(grams) </th>
                                       <th (click)="sortBy('diamond_weight')">Diamond weight <br/>(ct) </th>

                                       <th (click)="sortBy('displayed_selling_price')" width="120px"> Displayed selling price (MAD)</th> -->
                                       <!-- <th >Discount</th> -->
                                       <!-- <th width="120px"> Minimum selling price  (MAD)</th> -->

                                       <!-- <th > Description</th> -->
                                       <!-- <th >Gold Color</th>
                                       <th >Gold weight (grams)</th>
                                       <th > Diamond weight (ct)</th> -->
                                       <th ></th>
                                    </tr>
                                 </thead>
                                 <tbody >
                                    <tr class="cursor-pointer"   *ngFor="let product of products| paginate: { itemsPerPage: itemsPerPage , currentPage: page, totalItems: totalItems };let i=index  ">

                                       <td style="vertical-align: initial;">
                                          <div class="form-check">
                                             <label class="form-check-label">
                                               <input   (change)="selectCheckbox($event,product,i)" [(ngModel)]="product.isSelected" type="checkbox" class="form-check-input" value="">
                                             </label>
                                           </div>
                                       </td>
                                       <td  
                                       
                                       style="width: 80px;" (click)="productDetails(product.id,page)">
                                       <img  width="70px" style="object-fit: contain;" height="auto" onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;object-fit: contain;'" class="img-fluid1 mx-auto d-block img-p" src="{{product.photos[0] && product.photos[0].signedGetURL}}" alt=""></td>
                                       <td  (click)="productDetails(product.id,page)" > {{product.productCategory.name}}</td>
                                       <!-- <td  [routerLink]="['/product-details',product.id]">
                                          <div  class="text-ecllipse" style="max-width: 200px;">
                                             {{product.description}} </div>
                                       </td> -->
                                       <td  class="text-capitalize" (click)="productDetails(product.id,page)"> {{product.productName}}
                                          
                                       </td>
                                       <td  (click)="productDetails(product.id,page)">{{product.stockQuantity}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.tajNumber}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.goldColor}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.goldWeight}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.diamondWeight}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{roundToNear(product.displayedSellingPrice)}}</td>
                                       <!-- <td [routerLink]="['/product-details',product.id]">{{product.maxAllowed}}%</td> -->
                                       <!-- <td [routerLink]="['/product-details',product.id]">{{round(product.minSellingPrice)}}</td> -->
                                 
                                       <!-- <td>{{product.goldColor}}</td> -->
                                       <!-- <td>{{product.goldWeight}}</td> -->
                                       <!-- <td>{{product.diamondWeight}}</td> -->
                                       <!-- <td [routerLink]="['/product-details',product.id]"> {{product.productCategory && product.productCategory.name}}</td>
                                       <td>{{product.stockQuantity > 0 ? 'In Stock' : 'Out of stock'}}</td>
                                       <td>{{product.status ? 'Active' : 'Inactive'}}</td>
                                       <td [routerLink]="['/product-details',product.id]"> {{round(product.sellingPrice)}}</td>
                                       <td [routerLink]="['/product-details',product.id]"> {{round(product.stockQuantity)}}</td> -->

                                       <td class="project-actions">
                                          <a
                                data-toggle="dropdown"
                                class="ml-2"
                                style="border-bottom:0px;"
                                
                              >
                                          <img _ngcontent-mfd-c8="" alt="dots" id="blah" src="assets/dots.svg" style="transform: rotate(90deg);"></a>
                                          <div
                                  class="dropdown-menu dropdown-menu-right dropdown-menu-arrow "
                                 
                                >
                                <!-- <a
                                class="text-decoration-none p-2 ml-0 d-block border-bottom"
                                data-toggle="modal"
                                style="border-bottom:0px;"
                                (click)="addToCart(product.id)"
                                >Add to cart</a
                              > -->
                              <!-- <a
                              class="text-decoration-none p-2 ml-0 d-block border-bottom"
                              data-toggle="modal"
                              style="border-bottom:0px;"
                              (click)="updateQuatity(product.id)"
                              *ngIf="product.stockQuantity > 0"
                              >Edit</a
                            > -->
                                  <a
                                    class="text-decoration-none p-2 ml-0 d-block border-bottom"
                                    data-toggle="modal"
                                    style="border-bottom:0px;"
                                    (click)="addProduct(product.id)"
                                    *ngIf="isUserAllow"
                                    >Edit</a
                                  >
                                  <a
                                  class="text-decoration-none p-2 ml-0 d-block border-bottom"
                                  data-toggle="modal"
                                  style="border-bottom:0px;"
                                  (click)="confirmDelete(product)"
                                  *ngIf="isUserAllow"
                                  >Delete
                                  </a
                               >
                                    <a
                                       class="text-decoration-none p-2 ml-0 d-block border-bottom"
                                       data-toggle="modal"
                                       style="border-bottom:0px;"
                                      (click)="selectProduct=product;print(product)"
                                       >Print QR Code</a
                                    >
                                    <a
                                    class="text-decoration-none p-2 ml-0 d-block "

                                    data-toggle="modal"
                                    data-target="#qrCodeModal"
                                    style="border-bottom:0px;"
                                    (click)="selectProduct=product;isQROpen = true"
                                    >Scan QR Code</a
                                 >
                                 
                                </div> 
                                      </td>
                                    </tr>
                                    
                                    <!---->
                                 </tbody>
                                 </table>
                        </div>
                     </div>
                     <h5 class="text-center" *ngIf="!loading && products.length ==0">No products data.</h5>
                        <!-----------List View-------------------->
                        <div class="row sorting mb-5 mt-5 d-none">
                           <div class="col-12">
                     
                              <div class="btn-group float-md-right ml-3">
                                 <button type="button" class="btn btn-lg btn-light"> <span class="fa fa-arrow-left"></span> </button>
                                 <button type="button" class="btn btn-lg btn-light"> <span class="fa fa-arrow-right"></span> </button>
                              </div>
                              <div class="dropdown float-md-right">
                                 <label class="mr-2">View:</label>
                                 <a class="btn btn-light btn-lg dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">12 <span class="caret"></span></a>
                                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#">12</a>
                                    <a class="dropdown-item" href="#">24</a>
                                    <a class="dropdown-item" href="#">48</a>
                                    <a class="dropdown-item" href="#">96</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="has-text-centered text-center pt-4" *ngIf="products.length >0">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>

                    </div>
                  </div>
                
               </div>
               <!-- Content Row -->
            <!-- /.container-fluid -->



                  <div class="text-center">
                     <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
                  </div>
                  <div id="print-section"></div>
                  <div class="row justify-content-center m-0 d-none" id="data" *ngIf="selectProduct">
                      <qrcode [qrdata]="selectedLink" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                  </div>

          </div>
      </div>
   </div>
</div>



<ng-template #memberModal let-modal>
   <div class="">
     <div class="modal-content p-3">
       <!-- Modal Header -->
       <div class="modal-header border-0 p-0 grid-st">
         <p id="modal-basic-title" class="text-uppercase font-weight-bold text-center">
           Products Access 
         </p>
            
         <span aria-hidden="true" class="cursor-pointer text-right default-color
         " (click)="modal.dismiss(0)"><img src="assets/icon_X.svg" /></span>

       </div>
       <!-- Modal body -->
       <div class="modal-body pt-2">
        
         <form class="row justify-content-center m-0"   
       >
         <div class="w-100">
            <label>Select Action</label>
            <select  class="theme-select" name="accessValue" [(ngModel)]="accessValue">
               <option value="true">Products assign to members</option>
               <option value="false">Cancel access to members</option>
            </select>
         </div>

           <div class="w-100 pt-3 product-select">
            <label class="pb-2">Select Members</label>

            <ngx-select
            name="users"
            [allowClear]="true"
            [multiple]="true"
            [keepSelectedItems]="false"
            [items]="members"
            optionValueField="userId"
            optionTextField="firstName"
            [(ngModel)]="users"
            placeholder="+ Add Members"
            class="theme-select team-select "
          >
            <ng-template
              ngx-select-option
              ngx-select-option-selected
              let-option
              let-text="text"
            >
              <div class="d-flex text-left dropdown-custom">
                <img
                  src="assets/default.jpg"
                  class="borderImage1 border-501"
                  height="25px"
                  width="auto"
                />
                <div>
                  <p class="mb-0">{{option.data.firstName}} {{option.data.lastName}}</p>
                </div>
              </div>
            </ng-template>

            <ng-template ngx-select-option-not-found let-input>
              {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
            </ng-template>
          </ngx-select>
   
 
               <div class="text-center pt-4">
                 <a
                 class="theme-btn-lg"
                 (click)="modal.close(users)"
               >
                Save
               </a>
               </div>
           </div>
         </form>
       </div>
     </div>
   </div>
 </ng-template>

 <app-product-qr-code-generator *ngIf="isQROpen" [id]="selectProduct.id"  ></app-product-qr-code-generator>
