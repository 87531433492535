import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// import { ReactiveFormsModule, FormsModule } from '@angular/forms'
// import { CommonModule } from '@angular/common';
import { HttpClient} from '@angular/common/http'

import { EffectsModule } from '@ngrx/effects';
//import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
// import { NgxSelectModule } from 'ngx-select-ex'
import { Title } from '@angular/platform-browser';
import { QRCodeModule } from 'angularx-qrcode';
// language transaltion modules
import { TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSliderModule } from '@angular-slider/ngx-slider';



// import { StoreModule } from '@ngrx/store';
import { UserRoutingModule } from './user-routing.module';
import { MemberDetailsComponent } from './components/member-details/member-details.component';
import { MemberLoginComponent } from './components/member-login/member-login.component';
// import { Routes, RouterModule } from '@angular/router';
// import { reducers } from '../store/app.states';
import {  MemberAuthService} from './services/member-auth.service';
import {  PushNotificationsService} from './services/push.notification.service';
import { MemberAuthEffects} from './store/effects/member.auth.effects'
// import { MomentModule } from 'ngx-moment';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/app.states';
import { UserComponent } from './user.component';
import { UserHeaderComponent } from './components/header/header.component'
import { UserFooterComponent } from './components/footer/footer.component'
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { UpdateAccountComponent } from './components/account/update-account/update-account.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AccountComponent } from './components/account/account.component';
import { MyTeamsComponent } from './components/account/my-teams/my-teams.component';
import { OfficialProfileComponent } from './components/account/official-profile/official-profile.component';
// import { TagInputModule } from 'ngx-chips';
import { SharedModule} from '../shared/shared.module';
import { ParticipantListComponent } from './components/participant-list/participant-list.component'; // added this to resolve ngModule error during ng build

import {CreateNewLabelComponent} from './components/account/official-profile/create-new-label/create-new-label.component'
// import {ContactService} from './components/contacts/contact.service'
// import { AppPasswordDirective } from '../app-password.directive';
import { TeamsListComponent } from './components/teams/teams-list/teams-list.component'
import { CreateMemberComponent } from './components/members/create-member/create-member.component'
import { MembersListComponent } from './components/members/members-list/members-list.component'
import { AddTeamComponent } from './components/teams/add-team/add-team.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { ShortNamePipe } from './services/short-name.pipe';
import { AdminCreatedProfilesComponent } from './components/members/admin-created-profiles/admin-created-profiles.component';
import { MyProfileComponent } from './components/account/my-profile/my-profile.component';
// import { p } from '../user/store/reducers/profile.reducers';
// import { profileUpdate } from './store/actions/profile.actions';
import {AutosizeModule} from 'ngx-autosize';
import { ButtonDirective } from './services/button.directive';

import { IntegrationsComponent } from './components/account/integrations/integrations.component';
import { MergeAccountComponent } from './components/account/merge-account/merge-account.component';
import { PendingMembersComponent } from './components/members/pending-members/pending-members.component';
import { ViewMemberProfileComponent } from './components/members/view-member-profile/view-member-profile.component';
import { ViewAccountComponent } from './components/members/view-account/view-account.component';
import { NewHeaderComponent } from '../shared/components/new-header/new-header.component';
import { NewSidebarComponent } from '../shared/components/new-sidebar/new-sidebar.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { SyncronizationsComponent } from './components/account/syncronizations/syncronizations.component';
import { NgSelectModule,NgSelectConfig,ɵs } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgDragDropModule } from 'ng-drag-drop';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { DataTablesModule } from 'angular-datatables';
// import { ChartsModule,ThemeService } from 'ng2-charts';
import { TranscribeModalContainerComponent } from './components/transcribe-modal-container/transcribe-modal-container.component';
import { VerifyMfaComponent } from './components/account/verify-mfa/verify-mfa.component';
import { AdminDashboardComponent } from './components/members/admin-dashboard/admin-dashboard.component';
import { MyNotesComponent } from './components/my-notes/my-notes.component';
import { MemberMeetingsComponent } from './components/members/member-meetings/member-meetings.component';
import { CompanyMeetingsComponent } from './components/members/company-meetings/company-meetings.component';
import { TeamMembersComponent } from './components/teams/team-members/team-members.component';
import { ProductsComponent } from './components/products/products.component';
import { CreateProductComponent } from './components/products/create-product/create-product.component';
import { ImportProductComponent } from './components/products/import-product/import-product.component';
import { CreateTeamComponent } from './components/teams/facebook/create-team.component';
import { OpenProfileComponent } from './components/members/open-profile/open-profile.component';
import { DndDirective } from './direcitves/dnd.directive';
import {NgxPaginationModule} from 'ngx-pagination';
import { ProductsEnquiriesComponent } from './components/products/products-enquiries/products-enquiries.component';
import { CategoryComponent } from './components/products/category/category.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component'; // <-- import the module
import { NgxGalleryModule } from 'ngx-gallery';
import { ProductQrCodeGeneratorComponent } from './components/products/product-qr-code-generator/product-qr-code-generator.component';
import { OrderDetailsComponent } from './components/products/order-details/order-details.component';
import { ArchiveProductsComponent } from './components/products/archive-products/archive-products.component';
import { UpdateOrderComponent } from './components/products/update-order/update-order.component';
import { SearchCustomerComponent } from './components/products/search-customer/search-customer.component';
import { CreateCustomerComponent } from './components/products/create-customer/create-customer.component';
import { CartListComponent } from './components/products/cart-list/cart-list.component';
import { SearchProductsComponent } from './components/products/search-products/search-products.component';
import { SuppliersComponent } from './components/products/suppliers/suppliers.component';
import { OrderAnalyticsComponent } from './components/products/suppliers/order-analytics/order-analytics.component';
import { MoveOrderToSalesComponent } from './components/products/products-enquiries/move-order-to-sales/move-order-to-sales.component';

// import { AngMusicPlayerModule } from  'ang-music-player';
// import { PlyrModule } from 'ngx-plyr';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/user/', '.json');
}
@NgModule({
  declarations: [
    MembersListComponent,
    TeamsListComponent,
    CreateMemberComponent,
    MemberDetailsComponent,
    MemberLoginComponent,
    UserComponent,
    UserHeaderComponent,
    UserFooterComponent,
    SubMenuComponent,
    UpdateAccountComponent,
    SettingsComponent,
    ResetPasswordComponent,
    AccountComponent,
    MyTeamsComponent,
    OfficialProfileComponent,
    ParticipantListComponent,
    CreateNewLabelComponent,
    AddTeamComponent,
    ForgotPasswordComponent,
    VerifyOtpComponent,
    ShortNamePipe,
    AdminCreatedProfilesComponent,
    MyProfileComponent,
    ButtonDirective,
    IntegrationsComponent,
    MergeAccountComponent,
    PendingMembersComponent,
    ViewMemberProfileComponent,
    ViewAccountComponent,
    NewHeaderComponent,
    NewSidebarComponent,
    SyncronizationsComponent,
    AnalyticsComponent,
    TranscribeModalContainerComponent,
    VerifyMfaComponent,
    AdminDashboardComponent,
    MyNotesComponent,
    MemberMeetingsComponent,
    CompanyMeetingsComponent,
    TeamMembersComponent,
    CreateTeamComponent,
    ProductsComponent,
    CreateProductComponent,
    ImportProductComponent,
    OpenProfileComponent,
    DndDirective,
    ProductsEnquiriesComponent,
    CategoryComponent,
    ProductDetailsComponent,
    ProductQrCodeGeneratorComponent,
    OrderDetailsComponent,
    ArchiveProductsComponent,
    UpdateOrderComponent,
    SearchCustomerComponent,
    CreateCustomerComponent,
    CartListComponent,
    SearchProductsComponent,
    SuppliersComponent,
    OrderAnalyticsComponent,
    MoveOrderToSalesComponent
    //AppPasswordDirective
  ],
  imports: [
    
    SharedModule,
    

    DragDropModule,
    // CommonModule,
    UserRoutingModule,
    InfiniteScrollModule,
    // FormsModule,
    // RouterModule,
    // HttpClientModule,
    // ReactiveFormsModule,
    // MomentModule,
    // StoreModule.forFeature('member', reducers)
    StoreModule.forFeature('memberAuth', reducers),
    StoreModule.forFeature('profilePic', reducers),
    EffectsModule.forFeature([MemberAuthEffects]),
    // StoreModule.forRoot({ profilePic: profileUpdate }),

    //MalihuScrollbarModule.forRoot(),
    NgbModule,
    NgSelectModule,
    // NgxIntlTelInputModule,
    // NgxSelectModule,
    QRCodeModule,
    // TagInputModule,
    PerfectScrollbarModule,
    AutosizeModule,
    TranslateModule,
    DataTablesModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgDragDropModule.forRoot(),
    // ChartsModule,
    NgxPaginationModule,
    NgxGalleryModule,
    NgxSliderModule
    // PlyrModule
    // NgxAudioPlayerModule
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    // TranslateModule.forChild({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   },
    //   isolate:true
    // }),

    // ToastrModule.forRoot() // ToastrModule added
    // AngMusicPlayerModule,
  ],
  
  providers: [MemberAuthService,PushNotificationsService,Title,
    
    //ThemeService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      
      
    },
    NgSelectConfig,
    ɵs
   // ScreenTrackingService
  ],
  entryComponents: [CreateNewLabelComponent, TranscribeModalContainerComponent,MoveOrderToSalesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class UserModule { }
