import gql from 'graphql-tag'
// import {Team} from './team.modal'


export const membersBasicQuery = gql`
query users($userIds:[ID!],$limit:Int,$offset:Int, $sortByName: SortOrder,$sortByDate:SortOrder,$searchByName:String ) {
  users(userIds:$userIds,limit:$limit,offset:$offset,sortByName:$sortByName,sortByDate:$sortByDate,searchByName:$searchByName ){
    userId
    firstName
    lastName
    email
    phone
    firstLoginPending
    joinPending
    countryCode
    b2BPhone
    contactPresent
    role
    twoFactor
    isOwner
    teams {
      teamId
      name
      memberIds
      managers
     
    }
    profiles {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId

    }
    publicProfiles {
      profileId
      username
      description
    }
  }
}
`;

export const membersBasicQuery2 = gql`
query users($userIds:[ID!],$limit:Int,$offset:Int, $sortByName: SortOrder,$sortByDate:SortOrder,$searchByName:String ) {
  users(userIds:$userIds,limit:$limit,offset:$offset,sortByName:$sortByName,sortByDate:$sortByDate,searchByName:$searchByName ){
    userId
    firstName
    lastName
    email
    phone
    firstLoginPending
    joinPending
    countryCode
    b2BPhone
    contactPresent
    role
    twoFactor
    isOwner
    meetingCount
    lastMeetingLocation
    lastMeetingTitle
    lastMeetingStartTime
    lastMeetingEndTime
    lastEventParticipants {
      firstName
      lastName
      userId
      nonAppId
    }
    teams {
      teamId
      name
      memberIds
      managers
     
    }
    profiles {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId

    }
  }
}
`;

export const membersFilterBasicQuery = gql`
query users($userIds:[ID!],$limit:Int,$offset:Int, $sortByName: SortOrder,$sortByDate:SortOrder,$searchByName:String ) {
  users(userIds:$userIds,limit:$limit,offset:$offset,sortByName:$sortByName,sortByDate:$sortByDate,searchByName:$searchByName ){
    userId
    firstName
    lastName
    email
    phone
    firstLoginPending
    countryCode
    contactPresent
    twoFactor
    isOwner
    teams {
      teamId
      name
      memberIds
     
    }
    profiles {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId
    }
  }
}
`;
export const getmembersByIdQuery = gql`

  query users($userIds:[ID!] ) {
  users(userIds:$userIds){
    userId
    firstName
    lastName
    email
    phone
    firstLoginPending
    countryCode
    b2BPhone
    contactPresent
    role
    twoFactor
    teams {
      teamId
      name
      memberIds
      managers
     
    }
    profiles {
      displayImageURL
      displayImagePutURL
      profileId
      title
      type
      data{
        key
        value
        format
        title
        admin
      }
    }
  }
}
`;

export const membersQuery = gql`
{
  users{
    userId
    firstName
    lastName
    phone
    email
    countryCode
    contactPresent
    role
    twoFactor
    teams {
      teamId
      name
    }
    profiles {
      displayImageURL
      profileId
      title
      data{
        key
        value
        format
        title
        admin
      }
    }
  }
}
`;
export const addTeamMutation = gql`
mutation XTFPGBIOGK(
        $name: String!,
         $memberIds:[ID!],
         $hidden:Boolean
){
    XTFPGBIOGK(team:{name:$name, memberIds: $memberIds,hidden:$hidden}) {
    teamId
    name
    memberIds
    updatedAt
    managers
  }
}`

export const teamsQuery = gql`
{
    teams{
      teamId
      name
      memberIds
      updatedAt
      managers
    }
}
`;

export const deleteTeamMutation = gql`mutation HVVJQFSOQA($teamIds:[ID!]!){
  HVVJQFSOQA(teamIds: $teamIds)
}`

export const updateTeamMutation = gql`
mutation AFFOUFBOXK(
        $name: String!,
         $teamId:ID!
){
    AFFOUFBOXK(team:{name:$name, teamId: $teamId}) {
      teamId
    name
    memberIds
    updatedAt
    managers
  }
}`
export const teamsRemoveMemberMutation = gql`
mutation SSYEYCOLZJ(
        $memberIds: [ID!]!,
         $teamId:ID!
){
    SSYEYCOLZJ(team:{teamId:$teamId, memberIds: $memberIds}) {
    teamId
    name
    memberIds
    updatedAt
    managers

  }
}`
export const teamsAddMemberMutation = gql`
mutation MPYVMDLKYI(
        $memberIds: [ID!]!,
         $teamId:ID!
){
    MPYVMDLKYI(team:{teamId:$teamId, memberIds: $memberIds}) {
      teamId
    name
    memberIds
    updatedAt
  }
}`



export const addMemberMutation = gql`
mutation ERSNMYWSHP( $firstName: String!,$lastName: String!, $email: String!, $phone: String!,$countryCode: String,$b2bPhone:String,$role:Role){
    ERSNMYWSHP(users: {firstName: $firstName, lastName: $lastName, email: $email, phone: $phone,countryCode: $countryCode,b2bPhone:$b2bPhone,role:$role}) {
    userId
    firstName
    lastName
    phone
    email
    countryCode
    b2BPhone
    teams {
      teamId
      name
      memberIds
     
    }
    profiles {
      displayImageURL
      profileId
      title
      data{
        key
        value
        format
        title
        admin
      }
    }
  }
}
`;
export const updateMemberMutation = gql`
mutation QSGHAXEPKC( $firstName: String!,$lastName: String!, $email: String!, $phone: String,$userId:ID!,$countryCode: String, $b2bPhone:String,$role:Role){
  QSGHAXEPKC(user: {firstName: $firstName, lastName: $lastName, email: $email, phone: $phone,userId:$userId,countryCode: $countryCode, b2bPhone:$b2bPhone,role:$role}) {
    userId
    firstName
    lastName
    phone
    email
    countryCode
    b2BPhone
    teams {
      teamId
      name
      memberIds
     
    }
    profiles {
      displayImageURL
      profileId
      title
      data{
        key
        value
        format
        title
        admin
      }
    } 
  }
}
`;


export const deleteMemberMutution = gql`
mutation SIOVXASRBD( $userIds:[ID!]!){
    SIOVXASRBD(userIds:  $userIds)
}
`
export const useInviteMutution = gql`
mutation usersInvite( $userIds:[ID!]!){
  usersInvite(userIds:  $userIds){
    userId
    status
  }
}
`
export const settingsInfo = gql`
query accountInformation{

  accountInformation{
    id
    settings{
      key
      value
    }
   
  }
}`

export const createProfileMemberMutution = gql`
mutation QJTHNWOMEA( 
    $userId:ID!
    $data: [UserDataInput!]!,
    $title:String
  )
  {
    QJTHNWOMEA( profiles:{
     userId:$userId
      colorCode: "#7273DB,#56AAFE",
      data: $data,
      title:$title
    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId
    
  }
}
`
export const updateProfileMemberMutution = gql`
mutation LQQHPRFSLK( 
    $userId:ID!
    $data: [UserDataInput],
    $profileId:ID!,
    $title:String
  )
  {
    LQQHPRFSLK( profile:{
     userId:$userId,
     profileId:$profileId,
      data: $data,
      title:$title
    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId
    
  }
}`

export const updatePasswordMutation = gql `  
mutation MEELVPCWUZ($oldPassword:String!, $newPassword: String!){
  MEELVPCWUZ(oldPassword: $oldPassword, newPassword: $newPassword ){
    status
    description
  }
}`

export const allMembersQuery = gql`
query users($userIds:[ID!],$limit:Int,$offset:Int, $sortByName: SortOrder,$sortByDate:SortOrder,$searchByName:String ) {
  users(userIds:$userIds,limit:$limit,offset:$offset,sortByName:$sortByName,sortByDate:$sortByDate,searchByName:$searchByName ){
    userId
  }
}
`;

export const mergeUserMutation = gql `  
mutation OULVNHDAWI($user:MergeUserInput!, $code: String!){
  OULVNHDAWI(user: $user, code: $code)
}`


export const checkPermissionQuery = gql`
query users($feature:String ) {
  checkPermission(feature: "objectID") {
    granted
  }
}
`;

export const getMembersBasicQuery = gql`

  query users($userIds:[ID!] ) {
  users(userIds:$userIds){
    userId
    firstName
    lastName
    email
    phone
  }
}
`;


export const createCustomProfileMemberMutution = gql`
mutation YFvPnwQbVm( 
  $type :ProfileType!
  $title: String
    $data: [UserDataInput!]!,
    $accessLevel: ProfileAccessLevel
  )
  {
    YFvPnwQbVm( profile:{
      type:$type
      title : $title
     userId:$userId
      colorCode: "#7273DB,#56AAFE",
      data: $data,
      accessLevel:$accessLevel
    })
      {
        id
        type
        title
        displayImageURL
        displayImagePutURL
        data{
          key
          title
          value
          format
          admin
        }
        colorCode
        referralLink
        profileId
    
  }
}
`

export const setPublicProfileMemberMutution = gql`
mutation gOGvJiJdYl( 
  $profileId: ID!
  $username: String!
  $status: String
  $description: String
  )
  {
    gOGvJiJdYl( publicProfile:{
      profileId:$profileId
      username : $username
      status:$status
      description: $description,
    })
      {
        userId
        status
        description
        colorCode
        referralLink
        profileId
    
  }
}
`
export const myTeamInfo = gql`
query accountInformation{

  accountInformation{
    id
  
 
    teams{
      teamId
      name
      memberIds
      managers
    }
    
  }
}`



export const memberSearchForTeamsQuery = gql`
query users($searchByName :String ) {
  users(searchByName:$searchByName ){
    userId
    firstName
    lastName
     teams{
      teamId
      name
      memberIds
      managers
     }
  }
}
`;