import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
// var moment = require('moment-timezone')
import { HelperService } from '../../services/helper.service'

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
	public currentPath:any
  public activeCls:any
  specificUser:boolean = false
  permissions:any = ""
  public isAdmin: boolean = false

	constructor (    private router: Router, private helperService: HelperService) {
    let loginUser = localStorage.getItem("email")
    if(loginUser == 'emilot@staytouch.com' ||  loginUser == 'ramya@krify.net')
    {
      this.specificUser = true
    }
    // console.log(moment.tz.guess(),'moment.tz.guess()3',moment.tz.guess(),moment.tz.guess(true))
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

    let pathLink = this.router.url
    this.activeCls = false
    if(pathLink == '/my-profile' || pathLink == '/my-teams' || pathLink == '/reset-password' )
    {
		this.currentPath = '/update-account'
		
    }
    if(pathLink == '/update-profile')
    {
		this.currentPath = '/my-profile'
		this.activeCls = true
    }
    if(pathLink == '/update-account')
    {
		this.currentPath = '/members'
    }
    if(pathLink.includes('/zoom-connect'))
    {
      if(localStorage.getItem("meetingInfo_zoom"))
      {
        this.currentPath = '/create-meeting'
      }
      else{
        this.currentPath = '/update-account'
      }
    }
    if(pathLink == '/merge-connect')
    {
		this.currentPath = '/members'
    }
    if(pathLink == '/syncronizations')
    {
		this.currentPath = '/update-account'
    }
  }

  ngOnInit () {
    // setTimeout(()=>{
    //   this.permissions = JSON.parse(localStorage.getItem('permissions'))
    // },10)
    this.isAdmin = this.helperService.isAdmin()
  }


}
