import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Apollo, QueryRef } from 'apollo-angular'
// import { Router} from '@angular/router'
// import {memberDetails,updateAccount} from '../account.gql'
import { ToastrService } from 'ngx-toastr'
import { accountInfo, updateMemberMutation } from '../../../services/user.gql'
// import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common'
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router'
// import {pairwise} from 'rxjs/add/operator/pairwise';
import { AfterViewInit, Component, ElementRef, OnInit,OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html',
  styleUrls: ['./update-account.component.scss']
})
export class UpdateAccountComponent implements OnInit,OnDestroy {

  accountForm: FormGroup
  submitted: boolean = false
  private id: number
  private query: QueryRef<any>
  private query$:any
  public account: any
  previousUrl: string
  private currentUrl: string
  private location: Location
  separateDialCode: boolean = true
  loading:boolean =  false
  enableAutoCountrySelect:boolean = true
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  constructor (
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    public router: Router,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    private translate: TranslateService,
    config: NgbModalConfig, private modalService: NgbModal,
    private helperService: HelperService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.currentUrl = this.router.url
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        //console.log(this.router.url)

        this.previousUrl = this.router.url
        // alert(this.previousUrl)
        this.currentUrl = event.url
      }
    })
    
    this.isAdmin = this.helperService.isAdmin()
    // this.permissionsRes =  this.helperService.getAccountInforamtion()
    // this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
    
      // console.log(this.permissions)
    // })
  }

  ngOnInit () {
    this.accountForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', Validators.required]
    })
    this.getAccountDetails()
  }

  get f () {
    return this.accountForm.controls
  }
  onSubmit () {
    this.submitted = true
    this.updateAccount()
  }

  getAccountDetails () {
    this.loading = true
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      fetchPolicy:"no-cache"
    })
    this.query$ = this.query.valueChanges.subscribe(result => {
      this.account = result.data.accountInformation
      this.permissions = this.helperService.getPermissions(result.data)
      this.setValues(this.account)
      this.loading = false
    },error => {
      this.loading = false
    })
  }
  updateAccount () {
    const controls = this.accountForm.controls
    for (const name in controls) {
      if (controls[name].invalid) {
        //console.log(name)
        if (name != 'phonenumber') {
          return
        }
      }
    }
    let phone = this.f.phonenumber.value
    //console.log(phone)
    phone.number = phone.internationalNumber.split('-').join('')
    phone.number = phone.number.split(' ').join('')

    phone = phone.number
    this.loading =true
    this.apollo
      .mutate({
        mutation: updateMemberMutation,
      
        variables: {
          firstName: this.f.firstName.value,
          lastName: this.f.lastName.value,
          phone: phone,
          email: this.f.email.value,
          userId: this.account.id
        },
        update: (proxy, { data: { QSGHAXEPKC } }: any) => {
          // Read the data from our cache for this query.
          let data: any = proxy.readQuery({ query: accountInfo })
          let accountInformation = data.accountInformation
          accountInformation = { ...accountInformation, ...QSGHAXEPKC }
          data.accountInformation = accountInformation
          proxy.writeQuery({ query: accountInfo, data })
        }
      })
      .subscribe(
        ({ data }: any) => {
          this.loading = false
          // console.log(result)
          this.translate
            .get('MESSAGES.ACCOUNT_UPDATED_SUCCESSFULLY')
            .subscribe((res: string) => {
              this.toastr.info(res, null, {
                closeButton: true,
                disableTimeOut: false
              })
            })
        },
        error => {
          this.loading = false

          this.translate
            .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
            .subscribe((res: string) => {
              this.toastr.info(res, null, {
                closeButton: true,
                disableTimeOut: false
              })
            })
          console.log('there was an error sending the mutation', error)
        }
      )
  }

  setValues (account) {
    this.accountForm.patchValue({
      firstName: account.firstName,
      lastName: account.lastName,
      phonenumber: account.phone,
      email: account.email
    })

    setTimeout(()=>{
		this.enableAutoCountrySelect = false
    },2000)
  }

  checkNumber () {
    //console.log($, 'dfd12')
    // $('html').css('overflow','hidden')
    //this.elementRef.nativeElement.querySelector('#country-search-box')
    // .addEventListener('change', this.checkNumber1.bind(this));
  }
  checkNumber1 (event) {
    event.preventDefault()
    //console.log($, 'dfd')
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)
  }
  goBack () {
    //console.log(this.previousUrl)
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl)
    }

    // alert(this.previousUrl)
    // return this.previousUrl;
  }

  ngAfterViewInit () {
    // this.elementRef.nativeElement
    //   .querySelector('.flag-container')
    //   .addEventListener('click', this.checkNumber.bind(this))
  }
    ngOnDestroy(){
	this.query$ ? this.query$.unsubscribe() : "";
  }
  open(content) {
    this.modalService.open(content);
  }
}
