import { Component, OnInit, ViewChild, ElementRef, Inject,OnDestroy } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'
import {
  customersQuery,productsQuery,updateCartMutution,cartListMutution
} from '../../../services/products.gql'
import { Router, ActivatedRoute, Params } from '@angular/router'
import jsQR, { QRCode } from 'jsqr';
import {Html5QrcodeScanner} from "html5-qrcode"
import {Html5Qrcode} from "html5-qrcode"
import { exit } from 'process';
import { timeStamp } from 'console';
import QrScanner from 'qr-scanner'; // if installed via package and bundling with a module bundler like webpack or rollup

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent implements OnInit,OnDestroy {
  @ViewChild('video', {static: true}) videoElm: ElementRef;
  @ViewChild('canvas', {static: true}) canvasElm: ElementRef;
  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: {
      facingMode: 'environment'
      }
  };
  html5QrCode:any
  private query: QueryRef<any>
  private query2: QueryRef<any>
  private query$: any
  private query2$
  phone:string
  customerInfo:any
  loading:boolean = true
  productId:any
  productInfo:any
  products:any =[]
  cartObj:any 
  isFitler:boolean = false
  qrCodeScan:boolean = false
  searchProducts:boolean = false
  qrScanner:any
  searchKey:any
  constructor(private apollo: Apollo,private activatedRoute: ActivatedRoute,private router:Router) { 
    // This method will trigger user permissions

    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {columns,filterColumn,filterColumnValue,minValue,maxValue,searchText,phone} = this.router.getCurrentNavigation().extras.state
      if(phone){
        this.phone = phone
        this.filterCustomer()
      }
      
    }
      

    let localInfo :any = localStorage.getItem("cartObj")


    if(localInfo){
      localInfo  = JSON.parse(localInfo)
      this.cartObj  = localInfo
      this.customerInfo = localInfo[0].customer
      this.phone = localInfo[0].customer.phone 
    }   
    


    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.searchKey  = params.id
        let productInfo:any = this.genearteKey(params.id)
        productInfo = productInfo.split("-")
        this.productId = productInfo[1]
      }
      // window.history.pushState({}, document.title, "/search" );
    })

  
   
  }

  ngOnInit() {
    this.loading = true
    this.getCartDetails()
   

  }
   setResult( result) {
   if(result.data){
		window.location.href=result.data
       this.qrCodeScan = false
       this.stopScan()
		console.log(result.data);
    }

}

  filterCustomer(){ 
    if(!this.phone){
      return false
    }
    this.loading =true
    if(this.cartObj && this.cartObj.customer && this.cartObj.customer.phone ==this.phone){
      this.loading =false
      return false
    }
    this.customerInfo = undefined
    // let letter = this.phone.(0); //H
    let appendText ="" ;
    // if(letter == "0"){
    //   appendText="0"
    // }
    let phone = this.phone
    phone = phone ? phone.trim():''
    if(phone.length != 10){
      alert("Phone number should be 10 digits.")
      this.loading =false
      return;
    }
    phone = phone ? phone.toString():''
   
    this.query = this.apollo.watchQuery({
      query:   customersQuery  ,
      fetchPolicy:"network-only",
      variables:{
        phone: phone
      }
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      if(this.cartObj && this.cartObj.customer && this.cartObj.customer.phone != this.phone){
        this.products =[]
        
      }
      this.cartObj = {}
      this.products =[]
      this.clearCart()
      if(data.customers.length > 0){
        this.customerInfo = data.customers[0]
        if(this.productId){
          this.productDetails()
        }
      }
      this.isFitler = true
      this.loading = false
    
    },err=>{
      console.log(err)
      this.loading = false
    })
  
  }
  genearteKey(id){
    return window.atob(id)
  }

  public productDetails()
  {
      this.loading = true
      this.query2 = this.apollo.watchQuery({
        query: productsQuery,
        fetchPolicy:'network-only',
        variables:{
          id:this.productId
        }
      })
      this.query2$ = this.query2.valueChanges.subscribe(({ data }: any) => {
         this.loading = false
         this.productId =null
         this.products.push(data.products[0])
        //  console.log(this.products)
         this.updateCart()
         
      },err=>{
        this.loading = false
      })
    
  } 
  addToCart(product) {

    let filterItem = this.cartObj.findIndex((item)=>item.customer.phone == this.customerInfo.phone)   
    if(filterItem == -1) {
      this.cartObj.push({
        customer:{
          firstName:this.customerInfo.firstName,
          lastName:this.customerInfo.lastName,
          phone:this.customerInfo.phone,
          emile:this.customerInfo.emile,
          customerId:this.customerInfo.id,
          city:this.customerInfo.city? this.customerInfo.city : ""
        },
        products:[product]
      })
    }
    else{
      let filterCustomer:any = this.cartObj[filterItem]
      filterCustomer.products.push(product)
      this.cartObj[filterItem]= filterCustomer
    }
    localStorage.setItem("cartObj",JSON.stringify(this.cartObj))
    // this.router.navigate(['/cart-list'])
  }

  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo()
    }
    // this.videoStart ? this.stopVideo() : this.startVideo()
  }
  startVideo() {
    this.medias.video = true;
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }
  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width  = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT)
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT)
    const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

    if (code) {
      if(code && code.data){
        window.location.href = code.data
        this.qrCodeScan = false
        this.stopScan()
      }
        // this.openDialog(code);
    } else {
        setTimeout(() => { this.checkImage(); }, 100)
    }
  }
  searChProducts(type){
    if(type==1){
      this.router.navigate(['/search-products'])
    }
    else{
      // this.qrCodeScanner()
      setTimeout(()=>{
        const videoElem:any = document.getElementById('qr-video');

    this.qrScanner = new QrScanner(videoElem, result => this.setResult( result), {
      onDecodeError: error => {
          console.log(error,'error')
      },
      highlightScanRegion: true,
       highlightCodeOutline: true,
  });
      this.qrScanner.start();

    
      },100)
     
    
    }
    this.updateCart()
  }
  
  qrCodeScanner(){
	this.loading = true;
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };
    Html5Qrcode.getCameras().then(devices => {
    /**
    * devices would be an array of objects of type:
    * { id: "id", label: "label" }
    */
    if (devices && devices.length) {
    this.html5QrCode = new Html5Qrcode("reader");

    var cameraId = devices[0].id;
    // .. use this to start scanning.
    //  html5QrCode.start({ facingMode: { exact: "environment"} }, config, this.qrCodeSuccessCallback);
    if(this.html5QrCode){
      let config= { facingMode: { exact: "user"} }
    if(window.innerWidth < 760){
      config = { facingMode: { exact: "environment"} }
    }

    this.html5QrCode.start(
     config,
    {
      fps: 10,    // Optional, frame per seconds for qr code scanning
      qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
    },
    (decodedText, decodedResult) => {
            this.loading = false;

    // do something when code is read
      if(decodedText){
       window.location.href=decodedText
       this.qrCodeScan = false
       this.stopScan()
      }
    },
    (errorMessage) => {
    this.loading = false;
    // parse error, ignore it.
     console.log(errorMessage,'errorMessage')
    })
    .catch((err) => {
    this.loading = false;
     console.log(err,'err')
    // Start failed, handle it.
    });
    }
this.loading = false;

    }
    }).catch(err => {
    console.log(err)
    this.loading = false;
    // handle err
    });
  }
  updateCart(){
    
    if(!this.cartObj.phone) {
      this.products = this.products.map((item)=>{
        if(item.productCategory && item.productCategory.name){
        item.productCategory.name = item.productCategory.name.replace(/'/g, '###')
       }
        return item
      })
      this.products = Array.from(new Map(this.products.map(e=>[e.id, e])).values());
      this.cartObj = {
        customer:{
          firstName:this.customerInfo.firstName,
          lastName:this.customerInfo.lastName,
          phone:this.customerInfo.phone,
          email:this.customerInfo.email,
          customerId:this.customerInfo.id,
          city:this.customerInfo.city? this.customerInfo.city : ""
        },
        products:this.products
      }
      
    // let cartObj2 = JSON.stringify(this.cartObj).replace(/"/g, "'");
    let cartObj2 = encodeURIComponent(JSON.stringify(this.cartObj))

    this.apollo
    .mutate({
      mutation: updateCartMutution,
      variables: {
        cart:`${cartObj2}`
      },
      errorPolicy:"all"
    })
    .subscribe(
      ({ data, loading, errors }: any) => {
        this.loading = false
        document.getElementById('cartCount').innerHTML = this.products.length
        
      },
      error => {
        console.log(error)

        this.loading= false
       
      }
    )
    }
  

    
}
getCartDetails() {
  this.loading  = true
 
  this.query = this.apollo.watchQuery({
    query:   cartListMutution  ,
    fetchPolicy:"no-cache",
  })

   this.query.valueChanges.subscribe(({ data }: any) => {
      // this.products = data.products
      this.loading = false
      let cartInfo = data.accountInformation.cart
      if(cartInfo && cartInfo != ''){
        // cartInfo = cartInfo.replace(/'/g, '"')
        cartInfo = decodeURIComponent(cartInfo)

        cartInfo = cartInfo.replace(/###/g, "'")

        cartInfo = JSON.parse(cartInfo)
        
        this.cartObj  = cartInfo
        if(cartInfo.customer){
          this.customerInfo = cartInfo.customer
          this.phone = cartInfo.customer.phone ? cartInfo.customer.phone : ""
          if(cartInfo.products){
            this.products = cartInfo.products
          }
          if(this.productId){
            this.productDetails()
          }
          
          
        }

      }
      this.loading = false
  },err=>{
    this.loading = false
  })
}
removeFromCart(j){
  if(confirm("Are you sure do you want to delete this product from cart?")){
    this.products.splice(j,1)
    this.updateCart()
  }
}
stopScan(){
if(this.qrScanner){
this.qrScanner.stop();
}
    if(this.html5QrCode){
      this.html5QrCode.stop().then((ignore) => {
      this.qrCodeScan = false
        this.html5QrCode.clear().catch(error=>{
          this.qrCodeScan = false
})
        }).catch((err)=>{
          console.log(err)
          this.qrCodeScan = false
        })
        }
        else{
          this.qrCodeScan = false
        }
        
		
}
clearCart(){
  this.apollo
  .mutate({
    mutation: updateCartMutution,
    variables: {
      cart:""
    },
    errorPolicy:"all"
  })
  .subscribe(
    ({ data, loading, errors }: any) => {
      this.loading = false
      document.getElementById('cartCount').innerHTML = "0"
      
    },
    error => {
      console.log(error)

      this.loading= false
     
    }
  )
}
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}



createCustomer(){
  this.router.navigate(['/create-customer'], {
    state: {
      phone:this.phone,
      searchKey:this.searchKey
    }
  })
 
}
ngOnDestroy(){
  if(this.query2$){
    this.query2$.unsubscribe()
  }
if(this.qrScanner){
this.qrScanner.destroy();
}
  if(this.html5QrCode){
    this.html5QrCode.stop().then((ignore) => {
      this.html5QrCode.clear().catch(error=>{
        console.log(error)
      })
      }).catch((err)=>{
        console.log(err)
      })
      }
  }
}
