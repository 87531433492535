import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from "@angular/router"
import { Apollo, QueryRef } from 'apollo-angular'
import {profilesQuery} from '../../../user/services/user.gql'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'


import { MemberUser } from '../../../user/models/member-user'

import { AppState, selectMemberAuth,profileState } from '../../../user/store/app.states'
import { MemberLogIn,MemberLogOut,MemberStatus } from '../../../user/store/actions/member.auth.actions'
const languages = [
  {
    id:'en',
    title:'EN',
    image:'assets/i18n/us.svg'
  },
  {
    id:'fr',
    title:'FR',
    image:'assets/i18n/fr.svg'
  }]
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  languagesList= languages
  loginForm = null
  getState: Observable<any>
  getProfie:Observable<any>
  isAuthenticated: boolean = null
  errorMessage: string | null
  user: MemberUser = null
  selectedLang = 'en'
  imageUrl:any = ""
  random:any =0
  private queryPr: QueryRef<any>

  getLoginDetails() {
  	this.user = new MemberUser({id: 1, email: this.loginEmail.value, password: this.loginPassword.value})
  	const payload = {
      email: this.user.email,
      password: this.user.password
    };
    this.store.dispatch(new MemberLogIn(payload))
  }

  constructor (private apollo: Apollo,private store: Store<AppState>, public translate: TranslateService) {
    this.selectedLang = localStorage.getItem('lang') ? localStorage.getItem('lang'): 'en'
  	this.getState = this.store.select(selectMemberAuth)
  	this.getProfie = this.store.select(profileState)
    this.store.dispatch(new MemberStatus)
  }

  changeLanguage(lang) {
    localStorage.setItem('lang',lang);
    this.translate.use(lang)
  }

  ngOnInit () {
    this.random = Math.random();
  // console.log(3432)
    /** Profile Info **/
  	this.loginForm = new FormGroup({
	  	loginEmail: new FormControl('', [Validators.required, Validators.email]),
	  	loginPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
	  	isRememberMe: new FormControl(false)
    })
    // this.getProfie.subscribe((state) => {
    //   // console.log(state,'state profile')
    // })

    this.getState.subscribe((state) => {
      // console.log(state,'state profile2')

     
      // console.log('coming')
      this.isAuthenticated = state.memberAuth.isAuthenticated;
      this.user = state.memberAuth.user;
      this.errorMessage = state.memberAuth.errorMessage;
      this.getProfileInfo()
      setTimeout(()=>{
        if(state.profilePic.profilePic)
        {
          // console.log(state.profilePic.profilePic,'state profile3')
          this.imageUrl=state.profilePic.profilePic
        }
      },1000)
      

    });
  }
  getProfileInfo(){
    if(localStorage.getItem('token'))
    {
      this.queryPr = this.apollo.watchQuery({
        query: profilesQuery,
         fetchPolicy:"network-only"
      });
      this.queryPr.valueChanges.subscribe(({data}) => {
        // console.log('home')
        if(data.profiles[0])
        {
        this.imageUrl = data.profiles[0].displayImageURL
        localStorage.setItem('forgotEmail', this.loginEmail.value)
        }
      })
    }
  
  }

  logout() {
    alert(1)
    window.localStorage.clear();   
    window.location.href = '/login'
    this.store.dispatch(new MemberLogOut);
  }

  get loginEmail() { return this.loginForm.get('loginEmail') }
  get loginPassword() { return this.loginForm.get('loginPassword') }

}
