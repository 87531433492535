import gql from 'graphql-tag'
// import {Team} from './team.modal'


export const productsQuery = gql`
query products($sortByColumn:String,$sortByPosition:SortOrder,$id:ID,$limit:Int,$offset:Int,$search:String,$userId:ID ,$archived:Boolean,$filterByColumn:String,$filterColumnValue:String,$displayPriceStart:Int,$displayPriceEnd:Int,$stockQty:Int) 
{
  products(sortByColumn:$sortByColumn,sortByPosition:$sortByPosition,id:$id,limit:$limit,offset:$offset,search:$search,userId:$userId,archived:$archived,
    filterByColumn:$filterByColumn,filterColumnValue:$filterColumnValue,displayPriceStart:$displayPriceStart,displayPriceEnd:$displayPriceEnd,stockQty:$stockQty)
    {
    id
    productCategory{
      id
      name
    }
    productName
    designNumber
    itemNumber
    photos{
      id
      signedGetURL
  		signedPutURL
    }
    cost
    users{
      id
      firstName
      lastName
    }
    costUsd
    costMad
    costAed
    margin
    displayedSellingPrice
    discount
    sellingPrice
    sellingPriceMargin
    description
    company
    otherFieldX
    otherFieldY
    other
    status
    stockQuantity
    dateImport
    supplierName
    supplierInvoiceNumber
    tajNumber
    maxAllowed
    minSellingPrice
    goldColor
    goldWeight
    diamondWeight
    noDiamondStones
    coloredStonesTypeOne
    coloredStonesWeightOne
    coloredStonesTypeTwo
    coloredStonesWeightTwo
    size
    comment
    productLocation
    usdAedFx
    aedMadFx
    supplierInvoiceDate
    coloredStonesWeight3
    coloredStonesType3
    diamondPriceUsd
    coloredStonesPriceUsd
    goldPriceUsd
    makingChargesUsd
    goldInvoiceNumber
    totalProductsCount
  }
}
`;

export const productsAllQuery = gql`
query products($sortByColumn:String,$sortByPosition:SortOrder,$userId:ID,$search:String,$archived:Boolean ) {
  products(sortByColumn:$sortByColumn,sortByPosition:$sortByPosition,userId:$userId,search:$search,archived:$archived){
    id
  }
}
`;


export const createProductMutution = gql`
mutation createProduct( 
    $productCategory:ID,
    $productName :String,
    $designNumber :String,
    $itemNumber :String,
    $photo: String,
    $cost: Float,
    $description: String,
    $company :String,
    $otherFieldX :String,
    $otherFieldY: String,
    $other :String,
    $status :Boolean,
    $stockQuantity :Int,
    $dateImport:DateTime,
    $users :[ID],
    $costUsd :Float,
    $costMad :Float,
    $costAed :Float,
    $margin :Float,
    $displayedSellingPrice :Float,
    $discount :Float,
    $sellingPrice :Float,
    $sellingPriceMargin :Float,
    $currency:String,

    $supplierName:String
    $supplierInvoiceNumber:String
    $tajNumber:String
    $maxAllowed:Float
    $minSellingPrice:Float
    $goldColor:String
    $goldWeight:Float
    $diamondWeight:Float
    $noDiamondStones:Int
    $coloredStonesTypeOne:String
    $coloredStonesWeightOne:String
    $coloredStonesTypeTwo:String
    $coloredStonesWeightTwo:String
    $size:String
    $comment:String
    $productLocation:String
    
    $usdAedFx:Float
    $aedMadFx:Float
    $supplierInvoiceDate:DateTime
    $coloredStonesType3:String
    $coloredStonesWeight3:String
    $diamondPriceUsd:Float
    $coloredStonesPriceUsd:Float
    $goldPriceUsd:Float
    $makingChargesUsd:Float
    $goldInvoiceNumber:String
    
  )
  {
    createProduct( products:{
        productCategory:$productCategory,
        productName :$productName,
        designNumber :$designNumber,
        itemNumber :$itemNumber,
        photo:$ photo,
        cost:$ cost,
        description:$ description,
        company :$company,
        otherFieldX :$otherFieldX,
        otherFieldY:$ otherFieldY,
        other :$other,
        status :$status,
        stockQuantity :$stockQuantity,
        dateImport:$dateImport,
        users : $users,
        costUsd : $costUsd,
        costMad : $costMad,
        costAed : $costAed,
        margin : $margin,
        displayedSellingPrice : $displayedSellingPrice,
        discount : $discount,
        sellingPrice : $sellingPrice,
        sellingPriceMargin : $sellingPriceMargin,
        currency:$currency
        supplierName : $supplierName
        supplierInvoiceNumber : $supplierInvoiceNumber
        tajNumber: $tajNumber
        maxAllowed: $maxAllowed
        minSellingPrice: $minSellingPrice
        goldColor : $goldColor
        goldWeight : $goldWeight
        diamondWeight : $diamondWeight
        noDiamondStones  : $noDiamondStones
        coloredStonesTypeOne : $coloredStonesTypeOne
        coloredStonesWeightOne : $coloredStonesWeightOne
        coloredStonesTypeTwo  : $coloredStonesTypeTwo
        coloredStonesWeightTwo : $coloredStonesWeightTwo
        size : $size
        comment  : $comment
        productLocation : $productLocation
        usdAedFx:$usdAedFx
    aedMadFx:$aedMadFx
    supplierInvoiceDate:$supplierInvoiceDate
      coloredStonesType3:$coloredStonesType3
      coloredStonesWeight3:$coloredStonesWeight3
      diamondPriceUsd:$diamondPriceUsd
      coloredStonesPriceUsd:$coloredStonesPriceUsd
      goldPriceUsd:$goldPriceUsd
      makingChargesUsd:$makingChargesUsd
      goldInvoiceNumber:$goldInvoiceNumber
          
    })
      {
        id
        productCategory{
          id
          name
        }
        productName
        designNumber
        itemNumber
        photos{
          id
          signedGetURL
          signedPutURL
        }
        cost
        users{
          id
          firstName
          lastName
        }
        costUsd
        costMad
        costAed
        margin
        displayedSellingPrice
        discount
        sellingPrice
        sellingPriceMargin
        description
        company
        otherFieldX
        otherFieldY
        other
        status
        stockQuantity
        dateImport
        coloredStonesWeight3
        coloredStonesType3
        diamondPriceUsd
        coloredStonesPriceUsd
        goldPriceUsd
        makingChargesUsd
        goldInvoiceNumber
  }
}
`;

export const customersQuery = gql`
query customers($limit:Int,$offset:Int,$id:ID,$actionType:ProductActionType,$customerName:String,$filterByColumn:String,$filterColumnValue:String ,$search:String,$phone:String,$filterByProductColumn: String
$filterByProductColumnValue: String, $startDate: DateTime
$endDate: DateTime) {
  customers(limit:$limit,offset:$offset,id:$id,customerName:$customerName,actionType:$actionType,filterByColumn:$filterByColumn,filterColumnValue:$filterColumnValue,search:$search,phone:$phone,filterByProductColumn:$filterByProductColumn,filterByProductColumnValue:$filterByProductColumnValue,startDate:$startDate,endDate:$endDate){
    id
    productId
    firstName
    lastName
    phone
    product{
      id
      productName
      tajNumber
      stockQuantity
      displayedSellingPrice
      supplierName
      supplierInvoiceNumber
      productCategory{
        name
        id
        
      }
      photos{
        id
        signedPutURL
        signedGetURL
      }
    }
    purchasedProducts{
      id
      productName
      tajNumber
      stockQuantity
      displayedSellingPrice
      productCategory{
        name
        id
        
      }
      photos{
        id
        signedPutURL
        signedGetURL
      }
    }
    email
    quantity
    description
    city
    ringSize
    actualSellingPrice
    paymentMethod
    numberOfCheques
    chequeDate1
    chequeDate2
    chequeDate3
    chequeDate4
    chequeDate5
    chequeDate6
    chequeDate7
    chequeDate8
    chequeDate9
    chequeDate10
    cashAmount
    chequeDate1Amount
    chequeDate2Amount
    chequeDate3Amount
    chequeDate4Amount
    chequeDate5Amount
    chequeDate6Amount
    chequeDate7Amount
    chequeDate8Amount
    chequeDate9Amount
    chequeDate10Amount
    actionType
    sellingPrice
    advanceAmount
    remainingDueAmount
    createdAt
    customerOrderNumber
    cashAmount
    totalCustomersCount
    cart
    additionalDiscount
    effectiveSalesPrice
    goldColor
    additionalInformation
    updatedAt
    heldAmount
    amountToCollect
  }
}
`;
export const customersOnlyIdQuery = gql`
query customers($limit:Int,$offset:Int,$id:ID,,$actionType:ProductActionType ) {
  customers(limit:$limit,offset:$offset,id:$id,actionType:$actionType){
    id
   
  }
}
`;


export const deleteCustomerMutution = gql`
mutation deleteCustomer( 
    $ids:[ID!],
  )
  {
    deleteCustomer( ids:$ids)
}
`;
export const deleteProductMutution = gql`
mutation deleteProduct( 
    $ids:[ID!],
  )
  {
    deleteProduct(ids:$ids)
}
`;



export const updateProductMutution = gql`
mutation updateProduct( 
  $id:ID!,
    $productCategory:ID,
    $productName :String,
    $designNumber :String,
    $itemNumber :String,
    $photo: String,
    $cost: Float,
    $description: String,
    $company :String,
    $otherFieldX :String,
    $otherFieldY: String,
    $other :String,
    $status :Boolean,
    $stockQuantity :Int,
    $dateImport:DateTime,
    $users :[ID],
    $costUsd :Float,
    $costMad :Float,
    $costAed :Float,
    $margin :Float,
    $displayedSellingPrice :Float,
    $discount :Float,
    $sellingPrice :Float,
    $sellingPriceMargin :Float,
    $currency:String

 
    $supplierName:String
    $supplierInvoiceNumber:String
    $tajNumber:String
    $maxAllowed:Float
    $minSellingPrice:Float
    $goldColor:String
    $goldWeight:Float
    $diamondWeight:Float
    $noDiamondStones:Int
    $coloredStonesTypeOne:String
    $coloredStonesWeightOne:String
    $coloredStonesTypeTwo:String
    $coloredStonesWeightTwo:String
    $size:String
    $comment:String
    $productLocation:String
    $usdAedFx:Float
    $aedMadFx:Float
    $supplierInvoiceDate:DateTime
    $archived:Boolean
    $coloredStonesType3:String
    $coloredStonesWeight3:String
    $diamondPriceUsd:Float
    $coloredStonesPriceUsd:Float
    $goldPriceUsd:Float
    $makingChargesUsd:Float
    $goldInvoiceNumber:String
    $category:String
    )
  {
    updateProduct( product:{
      id:$id
        productCategory:$productCategory,
        productName :$productName,
        designNumber :$designNumber,
        itemNumber :$itemNumber,
        photo:$ photo,
        cost:$ cost,
        description:$ description,
        company :$company,
        otherFieldX :$otherFieldX,
        otherFieldY:$ otherFieldY,
        other :$other,
        status :$status,
        stockQuantity :$stockQuantity,
        dateImport:$dateImport,
        users : $users,
        costUsd : $costUsd,
        costMad : $costMad,
        costAed : $costAed,
        margin : $margin,
        displayedSellingPrice : $displayedSellingPrice,
        discount : $discount,
        sellingPrice : $sellingPrice,
        sellingPriceMargin : $sellingPriceMargin,
        currency:$currency

    supplierName : $supplierName
    supplierInvoiceNumber : $supplierInvoiceNumber
    tajNumber: $tajNumber
    maxAllowed: $maxAllowed
    minSellingPrice: $minSellingPrice
    goldColor : $goldColor
    goldWeight : $goldWeight
    diamondWeight : $diamondWeight
    noDiamondStones  : $noDiamondStones
    coloredStonesTypeOne : $coloredStonesTypeOne
    coloredStonesWeightOne : $coloredStonesWeightOne
    coloredStonesTypeTwo  : $coloredStonesTypeTwo
    coloredStonesWeightTwo : $coloredStonesWeightTwo
    size : $size
    comment  : $comment
    productLocation : $productLocation
    usdAedFx:$usdAedFx
    aedMadFx:$aedMadFx
    supplierInvoiceDate:$supplierInvoiceDate
    archived:$archived
    coloredStonesType3:$coloredStonesType3
    coloredStonesWeight3:$coloredStonesWeight3
    diamondPriceUsd:$diamondPriceUsd
    coloredStonesPriceUsd:$coloredStonesPriceUsd
    goldPriceUsd:$goldPriceUsd
    makingChargesUsd:$makingChargesUsd
    goldInvoiceNumber:$goldInvoiceNumber
    category:$category
  
    })
      {
        
        id
        productCategory{
          id
          name
        }
        productName
        designNumber
        itemNumber
        photos{
          id
          signedGetURL
          signedPutURL
        }
        cost
        users{
          id
          firstName
          lastName
        }
        costUsd
        costMad
        costAed
        margin
        displayedSellingPrice
        discount
        sellingPrice
        sellingPriceMargin
        description
        company
        otherFieldX
        otherFieldY
        other
        status
        stockQuantity
        dateImport
        coloredStonesWeight3
        coloredStonesType3
        diamondPriceUsd
        coloredStonesPriceUsd
        goldPriceUsd
        makingChargesUsd
        goldInvoiceNumber
    
  }
}
`;


export const createCategoryMutution = gql`
mutation createCategory( 
  $name:String!,
  )
  {
    createCategory( category:{
     name:$name
    })
      {
        id
        name
  }
}
`;
export const updateCategoryMutution = gql`
mutation updateCategory( 
  $name:String!,
  $id:ID!
  )
  {
    updateCategory( category:{
     name:$name, id:$id
    })
      {
        id
        name
  }
}
`;
export const deleteCategoryMutution = gql`
mutation deleteCategory( 
    $ids:[ID!],
  )
  {
    deleteCategory(ids:$ids)
}
`;


export const categoryQuery = gql`
query categories($limit:Int,$offset:Int,$id:ID,$name:String) {
  categories(limit:$limit,offset:$offset,id:$id,name:$name){
    id
    name
  }
}
`;

export const createProductImageMutution = gql`
mutation createProductImage( 
  $productId:ID,
  $numberOfImages:Int
  )
  {
    createProductImage( productId:$productId, numberOfImages:$numberOfImages)
      {
        id
        signedPutURL
        signedGetURL
  }
}
`;


export const createProductArrayMutation = gql`
mutation createProduct( 
  $createProductInput:[CreateProductInput!]!
  )
  {
    createProduct( products:$createProductInput)
      {
        id
        productCategory{
          id
          name
        }
        productName
        designNumber
        itemNumber
        photos{
          id
          signedGetURL
          signedPutURL
        }
        cost
        users{
          id
          firstName
          lastName
        }
        costUsd
        costMad
        costAed
        margin
        displayedSellingPrice
        discount
        sellingPrice
        sellingPriceMargin
        description
        company
        otherFieldX
        otherFieldY
        other
        status
        stockQuantity
        dateImport
  }
}
`;

export const bulkProductUpdateMutution = gql`
mutation bulkProductUpdate( 
  $productIds:[ID],
  $status:Boolean
  $users:[ID]
  $removeAll:Boolean
  )
  {
    bulkProductUpdate( productIds:$productIds, status:$status,users:$users,removeAll:$removeAll)
}
`;
export const archiveProductMutution = gql`
mutation archiveProduct( 
  $ids:[ID!],
  $status:Boolean!
  )
  {
    archiveProduct( ids:$ids, status:$status)
}`;

export const deleteAttachmentMutution = gql`
mutation deleteAttachment($id:[ID!]!)
  {
    deleteAttachment( id:$id){
      id
    }
}
`;



export const updateCustomerMutution = gql`
mutation updateCustomer( 
  $id:ID!,
  $productId:ID!,
    $firstName :String!,
    $lastName :String,
    $phone :String,
    $email :String,
    $city:String
    $description :String,
    $ringSize :String,
    $actualSellingPrice :Float,
    $paymentMethod :ProductPaymentMethod,
    $numberOfCheques :Int,
    $chequeDate1 :DateTime,
    $chequeDate2 :DateTime,
    $chequeDate3 :DateTime,
    $chequeDate4 :DateTime,
    $chequeDate5 :DateTime,
    $chequeDate6 :DateTime,
    $chequeDate7 :DateTime,
    $chequeDate8 :DateTime,
    $chequeDate9 :DateTime,
    $chequeDate10 :DateTime,
    $chequeDate1Amount :Float,
    $chequeDate2Amount :Float,
    $chequeDate3Amount :Float,
    $chequeDate4Amount :Float,
    $chequeDate5Amount :Float,
    $chequeDate6Amount :Float,
    $chequeDate7Amount :Float,
    $chequeDate8Amount :Float,
    $chequeDate9Amount :Float,
    $chequeDate10Amount :Float,
    $cashAmount :Float,
    $actionType :ProductActionType,
    $advanceAmount :Float,
    $remainingDueAmount :Float,
    $sellingPrice :Float,
    $cart: String,
    $purchasedProducts : String,
    $goldColor:String
    $additionalInformation:String
    $heldAmount:String
    $amountToCollect:String
    $additionalDiscount:String
    $effectiveSalesPrice:String
    )
  {
    updateCustomer( customer:{
      id:$id
      productId :$productId ,
        firstName :$firstName,
        lastName:$lastName,
        phone:$phone,
        email:$email,
        description :$description,
        ringSize :$ringSize,
        city:$city,
        actualSellingPrice :$actualSellingPrice,
        paymentMethod :$paymentMethod,
        numberOfCheques :$numberOfCheques,
        chequeDate1:$chequeDate1,
        chequeDate2 : $chequeDate2,
        chequeDate3 : $chequeDate3,
        chequeDate4 : $chequeDate4,
        chequeDate5 : $chequeDate5,
        chequeDate6 : $chequeDate6,
        chequeDate7 : $chequeDate7,
        chequeDate8 : $chequeDate8,
        chequeDate9 : $chequeDate9,
        chequeDate10 : $chequeDate10,

        cashAmount : $cashAmount
        chequeDate1Amount : $chequeDate1Amount
        chequeDate2Amount: $chequeDate2Amount
        chequeDate3Amount: $chequeDate3Amount
        chequeDate4Amount: $chequeDate4Amount
        chequeDate5Amount : $chequeDate5Amount
        chequeDate6Amount : $chequeDate6Amount
        chequeDate7Amount : $chequeDate7Amount
        chequeDate8Amount  : $chequeDate8Amount
        chequeDate9Amount : $chequeDate9Amount
        chequeDate10Amount : $chequeDate10Amount
        actionType  : $actionType
        sellingPrice : $sellingPrice
        advanceAmount : $advanceAmount
        remainingDueAmount  : $remainingDueAmount
        cart:$cart
        purchasedProducts : $purchasedProducts
        goldColor:$goldColor
        additionalInformation:$additionalInformation
        heldAmount:$heldAmount
        amountToCollect:$amountToCollect
        effectiveSalesPrice:$effectiveSalesPrice
        additionalDiscount:$additionalDiscount

    })
      {
        id    
  }
}
`;




export const createCustomerMutution = gql`
mutation createCustomer( 
    $productId:ID!,
    $firstName :String!,
    $lastName :String!,
    $phone :String!,
    $email :String!,
    $city:String,
    $quantity:ID!,
    $description :String,
    $ringSize :String,
    $actualSellingPrice :Float,
    $paymentMethod :ProductPaymentMethod,
    $numberOfCheques :Int,
    $chequeDate1 :DateTime,
    $chequeDate2 :DateTime,
    $chequeDate3 :DateTime,
    $chequeDate4 :DateTime,
    $chequeDate5 :DateTime,
    $chequeDate6 :DateTime,
    $chequeDate7 :DateTime,
    $chequeDate8 :DateTime,
    $chequeDate9 :DateTime,
    $chequeDate10 :DateTime,
    $chequeDate1Amount :Float,
    $chequeDate2Amount :Float,
    $chequeDate3Amount :Float,
    $chequeDate4Amount :Float,
    $chequeDate5Amount :Float,
    $chequeDate6Amount :Float,
    $chequeDate7Amount :Float,
    $chequeDate8Amount :Float,
    $chequeDate9Amount :Float,
    $chequeDate10Amount :Float,
    $cashAmount :Float,
    $actionType :ProductActionType,
    $advanceAmount :Float,
    $remainingDueAmount :Float,
    $sellingPrice :Float,
    $cart: String,
    $purchasedProducts : String
    $effectiveSalesPrice: String
    $additionalDiscount : String,
    $goldColor:String
    $heldAmount:String
    $amountToCollect:String,
    $additionalInformation:String
    $oldOrderId:String
    $customerOrderNumber:String
    )
  {
    createCustomer( customer:{
      productId :$productId ,
        firstName :$firstName,
        lastName:$lastName,
        phone:$phone,
        email:$email,
        description :$description,
        ringSize :$ringSize,
        city:$city,
        actualSellingPrice :$actualSellingPrice,
        paymentMethod :$paymentMethod,
        numberOfCheques :$numberOfCheques,
        chequeDate1:$chequeDate1,
        chequeDate2 : $chequeDate2,
        chequeDate3 : $chequeDate3,
        chequeDate4 : $chequeDate4,
        chequeDate5 : $chequeDate5,
        chequeDate6 : $chequeDate6,
        chequeDate7 : $chequeDate7,
        chequeDate8 : $chequeDate8,
        chequeDate9 : $chequeDate9,
        chequeDate10 : $chequeDate10,

        cashAmount : $cashAmount
        chequeDate1Amount : $chequeDate1Amount
        chequeDate2Amount: $chequeDate2Amount
        chequeDate3Amount: $chequeDate3Amount
        chequeDate4Amount: $chequeDate4Amount
        chequeDate5Amount : $chequeDate5Amount
        chequeDate6Amount : $chequeDate6Amount
        chequeDate7Amount : $chequeDate7Amount
        chequeDate8Amount  : $chequeDate8Amount
        chequeDate9Amount : $chequeDate9Amount
    chequeDate10Amount : $chequeDate10Amount
    actionType  : $actionType
    sellingPrice : $sellingPrice
    advanceAmount : $advanceAmount
    remainingDueAmount  : $remainingDueAmount
    cart:$cart,
    quantity:$quantity,
    purchasedProducts : $purchasedProducts
    additionalDiscount:$additionalDiscount
    effectiveSalesPrice:$effectiveSalesPrice
    goldColor:$goldColor
    heldAmount:$heldAmount
    amountToCollect:$amountToCollect
    additionalInformation:$additionalInformation
    oldOrderId:$oldOrderId
    customerOrderNumber:$customerOrderNumber
    })
      {
        
        id
        purchasedProducts { 
          id
          productName
        }
      
    
  }
}
`;



export const refreshImagesMutution = gql`
mutation refreshImages
{
    refreshImages
}
`;

export const updateCartMutution = gql`
mutation updateCart($cart:String!)
  {
    updateCart( cart:$cart){
      id
    }
}
`;
export const cartListMutution = gql`
query accountInformation
  {
    accountInformation{
      id
      cart
    }
}
`;

export const productSuppliersQuery = gql`
query products($sortByColumn:String,$sortByPosition:SortOrder,$id:ID,$limit:Int,$offset:Int,$search:String,$userId:ID ,$archived:Boolean,$filterByColumn:String,$filterColumnValue:String,$displayPriceStart:Int,$displayPriceEnd:Int,$stockQty:Int,
  $supplierMode :Boolean) 
{
  products(sortByColumn:$sortByColumn,sortByPosition:$sortByPosition,id:$id,limit:$limit,offset:$offset,search:$search,userId:$userId,archived:$archived,
    filterByColumn:$filterByColumn,filterColumnValue:$filterColumnValue,displayPriceStart:$displayPriceStart,displayPriceEnd:$displayPriceEnd,stockQty:$stockQty,supplierMode:$supplierMode)
    {
    id
    supplierName
    supplierInvoiceDate
    supplierInvoiceNumber
    totalProductsCount
  }
}
`;


export const updateProductQtyMutution = gql`
mutation updateProductQty( 
  $productId:ID!,
    $qty:Int
    
    )
  {
    updateProductQty( 
      productId:$productId
      qty:$qty,
        
  
    )
      {
        
        id
       
    
  }
}
`;
