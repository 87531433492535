<app-main-loading *ngIf="loading"></app-main-loading>

<div class="row m-0 aboutBlk pt-5" >
  <div class="col-md-6 grayBg py-5 text-center d-flex align-items-center">
    <div *ngIf="confirmBlock" class="py-5">
    <img src="assets/Rond.png" />
      <h5 class="py-5 grayText font-weight-normal">Your email is associated with your private account. Please go to the app and change your verified email address.</h5>
    </div>
    <div *ngIf="!confirmBlock" class="py-5 text-center w-100">
      <img src="assets/sync.png" />
      <h5  class="py-5 grayText">Begin Merging Process of your private account <br/>
        to corporate account</h5>
      
      </div>
  </div>
  <div class="col-md-6 py-5 d-flex align-items-center">
    <div *ngIf="confirmBlock" class="py-5 text-center w-100">
      <img src="assets/sync.png" />
      <h5  class="py-5 grayText">Begin Merging Process of your private account <br/>
        to corporate accout</h5>
        <a   style="color:#ffffff !important" class="btn  btn-primary  text-uppercase" (click)="confirmBlock=false"> Merge account</a>
      </div>
      <div class="col-lg-8 col-md-10 m-auto" *ngIf="!confirmBlock" >
        <div class="text-center pt-3"  *ngIf="!showAccountBlk && !confirmBlock" >
          <h4 class="text-gray">Welcome to Staytouch</h4>
        </div>
       
        <div *ngIf="errorMessage">
          <div class="text-danger p-3" role="alert">
            {{errorMessage}}
          </div>
        </div>
        <form class="form-signin pb-3"  (ngSubmit)="verifyAccount()" *ngIf="!showAccountBlk && !confirmBlock">
          <div class="text-center" >
            <h4 class="text-gray py-3">Please verify your account </h4>
          </div>
        <div class="form-label-group1 pb-3" >
          <input type="text" maxlength="6"  name="mergeCode" id="mergeCode" [(ngModel)]="mergeCode"  placeholder="Merge Code" class="form-control custominp" >
          
        </div>
        <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" >Verify</button>
        <div class="d-flex justify-content-center text-center pt-2">
          <a  [routerLink]="['/login']"  class=""> Login?</a>
       </div>

      </form>
        <form class="form-signin py-3" [formGroup]="mergeForm"  (ngSubmit)="mergeAccount()" *ngIf="showAccountBlk">
          <div class="text-center" >
            <h4 class="text-gray pb-3">Please enter account details </h4>
          </div>
        <div class="pb-3">
          <label for="organization" class="text-gray"> Company name</label>
          <input type="text" id="organization" formControlName="organization" class="form-control custominp" />
          <div *ngIf="submitted && f.organization.errors" class="text-danger">
            <div *ngIf="f.organization.errors.required">
              Please enter organization name
            </div>
          </div>
        </div>
        <div  class="pb-3">
          <label for="organization" class="text-gray"> Company website</label>
          <input type="text" id="website" formControlName="website" class="form-control custominp" />
          <div *ngIf="submitted && f.website.errors" class="text-danger">
            <div *ngIf="f.website.errors.required">
              Please enter website link
            </div>
           
          </div>
        </div>
        <div class="pb-3">
          <label for="inputEmail" class="text-gray">Address</label>
          <textarea  formControlName="address" class="form-control custominp" ></textarea>
          <div *ngIf="submitted && f.address.errors" class="text-danger">
            <div *ngIf="f.address.errors.required">
              Please enter address
            </div>
           
          </div>
        </div>
          <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" >Merge Account</button>
          <div class="d-flex justify-content-center text-center pt-2">
            <a (click)="goToVerify()"   class=""> Back</a>
          </div>
        </form>

      </div>
  </div>
</div>
  