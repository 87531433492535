import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from "@angular/router"
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { MemberUser } from '../../../user/models/member-user'
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AppState, selectMemberAuth } from '../../../user/store/app.states'
import { MemberLogIn, MemberLogOut, MemberStatus } from '../../../user/store/actions/member.auth.actions'
import { environment} from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-owner-registration',
  templateUrl: './owner-registration.component.html',
  styleUrls: ['./owner-registration.component.scss']
})


export class OwnerRegistrationComponent implements OnInit {

  loginForm = null
  getState: Observable<any>
  isAuthenticated: boolean | null = null
  errorMessage: string | null
  withEmail:boolean = false
  user: MemberUser | null = null
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  submitted:boolean = false
  showVerification:boolean = false
  showRegistration:boolean = true
  getLoginDetails() {
    this.submitted = true;
    let phonenumber =''
    // stop here if form is invalid
    if(this.withEmail)
    {
      if(this.loginEmail.value == '')
      {
        return
      }
    }
    if(!this.withEmail)
    {
      if(this.loginPhone.value == '')
      {
        return
      }
      phonenumber =`${this.loginPhone.value.dialCode}${this.loginPhone.value.number}`
    }
    if(this.loginForm.invalid) {
        return;
    }
    if(this.showRegistration)
    {
      this.signup(phonenumber)
      return false
    }


  	const userInfo = {
      email: this.loginEmail.value,
      phoneNumber: phonenumber,
      code: this.loginOtpCode.value,
      token: environment.AUTH_TOKEN,
      session: "sadfdafxvsdfsfsfs",
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee" 
    };
    let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}
   // this.http.post<any>(`${environment.AUTH_URL}/verifyPhone`, userInfo,headers).subscribe(data => {
    this.http.post<any>(`${environment.AUTH_URL}/ec962387c419ada3bcc590583d35fa2d`, userInfo,headers).subscribe(data => {
      if(data.status == 'VERIFICATION_CODE_SENT')
      {
        this.showVerification = true

      }
      if(data.status == 'PHONE_NUMBER_VERIFIED')
      {
        this.showVerification = false
        this.showRegistration = true
      }
      if (data.status=="INVALID_CODE") {
       alert("Invalid OTP. please enter valid code.") 
      }
    },(error) => {
      console.log('there was an error sending the query', error);
      if(error=="INVALID_AUTHORIZATION")
      {
      }
    });
  }

  constructor(private http: HttpClient,private store: Store<AppState>, private router: Router) { 
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      loginEmail: new FormControl('', [Validators.email]),	  	// loginEmail: new FormControl(''),
      name: new FormControl(''),	  	// loginEmail: new FormControl(''),
	  	loginPhone: new FormControl('',[Validators.minLength(10),Validators.maxLength(15)]),
	  	loginOtpCode: new FormControl(''),
	  	password: new FormControl(''),
      organization: new FormControl(''),
      website: new FormControl(''),
      address: new FormControl(''),
      plan: new FormControl(''),
      numberOfUsers: new FormControl('') 
    })

  
  }
  get loginEmail() { return this.loginForm.get('loginEmail') }
  get loginPhone() { return this.loginForm.get('loginPhone') }
  get loginOtpCode() { return this.loginForm.get('loginOtpCode') }
  get loginName() { return this.loginForm.get('name') }
  get loginOrganization() { return this.loginForm.get('organization') }
  get loginPassword() { return this.loginForm.get('password') }
  signup(phonenumber)
  {
    const userInfo = {
      "user":{
        givenName: this.loginName.value,
        familyName: this.loginName.value,
        phone: phonenumber,
        password: this.loginPassword.value,
        email: this.loginEmail.value,
      },
    
      token: environment.AUTH_TOKEN,
      session: "sadfdafxvsdfsfsfs",
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
    };
      let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}
  //  this.http.post<any>(`${environment.AUTH_URL}/signUp`, userInfo,headers).subscribe(data => {
    this.http.post<any>(`${environment.AUTH_URL}/9e927ef97f0cc93905a3a8f7b4044a41`, userInfo,headers).subscribe(data => {
      console.log(JSON.stringify(data),'data')
      if(data.status == 'USER_SIGNED_UP')
      {
        this.showVerification = true
        alert('Registration completed successfully.')
      }
     else{

     }
    },(error) => {
      console.log('there was an error sending the query', error);
      if(error=="INVALID_AUTHORIZATION")
      {
      }
    });
  }
}
