export class MemberUser {
    id?: number
	email?: string
    password?: string
    phone?: string
	token?: string
	b2bPhoneVerified?: any
	isTempPassword?: any
	role?: any

	constructor(values: MemberUser) {
		Object.assign(this, values)
	}
}
