import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgxGalleryOptions} from 'ngx-gallery';
import {NgxGalleryImage} from 'ngx-gallery';
import {NgxGalleryAnimation} from 'ngx-gallery';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Apollo, QueryRef } from 'apollo-angular'
import {productsQuery,customersQuery} from '../../../services/products.gql'
const moment = require('moment-timezone')


interface IServerResponse {
  items: string[];
  total: number;
}
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  productId:any;
  private query2: QueryRef<any>

    productData:any=[];
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    loading:boolean = true
    productInfo:any 
    orderInfo:any
    page:any =1
    type:any =1
    searchText:any =""
    filterByColumn:any
    filterColumnValue:any
    supplierInfo:any
    constructor(private activatedRoute: ActivatedRoute,private cdr: ChangeDetectorRef,private router: Router,   private apollo: Apollo) { 

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.productId=params.id;
        this.productDetails()
      }
      if (params.page) {
        this.page=params.page;
      }
    })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
        from:'suppliers'
      let {filterByColumn,filterColumnValue,searchText,from,supplierName,startDate,endDate,page} = this.router.getCurrentNavigation().extras.state

      if(from =='suppliers'){
        this.supplierInfo = {
          supplierName,
          startDate,
          endDate,
          page
        }
      }
      else{
        if(searchText){
          this.searchText = searchText
        }
        if(filterByColumn && filterColumnValue){
          this.filterByColumn = filterByColumn
          this.filterColumnValue = filterColumnValue
        }
      }
     
    }
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

   
  }
  public productDetails()
  {
      this.loading = true
      this.query2 = this.apollo.watchQuery({
        query: customersQuery,
        fetchPolicy:'network-only',
        variables:{
          id:this.productId
        }
      })
  
       this.query2.valueChanges.subscribe(({ data }: any) => {
         this.loading = false
       this.orderInfo = data.customers[0]

       this.type = this.orderInfo.actionType ==  'ORDER' ? 2 : 1 ;
      //  this.orderInfo.cartString = this.orderInfo.cart ? JSON.parse(this.orderInfo.cart)  :  []
       if(this.orderInfo.cart){
        let cartString = this.orderInfo.cart.replace(/'/g, '"')
         cartString = JSON.parse(cartString)
         this.orderInfo.cartString =cartString
       }
       else{
        this.orderInfo.cartString = []
       }
       this.galleryImages = this.orderInfo.product.photos.map((media)=>{
        return {
          small: media.signedGetURL,
          medium: media.signedGetURL,
          big: media.signedGetURL
        }
    })
    let groupProducts = []
    if(this.orderInfo.purchasedProducts && this.orderInfo.purchasedProducts.length > 0){
      let arr2 = [];
    
      let key ="id"
      this.orderInfo.purchasedProducts.forEach((x)=>{
           
        // Checking if there is any object in arr2
        // which contains the key value
         if(arr2.some((val)=>{ return val[key] == x[key] })){
             
           // If yes! then increase the occurrence by 1
           arr2.forEach((k)=>{
             if(k[key] === x[key]){ 
               k["occurrence"]++
             }
          })
             
         }else{
           // If not! Then create a new object initialize 
           // it with the present iteration key's value and 
           // set the occurrence to 1
           let a = {}
           a =x
           a[key] = x[key]
           a["occurrence"] = 1
           arr2.push(a);
         }
      })
      this.orderInfo.purchasedProducts = arr2
        
    }
    if(this.galleryImages.length ==0){
      this.galleryImages.push({
        small: 'assets/product.png',
        medium: 'assets/product.png',
        big: 'assets/product.png'
      })
    }
      },err=>{
        this.loading = false
      })
    
  }                                                   
  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }
 deleteProduct(){
    if(confirm("Are you sure do you want to delete this product?"))
    {
        // this.productService.deleteVariant(this.productId).subscribe((data: any)=>{
        //   if(data.status == 'success')
        //   {
        //     alert("Product deleted successfully. ")
        //     this.router.navigate(['/admin/product-list'])
        //   }
        // })
    }
  }
  round(number){
    return Math.round(number)
      }

      gotoOrders(){
        if(this.supplierInfo){
          this.router.navigate(['orders-analytics'], {
            state: this.supplierInfo
          })
          return
        }
        
        this.router.navigate(['/product-enquires',this.page,this.type], {
          state: {
            searchText:this.searchText,
            filterByColumn:this.filterByColumn,
            filterColumnValue : this.filterColumnValue,
           
          }
        })
      }
      replaceST(des){
        return des.split("_ST_NL_").join("\n");
       }
      //  getDisplayedSellingPrice(){
      //   // console.log(product)
      //   let purchasedProducts = this.orderInfo.purchasedProducts
        
      //   if(purchasedProducts){
      //     let count = 0
      //     purchasedProducts.forEach(item=>{
      //       count = count + item.displayedSellingPrice
      //     })
      //     return count
      //   }
      //   return product.displayedSellingPrice
       
        
      // }
    

}
