import { Component, OnInit, ViewChild,OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
import { ToastrService } from 'ngx-toastr'
import { teamsQuery, deleteTeamMutation,makeManagerMutation } from '../team.gql'
import { ElementRef } from '@angular/core'
import { membersBasicQuery, myTeamInfo,memberSearchForTeamsQuery } from '../../../services/admin.gql'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import {GoogleAnalyticsService} from "../../../../shared/google-analytics.service"; // import our analytics service
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss']
})
export class CreateTeamComponent implements OnInit {

  @ViewChild('closebutton', { static: false }) closebutton
  @ViewChild('addTeamButton', { static: false }) addTeamButton
  @ViewChild('myElem', { static: false }) MyProp: ElementRef

  myElement: ElementRef
  editOrAdd: boolean = false
  teamDetails = {}
  currentState = null
  toggleAddTeam = false
  teams: any = []
  internalTeams:any = []
  members: any = []
  ngxDisabled: boolean = false
  ngxMembersValue: any = []
  selectedTeamCreateProfile: string = '1'
  tNSortClicked: boolean = false
  isTeamNameSort: boolean = false
  nMSortClicked: boolean = false
  isNumberMembersSort: boolean = false
  isMemberSort: boolean = false
	query$:any
  private queryTm: QueryRef<any>
  private querymTm: QueryRef<any>
  private membersQuery: QueryRef<any>
  private querySearch:QueryRef<any>
  public teamId: any
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  // modal form variables
  firstname = null
  lastname = null
  email = null
  phonenumber = null
  isDropdown: boolean = false
  public loading: boolean = true
  userRole:any = "Member"
  currentTeamId:any
  accountInfo:any = {}
  isFilter:boolean= false
  filterText:any
  tempInfo:any = []
  userId:any
  constructor (
    private apollo: Apollo,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private translate: TranslateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private helperService:HelperService
  ) {
   this.userId = localStorage.getItem('id')
   this.myteamsInfo()
    this.isAdmin = this.helperService.isAdmin()
    
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
      }
    })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {

      this.currentState = this.router.getCurrentNavigation().extras.state
      if (this.currentState.toggleAddTeam == true) {
        this.toggleAddTeam = true

        // this.myElement.nativeElement.getElementById('addTeamDiv').scrollIntoView({behavior: 'smooth'});
        setTimeout(() => {
          const elmnt = document.getElementById('addTeamDiv') as HTMLElement
          elmnt.scrollIntoView()
        }, 500)
        //this.openAddTeam('','target');
      }
    }
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      // console.log(data,'data')
      this.accountInfo = data.accountInformation
       this.permissions = this.helperService.getPermissions(data)
        // console.log(this.permissions)
       this.userRole = this.permissions.role
    })
  }
  /**************/
  ngOnInit () {
    // this.getLocation()
  this.teamsInfo()
  }
  openAddTeam (team, el: HTMLElement) {
    this.SendEvent('createTeam','teams','click',1)

    this.teamDetails = {}
    this.toggleAddTeam = !this.toggleAddTeam
    this.editOrAdd = true
    el.scrollIntoView({ behavior: 'smooth' })
  }

  closeAddTeam (teamId) {
    this.teamsInfo()
    this.myteamsInfo()
    // alert()
    if(teamId){
      this.currentTeamId = teamId
    } 
     this.toggleAddTeam = !this.toggleAddTeam
    this.editOrAdd = false
    const elmnt1 = document.getElementById(`accordion`) as HTMLElement
    if(elmnt1)
    {
      elmnt1.click()
      elmnt1.scrollIntoView()
    }
   
  }

  editTeam (team, el: HTMLElement) {
    this.toggleAddTeam = true
    this.editOrAdd = false
    //console.log(team, 'team')
    this.teamDetails = team
    el.scrollIntoView({ behavior: 'smooth' })
  }

  sortTeamName () {
    this.tNSortClicked = true
    this.nMSortClicked = false
    this.isTeamNameSort = !this.isTeamNameSort
    if (this.isTeamNameSort) {
      this.teams.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      this.teams.sort((a, b) => b.name.localeCompare(a.name))
    }
  }

  sortMemberName () {
    this.isMemberSort = !this.isMemberSort
    if (this.isMemberSort) {
      this.members.sort((a, b) => a.firstName.localeCompare(b.firstName))
    } else {
      this.members.sort((a, b) => b.firstName.localeCompare(a.firstName))
    }
  }

  sortNumberMembers () {
    this.tNSortClicked = false
    this.nMSortClicked = true

    this.isNumberMembersSort = !this.isNumberMembersSort
    if (this.isNumberMembersSort) {
      this.teams.sort((a, b) => a.memberIds.length - b.memberIds.length)
    } else {
      this.teams.sort((a, b) => b.memberIds.length - a.memberIds.length)
    }
  }

  /**************/

  getLocation () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const longitude = position.coords.longitude
        const latitude = position.coords.latitude
        this.callApi(longitude, latitude)
      })
    } else {
      console.log('No support for geolocation')
    }
  }

  callApi (Longitude: number, Latitude: number) {
    const url = `http://www.mapquestapi.com/geocoding/v1/address?key=FsW4zPNITAJCPYTPDSNTjt7sgKEdGGWj&location=${Latitude},
    ${Longitude}&includeRoadMetadata=true&includeNearestIntersection=true`
    this.http.get<any>(url).subscribe(
      data => {
        //console.log(data, 'data')
      },
      error => {}
    )
  }

 
  teamsInfo(){
    this.queryTm = this.apollo.watchQuery({
      query: teamsQuery,
      variables: {},
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

    this.query$ = this.queryTm.valueChanges.subscribe(
      ({ data, loading }) => {
        this.teams = this.tempInfo = data.teams
        this.loading = false
        
        if(this.userRole=='MANAGER')
        this.teams = this.teams.filter((item1)=>{
           return this.accountInfo.teams.filter((item2)=>{
             if(item2.teamId == item1.teamId)
             {
              return true
             }
               
            }).length > 0
            
        })
        // console.log(this.teams)
        //while loading sort the team names in ascending order
        this.tNSortClicked = true
        this.isTeamNameSort = true
        if(this.currentTeamId){
          setTimeout(() => {
            const elmnt1 = document.getElementById(
              `team-${this.currentTeamId}`
            ) as HTMLElement
            if(elmnt1)
            {
              // console.log(elmnt1)
              elmnt1.click()
              elmnt1.scrollIntoView()
            }
          },10)

        }
        this.activatedRoute.params.subscribe((params: Params) => {
          if (params.id) {
            //  alert()
            this.teamId = params.id
            setTimeout(() => {
              const elmnt1 = document.getElementById(
                `team-${params.id}`
              ) as HTMLElement
              if(elmnt1)
              {
                // console.log(elmnt1)
                elmnt1.click()
                elmnt1.scrollIntoView()
              }
            })
          }
        })
        // this.teams.sort((a, b) => a.title.localeCompare(b.title))
      },
      error => {
        this.loading = false
        console.log('there was an error sending the mutation', error)
      }
    )
  }
  inviteMember () {
    this.translate
      .get('MESSAGES.SUCCESSFULLY_INVITE_THE_MEMBER')
      .subscribe((res: string) => {
        this.toastr.info(res, null, {})
      })
    this.closebutton.nativeElement.click()
  }
  getMemberDetails (team) {
    this.toggleAddTeam = false;
    this.members=[]
    this.loading = true
    this.membersQuery = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        userIds: team.memberIds
      },
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

    this.membersQuery.valueChanges.subscribe(
      ({ data, loading }) => {
        //console.log(data)
        this.loading = false
        if (data.users) {
          this.members = data.users
          this.members &&
            this.members.forEach((user, j) => {
              //this.members[j].title='--'
              this.members[j].profilePic =  user.profiles[0] && user.profiles[0].displayImageURL
              user.profiles[0] &&
                user.profiles[0].data.forEach((item, i) => {
                  if (item.format == 'JOB_TITLE') {
                    this.members[j].title = item.value ? item.value : '-'
                  }
                  if (item.format == 'DEPARTMENT') {
                    this.members[j].department = item.value ? item.value : '-'
                  }
                 
                })
            })
            //console.log(this.members)
        }
      },
      error => {
        this.loading = false
      }
    )
  }
  deleteTeam (teamId) {
    this.translate
      .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_THIS_TEAM')
      .subscribe((res: string) => {
        if (!confirm(res)) {
          return false
        }
        else{
          this.apollo
          .mutate({
            mutation: deleteTeamMutation,
            variables: {
              teamIds: [parseInt(teamId)]
            },
            update: (proxy, { data: { teamsDelete } }: any) => {
              // console.log(teamsDelete)
              // Read the data from our cache for this query.
              let data: any = proxy.readQuery({ query: teamsQuery })
              data = data.teams.filter(item => item.teamId !== teamId)
              proxy.writeQuery({ query: teamsQuery, data: { teams: data } })
            }
          })
          .subscribe(
            ({ data }) => {
              this.toggleAddTeam = false
              this.translate
                .get('MESSAGES.TEAM_DELETED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
            },
            error => {
              //  this.deleteTeam(teamId)
              this.toastr.info('Error while deleting the team.')
              //console.log('there was an error sending the mutation', error);
            }
          )
        }
    })
   
  }
  profileCreateSend(){
    
  }
  connectToMember(teamId,memberId,manager,i){
    this.router.navigate(['/view-member',memberId,"team"])
  }

  SendEvent(eventCategory,eventLabel,eventAction,eventValue) {
    this.googleAnalyticsService.eventEmitter(eventCategory,eventLabel,eventAction,eventValue);
  }
  isManger(managers,userId){
    return managers.includes(userId)
  }
   
  myteamsInfo(){
    this.querymTm = this.apollo.watchQuery({
      query: myTeamInfo,
      variables: {},
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

  this.querymTm.valueChanges.subscribe(
      ({ data, loading }) => {
        this.internalTeams = data.accountInformation.teams
        this.loading = false
      },
      error => {
        this.loading = false
        console.log('there was an error sending the mutation', error)
      }
    )
  }
  memberSearchInTeams(ev){
    let searchText = ev.target.value.trim()
    if(!searchText){
      this.teams = this.tempInfo
    }
    this.teams = []
    this.querySearch = this.apollo.watchQuery({
      query: memberSearchForTeamsQuery,
      variables: {
        searchByName: searchText,
      },
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

  this.querySearch.valueChanges.subscribe(
      ({ data, loading }) => {
        this.teams = []
        // this.teams = [...this.teams]
        data.users.forEach(element => {
          // this.teams.push(element.teams)
        // console.log(element.teams)
        // let z = this.teams
          this.teams =  this.teams.concat(element.teams)
          // this.teams = [...new Set([...z,...element.teams])]

        });
        this.teams = this.teams.reduce((accumalator, current) => {
          if(!accumalator.some(item => item.teamId === current.teamId)) {
            accumalator.push(current);
          }
          return accumalator;
      },[]);

        // let jsonObject:any = this.teams.map(JSON.stringify);
      
        // console.log(jsonObject);
  
        // let uniqueSet:any = new Set(jsonObject);
        // this.teams = Array.from(uniqueSet).map(JSON.parse);
  
        // console.log(uniqueArray);
        // this.teams.filter((item,i)=>{
        //     console.log(this.teams.indexOf(item))
        // })
//         var ids = new Set(this.team.map(d => d.ID));
// var merged = [...this.teams, ....teams.filter(d => !ids.has(d.ID))];
//         console.log(this.teams)
        
        // this.internalTeams = data.accountInformation.teams
        // this.loading = false
      },
      error => {
        this.loading = false
        console.log('there was an error sending the mutation', error)
      }
    )
  }
  clearFilter () {
    this.teams = this.tempInfo
    this.isFilter = false
    this.loading=false
    this.filterText=''
  }
  makeManager(teamId,memberId,manager,i){
    this.loading = true
    this.apollo
    .mutate({
      mutation: makeManagerMutation,
      variables: {
        teamId: teamId,
        memberId:memberId,
        manager:manager
      }
    })
    .subscribe(
      ({ data }) => {
        this.loading = false
        if(manager){
          this.teams[i].managers.push(memberId)
        }
        else{
          this.teams[i].managers = this.teams[i].managers.splice(i,1)
        }
        this.toastr.info("Updated the user role.")

      },
      error => {
        this.loading = false
      }
    )
  }
  ngOnDestroy(){
    //this.query.unsubscribe();
    this.query$ ? this.query$.unsubscribe() : "";
  }
}
