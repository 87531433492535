import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Apollo, QueryRef } from 'apollo-angular'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { Router, ActivatedRoute, Params } from '@angular/router'

import {
 
  mergeUserMutation
} from '../../../services/admin.gql'
@Component({
  selector: 'app-merge-account',
  templateUrl: './merge-account.component.html',
  styleUrls: ['./merge-account.component.scss']
})
export class MergeAccountComponent implements OnInit {

  constructor( private formBuilder: FormBuilder, private apollo: Apollo,private translate: TranslateService,private toastr: ToastrService, private router: Router) { }
  mergeForm= null
  loading:boolean = false
  code:any =""
  showAccountBlk:boolean = false
  submitted= false
  ngOnInit() {
    this.mergeForm = this.formBuilder.group({
      email: ['' ],
      phone: [''],
    })
  }
  get f () {
    return this
    .mergeForm.controls
  }
  verifyCode(){
    if(!this.code)
    {
      alert("Please enter verification code.")
    }
    else{
      this.mergeAccount(this.code)    
    }
  }
  mergeAccount(code="")
  { 
    this.submitted = true
    if (this.mergeForm.invalid) {
      this.translate
      .get('MESSAGES.PLEASE_ENTER_THE_REQUIRED_FIELDS')
      .subscribe((res: string) => {
        this.toastr.info(res)
          })
    return
    }
    if(!this.f.email.value && !this.f.phone.value)
    {
      this.toastr.error("Please enter email or phone number.")
      return false
    }
    this.loading = true
    let phone:any = ''
    if(this.f.phone.value && this.f.phone.value.internationalNumber)
    {
      phone = this.f.phone.value
      phone = phone.internationalNumber.split('-').join('')
      phone = phone.split(' ').join('')
    }
    let userInfo = {
      email: this.f.email.value,
      phone: phone,
      account:true
    }
    this.apollo
    .mutate({
      mutation: mergeUserMutation,
      variables: {
        user:userInfo,
        code:code,
       
      },
      errorPolicy:"all"
    })
    .subscribe(
      ({ data,errors }:any) => {
        this.loading = false
       if(data.mergeUser)
       {
         if(this.code)
         {
            alert("Account merged successfully.")
            this.router.navigate(['/member-details'])
         }
         else{
          this.showAccountBlk = true
         }
        
       }
       else if(errors[0].message == "CODE_VERIFICATION_FAILED"){
        alert("Invalid verification code.")
      }
       else{
         alert("Error while merge the account.")
       }
        //console.log('got data', data);
      },
      error => {
        this.loading = false

        alert("Error while merge the account.")
        console.log('there was an error sending the mutation', error)
      }
    )

  }
 
}
