import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,ActivatedRoute ,Params} from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { AppState, selectMemberAuth } from '../store/app.states'
import { MemberStatus } from '../store/actions/member.auth.actions'

import { MemberUser } from '../models/member-user'
@Injectable({ providedIn: 'root' })
export class MemberAuthGuard implements CanActivate {

	getState: Observable<any>
	isAuthenticated: boolean = null
    user: MemberUser = null
    b2bPhoneVerified:any = null
    id:any
    constructor(
        private router: Router, private store: Store<AppState>,
        public activatedRoute:ActivatedRoute
    ) {
      
    	this.getState = this.store.select(selectMemberAuth)
    	this.store.dispatch(new MemberStatus)
      this.activatedRoute.params.subscribe((params: Params) => {
        // console.log(params,'pg  x  bn i ')
        
        if (params.id) {
          this.id = params.id
        }
        // window.history.pushState({}, document.title, "/search" );
      })
      }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    	this.getState.subscribe((state) => {
        // console.log(route.url[0].path)
        // console.log(route.params,route,state)
          this.isAuthenticated = state.memberAuth.isAuthenticated;
	      this.b2bPhoneVerified = state.memberAuth.user.b2bPhoneVerified ? state.memberAuth.user.b2bPhoneVerified : '';
          this.user = state.memberAuth.user;
          if(state.memberAuth.user.role == "ADMIN"  && this.b2bPhoneVerified == "false" && localStorage.getItem("phone")!='null') {
                this.router.navigate(['/company-verification']);
                return false
           }
          // if(state.memberAuth.user.role == "ADMIN" && (localStorage.getItem("verified") == null || window.atob(localStorage.getItem("verified"))!='true')){
          //   this.router.navigate(['/login']);
          //   return false

          // }
        });
        if(localStorage.getItem("verified") == null || window.atob(localStorage.getItem("verified"))!='true')
        {  
          // alert(2)
          if(route.url && route.url[0] && route.url[0].path == 'search'){
            // this.pa
            this.id = route.params.id
          }
          this.router.navigate(['/login',this.id]);
          return false;
        }
        // return true
        const currentUser = this.user
        if (this.isAuthenticated) {
            // logged in so return true
            
            return true;
        }
        //  alert()
alert(4)
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login',this.id]);
        return false;
    }
}
