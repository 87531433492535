import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {MemberDetailsComponent} from './components/member-details/member-details.component'
import { MemberAuthGuard  } from './services/member-auth.guard'
import { PermissionsAuthGuard  } from './services/permissions-auth.guard'
import { UserComponent } from './user.component'
import { SettingsComponent} from './components/settings/settings.component'
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MyTeamsComponent } from './components/account/my-teams/my-teams.component';
import { OfficialProfileComponent } from './components/account/official-profile/official-profile.component';
import { UpdateAccountComponent } from './components/account/update-account/update-account.component';
import { TeamsListComponent } from './components/teams/teams-list/teams-list.component'
import { CreateMemberComponent } from './components/members/create-member/create-member.component'
import { MembersListComponent } from './components/members/members-list/members-list.component'

import { AdminCreatedProfilesComponent } from './components/members/admin-created-profiles/admin-created-profiles.component';
import { MyProfileComponent } from './components/account/my-profile/my-profile.component';
import { IntegrationsComponent } from './components/account/integrations/integrations.component'
import { MergeAccountComponent } from './components/account/merge-account/merge-account.component'
import { PendingMembersComponent } from './components/members/pending-members/pending-members.component'
import { ViewMemberProfileComponent } from './components/members/view-member-profile/view-member-profile.component';
import { ViewAccountComponent } from './components/members/view-account/view-account.component';
import { SyncronizationsComponent } from './components/account/syncronizations/syncronizations.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { VerifyMfaComponent } from './components/account/verify-mfa/verify-mfa.component';
import { AdminDashboardComponent } from './components/members/admin-dashboard/admin-dashboard.component';
import { MemberMeetingsComponent } from './components/members/member-meetings/member-meetings.component';
import { CompanyMeetingsComponent } from './components/members/company-meetings/company-meetings.component';
import { TeamMembersComponent } from './components/teams/team-members/team-members.component';
import { ProductsComponent } from './components/products/products.component';
import { CreateProductComponent } from './components/products/create-product/create-product.component';
import { ImportProductComponent } from './components/products/import-product/import-product.component';
import { CreateTeamComponent } from './components/teams/facebook/create-team.component';
import { OpenProfileComponent } from './components/members/open-profile/open-profile.component';

import { ProductsEnquiriesComponent } from './components/products/products-enquiries/products-enquiries.component'; // <-- import the module
import { CategoryComponent } from './components/products/category/category.component'; // <-- import the module
import { ProductDetailsComponent } from './components/products/product-details/product-details.component'; // <-- import the module
import { OrderDetailsComponent } from './components/products/order-details/order-details.component'; // <-- import the module

import { ArchiveProductsComponent } from './components/products/archive-products/archive-products.component'; // <-- import the module
import { UpdateOrderComponent } from './components/products/update-order/update-order.component'; // <-- import the module
import { SearchCustomerComponent } from './components/products/search-customer/search-customer.component';
import { CreateCustomerComponent } from './components/products/create-customer/create-customer.component';
import { CartListComponent } from './components/products/cart-list/cart-list.component';
import { SearchProductsComponent } from './components/products/search-products/search-products.component';
import { SuppliersComponent } from './components/products/suppliers/suppliers.component';
import { OrderAnalyticsComponent } from './components/products/suppliers/order-analytics/order-analytics.component';

const routes: Routes = [
      {
        path: '', 
        component: UserComponent, 
        children: [
          { path: 'teams', component: TeamsListComponent ,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Internal Team',idtype:'t'}},
          { path: 'teams/:id', component: TeamsListComponent ,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'CREATE_MEMBER.TEAMS',idtype:'t'}},
          { path: 'member-details',component: MemberDetailsComponent, canActivate: [MemberAuthGuard], data: {title: 'HEADER.DASHBOARD'}},
         // { path: '',component: MemberDetailsComponent, canActivate: [MemberAuthGuard], data: {title: 'HEADER.DASHBOARD'}},
			{path:'',component:ProductsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},

          
          { path: 'settings',component:SettingsComponent, canActivate: [MemberAuthGuard],data:{title: 'HEADER.SETTINGS'}},
          { path: 'update-account',component:UpdateAccountComponent, canActivate: [MemberAuthGuard],data:{title: 'HEADER.ACCOUNT'}},

          { path: 'reset-password',component:ResetPasswordComponent, canActivate: [MemberAuthGuard],data:{title: 
            'HEADER.ACCOUNT'}},
          { path: 'reset-password/:id',component:ResetPasswordComponent, canActivate: [MemberAuthGuard],data:{title: 
            'HEADER.ACCOUNT'}},


          { path: 'update-profile',component:OfficialProfileComponent, canActivate: [MemberAuthGuard],data:{title: 'HEADER.ACCOUNT'}},
          { path: 'my-profile',component:MyProfileComponent, canActivate: [MemberAuthGuard],data:{title: 'HEADER.ACCOUNT'}},
          { path: 'my-teams',component:MyTeamsComponent, canActivate: [MemberAuthGuard],data:{title: 'HEADER.ACCOUNT'}},
      


          { path: 'create-member', component: CreateMemberComponent ,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: "Internal Team Members"}},
          { path: 'create-member/:id', component: CreateMemberComponent ,canActivate: [MemberAuthGuard,PermissionsAuthGuard], data: {title: " Internal Team Members"}},
          { path: 'members', component: MembersListComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: "Internal Team Members",idtype:'m'} },
          { path: 'official-profile/:id/:from',component: AdminCreatedProfilesComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: "Internal Team Members"}},
          { path: 'official-profile',component: AdminCreatedProfilesComponent,canActivate: [MemberAuthGuard],data: {title: 'MESSAGES.OFFICIAL_PROFILE'}},
          
          { path: 'zoom-connect',component: IntegrationsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Account'}},
          { path: 'merge-account',component: MergeAccountComponent,canActivate: [MemberAuthGuard],data: {title: 'Account'}},
          { path: 'syncronizations',component: SyncronizationsComponent,canActivate: [MemberAuthGuard],data: {title: 'Account'}},
          { path: 'pending-members',component: PendingMembersComponent,canActivate: [MemberAuthGuard],data: {title: 'Account'}},
          { path: 'view-profile/:id',component: ViewMemberProfileComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Internal Team Members'}},
          { path: 'view-member/:id',component: ViewAccountComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Internal Team Members'}},
          { path: 'view-member/:id/:type/:projectId',component: ViewAccountComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Internal Team Members'}},

          { path: 'view-member/:id/:type',component: ViewAccountComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Internal Team Members'}},
          { path: 'analytics',component: AnalyticsComponent,canActivate: [MemberAuthGuard],data: {title: 'Analytics'}},
           {path:'mfa',component:VerifyMfaComponent,canActivate: [MemberAuthGuard],data: {title: 'Account'}},
           {path:'admin-dashboard',component:AdminDashboardComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Admin Dashboard'}},  
           {path:'member-meetings',component:MemberMeetingsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Admin Dashboard'}},
           {path:'company-meetings',component:CompanyMeetingsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Company Meetings'}},
           {path:'team-members/:teamId',component:TeamMembersComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Teams'}},
           {path:'products/:page',component:ProductsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'products',component:ProductsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'products/:page/:type',component:ProductsComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'product-enquires',component:ProductsEnquiriesComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'product-enquires/:page',component:ProductsEnquiriesComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'product-enquires/:page/:type',component:ProductsEnquiriesComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},

           {path:'create-product',component:CreateProductComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'create-product/:id/:page',component:CreateProductComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
           {path:'import-product',component:ImportProductComponent,canActivate: [MemberAuthGuard,PermissionsAuthGuard],data: {title: 'Products'}},
          //  {path:'my-notes',component:MyNotesComponent,data: {title: 'Admin Dashboard'}},
           {path:'facebook',component:CreateTeamComponent,canActivate: [MemberAuthGuard],data: {title: 'Facebook'}},
          {path:'public-profile',component:OpenProfileComponent,canActivate: [MemberAuthGuard],data: {title: 'Projects'}},
          {path:'public-profile/:id',component:OpenProfileComponent,canActivate: [MemberAuthGuard],data: {title: 'Projects'}},
          { path: 'categories',component: CategoryComponent,canActivate: [MemberAuthGuard],data: {title: 'Products'}},
          { path: 'product-details/:id/:page',component: ProductDetailsComponent,canActivate: [MemberAuthGuard],data: {title: 'Products'}},
          { path: 'order-details/:id/:page',component: OrderDetailsComponent,canActivate: [MemberAuthGuard],data: {title: 'Products'}},
          { path: 'order-details/:id',component: OrderDetailsComponent,canActivate: [MemberAuthGuard],data: {title: 'Products'}},

          { path: 'product-details/:id//:page/:type',component: ProductDetailsComponent,canActivate: [MemberAuthGuard],data: {title: 'Products'}},
          { path: 'archive-products',component: ArchiveProductsComponent,canActivate: [MemberAuthGuard],data: {title: 'Archived Products'}},

          { path: 'update-order/:id/:page',component: UpdateOrderComponent,canActivate: [MemberAuthGuard],data: {title: 'Update Order'}},
          { path: 'search',component: SearchCustomerComponent,canActivate: [MemberAuthGuard],data: {title: 'Identify customer'}},

          { path: 'search/:id',component: SearchCustomerComponent,canActivate: [MemberAuthGuard],data: {title: 'Identify customer'}},

          { path: 'create-customer',component: CreateCustomerComponent,canActivate: [MemberAuthGuard],data: {title: 'Create Customer'}},
          { path: 'cart-list',component: CartListComponent,canActivate: [MemberAuthGuard],data: {title: 'Payment'}},
          { path: 'search-products',component: SearchProductsComponent,canActivate: [MemberAuthGuard],data: {title: 'Search Products'}},
          { path: 'suppliers-list',component: SuppliersComponent,canActivate: [MemberAuthGuard],data: {title: 'Suppliers'}},
          { path: 'orders-analytics',component: OrderAnalyticsComponent,canActivate: [MemberAuthGuard],data: {title: 'Suppliers'}},

          
        ]
        
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
 
})
export class UserRoutingModule { }
