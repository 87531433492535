<!-- <h2 class="font-weight-bold pb-3">{{ 'TEAMSPAGE.DASHBOARD' | translate  }}</h2> -->
<!-- <app-sub-menu></app-sub-menu> -->
<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card p-md-5 py-5 my-3 ">
      <div class="dropdown p-absolute">

      </div>
      <div class="d-flex justify-content-between py-3">
        <p
          (click)="sortTeamName()"
          class="small-font-mb cursor-pointer"
          [ngClass]="{ 'text-gray2': !tNSortClicked }"
        >
          Team &ensp;<img class="arrImg"
            *ngIf="!isTeamNameSort && !nMSortClicked"
            [src]="tNSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
          /><img class="arrImg"
            *ngIf="isTeamNameSort && !nMSortClicked"
            [src]="tNSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
            style="transform: rotate(180deg);"
          />
        </p>
        <p
          (click)="sortNumberMembers()"
          class="small-font-mb cursor-pointer"
          [ngClass]="{ 'text-gray2': !nMSortClicked }"
        >
          {{ 'TEAMSPAGE.NUMBER_OF_MEMBERS' | translate }} &ensp;<img class="arrImg"
            *ngIf="isNumberMembersSort && !tNSortClicked"
            [src]="nMSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
          /><img
            *ngIf="!isNumberMembersSort && !tNSortClicked" class="arrImg"
            [src]="nMSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
            style="transform: rotate(180deg);"
          />
        </p>
      </div>
      <ng-container *ngIf="teams.length >= 0">
        <ul class="m-0 p-0 temsBlk-lg" id="accordion"  #teamsTarget>
          <li class="" *ngFor="let team of internalTeams;let i=index">
                <a
                class="d-sm-flex justify-content-between"
                data-toggle="collapse"
                href="#collapseOne-{{ team.teamId }}"
                id="collapse-{{ team.teamId }}"
              >
                <p id="team-{{ team.teamId }}" (click)="getMemberDetails(team)" class="m-0 flex-1">
                  {{ team.name }}
                </p>
                <div class="text-right">
                 <span (click)="getMemberDetails(team)" style="
                 padding: 24px 0px;
             "> {{
                    'TEAMSPAGE.N_MEMBERS'
                      | translate: { members_count: team.memberIds.length }
                  }}</span>
                  <a
                    data-toggle="dropdown"
                    class="ml-2 d-none"
                    style="border-bottom:0px;"
                    *ngIf="isAdmin || (permissions && permissions.company.create_teams_and_departments == 'true')"
                  >
                    <img
                      id="blah"
                      src="assets/dots.svg"
                      alt="dots"
                      style="transform: rotate(90deg);"
                    />
                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow "
                     
                    >
                      <a
                        class="text-decoration-none p-2 ml-0 d-block border-bottom"
                        data-toggle="modal"
                        (click)="editTeam(team, target)"
                        style="border-bottom:0px;"
                        >{{ 'TEAMSPAGE.EDIT_TEAM' | translate }}</a
                      >
                     
                      <a
                        class="text-decoration-none p-2 ml-0 d-block"
                        data-toggle="modal"
                        (click)="deleteTeam(team.teamId)"
                        style="border-bottom:0px;"
                        >{{ 'TEAMSPAGE.DELETE_TEAM' | translate }}</a
                      >
                    </div>
                  </a>
                </div>
                <!-- </div> -->
              </a>
  
              <div
                id="collapseOne-{{ team.teamId }}"
                class="collapse pl-4 border-bottom"
                data-parent="#accordion"
              >
                <p
                  (click)="sortMemberName()"
                  *ngIf="false && members.length > 0"
                  class="small-font-mb cursor-pointer pt-3 mb-0"
                >
                  {{ 'SUBMENU.MEMBERS' | translate }} &ensp;<img class="arrImg"
                    *ngIf="isMemberSort"
                    [src]="isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                  /><img class="arrImg"
                    *ngIf="!isMemberSort"
                    [src]="!isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                    style="transform: rotate(180deg);"
                  />
                </p>
                <div class="row">
                  <div
                    class="col-md-6 py-2 d-block d-md-flex align-items-center justify-content-between w-100 cursor-pointer"
                    
                    *ngFor="let user of members"
                  >
                    <div class="" [routerLink]="['/view-member', user.userId,'team']">
                      <a class="mr-1 text-decoration-none d-block border-0 py-2">
                        <img
                          src="{{user.profilePic}}"
                          width="50px"
                          height="50px"
                          class="borderImage-sm "
                          onerror="this.src='assets/default.jpg'"
                        />
                      </a>
                    </div>
                    <div
                      class=" w-100  d-block d-md-flex justify-content-between align-items-center"
                    >
                      <div class="col-md-8" [routerLink]="['/view-member', user.userId,'team']">
                        <h6 class="card-title mb-1 text-capitalize">
                          {{ user.firstName }} {{ user.lastName }}
                        </h6>
                        <span class="card-text p-0 m-0 ">{{ user.title }}</span>
                        <span *ngIf="user.department && user.title">, </span>
                        <span class="card-text p-0 m-0 "
                          >{{ user.department }}
                        </span>
                      </div>
                     
                      <!-- <a class="btn btn-primary btn-sm text-white" style="color: #ffffff !important;
                      white-space: nowrap;"  *ngIf="isAdmin && !isManger(team.managers,user.userId)" (click)="makeManager(team.teamId,user.userId,true,i)">Make Manager</a>
                      <a class="btn btn-primary btn-sm text-white" style="color: #ffffff !important;
                      white-space: nowrap;"  *ngIf="isAdmin && isManger(team.managers,user.userId)" (click)="makeManager(team.teamId,user.userId,false,i)">Remove Manager</a> -->
                      <!-- <label class="default-color border-0 m-0"  *ngIf="isManger(team.managers,user.userId)">Remove Manager</label> -->
                    </div>
                  </div>
                  <h5
                    class="p-5 text-center w-100"
                    *ngIf="!loading && members.length == 0"
                  >
                    No members are found.
                  </h5>
                </div>
              </div>
          
          </li>
        </ul>
      </ng-container>

      <h5 *ngIf="!loading && internalTeams.length === 0">
        {{ 'TEAMSPAGE.NO_TEAMS_ARE_FOUND' | translate }}
      </h5>
      <div class="pt-5 text-right"  *ngIf="isAdmin">
        <a
          class="btn "
          (click)="openAddTeam('', target)"
          [ngClass]="!toggleAddTeam ? 'theme-btn' : 'btn-white'"
          id="addTeam"
          >{{ 'SUBMENU.ADD_TEAM' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
</div>



<!------------------------------------------------------------------------------------------------------------------------>

<div class="row justify-content-center ">
    <div class="col-md-12  px-0">
      <div class="col-md-12 p-2">
        <div class="card p-md-5 py-5 my-3 ">
        <div class="dropdown p-absolute">
  
        </div>
        <div class="d-flex justify-content-between py-3">
          <p
            (click)="sortTeamName()"
            class="small-font-mb cursor-pointer"
            [ngClass]="{ 'text-gray2': !tNSortClicked }"
          >
            Company ({{teams.length}})&ensp;<img class="arrImg"
              *ngIf="!isTeamNameSort && !nMSortClicked"
              [src]="tNSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
            /><img class="arrImg"
              *ngIf="isTeamNameSort && !nMSortClicked"
              [src]="tNSortClicked ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
              style="transform: rotate(180deg);"
            />
          </p>
          <div class="position-relative col-md-6 ">
            <input
              type="text"
              placeholder="Search Member"
              id="searchMember"
              class="theme-input"
              style="background:transparent;"
              [(ngModel)]="filterText"
              (input)="isFilter=true;memberSearchInTeams($event)"
            />
            <img
              id="blah"
              src="assets/icon_search.svg"
              alt="search"
              class="searchIcon"
              *ngIf="!isFilter"
            />
            <img
              id="blah"
              src="assets/icon_X.png"
              alt="search"
              *ngIf="isFilter"
              (click)="clearFilter()"
              class="searchIcon cursor-pointer"
            />
          </div>
          <p
            (click)="sortNumberMembers()"
            class="small-font-mb cursor-pointer"
            [ngClass]="{ 'text-gray2': !nMSortClicked }"
          >
            {{ 'TEAMSPAGE.NUMBER_OF_MEMBERS' | translate }} &ensp;
          </p>
        </div>
        <ng-container *ngIf="teams.length >= 0">
          <ul class="m-0 p-0 temsBlk-lg" id="accordion2"  #teamsTarget>
            <li class="" *ngFor="let team of teams;let i=index">
              <a
                class="d-sm-flex justify-content-between"
                data-toggle="collapse"
                href="#collapseOne1-{{ team.teamId }}"
                id="collapse-{{ team.teamId }}"
              >
                <p id="team-{{ team.teamId }}"  (click)="getMemberDetails(team)" class="m-0 flex-1">
                  {{team.name}}
                </p>
                
                <div class="text-right">
                 <span (click)="getMemberDetails(team)" style="
                 padding: 24px 0px;
             "> {{
                    'TEAMSPAGE.N_MEMBERS'
                      | translate: { members_count: team.memberIds.length }
                  }}</span>
                  <a
                    data-toggle="dropdown"
                    class="ml-2 d-none"
                    style="border-bottom:0px;"
                    *ngIf="isAdmin || (permissions && permissions.company.create_teams_and_departments == 'true')"
                  >
                    <img
                      id="blah"
                      src="assets/dots.svg"
                      alt="dots"
                      style="transform: rotate(90deg);"
                    />
                    <div
                      class="dropdown-menu dropdown-menu-right dropdown-menu-arrow "
                     
                    >
                      <a
                        class="text-decoration-none p-2 ml-0 d-block border-bottom"
                        data-toggle="modal"
                        (click)="editTeam(team, target)"
                        style="border-bottom:0px;"
                        >{{ 'TEAMSPAGE.EDIT_TEAM' | translate }}</a
                      >
                     
                      <a
                        class="text-decoration-none p-2 ml-0 d-block"
                        data-toggle="modal"
                        (click)="deleteTeam(team.teamId)"
                        style="border-bottom:0px;"
                        >{{ 'TEAMSPAGE.DELETE_TEAM' | translate }}</a
                      >
                    </div>

                  </a>
                </div>
                <!-- </div> -->
              </a>
  
              <div
                id="collapseOne1-{{ team.teamId }}"
                class="collapse pl-4 border-bottom"
                data-parent="#accordion2"
              >
                <p
                  (click)="sortMemberName()"
                  *ngIf="false && members.length > 0"
                  class="small-font-mb cursor-pointer pt-3 mb-0"
                >
                  {{ 'SUBMENU.MEMBERS' | translate }} &ensp;<img class="arrImg"
                    *ngIf="isMemberSort"
                    [src]="isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                  /><img class="arrImg"
                    *ngIf="!isMemberSort"
                    [src]="!isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                    style="transform: rotate(180deg);"
                  />
                </p>
                <div class="row">
                  <div
                    class="col-md-6 py-2 d-block d-md-flex align-items-center justify-content-between w-100 cursor-pointer"
                    
                    *ngFor="let user of members"
                  >
                    <div class="" [routerLink]="['/view-member', user.userId,'team']">
                      <a class="mr-1 text-decoration-none d-block border-0 py-2">
                        <img
                          src="{{user.profilePic}}"
                          width="50px"
                          height="50px"
                          class="borderImage-sm "
                          onerror="this.src='assets/default.jpg'"
                        />
                      </a>
                    </div>
                    <div
                      class=" w-100  d-block d-md-flex justify-content-between align-items-center"
                    >
                      <div class="col-md-8" [routerLink]="['/view-member', user.userId,'team']">
                        <h6 class="card-title mb-1 text-capitalize">
                          {{ user.firstName }} {{ user.lastName }}
                        </h6>
                        <span class="card-text p-0 m-0 ">{{ user.title }}</span>
                        <span *ngIf="user.department && user.title">, </span>
                        <span class="card-text p-0 m-0 "
                          >{{ user.department }}
                        </span>
                      </div>
                     
                      <a class="btn btn-primary btn-sm text-white" style="color: #ffffff !important;
                      white-space: nowrap;"  *ngIf=" !user.contactPresent && userId != user.userId" (click)="connectToMember(team.teamId,user.userId,true,i)">Connect</a>
                        <a class="btn btn-primary btn-sm text-white" style="color: #ffffff !important;
                      white-space: nowrap;"  *ngIf="( user.contactPresent) || userId == user.userId" (click)="connectToMember(team.teamId,user.userId,true,i)">View Profile</a>
                      <!-- <a class="btn btn-primary btn-sm text-white" style="color: #ffffff !important;
                      white-space: nowrap;"  *ngIf="isManger(team.managers,user.userId)" (click)="makeManager(team.teamId,user.userId,false,i)">Remove Manager</a> -->
                    </div>
                  </div>
                  <h5
                    class="p-5 text-center w-100"
                    *ngIf="!loading && members.length == 0"
                  >
                    No members are found.
                  </h5>
                </div>
              </div>
            </li>
          </ul>
        </ng-container>
  
        <h5 *ngIf="!loading && teams.length === 0">
          {{ 'TEAMSPAGE.NO_TEAMS_ARE_FOUND' | translate }}
        </h5>
      
      </div>
    </div>
  </div>
  </div>

<!-------------------------------------------------------------------------------------------------------------------------->
<!-- v -->
<div #target id="addTeamDiv">
  <app-add-team
    [teamUpdate]="teamDetails"
    [isEditOrAdd]="editOrAdd"
    *ngIf="toggleAddTeam"
    (onHide)="closeAddTeam($event)"
  ></app-add-team>
</div>

<!-- The Modal -->
<div class="modal" id="inviteModal">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h5>{{ 'TEAMSPAGE.INVITE_PEOPLE_MSG' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" #closebutton>
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body pt-2 px-5">
        <!--
      <p>Your teammates will get an email that gives them access to your team.</p>
-->
        <div class="row justify-content-center m-0">
          <div class="py-2 d-block w-100">
            <label>{{ 'TEAMSPAGE.INVITE_MEMBERS_MSG' | translate }}</label
            ><br />
            <!-- <select data-placeholder="Choose a Team member..." class="chosen-select" multiple tabindex="4">
            <option value=""></option>
            <option *ngFor="let member of members" [value]="member.id">{{ member.name }}</option>
          </select> -->
            <ngx-select
              name="participants"
              [allowClear]="true"
              [multiple]="true"
              [keepSelectedItems]="false"
              [items]="members"
              optionValueField="id"
              optionTextField="name"
              [disabled]="ngxDisabled"
              [(ngModel)]="ngxMembersValue"
              placeholder="Participants"
              class="theme-select"
            >
              <ng-template
                ngx-select-option
                ngx-select-option-selected
                let-option
                let-text="text"
              >
                <div
                  class="d-flex text-left dropdown-custom align-items-center"
                >
                  <img
                    src="{{ option.data.image }}"
                    class="borderImage border-50"
                  />
                  <div class="ml-2">
                    <p [innerHtml]="text" class="mb-0"></p>
                    <div class="text-gray optionClr">
                      {{ option.data.position }}
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template ngx-select-option-not-found let-input>
                {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
              </ng-template>
            </ngx-select>
          </div>

          <div class="py-2 d-block w-100">
            <label>{{ 'TEAMSPAGE.ADD_TO_TEAM' | translate }}</label
            ><br />
            <select class="form-control theme-select-lg">
              <option *ngFor="let team of teams" [value]="team.teamId">{{
                team.title
              }}</option>
            </select>
          </div>
          <div class="text-center col-md-12 py-4">
            <input
              type="button"
              (click)="inviteMember()"
              name="submit"
              class="theme-btn"
              value="{{ 'TEAMSPAGE.SEND_INVITE' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- The Modal -->
<div class="modal" id="createProfile">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h5>Create profile for team member</h5>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body pt-2  px-5">
        <p>
          Your teammates will get an email that gives them access to your team.
        </p>
        <div class="row justify-content-center m-0">
          <form
            #createProfileForm="ngForm"
            class="w-100"
            (ngSubmit)="createProfileForm.form.valid && profileCreateSend()"
          >
            <div class="py-2 d-block w-100">
              <label>First Name</label><br />
              <input
                type="text"
                name="firstname"
                id="firstname"
                class="theme-input-lg"
                [(ngModel)]="firstname"
                required
                minlength="4"
                #firstname1="ngModel"
              />
              <!-- validation start -->
              <div
                *ngIf="
                  firstname1.invalid &&
                  (firstname1.dirty ||
                    firstname1.touched ||
                    createProfileForm.submitted)
                "
                class="text-danger"
              >
                <div *ngIf="firstname1.errors.required">
                  First Name is required.
                </div>
                <div *ngIf="firstname1.errors.minlength">
                  First Name must be at least 4 characters long.
                </div>
              </div>
              <!-- validation end -->
            </div>
            <div class="py-2 d-block w-100">
              <label>Last Name</label><br />
              <input
                type="text"
                name="lastname"
                id="lastname"
                class="theme-input-lg"
                [(ngModel)]="lastname"
                required
                minlength="4"
                #lastname1="ngModel"
              />
              <!-- validation start -->
              <div
                *ngIf="
                  lastname1.invalid &&
                  (lastname1.dirty ||
                    lastname1.touched ||
                    createProfileForm.submitted)
                "
                class="text-danger"
              >
                <div *ngIf="lastname1.errors.required">
                  Last Name is required.
                </div>
                <div *ngIf="lastname1.errors.minlength">
                  Last Name must be at least 4 characters long.
                </div>
              </div>
              <!-- validation end -->
            </div>
            <div class="py-2 d-block w-100">
              <label>Email</label><br />
              <input
                type="text"
                name="email"
                id="email"
                class="theme-input-lg"
                [(ngModel)]="email"
                required
                email
                #email1="ngModel"
              />
              <!-- validation start -->
              <div
                *ngIf="
                  email1.invalid &&
                  (email1.dirty ||
                    email1.touched ||
                    createProfileForm.submitted)
                "
                class="text-danger"
              >
                <div *ngIf="email1.errors.required">
                  Email is required.
                </div>
                <div *ngIf="email1.errors.email">
                  Email must be valid.
                </div>
              </div>
              <!-- validation end -->
            </div>
            <div class="py-2 d-block w-100">
              <label>Phone number</label><br />
              <input
                type="text"
                name="phonenumber"
                id="phonenumber"
                class="theme-input-lg"
                [(ngModel)]="phonenumber"
                required
                minlength="10"
                maxlength="10"
                #phonenumber1="ngModel"
                pattern="^[0-9]+$"
              />
              <!-- validation start -->
              <div
                *ngIf="
                  phonenumber1.invalid &&
                  (phonenumber1.dirty ||
                    phonenumber1.touched ||
                    createProfileForm.submitted)
                "
                class="text-danger"
              >
                <div *ngIf="phonenumber1.errors.required">
                  Phone number is required.
                </div>
                <div *ngIf="phonenumber1.errors.minlength">
                  Phone number must be 10 digits.
                </div>

                <div *ngIf="phonenumber1.errors.pattern">
                  Phone number should contain only digits
                </div>
              </div>
              <!-- validation end -->
            </div>
            <div class="py-2 d-block w-100">
              <label>Add to team</label><br />
              <select
                class="form-control theme-select-lg"
                [(ngModel)]="selectedTeamCreateProfile"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let team of teams" [value]="team.id">{{
                  team.title
                }}</option>
              </select>
            </div>

            <div class="text-center col-md-12  py-4">
              <input
                type="submit"
                name="submit"
                class="theme-btn"
                value="Create Profile and Send Invitation"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
