import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms'
import { Apollo, QueryRef } from 'apollo-angular'
import { Router, ActivatedRoute, Params } from '@angular/router'


import {
  cartListMutution,createCustomerMutution,updateCartMutution,updateProductMutution
  } from '../../../services/products.gql'
  import { ToastrService } from 'ngx-toastr';

const moment = require('moment-timezone')
@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit {
  customerInfo:any
  products:any = []
  quantity:number =1
  loading:boolean =false
  cartInfo:any
  totalAmount:any = 0
  totalFinalSellingAmount:any = 0
  submitted:boolean =false
  cartProductIds:any = []
  clearCartStatus:boolean = false
  public orderForm: FormGroup
  private query: QueryRef<any>
cartObj:any

  buttonEl: any
  placement = 'bottom-left'
  goldColors:any =[
    "ROSE", "WHITE", "YELLOW","MIXED"
  ]
  paymentTypes:any =[
    {
      id:"CARD",
      name:"Card",
    },
    {
      id:"CHEQUE",
      name:"Cheque",
    },
    {
      id:"CASH",
      name:"Cash",
    },
    {
      id:"CHEQUE_AND_CASH",
      name:"Cheque & Cash",
    },
    {
      id:"A_RECUPERER",
      name:"A Recuperer",
    },
    {
      id:"BANK_TRANSFER",
      name:"Bank Transfer",
    },
  ]
  chequestCount:any =[]
  constructor(private formBuilder: FormBuilder,   private apollo: Apollo,private toastr: ToastrService,private router: Router, private el: ElementRef) {
   
    for(let i=1;i<=10;i++){
      this.chequestCount.push(i)
     }   
      this.orderForm = this.formBuilder.group({
       productCategory: [0],
       firstName: ['',Validators.required],
       lastName: [''],
       email:[''],
       phone: ['',Validators.required],
       description:[''],
       city: [''],
       ringSize: ['',Validators.required],
       goldColor:['',Validators.required],
       quantity: [''],

       actualSellingPrice:[''],
       paymentMethod:[''],
       numberOfCheques:[0],
       chequeDate1:[],
       chequeDate2:[],
       chequeDate3:[],
       chequeDate4:[],
       chequeDate5:[],
       chequeDate6:[],
       chequeDate7:[],
       chequeDate8:[],
       chequeDate9:[],
       chequeDate10:[],
       cashAmount:[''],
       chequeDate1Amount:[''],
       chequeDate2Amount:[''],
       chequeDate3Amount:[''],
       chequeDate4Amount:[''],
       chequeDate5Amount:[''],
       chequeDate6Amount:[''],
       chequeDate7Amount:[''],
       chequeDate8Amount:[''],
       chequeDate9Amount:[''],
       chequeDate10Amount:[''],
       actionType:[''],
       sellingPrice:[''],
       advanceAmount:[''],
       remainingDueAmount:[''],
       additionalDiscount:[''],
       effectiveSalesPrice:[''],
       heldAmount:['0'],
       amountToCollect:['0'],
       timezone: [moment.tz.guess()],
       additionalInformation:['']
   })

   }

  ngOnInit() {
    let localInfo :any = localStorage.getItem("cartArray")
    // if(localInfo){
    //   localInfo  = JSON.parse(localInfo)
    //   let {customer,products} =localInfo[0]
    //   this.customerInfo = customer
    //   this.products = products
    // }
    
    this.getCartDetails()
  }
  removeProduct(i){
    if(confirm("Are you sure do you want to delete this product from cart?")){
      this.products.splice(i,1)
      this.cartProductIds = this.products.map((item)=>item.id)
      this.updateCart2()
      
    }
    
    
  } 
  clearCart(){
    localStorage.removeItem("cartArray")
    this.cartInfo = {}
    this.products = []
    this.totalAmount =0
    this.totalFinalSellingAmount = 0
    this.clearCartStatus = true
    this.updateCart()
  }

  changePaymentMethod(event){
    if(event.target.value!='CHEQUE' && event.target.value!='CHEQUE_AND_CASH'){
      this.orderForm.patchValue({
        numberOfCheques:0
      })
      this.orderForm.patchValue({
        chequeDate1:"",
        chequeDate2:"",
        chequeDate3:"",
        chequeDate4:"",
        chequeDate5:"",
        chequeDate6:"",
        chequeDate7:"",
        chequeDate8:"",
        chequeDate9:"",
        chequeDate10:"",
        cashAmount:[''],
        chequeDate1Amount:"",
        chequeDate2Amount:"",
        chequeDate3Amount:"",
        chequeDate4Amount:"",
        chequeDate5Amount:"",
        chequeDate6Amount:"",
        chequeDate7Amount:"",
        chequeDate8Amount:"",
        chequeDate9Amount:"",
        chequeDate10Amount:"",
      })
    }
     if(event.target.value!='CASH' && event.target.value!='CHEQUE_AND_CASH'){
      this.orderForm.patchValue({
        cashAmount:""
      })
    }

  }

  getCartDetails() {
    this.loading  = true
   
    this.query = this.apollo.watchQuery({
      query:   cartListMutution  ,
      fetchPolicy:"no-cache",
    })

     this.query.valueChanges.subscribe(({ data }: any) => {
        // this.products = data.products
        this.loading = false
        let cartInfo = data.accountInformation.cart
        if(cartInfo && cartInfo != ''){
          cartInfo = cartInfo
          // cartInfo = cartInfo.replace(/'/g, '"')
          cartInfo = decodeURIComponent(cartInfo)
          cartInfo = cartInfo.replace(/###/g, "'")
          cartInfo = JSON.parse(cartInfo)
          this.cartInfo =cartInfo
          this.cartObj  = cartInfo
          this.customerInfo = cartInfo.customer
          this.products = this.cartInfo.products
          let totalAmount = 0
          let totalFinalSellingAmount = 0
          this.cartProductIds = []
          this.products.forEach((element,j) => {
            this.products[j].quantity = this.products[j].quantity ? this.products[j].quantity : 1
            this.products[j].finalPrice = this.products[j].finalPrice ? this.products[j].finalPrice : this.products[j].displayedSellingPrice
            totalAmount = totalAmount+ element.displayedSellingPrice
            totalFinalSellingAmount = totalFinalSellingAmount + parseInt(this.products[j].finalPrice)
            this.cartProductIds.push(element.id)

          });
          this.totalAmount = totalAmount
          this.totalFinalSellingAmount = totalFinalSellingAmount
          let {firstName,lastName,phone,email,city} = this.cartInfo.customer
          let discountAmount = this.f.additionalDiscount.value ? this.f.additionalDiscount.value : 0
          this.orderForm.patchValue({
            firstName:firstName,
            lastName:lastName,
            email:email,
            phone:phone,
            city:city,
            actualSellingPrice:totalFinalSellingAmount,
            effectiveSalesPrice: totalFinalSellingAmount - discountAmount
            
          })
        }
        this.loading = false
    },err=>{
      this.loading = false
    })
  }

  get f () {
    return this.orderForm.controls
  }
  updateCart(type=0){
    this.apollo
    .mutate({
      mutation: updateCartMutution,
      variables: {
        cart:""
      },
      errorPolicy:"all"
    })
    .subscribe(
      ({ data, loading, errors }: any) => {
        this.loading = false
        document.getElementById("cartCount").innerHTML = "0"
        if(type==1){
          this.router.navigate(['/product-enquires']);

        }
        else{
          this.toastr.info("Cart cleared successfully.")
        }
        this.products = []
        this.cartInfo = {}
        this.customerInfo ={}
      },
      error => {
        console.log(error)

        this.loading= false
       
      }
    )
  }


  changeQuantity(product,k){
    if(product.stockQuantity < product.quantity){ 
      alert('The quantity is higher than the maximum allowed quantity of '+ product.stockQuantity)
      if(this.products[k]){
        setTimeout(() => {
          this.products[k].quantity = product.stockQuantity
        }, 1);
      }
    }
    setTimeout(() => {
      let totalAmount = 0
      let totalFinalSellingAmount = 0
      this.cartProductIds = []
      this.products.forEach((element,j) => {
        let quantityPrice = element.displayedSellingPrice * element.quantity
        totalAmount = totalAmount+ quantityPrice
        this.products[j].quantity = element.quantity
        let totalSellingPrice = element.displayedSellingPrice * element.quantity
        this.products[j].finalPrice = totalSellingPrice
        totalFinalSellingAmount = totalFinalSellingAmount + parseInt(this.products[j].finalPrice)
        this.cartProductIds.push(element.id)
      });
      this.totalAmount = totalAmount
      this.totalFinalSellingAmount = totalFinalSellingAmount
      let discountAmount = parseInt(this.f.additionalDiscount.value) ? parseInt(this.f.additionalDiscount.value) : 0

      this.orderForm.patchValue({
        actualSellingPrice:totalFinalSellingAmount,
        effectiveSalesPrice: totalFinalSellingAmount - discountAmount
      })
    },2)
  }
  changePrice(){
    let totalAmount = 0
    let totalFinalSellingAmount:number = 0
    this.products.forEach((element,j) => {
      let quantityPrice = element.displayedSellingPrice * element.quantity
      totalAmount = totalAmount+ quantityPrice
      this.products[j].quantity = element.quantity
      let totalSellingPrice = element.finalPrice 
      // this.products[j].finalPrice = totalSellingPrice
      totalFinalSellingAmount =totalFinalSellingAmount + parseInt(totalSellingPrice)
    });
    let discountAmount = parseInt(this.f.additionalDiscount.value) ? parseInt(this.f.additionalDiscount.value) : 0

    this.totalAmount = totalAmount
    this.totalFinalSellingAmount = totalFinalSellingAmount
    this.orderForm.patchValue({
      actualSellingPrice:totalFinalSellingAmount,
      effectiveSalesPrice: totalFinalSellingAmount - discountAmount
    })
  }
  saveForm () {
    this.submitted = true
    if (this.orderForm.invalid) {   
        return
    }

    const firstName = this.f.firstName.value
    const lastName = this.f.lastName.value
    const email = this.f.email.value
    const phone = this.f.phone.value
    const description = this.f.description.value
    const city = this.f.city.value
    const ringSize = this.f.ringSize.value
    const goldColor = this.f.goldColor.value
    const paymentMethod = this.f.paymentMethod.value
    const additionalDiscount= this.f.additionalDiscount.value.toString()
    const effectiveSalesPrice= this.f.effectiveSalesPrice.value.toString()

    // console.log(this.f.additionalDiscount.value,this.f.effectiveSalesPrice.value)
    const actualSellingPrice = parseFloat(this.f.actualSellingPrice.value)
    const chequeDate1Amount = parseFloat(this.f.chequeDate1Amount.value)
    const chequeDate2Amount = parseFloat(this.f.chequeDate2Amount.value)
    const chequeDate3Amount = parseFloat(this.f.chequeDate3Amount.value)
    const chequeDate4Amount = parseFloat(this.f.chequeDate4Amount.value)
    const chequeDate5Amount = parseFloat(this.f.chequeDate5Amount.value)
    const chequeDate6Amount = parseFloat(this.f.chequeDate6Amount.value)
    const chequeDate7Amount = parseFloat(this.f.chequeDate7Amount.value)
    const chequeDate8Amount = parseFloat(this.f.chequeDate8Amount.value)
    const chequeDate9Amount = parseFloat(this.f.chequeDate9Amount.value)
    const chequeDate10Amount = parseFloat(this.f.chequeDate10Amount.value)
    const actionType = this.f.actionType.value
    const sellingPrice = this.f.sellingPrice.value
    const advanceAmount = parseFloat(this.f.advanceAmount.value)
    const remainingDueAmount = parseFloat(this.f.remainingDueAmount.value)
    const cashAmount = parseFloat(this.f.cashAmount.value)
    const numberOfCheques = parseInt(this.f.numberOfCheques.value)
    const heldAmount = this.f.heldAmount.value ? this.f.heldAmount.value.toString() : "0"
    const amountToCollect = this.f.amountToCollect.value ? this.f.amountToCollect.value.toString() : "0"
    const additionalInformation = this.f.additionalInformation.value ? this.f.additionalInformation.value.toString()  : "" 
    if(this.f.effectiveSalesPrice.value > this.f.actualSellingPrice.value){
      alert("The final selling price should be less than the actual selling price")
      return false
    }
    let chequeDate1 = this.f.chequeDate1.value
    if(chequeDate1){
      
      chequeDate1 = `${chequeDate1.year}-${chequeDate1.month}-${chequeDate1.day}`
        chequeDate1 = moment(chequeDate1,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
        chequeDate1 = moment
      .tz(chequeDate1, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate2 = this.f.chequeDate2.value

    if(chequeDate2){
      chequeDate2 = `${chequeDate2.year}-${chequeDate2.month}-${chequeDate2.day}`

      chequeDate2 = moment(chequeDate2,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')

      chequeDate2 = moment
      .tz(chequeDate2, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate3 = this.f.chequeDate3.value
    if(chequeDate3){
      chequeDate3 = `${chequeDate3.year}-${chequeDate3.month}-${chequeDate3.day}`
      chequeDate3 = moment(chequeDate3,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate3 = moment
      .tz(chequeDate3, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate4 = this.f.chequeDate4.value
    if(chequeDate4){
      chequeDate4 = `${chequeDate4.year}-${chequeDate4.month}-${chequeDate4.day}`
      chequeDate4 = moment(chequeDate4,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate4 = moment
      .tz(chequeDate4, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate5 = this.f.chequeDate5.value
    if(chequeDate5){
      chequeDate5 = `${chequeDate5.year}-${chequeDate5.month}-${chequeDate5.day}`
      chequeDate5 = moment(chequeDate5,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
        chequeDate5 = moment
      .tz(chequeDate5, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate6 = this.f.chequeDate6.value
    if(chequeDate6){
      chequeDate6 = `${chequeDate6.year}-${chequeDate6.month}-${chequeDate6.day}`
      chequeDate6 = moment(chequeDate6,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate6 = moment
      .tz(chequeDate6, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate7 = this.f.chequeDate7.value
    if(chequeDate7){
      chequeDate7 = `${chequeDate7.year}-${chequeDate7.month}-${chequeDate7.day}`
      chequeDate7 = moment(chequeDate7,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate7 = moment
      .tz(chequeDate7, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate8 = this.f.chequeDate8.value
    if(chequeDate8){
      chequeDate8 = `${chequeDate8.year}-${chequeDate8.month}-${chequeDate8.day}`
      chequeDate8 = moment(chequeDate8,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate8 = moment
      .tz(chequeDate8, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate9 = this.f.chequeDate9.value
    if(chequeDate9){
      chequeDate9 = `${chequeDate9.year}-${chequeDate9.month}-${chequeDate9.day}`
      chequeDate9 = moment(chequeDate9,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate9 = moment
      .tz(chequeDate9, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    let chequeDate10 = this.f.chequeDate10.value
    if(chequeDate10){
      chequeDate10 = `${chequeDate10.year}-${chequeDate10.month}-${chequeDate10.day}`
      chequeDate10 = moment(chequeDate10,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate10 = moment
      .tz(chequeDate10, this.f.timezone.value)
      .format('YYYY-MM-DD')
    }
    
    const invalid = [];
    const controls = this.orderForm.controls;

   
    
    let productObj:any ={
      firstName,
      lastName,
      phone,
      email,
      description,
      ringSize,
      city,
      paymentMethod,
      actualSellingPrice,
      chequeDate1Amount,
      chequeDate2Amount,
      chequeDate3Amount,
      chequeDate4Amount,
      chequeDate5Amount,
      chequeDate7Amount,
      chequeDate6Amount,
      chequeDate8Amount,
      chequeDate9Amount,
      chequeDate10Amount,
      actionType,
    sellingPrice,
    advanceAmount,
    remainingDueAmount,
    numberOfCheques,
    cashAmount,
    chequeDate10,
    chequeDate9,
    chequeDate8,
    chequeDate7,
    chequeDate6,
    chequeDate5,
    chequeDate4,
    chequeDate3,
    chequeDate2,
    chequeDate1,
    effectiveSalesPrice,
    additionalDiscount,
    goldColor,
    additionalInformation

      
    }
  
    productObj = Object.entries(productObj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  
    productObj.cashAmount = cashAmount ? cashAmount :0
    productObj.actualSellingPrice = actualSellingPrice ? actualSellingPrice :0
    if(chequeDate1){
      
      productObj.chequeDate1Amount = productObj.chequeDate1Amount ? productObj.chequeDate1Amount :0
    }
    if(chequeDate2){
      
      productObj.chequeDate2Amount = productObj.chequeDate2Amount ? productObj.chequeDate2Amount :0
    }
    if(chequeDate3){
      
      productObj.chequeDate3Amount = productObj.chequeDate3Amount ? productObj.chequeDate3Amount :0
    }
    if(chequeDate4){
      
      productObj.chequeDate4Amount = productObj.chequeDate4Amount ? productObj.chequeDate4Amount :0
    }
    if(chequeDate5){
      
      productObj.chequeDate5Amount = productObj.chequeDate5Amount ? productObj.chequeDate5Amount :0
    }
    if(chequeDate6){
      
      productObj.chequeDate6Amount = productObj.chequeDate6Amount ? productObj.chequeDate6Amount :0
    }
    if(chequeDate7){
      
      productObj.chequeDate7Amount = productObj.chequeDate7Amount ? productObj.chequeDate7Amount :0
    }
    if(chequeDate8){
      
      productObj.chequeDate8Amount = productObj.chequeDate8Amount ? productObj.chequeDate8Amount :0
    }
    if(chequeDate9){
      
      productObj.chequeDate9Amount = productObj.chequeDate9Amount ? productObj.chequeDate9Amount :0
    }
    if(chequeDate10){
      
      productObj.chequeDate10Amount = productObj.chequeDate10Amount ? productObj.chequeDate10Amount :0
    }

   if(heldAmount){
    productObj.heldAmount = heldAmount
   }
   if(amountToCollect){
    productObj.amountToCollect = amountToCollect
   }
// console.log(productObj,'product')
    //  return
      this.updateCustomer(productObj)
    
   
   
   
  }

  updateCustomer (productObj) {
    productObj.productId = this.cartProductIds[0]
    productObj.quantity = this.products[0].quantity
    productObj.description =  ""
    productObj.actionType =  "BUY"
    // productObj.email =  ""
    this.cartProductIds = []
    let quanitiesArray = []
    this.products.forEach((element,j) => {
      let i =0;
      while(i<element.quantity){
        this.cartProductIds.push(element.id)
        i++;
      }
      quanitiesArray.push({
        pid: element.id,
        qty:element.quantity,
        finalSellingPrice : element.finalPrice
      })
    });
    productObj.purchasedProducts = this.cartProductIds.toString()
    productObj.cart = JSON.stringify(quanitiesArray).replace(/"/g, "'");
    productObj.email = productObj.email ? productObj.email : "";
    productObj.lastName = productObj.lastName ? productObj.lastName : "";

    this.loading = true
    this.apollo
      .mutate({
        mutation: createCustomerMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          if(data.createCustomer){
            this.products.forEach(element => {
              this.updateProduct (element)  
            });
            this.clearCartStatus = true

            this.updateCart(1)
          }
          this.toastr.info("order created successfully.")
        },
        error => {
          console.log(error)
          this.loading= false         
        }
      )
  }
  updateProduct (product) {
    this.loading = true
    let productObj:any ={
      stockQuantity: parseInt(product.stockQuantity)-parseInt(product.quantity),
      id: product.id,
      category:product.productCategory.name
    }
    this.apollo
      .mutate({
        mutation: updateProductMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
        },
        error => {
          console.log(error)
          this.loading= false
        }
      )
  }
  updateCart2(){
    if(this.customerInfo && this.customerInfo.phone){
      this.products = this.products.map((item)=>{
        if(item.productCategory && item.productCategory.name){
        item.productCategory.name = item.productCategory.name.replace(/'/g, '###')
       }
        return item
      })
      this.products = Array.from(new Map(this.products.map(e=>[e.id, e])).values());

      this.cartObj = {
        customer:{
          firstName:this.customerInfo.firstName,
          lastName:this.customerInfo.lastName,
          phone:this.customerInfo.phone,
          email:this.customerInfo.email,
          customerId:this.customerInfo.id,
          city:this.customerInfo.city? this.customerInfo.city : ""
        },
        products:this.products
      }
    
    
      let cartObj2 = encodeURIComponent(JSON.stringify(this.cartObj))

    
    // let cartObj2 = JSON.stringify(this.cartObj).replace(/"/g, "'");

    this.apollo
    .mutate({
      mutation: updateCartMutution,
      variables: {
        cart:`${cartObj2}`
      },
      errorPolicy:"all"
    })
    .subscribe(
      ({ data, loading, errors }: any) => {
        this.loading = false
        document.getElementById('cartCount').innerHTML = this.products.length
        
      },
      error => {
        console.log(error)

        this.loading= false
       
      }
    )
    
  }

    
}
  setFinalSellingPrice(){
    let discountAmount = this.f.additionalDiscount.value ? this.f.additionalDiscount.value : 0
    let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value

    this.orderForm.patchValue({
      effectiveSalesPrice : this.f.actualSellingPrice.value - discountAmount,
      amountToCollect:amountToCollect
    })
  }
  updateQuantity(product){
    return product.stockQuantity > product.quantity ? product.quantity: product.stockQuantity
  }
  trackByFn(item) {
    return item;
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  indexTracker(index: number, value: any) {
    return index;
  }
  updateAmoutCollect(){
    if(this.f.heldAmount.value > this.f.actualSellingPrice.value){
      this.orderForm.patchValue({
        heldAmount:0
       })
      alert("Held amount cannot be higher than actual selling price")
      return false
    }
   let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value
   this.orderForm.patchValue({
    amountToCollect:amountToCollect
   })
  }
  checkFinalSellingPrice(){
    if(this.f.effectiveSalesPrice.value > this.f.actualSellingPrice.value){
      alert("The final selling price should be less than the actual selling price")
      this.orderForm.patchValue({
        effectiveSalesPrice: 0
      })
      return false
    }
  }
  ngOnDestroy(){
    if(!this.clearCartStatus){
      this.updateCart2()
    }
  }
}
