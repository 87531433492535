<app-loading *ngIf="loading"></app-loading>

<ul class="navbar-nav ml-auto d-flex align-items-center float-right pr-4 w-100 justify-content-end" >
  <li class="nav-item dropdown d-none no-arrow mx-1 notificationsBlk"> <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-bell fa-fw"></i> <span class="badge-count badge badge-danger badge-counter">{{totalNotifications}}</span> </a>
    <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in py-0 justify-content-between" style="width:200px;">
        <h5 class="dropdown-header"> Notifications </h5> 
        <a class="dropdown-item d-flex align-items-center py-2  " [routerLink]="['/pending-requests']">
             <div class="w-100">
                <div class=" text-gray-500"><span>Contacts </span> <span class="float-right badge badge-pill badge-info">{{notificationsData.contacts}}</span></div> 
            </div>
           </a> 
           <a class="dropdown-item d-flex align-items-center py-2 " [routerLink]="['/meeting-requests']">
           
            <div class="w-100">
                      <div class=" text-gray-500"><span>Meetings </span> <span class="text-white float-right badge badge-pill badge-warning">{{notificationsData.meetings}}</span></div>
                  </div>
              </a>
          <a class="dropdown-item d-flex align-items-center py-2" [routerLink]="['/conference-requests']">
            <div class="w-100">
                <div class=" text-gray-500"><span>Conference </span><span class="float-right badge badge-pill badge-primary">{{notificationsData.conference}}</span></div> 
            </div>
        </a>
         
            
          
       
    </div>
</li>
<li [ngClass]="toggle ? '':'mr-auto' ">
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle "  (click)="toggleAction()">
    <i class="fa fa-bars default-color"></i>
  </button>

</li>
<li>
 
  <a class="dropdown-item d-flex align-items-center py-2" [routerLink]="['/cart-list']">
    <div class="w-100">
        <div class=" text-gray-500">    <i class="fa fa-shopping-cart text-gray" aria-hidden="true" style="    color: #b9b6b6;"></i>
          <span class="float-right badge badge-pill badge-primary " id="cartCount">{{cartCount}}</span></div> 
    </div>
</a>
</li>

  <li
    class="nav-item dropdown"
    
  >
    <div *ngIf="isAuthenticated" class="dropdown">
      <a class=" nav-link p-0 dropdown-toggle m-0" data-toggle="dropdown">
        <img
          src="{{imageUrl}}?x={{random}}"
          onerror="this.src='assets/default.jpg'"
          class="profilePic"
          width="50px"
          height="50px"
        />
        <span class="d-md-inline-block d-none">{{ user.email ? user.email: user.phone  }}</span>
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" [routerLink]="['/member-details']">{{ 'HEADER.DASHBOARD' | translate }}</a>
        <!-- <a class="dropdown-item" [routerLink]="['/settings']">{{ 'HEADER.SETTINGS' | translate }}</a> -->
        <!-- <a class="dropdown-item" href="#">Notifications</a> -->
        <a class="dropdown-item" [routerLink]="['/reset-password']">{{ 'HEADER.RESET_PASSWORD' | translate }}</a>
        <a class="dropdown-item" *ngIf="accountInfo.twoFactor" (click)="disable2Mfa()" >Disable 2Mfa </a>

        <a class="dropdown-item" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</a>
        <form
          id="logout-form"
          action=""
          method="POST"
          style="display: none;"
        ></form>
      </div>
      <!-- Select option for language switching -->
      <div>
      <!-- <select class="dropdown-menu1" [(ngModel)]="selectedLang" (change)="changeLanguage($event)">
          <option *ngFor="let lang of translate.getLangs(); let i = index" [value]="lang">
              {{ lang }}
          </option>
      </select> -->
      <!-- <a class=" nav-link p-0 dropdown-toggle" data-toggle="dropdown">Choose Language</a>
      <div class="dropdown-menu">
        <a *ngFor="let lang of translate.getLangs()" class="dropdown-item" (click)="changeLanguage(lang)">{{ lang }}</a>
      </div> -->
    </div>
    </div>
    <div *ngIf="!isAuthenticated" class="dropdown d-none">
      <a class=" nav-link p-0 dropdown-toggle m-0" data-toggle="dropdown">
        {{ 'HEADER.LOGIN' | translate }}
      </a>
      <div class="dropdown-menu d-none">
        <form class="form dropdown-item" [formGroup]="loginForm" (ngSubmit)="getLoginDetails()">
          <h3 class="my-3 loginCls">Login</h3>
          <h6 *ngIf="!isAuthenticated" style="color:red;">{{ errorMessage }}</h6>
          <div class="loginBlock">
            <div class="col-md-12 px-0">
              <input
                class="theme-input"
                placeholder="Enter Email "
                autofocus=""
                autocomplete="off"
                name="email"
                type="text"
                formControlName="loginEmail"
              />
              <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)">
                <p style="color:red;" *ngIf="loginEmail.errors.required">
                  Email is required.
                </p>
                <p style="color:red;" *ngIf="loginEmail.errors.email">
                  Email is not valid.
                </p>
              </div>
            </div>

            <div class="col-md-12 pt-3 px-0">
              <input
                placeholder="Password"
                class="theme-input"
                name="password"
                type="password"
                formControlName="loginPassword"
              />
            <div *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)">
              <p style="color:red;" *ngIf="loginPassword.errors.required">
                Password is required.
              </p>
              <p style="color:red;" *ngIf="loginPassword.errors.minlength">
                minimum be 5 characters.
              </p>
            </div>
            </div>
            <div class="text-center col-md-12 pt-3">
              <input
                class="theme-btn"
                name="submit"
                type="submit"
                value="Login"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="dropdown">
        <a class=" nav-link p-0 dropdown-toggle" data-toggle="dropdown">
          <img
            src="assets/profile.png"
            onerror="this.src='images/profile.png'"
            class="profilePic"
            width="50px"
            height="50px"
          />
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="members.php">Dashboard</a>
          <a class="dropdown-item" href="settings.php">Settings</a>
          <a class="dropdown-item" href="notifications.php"
            >Notifications</a
          >
          <a class="dropdown-item" href="#">Logout</a>
          <form
            id="logout-form"
            action=""
            method="POST"
            style="display: none;"
          ></form>
        </div>
      </div> -->
  </li>
  </ul>
