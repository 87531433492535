<!-- <app-loading></app-loading> -->
<app-header *ngIf="!authicatedUser"></app-header> 
<router-outlet></router-outlet>
<!-- <ng-select [items]="items"
           bindLabel="name"
           autofocus
           bindValue="id"
           [(ngModel)]="selected">
</ng-select>            


-->
<app-footer *ngIf="!authicatedUser"></app-footer>

  
