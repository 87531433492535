import { createFeatureSelector } from '@ngrx/store';
import * as memberAuth from './reducers/member.auth.reducers';
import *  as  profileReducer from './reducers/profile.reducers';

export interface AppState {
  memberAuthState: memberAuth.State;
}

export const reducers = {
  memberAuth: memberAuth.reducer,
  profilePic : profileReducer.reducer
};

export const selectMemberAuth = createFeatureSelector<AppState>('memberAuth');
export const profileState = createFeatureSelector<AppState>('profileReducer');

