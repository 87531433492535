import { Component, OnInit,HostListener } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router'
import {environment} from "../../../../../environments/environment"
import { accountInfo,updateZoomMutution,updateWebexMutution} from '../../../services/user.gql'
import { ToastrService } from 'ngx-toastr'
// import { UserAgentApplication } from 'msal'
import { SortablejsOptions } from 'ngx-sortablejs';
import { HelperService } from '../../../services/helper.service'


@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  public zoomConnectStatus:any = (localStorage.getItem("oauth_access_token") && localStorage.getItem("oauth_access_token") != "undefined")  ? true : false
  redirectionURL:any
  newwindow:any
  private query: QueryRef<any>
  private query$ : any
  loading:any= false
  webexConnectStatus:boolean=false
 
  constructor(private activatedRoute: ActivatedRoute,private http: HttpClient,public router: Router, private apollo: Apollo,private toastr: ToastrService,private helperService:HelperService) {
    this.redirectionURL = environment.OUTLOOK_REDIRECT_URL
    
  }
  ngOnInit() {
    // this.codeClick()

    this.query = this.apollo.watchQuery({
      query: accountInfo,
      variables: {},
      fetchPolicy:"network-only"
    })
    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      if(data.accountInformation)
      {
        let {zoomAccessToken,zoomAuthorizationToken,zoomRefreshToken,webexAccessToken} = data.accountInformation
        if(zoomAccessToken)
        {
          localStorage.setItem("oauth_access_token",zoomAccessToken)
          localStorage.setItem("oauth_refresh_token",zoomRefreshToken)
        }
        if(webexAccessToken)
        {
          this.webexConnectStatus= true
        }
      }
      // console.log(data.accountInformation)
    })
    this.activatedRoute.queryParams.subscribe(params => {
    
      let parm = params.code
     
      if (params.code) {

        if(params.state=="3")
        {
          this.getWebexAccessToken(params.code)
        }
        else if(!localStorage.getItem("oauth_access_token") || localStorage.getItem("oauth_access_token") == "undefined" )
        {
          this.getAccessToken(params.code);
        }
        // else{
        //     this.getOutlookAccessToken(params.code);
        // }
          
      }
    });
  }
  

  getAccessToken(code: string) {
      const params = [
        `code=${code}`
    ];
    // window.location.href = 'https://127.0.0.1/bitbucketworkspace/staytouch-repos/staytouch/oauth/callback.php?' + params.join('&');
    //https://api.zoom.us/v2/users/${userId}/meetings
    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('redirectUrl', environment.ZOOM_REDIRECT_URL)
      //getOauthToken
      this.http.post(`${environment.ZOOM_URL}/C46C54AD376BCE9786A2AF799841AD0B9DF982C4`, payload, {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
          "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
      }
    }).subscribe((response:any) => {

        if(response.access_token)
        { 
          // alert()
          let {expires_in, access_token,refresh_token}:any = response
          this.zoomConnectStatus = true
          this.saveToken(access_token,refresh_token,'zoom')
          localStorage.setItem("oauth_access_token",access_token)
          localStorage.setItem("oauth__expires_in",expires_in)
          localStorage.setItem("oauth_refresh_token",refresh_token)
          if(localStorage.getItem("zoomMeeting")=='yes')
          {
            this.router.navigate(['/create-meeting'])
            localStorage.removeItem("zoomMeeting")
          }
        }
        else{
          alert("Unable to connect. Please try again later.")
        }
        
          // this.oauthResponse = response;
      });
  }

 
  saveToken(access_token,refresh_token,type){
    let mutationName = updateWebexMutution
    if(type=='zoom')
    {
      mutationName = updateZoomMutution
    }
    
    this.apollo
        .mutate({
          mutation: mutationName,
          variables: {
            refreshToken: refresh_token,
            accessToken:access_token
          }
        })
        .subscribe(({ data }: any) => {
        console.log(data)
      })
  }




  disconnect(type){
    if(type=='zoom')
    {
      localStorage.removeItem("oauth_access_token")
      localStorage.removeItem("oauth__expires_in")
      localStorage.removeItem("oauth_refresh_token")
     
      this.zoomConnectStatus = false
      this.toastr.info("Disconnected to zoom account.")
    }
    else{
      this.webexConnectStatus = false
      this.toastr.info("Disconnected to webex account.")
    }
    this.saveToken('','',type)
  }

  setLoginType(type) {
    localStorage.setItem("oauthType",type)
  }

  codeClick() {
    const appClientId = "C5f48658f6d8c1092b83f89733d4a6543fbb027371a491c1c486a6cd9ffe481ae"
    const appRedirectUri = "https://localhost:4200/zoom-connect"
    //Build the request URL.  The base URL and next 4 items are typically always the same for Webex Teams web apps
    var requestUrl = 'https://webexapis.com/v1/authorize?' + //Webex Teams OAuth2 base URL
      'response_type=code&'+'scope=spark-compliance%3Amemberships_read%20spark-admin%3Aresource_groups_read%20meeting%3Arecordings_read%20spark%3Aall%20meeting%3Apreferences_write%20spark-admin%3Apeople_write%20spark-admin%3Aorganizations_read%20spark-admin%3Aplaces_read%20meeting%3Aschedules_write%20spark-compliance%3Ateam_memberships_read%20spark-compliance%3Ateam_memberships_write%20spark-admin%3Adevices_read%20spark-admin%3Ahybrid_clusters_read%20spark-compliance%3Amessages_read%20spark-admin%3Adevices_write%20meeting%3Aschedules_read%20spark-compliance%3Amemberships_write%20identity%3Aplaceonetimepassword_create%20spark-admin%3Aroles_read%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20spark-admin%3Aresource_group_memberships_read%20spark-compliance%3Aevents_read%20spark-admin%3Aresource_group_memberships_write%20spark-compliance%3Arooms_read%20spark-admin%3Acall_qualities_read%20spark-compliance%3Amessages_write%20spark%3Akms%20spark-admin%3Ahybrid_connectors_read%20audit%3Aevents_read%20spark-compliance%3Ateams_read%20spark-admin%3Aplaces_write%20spark-admin%3Alicenses_read%20spark-admin%3Apeople_read'+'&state=3'  + '&' +	// Random string for OAuth2 nonce replay protection
      'client_id=' + encodeURIComponent(appClientId) + '&' + // The custom app Client ID
      'redirect_uri=' + encodeURIComponent(environment.ZOOM_REDIRECT_URL); // The custom app's Redirect URI
      window.location.href = requestUrl; // Redirect the browser to the OAuth2 kickoff URL
  }
  getWebexAccessToken(code: string) {
  const payload = new HttpParams()
    .append('grant_type', 'authorization_code')
    .append('code', code)
    .append('redirectUrl', environment.ZOOM_REDIRECT_URL)
    //getWebexgOauthToken
    this.http.post(`${environment.ZOOM_URL}/C07EAC04FB268E4C3F23B90093C3643B8412214C`, payload, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
        "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
    }
  }).subscribe((response:any) => {
      if(response.access_token)
      { 
        // alert()
        let  {access_token,refresh_token} = response
        this.webexConnectStatus = true
        this.saveToken(access_token,refresh_token,'webex')
        if(localStorage.getItem("zoomMeeting")=='yes')
          {
            this.router.navigate(['/create-meeting'])
            localStorage.removeItem("zoomMeeting")
          }
      }
      else{
        alert("Unable to connect. Please try again later.")
      }
      
        // this.oauthResponse = response;
    });
}
}
