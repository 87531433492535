<div class="grayBg container-cs aboutBlk">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center"> {{ 'HEADER.LOGIN' | translate }}</h5>
          <div *ngIf="errorMessage">
            <div class="text-danger p-3" role="alert">
              {{errorMessage}}
            </div>
          </div>
          <form class="form-signin py-3" [formGroup]="loginForm" (ngSubmit)="getLoginDetails()">
          <div *ngIf="withEmail">
            <div class="form-label-group1">
              <input type="email" id="inputEmail" formControlName="loginEmail" class="form-control custominp" placeholder="{{ 'HEADER.EMAIL_ADDRESS' | translate }}" >
              <!-- <label for="inputEmail">Email address</label> -->
            </div>
            <div *ngIf="submitted && loginEmail.value==''" class="alert alert-danger">
              Email is required.
            </div>
            <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)"
                class="alert alert-danger">
              <div *ngIf="loginEmail.errors.required">
                Email is required.
              </div>
              <div *ngIf="loginEmail.errors.email">
                Email is not valid.
              </div>
            </div>
          </div>

              <div class="form-label-group1" *ngIf="!withEmail">
                <ngx-intl-tel-input 
                [cssClass]="'form-control custominp'" 
                [preferredCountries]="['us', 'gb']" 
                [enablePlaceholder]="false"
                [enableAutoCountrySelect]="false"
                name="loginPhone"
                id="loginPhone" 
                [searchCountryFlag]="true"
                [separateDialCode]="'false'"
                [maxLength]="15"
                formControlName="loginPhone">
              </ngx-intl-tel-input>
              </div>
              <div >
                <div class="alert alert-danger" *ngIf="submitted && loginPhone.value && loginPhone.value.number.length < 10">
                  Phone number minimum length should be 10 characters
                </div>
                <div class="alert alert-danger" *ngIf="submitted && loginPhone.value && loginPhone.value.number.length >15">
                  Phone number maximum length should be 15 characters
                </div>
            </div>
            <div class="form-label-group1 py-3">
              <input type="password" id="inputPassword" formControlName="loginPassword" class="form-control custominp" placeholder="{{ 'HEADER.PASSWORD' | translate }}" appAppPassword  autocomplete="new-password"  />
              <!-- <label for="inputPassword">Password</label> -->
            </div>
            <div *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)"
                class="alert alert-danger">
  
              <div *ngIf="loginPassword.errors.required">
                Password is required.
              </div>
              <div *ngIf="loginPassword.errors.minlength">
                Password must be minimum 5 characters.
              </div>
            </div>
            <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" [disabled]="!loginForm.valid">{{ 'HEADER.SIGNIN' | translate }}</button>
            
            
          </form>
        </div>
      </div>
    </div>
  </div>
  
