<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
   <div class="col-md-12  px-0">
  
      <div class="col-md-12 p-2">
         <div class="card py-3 ">
        
<div class="px-4">
            <div class="row   pb-1 pt-2">
               <!-- Types-->
               <!-- Types-->
               <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
                  <div class="d-flex justify-content-between w-100" >
                     <h5 class="col-md-6 px-0 mbfont ">Search Products</h5>
                        <select (change)="selectAction($event,memberModal)" class="mbnonels rounded-0 theme-select col-md-3 " *ngIf="showBlock" [(ngModel)]="selecteAction" >
                            <option value="0">Select Action</option>
                        
                           <option value="1" >Add to cart</option>
                           <option value="6" >Cancel</option>
                        </select>
                  </div>
                  <div class="dropdown col-md-12 pt-3 pl-0 cust-theme">
                  
                     <div class="d-flex align-items-end flex-md-nowrap flex-wrap">
                       
                        <ng-select [items]="columnType"
            bindLabel="name"
            bindValue="filterName"
            class="theme-select pb-1 mb-2 mb-md-0"
            [clearable]="false"
            [(ngModel)]="filterColumn"
            placeholder ="Search or Enter Filter name"
            (change)="filterProducts()"
            >
            </ng-select>

                        <div  class="ml-md-2 ml-0 col-md-4 pb-0   mb-md-0 px-0 px-md-2 col-7"  >
                           <input type="text" class="theme-input" placeholder="Column Value" [(ngModel)]="filterColumnValue" (input)="filterProducts()" />
                        </div>
                        <div class=" px-0 col-md-3 pb-0  mb-md-0 col-5 thembtnsm">
                           <a (click)="clearFilterData()" class="theme-btn">Clear </a>
                        </div>

                     </div>
                  </div>

                    


               </div>
             
            </div>
            
            </div>
            <div class="d-block text-center pt-3 mbshowcls" *ngIf="products && products.length>0"  >
               <a class="theme-btn mx-1" (click)="addToCart()">Add to cart</a>
               <a class="theme-btn mx-1" (click)="showCheckboxes=false;resetProducts()">Cancel</a>
            </div>
                            <!-- Content Row -->
                            <h5 class="col-md-12 px-0 text-center py-2 pt-5" *ngIf="products.length == 0 && !filterColumnValue" >Select products and add to cart</h5>
                            <h5 class="col-md-12 px-0 text-center py-2 pt-5" *ngIf="!loading && products.length == 0 && filterColumnValue" >No data found.</h5>
        
                            <div class="row col-md-12 px-0  mx-0 h-100" *ngIf="products && products.length>0">
                
                  <div class="col-md-12  col-lg-12 pt-3">
                    
                     <div class="container-fluid">
                      
                      
                        
                        

                          <!-----------List View-------------------->
                        <div class="row"  >
                          
                           <div class="col-md-12 table-responsive px-0">
                              <table class="table table-hover js-basic-example dataTable table-custom m-b-0">

                                 <thead >
                                    <tr >
                                      
                                       <th  style="vertical-align: middle;">
                                          <div class="form-check pb-3">
                                             <label class="form-check-label">

                                               <input (change)="checkUncheckAll(!selectAll)" [checked]="selectAll"  type="checkbox" class="form-check-input" value="">
                                             </label>
                                           </div>
                                       </th>
                                       <th >Image
                                          <div class="text-right">
                                             <i style="visibility: hidden;" class="fa fa-sort"
                                             
                                             ></i>
                                             </div>

                                       </th>
                                       <th *ngFor="let column of theadArray" class="cursor-pointer"  (click)="sortBy(column)">
                                         
                                          {{column.name}}
                                          <div class="text-right">
                                             <i class="fa fa-sort-down"
                                             [ngClass]="{ 'fa-sort-up': column.sortType == 'ASC','fa-sort-down': column.sortType == 'DESC',
                                             'fa-sort': column.sortType == ''
                                           }"
                                             ></i>
                                          </div>
                                         
                                       </th>
                                    
                                    </tr>
                                 </thead>
                                 <tbody >
                                    <tr class="cursor-pointer"   *ngFor="let product of products| paginate: { itemsPerPage: itemsPerPage , currentPage: page, totalItems: totalItems };let i=index  ">

                                       <td style="vertical-align: initial;">
                                          <div class="form-check">
                                             <label class="form-check-label">
                                               <input   (change)="selectCheckbox($event,product,i)" [(ngModel)]="product.isSelected" type="checkbox" class="form-check-input" value="">
                                             </label>
                                           </div>
                                       </td>
                                       <td  
                                       
                                       style="width: 80px;" (click)="productDetails(product.id,page)">
                                       <img  width="70px" style="object-fit: contain;" height="auto" onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;object-fit: contain;'" class="img-fluid1 mx-auto d-block img-p" src="{{product.photos[0] && product.photos[0].signedGetURL}}" alt=""></td>
                                       <td  (click)="productDetails(product.id,page)" > {{product.productCategory.name}}</td>
                                       <!-- <td  [routerLink]="['/product-details',product.id]">
                                          <div  class="text-ecllipse" style="max-width: 200px;">
                                             {{product.description}} </div>
                                       </td> -->
                                       <td  class="text-capitalize" (click)="productDetails(product.id,page)"> {{product.productName}}
                                          
                                       </td>
                                       <td  (click)="productDetails(product.id,page)">{{product.stockQuantity}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.tajNumber}}</td>
                                       <!-- <td  (click)="productDetails(product.id,page)">{{product.goldColor}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.goldWeight}}</td>
                                       <td  (click)="productDetails(product.id,page)">{{product.diamondWeight}}</td> -->
                                       <td  (click)="productDetails(product.id,page)">{{roundToNear(product.displayedSellingPrice)}}</td>
                                    

                                      
                                    </tr>
                                    
                                    <!---->
                                 </tbody>
                                 </table>
                        </div>
                     </div>
                     <h5 class="text-center" *ngIf="!loading && products.length ==0">No products data.</h5>
                        <!-----------List View-------------------->
                        <div class="row sorting mb-5 mt-5 d-none">
                           <div class="col-12">
                     
                              <div class="btn-group float-md-right ml-3">
                                 <button type="button" class="btn btn-lg btn-light"> <span class="fa fa-arrow-left"></span> </button>
                                 <button type="button" class="btn btn-lg btn-light"> <span class="fa fa-arrow-right"></span> </button>
                              </div>
                              <div class="dropdown float-md-right">
                                 <label class="mr-2">View:</label>
                                 <a class="btn btn-light btn-lg dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">12 <span class="caret"></span></a>
                                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#">12</a>
                                    <a class="dropdown-item" href="#">24</a>
                                    <a class="dropdown-item" href="#">48</a>
                                    <a class="dropdown-item" href="#">96</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="has-text-centered text-center pt-4" *ngIf="products.length >0">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>

                    </div>
                  </div>
                
               </div>
               <!-- Content Row -->
            <!-- /.container-fluid -->



                  <div class="text-center" *ngIf="products && products.length > 0">
                     <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
                  </div>
                  <div id="print-section"></div>
                  <div class="row justify-content-center m-0 d-none" id="data" *ngIf="selectProduct">
                      <qrcode [qrdata]="selectedLink" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                  </div>

          </div>
      </div>
   </div>
</div>



<ng-template #memberModal let-modal>
   <div class="">
     <div class="modal-content p-3">
       <!-- Modal Header -->
       <div class="modal-header border-0 p-0 grid-st">
         <p id="modal-basic-title" class="text-uppercase font-weight-bold text-center">
           Products Access 
         </p>
            
         <span aria-hidden="true" class="cursor-pointer text-right default-color
         " (click)="modal.dismiss(0)"><img src="assets/icon_X.svg" /></span>

       </div>
       <!-- Modal body -->
       <div class="modal-body pt-2">
        
         <form class="row justify-content-center m-0"   
       >
         <div class="w-100">
            <label>Select Action</label>
            <select  class="theme-select" name="accessValue" [(ngModel)]="accessValue">
               <option value="true">Products assign to members</option>
               <option value="false">Cancel access to members</option>
            </select>
         </div>

           <div class="w-100 pt-3 product-select">
            <label class="pb-2">Select Members</label>

            <ngx-select
            name="users"
            [allowClear]="true"
            [multiple]="true"
            [keepSelectedItems]="false"
            [items]="members"
            optionValueField="userId"
            optionTextField="firstName"
            [(ngModel)]="users"
            placeholder="+ Add Members"
            class="theme-select team-select "
          >
            <ng-template
              ngx-select-option
              ngx-select-option-selected
              let-option
              let-text="text"
            >
              <div class="d-flex text-left dropdown-custom">
                <img
                  src="assets/default.jpg"
                  class="borderImage1 border-501"
                  height="25px"
                  width="auto"
                />
                <div>
                  <p class="mb-0">{{option.data.firstName}} {{option.data.lastName}}</p>
                </div>
              </div>
            </ng-template>

            <ng-template ngx-select-option-not-found let-input>
              {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
            </ng-template>
          </ngx-select>
   
 
               <div class="text-center pt-4">
                 <a
                 class="theme-btn-lg"
                 (click)="modal.close(users)"
               >
                Save
               </a>
               </div>
           </div>
         </form>
       </div>
     </div>
   </div>
 </ng-template>

 <app-product-qr-code-generator *ngIf="isQROpen" [id]="selectProduct.id"  ></app-product-qr-code-generator>
