import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, EMPTY } from 'rxjs';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';

import { MemberAuthService } from '../../services/member-auth.service';
import {
  AuthActionTypes,
  MemberLogIn, MemberLogInSuccess, MemberLogInFailure, MemberLogOut, MemberStatus
} from '../actions/member.auth.actions';

// import * as CryptoJS from 'crypto-js';  
import { environment} from '../../../../environments/environment'
var CryptoJS = require("crypto-js");


@Injectable()
export class MemberAuthEffects {

  constructor(
    private actions: Actions,
    private authService: MemberAuthService,
    private router: Router,
  ) {}

  @Effect()
  Login: Observable<any> = this.actions.pipe(ofType(AuthActionTypes.MEMBER_LOGIN),
      map((action: MemberLogIn) => action.payload),
      switchMap(payload => {
        return this.authService.logIn(payload.email, payload.password,payload.phone)
        .pipe(
          map((user) => {
            let obj1 = {...payload,...user}
            obj1.token = user.authorizationToken
            return new MemberLogInSuccess(obj1);
            
          }),
          catchError((error) => {
            return of(new MemberLogInFailure({ error: error }));
          }));
    }));
 
  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.MEMBER_LOGIN_SUCCESS),
    tap((user) => {
      // alert()
      // console.log("----------------------------------------------------",user.payload.tempInfo,'--------------------------------')
      let enPassword = this.cryptKey(user.payload.tempInfo)
     // localStorage.setItem('userInfo',JSON.stringify(user))
      localStorage.setItem('token', user.payload.token);
      localStorage.setItem('email', user.payload.email);
      localStorage.setItem('phone', user.payload.phone);
      localStorage.setItem('role', user.payload.role);
      // localStorage.setItem('tempInfo', user.payload.tempInfo);
      localStorage.setItem('tempInfo', enPassword);
      //localStorage.setItem('password', user.payload.password);
      localStorage.setItem('b2bPhoneVerified', user.payload.b2bPhoneVerified);
      localStorage.setItem('isTempPassword',user.payload.isTempPassword)
      // localStorage.setItem('id', user.payload.id);
      // this.router.navigateByUrl('/');
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.MEMBER_LOGIN_FAILURE),
      map((user) => {
      // console.log(user)
      return new MemberLogInFailure(user);
		//console.log(user)
    }),
  );

  @Effect()
  Status: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.MEMBER_STATUS),
    map((user) => {
      if (localStorage.getItem('token') != null ) {
        let {token,lang,tempInfo,email,role,phone,b2bPhoneVerified,isTempPassword} = localStorage
        tempInfo = this.cryptDEKey(tempInfo)
        return new MemberLogInSuccess({
          token,lang,tempInfo,email,role,phone,b2bPhoneVerified,isTempPassword
        }
        );
      }
    }),
    filter(action => !!action)
  );

  @Effect({ dispatch: false })
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.MEMBER_LOGOUT),
    tap((user) => {
      localStorage.clear();
    })
  );

   cryptKey = (password) =>{
     
    var data = password
    // console.log('passowrd saving', password)
    // Encrypt
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();
     return CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretkey).toString();
  }
   cryptDEKey = (ciphertext) =>{
   		const bytes= CryptoJS.AES.decrypt(ciphertext, environment.secretkey);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

}
