import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { Apollo, QueryRef } from 'apollo-angular'
import { TranslateService } from '@ngx-translate/core'
import {
customersQuery,updateCustomerMutution, createCustomerMutution
} from '../../../services/products.gql'
import { HelperService } from '../../../services/helper.service'
const moment = require('moment-timezone')
@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

 
  categoriesList:any =[]
  public members: any
  private query: QueryRef<any>
  private query2: QueryRef<any>
  permissionsRes:any ={}
  submitted:boolean=false;
  loading:boolean = false
  buttonEl: any
  placement = 'bottom-left'
  public orderForm: FormGroup
  customerId:any
  variantImages:any =[]
  selectedVarientIndexForImages:any=0
  customerInfo:any = {}
  page:any =1
  type:any=1
  p:any
  role:any = ''
  chequestCount:any =[]
  searchText:any =""
  filterByColumn:any
  filterColumnValue:any
  phone:any
  searchKey:any
  constructor(
    private apollo: Apollo,
    private translate: TranslateService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private toastr: ToastrService,private router: Router,private helperService:HelperService,  private el: ElementRef
    ) { 
      for(let i=1;i<=10;i++){
       this.chequestCount.push(i)
      }   
       this.orderForm = this.formBuilder.group({
        productCategory: [0],
        firstName: [''],
        lastName: [''],
        email:['' ],
        phone: [''],
        description:[''],
        city: [''],
        ringSize: [''],
        quantity: [''],
        actualSellingPrice:[''],
        paymentMethod:[0],
        numberOfCheques:[0],
        actionType:[''],
        sellingPrice:[''],
        advanceAmount:[''],
        remainingDueAmount:[''],
        timezone: [moment.tz.guess()],       
      })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {filterByColumn,filterColumnValue,searchText,phone,searchKey} = this.router.getCurrentNavigation().extras.state
      if(searchText){
        this.searchText = searchText
      }
      if(filterByColumn && filterColumnValue){
        this.filterByColumn = filterByColumn
        this.filterColumnValue = filterColumnValue
      }
      if(phone){
        this.phone = phone
      }
      if(searchKey){
        this.searchKey= searchKey
      }
      this.orderForm.patchValue({
        phone:this.phone
      })
    }
   }

  ngOnInit() {
  
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.customerId = params.id
      }
      if(params.page){
        this.page = params.page
      }

    })
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      this.role = data.accountInformation.role 
      /*if(this.role == 'SALES'){
        this.router.navigate(['/products',this.page], {
          state: {
            searchText:this.searchText,
            filterByColumn:this.filterByColumn,
            filterColumnValue : this.filterColumnValue,
           
          }
        })
      }*/
    })
   
  }

  saveForm () {
    this.submitted = true
    const firstName = this.f.firstName.value
    const lastName = this.f.lastName.value
    const email = this.f.email.value
    const phone = this.f.phone.value
    const city = this.f.city.value
    const invalid = [];
    const controls = this.orderForm.controls;

    for (const key of Object.keys(this.orderForm.controls)) {
		if (this.orderForm.controls[key].invalid) {
			const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
			// invalidControl.focus();
			// console.log(key,'key')
			// break;
		 }
	}
    
    let productObj:any ={
      firstName,
      lastName,
      phone,
      email,
      city,
      quantity:0
    }
    this.createCustomer(productObj)
  }

  createCustomer (productObj) {
    this.loading = true
    productObj.productId =0
    productObj.description = ""
    this.apollo
      .mutate({
        mutation: createCustomerMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          this.toastr.info("Customer created successfully.")
          this.router.navigate(['/search'], {
            state: {
              phone:productObj.phone,
              searchKey:this.searchKey
             
            }
          })
          
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }


  get f () {
    return this.orderForm.controls
  }


  setChequeDate(date,name){
    date = moment(date)
    this.orderForm.patchValue({
      [name]: {
      year: parseInt(date.format('YYYY')),
      month: parseInt(date.format('M')),
      day: parseInt(date.format('D'))
      }
    })
  }

  counter(i: number) {
    return new Array(i);
  }

  
  gotoOrders(){
    this.router.navigate(['/search'])
  }

}
