<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
   <div class="col-md-12  px-0">
      <div class="col-md-12 p-2">
         <div class="card p-md-1 py-3 my-3 ">
            <div class="py-5">
               <!-- Content Row -->
               <div class="row col-md-12 px-0  mx-0 h-100">
                  <div class="col-md-12  col-lg-12">
                     <div class="container-fluid">
                        <!-----------List View-------------------->
                        <div class="row" >
                           <div class="col-md-12 table-responsive">
                              <table class="table table-hover js-basic-example dataTable table-custom m-b-0">
                                 <thead >
                                    <tr >
                                       <th *ngIf="showCheckboxes" >
                                          <div class="form-check pb-3">
                                             <label class="form-check-label">
                                             <input (change)="checkUncheckAll(!selectAll)" [checked]="selectAll"  type="checkbox" class="form-check-input" value="">
                                             </label>
                                          </div>
                                       </th>
                                       <th >Image</th>
                                       <th >Category</th>
                                       <th > Name</th>
                                       <th >Inventory </th>
                                       <th > Taj number</th>
                                       <th  >Gold color </th>
                                       <th  >Gold weight<br/>(grams) </th>
                                       <th >Diamond weight <br/>(ct) </th>
                                       <th  width="120px"> Displayed selling price (MAD)</th>
                                    </tr>
                                 </thead>
                                 <tbody >
                                    <tr class="cursor-pointer"  *ngFor="let product of products| paginate: { itemsPerPage: itemsPerPage , currentPage: page, totalItems: totalItems };let i=index  ">
                                       <td *ngIf="showCheckboxes">
                                          <div class="form-check">
                                             <label class="form-check-label">
                                             <input   (change)="selectCheckbox($event,product,i)" [(ngModel)]="product.isSelected" type="checkbox" class="form-check-input" value="">
                                             </label>
                                          </div>
                                       </td>
                                       <td  
                                          
                                          style="width: 80px;">
                                          <img  width="70px" style="object-fit: contain;" height="auto" onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;object-fit: contain;'" class="img-fluid1 mx-auto d-block img-p" src="{{product.photos[0] && product.photos[0].signedGetURL}}" alt="">
                                       </td>
                                       <td > {{product.productCategory.name}}</td>
                                       <td class="text-capitalize"> {{product.productName}}</td>
                                       <td>{{product.stockQuantity}}</td>
                                       <td>{{product.tajNumber}}</td>
                                       <td>{{product.goldColor}}</td>
                                       <td>{{product.goldWeight}}</td>
                                       <td>{{product.diamondWeight}}</td>
                                       <td>{{roundToNear(product.displayedSellingPrice)}}</td>
                                    </tr>
                                    <!---->
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <h5 class="text-center" *ngIf="!loading && products.length ==0">No products data.</h5>
                        <!-----------List View-------------------->
                        
                     </div>
                     <div class="has-text-centered text-center pt-4" *ngIf="products.length >0">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                     </div>
                  </div>
               </div>
               <!-- Content Row -->
               <!-- /.container-fluid -->
               <div class="text-center">
                  <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
               </div>
               <div id="print-section"></div>
               <div class="row justify-content-center m-0 d-none" id="data" *ngIf="selectProduct">
                  <qrcode [qrdata]="selectedLink" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<app-product-qr-code-generator *ngIf="isQROpen" [id]="selectProduct.id"  ></app-product-qr-code-generator>