import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import {environment} from '../../../environments/environment'
import { HelperService } from './helper.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  headerOptions: any = null

  _isLoggedIn: boolean = false

  authSub = new Subject<any>();

  constructor(private _http: HttpClient,  private helperService:HelperService,) {
  }

  setupAuth() {
		//tfa/setup
    return this._http.post(`${environment.API_URL}/DAF5B4CF41F3AF6D1C8B14BC5C17DB59D272D268`, {uname:localStorage.getItem('email')}, { observe: 'response',headers: {
      'Content-Type': 'application/json',
      "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
  } })
  }

  updateAuthStatus(value: boolean) {
    this._isLoggedIn = value
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
  }

  getAuthStatus() {
    this._isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
    return this._isLoggedIn
  }

  logoutUser() {
    this._isLoggedIn = false;
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', "false")
  }

  getAuth() {
    //tfa/setup/
    return this._http.get(`${environment.API_URL}/2A77054453D95CABCA072802D139F2017E94F1C7/${localStorage.getItem('email')}`, { observe: 'response',headers: {
      'Content-Type': 'application/json',
      "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
  } });
  }

  deleteAuth() {
    //tfa/deleteSetup
    return this._http.post(`${environment.API_URL}/B27B50FBC020CC11EF74E53DB02FD9E641AA1AB5`, {uname:localStorage.getItem('email')},{ observe: 'response',headers: {
      'Content-Type': 'application/json',
      "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
  } });
  }

  verifyAuth(token: any) {
    //tfa/verify
    return this._http.post(`${environment.API_URL}/841B4387738939390EBC1F98FBDACCF60DD44B70`, { token, uname:localStorage.getItem('email') }, { observe: 'response',headers: {
      'Content-Type': 'application/json',
      "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
      "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
  } });
  }

}


