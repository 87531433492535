import gql from 'graphql-tag';



export const meetingsQuery = gql`
query  events($limit: Int, $offset: Int, $after: DateTime, $before: DateTime,$id:[ID],$with:ID,$isConference:Boolean,$location :String,$title :String,$pending:Boolean,$sortByTime:EventSortOrder,$userId:String,$participant:String,$company:String,$projectId: ID){
events(limit: $limit,offset: $offset,descending: true,after: $after, before: $before,id:$id, with:$with,isConference:$isConference,title:$title,location:$location,pending:$pending,sortByTime:$sortByTime,userId:$userId,participant: $participant,company:$company,projectId:$projectId) {
    id
    type
    status
    userIdOfEventCreator
    title
    description
    conferenceDetails
    location
    startTime
    endTime
    hasHappened
    isConference
    conferenceId
    checkInStatus
    conferenceVisibility
    conferenceType
    tags {
      key
      value
    }
    isPrivate
    password
    userIdOfEventCreator
    profileId
    passwordVerification
    isAdmin
    adminReject
    adminApproved
    participants{
      id
      firstName
      lastName
      nonAppParticipant
      confirmationStatus
      checkInStatus
      conferenceVisibility
      isAdmin
      adminApproved
      adminReject
      speaker
      eventLink
    }
    notes(type: NOTE_TEXT){
      contents
    }
    imageNote: notes(type: NOTE_IMAGE){id}
    audioNote: notes(type: NOTE_AUDIO){id}
    textNote
    isZoom
    zoomEventId
    zoomEventToken,
    isWebex
    webexEventId
    webexEventToken
    adminValidation
    onlyOriginalParticipantsCanJoin
    speaker
    hubspotId
    googleId
    isRecurring
    recurringValue
    isRecurringEndsOn
    recurringEndsOn
    recurringCustom
    recurringCustomDates
    token
    projectId
    projectName
    allNotes:notes(type:[NOTE_IMAGE,NOTE_TEXT,NOTE_AUDIO]){
      signedPutURL
      signedGetURL
      contents
      userId
     id
     type
    }
  }
}
`;


export const meetingsQueryOnlyIDS = gql`
query  events($limit: Int, $offset: Int, $after: DateTime, $before: DateTime,$id:[ID], $with: ID, $sortByTime:EventSortOrder,$userId:String,$having:[ID]){
events(limit: $limit,offset: $offset,descending: false,after: $after, before: $before,id:$id, with:$with,sortByTime:$sortByTime,userId:$userId,having:$having) {
    id
    startTime
    location
    endTime
    title
    participants {
      id
      firstName
      lastName
  	}
  }
}
`;
export const meetingsQueryforTotalCount = gql`
query  events($limit: Int, $offset: Int, $after: DateTime, $before: DateTime,$id:[ID], $with: ID,$isConference: Boolean,$userId: String,$participant: String,$company:String){
events(limit: $limit,offset: $offset,descending: true,after: $after, before: $before,id:$id, with:$with,isConference: $isConference,userId: $userId,participant:$participant,company:$company) {
    id
  }
}
`;

export const createMeetingMutation = gql`
mutation  UVNTRMDJCY($title:String!, $participantIds:[ID], $isConference: Boolean, $location: String, $startTime:DateTime!, $endTime:DateTime!, $description: String,$profileId:ID,$password:String,$isPrivate:Boolean,$type:EventType!,$conferenceType:ConferenceType,$conferenceDetails: String,$isZoom: Boolean, $zoomEventId: String,$zoomEventToken:String,$adminValidation:Boolean, $onlyOriginalParticipantsCanJoin:Boolean,$isWebex: Boolean, $webexEventId: String,$webexEventToken:String,$sipAddress: String,$hubspotId: String,$googleId: String,$isRecurring:Boolean,$recurringValue:RecurringType, $isRecurringEndsOn: Boolean, $recurringEndsOn: DateTime, $recurringCustom: DateTime, $recurringCustomDates: String,$projectId :ID  ){
  UVNTRMDJCY(event:{
    title: $title, participantIds:$participantIds, isConference: $isConference, location: $location, startTime:$startTime , endTime:$endTime, description: $description,type:$type,profileId:$profileId,password:$password,isPrivate:$isPrivate,conferenceType:$conferenceType,conferenceDetails:$conferenceDetails, isZoom:$isZoom, zoomEventId:$zoomEventId,zoomEventToken:$zoomEventToken, adminValidation:$adminValidation, onlyOriginalParticipantsCanJoin:$onlyOriginalParticipantsCanJoin, isWebex:$isWebex, webexEventId:$webexEventId,webexEventToken:$webexEventToken,sipAddress:$sipAddress,hubspotId: $hubspotId,googleId: $googleId,isRecurring: $isRecurring,recurringValue:$recurringValue, isRecurringEndsOn: $isRecurringEndsOn, recurringEndsOn: $recurringEndsOn, recurringCustom: $recurringCustom, recurringCustomDates: $recurringCustomDates,projectId:$projectId
  }) {
      id
      type
      status
      userIdOfEventCreator
      title
      description
      conferenceDetails
      location
      startTime
      endTime
      hasHappened
      isArchived
      noteStatus
      isConference
      conferenceId
      checkInStatus
      conferenceVisibility
      conferenceType
      isZoom
      zoomEventId
      zoomEventToken
      isWebex
    webexEventId
    webexEventToken
      tags {
        key
        value
      }
      isPrivate
      password
      profileId
      userIdOfEventCreator
      textNote
      passwordVerification
      isAdmin
      adminReject
      adminApproved
      participants{
        id
        firstName
        lastName
        nonAppParticipant
        confirmationStatus
        checkInStatus
        conferenceVisibility
        isAdmin
        adminApproved
        adminReject
      }
      adminValidation,
      onlyOriginalParticipantsCanJoin
      hubspotId
      googleId
      isRecurring
      recurringValue
      isRecurringEndsOn
      recurringEndsOn
      recurringCustom
      recurringCustomDates
      token
      projectId
      projectName
  }
}
`


export const createConferenceEventMutation = gql`
mutation  WPCRZFFNAE($title:String!, $participantIds:[ID], $isConference: Boolean, $location: String, $startTime:DateTime!, $endTime:DateTime!, $description: String,$profileId:ID,$password:String,$isPrivate:Boolean,$type:EventType!,$conferenceType:ConferenceType,$conferenceDetails: String){
  WPCRZFFNAE(event:{
    title: $title, participantIds:$participantIds, isConference: $isConference, location: $location, startTime:$startTime , endTime:$endTime, description: $description,type:$type,profileId:$profileId,password:$password,isPrivate:$isPrivate,conferenceType:$conferenceType,conferenceDetails:$conferenceDetails
  }) {
      id
      
      title
      description
      conferenceDetails
      location
      startTime
      endTime
      hasHappened
      isArchived
      noteStatus
      isConference
      conferenceId
      checkInStatus
      conferenceVisibility
      conferenceType
     
      isPrivate
      password
      profileId
      
      textNote
      passwordVerification
      isAdmin
      adminReject
      adminApproved
      participants{
        firstName
        lastName
        nonAppParticipant
        confirmationStatus
        checkInStatus
        conferenceVisibility
        isAdmin
        adminApproved
        adminReject
      }
      adminValidation
      onlyOriginalParticipantsCanJoin
      
    
  }
}
`
export const historyQuery = gql`
query  events($with: ID,$userId:String){
events( descending: false, with: $with,sortByTime:ASC,userId:$userId) {
    id
    type
    status
    userIdOfEventCreator
    title
    description
    conferenceDetails
    location
    startTime
    endTime
    hasHappened
    isArchived
    noteStatus
    isConference
    conferenceId
    checkInStatus
    conferenceVisibility
    conferenceType
    tags {
      key
      value
    }
    textNote
    passwordVerification
    isAdmin
    adminReject
    adminApproved
    participants{
      id
      firstName
      lastName
      nonAppParticipant
      confirmationStatus
      checkInStatus
      conferenceVisibility
    }
    notes(type: NOTE_TEXT){
      contents
    }
    imageNote: notes(type: NOTE_IMAGE){id}
    audioNote: notes(type: NOTE_AUDIO){id}
  }
}
`;



export const updateMeetingMutation = gql`
mutation  CORRWWWNNU($title:String, $participantIds:[ID], $location: String, $startTime:DateTime, $endTime:DateTime, $description: String,$id:ID!,$password:String,$isPrivate:Boolean,$adminValidation:Boolean, $onlyOriginalParticipantsCanJoin:Boolean, $profileId:ID,$hubspotId: String,$googleId: String,$isRecurring:Boolean,$recurringValue:RecurringType, $isRecurringEndsOn: Boolean, $recurringEndsOn: DateTime, $recurringCustom: DateTime, $recurringCustomDates: String,$conferenceDetails: String,$projectId:ID  ){
  CORRWWWNNU(event:{
    title: $title, participantIds:$participantIds, location: $location, startTime:$startTime , endTime:$endTime, description: $description, id: $id,password:$password,isPrivate:$isPrivate,adminValidation:$adminValidation, onlyOriginalParticipantsCanJoin: $onlyOriginalParticipantsCanJoin,profileId:$profileId,hubspotId: $hubspotId,googleId: $googleId,,isRecurring: $isRecurring,recurringValue:$recurringValue, isRecurringEndsOn: $isRecurringEndsOn, recurringEndsOn: $recurringEndsOn, recurringCustom: $recurringCustom, recurringCustomDates: $recurringCustomDates,conferenceDetails:$conferenceDetails,projectId:$projectId
  }) {
    id
    type
    
    title
    description
    conferenceDetails
    location
    startTime
    endTime
    hasHappened
    isArchived
    noteStatus
    isConference
    conferenceId
    checkInStatus
    conferenceVisibility
    conferenceType
    userIdOfEventCreator
    textNote
    passwordVerification
    isAdmin
    adminReject
    adminApproved
    participants{
      id
      firstName
      lastName
      nonAppParticipant
      confirmationStatus
      checkInStatus
      conferenceVisibility
      isAdmin
      adminApproved
      adminReject
    }
    tags {
      key
      value
    }
    isPrivate
    password
    profileId
    adminValidation
    onlyOriginalParticipantsCanJoin
    hubspotId
    googleId
    isRecurring
    recurringValue
    isRecurringEndsOn
    recurringEndsOn
    recurringCustom
    recurringCustomDates
    projectId
    projectName
  }
}`


export const updateConferenceEventMutation = gql`
mutation  QVAQRPCVHK($title:String, $participantIds:[ID], $location: String, $startTime:DateTime, $endTime:DateTime, $description: String,$id:ID!,$password:String,$isPrivate:Boolean ){
  QVAQRPCVHK(event:{
    title: $title, participantIds:$participantIds, location: $location, startTime:$startTime , endTime:$endTime, description: $description, id: $id,password:$password,isPrivate:$isPrivate
  }) {
    id
    title
    description
    conferenceDetails
    location
    startTime
    endTime
    hasHappened
    isArchived
    noteStatus
    isConference
    conferenceId
    checkInStatus
    conferenceVisibility
    conferenceType
    textNote
    passwordVerification
    isAdmin
    adminReject
    adminApproved
    participants{
      
      firstName
      lastName
      nonAppParticipant
      confirmationStatus
      checkInStatus
      conferenceVisibility
      isAdmin
      adminApproved
      adminReject
    }
    
    isPrivate
    password
    profileId
    adminValidation
    onlyOriginalParticipantsCanJoin
  }
}`

export const checkInToConferenceMutation = gql`
mutation  OKKRCPRPND($conferenceId:ID!, $profileId:ID!, $visibility: ConferenceVisibilityStatus,$password: String){
  OKKRCPRPND(checkIn:{
    conferenceId: $conferenceId, profileId:$profileId, visibility: $visibility, password: $password
  }) {
    id
    type
    status
    userIdOfEventCreator
    title
    description
    conferenceDetails
    location
    startTime
    endTime
    hasHappened
    isArchived
    noteStatus
    isConference
    conferenceId
    checkInStatus
    conferenceVisibility
    conferenceType
    isPrivate
    password
    profileId
    textNote
    userIdOfEventCreator
    passwordVerification
    isAdmin
    adminReject
    adminApproved
    participants{
      id
      firstName
      lastName
      nonAppParticipant
      confirmationStatus
      checkInStatus
      conferenceVisibility
      isAdmin
      adminApproved
      adminReject
    }
    tags {
      key
      value
    }
    adminValidation
    onlyOriginalParticipantsCanJoin
  }
}`

export const checkOutToConferenceMutation = gql`
mutation EUYSDMULLZ($conferenceId: ID!){
  EUYSDMULLZ(conferenceId: $conferenceId){
    status
    conferenceId
    conferenceVisibility
  }
}`

export const deleteEventMutation = gql`
mutation YUNJBIDXJT($id: [ID!]!){
  YUNJBIDXJT(id: $id){
    id
  }
}
`
export const meetingNotesQuery =  gql`

query  events($limit: Int, $offset: Int, $after: DateTime, $before: DateTime,$id:[ID], $with: ID,$userId:String,$participant:String,$company:String){
  events(limit: $limit,offset: $offset,descending: true,after: $after, before: $before,id:$id, with:$with,userId:$userId,participant:$participant,company:$company) {
    id
    textNote
    isConference
    title
    conferenceId
    startTime
    endTime
    isZoom
    description
    hubspotId
    userIdOfEventCreator
    notes(type:[NOTE_IMAGE,NOTE_TEXT,NOTE_AUDIO]){
      signedPutURL
      signedGetURL
      contents
      userId
     id
     type
     createdAt
     updatedAt
     filename
     type
     extension
     updatedAt
     isTranscribed
     transcribedText
     transcribedLanguage
     transcribedTime
     audioTitle
    }
    
  }
} 
`;

export const confirmEventMutation = gql`
mutation IBOJPRWENR($eventId: ID,$confirmationStatus:ConfirmationStatusType){
  IBOJPRWENR(eventId: $eventId,confirmationStatus:$confirmationStatus){
    eventId
    userId
    confirmationStatus
  }
}
`

export const updateConferenceVisibilityMutation = gql`
mutation AXTTXJDWBU($conferenceId: ID!,$visibility:ConferenceVisibilityStatus){
  AXTTXJDWBU(conferenceId: $conferenceId,visibility:$visibility){
    status
    conferenceId
    conferenceVisibility
  }
}
`
export const createAdmintMutation = gql`
mutation JOQQTUSSSY($eventId: ID!,$participantId:ID!){
  JOQQTUSSSY(eventId: $eventId,participantId:$participantId)
}
`
export const removeAdmintMutation = gql`
mutation DDXLYISZSB($eventId: ID!,$participantId:ID!){
  DDXLYISZSB(eventId: $eventId,participantId:$participantId)
}
`
export const approveRequestMutation = gql`
mutation KUVGHJQHSW($eventId: ID!,$participantId:ID!){
  KUVGHJQHSW(eventId: $eventId,participantId:$participantId)
}
`
export const rejectRequestMutation = gql`
mutation MSYEFFZXMU($eventId: ID!,$participantId:ID!){
  MSYEFFZXMU(eventId: $eventId,participantId:$participantId)
}
`

export const createConferenceContactMutation = gql`
mutation ZTTHZHGJDB($eventId: ID!,$participantIds:[ID!]!){
  ZTTHZHGJDB(conferenceContact:{eventId: $eventId,participantIds:$participantIds}){
    id
    userIdOfContact
    firstName
    lastName
    favorite
    nonAppContact
    pending
    createdAt
    updatedAt
    keywords
    hashTags
    displayImageURL
    displayImagePutURL
    rating
    sent{
      profileId
      profileVersion
    }
    importedData  { key title value format }
    received {
      displayImageURL
      data{
        key
        format
        value
        format
      }
    }

  }
}
`
export const sendMeetingInviteEmailMutation = gql`
  mutation sendMeetingInviteEmail($userId: ID,$token:String,$eventId:String, $nonAppContactId:ID){
    sendMeetingInviteEmail(userId: $userId,token:$token,eventId:$eventId, nonAppContactId:$nonAppContactId)
  }
`

export const sendVcardMutation = gql`
  mutation CBXQMLBKKW($userId: ID,$profileId:ID!,$nonAppContactId:ID){
    CBXQMLBKKW(userId: $userId,profileId:$profileId,nonAppContactId:$nonAppContactId)
  }
`

export const approveMeetingRequestMutation = gql`
  mutation ZFLYAKIIUL($eventId: String,$nonAppContactId:ID){
    ZFLYAKIIUL(eventId: $eventId,nonAppContactId:$nonAppContactId)
  }
`

export const shareEventByMailMutation = gql`
  mutation LONRMKWABC($eventId: ID!,$email:String!){
    LONRMKWABC(eventId: $eventId,email:$email)
  }
`

export const pendingMeetingsQuery = gql`
query  events{
events(pending:true, isConference:false) {
    id
    
  }
}
`;
export const pendingConferenQuery = gql`
query  events{
events(pending:true, isConference:true) {
    id
    
  }
}
`;

export const getCalendarQuery = gql`
query  getCalendar{
  getCalendar {
    id
    timeslot
    availableFrom
    availableTo
    availableDays
    lunchBreakFrom
    lunchBreakTo
    link
    custom
  }
}
`;

export const createCalendarMutation = gql`
mutation  PFBYQPHAWO($timeslot:String!, $availableFrom:String!, $availableTo: String!, $availableDays: CalendarDays!, $lunchBreakFrom:String!, $lunchBreakTo:String!, $link: String,$custom: String ){
  PFBYQPHAWO(calendar:{
    timeslot: $timeslot, availableFrom:$availableFrom, availableTo: $availableTo, availableDays: $availableDays, lunchBreakFrom:$lunchBreakFrom , lunchBreakTo:$lunchBreakTo, link: $link,custom: $custom
  }) {
    id
    timeslot
    availableFrom
    availableTo
    availableDays
    lunchBreakFrom
    lunchBreakTo
    link
    custom
  }
}`

export const updateCalendarMutation = gql`
mutation  BPINWXPOKV($timeslot:String!, $availableFrom:String!, $availableTo: String!, $availableDays: CalendarDays!, $lunchBreakFrom:String!, $lunchBreakTo:String!, $link: String, $id:ID!,$custom: String){
  BPINWXPOKV(calendar:{
    timeslot: $timeslot, availableFrom:$availableFrom, availableTo: $availableTo, availableDays: $availableDays, lunchBreakFrom:$lunchBreakFrom , lunchBreakTo:$lunchBreakTo, link: $link, id: $id,custom: $custom
  }) {
    id
    timeslot
    availableFrom
    availableTo
    availableDays
    lunchBreakFrom
    lunchBreakTo
    link
    custom
  }
}`

export const meetingsQuerySync = gql`
query  {
  events {
    id
    location
    startTime
    endTime
    title
    description
    userIdOfEventCreator
    participants{
      id
      firstName
      lastName
      email
    }
    notes(type:[NOTE_IMAGE,NOTE_TEXT,NOTE_AUDIO]){
      signedGetURL
      contents
      type
    }
    hubspotId
    googleId
  }
}
`;
