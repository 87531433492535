<app-loading *ngIf="loading"></app-loading>

<div class="modal-header">
   <h5 class="m-0">Update Product</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body px-4 py-0 ">
    <form  [formGroup]="orderForm"
    (ngSubmit)="saveForm()" class="py-3 d-flex flex-wrap small-picker">
        <div class="divBlk col-md-12">
            <label for="additionalInformation">Additional Information<span class="man-star d-none">*</span></label>
            <textarea class="form-control" formControlName="additionalInformation" id="additionalInformation" name="additionalInformation"> </textarea>
        </div>
        <div class="divBlk col-md-6">
         <label for="actualSellingPrice">Actual selling price (MAD)<span class="man-star d-none">*</span></label>
         <input type="text"  (input)="updateRemainingAmount()" maxlength="50" id="actualSellingPrice" formControlName="actualSellingPrice" name="actualSellingPrice" class="form-control custominp"/>
      </div>
      <div class="divBlk col-md-6">
         <label for="heldAmount">Avoir / Held Amount<span class="man-star d-none">*</span></label>
         <input type="text"   maxlength="50" id="heldAmount"  (input)="updateAmoutCollect()" formControlName="heldAmount" name="heldAmount" class="form-control custominp"/>
      </div>

                        <div class="divBlk col-md-6">
                           <label for="amountToCollect">Amount to be collected<span class="man-star d-none">*</span></label>
                           <input type="number" readonly  maxlength="50" id="amountToCollect" formControlName="amountToCollect" name="amountToCollect" class="form-control custominp"/>
                        </div>


      <div class="divBlk col-md-6" >
         <label for="goldColor"> Payment method<span class="man-star d-none">*</span></label>
         <select class="form-control" formControlName="paymentMethod" (change)="changePaymentMethod($event)">
            <option value="0">Select Payment Method</option>
            <option value="{{paymentType.id}}" *ngFor="let paymentType of paymentTypes">{{paymentType.name}}</option>
         </select>
      </div>
     
      <ng-container>
          <div class="divBlk col-md-6" *ngIf="f.paymentMethod.value == 'CASH' || f.paymentMethod.value == 'CHEQUE_AND_CASH'">
              <label for="cashAmount">Cash Amount<span class="man-star d-none">*</span></label>
              <input type="text" maxlength="50" id="cashAmount" formControlName="cashAmount" name="cashAmount" class="form-control custominp"/>
           </div>
           <div class="divBlk col-md-6" *ngIf="f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH'">
              <label for="numberOfCheques"> Number of cheques<span class="man-star d-none">*</span></label>
              <select class="form-control" formControlName="numberOfCheques">
                 <option value="0">Select</option>
                 <option value="{{count}}" *ngFor="let count of chequestCount">{{count}}</option>
              </select>
           </div>
      </ng-container>
      <ng-container *ngIf="f.numberOfCheques.value >0 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate1">  Cheque Date - 1<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate1"
               formControlName="chequeDate1"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>

         <div class="divBlk col-md-6" >
            <label for="chequeDate1Amount">Cheque Amount - 1<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate1Amount" formControlName="chequeDate1Amount" name="chequeDate1Amount" class="form-control custominp"/>
         </div>
      </ng-container>

      <ng-container *ngIf="f.numberOfCheques.value >1 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate2">  Cheque Date - 2<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate2"
               formControlName="chequeDate2"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>

         <div class="divBlk col-md-6" >
            <label for="chequeDate2Amount">Cheque Amount - 2<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate1Amount" formControlName="chequeDate2Amount" name="chequeDate2Amount" class="form-control custominp"/>
         </div>
      </ng-container>
      <ng-container *ngIf="f.numberOfCheques.value >2 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate3">  Cheque Date - 3<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate3"
               formControlName="chequeDate3"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate3Amount">Cheque Amount - 3<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate3Amount" formControlName="chequeDate3Amount" name="chequeDate3Amount" class="form-control custominp"/>
         </div>
      </ng-container>
   
      <ng-container *ngIf="f.numberOfCheques.value >3 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate4">  Cheque Date - 4<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate4"
               formControlName="chequeDate4"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate4Amount">Cheque Amount - 4<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate4Amount" formControlName="chequeDate4Amount" name="chequeDate4Amount" class="form-control custominp"/>
         </div>
      </ng-container>
   


     
      <ng-container *ngIf="f.numberOfCheques.value >4 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate5">  Cheque Date - 5<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate5"
               formControlName="chequeDate5"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate5Amount">Cheque Amount - 5<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate5Amount" formControlName="chequeDate5Amount" name="chequeDate5Amount" class="form-control custominp"/>
         </div>
      </ng-container>

      <ng-container *ngIf="f.numberOfCheques.value >5 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate6">  Cheque Date - 6<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate6"
               formControlName="chequeDate6"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate6Amount">Cheque Amount - 6<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate6Amount" formControlName="chequeDate6Amount" name="chequeDate6Amount" class="form-control custominp"/>
         </div>
      </ng-container>
      <ng-container *ngIf="f.numberOfCheques.value >6 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate7">  Cheque Date - 7<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate7"
               formControlName="chequeDate7"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate7Amount">Cheque Amount - 7<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate7Amount" formControlName="chequeDate7Amount" name="chequeDate7Amount" class="form-control custominp"/>
         </div>
      </ng-container>
      
      <ng-container *ngIf="f.numberOfCheques.value >7 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate8">  Cheque Date - 8<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate8"
               formControlName="chequeDate8"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate8Amount">Cheque Amount - 8<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate8Amount" formControlName="chequeDate8Amount" name="chequeDate8Amount" class="form-control custominp"/>
         </div>
      </ng-container>
      <ng-container *ngIf="f.numberOfCheques.value >8 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate9">  Cheque Date - 9<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate9"
               formControlName="chequeDate9"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate9Amount">Cheque Amount - 9<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate9Amount" formControlName="chequeDate9Amount" name="chequeDate9Amount" class="form-control custominp"/>
         </div>
      </ng-container>
                             
      <ng-container *ngIf="f.numberOfCheques.value==10 && (f.paymentMethod.value == 'CHEQUE' || f.paymentMethod.value == 'CHEQUE_AND_CASH')">
         <div class="divBlk col-md-6" >
            <label for="chequeDate10">  Cheque Date - 10<span class="man-star d-none">*</span></label>
            <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
               <input
               class=" form-control w-100"
               type="text"
               value=""
               id="chequeDate10"
               formControlName="chequeDate10"
               ngbDatepicker
               #d2="ngbDatepicker"
               [placement]="placement"
               [positionTarget]="buttonEl"
               (click)="d2.toggle()"
               />
            </div>
         </div>
     
         <div class="divBlk col-md-6" >
            <label for="chequeDate10Amount">Cheque Amount - 10<span class="man-star d-none">*</span></label>
            <input type="text" maxlength="50" id="chequeDate10Amount" formControlName="chequeDate10Amount" name="chequeDate10Amount" class="form-control custominp"/>
         </div>
      </ng-container>
      
      <ng-container *ngIf="f.actionType.value=='ORDER'">
      <div class="divBlk col-md-6" >
          <label for="advanceAmount"> Advance amount in MAD<span class="man-star d-none">*</span></label>
          <input type="text" (input)="updateRemainingAmount()" maxlength="50" id="advanceAmount" formControlName="advanceAmount" name="advanceAmount" class="form-control custominp"/>
       </div>
       <div class="divBlk col-md-6" >
          <label for="remainingDueAmount"> Implied remaining amount due<span class="man-star d-none">*</span></label>
          <input type="text" maxlength="50" id="remainingDueAmount" formControlName="remainingDueAmount" name="remainingDueAmount" class="form-control custominp"/>

       </div>
      <div class="divBlk col-md-12" *ngIf="f.actionType.value=='ORDER'">
          <label for="description">Description<span class="man-star d-none">*</span></label>
          <textarea class="form-control" autosize formControlName="description" id="description" name="description"> </textarea>
       </div>
      </ng-container>

      <div class="divBlk col-md-6" *ngIf="f.actionType.value=='BUY'">
         <label for="additionalInformation">
            Additional Information
            <span class="man-star d-none">*</span>
         </label>
         <textarea class="form-control" formControlName="additionalInformation" id="additionalInformation" name="additionalInformation"></textarea>
      </div>
      <ng-container *ngIf="f.actionType.value=='BUY'">
      <div class="form-group col-md-6 ">
         <label for="additionalDiscount">Additional Discount</label>
         <input class="form-control" formControlName="additionalDiscount" (input)="setFinalSellingPrice()" name="additionalDiscount" id="additionalDiscount" type="text" value="" />
         <div class="error text-danger"></div>
      </div>
      <div class="form-group col-md-6 ">
         <label for="effectiveSalesPrice">Final Selling Price</label>
         <input class="form-control" formControlName="effectiveSalesPrice" name="effectiveSalesPrice" id="effectiveSalesPrice" type="text" value="" />
         <div class="error text-danger"></div>
      </div>
      </ng-container>
      

         <div class="divBlk pt-3 col-md-12 text-center" >
            <input type="submit" class="btn btn-primary mr-2"  value="Move to Sold"  />
         </div>
    </form>
</div>

