import { Component, OnInit } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  profilesQuery,accountInfo
} from '../../../services/user.gql'

import * as data from '../../../../shared/country.json'
import { Router, ActivatedRoute, Params } from '@angular/router'
import {
  membersBasicQuery 
} from '../../../services/admin.gql'
@Component({
  selector: 'app-view-member-profile',
  templateUrl: './view-member-profile.component.html',
  styleUrls: ['./view-member-profile.component.scss']
})
export class ViewMemberProfileComponent implements OnInit {

  private queryPr1: QueryRef<any>
  private queryAc:QueryRef<any>
  public years: any[] = []
  public userId: number
 
  public userData: any = []
  public profilePic: any
  public countryList: any = []
  public isOpen = false
  public countryData: any = data
  public accountInfo :any
  loading:boolean=true
public userInfo:any = ''  
  constructor (
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute
  ) {
    this.countryList = this.countryData ? this.countryData.default : []

    //this.countryList = data? data.default : []
    const year = new Date().getFullYear()
    for (var i = 1960; i <= year; i++) {
      this.years.push({
        id: i + 1,
        text: i + 1
      })
    }
  }
  ngOnInit () {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.userId = params.id
        this.getUserDetails(params.id)
      }
    })
    // this. getAccountInfo()
 
  }

  getAccountInfo()
  {
    this.queryAc = this.apollo.watchQuery({
      query: accountInfo
    })
    this.queryAc.valueChanges.subscribe(({ data }) => {
      this.accountInfo =  data.accountInformation
    },error => {
      this.loading = false
    })
  }
  getUserDetails (userId) {
    // this.queryPr1 = this.apollo.watchQuery({
    //   query: profilesQuery,
    //   fetchPolicy:"network-only"
    // })

    this.queryPr1 = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        userIds: [userId]
      },
      fetchPolicy:"network-only"
    })
    this.queryPr1.valueChanges.subscribe(({ data }) => {
      this.loading = false
      this.userInfo = data.users[0]

      if(data.users[0].profiles.length>0)
      {
        console.log(data.users)
        this.userData = data.users[0].profiles[0]
        const [emails, phones, links, socialLinks, location,department,title, company,education,custom,university] =   this.userData.data.reduce((result, element) => {
          if(element.format=='EMAIL')
          {
            result[0].push(element)
          }
          if(element.format=='PHONE_NUMBER')
          {
            result[1].push(element)
          }
          if(element.format=='LINK')
          {
            result[2].push(element)
          }
          if(element.format=='SOCIAL_MEDIA')
          {
            
            result[3].push(element)
          }
          if(element.format=='LOCATION')
          {
            element.value = element.value.split('$$$').filter(Boolean).join(", ");
            result[4].push(element)
          }
          if(element.format=='DEPARTMENT')
          {
            result[5]=element
          } 
          if(element.format=='JOB_TITLE')
          {
            result[6]=element
          }
          if(element.format == 'ORGANIZATION')
          { 
            if(element.title.toLowerCase()=='company')
            {
              result[7]=element
            }
            if(element.title.toLowerCase()=='university')
            {
              result[10]=element
            }
          }
          if(element.format == 'UNIVERSITY')
          {
            // console.log(element.value.split('$$$'))
            let arrayInfo = element.value.split('$$$')
            // let duration
            // if(arrayInfo[4] && arrayInfo[5] ){
            //   duration = `${arrayInfo[4]} - ${arrayInfo[5}`
            // }
            element.value = element.value.split('$$$').filter(Boolean).join(" ");
            result[8]=element
          }
          if(element.format == 'GENERIC')
          {
            result[9].push(element)
          }
  
          // result[8].push(element)
          // result[element <= 10 ? 0 : 1].push(element); // Determine and push to small/large arr
          return result;
        },
        [[], [],[], [],[], {},{},{},{},[],{}]);  
        let filterData = {emails, phones, links, socialLinks, location,department,title, company,education,custom,university}
        this.userData = {...this.userData,...filterData}
        // console.log( this.userData ,' this.userData ')
      }
      this.loading = false
    },error => {
      this.loading = false
    })
  }
  
 
 
  getValidUrl = (url = '') => {
    let myWindow = window as any
    let newUrl = myWindow.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, '')

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`
    }

    return newUrl
  }
  errorHandler (event) {
    event.target.src = 'assets/default.jpg'
  }
}
