<div class="row justify-content-center align-items-center ">
    <div class="col-lg-12  px-0">
      <div class="col-md-12 p-2">
        <div class="card px-md-3 py-5 my-3 d-flex col-lg-12  justify-content-center ">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a  [routerLink]="['/update-account']">Account</a></li>
                  <li class="breadcrumb-item active" aria-current="page">2MFA</li>
                </ol>
              
              </nav>
            <div class=" card-container col-md-6 col-lg-4 m-auto">
            <div *ngIf="tfa.secret">
        
                <h5 style="border-bottom: 1px solid #a8a8a8; padding-bottom: 5px;">Current Settings</h5>
        
                <img [src]="tfa.dataURL" alt="" class="img-thumbnail" style="display:block;margin:auto">
        
                <!-- <p>Secret Key - {{tfa.secret || tfa.tempSecret}}</p>
        
                <p>Auth Type - Time Based - OTP</p> -->
        
                <button class="btn btn-lg btn-danger btn-block btn-signin" (click)="disabledTfa()">Disable 2MFA</button>
            </div>
            <div *ngIf="!tfa.secret ">
        
        
                <span *ngIf="!!tfa.tempSecret">
        
                <p>Scan the QR code or enter the secret key in Google Authenticator</p>
        
                <img [src]="tfa.dataURL" alt="" class="img-thumbnail" style="display:block;margin:auto">
        
                <!-- <p>Secret Key - {{tfa.tempSecret}}</p>
        
                <p>Auth Type - Time Based - OTP</p>
         -->
                <form class="form-group pt-3" (ngSubmit)="confirm()" #otpForm="ngForm">
                    <input name="authcode" type="number" #iauthcode="ngModel" class="form-control" maxlength="6"
                    placeholder="Enter the Auth Code" id="authcode" autocomplete="off" [(ngModel)]="authcode" required>
                    <br>
                    <button type="Submit" class="btn btn-lg btn-primary btn-block btn-signin"
                    [disabled]="iauthcode?.errors?.required">Enable 2MFA</button>
                </form>
                <p class="text-danger" style="text-align:center;" *ngIf="errorMessage">{{errorMessage}}</p>
                </span>
            </div>
            </div>
        </div>
      </div>
    </div>
</div>