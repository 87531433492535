import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
const moment = require('moment-timezone')

import {
  customersQuery, customersOnlyIdQuery, deleteCustomerMutution, updateProductMutution, updateCustomerMutution, updateProductQtyMutution, createCustomerMutution
} from '../../../../services/products.gql'
import { exit } from 'process';

@Component({
  selector: 'app-move-order-to-sales',
  templateUrl: './move-order-to-sales.component.html',
  styleUrls: ['./move-order-to-sales.component.scss']
})
export class MoveOrderToSalesComponent implements OnInit {
  @Input() public propsData;
  private query2: QueryRef<any, any>

  public orderForm: FormGroup
  loading: boolean = false
  submitted: boolean = false
  chequestCount: any = []
  placement = 'bottom-left'
  buttonEl: any
  paymentTypes: any = [
    {
      id: "CARD",
      name: "Card",
    },
    {
      id: "CHEQUE",
      name: "Cheque",
    },
    {
      id: "CASH",
      name: "Cash",
    },
    {
      id: "CHEQUE_AND_CASH",
      name: "Cheque & Cash",
    },
    {
      id: "A_RECUPERER",
      name: "A Recuperer",
    },
    {
      id: "BANK_TRANSFER",
      name: "Bank Transfer",
    },
  ]
  // chequeDate1Amount:number
  // chequeDate2Amount:number
  // chequeDate3Amount:number
  // chequeDate4Amount:number
  // chequeDate5Amount:number
  // chequeDate6Amount:number
  // chequeDate7Amount:number
  // chequeDate8Amount:number
  // chequeDate9Amount:number
  // chequeDate10Amount:number

  // chequeDate1:any
  // chequeDate2:any
  // chequeDate3:any
  // chequeDate4:any
  // chequeDate5:any
  // chequeDate6:any
  // chequeDate7:any
  // chequeDate8:any
  // chequeDate9:any
  // chequeDate10:any



  constructor(private formBuilder: FormBuilder, private apollo: Apollo, public activeModal: NgbActiveModal) {
    for (let i = 1; i <= 10; i++) {
      this.chequestCount.push(i)
    }
    this.orderForm = this.formBuilder.group({
      productCategory: [0],
      // firstName: [''],
      // lastName: [''],
      // email:['' ],
      // phone: [''],
      description: [''],
      additionalInformation: [''],
      // city: [''],
      // ringSize: [''],
      // quantity: [''],

      actualSellingPrice: [''],
      paymentMethod: [0],
      numberOfCheques: [0],
      chequeDate1: [],
      chequeDate2: [],
      chequeDate3: [],
      chequeDate4: [],
      chequeDate5: [],
      chequeDate6: [],
      chequeDate7: [],
      chequeDate8: [],
      chequeDate9: [],
      chequeDate10: [],
      cashAmount: [''],
      chequeDate1Amount: [''],
      chequeDate2Amount: [''],
      chequeDate3Amount: [''],
      chequeDate4Amount: [''],
      chequeDate5Amount: [''],
      chequeDate6Amount: [''],
      chequeDate7Amount: [''],
      chequeDate8Amount: [''],
      chequeDate9Amount: [''],
      chequeDate10Amount: [''],
      actionType: [''],
      sellingPrice: [''],
      advanceAmount: [''],
      remainingDueAmount: [''],
      timezone: [moment.tz.guess()],
      heldAmount: ['0'],
      amountToCollect: ['0']

    })
  }

  ngOnInit() {
    this.orderInfo()
  }

  get f() {
    return this.orderForm.controls
  }
  saveForm() {
    this.submitted = true
    // const firstName = this.f.firstName.value
    // const lastName = this.f.lastName.value
    // const email = this.f.email.value
    // const phone = this.f.phone.value
    const description = this.f.description.value
    // const city = this.f.city.value
    // const ringSize = this.f.ringSize.value
    const paymentMethod = this.f.paymentMethod.value
    const actualSellingPrice = parseFloat(this.f.actualSellingPrice.value)
    const chequeDate1Amount = parseFloat(this.f.chequeDate1Amount.value)
    const chequeDate2Amount = parseFloat(this.f.chequeDate2Amount.value)
    const chequeDate3Amount = parseFloat(this.f.chequeDate3Amount.value)
    const chequeDate4Amount = parseFloat(this.f.chequeDate4Amount.value)
    const chequeDate5Amount = parseFloat(this.f.chequeDate5Amount.value)
    const chequeDate6Amount = parseFloat(this.f.chequeDate6Amount.value)
    const chequeDate7Amount = parseFloat(this.f.chequeDate7Amount.value)
    const chequeDate8Amount = parseFloat(this.f.chequeDate8Amount.value)
    const chequeDate9Amount = parseFloat(this.f.chequeDate9Amount.value)
    const chequeDate10Amount = parseFloat(this.f.chequeDate10Amount.value)
    // const actionType = this.f.actionType.value
    // const sellingPrice = this.f.sellingPrice.value
    const advanceAmount = parseFloat(this.f.advanceAmount.value)
    const remainingDueAmount = parseFloat(this.f.remainingDueAmount.value)
    const cashAmount = parseFloat(this.f.cashAmount.value)
    const numberOfCheques = parseInt(this.f.numberOfCheques.value)
    const additionalInformation = this.f.additionalInformation.value
    const heldAmount = this.f.heldAmount.value ? this.f.heldAmount.value.toString() : "0"
    const amountToCollect = this.f.amountToCollect.value ? this.f.amountToCollect.value.toString() : "0"
    let chequeDate1 = this.f.chequeDate1.value
    if (chequeDate1) {

      chequeDate1 = `${chequeDate1.year}-${chequeDate1.month}-${chequeDate1.day}`
      chequeDate1 = moment(chequeDate1, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate1 = moment
        .tz(chequeDate1, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate2 = this.f.chequeDate2.value

    if (chequeDate2) {
      chequeDate2 = `${chequeDate2.year}-${chequeDate2.month}-${chequeDate2.day}`

      chequeDate2 = moment(chequeDate2, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')

      chequeDate2 = moment
        .tz(chequeDate2, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate3 = this.f.chequeDate3.value
    if (chequeDate3) {
      chequeDate3 = `${chequeDate3.year}-${chequeDate3.month}-${chequeDate3.day}`
      chequeDate3 = moment(chequeDate3, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate3 = moment
        .tz(chequeDate3, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate4 = this.f.chequeDate4.value
    if (chequeDate4) {
      chequeDate4 = `${chequeDate4.year}-${chequeDate4.month}-${chequeDate4.day}`
      chequeDate4 = moment(chequeDate4, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate4 = moment
        .tz(chequeDate4, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate5 = this.f.chequeDate5.value
    if (chequeDate5) {
      chequeDate5 = `${chequeDate5.year}-${chequeDate5.month}-${chequeDate5.day}`
      chequeDate5 = moment(chequeDate5, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate5 = moment
        .tz(chequeDate5, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate6 = this.f.chequeDate6.value
    if (chequeDate6) {
      chequeDate6 = `${chequeDate6.year}-${chequeDate6.month}-${chequeDate6.day}`
      chequeDate6 = moment(chequeDate6, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate6 = moment
        .tz(chequeDate6, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate7 = this.f.chequeDate7.value
    if (chequeDate7) {
      chequeDate7 = `${chequeDate7.year}-${chequeDate7.month}-${chequeDate7.day}`
      chequeDate7 = moment(chequeDate7, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate7 = moment
        .tz(chequeDate7, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate8 = this.f.chequeDate8.value
    if (chequeDate8) {
      chequeDate8 = `${chequeDate8.year}-${chequeDate8.month}-${chequeDate8.day}`
      chequeDate8 = moment(chequeDate8, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate8 = moment
        .tz(chequeDate8, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate9 = this.f.chequeDate9.value
    if (chequeDate9) {
      chequeDate9 = `${chequeDate9.year}-${chequeDate9.month}-${chequeDate9.day}`
      chequeDate9 = moment(chequeDate9, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate9 = moment
        .tz(chequeDate9, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }
    let chequeDate10 = this.f.chequeDate10.value
    if (chequeDate10) {
      chequeDate10 = `${chequeDate10.year}-${chequeDate10.month}-${chequeDate10.day}`
      chequeDate10 = moment(chequeDate10, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
      chequeDate10 = moment
        .tz(chequeDate10, this.f.timezone.value)
        .format('YYYY-MM-DD')
    }

    const invalid = [];
    const controls = this.orderForm.controls;

    for (const key of Object.keys(this.orderForm.controls)) {
      if (this.orderForm.controls[key].invalid) {
        // const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        // invalidControl.focus();
        // console.log(key,'key')
        // break;
      }
    }

    let productObj: any = {
      // firstName,
      // lastName,
      // phone,
      // email,
      description,
      additionalInformation,
      // ringSize,
      // city,
      paymentMethod,
      actualSellingPrice,
      chequeDate1Amount,
      chequeDate2Amount,
      chequeDate3Amount,
      chequeDate4Amount,
      chequeDate5Amount,
      chequeDate7Amount,
      chequeDate6Amount,
      chequeDate8Amount,
      chequeDate9Amount,
      chequeDate10Amount,
      // actionType,
      // sellingPrice,
      advanceAmount,
      remainingDueAmount,
      numberOfCheques,
      cashAmount,
      chequeDate10,
      chequeDate9,
      chequeDate8,
      chequeDate7,
      chequeDate6,
      chequeDate5,
      chequeDate4,
      chequeDate3,
      chequeDate2,
      chequeDate1,
      heldAmount,
      amountToCollect
    }

    productObj = Object.entries(productObj).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})

    productObj.cashAmount = cashAmount ? cashAmount : 0
    productObj.actualSellingPrice = actualSellingPrice ? actualSellingPrice : 0
    if (chequeDate1) {

      productObj.chequeDate1Amount = productObj.chequeDate1Amount ? productObj.chequeDate1Amount : 0
    }
    if (chequeDate2) {

      productObj.chequeDate2Amount = productObj.chequeDate2Amount ? productObj.chequeDate2Amount : 0
    }
    if (chequeDate3) {

      productObj.chequeDate3Amount = productObj.chequeDate3Amount ? productObj.chequeDate3Amount : 0
    }
    if (chequeDate4) {

      productObj.chequeDate4Amount = productObj.chequeDate4Amount ? productObj.chequeDate4Amount : 0
    }
    if (chequeDate5) {

      productObj.chequeDate5Amount = productObj.chequeDate5Amount ? productObj.chequeDate5Amount : 0
    }
    if (chequeDate6) {

      productObj.chequeDate6Amount = productObj.chequeDate6Amount ? productObj.chequeDate6Amount : 0
    }
    if (chequeDate7) {

      productObj.chequeDate7Amount = productObj.chequeDate7Amount ? productObj.chequeDate7Amount : 0
    }
    if (chequeDate8) {

      productObj.chequeDate8Amount = productObj.chequeDate8Amount ? productObj.chequeDate8Amount : 0
    }
    if (chequeDate9) {

      productObj.chequeDate9Amount = productObj.chequeDate9Amount ? productObj.chequeDate9Amount : 0
    }
    if (chequeDate10) {

      productObj.chequeDate10Amount = productObj.chequeDate10Amount ? productObj.chequeDate10Amount : 0
    }



    // return

    // this.updateCustomer(productObj)
    this.createCustomerToMoveOrder(productObj)




  }
  updateCustomer(customerObj) {
    let {id, productId,firstName } = this.propsData.customer
    let obj:any = {
      id,
      productId,
      actionType:"BUY",
      firstName

    }
    obj = {...customerObj,...obj}
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateCustomerMutution,
        variables: obj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          this.updateQuantity ()
          this.activeModal.close('1')
          // this.toastr.info("Successfully move to the sales.")
          
          
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }

  createCustomerToMoveOrder (customerObj) {
    let {id, productId,firstName, customerOrderNumber,lastName,phone,email,quantity,ringSize,goldColor,city} = this.propsData.customer
    let obj:any = {
      // id,
      productId,
      actionType:"BUY",
      firstName,
      oldOrderId:id,
      customerOrderNumber:customerOrderNumber,
      lastName:lastName||'',
      email:email||'',
      phone:phone||'',
      quantity:quantity||0,
      ringSize,
      goldColor,
      city

    }
    obj = {...customerObj,...obj}
    // return
    this.loading = true
    this.apollo
      .mutate({
        mutation: createCustomerMutution,
        variables: obj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          this.updateQuantity ()
          this.activeModal.close('1')
          // this.toastr.info("Successfully move to the sales.")
          
          
        },
        error => {
          console.log(error)
          this.loading= false
        }
      )
  }

  updateProduct(product) {
    this.loading = true
    let productObj: any = {
      stockQuantity: parseInt(product.stockQuantity) + 1,
      id: product.id,
      category: product.productCategory.name
    }
    this.apollo
      .mutate({
        mutation: updateProductMutution,
        variables: productObj,
        errorPolicy: "all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
        },
        error => {
          console.log(error)
          this.loading = false
        }
      )
  }
  changePaymentMethod(event) {
    if (event.target.value != 'CHEQUE' && event.target.value != 'CHEQUE_AND_CASH') {
      this.orderForm.patchValue({
        numberOfCheques: 0
      })
      this.orderForm.patchValue({
        chequeDate1: "",
        chequeDate2: "",
        chequeDate3: "",
        chequeDate4: "",
        chequeDate5: "",
        chequeDate6: "",
        chequeDate7: "",
        chequeDate8: "",
        chequeDate9: "",
        chequeDate10: "",
        cashAmount: [''],
        chequeDate1Amount: "",
        chequeDate2Amount: "",
        chequeDate3Amount: "",
        chequeDate4Amount: "",
        chequeDate5Amount: "",
        chequeDate6Amount: "",
        chequeDate7Amount: "",
        chequeDate8Amount: "",
        chequeDate9Amount: "",
        chequeDate10Amount: "",
      })
    }
    if (event.target.value != 'CASH' && event.target.value != 'CHEQUE_AND_CASH') {
      this.orderForm.patchValue({
        cashAmount: ""
      })
    }

  }
  closeModal() {
    this.activeModal.close()
  }
  updateQuantity() {
    this.loading = true

    let { stockQuantity, id } = this.propsData.customer.product
    stockQuantity = stockQuantity > 0 ? parseInt(stockQuantity) - 1 : 0
    this.loading = true
    let productObj: any = {
      qty: stockQuantity,
      productId: id,

    }
    // return
    this.apollo
      .mutate({
        mutation: updateProductQtyMutution,
        variables: productObj,
        errorPolicy: "all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
        },
        error => {
          console.log(error)
          this.loading = false
        }
      )
  }
  orderInfo() {
    let { id, productId, firstName } = this.propsData.customer

    this.loading = true
    this.query2 = this.apollo.watchQuery({
      query: customersQuery,
      fetchPolicy: 'network-only',
      variables: {
        id: id
      }
    })

    this.query2.valueChanges.subscribe(({ data }: any) => {
      this.loading = false
      let customerInfo: any = data.customers[0]



      let { firstName, lastName, email, phone,
        description,
        chequeDate1,
        chequeDate2,
        chequeDate3,
        chequeDate4,
        chequeDate5,
        chequeDate6,
        chequeDate7,
        chequeDate8,
        chequeDate9,
        chequeDate10,
        numberOfCheques,
        chequeDate1Amount,
        chequeDate2Amount,
        chequeDate3Amount,
        chequeDate4Amount,
        chequeDate5Amount,
        chequeDate6Amount,
        chequeDate7Amount,
        chequeDate8Amount,
        chequeDate9Amount,
        chequeDate10Amount,
        actionType,
        sellingPrice,
        advanceAmount,
        remainingDueAmount,
        ringSize,
        city,
        paymentMethod,
        actualSellingPrice,
        cashAmount,
        additionalInformation,
        goldColor,
        effectiveSalesPrice,
        additionalDiscount,
        heldAmount,
        amountToCollect

      } = customerInfo
      if (actualSellingPrice) {
        heldAmount = heldAmount ? heldAmount : 0
        amountToCollect = actualSellingPrice - heldAmount
      }

      // let startDate = moment(createdAt)


      if (chequeDate1) {
        this.setChequeDate(chequeDate1, "chequeDate1")
      }
      if (chequeDate2) {
        this.setChequeDate(chequeDate2, "chequeDate2")
      }
      if (chequeDate3) {
        this.setChequeDate(chequeDate3, "chequeDate3")
      }
      if (chequeDate4) {
        this.setChequeDate(chequeDate4, "chequeDate4")
      }
      if (chequeDate5) {
        this.setChequeDate(chequeDate5, "chequeDate5")
      }
      if (chequeDate6) {
        this.setChequeDate(chequeDate6, "chequeDate6")
      }
      if (chequeDate7) {
        this.setChequeDate(chequeDate7, "chequeDate7")
      }
      if (chequeDate8) {
        this.setChequeDate(chequeDate8, "chequeDate8")
      }
      if (chequeDate9) {
        this.setChequeDate(chequeDate9, "chequeDate9")
      }
      if (chequeDate10) {
        this.setChequeDate(chequeDate10, "chequeDate10")
      }
      description = this.replaceST(description)
      this.orderForm.patchValue({
        firstName: firstName,
        description: description,
        lastName: lastName,
        email: email,
        phone: phone,
        ringSize: ringSize,
        city: city,
        chequeDate1Amount: chequeDate1Amount,
        chequeDate2Amount: chequeDate2Amount,
        chequeDate3Amount: chequeDate3Amount,
        chequeDate4Amount: chequeDate4Amount,
        chequeDate5Amount: chequeDate5Amount,
        chequeDate6Amount: chequeDate6Amount,
        chequeDate7Amount: chequeDate7Amount,
        chequeDate8Amount: chequeDate8Amount,
        chequeDate9Amount: chequeDate9Amount,
        chequeDate10Amount: chequeDate10Amount,
        actualSellingPrice: actualSellingPrice,
        paymentMethod: paymentMethod ? paymentMethod : 0,
        numberOfCheques: numberOfCheques ? numberOfCheques : 0,
        sellingPrice: sellingPrice,
        advanceAmount: advanceAmount,
        remainingDueAmount: remainingDueAmount,
        cashAmount: cashAmount,
        actionType: actionType,
        additionalInformation: additionalInformation,
        goldColor: goldColor,
        effectiveSalesPrice: effectiveSalesPrice,
        additionalDiscount: additionalDiscount,
        heldAmount: heldAmount ? heldAmount : "0",
        amountToCollect: amountToCollect

      })

      this.loading = false

    }, err => {
      this.loading = false
    })
  }

  setChequeDate(date, name) {
    date = moment(date)

    this.orderForm.patchValue({
      [name]: {
        year: parseInt(date.format('YYYY')),
        month: parseInt(date.format('M')),
        day: parseInt(date.format('D'))
      }
    })
  }
  replaceST(des) {
    return des.split("_ST_NL_").join("\n");
  }
  updateRemainingAmount() {
    let remainingDueAmount = this.f.actualSellingPrice.value - this.f.advanceAmount.value
    let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value
    this.orderForm.patchValue({
      remainingDueAmount: remainingDueAmount,
      amountToCollect: amountToCollect
    })
  }
  setFinalSellingPrice() {
    let discountAmount = this.f.additionalDiscount.value ? this.f.additionalDiscount.value : 0
    this.orderForm.patchValue({
      effectiveSalesPrice: this.f.actualSellingPrice.value - discountAmount
    })
  }
  updateAmoutCollect() {
    let heldAmount = this.f.heldAmount.value ? parseInt(this.f.heldAmount.value) : 0
    let actualSellingPrice = this.f.actualSellingPrice.value ? parseInt(this.f.actualSellingPrice.value) : 0
    if (heldAmount > actualSellingPrice) {
      this.orderForm.patchValue({
        heldAmount: 0
      })
      alert("The held amount cannot be higher than actual selling price")
      return false
    }
    let amountToCollect = this.f.actualSellingPrice.value - this.f.heldAmount.value
    this.orderForm.patchValue({
      amountToCollect: amountToCollect
    })
  }

  removeOrder(id) {
    let obj = {
      ids: id,
    }
    this.apollo
      .mutate({
        mutation: deleteCustomerMutution,
        variables: obj
      })
      .subscribe(({ data }: any) => {
        if (data.deleteCustomer) {


        }
      })
  }
}
