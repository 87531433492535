import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Apollo, QueryRef } from 'apollo-angular'
import {environment} from "../../../environments/environment"
import { accountInfo,accountPermissionsInfo } from '../services/user.gql'
const keypair = require('keypair')
var CryptoJS = require("crypto-js");

@Injectable({
    providedIn: 'root'
  })

export class HelperService {
  private querySync:QueryRef<any>
  accountInformation:any ={}
  loading:boolean= false
  permissions:any = {
    "admin": {
      "company": {
        "type_of_contract": "true",
        "create_admin_manager_member": "true",
        "payment": "true",
        "mfa_compulsory": "true",
        "mfa_provide_option_for_managers_members": "true",
        "integration_and_sync_settings": "true",
        "create_teams_and_departments": "true",
        "designate_managers_to_teams": "true",
        "add_members_to_teams": "true",
        "reset_password_for_everyone": "true",
        "deletion_settings": "true",
        "disable_mfa":"true"
      },
      "account": {
        "company_performance": "true",
        "usage_storage_overall": "true",
        "usage_storage_per_member": "true",
        "most_commonly_used_features": "true",
        "total_number_of_seats":"true",
        "seats_outstanding":"true"
      },
      "contacts_and_meetings": {
        "meetings": "true",
        "contacts": "true",
        "sync_per_company_settings": "true",
        "integration_per_company_settings": "true",
        "meeting_notes": "true",
        "contact_add_group_list": "true"
      },
      "sharing": {
        "connections":"false",
          "contact_details": "false",
          "meeting_details": "false",
          "share_meeting_notes": "false"
        }
    },
    "manager": {
      "company": {
        "type_of_contract": "false",
        "create_admin_manager_member": "false",
        "payment": "false",
        "mfa_compulsory": "false",
        "mfa_provide_option_for_managers_members": "false",
        "integration_and_sync_settings": "false",
        "create_teams_and_departments": "false",
        "designate_managers_to_teams": "false",
        "add_members_to_teams": "true",
        "reset_password_for_everyone": "false",
        "deletion_settings": "false",
        "disable_mfa":"true"
      },
      "account": {
        "company_performance": "false",
        "usage_storage_overall": "false",
        "usage_storage_per_member": "false",
        "most_commonly_used_features": "false",
        "total_number_of_seats":"false",
        "seats_outstanding":"false"
      },
      "contacts_and_meetings": {
        "meetings": "true",
        "contacts": "true",
        "sync_per_company_settings": "true",
        "integration_per_company_settings": "true",
        "meeting_notes": "true",
        "contact_add_group_list": "true",
      },
      "sharing": {
        "connections":"false",
          "contact_details": "false",
          "meeting_details": "false",
          "share_meeting_notes": "false"
        }
    },
    "member": {
      "company": {
        "type_of_contract": "false",
        "create_admin_manager_member": "false",
        "payment": "false",
        "mfa_compulsory": "false",
        "mfa_provide_option_for_managers_members": "false",
        "integration_and_sync_settings": "false",
        "create_teams_and_departments": "false",
        "designate_managers_to_teams": "false",
        "add_members_to_teams": "false",
        "reset_password_for_everyone": "false",
        "deletion_settings": "false",
        "disable_mfa":"false"
      },
      "account": {
        "company_performance": "false",
        "usage_storage_overall": "false",
        "usage_storage_per_member": "false",
        "most_commonly_used_features": "false",
        "total_number_of_seats":"false",
        "seats_outstanding":"false"
      },
      "contacts_and_meetings": {
        "meetings": "true",
        "contacts": "true",
        "sync_per_company_settings": "true",
        "integration_per_company_settings": "true",
        "meeting_notes": "true",
        "contact_add_group_list": "true"
      },
      "sharing": {
        "connections":"false",
          "contact_details": "false",
          "meeting_details": "false",
          "share_meeting_notes": "false"
        }
    },
    "no_role": {
      "company": {
        "type_of_contract": "false",
        "create_admin_manager_member": "false",
        "payment": "false",
        "mfa_compulsory": "false",
        "mfa_provide_option_for_managers_members": "false",
        "integration_and_sync_settings": "false",
        "create_teams_and_departments": "false",
        "designate_managers_to_teams": "false",
        "add_members_to_teams": "false",
        "reset_password_for_everyone": "true",
        "deletion_settings": "false",
        "disable_mfa":"false"
      },
      "account": {
        "company_performance": "false",
        "usage_storage_overall": "false",
        "usage_storage_per_member": "false",
        "most_commonly_used_features": "false",
        "total_number_of_seats":"false",
        "seats_outstanding":"false"
      },
      "contacts_and_meetings": {
        "meetings": "true",
        "contacts": "true",
        "sync_per_company_settings": "true",
        "integration_per_company_settings": "true",
        "meeting_notes": "true",
        "contact_add_group_list": "true"
      },
      "sharing": {
        "connections":"false",
          "contact_details": "false",
          "meeting_details": "false",
          "share_meeting_notes": "false"
        }
    }
  }
  constructor(private http: HttpClient, private apollo: Apollo) {
    // this.getAccountInforamtion()
    // alert()
  }
   
     getAccountInforamtion()
    {
      return this.querySync = this.apollo.watchQuery({
        query: accountPermissionsInfo,
        fetchPolicy:"network-only",
        variables: {},
      })
    }
    getPermissions(data){
     let {role,permissions,teams,id} = data.accountInformation
     if(role=='MEMBER' || role=='MANAGER'){
      teams && teams.forEach(element => {
        // element.managers = ['158532']
         if(element.managers.includes(id)) {
          role = 'MANAGER'
          } 
        });
     }
     if(localStorage.getItem('role') == 'ADMIN'){
      role = 'ADMIN'
     }
    

      let info;
      if(permissions && permissions != 'undefined' && permissions != null)
      {
        permissions = permissions.replace(/'/g, '"')
        permissions = permissions.replace('"', "")
        permissions = permissions.replace(/"$/,'')
        permissions = permissions.replace(/'/g,'')
        this.permissions = JSON.parse(permissions)        
      }
      if(role=='ADMIN'){
        info = this.permissions.admin
      }
      else if(role=='MANAGER'){
        info = this.permissions.manager
      }
      else if(role=='MEMBER' || role==null){
        info = this.permissions.member
      }
      else {
        info= this.permissions.no_role 
      }
      info.role = role ? role : 'MEMBER'
      localStorage.setItem("permissions",JSON.stringify(info))
      return info;
      // return this.getPermissions()
    }

    isAdmin(){
      if (localStorage.getItem('role') === 'ADMIN') {
        return true
      }
      return false
    }
    public getTimezonesJSON(){
      return this.http.get("assets/timezones.json")     
    }
     currentToken = () =>{
      return localStorage.getItem('token')
    }
    
    
    cryptKey = () =>{
      const data = [{email: localStorage.getItem('email')}, {id: localStorage.getItem('id')}, {firstName: localStorage.getItem('firstName')}, {lastName: localStorage.getItem('lastName')}]
      // Encrypt
      const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.currentToken()).toString();    
      return ciphertext
    }
    rsaKey = () =>{
      return keypair().public
    }
    getTheDomain(email){
      return email.replace(/.*@/, "").split('.')[0];
    }
}


  
