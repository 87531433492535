import { Component, OnInit } from '@angular/core';
import { environment} from '../../../../environments/environment'
import { HttpClient,HttpHeaders } from '@angular/common/http'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router'

@Component({
  selector: 'app-verify-and-merge',
  templateUrl: './verify-and-merge.component.html',
  styleUrls: ['./verify-and-merge.component.scss']
})
export class VerifyAndMergeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,private http: HttpClient,public router: Router, private formBuilder: FormBuilder) {
    
  }  
   loading:boolean = false
  mergeForm= null
  mergeCode:any = ""
  socialType:any = ""
  errorMessage:any =""
  ud:any
  od:any  
  showAccountBlk:boolean= false
  confirmBlock:boolean = true
  submitted:boolean = false
  ngOnInit() {
    // this.testAPI()
   
    localStorage.clear()
    this.mergeForm = this.formBuilder.group({
      organization: ['', Validators.required],
      website: ['', Validators.required],
      address: ['', Validators.required],
    })

  
  this.activatedRoute.queryParams.subscribe(params => {
      if (params.ud) {
        this.ud =params.ud  
      }
      if (params.od) {
        this.od =params.od  
      }
    });
  }
  get f () {
    return this
    .mergeForm.controls
  }
  verifyAccount()
  {
    if(this.mergeCode && this.ud && this.od)
    {
        this.mergeAccount()
    }
    else if(this.mergeCode)
    {
      this.showAccountBlk = true
      this.getTheOrganizationInfo()
    }

    else{
      alert("Please enter merge code.")
    }
  }
  mergeInternalDashboard(userInfo)
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let {organizationName:companyName,organizationAddress:address,ownerFirstName,ownerLastName,ownerEmail:email,ownerPhone:phone,organizationWebsite:websiteLink,organizationPlan:plan,organizationUsers:numberOfUsers} = userInfo
    if(email == null)
    {
        email =''
    }
    if(phone == null)
    {
      phone =''
    }
    let mergeInput = ""
    mergeInput = `companyName=${companyName}&address=${address}&ownerFirstName=${ownerFirstName}&ownerLastName=${ownerLastName}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}&websiteLink=${websiteLink}&plan=${plan}&numberOfUsers=${numberOfUsers}`
    this.http.post<any>(`https://cpanel.staytouch.com/API/updateCompanyInfo.php`, mergeInput,{ headers }).subscribe(data => {
      this.loading = false
      console.log(data,'data')
      
    },(error) => {
      this.loading = false
      
    });
  }
  mergeAccount(){
    if(!this.ud && !this.od){
      this.getUserIds()
        return false
    }
    this.loading = true
    const userInfo:any ={
     
      "session": "afafdadfdafsf",
      "token":"vf7P7GwvN0l91hfOoABioEZ59NoME9CG",
      "merge": {
         "code": this.mergeCode
      }
   }
    if(this.ud && this.od)
    {
      userInfo.user =   {
        "userId": this.ud,
        "orgId": this.od
       }
    }
    else {
      this.submitted = true
      if (this.mergeForm.invalid) {
        alert("Please enter all required fields.")   
        this.loading = false
        return false
      }
      userInfo.user =    {
        "companyName": this.f.organization.value,
        "companyAddress": this.f.address.value,
        "authorizedPlan": "Model basic",
        "authorizedUsers": "10",
        "website": this.f.website.value,
        "userId": this.ud
       }

    }
    // this.mergeInternalDashboard(userInfo)
    // return


    
  //  console.log(userInfo,'ise')
  //  return
   
  //  console.log(this.f)
   let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}
    this.http.post<any>(`${environment.AUTH_URL}signUpB2B`, userInfo,headers).subscribe(data => {
      this.loading = false
     
      if(data.status == "SUCCESS")
      {
        if(this.ud && this.od)
        {
            //no changes
        }
        else
        {
          this.mergeInternalDashboard(data.data)
        }
          alert("Successfully completes the registration of your business account and migration from your personal account.")
          this.router.navigate(['/login'])
      }
      else if(data.status == "B2B_ACCOUNT_EXISTS")
      {
        alert("Account already exists.")
      }
      else if(data.status == "CODE_VERIFICATION_FAILED")
      {
        this.showAccountBlk = false
        alert("Invalid verification code.")
      }
      else{
        alert("Error while merge the details")
      }
      
    },(error) => {
      this.loading = false
      console.log('there was an error sending the query', error);
      alert('Server Error')
      if(error=="INVALID_AUTHORIZATION")
      {
      }
    });
  }

  getUserIds(){
    this.loading = true
     let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}
    this.http.get<any>(`https://api.staytouch.com/v2.1/public/getUserData/${this.mergeCode}`,headers).subscribe(data => {
      this.loading = false
      if(data.status == 'CODE_VALID')
      {
        console.log(data)
        this.loading = false
        this.ud = data.userId
        this.od = data.orgId
         this.mergeAccount()
      }
      
      else{
        alert("Code verification failed.")
      }
    },(error) => {
      this.loading = false
      
    });
  }
  goToVerify(){
    this.showAccountBlk = false
  }

  testAPI()
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let mergeInput = ""
    mergeInput = `companyName=test&address=kkd&ownerFirstName=ramya&ownerLastName=priya&email=ramya+12@krify.net&phone=${encodeURIComponent("+919391")}&websiteLink=https://meeting.staytouch.com&plan=Plan A&numberOfUsers=12`
    this.http.post<any>(`https://localhost/bitbucketworkspace/staytouch-repos/dashboard/API/updateCompanyInfo.php`, mergeInput,{ headers }).subscribe(data => {
      this.loading = false
      console.log(data,'data')
      
    },(error) => {
      this.loading = false
      
    });
  }
  getTheOrganizationInfo(){
    this.loading = true
    this.http.get<any>(`https://api.staytouch.com/v2.1/public/getUserData/${this.mergeCode}`).subscribe(data => {
      this.loading = false
        if(data.userId){
          this.getUserInfo(data.userId)
        }
    },(error) => {
      this.loading = false
      
    });
  }
  getUserInfo(userId){
    this.loading = true
    this.http.get<any>(`https://api.staytouch.com/v2.1/public/getUserInfo/${userId}/vf7P7GwvN0l91hfOoABioEZ59NoME9CG`).subscribe(data => {
      this.loading = false
      if(data.email){
        this.getCompanyInfo(data.email)
      }
    },(error) => {
      this.loading = false
      
    });
  }
  getCompanyInfo(email){
    this.loading = true
    let inpubObj = `email=${email}`
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    this.http.post<any>(`https://cpanel.staytouch.com/API/getCompanyInfo.php`,inpubObj,{headers}).subscribe(data => {
      this.loading = false
      if(data && data.owner_email){
        let {website_link,company_address,company_name} = data
          this.mergeForm.patchValue({
            organization:company_name,
            website:website_link,
            address:company_address,
          })
      }
     
    },(error) => {
      this.loading = false
      
    });
  }
}
