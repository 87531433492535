<div class="row justify-content-center " *ngIf="member">
    <app-loading *ngIf="loading"></app-loading>
    <div class="col-md-12  px-0">
      <div class="d-flex card  m-2 pb-3">
        <div class="card-header py-3 mb-4 d-flex justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary ">Meetings by company</h6>
          <a [routerLink]="['/admin-dashboard']">Back</a>

        </div>  
        <div class="d-block d-md-flex pb-4 px-sm-4 " >
          <div class="   p-0"  style="width: 200px;" >
            <span class="font-weight-bold"
             >Company name &nbsp;
          </span>
          <span class="themeBrd"></span>
          </div>
          <div class="col-lg-10 d-flex pl-4 text-left ">
            <div class="col-md-3 text-gray2 tiny-font1 pr-0 d1-none d-md1-none d-md1-block font-weight-bold">
              Number of meetings &nbsp;
              <span class="themeBrd"></span>
            </div>
            <div class="col-md-9 text-gray2 tiny-font1 p-0 d-none d-md-block text-left font-weight-bold">
              Latest meeting &nbsp;
              <span class="themeBrd"></span>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let company of performanceInfo;let j=index">
          <div class="d-block d-md-flex pb-2 px-4 " >
            <div class="   p-0"  style="width: 200px;" >
              <span class=" default-color"
               ><a  [routerLink]="['/meetings', 'c-'+company.companyName]">{{company.companyName}} </a>&nbsp;
            </span>
            <span class="themeBrd"></span>
            </div>
            <div class="col-lg-10 d-flex pl-4 text-left ">
              <div class="col-md-3 text-gray2 tiny-font1 pr-0 d1-none d-md1-none d-md1-block ">
                {{company.numberofMeetings}}&nbsp;
                <span class="themeBrd"></span>
              </div>
              <div class="col-md-9 text-gray2 tiny-font1 p-0 d-none d-md-flex text-left " *ngIf="company.lastMeetingTitle">
  
                <div class="date-cs-bg1  pl-0 " style="width:53px;border: 0px;">
                  <div class="d-flex justify-content-between  text-left ">
                    <div>
                      <label style=" color: #2E2E35 !important;font-weight: 500;">Date</label>
                      <h6 style=" color: #2E2E35 !important;font-weight: 500;">
                        {{
                          company.lastMeetingStartTime | amParse: 'DD-MM-YYYY' | amDateFormat: 'DD'
                        }}
                      </h6>
        
                      <h6 class="text-uppercase" style=" color: #2E2E35 !important;font-weight: 500;">
                        {{
                          company.lastMeetingStartTime | amParse: 'DD-MM-YYYY' | amDateFormat: 'MMM'
                        }}
                      </h6>
                    </div>
                  
                  </div>
                </div>
              
                <div
                class="w-100 "
              >
                <!-- {{meeting | json}} -->
                <div 
                  class="d-md-flex justify-content-between summary-list mx-md-3 m-0  d-block cursor-pointer align-items-center1"
                >
                <div  class="col-md-7 pl-md-0  pr-0 pb-0">
                  <label>Title</label>
                  <h6 class=" text-capitalize ">
                    {{company.lastMeetingTitle}}        
                    </h6>
                    <p class="text-gray m-0">{{
                      company.lastMeetingStartTime  | amDateFormat: 'h:mm A'
                    }}
                    - {{ company.lastMeetingEndTime  | amDateFormat: 'h:mm A' }}</p>
                    <!-- <p class="text-gray m-0">  {{company.lastMeetingLocation}}  </p> -->
                    
                </div>
            
                  <div class="col-md-5  pl-2 m-0  pb-2 flex-column d-flex" >
                    <label>Participants</label>
                    <h6 *ngIf="company.lastEventParticipants.length == 0" class="text-weight-bold pt-2">No participants</h6>
    
                    <ng-container *ngFor="let contact of company.lastEventParticipants; let j = index">
                      <h6 class="" *ngIf="j < 2">
                        <span >
                          <span class="text-capitalize"> {{ contact.firstName }} {{ contact.lastName }}</span>
                          <i *ngIf="contact.title" class="ds-font">, {{ contact.title }}</i>
                          <span *ngIf=" contact.company">, </span>
                          <i class="ds-font">{{ contact.company }}</i>
                            
                        </span>
                      </h6>
                      <div *ngIf="j == 2" class="text-italic along-with ">
                        <a
                          data-toggle="modal"
                          data-target="#participantsModal"
                          class="theme-color"
                          (click)="isOpen = !isOpen; "
                        >
                          <small class="ds-font"
                            >{{ 'MEETING.ALONG_WITH' | translate }}
                            <b>{{ company.lastEventParticipants.length - 2 }}</b>
                            {{ 'COMMON.PARTICIPANTS' | translate }}
                          </small>
                        </a>
                      </div>
                    </ng-container>
                    
                  </div>
                </div>
              </div>
              </div>
            </div>
  
          </div>
          <hr class="col-md-11 m-auto pb-2" *ngIf=" j < 5"   />
        </ng-container>
      </div>
     
    
    </div>
  </div>
  
  