import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Apollo, QueryRef } from 'apollo-angular'
import { TranslateService } from '@ngx-translate/core'
const moment = require('moment-timezone')
import {
  getMembersBasicQuery,
} from '../../../services/admin.gql'
import {
createProductMutution,categoryQuery,updateProductMutution,createProductImageMutution,productsQuery,deleteAttachmentMutution
} from '../../../services/products.gql'
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit {
  categoriesList:any =[]
  public members: any
  private query: QueryRef<any>
  private query1: QueryRef<any>
  private query2: QueryRef<any>

  private query$: any
  private query1$: any
  tempImages:any = []
  urlIndex:any =0
  permissionsRes:any ={}

  coloredStonesTyeps:any =[
    "Emerald", "Sapphire", "Rainbow Sapphire", "Ruby", "Malachite", "Onyx", "Lapiz", "Nacre", "Turquoise", "Blue Topaz", "Quartz", "Tourmaline","Other"
  ]
  goldColors:any =[
    "ROSE", "WHITE", "YELLOW","MIXED"
  ]
  locationArray:any=[
    "Hong Kong", "Dubai", "Rabat","Casa","Consignment"
  ]
  
  urls = [
    {
      id:1,
      url:'',
      file:''
    }
  ]
  submitted:boolean=false;
  loading:boolean = false
  buttonEl: any
  placement = 'bottom-left'
  public productForm: FormGroup
  productId:any
  variantImages:any =[]
  selectedVarientIndexForImages:any=0
  productInfo:any = {}
  page:any =1
  p:any
  role:any = ''
  columns:any
  filterByColumn:any
  filterColumn :any
  filterColumnValue:any
  minValue:any
  maxValue:any
  searchText:any
  column:any
  itemsPerPage:any
  isUserAllow:boolean = true
accountId:any
  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,private toastr: ToastrService,private router: Router,private modalService: NgbModal,private helperService:HelperService,  private el: ElementRef
    ) {    
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {
      let {maxValue,minValue,filterColumnValue,filterColumn,columns,filterByColumn,searchText,itemsPerPage} = this.router.getCurrentNavigation().extras.state
      // console.log(this.router.getCurrentNavigation().extras.state,'this.router.getCurrentNavigation().extras.state')
      this.column=columns
      this.filterByColumn =filterByColumn
      this.filterColumn = filterColumn
       this.filterColumnValue = filterColumnValue
       this.minValue=minValue
       this.maxValue=maxValue
       this.searchText = searchText
       this.itemsPerPage = itemsPerPage
      }
       this.productForm = this.formBuilder.group({
        productCategory: [0],
        productName: [''],
        designNumber: [''],
        itemNumber:['' ],
        description: [''],
        company:[''],
        otherFieldX: [''],
        otherFieldY: [''],
        other: [''],
        status:[false],
        stockQuantity:[0],
        dateImport:[ {
          year: parseInt(moment().format('YYYY')),
          month: parseInt(moment().format('M')),
          day: parseInt(moment().format('D'))
        }],
        supplierInvoiceDate:[],
        cost:[0.0],
        costAED:[0.0],
        costMAD:[0.0],
        costUSD:[0.0],
        margin:[0.0],
        usdAedFx :[0.0],
        aedMadFx:[0.0],
        displayedSellingPrice:[0.0],
        discount:[0.0],
        sellingPrice:[0.0],
        sellingPriceMargin:[0.0],
        timezone: [moment.tz.guess()],
        users:[[]], 
        supplierName:[''],
        supplierInvoiceNumber:[''],        
        tajNumber:['',Validators.required],
        displayedSellingPriceInMAD:[''],
        maximumDiscountAllowed:[0.0],
        minimumSellingPriceInMAD:[''],
        impliedMinimumSellingPriceMargin:[''],
        goldColor:[''],
        goldWeight:[''],
        diamondWeight:[''],
        numberOfDiamondStones:[''],
        coloredStonesType1:[''],
        coloredStonesWeight1:[''],
        coloredStonesType2:[''],
        coloredStonesWeight2:[''],
        size:[''],
        comment:[''],
        productLocation: [''],
        coloredStonesWeight3:[''],
        coloredStonesType3:[''],
        goldPriceUsd:[''],
        diamondPriceUsd:[''],
        coloredStonesPriceUsd:[''],
        makingChargesUsd:[''],
        goldInvoiceNumber:['']
    })
    this.getFxValue('usd')
    this.getFxValue('aed')
    

   }

  ngOnInit() {
    // fetch("https://www.dropbox.com/sh/0vjhaonfxt1xuol/AABIN6NG5IKWmDU9lff0C-nNa/1-Logo?dl=0&preview=Logo_sign_white.png&subfolder_nav_tracking=1").then((r)=>{r.text().then((d)=>{let CONTENT = d})})
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.productId = params.id
        // this.getProjectInfo()
      }
      if(params.page){
        this.page = params.page
      }
    })
    
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      this.role = data.accountInformation.role 
      this.accountId = data.accountInformation.id
      if(this.role == 'SALES' && !this.checkUserIds()){
        
        this.router.navigate(['/products',this.page],
        {
          state: {
            columns: this.column,
            searchText:this.searchText,
            filterByColumn:this.filterByColumn,
            filterColumn:this.filterColumn,
            filterColumnValue : this.filterColumnValue,
            minValue : this.minValue,
            maxValue : this.maxValue,
            itemsPerPage:this.itemsPerPage
          }
        })
      }
      
    })
    this.getUserDetails()
    this.getCategories()
  }
  addImage(trigger=false){
    this.variantImages.push({
      imageId:1,
      url:'',
      file:''
    })
    let imageId = this.variantImages.length -1
    setTimeout(()=>{
      if(trigger){
        let element: HTMLElement = document.getElementById('imageId'+imageId) as HTMLElement;
        element.click();
      }
    },10)
    
  }

  getCategories(){
    this.loading = true
    this.query1 = this.apollo.watchQuery({
      query:categoryQuery,
      variables:{
      },
      fetchPolicy:"network-only"
      
    })
    this.query1$ =this.query1.valueChanges.subscribe(result => {
      this.loading = false
      if(result.data.categories)
      {
        this.categoriesList = result.data.categories
      }
      
      },error => {
        this.loading = false
      })
  }

  saveProduct () {
    // if(this.variantImages.length==0){
    //   alert('Product image is required')
    // }
    this.submitted = true
    // const controls = this.projectForm.controls
    const productCategory = this.f.productCategory.value
    const productName = this.f.productName.value
    const designNumber = this.f.designNumber.value
    const itemNumber = this.f.itemNumber.value
    const cost = parseFloat(this.f.costUSD.value)
    const description = this.f.description.value
    const company = this.f.company.value
    const otherFieldX = this.f.otherFieldX.value
    const otherFieldY = this.f.otherFieldY.value
    const other = this.f.other.value
    const status = this.f.status.value ? this.f.status.value : true
    const stockQuantity = parseInt(this.f.stockQuantity.value)
    const users = this.f.users.value
    const costUsd =  this.f.costUSD.value  ? parseFloat(this.f.costUSD.value) : 0
    const costMad = this.f.costMAD.value  ?parseFloat(this.f.costMAD.value) : 0
    const costAed = this.f.costAED.value  ?  parseFloat(this.f.costAED.value) : 0
    const margin = this.f.margin.value  ? parseFloat(this.f.margin.value) : 0
    const displayedSellingPrice = this.f.displayedSellingPriceInMAD.value ? parseFloat(this.f.displayedSellingPriceInMAD.value) : 0.0
    const discount = parseFloat(this.f.discount.value)
    const sellingPrice = this.f.sellingPrice.value  ? parseFloat(this.f.sellingPrice.value) : 0
    const sellingPriceMargin = this.f.impliedMinimumSellingPriceMargin.value  ? parseFloat(this.f.impliedMinimumSellingPriceMargin.value) : 0
    const usdAedFx = this.f.usdAedFx.value  ? parseFloat(this.f.usdAedFx.value) : 0
    const aedMadFx = this.f.aedMadFx.value  ? parseFloat(this.f.aedMadFx.value) : 0

    const currency = "USD"

    const supplierName = this.f.supplierName.value ? this.f.supplierName.value.trim():"" 
    const supplierInvoiceNumber = this.f.supplierInvoiceNumber.value
    const tajNumber = this.f.tajNumber.value
    // const displayedSellingPrice = this.f.displayedSellingPriceInMAD.value
    const maxAllowed = this.f.maximumDiscountAllowed.value?parseFloat(this.f.maximumDiscountAllowed.value):0
    const minSellingPrice = this.f.minimumSellingPriceInMAD.value ? parseFloat(this.f.minimumSellingPriceInMAD.value) : ''
    // const sellingPriceMargin = this.f.impliedMinimumSellingPriceMargin.value
    const goldColor = this.f.goldColor.value
    const goldWeight = this.f.goldWeight.value || this.f.goldWeight.value ==0 ? parseFloat(this.f.goldWeight.value) : null
    const diamondWeight = this.f.diamondWeight.value || this.f.diamondWeight.value ==0 ? parseFloat(this.f.diamondWeight.value) :null
    const noDiamondStones = this.f.numberOfDiamondStones.value || this.f.numberOfDiamondStones.value ==0 ? parseInt(this.f.numberOfDiamondStones.value) : null
    const coloredStonesTypeOne = this.f.coloredStonesType1.value
    const coloredStonesWeightOne = this.f.coloredStonesWeight1.value? this.f.coloredStonesWeight1.value.toString():''
    const coloredStonesTypeTwo = this.f.coloredStonesType2.value
    const coloredStonesWeightTwo =this.f.coloredStonesWeight2.value ?  this.f.coloredStonesWeight2.value.toString() : ''
    const coloredStonesType3 = this.f.coloredStonesType3.value
    const coloredStonesWeight3 =this.f.coloredStonesWeight3.value ?  this.f.coloredStonesWeight3.value : ''
    const size = this.f.size.value
    const comment = this.f.comment.value
    const productLocation = this.f.productLocation.value
    const goldInvoiceNumber =this.f.goldInvoiceNumber.value ?  this.f.goldInvoiceNumber.value : ''
    const makingChargesUsd =(this.f.makingChargesUsd.value || this.f.makingChargesUsd.value == 0) ?  parseFloat(this.f.makingChargesUsd.value) : null
    const goldPriceUsd =this.f.goldPriceUsd.value || this.f.goldPriceUsd.value ==0?  parseFloat(this.f.goldPriceUsd.value) : null
    const coloredStonesPriceUsd = (this.f.coloredStonesPriceUsd.value || this.f.coloredStonesPriceUsd.value ==0) ?  parseFloat(this.f.coloredStonesPriceUsd.value) : null
    
    const diamondPriceUsd =this.f.diamondPriceUsd.value || this.f.diamondPriceUsd.value==0 ?  parseFloat(this.f.diamondPriceUsd.value) : null

    // const product = parseFloat(this.f.sellingPriceMargin.value
    let dateImport = this.f.dateImport.value
    if(dateImport){
      dateImport = `${dateImport.year}-${dateImport.month}-${dateImport.day}`
    dateImport = moment(dateImport,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
    dateImport = moment
    .tz(dateImport, this.f.timezone.value)
    .format('YYYY-MM-DDTHH:mm:ss.SSSZ')

    }
    
    let supplierInvoiceDate = this.f.supplierInvoiceDate.value
    if(supplierInvoiceDate){
		supplierInvoiceDate = `${supplierInvoiceDate.year}-${supplierInvoiceDate.month}-${supplierInvoiceDate.day}`
    // console.log(supplierInvoiceDate,'supplierInvoiceDate')
		supplierInvoiceDate = moment(supplierInvoiceDate,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
		supplierInvoiceDate = moment
		.tz(supplierInvoiceDate, this.f.timezone.value)
		.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
	}
    const invalid = [];
    const controls = this.productForm.controls;
    // for (const name in controls) {
    //     if (controls[name].invalid) {
    //         invalid.push(name);
    //     }
    // }
    for (const key of Object.keys(this.productForm.controls)) {
		if (this.productForm.controls[key].invalid) {
			const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
			invalidControl.focus();
			break;
		 }
	}
    if (this.productForm.invalid) {
            this.translate
            .get('MESSAGES.PLEASE_ENTER_THE_REQUIRED_FIELDS')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })
            
      return
    }
    let productObj:any ={
      productCategory,
      productName,
      designNumber,
      itemNumber,
      cost,
      description,
      company,
      otherFieldX,
      otherFieldY,
      other,
      status,
      stockQuantity,
      dateImport,
      users,
      costUsd,
      costMad,
      costAed,
      sellingPrice,
      discount,
      displayedSellingPrice,
      margin,
      currency,
      supplierName,
      supplierInvoiceNumber,
      tajNumber,
      goldColor,
      coloredStonesTypeOne,
      coloredStonesTypeTwo,
      maxAllowed,
      size,
      comment,
      productLocation,
      supplierInvoiceDate,
      usdAedFx,
      aedMadFx,
      coloredStonesType3,
      coloredStonesWeight3,
      goldInvoiceNumber,
      diamondPriceUsd,
      coloredStonesPriceUsd,
      makingChargesUsd,
      goldPriceUsd
    }
    if(noDiamondStones || noDiamondStones ==0){
      productObj.noDiamondStones =noDiamondStones
    }
    if(coloredStonesWeightTwo || coloredStonesWeightTwo ==0) {
      productObj.coloredStonesWeightTwo =coloredStonesWeightTwo
    }
    if(coloredStonesWeightOne || coloredStonesWeightOne ==0){
      productObj.coloredStonesWeightOne =coloredStonesWeightOne
    }
    
    // if(maxAllowed){
    //   productObj.maxAllowed =maxAllowed
    // }
    if(maxAllowed){
      productObj.minSellingPrice =minSellingPrice
    }
    else{
      productObj.minSellingPrice =displayedSellingPrice
    }
    
    if(goldWeight || goldWeight==0){
      productObj.goldWeight = goldWeight
    }
    if(diamondWeight || diamondWeight==0){
      productObj.diamondWeight =diamondWeight
    }
    if(sellingPriceMargin){
      productObj.sellingPriceMargin =sellingPriceMargin
    }
    else{
      productObj.sellingPriceMargin =margin
    }

    if(productObj.productCategory){
      let filterObj  = this.categoriesList.filter(item=>item.id == productObj.productCategory)
      if(filterObj[0]){
        productObj.category = filterObj[0].name
      }
      
    }
    // productObj = Object.entries(productObj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    if(!this.productId){
      productObj = this.clean(productObj)
      productObj=   Object.entries(productObj).reduce((a,[k,v]) => (v == undefined && v == '' && v == "" && v == null ? a : (a[k]=v, a)), {})
    }

    // console.log(productObj)

    // if(noDiamondStones || noDiamondStones ==0){
    //   productObj.noDiamondStones =noDiamondStones
    // }
    productObj.stockQuantity = stockQuantity >0 ? stockQuantity:0
    // return
    if(this.productId){
      productObj=   Object.entries(productObj).reduce((a,[k,v]) => ( v == null ? a : (a[k]=v, a)), {})
      productObj.coloredStonesPriceUsd =  productObj.coloredStonesPriceUsd || 0
      productObj.goldPriceUsd =  productObj.goldPriceUsd || 0
      productObj.makingChargesUsd =  productObj.makingChargesUsd || 0
      productObj.margin =  productObj.margin || 0
      productObj.cost =  productObj.cost || 0
      productObj.dateImport = dateImport ? dateImport : "0000-01-01T00:00:00Z"
      productObj.supplierInvoiceDate = supplierInvoiceDate ? supplierInvoiceDate : "0000-01-01T00:00:00Z"

      productObj.diamondPriceUsd =  productObj.diamondPriceUsd || 0
      if(this.productInfo.goldInvoiceNumber && this.productInfo.goldInvoiceNumber != goldInvoiceNumber){
        productObj.goldInvoiceNumber = goldInvoiceNumber ? goldInvoiceNumber : ' '
      }
      if(this.productInfo.diamondWeight && this.productInfo.diamondWeight != diamondWeight){
        productObj.diamondWeight = diamondWeight ? diamondWeight : 0
      }
      if(this.productInfo.noDiamondStones && this.productInfo.noDiamondStones != noDiamondStones){
        productObj.noDiamondStones = noDiamondStones ? noDiamondStones : 0
      }
      if(this.productInfo.coloredStonesWeight3 && this.productInfo.coloredStonesWeight3 != coloredStonesWeight3){
        productObj.coloredStonesWeight3 = coloredStonesWeight3 ? coloredStonesWeight3 : ' '
      }
      if(this.productInfo.goldWeight && this.productInfo.goldWeight != goldWeight){
        productObj.goldWeight = goldWeight ? goldWeight : 0
      }
      this.updateProduct(productObj)
    }
    else{
      this.createProduct(productObj)
    }
   
   
  }
  createProduct (productObj) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createProductMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          if(this.variantImages.length > 0){
            this.createProductImage(data.createProduct[0])
          }
          this.toastr.info("product created successfully.")
          this.router.navigate(['/product-details',data.createProduct[0].id,this.page], {
            state: {
              columns: this.column,
              searchText:this.searchText,
              filterByColumn:this.filterByColumn,
              filterColumn:this.filterColumn,
              filterColumnValue : this.filterColumnValue,
              minValue : this.minValue,
              maxValue : this.maxValue,
              itemsPerPage:this.itemsPerPage
            }
          })
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }
  updateProduct (productObj) {
    let variantImages = this.variantImages
    productObj.id= this.productId
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateProductMutution,
        variables: productObj,
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading = false
          if(data.updateProduct){
            if(variantImages.length > 0){
              variantImages.forEach(element => {
                if(element.file && !element.signedPutURL){
                  this.updateProductImage(data.updateProduct,variantImages,element.file)
                }
                // else{
                //   this.updateProductImage(data.updateProduct,variantImages)
                // }
                
              });
              this.toastr.info("Product updated successfully.")
              // this.router.navigate(['/products',this.page,1])
              this.router.navigate(['/product-details',productObj.id,this.page], {
                state: {
                  columns: this.column,
                  searchText:this.searchText,
                  filterByColumn:this.filterByColumn,
                  filterColumn:this.filterColumn,
                  filterColumnValue : this.filterColumnValue,
                  minValue : this.minValue,
                  maxValue : this.maxValue,
                  itemsPerPage:this.itemsPerPage
                }
              })
              // this.createProductImage(data.updateProduct)
            }
            else{
              this.toastr.info("Product updated successfully.")
              this.router.navigate(['/product-details',productObj.id,this.page], {
                state: {
                  columns: this.column,
                  searchText:this.searchText,
                  filterByColumn:this.filterByColumn,
                  filterColumn:this.filterColumn,
                  filterColumnValue : this.filterColumnValue,
                  minValue : this.minValue,
                  maxValue : this.maxValue,
                  itemsPerPage:this.itemsPerPage
                }
              })
            }
          }
          else{
            this.toastr.info("Unable to update the product.")
          }
         
          
          
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }
  createProductImage (productObj) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createProductImageMutution,
        variables: {
          productId:productObj.id,
          numberOfImages:this.variantImages.length
        },
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading=false
          if(data.createProductImage){
            data.createProductImage.forEach((element,j) => {
              if(this.variantImages[j].file){
                this.imageUploadtoS3(element.signedPutURL,this.variantImages[j].file)
              }
            });
          }
        },
        error => {
          console.log(error)
          this.loading= false
         
        }
      )
  }
  updateProductImage (productObj,variantImages,file) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createProductImageMutution,
        variables: {
          productId:productObj.id,
          numberOfImages:1
        },
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          this.loading=false
          if(data.createProductImage){
            data.createProductImage.forEach((element,j) => {
              // if(variantImages[j].file && !element.signedPutURL){
                this.imageUploadtoS3(element.signedPutURL,file)
              // }
            });
          }
        },
        error => {
          console.log(error)
          this.loading= false
         
        }
      )
  }
  get f () {
    return this.productForm.controls
  }
  setSellingPrice(){
    let margin = this.f.margin.value ? this.f.margin.value : 0
    // let  displayedSellingPrice = parseFloat(this.f.costMAD.value) + parseFloat(margin)
    let displayedSellingPrice = parseFloat(this.f.costMAD.value) / (1- (margin/100))

    this.productForm.patchValue({
      displayedSellingPriceInMAD: Math.round(displayedSellingPrice)
    })
    this.getDiscountPrice()
  }
  getDiscountPrice(){
    let discuountPrice = this.f.displayedSellingPriceInMAD.value - (this.f.displayedSellingPriceInMAD.value * this.f.maximumDiscountAllowed.value / 100)
    this.productForm.patchValue({
      minimumSellingPriceInMAD: Math.round(discuountPrice)
    })
    this.getImpliedMargin()
    // alert(discuountPrice)
  }
  currencyConverter(type){
    let base = 'USD'
    if(type==2){
      base = 'AED'
    }
    if(type==3){
      base = 'MAD'
    }

    this.httpClient.get(`https://api.exchangerate-api.com/v4/latest/${base}`, {
    }).subscribe((response:any) => {
    //  if()
     let usd = response.rates.USD
     let mad = response.rates.MAD
     let aed = response.rates.AED
     if(type ==1){
       let costAED = Math.round(this.f.usdAedFx.value * this.f.costUSD.value)
       this.productForm.patchValue({
        costAED: Math.round(this.f.usdAedFx.value * this.f.costUSD.value),
       costMAD: Math.round(this.f.aedMadFx.value * costAED),
       
        // aedMadFx:mad
        // costMAD: Math.round(mad * this.f.costUSD.value),
       })
      //  if(!this.f.aedMadFx.value){
      //   this.productForm.patchValue({
      //     aedMadFx:mad
      //   })
      //  }
      //  if(!this.f.usdAedFx.value){
      //   this.productForm.patchValue({
      //     usdAedFx:aed
      //   })
      //  }
     }
     if(type ==2){
      this.productForm.patchValue({
        costUSD: Math.round(usd * this.f.costAED.value),
        costMAD: Math.round(mad * this.f.costAED.value),
        // usdAedFx:aed,
        // aedMadFx:mad
       })
    }
    if(type ==3){
      this.productForm.patchValue({
        costAED: Math.round(aed * this.f.costMAD.value),
        costUSD: Math.round(usd * this.f.costMAD.value),
        // usdAedFx:aed,
        // aedMadFx:mad
       })
    }
    
    this.setSellingPrice()
    if(type !=2){
      this.setAed()
    }
    if(type !=3){
      this.setMad()
    }
    
    //  if
    },err=>{

      if(type ==1){
        let mad = 8.92
        let aed = 3.67
        let usd = 1
        this.productForm.patchValue({
          costAED: Math.round(mad * this.f.costUSD.value),
          costMAD: Math.round(aed * this.f.costUSD.value)
        })
      }
      if(type ==2){
        let mad = 2.7 
        let aed = 1
        let usd = 0.27
        this.productForm.patchValue({
          costUSD: Math.round(usd * this.f.costAED.value),
          costMAD: Math.round(mad * this.f.costAED.value)
        })
      }
      if(type ==3){
        let mad = 1 
        let aed = 0.4
        let usd = 0.1
        this.productForm.patchValue({
          costAED: Math.round(aed * this.f.costMAD.value),
          costUSD: Math.round(usd * this.f.costMAD.value)
        })
      }
      this.setSellingPrice()
      this.setAed()
    this.setMad()
    });
    
  }

  getUserDetails () {
    this.query = this.apollo.watchQuery({
      query: getMembersBasicQuery,
      fetchPolicy:'network-only'
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
     
      this.members = data.users
     this.members =  data.users.map(element => {
        element.name = `${element.firstName} ${element.lastName}`
        return element
      });
      if(this.productId){
        this.getProductInfo()
      }
      
    })
  }
  getImpliedMargin(){
    // let displayPrice =this.f.displayedSellingPriceInMAD.value?this.f.displayedSellingPriceInMAD.value:0
    // let minPrice =this.f.minimumSellingPriceInMAD.value?this.f.minimumSellingPriceInMAD.value:0
    // let margin =   parseFloat(displayPrice) -parseFloat(minPrice)  
    let margin =0
    if(this.f.minimumSellingPriceInMAD.value){
      margin = ((this.f.minimumSellingPriceInMAD.value - this.f.costMAD.value) / this.f.minimumSellingPriceInMAD.value)*100
    }
    // let margin = this.f.sellingPrice.value - 
    this.productForm.patchValue({
      impliedMinimumSellingPriceMargin:Math.round(margin)
    })
  }
  setMargin(){
    let margin = ((this.f.displayedSellingPriceInMAD.value - this.f.costMAD.value) / this.f.displayedSellingPriceInMAD.value)*100
    // let margin = this.f.sellingPrice.value - 
    this.productForm.patchValue({
      margin:Math.round(margin)
    })
  }
  detectFiles(event,i) {
    // this.urls = [];
    let files:any = event.target.files;
    if(event.target.files.length > 1 ){
      let j =0;
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          if(!this.variantImages[j]){
            this.variantImages[j] = []
          }
          this.variantImages[j].url=e.target.result
          this.variantImages[j].file=file
          if(this.variantImages[j].signedPutURL){
            this.imageUploadtoS3(this.variantImages[j].signedPutURL,file)
          }
          j++;
          // this.setTempFiles()
          
        }
        reader.readAsDataURL(file);
      }
    }else{
      if (files) {
        for (let file of files) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.variantImages[i].url=e.target.result
            this.variantImages[i].file=file
            if(this.variantImages[i].signedPutURL){
              this.imageUploadtoS3(this.variantImages[i].signedPutURL,file)
            }
            // this.setTempFiles()
            
          }
          reader.readAsDataURL(file);
        }
      }
    }
    
  
  }
  setTempFiles(){
    // this.tempImages = [];
    // this.variantImages.forEach(element => {
    //   if(element.file){
    //     this.tempImages.push({
    //       url:element.url,
    //       file:element.file,
    //       imageId:element.imageId,
    //       id:element.id
    //     })
    //   }
    // });
  }
  imageUploadtoS3 (imageUrl, file) {
    
    this.loading= true
    let headers = new HttpHeaders({
      'Content-Type': 'image/jpeg'
    })
    let options = {
      headers: headers
    }
    const formData = new FormData()
    formData.append('file', file)
    this.httpClient
      .put<any>(imageUrl, file, options)
      .subscribe((event: any) => {
        this.loading = false
      },err=> {
        this.loading=false
      })
  }

  

  getProductInfo () {
    this.loading = true
    this.query2 = this.apollo.watchQuery({
      query: productsQuery,
      fetchPolicy:'network-only',
      variables:{
        id:this.productId
      }
    })

     this.query2.valueChanges.subscribe(({ data }: any) => {
      
     this.productInfo = data.products[0]
    let {productName,description,productCategory,designNumber,itemNumber,cost,costAed,costUsd,costMad,margin,displayedSellingPrice,discount,sellingPrice,sellingPriceMargin,status,host,manager,progress, team,deadline,createdAt,users,company,otherFieldX,otherFieldY,other,stockQuantity,dateImport,photos,supplierName,
      supplierInvoiceNumber,
      tajNumber,
      maxAllowed,
      minSellingPrice,
      goldColor,
      goldWeight,
      diamondWeight,
      noDiamondStones,
      coloredStonesTypeOne,
      coloredStonesWeightOne,
      coloredStonesTypeTwo,
      coloredStonesWeightTwo,
      size,
      comment,
      productLocation,
      supplierInvoiceDate,
      usdAedFx,
      aedMadFx,
      coloredStonesType3,
      coloredStonesWeight3,
      makingChargesUsd,
      goldInvoiceNumber,
      goldPriceUsd,
      diamondPriceUsd,
      coloredStonesPriceUsd
    } = data.products[0]

    // let startDate = moment(createdAt)
    let dateImportFormat = moment(dateImport)
    if(dateImportFormat.format('YYYY')!='Invalid date'){
      this.productForm.patchValue({
        dateImport: {
          year: parseInt(dateImportFormat.format('YYYY')),
          month: parseInt(dateImportFormat.format('M')),
          day: parseInt(dateImportFormat.format('D'))
        }
      })
     
    }
    else{
      this.productForm.patchValue({
        dateImport:null
      })
    }
    let endDate = moment(deadline)
        let memberIds = users.map(element => {
           return element.id
        });
        if(supplierInvoiceDate){
             supplierInvoiceDate = moment(supplierInvoiceDate)
             if(supplierInvoiceDate.format('YYYY')!='Invalid date'){

              this.productForm.patchValue({
                    supplierInvoiceDate: {
                    year: parseInt(supplierInvoiceDate.format('YYYY')),
                    month: parseInt(supplierInvoiceDate.format('M')),
                    day: parseInt(supplierInvoiceDate.format('D'))
                    }
                  })
             }
             else{
             this.productForm.patchValue({
               supplierInvoiceDate: null
               })
            }
        }
       else{
        this.productForm.patchValue({
          supplierInvoiceDate: null
          })
       }
       productName = productName ? productName : ""
        this.productForm.patchValue({
          productName:productName,
          projectDescription: description,
          productCategory: productCategory.id,
          designNumber:designNumber,
          itemNumber:itemNumber,
          cost:cost,
          costUSD:costUsd,
          costMAD:costMad,
          costAED:costAed,
          margin:margin,
          displayedSellingPriceInMAD:displayedSellingPrice,
          discount:discount,
          sellingPrice:sellingPrice,
          impliedMinimumSellingPriceMargin:Math.round(sellingPriceMargin),
          description:description,
          company:company,
          otherFieldX:otherFieldX,
          otherFieldY:otherFieldY,
          other:other,
          status:status,
          stockQuantity:stockQuantity,
          users:memberIds,
          supplierName:supplierName,
          supplierInvoiceNumber:supplierInvoiceNumber,
          tajNumber:tajNumber,
          maximumDiscountAllowed:maxAllowed,
          minimumSellingPriceInMAD:minSellingPrice,
          goldColor: goldColor,
          goldWeight: goldWeight,
          diamondWeight:diamondWeight,
          numberOfDiamondStones:noDiamondStones,
          coloredStonesType1:coloredStonesTypeOne,
      coloredStonesWeight1:coloredStonesWeightOne,
      coloredStonesType2:coloredStonesTypeTwo,
      coloredStonesWeight2:coloredStonesWeightTwo,
      size:size,
      comment:comment,
      productLocation: productLocation ? productLocation.trim() : '',
     
      usdAedFx:usdAedFx,
      aedMadFx:aedMadFx,
      coloredStonesType3:coloredStonesType3,
      coloredStonesWeight3:coloredStonesWeight3,
      goldInvoiceNumber:goldInvoiceNumber,
      diamondPriceUsd:diamondPriceUsd,
      coloredStonesPriceUsd:coloredStonesPriceUsd,
      makingChargesUsd:makingChargesUsd,
      goldPriceUsd:goldPriceUsd
        })
        this.variantImages = [];
        photos.forEach(element => {
          

          this.variantImages.push({
              id:element.id,
              url:element.signedGetURL,
              file:'',
              signedPutURL:element.signedPutURL
          })
        });
        this.loading = false
      
    },err=>{
      this.loading = false
    })
  }
  deleteImage(id){
    this.apollo
      .mutate({
        mutation: deleteAttachmentMutution,
        variables: {
          id:id
        },
        errorPolicy:"all"
      })
      .subscribe(
        ({ data, loading, errors }: any) => {
          
        },
        error => {
          console.log(error)

          this.loading= false
         
        }
      )
  }
  removeImg(index,id){
    if(id){
      this.variantImages.splice(index,1)
      this.deleteImage(id)
    }
    else{
      this.variantImages.splice(index,1)
      this.setTempFiles()
    }
  }
  setMad(){
    if(this.f.aedMadFx.value){
      this.productForm.patchValue({
        costMAD:  Math.round(this.f.aedMadFx.value * this.f.costAED.value),
      })
    }
  }
  setAed(){
    if(this.f.usdAedFx.value){
      this.productForm.patchValue({
        costAED:  Math.round(this.f.usdAedFx.value * this.f.costUSD.value),
      })
    }

  }
  getFxValue(type){
    this.httpClient.get(`https://api.exchangerate-api.com/v4/latest/${type}`, {
    }).subscribe((response:any) => {
      if(type=='usd'){
        let aed = response.rates.AED
        this.productForm.patchValue({
          usdAedFx:  aed
        })
      }
      if(type=='aed'){
        let mad = response.rates.MAD
        this.productForm.patchValue({
          aedMadFx:  mad
        })
      }
    },err=> {
      this.productForm.patchValue({
        aedMadFx:  2.7,
        usdAedFx:  3.67
      })
    })
  }
  goToBack(){
    this.router.navigate(['/products',this.page], {
      state: {
        columns: this.column,
        searchText:this.searchText,
        filterByColumn:this.filterByColumn,
        filterColumn:this.filterColumn,
        filterColumnValue : this.filterColumnValue,
        minValue : this.minValue,
        maxValue : this.maxValue,
        itemsPerPage:this.itemsPerPage
      }
    })    
  }
   clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }
  checkUserIds(){
    return [
		"160721",
       "160875",
       "160873",
       "160720",
       "160731","158530"].includes(this.accountId)
   }
}
