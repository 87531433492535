

<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3 pb-5 my-3 ">
        <app-account></app-account>
       
        <div class="row p-3  justify-content-center">
          <h5 class="col-md-9 m-auto text-center innerTitle d-block pb-3"> You can access StayTouch app using corporate login. You can also merge and import your private StayTouch account.
            <small placement="bottom" ngbTooltip="If you have private account on StayTouch app, you can chose to merge it to your StayTouch Corporate account. Once merged into corporate account, you will not have access to private account. Your contacts and meetings will be imported to your corporate account"> <i class="fa fa-info-circle" aria-hidden="true"  ></i></small>
          </h5> 
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
             

                <form class="form-signin py-3"  (ngSubmit)="verifyCode()" *ngIf="showAccountBlk" >
                    <div class="text-center" >
                      <h4>Please enter the verification code </h4>
                    </div>
                  <div class="form-label-group1 pb-3" >
                    <input type="text" maxlength="6"  name="code" id="code" [(ngModel)]="code"  placeholder="Merge Code" class="form-control custominp" >
                    
                  </div>
                  <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" >Verify</button>
        
                </form>
            <form class="form-signin" [formGroup]="mergeForm" *ngIf="!showAccountBlk" (ngSubmit)="mergeAccount()">
                <div class="pb-2">
                    <label for="inputEmail">Enter email address</label>
                    <input type="email" id="inputEmail" formControlName="email" class="form-control " >
                    <!-- <div *ngIf="submitted && f.email.errors" class="text-danger">
                      <div *ngIf="f.email.errors.required">
                       Email is required
                      </div>
                    </div> -->
                  </div>
                 
                  <div class="pb-4" >
                     <label class="pt-2">Enter phone number</label>
                      <ngx-intl-tel-input 
                      [cssClass]="'form-control '" 
                      [preferredCountries]="['us', 'gb']" 
                      [enablePlaceholder]="false"
                      [enableAutoCountrySelect]="false"
                      name="phone"
                      id="phone" 
                      [searchCountryFlag]="true"
                      [separateDialCode]="'false'"
                      [maxLength]="15"
                      formControlName="phone">
                    </ngx-intl-tel-input>
                    <!-- <div *ngIf="submitted && f.phone.errors" class="text-danger">
                      <div *ngIf="f.phone.errors.required">
                       phone number is required
                      </div>
                    </div> -->
                </div>
                
                <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" >Merge Account</button>

            </form>
            </div>

        </div>
          
      </div>
    </div>
  </div>
</div>


