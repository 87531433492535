<nav
  class=" navbar navbar-expand-lg position-fixed   w-100  blueBg "
  id="top-nav"
>
  <div class="col-sm-11 justify-content-around d-lg-flex m-auto p-3Percent">
    <!-- LOGO DESKTOP -->
    <div class="d-flex justify-content-between">
      <a class="navbar-brand" href="https://www.staytouch.com/" target="_blank">
        <img src="assets/logo.svg?x=1" alt="Logo" width="175px" height="auto"  />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="fa fa-bars"></span>
      </button>
    </div>
    <div
      class="collapse navbar-collapse d-lg-flex justify-content-between"
      id="collapsibleNavbar"
    >
      <ul class=" navbar-nav ">
        <li class="nav-item ">
          <a class="nav-link " href="https://www.staytouch.com/" target="_blank">{{ 'HEADER.HOME' | translate }}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link " href="https://www.staytouch.com/how-to-staytouch.php" target="_blank">{{ 'HEADER.HOW_IT_WORKS' | translate }} </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link " href="https://www.staytouch.com/about.php" target="_blank">{{ 'HEADER.ABOUT_US' | translate }} </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link " href="https://www.staytouch.com/contact.php" target="_blank">{{ 'COMMON.CONTACT' | translate }}</a>
        </li>
      </ul>
      <ul class="nav px-0 pb-3 pb-sm-0 d-flex align-items-center ">
        <li class="language-blk mr-2 headBlk">
          <!-- <img src="assets/i18n/us.svg" height="15px" width="auto" *ngIf="selectedLang=='en'" />
          <img src="assets/i18n/fr.svg" height="15px" width="auto" *ngIf="selectedLang=='fr'" />
         <select class="dropdown-menu1 border-0 pr-0 " [(ngModel)]="selectedLang" (change)="changeLanguage($event)">
           <option *ngFor="let lang of translate.getLangs(); let i = index" [value]="lang">
              {{ lang }}
           </option>
       </select> -->
       <ngx-select
       name="participants"
       [multiple]="false"
       [items]="languagesList"
       optionValueField="id"
       optionTextField="title"
       [(ngModel)]="selectedLang"
       placeholder="{{'TEAMSPAGE.TEAMS' | translate}}"
       class="theme-select languageDp  "
       (select)="changeLanguage($event)"
       [noAutoComplete]= "true"
       >
       <ng-template
         ngx-select-option
         ngx-select-option-selected
         let-option
         let-text="text">
           <div class="d-flex align-items-center"> 
             <img src="{{option.data.image}}" width="20px" /> 
             <p [innerHtml]="text"  class="mb-0 pl-2"  ></p>
           </div>
        
       </ng-template>
      
     </ngx-select>
     </li>
        <li
          class="nav-item dropdown"
          
        >
          <div *ngIf="isAuthenticated" class="dropdown">
            <a class=" nav-link p-0 dropdown-toggle m-0" data-toggle="dropdown">
              <img
                src="{{imageUrl}}?x={{random}}"
                onerror="this.src='assets/default.jpg'"
                class="profilePic"
                width="50px"
                height="50px"
              />{{ user.email ? user.email: user.phone  }}
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" [routerLink]="['/member-details']">{{ 'HEADER.DASHBOARD' | translate }}</a>
              <!-- <a class="dropdown-item" [routerLink]="['/settings']">{{ 'HEADER.SETTINGS' | translate }}</a> -->
              <!-- <a class="dropdown-item" href="#">Notifications</a> -->
              <a class="dropdown-item" [routerLink]="['/reset-password']">{{ 'HEADER.RESET_PASSWORD' | translate }}</a>
              <a class="dropdown-item" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</a>
              <form
                id="logout-form"
                action=""
                method="POST"
                style="display: none;"
              ></form>
            </div>
            <!-- Select option for language switching -->
            <div>
            <!-- <select class="dropdown-menu1" [(ngModel)]="selectedLang" (change)="changeLanguage($event)">
                <option *ngFor="let lang of translate.getLangs(); let i = index" [value]="lang">
                    {{ lang }}
                </option>
            </select> -->
            <!-- <a class=" nav-link p-0 dropdown-toggle" data-toggle="dropdown">Choose Language</a>
            <div class="dropdown-menu">
              <a *ngFor="let lang of translate.getLangs()" class="dropdown-item" (click)="changeLanguage(lang)">{{ lang }}</a>
            </div> -->
          </div>
          </div>
          <div *ngIf="!isAuthenticated" class="dropdown d-none">
            <a class=" nav-link p-0 dropdown-toggle m-0" data-toggle="dropdown">
              {{ 'HEADER.LOGIN' | translate }}
            </a>
            <div class="dropdown-menu d-none">
              <form class="form dropdown-item" [formGroup]="loginForm" (ngSubmit)="getLoginDetails()">
                <h3 class="my-3 loginCls">Login</h3>
                <h6 *ngIf="!isAuthenticated" style="color:red;">{{ errorMessage }}</h6>
                <div class="loginBlock">
                  <div class="col-md-12 px-0">
                    <input
                      class="theme-input"
                      placeholder="Enter Email "
                      autofocus=""
                      autocomplete="off"
                      name="email"
                      type="text"
                      formControlName="loginEmail"
                    />
                    <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)">
                      <p style="color:red;" *ngIf="loginEmail.errors.required">
                        Email is required.
                      </p>
                      <p style="color:red;" *ngIf="loginEmail.errors.email">
                        Email is not valid.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12 pt-3 px-0">
                    <input
                      placeholder="Password"
                      class="theme-input"
                      name="password"
                      type="password"
                      formControlName="loginPassword"
                    />
                  <div *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)">
                    <p style="color:red;" *ngIf="loginPassword.errors.required">
                      Password is required.
                    </p>
                    <p style="color:red;" *ngIf="loginPassword.errors.minlength">
                      minimum be 5 characters.
                    </p>
                  </div>
                  </div>
                  <div class="text-center col-md-12 pt-3">
                    <input
                      class="theme-btn"
                      name="submit"
                      type="submit"
                      value="Login"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="dropdown">
              <a class=" nav-link p-0 dropdown-toggle" data-toggle="dropdown">
                <img
                  src="assets/profile.png"
                  onerror="this.src='images/profile.png'"
                  class="profilePic"
                  width="50px"
                  height="50px"
                />
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="members.php">Dashboard</a>
                <a class="dropdown-item" href="settings.php">Settings</a>
                <a class="dropdown-item" href="notifications.php"
                  >Notifications</a
                >
                <a class="dropdown-item" href="#">Logout</a>
                <form
                  id="logout-form"
                  action=""
                  method="POST"
                  style="display: none;"
                ></form>
              </div>
            </div> -->
        </li>
      </ul>
    </div>
  </div>
</nav>
