import { Component, OnInit,HostListener,Input,ViewChild } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular'

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router'
import {environment} from "../../../../../environments/environment"
import { accountInfo,updateZoomMutution,updateSyncSettingsMutation,syncSettings,syncAllFieldsMutation } from '../../../services/user.gql'
import { meetingsQuerySync } from '../../../services/meeting.gql'
import { ToastrService } from 'ngx-toastr'
// import { UserAgentApplication } from 'msal'
import { SortablejsOptions } from 'ngx-sortablejs';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import {
  contactsLessInfoQuery
} from '../../../services/contacts.gql'

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store'
import { MemberLogIn,MemberLogOut,MemberStatus } from '../../../../user/store/actions/member.auth.actions';
import { AppState, selectMemberAuth,profileState } from './../../../store/app.states'

import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap'
import { HelperService } from '../../../services/helper.service'

declare var gapi: any;

@Component({
  selector: 'app-syncronizations',
  templateUrl: './syncronizations.component.html',
  styleUrls: ['./syncronizations.component.scss']
})
export class SyncronizationsComponent implements OnInit {
  @Input() permissions: any;
  @ViewChild('microsoftModal', { static: false }) private microsoftModal;

  specificUser:boolean = false
  public zoomConnectStatus:any = (localStorage.getItem("oauth_access_token") && localStorage.getItem("oauth_access_token") != "undefined")  ? true : false
  
  outlookStatus:any = false
  salesforceObj:any
  outlookObj:any ={}
  googleObj:any ={}
  hubspotObj:any = {}
  redirectionURL:any
  newwindow:any
  private query: QueryRef<any>
  private querySync:QueryRef<any>
  private queryContacts: QueryRef<any>
  private queryMeetings:QueryRef<any>
  private queryAllMeetings:QueryRef<any> 
  private queryAllContacts:QueryRef<any>
  
  private query$ : any
  loading:any= false
  showList:any = false
  isContact:boolean = false
  isMeeting:boolean = false
  salesforceAceesToken:any 
  meetingObj:any
  teamObj:any
  conactObj:any
  salesforceObjects:any = []
  sfobjFields:any =[]
  stobjFields:any =[]
  sfObjId:any
  stObjId:any
  showTable:boolean = false
  syncForm: FormGroup;
  accountInformation:any ={}
  googleMultiSync:boolean = false
  outlookMultiSync:boolean = false
  isOutlookEnabled:boolean = false
  isOffice365Enabled:boolean = false
  userId:any 
  staytouchObjects = [
	{
		id:1,
		name:"Contacts"
	},
	{
		id:2,
		name:"Meetings"
	},
  ]
  contactFields = [
    {
      id:1,
      name:"firstName",
      displayName: "First Name"
    },
    {
      id:1,
      name:"lastName",
      displayName: "Last Name"
    },
    {
      id:1,
      name:"email",
      displayName: "Email"
    },
    {
      id:1,
      name:"phone",
      displayName: "Phone"
    }

  ];
  meetingFields = [
    {
      id:1,
      name:"title",
      displayName: "Title"
    },
    {
      id:1,
      name:"startTime",
      displayName: "Start Time"
    },
    {
      id:1,
      name:"endTime",
      displayName: "End Time"
    },
    {
      id:1,
      name:"location",
      displayName: "Location"
    },
    {
      id:1,
      name:"participants",
      displayName: "Participants"
    }

  ];

  options: SortablejsOptions = {
    group: 'test'
  };
  constructor(private activatedRoute: ActivatedRoute,private http: HttpClient,public router: Router, private apollo: Apollo,private toastr: ToastrService,private fb:FormBuilder,   private store: Store<AppState>,private modalService: NgbModal,    private helperService:HelperService,
    ) {
    this.redirectionURL = environment.OUTLOOK_REDIRECT_URL
    this.syncForm = this.fb.group({
      quantities: this.fb.array([]) ,
    });
    
  }
  quantities() : FormArray {
    return this.syncForm.get("quantities") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      stField: '',
      sfField: '',
    })
  }
   
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
   
  removeQuantity(i:number) {
    this.quantities().removeAt(i);
  }
   
  ngOnInit() {
    let loginUser = localStorage.getItem("email")
    if(loginUser == 'emilot@staytouch.com')
    {
      this.specificUser = true
    }
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      variables: {},
      fetchPolicy:"network-only"
    })
    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.loading=false
      this.accountInformation = data.accountInformation
      this.userId = this.accountInformation.id
      if(data.accountInformation)
      {
        this.outlookMultiSync = this.accountInformation.outlookMultiSync
        this.googleMultiSync = this.accountInformation.googleMultiSync
        let {zoomAccessToken,zoomAuthorizationToken,zoomRefreshToken} = data.accountInformation
        if(zoomAccessToken)
        {
          localStorage.setItem("oauth_access_token",zoomAccessToken)
          localStorage.setItem("oauth_refresh_token",zoomRefreshToken)
        }
      }
    })
    this.getSyncSettings()
    this.activatedRoute.queryParams.subscribe(params => {
      let parm = params.code
     
      if (params.code) {

        if(params.state=="12345")
        {
          this.getOutlookAccessToken(params.code);
        }
       else if(params.state=="2")
        {
          this.getSalesforceToken(params.code)
          // this.getOutlookAccessToken(params.code);
        }
        else if(params.state=="4")
        {
          this.getGoogleAccessToken(params.code)
        }
        else if(params.state=="5")  // teams
        {
          this.getTeamsAccessToken(params.code)
        }
        else if(params.state=="6")  // hubspot
        {
          // alert('hubspot')
          this.getHubspotAccessToken(params.code)
        }
        else if(params.state=="7")  // office
        {
          this.getOffice365Code(params.code)
        }
        else if(!localStorage.getItem("oauth_access_token") || localStorage.getItem("oauth_access_token") == "undefined" )
        {
          this.getAccessToken(params.code);
        }
        
        // else{
        //     this.getOutlookAccessToken(params.code);
        // }
          
      }
    });
  }

  getTeamsAccessToken(code){
    const scope = "https://graph.microsoft.com/openid profile offline_access User.Read Calendars.ReadWrite Contacts.ReadWrite"
    const payload = new HttpParams()
        .append('grant_type', 'authorization_code')
        .append('code', code)
        .append('redirectUrl', environment.TEAMS_SCOPE)
        .append('scope', scope)
        this.http.post(`${environment.TEAMS_CLIENTID}/getMicroSoftOauthToken`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
      if(response.access_token)
      { 
        let {expires_in, access_token,refresh_token,token_type}:any = response
        // this.getMicrosoftUserinfo(access_token,refresh_token,expires_in,scope)
      }
      else{
        alert("Unable to connect. Please try again later.")
      }
      
    });
  }
  
  getmicrosofAccessToken(type){
    
    let oauth2Endpoint = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';
    let client_id = environment.OUTLOOK_CLIENTID;
    let scope = environment.OUTLOOK_SCOPE

    if(type==7)
    {

     oauth2Endpoint = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';
     client_id = environment.OFFICE365_CLIENTID;
     scope = environment.OFFICE365_SCOPE
    }
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    let requestUrl = `${oauth2Endpoint}scope=${scope}&response_type=code&access_type=offline&state=${type}&redirect_uri=${environment.OUTLOOK_REDIRECT_URL}&client_id=${client_id}`
      window.location.href = requestUrl;
  }
  
  getSyncSettings()
  {
    
    this.loading = true
    this.querySync = this.apollo.watchQuery({
      query: syncSettings,
      variables: {},
      fetchPolicy:"network-only"
    })
    this.querySync.valueChanges.subscribe(({ data }: any) => {
      this.loading = false
      data.syncSettings.forEach((item,i)=>{
          if(item.serviceProvider == 'OUTLOOK')
          {
            this.outlookObj =item
            if(this.outlookObj.syncStatus == 'ENABLED')
            {
              if(this.outlookObj.channelId)
              {
                // this.renewSubscriptions(this.outlookObj.channelId,2)
              }
              if(this.outlookObj.resourceId)
              {
                // this.renewSubscriptions(this.outlookObj.resourceId,1)
              }              
            }
            
          }
          if(item.serviceProvider == 'GOOGLE')
          {
            this.googleObj =item
            // this.getAllMeetings(item.accessToken,item.refreshToken)

          }
          if(item.serviceProvider == 'TEAMS')
          {
            this.teamObj =item
          }
          if(item.serviceProvider == 'HUBSPOT')
          {
            this.hubspotObj =item
          }
          if(item.serviceProvider == 'SALESFORCE')
          {
            this.salesforceObj =item
            if(item.accessToken && item.refreshToken)
            {
              this.getSalesforceRefreshToken(item)
            }
          }

      })
    },err=>{
      this.loading =false
    })
  }
  getAccessToken(code: string) {
      const params = [
        `code=${code}`
    ];

    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('redirectUrl', environment.ZOOM_REDIRECT_URL)
      //getOauthToken
      this.http.post(`${environment.ZOOM_URL}/C46C54AD376BCE9786A2AF799841AD0B9DF982C4`, payload, {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
          "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
          
      }
    }).subscribe((response:any) => {

        if(response.access_token)
        { 
          let {expires_in, access_token,refresh_token}:any = response
          this.zoomConnectStatus = true
          this.saveToken(access_token,refresh_token)
          localStorage.setItem("oauth_access_token",access_token)
          localStorage.setItem("oauth__expires_in",expires_in)
          localStorage.setItem("oauth_refresh_token",refresh_token)
          if(localStorage.getItem("zoomMeeting")=='yes')
          {
            this.router.navigate(['/create-meeting'])
            localStorage.removeItem("zoomMeeting")
          }
        }
        else{
          alert("Unable to connect. Please try again later.")
        }
        
      });
  }
  getOutlookAccessToken(code: string) {
  const scope = "https://graph.microsoft.com/openid profile offline_access User.Read Calendars.ReadWrite Contacts.ReadWrite"
    const payload = new HttpParams()
        .append('grant_type', 'authorization_code')
        .append('code', code)
        .append('redirectUrl', environment.OUTLOOK_REDIRECT_URL)
        .append('scope', scope)
        //getMicroSoftOauthToken
        this.http.post(`${environment.OUTLOOK_URL}/988AFFDC5135596C9B865733270A2087004AB185`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
      if(response.access_token)
      { 
        let {expires_in, access_token,refresh_token,token_type}:any = response

        this.getMicrosoftUserinfo(access_token,refresh_token,expires_in,scope)
        
      }
      else{
        alert("Unable to connect. Please try again later.")
      }
      
    });
  }
  getOffice365Code(code: string) {
      const payload = new HttpParams()
          .append('grant_type', 'authorization_code')
          .append('code', code)
          .append('redirectUrl', environment.OUTLOOK_REDIRECT_URL)
          .append('scope', environment.OUTLOOK_SCOPE)
          //office365/oauthToken
          this.http.post(`${environment.OFFICE365_URL}/17CF8548A221A87D3D2A67888B8DB801F110BD9B`, payload, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
              "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
          }
      }).subscribe((response:any) => {
        if(response.access_token)
        { 
          let {expires_in, access_token,refresh_token,token_type}:any = response
  
          this.getOffice365Userinfo(access_token,refresh_token,expires_in,environment.OFFICE365_SCOPE)
          
        }
        else{
          alert("Unable to connect. Please try again later.")
        }
        
      });
    }
  
  getSalesforceToken(code: string) {
    const payload = new HttpParams()
    .append('code', code)
    //salesforceCallback
    this.http.post(`${environment.OUTLOOK_URL}/2D43BC880B5AB45D941B963DDCA90896B5BD3921`, payload, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
        "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
    }
  }).subscribe((response:any) => {
    this.loading = false
      if(response.access_token)
      { 
        let {access_token,refresh_token}:any = response
        this.salesforceAceesToken =access_token
          this.getSalesforceUser(access_token,refresh_token)
        localStorage.setItem("saccessToken",access_token)
      }
      else{
        alert("Unable to connect. Please try again later.")
      }
      
    });
  }
  getMicrosoftUserinfo(access_token,refresh_token,expires_in,scope){
    const payload = new HttpParams()
    .append('authToken', access_token)
    //getMicrosoftUser
    this.http.post(`${environment.OUTLOOK_URL}/AB33B9534D3010ACE9CFF42917E1186E8DE0068E`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response.userPrincipalName)
        {
          this.openModal()
          this.outlookObj.username = response.userPrincipalName
          this.outlookObj.accessToken = access_token
          this.outlookObj.refreshToken = refresh_token
          this.updateSyncSetting(access_token,refresh_token,expires_in,response.userPrincipalName,'ENABLED',scope,'OUTLOOK',"","",false)

        }
    })
  }
  getOffice365Userinfo(access_token,refresh_token,expires_in,scope){
    const payload = new HttpParams()
    .append('authToken', access_token)
    this.http.post(`${environment.OFFICE365_URL}/43B58142E80DB665FF783ABCCC07E8EFA7A05D7B`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response.userPrincipalName)
        {
          this.outlookObj.username = response.userPrincipalName
          this.outlookObj.accessToken = access_token
          this.outlookObj.refreshToken = refresh_token
          this.updateSyncSetting(access_token,refresh_token,expires_in,response.userPrincipalName,'ENABLED',scope,'OUTLOOK',"","",true)
        }
    })
  }
  
  getSalesforceUser(access_token,refresh_token){
    const payload = new HttpParams()
    .append('authToken', access_token)
    //getSalesforceUser
    this.http.post(`${environment.OUTLOOK_URL}/1642D0FB993DF484825851D6E32B4B37E26F9B11`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response.email)
        {
          this.salesforceObj.username = response.email
          this.updateSyncSetting(access_token,refresh_token,'',response.email,'ENABLED','','SALESFORCE',"","",false)
        }
    })
  }
  getCustomObjInfo(objName=""){
    if(!localStorage.getItem('saccessToken'))
    {
      this.disconnectSync('SALESFORCE')
    }
    const payload = new HttpParams()
    .append('authToken',localStorage.getItem('saccessToken'))
    .append('objName', objName)
    //getObjectInfo
    this.http.post(`${environment.OUTLOOK_URL}/139F29F25BD96B1E4312E0E9C5E1689B6F7E1BA4`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        this.sfobjFields = response
    })
  }
  getSalesforceObjects(){
    if(!localStorage.getItem('saccessToken'))
    {
      this.disconnectSync('SALESFORCE')
    }
    this.loading = true
    const payload = new HttpParams()
    .append('authToken',localStorage.getItem('saccessToken'))
    //getsObjects
    this.http.post(`${environment.OUTLOOK_URL}/DA89365058ACA35FEC508865E6505542DF5F228E`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
      this.loading = false
      this.salesforceObjects = response
    })
  }
  getSalesforceRefreshToken(userObj){
    this.loading = true
    let {accessToken,refreshToken,username} = userObj

    const payload = new HttpParams()
    .append('accessToken', accessToken)
    .append('refreshToken',refreshToken)
    //getSalesforceRefreshToken
    this.http.post(`${environment.OUTLOOK_URL}/CF1C69FADCEA1A975206EE0CCD09EB6B8778BEC7`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
      this.loading = false
      if(response.access_token)
      {
        
        let {access_token} = response
        localStorage.setItem("saccessToken",access_token)
        this.updateSyncSetting(access_token,refreshToken,'',username,'ENABLED','','SALESFORCE',"","",false)
      }
    },err=>{
      this.loading = false
    })
  }
  saveToken(access_token,refresh_token){

    this.apollo
        .mutate({
          mutation: updateZoomMutution,
          variables: {
            refreshToken: refresh_token,
            accessToken:access_token
          }
        })
        .subscribe(({ data }: any) => {
      })
  }

  updateSyncSetting(access_token,refresh_token,expires_in,username,status,scope,serviceProvider,channelId,resourceId,multiSync){
    this.loading = true
    let objValues:any ={
      serviceProvider: serviceProvider,
      syncStatus:status,
    
      credentials: {
        username:username,
        accessToken: access_token,
        refreshToken: refresh_token,
        scope:scope,
        idToken:"",
        multiSync:multiSync,
        channelId:channelId,
        resourceId:resourceId
        
      }
    }
    if(serviceProvider == 'OUTLOOK' )
    {
      objValues ={
        serviceProvider: serviceProvider,
        syncStatus:status,
        credentials: {
          username:username,
          accessToken: access_token,
          refreshToken: refresh_token,
          scope:scope,
          idToken:"",
		      multiSync: multiSync,
          calendar:this.outlookObj.calendar,
          contact:this.outlookObj.contact,
          resourceId:resourceId, //contacts subscritption
          channelId:channelId, // calendar subscritpion

        },
        attachment: true,
     }
    }
    
    if(serviceProvider == 'GOOGLE')
    {
      objValues ={
        serviceProvider: serviceProvider,
        syncStatus:status,
        
        credentials: {
          username:username,
          accessToken: access_token,
          refreshToken: refresh_token,
          scope:scope,
          idToken:"",
          multiSync: this.googleObj.multiSync,
          syncToken:this.googleObj.syncToken,
          calendar:true,
          contact:true
        },
       
        attachment: true,
      }
    }
    this.apollo
        .mutate({
          mutation: updateSyncSettingsMutation,
          variables: objValues
        })
    .subscribe(({ data }: any) => {
      this.loading = false
      if(serviceProvider == 'OUTLOOK')
      {
        this.outlookObj.syncStatus = status
        this.outlookObj.username = username
        this.outlookObj.channelId = channelId
        this.outlookObj.resourceId = resourceId
        if(localStorage.getItem("from") == "importOutlook")
        {
          localStorage.removeItem("from")
          this.router.navigate(['/import-contacts'])
        }
      }
      else if(serviceProvider == 'GOOGLE')
      {
        this.googleObj = data.NCIYUUFGJF;
        this.googleObj.syncStatus = status
        this.googleObj.username = username;
        this.googleObj.accessToken = access_token; 
        this.googleObj.refreshToken = refresh_token;
      }
      else if(serviceProvider == 'TEAMS')
      {
        this.teamObj.syncStatus = status
        this.teamObj.username = username
      }
      else if(serviceProvider == 'HUBSPOT')
      {
        this.hubspotObj.syncStatus = status
        this.hubspotObj.username = username
      }
      else {
        this.salesforceObj.syncStatus = status
      // this.outlookObj.username = username
      }

    },err=>{
      console.log(err)
     this.loading = false
    })
  }


  disconnect(){
    localStorage.removeItem("oauth_access_token")
    localStorage.removeItem("oauth__expires_in")
    localStorage.removeItem("oauth_refresh_token")
    this.saveToken('','')
    this.zoomConnectStatus = false
    this.toastr.info("Disconnected to zoom account.")
  }

  setLoginType(type) {
    localStorage.setItem("oauthType",type)
  }
  disconnectSync(type){
    if(type == 'GOOGLE')
    {
      this.disconnectMultiSync('DISABLED')
    }
    else if(type == 'OUTLOOK')
    {
      this.deleteAllSubscriptions(this.outlookObj.accessToken)

      this.updateSyncSetting("null","null","null","null",'DISABLED',"null",type,"null","null",false);
    }
    else{
      this.updateSyncSetting('','','','','DISABLED','',type,'','',false);
    }
  }
  getFiledInfo(){
      if(!this.stObjId && !this.sfObjId)
      {
        return false
      }
      this.showTable = true
      this.stobjFields = this.meetingFields
      if(this.stObjId ==1)
      {
        this.stobjFields = this.contactFields
      }
      if(this.sfObjId)
      {
        this.getCustomObjInfo(this.sfObjId)
      }
  }
  syncAllFields(){
        let oneSalesforceObject:any = this.f.quantities.value
        let objectName = "event"
        if(this.stObjId ==1)
        {
            objectName = "contact"
        }
       let newarray = oneSalesforceObject.reduce((acc, item,i) => {
        let staytocuhKey = objectName+":"+item.stField              
        let salesforceKey = this.sfObjId+":"+item.sfField 
          if (acc[staytocuhKey]) {
          } else {
            acc[staytocuhKey] = {}
          }
          acc[staytocuhKey] = salesforceKey  
          return acc
        }, [])
        newarray = {...newarray}
    this.apollo
        .mutate({
          mutation: syncAllFieldsMutation,
          variables: {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            pedData:JSON.stringify(newarray)
          }
        })
        .subscribe(({ data }: any) => {
          
        alert("Data updated successfully.")
        this.loading = false
      })
  }
 
  get f () {
    return this.syncForm.controls
  }

    /*
  * Create form to request access token from Google's OAuth 2.0 server.
  */
  oauthGoogleSignIn(type) {
    // Google's OAuth 2.0 endpoint for requesting an access token
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/auth?';
    const client_id =environment.GOOGLE_CLIENT_ID
    const scope =environment.GOOGLE_SCOPE

    
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    let requestUrl = `${oauth2Endpoint}scope=${scope}&response_type=code&access_type=offline&state=4&redirect_uri=${environment.OUTLOOK_REDIRECT_URL}&client_id=${client_id}`
      window.location.href = requestUrl;
    // Add form to page and submit it to open the OAuth 2.0 endpoint.
  }


   /*
  * Create form to request access token from Google's OAuth 2.0 server.
  */
 oauthTeamsSignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';
  const client_id = environment.TEAMS_CLIENTID;
  const scope = "openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite"
  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  let requestUrl = `${oauth2Endpoint}scope=${scope}&response_type=code&access_type=offline&state=4&redirect_uri=${environment.TEAMS_REDIRECT_URL}&client_id=${client_id}`
    window.location.href = requestUrl;
  // Add form to page and submit it to open the OAuth 2.0 endpoint.
}

  getGoogleUsrInfo(access_token,refresh_token){
    const payload = new HttpParams()
    .append('authToken', access_token)
    //getGoogleUser
    this.http.post(`${environment.ZOOM_URL}/96AEA65D36301A0DE2CB0471B8EBC6A4302DC608`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
      this.loading = false
        if(response.email)
        {
          this.updateSyncSetting(access_token,refresh_token,'',response.email,'ENABLED','','GOOGLE',this.googleObj.channelId,this.googleObj.resourceId,this.googleObj.multiSync)
          this.getAllMeetingsContacts(access_token,refresh_token,'GOOGLE')

         
         // this.getAllMeetings(access_token,refresh_token,'GOOGLE')
          //this.getAllContacts(access_token,refresh_token,'GOOGLE')
         
          // if(this.a){

          // }
        }
    })
  }
  getGoogleAccessToken(code: string) {
    this.loading= true
    const payload = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('code', code)
      .append('redirectUrl', environment.OUTLOOK_REDIRECT_URL)
      //getGoogleAuthToken
      this.http.post(`${environment.ZOOM_URL}/5EF60FC497D6F853EE3DF607226F4581AB1850C4`, payload, {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
          "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
      }
    }).subscribe((response:any) => {
      this.loading = false
        if(response.access_token)
        { 
          const {access_token, refresh_token, expires_in} = response
          this.getGoogleUsrInfo(access_token, refresh_token)
          // this.updateSyncSetting(access_token,refresh_token,expires_in,response.userPrincipalName,'ENABLED',scope,'G-SUIT')
        }
        else{
          alert("Unable to connect. Please try again later.")
        }
        
      });
  }
  oauthHubspotSignIn(){
    this.loading= true
    const payload = new HttpParams()
    //install
    this.http.get(`${environment.ZOOM_URL}/4A2B2C75B01F370C170FD941E7E96691590AB4BA`,  {
      headers: {
        "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
        "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
      
      }
  }).subscribe((response:any) => {
      window.location.href = response.url+"&state=6"
    });
  }

  getHubspotAccessToken(code){
    const payload = new HttpParams()
    .append('code', code)
    //oauth-callback
    this.http.post(`${environment.ZOOM_URL}/C850804E8A151D16E55CF04E966B0E37B05680B0`, payload, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
        "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
    }
  }).subscribe((response:any) => {
      if(response.accessToken){
        const {accessToken, refreshToken, expires_in,email} = response
        this.updateSyncSetting(accessToken,refreshToken,'',email,'ENABLED','','HUBSPOT',"","",false)
        this.getAllMeetings(accessToken,refreshToken,'HUBSPOT')
        this.getAllContacts(accessToken,refreshToken,'HUBSPOT')
      }
    });
  }
  getAllMeetingsContacts(accessToken,refreshToken,type){
    this.queryMeetings = this.apollo.watchQuery({
      query: meetingsQuerySync,
      variables: {},
      fetchPolicy:"network-only"
    })
    this.queryMeetings.valueChanges.subscribe(({ data }: any) => {
      this.sendEventData(accessToken,refreshToken,type,JSON.stringify(data.events))
    })

  }
  sendEventData(accessToken,refreshToken,type,events){
    this.queryContacts = this.apollo.watchQuery({
      query: contactsLessInfoQuery,
      variables: {},
      fetchPolicy:"no-cache"
    })
     this.queryContacts.valueChanges.subscribe(({ data }: any) => {
      if(type == 'GOOGLE' )
      {
        const payload1 = new HttpParams()
        .append('events', encodeURIComponent(events))
        .append('contacts', encodeURIComponent(JSON.stringify(data.contacts)))
        .append('accessToken',accessToken)
        .append('refreshToken',refreshToken)
        .append('authorizationToken', localStorage.getItem("token"))
        .append('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
        .append('accountEmail',this.accountInformation.email)
        .append('googleMultiSync',this.googleObj.multiSync)
        .append('id',this.accountInformation.id) 
        //gsuit/syncall
        this.http.post(`${environment.ZOOM_URL}/9EF5AD1D4C09B63520E22D77543D4B70D9222056`, payload1, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
              "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
          }
         }).subscribe((response:any) => {

          if(response.accessToken && response.refreshToken){
           // this.getSyncSettings();
            //this.updateSyncSetting(response.accessToken,response.refreshToken,'',this.googleObj.username,'ENABLED','','GOOGLE')
          
          }
          else{
            if(response.error){
              Swal.fire({
                title: 'Google sync error',
                text: 'Your Token has been expired, Please login to your google account again to sync your contacts and meetings.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'OK',
              }).then((result) => {
                if (result.value) {
                  this.updateSyncSetting('','','',this.googleObj.username,'DISABLED','','GOOGLE',"","",false);
                  // this.toastr.info("Logged out from account");
                  // window.localStorage.clear()
                  // window.location.href = '/login'
                  // this.store.dispatch(new MemberLogOut)
                }
              })
            }
          }


        });
      }
     
    })

  }

  getAllMeetings(accessToken,refreshToken,type){
    this.queryAllMeetings = this.apollo.watchQuery({
      query: meetingsQuerySync,
      variables: {},
      fetchPolicy:"network-only"
    })
  
       this.queryAllMeetings.valueChanges.subscribe(({ data }: any) => {
        if(type=='GOOGLE'){
          const payload1 = new HttpParams()
          .append('events', encodeURIComponent(JSON.stringify((data.events))))
          .append('accessToken', JSON.stringify(accessToken))
          .append('refreshToken', JSON.stringify(refreshToken))
          .append('authorizationToken', localStorage.getItem("token"))
          .append('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
          .append('accountEmail',this.accountInformation.email)
          .append('googleMultiSync',this.accountInformation.multiSync)
          .append('id',this.accountInformation.id)
          //gsuit/syncall
          this.http.post(`${environment.ZOOM_URL}/9EF5AD1D4C09B63520E22D77543D4B70D9222056`, payload1, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
                "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
            }
           }).subscribe((response:any) => {            
          });
        }
        if(type=='HUBSPOT'){   
          const payload = new HttpParams()
            .append('events', encodeURIComponent(JSON.stringify((data.events))))
            .append('accessToken', accessToken)
            .append('refreshToken', refreshToken)
            .append('authorizationToken', localStorage.getItem("token"))
            .append('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
            .append('accountEmail',this.accountInformation.email)
            //hubspot/events/syncall
            this.http.post(`${environment.ZOOM_URL}/F6406684E9F2D8F55D4BAC829610B0D6F8BD58CC`, payload, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
                "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
            }
          }).subscribe((response:any) => {
            //
          });
      }
    })
  }
  getAllContacts(accessToken,refreshToken,type){
    this.queryAllContacts = this.apollo.watchQuery({
      query: contactsLessInfoQuery,
      variables: {},
      fetchPolicy:"network-only"
    })
     this.queryAllContacts.valueChanges.subscribe(({ data }: any) => {
      if(type == 'GOOGLE' )
      {
        const payload2 = new HttpParams()
        .append('contacts', encodeURIComponent(JSON.stringify(data.contacts)))
        .append('accessToken', JSON.stringify(accessToken))
        .append('refreshToken', JSON.stringify(refreshToken))
        .append('authorizationToken', localStorage.getItem("token"))
        //gsuit/syncall
        this.http.post(`${environment.ZOOM_URL}/9EF5AD1D4C09B63520E22D77543D4B70D9222056`, payload2, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
              "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
          }
        }).subscribe((response:any) => {
        
        });
      }

      if(type == 'HUBSPOT' )
      {
        const payload = new HttpParams()
        .append('contacts', encodeURIComponent(JSON.stringify(data.contacts)))
        .append('accessToken', accessToken)
        .append('refreshToken', refreshToken)
        .append('authorizationToken', localStorage.getItem("token"))
        //hubspot/contacts/syncall
        this.http.post(`${environment.ZOOM_URL}/CA6A72F8AA95083A4DEF4D1DD3AC3003C162AB67`, payload, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
              "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
          }
        }).subscribe((response:any) => {
          
        });
      } 
    })
  }

  updateMultiSync(event,type){
      this.loading = true
      let objValues:any ={}
      if(type == 'GOOGLE') {
        const scope = "email profile openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/contacts.readonly";
        if(this.googleObj.syncStatus=="DISABLED") // disconnect
        {
          event.target.checked = false;
          this.googleMultiSync = false;
          alert("Please connect to the gsuit server first...")
          this.loading = false;
          this.googleObj.multiSync = false;
          this.disconnectMultiSync("DISABLED");
          return;
        }
        else
        {
          this.googleMultiSync = event.target.checked
          this.googleObj.multiSync = this.googleMultiSync
        }
        if(this.googleMultiSync == false){
          this.loading = false;
          this.disconnectMultiSync("");
        } 
        else{
          this.loading = false;
          this.getAllMeetingsContacts(this.googleObj.accessToken,this.googleObj.refreshToken,type)
         }

       
      }
      if(type == 'OUTLOOK') {
        const scope = "https://graph.microsoft.com/openid profile offline_access User.Read Calendars.ReadWrite Contacts.ReadWrite"

        this.outlookMultiSync = event.target.checked
        objValues ={
          serviceProvider: type,
          syncStatus:this.outlookObj.syncStatus,
          credentials: {
            username:this.outlookObj.username,
            accessToken: this.outlookObj.accessToken,
            refreshToken: this.outlookObj.refreshToken,
            scope:scope,
            idToken:"",
            multiSync: this.outlookMultiSync,
            calendar:this.outlookObj.calendar,
            contact:this.outlookObj.contact
          },
          attachment: true,
       }
       this.apollo
        .mutate({
          mutation: updateSyncSettingsMutation,
          variables: objValues
        })
        .subscribe(({ data }: any) => {
          this.loading = false

        },err=>{
        this.loading = false
        })
      }
  
    
  
  }

  disconnectMultiSync(status){
    const payload1 = new HttpParams()
    .append('accessToken', this.googleObj.accessToken)
    .append('refreshToken', this.googleObj.refreshToken)
    .append('authorizationToken', localStorage.getItem("token"))
    .append('accountEmail',this.accountInformation.email)
    .append('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
    .append('googleMultiSync',"false")
    .append('id',this.accountInformation.id)
    //gsuit/stopwebhook
    this.http.post(`${environment.ZOOM_URL}/53024F4F4DE3BB9728F6C77A156860306C96B149`, payload1, {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
          "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
      }
     }).subscribe((response:any) => {
      this.loading = false; 
     
        if(status == 'DISABLED'){
          response.accessToken = '';
          response.refreshToken = '';
          this.googleObj.multiSync = false;
          this.googleMultiSync = false;
          this.googleObj.username =''
          this.googleObj.syncStatus = 'DISABLED'

        }
        this.updateSyncSetting(response.accessToken,response.refreshToken,'',this.googleObj.username,this.googleObj.syncStatus,'','GOOGLE',this.googleObj.channelId,this.googleObj.resourceId,this.googleObj.multiSync)
      // }
    },err=>{ 
       let response:any ={}
          response.accessToken = '';
          response.refreshToken = '';
          this.googleObj.multiSync = false;
          this.googleMultiSync = false;
          this.googleObj.syncStatus = 'DISABLED'
          this.googleObj.username ='';
           this.updateSyncSetting(response.accessToken,response.refreshToken,'',this.googleObj.username,this.googleObj.syncStatus,'','GOOGLE',this.googleObj.channelId,this.googleObj.resourceId,this.googleObj.multiSync)
    });
  }
  openModal(content=this.microsoftModal){
    let ngbModalOptions: NgbModalOptions = {
      // backdrop: 'static',
      // keyboard: false
      centered:true,
      windowClass:"myModal"
    }
    this.modalService.open(content, ngbModalOptions).result.then(
      result => {
        if (result != 0) {
         
        }
      },
      reason => {
        let closeResult = `Dismissed ${this.getDismissReason(reason)}`
      }
    )

  }
  private getDismissReason (reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }
  updateOutlookSync(type,event, serviceProvider,syncType){
    let multiSync = this.outlookObj.multiSync ? this.outlookObj.multiSync : false
    let scope  =''
    console.log(type,'type')
    if(this.outlookObj.syncStatus != 'ENABLED' )
    {
      console.log('coming2')
      if(serviceProvider == 'OFFICE365')
      {
        this.getmicrosofAccessToken(7)
      }
      else{
        this.getmicrosofAccessToken(12345)
      }
    }
    else{
      console.log('coming',this.outlookObj)
      
      if(syncType==2 && event.target.checked){
          multiSync = true
         this.outlookObj.multiSync = true
          this.addMicrosoftSubscription(type)
          return false
      }
      else if(syncType==2)
      {
        this.deleteMicrosoftSubscription()
        if(type=='contact'){
          this.outlookObj.resourceId =''
        }
        if(type=='calendar'){
          this.outlookObj.channelId =''
        }
        
      }
     else if(syncType==1){
      this.outlookObj[type] = event.target.checked
     }
     
      console.log(this.outlookObj,event.target.checked)
      console.log(this.outlookObj[type],event.target.checked)
      scope = serviceProvider == 'OUTLOOK' ? environment.OUTLOOK_SCOPE : environment.OFFICE365_SCOPE
      this.updateSyncSetting(this.outlookObj.accessToken,this.outlookObj.refreshToken,"",this.outlookObj.username,'ENABLED',scope,'OUTLOOK',this.outlookObj.channelId,this.outlookObj.resourceId,multiSync)
      
    }

  }
  addMicrosoftSubscription(type){
    //office365/eventSubscription
    let endPoint = '1C32AFFC524AA24DA465B77FF91ACDB795A4196B'
    if(type=='contact'){
      //office365/contactSubscription
      endPoint = '806047249B56AB29F5AEFAB95BD37045EBAE1437'
    }
    const payload = new HttpParams()
    .append('accessToken', this.outlookObj.accessToken)
    .append('refreshToken', this.outlookObj.refreshToken)
    .append('email',  this.accountInformation.email)
    .append('userId', this.userId)

   
    this.http.post(`${environment.OUTLOOK_URL}/${endPoint}`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response)
        {
          let channelId = this.outlookObj.channelId
          let resourceId = this.outlookObj.resourceId
          if(type== 'contact'){
            resourceId = response.id
          }
          else{
            channelId = response.id
          }
          this.updateSyncSetting(this.outlookObj.accessToken,this.outlookObj.refreshToken,"",this.outlookObj.username,'ENABLED',environment.OFFICE365_SCOPE,'OUTLOOK',channelId,resourceId,true)
      
          // this.outlookObj.username = response.userPrincipalName
          // this.updateSyncSetting(access_token,refresh_token,expires_in,response.userPrincipalName,'ENABLED',scope,'OUTLOOK',"","",false)
        }
    })
  }
  deleteMicrosoftSubscription(){
    const payload = new HttpParams()
    .append('accessToken', this.outlookObj.accessToken)
    .append('subscriptionId', this.outlookObj.resourceId)
    .append('refreshToken', this.outlookObj.refreshToken)
    .append('userId', this.userId)
    //office365/deleteSubscription
    this.http.post(`${environment.OUTLOOK_URL}/BBD2931B03A1D86375D7A64E572064FD2CC46243`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response)
        {
        }
    })
  }
  deleteAllSubscriptions(accessToken){
    const payload = new HttpParams()
    .append('accessToken', accessToken)
    .append('refreshToken', this.outlookObj.refreshToken)
    .append('userId', this.userId)
    //office365/deleteAllSubscriptions
    this.http.post(`${environment.OUTLOOK_URL}/71269F2912260F7DFA1B021D32FB890822D8330C`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response)
        {
        }
    })
  }
  renewSubscriptions(subscriptionId,type){
    const payload = new HttpParams()
    .append('accessToken', this.outlookObj.accessToken)
    .append('subscriptionId', this.outlookObj.subscriptionId)
    .append('refreshToken', this.outlookObj.refreshToken)
    .append('userId', this.userId)
    //office365/renewSubscription
    this.http.post(`${environment.OUTLOOK_URL}/CADB2EF59E66DA5D38AE3035B999E11724022041`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
            "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
        }
    }).subscribe((response:any) => {
        if(response)
        {
          let channelId = this.outlookObj.channelId
          let resourceId = this.outlookObj.resourceId
          if(type== 1){ // contacts
            resourceId = response.id
            this.outlookObj.resourceId = response.id
          }
          else{ // calendar
            channelId = response.id
            this.outlookObj.channelId = response.id
          }
          this.updateSyncSetting(this.outlookObj.accessToken,this.outlookObj.refreshToken,"",this.outlookObj.username,'ENABLED',environment.OFFICE365_SCOPE,'OUTLOOK',channelId,resourceId,true)
        }
    })
  }
}
  
