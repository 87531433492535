import { Component, OnInit,OnDestroy } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import { membersBasicQuery2 } from '../../../services/admin.gql'
import {  Subject } from 'rxjs';
var moment = require('moment-timezone')
@Component({
  selector: 'app-member-meetings',
  templateUrl: './member-meetings.component.html',
  styleUrls: ['./member-meetings.component.scss']
})
export class MemberMeetingsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
   // thus we ensure the data is fetched before rendering
    dtTrigger = new Subject<any>();
   public member: any={}
   meetings: any
   userId: number
   private queryU: QueryRef<any>
   meetingId: number
   isOpen: boolean
   contacts: any =[]
   loading: boolean = false
   url: any = ''
   meetingsCount = 0
   contactsCount = 0
   query$:any
   queryPr$:any
   meetingQuery$:any
   contactsQuery$:any
   allMeetingQuery$:any
   performanceInfo:any
   members:any =[]
 
   constructor (
     private apollo: Apollo,
   ) {
     
   }
 
   ngOnInit () {
     /** Get today reminders **/
     this.getMembersInfo()
   }
 
   getMembersInfo(){
     this.loading = true
    // querying the members list
    this.queryU = this.apollo.watchQuery({
     query: membersBasicQuery2,
     variables: {
       sortByDate: 'DESC'
     },
     fetchPolicy: 'network-only'
   })

   this.queryU.valueChanges.subscribe(({ data }: any) => {
     this.members = data.users
     this.loading = false
   },error=>{
     this.loading = false
   })
 }
 
   ngOnDestroy(){
     this.query$ ? this.query$.unsubscribe() : "";
     this.queryPr$ ? this.queryPr$.unsubscribe() : "";
     this.meetingQuery$ ? this.meetingQuery$.unsubscribe() : "";
     this.contactsQuery$ ? this.contactsQuery$.unsubscribe() : "";
     this.allMeetingQuery$ ? this.allMeetingQuery$.unsubscribe() : "";
   }
 

}
