import { Component, OnInit,OnDestroy,ChangeDetectionStrategy,Input,ChangeDetectorRef,ViewRef } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'

import {Observable, of} from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import {  Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  productsQuery,productsAllQuery,deleteProductMutution,bulkProductUpdateMutution
} from '../../../services/products.gql'
import { HelperService } from '../../../services/helper.service'
import { ToastrService } from 'ngx-toastr'
import { appUserConnectionsMutation } from '../../../services/user.gql';
import {
  getMembersBasicQuery,
} from '../../../services/admin.gql'
    // keyboard: false
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap'
import * as CryptoJS from 'crypto-js';  
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs'
// import { HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-archive-products',
  templateUrl: './archive-products.component.html',
  styleUrls: ['./archive-products.component.scss']
})
export class ArchiveProductsComponent implements OnInit {

  role:any =''

  private query: QueryRef<any>
  private queryAll: QueryRef<any>
  private query$: any
  isQROpen:boolean = false
  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
   // thus we ensure the data is fetched before rendering
    dtTrigger = new Subject<any>();
  productList:any=[];
  profileInfo:any=[];
  categoriesList:any=[]
  subCategoriesList:any=[]
  subSubCategoriesList:any=[]
  availabilitySizes:any=[]
  productAreas:any =[]
  selectedType:any
  selectedLevel2:any={}
  selectedLevel3:any={}
  selectedArea:any={}
  selectedSize:any={}
  selectedColor:any={}
  selectedSortType:any
  loading:boolean = true
  isGrid:boolean = true
  showCheckboxes:boolean = false
  selectedProducts:any =[]
  selectAll:boolean= false
  asyncMeals: Observable<string[]>;
  page: number = 1;
  total: number;
  pageSize:number =8
  myAngularxQrCode:string | number = 'Your QR code data string';
  products:any = []
  allProducts:any = []
  totalItems:any
  itemsPerPage = 10;
  collection = [];
  searchText:any
  permissionsRes:any ={}
  permissions:any = ""
  organizationId:any 
  members:any=[]
  users:any = []
  accessValue:boolean
  selectProduct:any
  selectedLink:any=''
  sortByColumn:any 
  sortByPosition:any
  isFilter:boolean =false
  p:any
  isAdmin:boolean = false
  accountId:any
  private modalReference: NgbModalRef; 

  constructor(  private activatedRoute: ActivatedRoute,private http: HttpClient,private cd: ChangeDetectorRef,  private apollo: Apollo, private helperService:HelperService,private toastr: ToastrService, private modalService: NgbModal,) {
    
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.page) {
        this.page=params.page;

      }
      
    })
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
       this.organizationId = data.accountInformation.organizationId
       this.accountId = data.accountInformation.id
       this.role = data.accountInformation.role 
       this.getUserDetails()
       this.getAllProducts()
       this.getPage(this.page)
    })
    this.isAdmin = this.helperService.isAdmin()
   }
  sortOptions=[
   
    {
      id:"product_name-desc",
      name:"Name -- DESC"
    },
    {
      id:"product_name-asc",
      name:"Name -- ASC"
    },
    {
      id:"stock_quantity-asc",
      name:"Quantity -- Low to High"
    },
    {
      id:"stock_quantity-desc",
      name:"Quantity -- High to Low"
    },
    // {
    //   id:"product_category-desc",
    //   name:"Category -- DESC"
    // },
    // {
    //   id:"product_category-asc",
    //   name:"Category -- ASC"
    // },
    {
      id:"cost-asc",
      name:"price -- Low to High"
    },
    {
      id:"cost-desc",
      name:"price -- High to Low"
    },
   
  ]
  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 10
    };
    // this.getProfileInfo()
    // this.categories()
    // this.getAllData()
    // this.getProductAreas()
   
    
    
  }
  filterChanged(eve)
  {
    this.sortByPosition = ''
    this.sortByColumn = ''
    if(eve){
      let sortArr = eve.split("-")
      let sortColumn = sortArr[0]
      let sortPosition = sortArr[1].toUpperCase()
      this.sortByColumn = sortColumn
      this.sortByPosition = sortPosition
      this.getPage(this.page,'',sortColumn,sortPosition)
      this.getAllProductList()

    }

    this.selectedSortType = eve
  }
  public getAllProductList()
  {
    this.loading = true
      const inputData :any= {
        "business_userId":this.profileInfo.id,
        "offset":(this.page*this.pageSize)-this.pageSize,
        "limit":this.pageSize,
        "filters":{
          "cat_1":this.selectedType ? [this.selectedType] : [],
          "cat_2":this.selectedLevel2.id?[this.selectedLevel2.id]:[],
          "cat_3":this.selectedLevel3.id?[this.selectedLevel3.id]:[],
          "sizes":this.selectedSize.id?[this.selectedSize.id]:[],
          "area":this.selectedArea.id?[this.selectedArea.id]:[],
          "colors":this.selectedColor.id?[this.selectedColor.id]:[],
        },
        "sorting":this.selectedSortType?this.selectedSortType: "newest"
      };
     
  }
  public getProfileInfo()
  {
     
  }
  public categories(level='level1',id='')
  {
  
  }

  public getSubCategories(event,level='level1',id='')
  {
    // alert(level+''+id)
    // alert(id)
    if(event)
    {
      event.preventDefault();
    }
    

     
   

  }

  public getProductAreas(){
   
  }
  onCheckboxChange(e,type='',selectedValue="") {
    if(type=='level2')
    {
      this.selectedLevel2 = selectedValue
    }
    if(type=='level3')
    {
      this.selectedLevel3 = selectedValue
      this.getAllProductList()

    }
    if(type=='size')
    {
      this.selectedSize = selectedValue
      this.getAllProductList()
    }
    if(type=='area')
    {
      this.selectedArea=selectedValue
      this.getAllProductList()
    }

  }      
  editProducts(){
    this.showCheckboxes = true
  }
  checkUncheckAll (ev) {
    this.selectedProducts = []
    this.products.length > 0 &&
      this.products.forEach((x, i) => {
        x.isSelected = ev
        if (ev) {
          x.isSelected = ev
          this.selectedProducts.push(x)
        }
        
      })
      this.selectAll = !this.selectAll


  }
  isAllChecked () {
    return this.products.every(item => item.isSelected)
  }
  selectCheckbox(ev,id,index){
    if (ev.target.checked) {
      this.selectedProducts.push(id)
    }
    else{
      this.selectedProducts.splice(index,1)
    }
  }
  

  updateProducts(status){
    if(this.selectedProducts.length > 0)
    {
      let ids = this.selectedProducts.join(',')
      const inputData :any= {
        "is_stock_available":status?1:0,
        "ids":ids,
      };
      this.loading = true
     
    }
    else{
      alert("Please select atleast one product.");
     }
  }
  handlePageChange(event) {
    this.page = event;
    this.getAllProductList()
  }
 


  print (product): void {
    this.selectedLink = this.getLink(product.id)
    console.log(this.selectedLink,'this.selectedLink')
    let price = product.minSellingPrice && product.minSellingPrice != null ?  product.minSellingPrice : this.roundToNear(product.displayedSellingPrice)
    setTimeout(()=>{
      let printContents, popupWin
      printContents = document.getElementById('print-section').innerHTML
      let print1 = document.getElementById('data').innerHTML
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
      popupWin.document.open()
      popupWin.document.write(`
          <html>
            <head>
              <title>StayTouch</title>
              <style>
              //........Customized style.......
              </style>
            </head>
        <body onload="window.print();" style="text-align:center;margin:auto;">${print1} 
        <h4 style="margin:0px;font-weight:100;">Design Number: <span style="font-weight:bold">${product.designNumber}</span></h4>
        <h4 style="margin:0px;font-weight:100;">Taj Number: <span style="font-weight:bold">${product.tajNumber}</span></h4>
        <h4 style="margin:2px;font-weight:100;">Price: <span style="font-weight:bold">${this.round(price)} MAD</span></h4>
        <h4 style="margin:2px;font-weight:100;">Displayed selling price: <span style="font-weight:bold">${this.roundToNear(product.displayedSellingPrice)} MAD</span></h4>
        <h4 style="margin:2px;font-weight:100;">Gold Weight: <span style="font-weight:bold">${product.goldWeight} grams</span></h4>
        <h4 style="margin:2px;font-weight:100;">Diamond Weight: <span style="font-weight:bold">${product.diamondWeight} ct</span></h4>
        <h3 style="text-transform: capitalize;">${product.productName}</h3>
        <!--p style="padding:10px 50px;">${product.description}.</p-->
      
        </body>
          </html>`)
      popupWin.document.close()
    },100)
   
  }

  filterProjects(eve){
    this.searchText = ""
    console.log(eve,'eve')
    if(eve.target.value.length <3){
      this.page=1
    }
    if(eve.target.value.length > 2){
      let searchText = eve.target.value
      this.searchText = searchText
      this.getPage(this.page,searchText)
      this.getAllProducts()
      
    }
    
  }


  getAllProducts () {
    let obj:any = {
      archived:true,
    }
     
    if(this.searchText){
      obj.search = this.searchText
    }
    this.queryAll = this.apollo.watchQuery({
      query: productsAllQuery  ,
      variables:obj,
      fetchPolicy:"no-cache"
    })

    this.queryAll.valueChanges.subscribe(({ data }: any) => {
      // this.products = data.products
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
        }
      this.totalItems = data.products.length
      
   
    })
  }

  getPage(page,searchText='',sortColumn='',sortPosition='') {
    this.loading  = true
    this.page = page
    let obj:any = {
      limit:this.itemsPerPage,
      archived:true,
      offset:(this.page*this.itemsPerPage)-this.itemsPerPage,
    }
    if(this.searchText){
      obj.search = searchText
    }
    if(this.sortByColumn){
      obj.sortByColumn = this.sortByColumn
    }
    if(this.sortByPosition){
      obj.sortByPosition = this.sortByPosition
    }
    // if(!this.isAdmin){
    //   obj.userId = this.accountId
    // }
    this.loading = true
   
    this.query = this.apollo.watchQuery({
      query:   productsQuery  ,
      fetchPolicy:"no-cache",
      variables:obj
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.loading = false
      // this.products = data.products
      this.products =  data.products.map(element => {
        element.isSelected = false
        return element
      });
      if (this.cd !== null && this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
        }
    
    },err=>{
      this.loading = false
    })
  }
 

 

  
  genearteKey(id){
    return window.btoa(`productId-${id}`)
  }
  getUserDetails () {
    this.query = this.apollo.watchQuery({
      query: getMembersBasicQuery,
      fetchPolicy:'network-only'
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
     this.members =  data.users.map(element => {
        element.name = `${element.firstName} ${element.lastName}`
        return element
      });
    })
  }

  private getDismissReason (reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC'
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop'
    } else {
      return `with: ${reason}`
    }
  }
  getLink(id){
    return  `https://www.staytouch.com/p/index.php?token=${this.genearteKey(id)}`  
  }
  clearFilter(){
    this.searchText = ""
    this.isFilter= false
    this.getAllProducts()
    this.getPage(1)
  }
  round(number){
return Math.round(number)
  }
  roundToNear(number){
    return Math.ceil(number/100)*100


  }
  sortBy(column){
    if(this.sortByPosition == 'ASC'){
      this.sortByPosition = 'DESC'
    }
    else{
      this.sortByPosition = 'ASC'
    }

    this.sortByColumn = column
    this.getPage(1)
    this.getAllProducts()
  }
}
