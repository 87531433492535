import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
// import { HttpClient } from '@angular/common/http'
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input'
import { environment } from '../../../../environments/environment'
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core'
import { HelperService } from '../../services/helper.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm = null
  errorMessage: string | null
  withEmail: boolean = true
  separateDialCode = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  submitted: boolean = false

 

  constructor (
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private helperService:HelperService
  ) {
    window.localStorage.removeItem('forgotPhone')
    window.localStorage.removeItem('forgotEmail')
    
  }

  ngOnInit () {
    localStorage.clear()
    this.forgotForm = new FormGroup({
      loginEmail: new FormControl('', [Validators.email]), // loginEmail: new FormControl(''),
      loginPhone: new FormControl('', [
        Validators.minLength(10),
        Validators.maxLength(15)
      ])
    })
  }

  fogotPassword () {
    this.submitted = true
    let phonenumber = ''
    // stop here if form is invalid
    if (this.withEmail) {
      if (this.loginEmail.value == '') {
        return
      }
      localStorage.setItem('forgotEmail', this.loginEmail.value)
    }
    if (!this.withEmail) {
      if (this.loginPhone.value == '') {
        return
      }
      phonenumber = `${this.loginPhone.value.dialCode}${this.loginPhone.value.number}`
      localStorage.setItem('forgotPhone', phonenumber)
    }
    if (this.forgotForm.invalid) {
      return
    }
    let userInfo = {
      email: this.loginEmail.value,
      phoneNumber: phonenumber,
      token: environment.AUTH_TOKEN,
      b2b:true,
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
    }
    let headers = {}
    let loginUser = localStorage.getItem("email")
    if(loginUser == 'ramya@krify.net')
    {
       headers = {
        headers: {
          'xsecuritytoken': environment.xsecuritytoken,
          'xframetoken': environment.xframetoken
        }
      }
    }
    //forgot password
    this.http
      .post<any>(`${environment.AUTH_URL}b50bafc56c5b9d279ad2c2d3da7c40c7`, userInfo,headers)
      .subscribe(
        data => {
          if (data.status == 'OK') {
            this.translate
              .get('MESSAGES.OTP_VERIFICATION_MESSAGE')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })

            this.router.navigate(['/otp-verify'])
          }
		 else if (data.error == 'INVALID_USER') {
			alert("No Corporate Account associated with this email. Please contact us at corporatesupport@staytouch.com")
          }
           else {
            //console.log('coming')
          }
        },
        error => {
          //console.log('there was an error sending the query', error)
          if (error == 'INVALID_AUTHORIZATION') {
          }
        }
      )
  }

  get loginEmail () {
    return this.forgotForm.get('loginEmail')
  }

  get loginPhone () {
    return this.forgotForm.get('loginPhone')
  }
  sendData(){
    let array =[{"id":"N144488","userIdOfContact":null,"received":[{"data":[{"key":"","title":"Email","value":"ramya+02@krify.net","format":"EMAIL","__typename":"UserData"}],"displayImageURL":null,"__typename":"ReceivedData"}]}]
    // let userInfo = {
    //   array: JSON.stringify(array)
    // }
    const payload = new HttpParams()
    .append('array', encodeURIComponent(JSON.stringify( JSON.stringify(array))))
    //checkData
    this.http
    .post<any>(`${environment.ZOOM_URL}/checkData`, payload,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "ST-LOGGED-ENCRYPTED":`${this.helperService.cryptKey()}`,
        "ST-ENCYT-KEY":  localStorage.getItem('kToken')?`${JSON.stringify(localStorage.getItem('kToken'))}`:JSON.stringify(this.helperService.rsaKey())  
    }
    })
    .subscribe(
      data => {
        
      },
      error => {
        
      }
    )
  }
}
