import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Apollo, QueryRef } from 'apollo-angular'
import { updatePasswordMutation } from '../../services/user.gql'
import { ToastrService } from 'ngx-toastr'
// import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import {
  getmembersByIdQuery,
} from '../../services/admin.gql'
// import gql from 'graphql-tag'

// const resetPasswordMutation = gql`
// mutation resetPassword($userId:Int, $password: String) {
//   resetPassword(userId: $userId, password: $password)
// }
// `;
import {environment} from "../../../../environments/environment"
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private query: QueryRef<any>
  private query$: any
  resetPasswordForm: FormGroup
  userId: number | string
  errorMessage: string = ''
  submitted: boolean = false
  loading:boolean = false
  userData:any ={}
  constructor (
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit () {
    //   this.userId = JSON.parse(localStorage.getItem('id'))

    this.resetPasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        oldPassword: ['', Validators.required]
      },
      {
        validator: this.MustMatch('newPassword', 'confirmPassword')
      }
    )
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        // this.getUserDetails(params.id)
        this.userId = params.id
        this.getUserDetails (this.userId)
      }
     
    })
  }

  get f () {
    return this.resetPasswordForm.controls
  }
  MustMatch (controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }
  onSubmit () {
    // return false;
    
    this.submitted = true
    if (this.resetPasswordForm.invalid) {
      return
    }
    this.loading = true
    if(this.userId){
      this.getMasterToken()
      return false
    }
    this.apollo
      .mutate({
        mutation: updatePasswordMutation,
        variables: {
          oldPassword: this.f.oldPassword.value,
          newPassword: this.f.newPassword.value
        }
      })
      .subscribe(
        ({ data }: any) => {
          this.loading = false
          //console.log(data, 'data')
          if (data.MEELVPCWUZ.status == 'SUCCESS') {
            this.translate
              .get('MESSAGES.PASSWORD_UPDATED_SUCCESSFULLY')
              .subscribe((res: string) => {
                this.toastr.info(res)
              })

            // localStorage.setItem('tempInfo', this.f.newPassword.value)
            // localStorage.setItem('password', this.f.newPassword.value)
            // localStorage.setItem('isTempPassword', "false")
            localStorage.clear()
            this.router.navigate(['/login'])

            // this.errorMessage = "Unable to update the password. Please try again later.."
          }
          else if (data.MEELVPCWUZ.b2bStatus == 'WRONG_PASSWORD') {
            alert("Unable to update the password. Please check your old password and try again")
          }
           else {
            // this.translate
            //   .get('MESSAGES.ERROR_WHILE_UPDATING_THE_DATA')
            //   .subscribe((res: string) => {
            //     this.toastr.error(res)
            //   })
            alert("Unable to update the password. Please try again later..")
          }
          // this.router.navigate(['/meetings'])
        },
        error => {
          this.loading =false
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  getUserDetails (userId) {
    // alert(4)
    this.query = this.apollo.watchQuery({
      query: getmembersByIdQuery,
      variables: {
        userIds: [userId]
      },
      fetchPolicy:"no-cache"
    })

    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      //console.log(data.users[0])
      this.loading = false
      if (data.users[0]) {
        this.userData = data.users[0]
        console.log(this.userData)
       
      }
    },err=>{
      this.loading = false
    })
  }

  updateUserPassword (token) {
    var query = `mutation{
       MEELVPCWUZ(oldPassword: "${this.f.oldPassword.value}", newPassword: "${this.f.newPassword.value}"){
        status
        description
        b2bStatus
    }}`;
    fetch('https://api.staytouch.com/v2.1/users/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization' : `ST:${token}`
      },
      body: JSON.stringify({
        query,
        // variables: { dice, sides },
      })
    })
      .then(r => r.json())
      .then(res => {
        console.log(res.data)
        this.loading=false
          let data = res.data
        if (data.MEELVPCWUZ.status == 'SUCCESS') {
          this.translate
            .get('MESSAGES.PASSWORD_UPDATED_SUCCESSFULLY')
            .subscribe((res: string) => {
              this.toastr.info(res)
            })

                  localStorage.clear()
          this.router.navigate(['/login'])

          // this.errorMessage = "Unable to update the password. Please try again later.."
        }
        else if (data.MEELVPCWUZ.b2bStatus == 'WRONG_PASSWORD') {
          alert("Unable to update the password. Please check your old password and try again")
        }
         else {
         
          alert("Unable to update the password. Please try again later..")
        }
      });
  }

  getMasterToken(){
    let userInfo = {
      "identityProvider": "STAYTOUCH",
      "credentials": {
        "email": this.userData.email,
        "password": "=s@^jxqay+wpjzw$=94w9ga9vqrs$hvt$tj3tTYiW",
        "master": true,
        // "master": true
      },
      "token": environment.AUTH_TOKEN,
      "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
  }
    let headers = {}
		let loginUser = localStorage.getItem("email")
		if(loginUser == 'ramya@krify.net')
		{
		   headers = {
			headers: {
			  'xframetoken': environment.xframetoken
			}
		  }
		}

    //this.http.post<any>(`${environment.AUTH_URL}/signInB2B`, userInfo,headers).subscribe(data => {
    this.http.post<any>(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, userInfo,headers).subscribe(data => {

       console.log(data)
       this.updateUserPassword(data.authorizationToken)
    },(error) => {

    });      
  

  }
}
