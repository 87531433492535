<div class="row justify-content-center " id="addTeamBlock">
  <div class="col-md-12 p-2">
    <div class="card p-md-5 py-5 my-3 ">
      <app-loading *ngIf="loading"></app-loading>

      <form
        class="meetingsBlock row "
        #addTeamForm="ngForm"
        (ngSubmit)="addTeamForm.form.valid && saveTeam()"
      >
        <div class="py-2 col-md-6">
          <h4 class="mem1 mb-0 pb-3">{{ (!teamUpdate.teamId ? 'SUBMENU.ADD_TEAM' : 'TEAMSPAGE.EDIT_TEAM') | translate }}</h4>
          <div class="d-flex">
            <label style="width:180px;" class="mb-0">{{
              'TEAMSPAGE.TEAM_NAME' | translate
            }}</label>
            <div class="w-100">
              <input
              type="text"
              name="name"
              id="name"
              [(ngModel)]="name"
              required
              autocomplete="off"
              class="theme-input"
              #title1="ngModel"
            />
                <div
                *ngIf="
                  title1.invalid &&
                  (title1.dirty || title1.touched || addTeamForm.submitted)
                "
              >
                <p class="text-danger" *ngIf="title1.errors.required">
                  Team Name is required.
                </p>
                <!-- <p class="text-danger" *ngIf="title1.errors.minlength">
                  Team Name should be minimum of 6 characters.
                </p> -->
              </div>
            </div>
           
          </div>
         
        </div>
        <div class="py-2 col-md-6" >
          <div class="d-flex justify-content-between" >
            <h5 class="mem mb-0" style="padding-bottom: 17px;">
              {{ 'SUBMENU.MEMBERS' | translate }}
            </h5>
            <a
            (click)="addMoreMembers()"
              class="theme-btn1"
              style="    text-decoration: underline;"
              >{{ 'TEAMSPAGE.ADD_MORE_MEMBERS' | translate }}</a
            >
          </div>
          <div class="d-none">
            <div *ngFor="let user of users">
              <div class="d-flex text-left dropdown-custom">
                <img src="assets/default.jpg" class="borderImage border-50" />
                <div>
                  <p class="mb-0 text-capitalize">{{ user.firstName }}</p>
                </div>
              </div>
            </div>
            <a class="moreUserBtn" *ngIf="users">
              5+
            </a>
            <a>
              <i
                class="fa fa-plus-circle"
                aria-hidden="true"
                style="font-size: 50px;color: #2d7be2;"
              ></i>
              <p>Add More</p>
            </a>
          </div>
          <div class="teams-dp inner-addon right-addon position-relative">
            <img
              src="assets/icon_search.svg"
              alt="Plus"
              height="20px"
              class="position-absolute"
              style="top: 8px;right: 10px;z-index:1"
            />
            <ngx-select
              name="participants"
              [allowClear]="true"
              [multiple]="true"
              [keepSelectedItems]="false"
              [items]="members"
              optionValueField="userId"
              optionTextField="firstName"
              [disabled]="ngxDisabled"
              [(ngModel)]="ngxMembersValue"
              placeholder="{{ 'SUBMENU.MEMBERS' | translate }}"
              class="theme-select team-select"
              (select)="teamMemberAdd($event)"
              (remove)="removeMemberAddFun($event)"
            >
              <ng-template
                ngx-select-option
                ngx-select-option-selected
                let-option
                let-text="text"
              >
                <div class="d-flex text-left dropdown-custom">
                  <img
                    src="assets/default.jpg"
                    class="borderImage1 border-501"
                    height="25px"
                    width="auto"
                  />
                  <div>
                    <!-- <p [innerHtml]="text" class="mb-0"></p> -->
                    <p class="mb-0">{{option.data.firstName}} {{option.data.lastName}}</p>
                    <!--
                    <div class="text-gray optionClr">
                      {{ option.data.position }}
                    </div>
-->
                  </div>
                </div>
              </ng-template>

              <ng-template ngx-select-option-not-found let-input>
                {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
              </ng-template>
            </ngx-select>
          </div>

          <!--
          <p class="mems py-4">
            <a [routerLink]="['/admin/create-member']">{{'TEAMSPAGE.ADD_MORE_MEMBERS' | translate}}</a>
          </p>
-->
        </div>
        <div></div>
        <div class="py-2 text-center col-md-12">
          <input
            type="submit"
            [value]="addOrSaveTeam | translate"
            class="theme-btn"
          />
        </div>
        <div
          class="py-2 text-center col-md-12 text-gray"
          (click)="closeAddTeam()"
          style="cursor: pointer;"
        >
          {{ 'PROFILE.CANCEL' | translate }}
        </div>
      </form>
    </div>
  </div>
</div>
