import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Apollo, QueryRef } from 'apollo-angular'
import {environment} from "../../../environments/environment"
import { accountInfo,updateZoomMutution,updateSyncSettingsMutation,syncSettings } from '../services/user.gql'
import { env } from 'process';

@Injectable({
    providedIn: 'root'
  })

export class SyncService {
  private querySync:QueryRef<any>

  constructor(private http: HttpClient, private apollo: Apollo) {
  }
    getSalesforceRefreshToken(userObj){
      let {accessToken,refreshToken,username} = userObj
  
      const payload = new HttpParams()
      .append('accessToken', accessToken)
      .append('refreshToken',refreshToken)
      //getSalesforceRefreshToken
     return  this.http.post(`${environment.OUTLOOK_URL}/CF1C69FADCEA1A975206EE0CCD09EB6B8778BEC7`, payload, {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          }
      })
    }
  
    updateSyncSetting(access_token,refresh_token,expires_in,username,status,scope,serviceProvider){
      return this.apollo
          .mutate({
            mutation: updateSyncSettingsMutation,
            variables: {
              serviceProvider: serviceProvider,
              syncStatus:status,
              credentials: {
                username:username,
                accessToken: access_token,
                refreshToken: refresh_token,
                scope:scope,
                idToken:""
              }
            }
          })
     
    }
     getSyncSettings()
    {
      return this.querySync = this.apollo.watchQuery({
        query: syncSettings,
        variables: {},
        fetchPolicy:"network-only"
      })
     
    }
    getOutlookContacts(accessToken, refreshToken ){
      const payload = {
        accessToken: accessToken,
        refreshToken: refreshToken
      }
      //importOutlookContacts
      return  this.http.post(`${environment.OUTLOOK_URL}/A9193645475121127EDB6E8541E5CFC90EBCE21F`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }   
    static saveURL(): any {
      console.log('rmaya')
      return 1
    }
    getGoogleContacts(accessToken){
     
      return  this.http.get(`https://people.googleapis.com/v1/people/me/connections?personFields=emailAddresses,addresses,names,locations,phoneNumbers,organizations,&pageSize=1000`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }   


    microsoftSign(){
      //Build the request URL.  The base URL and next 4 items are typically always the same for Webex Teams web apps
      var requestUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&scope=${environment.OUTLOOK_SCOPE}&response_mode=query&state=12345&client_id=${environment.OUTLOOK_CLIENTID}&redirect_uri=${environment.OUTLOOK_IMPORT_REDIRECTION_URL}`
      console.log(requestUrl)
      window.location.href = requestUrl; // Redirect the browser to the OAuth2 kickoff URL
   }
   googleSign(){
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/auth?';
    const client_id = '865594219332-aqe1cbf81rk159i39l5ssltjmklr1bnj.apps.googleusercontent.com';
    const scope = environment.GOOGLE_SCOPE
    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    let requestUrl = `${oauth2Endpoint}scope=${scope}&response_type=code&access_type=offline&state=4&redirect_uri=${environment.OUTLOOK_IMPORT_REDIRECTION_URL}&client_id=${client_id}`
      window.location.href = requestUrl;
      console.log(requestUrl)
   }
   getMicroSoftOauthToken(code)
   {
      const payload = {
        code:code,
        redirectUrl: environment.OUTLOOK_IMPORT_REDIRECTION_URL,
        scope:environment.OUTLOOK_SCOPE,
        grant_type:"authorization_code"
      }
      //getMicroSoftOauthToken
      return  this.http.post(`${environment.OUTLOOK_URL}/988AFFDC5135596C9B865733270A2087004AB185`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
      })
   }
   getGoogleOauthToken(code)
   {
      const payload = {
        code:code,
        redirectUrl: environment.OUTLOOK_IMPORT_REDIRECTION_URL,
        scope:environment.GOOGLE_SCOPE,
        grant_type:"authorization_code"
      }
      //getGoogleAuthToken
      return  this.http.post(`${environment.OUTLOOK_URL}/5EF60FC497D6F853EE3DF607226F4581AB1850C4`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
      })
   }

  /*
  * Create form to request access token from Google's OAuth 2.0 server.
  */
  oauthGoogleSignIn(type="import") {
      // Google's OAuth 2.0 endpoint for requesting an access token
      const {GOOGLE_ENDPOINT, GOOGLE_CLIENT_ID, GOOGLE_SCOPE, GOOGLE_IMPORT_REDIRECTION_URL} = environment
      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      let requestUrl = `${GOOGLE_ENDPOINT}scope=${GOOGLE_SCOPE}&response_type=code&access_type=offline&state=4&redirect_uri=${GOOGLE_IMPORT_REDIRECTION_URL}&client_id=${GOOGLE_CLIENT_ID}`
        window.location.href = requestUrl;
      // Add form to page and submit it to open the OAuth 2.0 endpoint.
    }
    

}


  
