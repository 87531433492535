import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-create-new-label',
  templateUrl: './create-new-label.component.html',
  styleUrls: ['./create-new-label.component.scss']
})
export class CreateNewLabelComponent implements OnInit {
  public error = "";
  @Input() public newLabel;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    // console.log(this.user);
  }

  passBack() {

    if(this.newLabel == '')
    {
      this.error ="Please enter label";
      return false;
    }
    else
    {
      this.passEntry.emit(this.newLabel);
      this.activeModal.close(this.newLabel);
    }
    this.passEntry.emit(this.newLabel);
    this.activeModal.close(this.newLabel);
  }

}
