
<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card p-md-5 py-5  ">
      <div class="dropdown p-absolute pr-2">
        <a [routerLink]="['/connections']"  > 
          <img src="assets/back.svg?x=1" class="arrowIconn" /> {{'COMMON.BACK' | translate}}
          </a>
      </div>
   
      <ng-container *ngIf="teams.length >= 0">
        <ul class="m-0 p-0 temsBlk-lg" id="accordion"  #teamsTarget>
          <li class="" *ngFor="let team of teams;let i=index">
            <a
              class="d-sm-flex justify-content-between"
              data-toggle="collapse"
              href="#collapseOne-{{ team.teamId }}"
              id="collapse-{{ team.teamId }}"
            >
              <p id="team-{{ team.teamId }}" (click)="getMemberDetails(team)" class="m-0 flex-1">
                {{ team.name }}
              </p>
              <div class="text-right">
               <span (click)="getMemberDetails(team)" style="
               padding: 24px 0px;
           "> {{
                  'TEAMSPAGE.N_MEMBERS'
                    | translate: { members_count: team.memberIds.length }
                }}</span>
               
              </div>
              <!-- </div> -->
            </a>

            <div
             
            >
              <p
                (click)="sortMemberName()"
                *ngIf="false && members.length > 0"
                class="small-font-mb cursor-pointer pt-3 mb-0"
              >
                {{ 'SUBMENU.MEMBERS' | translate }} &ensp;<img class="arrImg"
                  *ngIf="isMemberSort"
                  [src]="isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                /><img class="arrImg"
                  *ngIf="!isMemberSort"
                  [src]="!isMemberSort ? 'assets/arr.svg?x=1' : 'assets/arrr1.svg?x=1'"
                  style="transform: rotate(180deg);"
                />
              </p>
              <div class="row">
                <div
                  class="col-md-6 py-2 d-block d-md-flex align-items-center justify-content-between w-100 cursor-pointer"
                  
                  *ngFor="let user of members"
                >
                  <div class="" [routerLink]="['/view-member', user.userId]">
                    <a class="mr-1 text-decoration-none d-block border-0 py-2">
                      <img
                        src="{{user.profilePic}}"
                        width="50px"
                        height="50px"
                        class="borderImage-sm "
                        onerror="this.src='assets/default.jpg'"
                      />
                    </a>
                  </div>
                  <div
                    class=" w-100  d-block d-md-flex justify-content-between align-items-center"
                  >
                    <div class="col-md-8" [routerLink]="['/view-member', user.userId]">
                      <h6 class="card-title mb-1 text-capitalize">
                        {{ user.firstName }} {{ user.lastName }}
                      </h6>
                      <span class="card-text p-0 m-0 ">{{ user.title }}</span>
                      <span *ngIf="user.department && user.title">, </span>
                      <span class="card-text p-0 m-0 "
                        >{{ user.department }}
                      </span>
                    </div>
                   
                  </div>
                </div>
                <h5
                  class="p-5 text-center w-100"
                  *ngIf="!loading && members.length == 0"
                >
                  No members are found.
                </h5>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <h5 *ngIf="teams.length==0" class="text-center">No team info.</h5>
  
    </div>
  </div>
</div>
</div>

