import { Component, OnInit,ChangeDetectorRef } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  profilesQuery,
  createProfileMemberMutution,
  updateProfileMemberMutution
} from '../../../services/user.gql'
import { Store } from '@ngrx/store'

import { Observable } from 'rxjs'

import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CreateNewLabelComponent } from './create-new-label/create-new-label.component'
import {
  addressTypes,
  linkTypes,
  customTypes,
  emailTypes,
  phoneTypes
} from './staticLabels'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { memberHelper } from './member-helper'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input'
import {accountInfo} from '../../../services/user.gql'
import * as data from '../../../../shared/country.json'
import { TranslateService } from '@ngx-translate/core'
import { profileState,AppState } from '../../../../user/store/app.states'
import {  ProfileUpdate } from '../../../../user/store/actions/profile.actions'
import * as $ from 'jquery' 
import {
  getCompanyQuery,createCompanyMutation
} from '../../../services/contacts.gql'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-official-profile',
  templateUrl: './official-profile.component.html',
  styleUrls: ['./official-profile.component.scss']
})
export class OfficialProfileComponent implements OnInit {
  private query: QueryRef<any>
  profileForm: FormGroup
  public years: any[] = []
  public userId: number
  public addressTypes: any[] = addressTypes
  public linkTypes: any[] = linkTypes
  public phoneTypes: any[] = phoneTypes
  public emailTypes: any[] = emailTypes
  public customTypes: any[] = customTypes
  public userData: any = []
  role:any 
  url: any = 'assets/default.jpg'
  public profilePic: any
  public countryList: any = []
  public isOpen = false
  separateDialCode = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  public countryData: any = data
  getProfile: Observable<any>
  loading:boolean =false;
  socialType =1
  updateProfileStatus:boolean = false
  enableAutoCountrySelect:boolean = true
  companiesArray:any =[]
  private companyQuery: QueryRef<any>
  private queryAcc: QueryRef<any>
  accountInfo:any ={}
  domainsArray:any =[]
  allCompanies:any =[]
  commonDomains:any =[
    "outlook",
    "gmail",
    "yahoo",
    "inbox",
    "icloud"
  ]
  timeout: any = null;
  constructor (
    private apollo: Apollo,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private helper: memberHelper,
    private http: HttpClient,
    private translate: TranslateService,
    private store: Store<AppState>,
    private helperService:HelperService,
    private cd: ChangeDetectorRef
  ) {
    this.role = localStorage.getItem('role')
    this.countryList = this.countryData ? this.countryData.default : []

    //this.countryList = data? data.default : []
    const year = new Date().getFullYear()
    for (var i = 1960; i <= year; i++) {
      this.years.push({
        id: i + 1,
        text: i + 1
      })
    }
    this.queryAcc = this.apollo.watchQuery({
      query: accountInfo,
      variables: {},
    })
     this.queryAcc.valueChanges.subscribe(({ data }: any) => {
      this.accountInfo = data.accountInformation
    })
  }
  ngOnInit () {

  
    this.profileForm = this.formBuilder.group({
      jobTitle: [''],
      company: [''],
      university: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      department: [''],
      facebook: this.formBuilder.array([this.helper.createFacebookFormGroup()]),
      twitter: this.formBuilder.array([this.helper.createTwitterFormGroup()]),
      tiktok: this.formBuilder.array([this.helper.createTikTokFormGroup()]),
      linkedIn: this.formBuilder.array([this.helper.createLinkFormGroup()]),
      instagram: this.formBuilder.array([this.helper.createInstagramFormGroup()]),
      snapchat: this.formBuilder.array([this.helper.createSnapchatFormGroup()]),
      education: this.formBuilder.array([
        this.helper.createEducationFormGroup()
      ]),
      address: this.formBuilder.array([this.helper.createAddressFormGroup()]),
      custom: this.formBuilder.array([this.helper.createCustomFromGroup()]),
      emails: this.formBuilder.array(
        [this.helper.createEmailFormGroup()],
        [Validators.email]
      ),
      phones: this.formBuilder.array([this.helper.createPhoneFormGroup()]),
      links: this.formBuilder.array([this.helper.createLinkFormGroup()]),
      description: [''],
      employees: this.formBuilder.array(
        [this.createEmpFormGroup()],
        [Validators.required]
      )
    })
    this.getUserDetails()
    this.getTheCompanyValue1('',true)
  }

  saveMember () {
    let memberObj: any = {}
    let dataArray: any = []
    if (
      this.f.education.value[0].startYear > this.f.education.value[0].endYear
    ) {
      return false
    }
    if (this.f.jobTitle.value != '') {
      const jobTitle = {
        title: 'Position',
        value: this.f.jobTitle.value,
        format: 'JOB_TITLE'
      }
      dataArray.push(jobTitle)
    }
    if (this.f.company.value != '') {
      const company = {
        title: 'Company',
        value: this.f.company.value,
        format: 'ORGANIZATION'
      }
      dataArray.push(company)
    }
    if (this.f.university.value != '') {
      const university = {
        title: 'University',
        value: this.f.university.value,
        format: 'ORGANIZATION'
      }
      dataArray.push(university)
    }
    // if (this.f.facebook.value != '') {
    //   dataArray.push({
    //     title: 'Facebook',
    //     value: this.f.facebook.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.twitter.value != '') {
    //   dataArray.push({
    //     title: 'Twitter',
    //     value: this.f.twitter.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.linkedIn.value != '') {
    //   dataArray.push({
    //     title: 'Linked In',
    //     value: this.f.linkedIn.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.ticktok.value != '') {
    //   dataArray.push({
    //     title: 'TikTok',
    //     value: this.f.ticktok.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.instagram.value != '') {
    //   dataArray.push({
    //     title: 'Instagram',
    //     value: this.f.instagram.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.snapchat.value != '') {
    //   dataArray.push({
    //     title: 'Snapchat',
    //     value: this.f.snapchat.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    if (this.f.department.value != '') {
      dataArray.push({
        title: 'Department',
        value: this.f.department.value,
        format: 'DEPARTMENT'
      })
    }

    this.f.address.value.forEach(function (address, i) {
      if (
        address.street1 != '' ||
        address.street != '' ||
        address.city != '' ||
        address.state != ''
      ) {
        let addValue = `${address.street}$$$${address.street1}$$$${address.postCode}$$$${address.city}$$$${address.state}$$$${address.country}`
        dataArray.push({
          title: address.title,
          value: addValue,
          format: 'LOCATION',
          admin: address.admin
        })
      }
    })
    this.f.education.value.forEach(function (edu, i) {
      if (
        edu.startYear != '' ||
        edu.endYear != '' ||
        edu.study != '' ||
        edu.university != '' ||
        edu.degree != ''
      ) {
        let eduValue = `${edu.university}$$$${edu.degree}$$$${edu.study}$$$${edu.startYear}$$$${edu.endYear}`
        dataArray.push({
          title: 'Education',
          value: eduValue,
          format: 'UNIVERSITY',
          admin:edu.admin
        })
      }
    })
    this.f.phones.value.forEach(function (phone1, i) {
      if (phone1.value) {
        if (
          phone1.value.number &&
          (phone1.value.number != '' || phone1.title != '')
        ) {
                  let key = phone1.value.countryCode

          let number = phone1.value.internationalNumber.split('-').join('')
          number = number.split(' ').join('')
          //console.log(phone1.value.internationalNumber)
          dataArray.push({
            title: phone1.title,
            key: key.toUpperCase(),
            value: number,
            format: phone1.format,
            admin:phone1.admin
          })
        }
      }
    })
    this.f.emails.value.forEach(function (email, i) {
      if (email.value != '') {
      email.value = email.value.toLocaleLowerCase()
      email.admin = email.admin
        dataArray.push(email)
      }
    })
    this.f.links.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = link.admin
        dataArray.push(link)
      }
    })
    this.f.custom.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        cutm.admin = cutm.admin
        dataArray.push(cutm)
      }
    })
    this.f.facebook.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = link.admin
        dataArray.push(link)
      }
    })
    this.f.twitter.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = link.admin
        dataArray.push(link)
      }
    })
    this.f.tiktok.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = link.admin
        dataArray.push(link)
      }
    })
    this.f.linkedIn.value.forEach(function (link, i) {
      if (link.value != '') {
        link.admin = link.admin
        dataArray.push(link)
      }
    })

    this.f.instagram.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        cutm.admin = cutm.admin
        dataArray.push(cutm)
      }
    })
    this.f.snapchat.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        cutm.admin = cutm.admin
        dataArray.push(cutm)
      }
    })
    // dataArray= dataArray.concat(this.f.emails.value)
    //dataArray =dataArray.concat(this.f.phones.value)
    //dataArray= dataArray.concat(this.f.custom.value)
    //  dataArray =dataArray.concat(this.f.links.value)
    memberObj.profileId = this.userData.profiles[0]
      ? this.userData.profiles[0].profileId
      : ''
    memberObj.userId = this.userId
    memberObj.data = dataArray
//console.log(memberObj)
    // return

    if(this.f.company.value && !this.companiesArray.some(item => item.name.toLowerCase() == this.f.company.value.toLowerCase()))
    {
      this.createNewCompany(this.f.company.value)
    }
    if (memberObj.profileId) {
      this.updateMember(memberObj)
      return
    }
    this.createOfficialProfile(memberObj)
  }

  getUserDetails () {
    this.query = this.apollo.watchQuery({
      query: profilesQuery,
     fetchPolicy:"no-cache"
    })
    this.query.valueChanges.subscribe(({ data }) => {
      this.userData = []
      this.userData = data
      let emailArr = []

      let isEmail, isAddress, isLink, isCustom, isPhone,isFacebook, isTwitter, isSnapchat, isInstagram, isLinkedIn, isTiktok
      if(this.userData.profiles.length > 0)
      {
          this.updateProfileStatus =true
      }
      else {
        let email = localStorage.getItem('email') ? localStorage.getItem('email') : ""
        let phone = localStorage.getItem('phone') ? localStorage.getItem('phone') : ""
        
        this.getData('emails').removeAt(0)
      
        this.getData('emails').push(
          this.formBuilder.group({
            value: [email, [Validators.required, Validators.email]],
            format: ['EMAIL'],
            title: ['Email'],
            key:[''],
          
          })
        )
        if(phone != "null")
        {
          this.getData('phones').removeAt(0)
          this.getData('phones').push(
            this.formBuilder.group({
              value: ['+' + phone],
              format: ['PHONE_NUMBER'],
              title: ['Phone'],
              key: ['']
            })
          )
        }
     
      }
      this.userData.profiles[0] &&
        this.userData.profiles[0].data.forEach((item, index) => {
          delete item.__typename
          if (this.userData.profiles[0]) {
            this.url = this.userData.profiles[0].displayImageURL
          }
          if (item.format == 'EMAIL') {
            const found = emailTypes.some(el => el.id === item.title)
            if (!found) emailTypes.push({ id: item.title, value: item.title })
            this.getData('emails').push(
              this.formBuilder.group({
                value: [item.value, [Validators.required, Validators.email]],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isEmail = true
          } else if (item.format == 'PHONE_NUMBER') {
            let moblie = item.value
            const found = phoneTypes.some(el => el.id === item.title)
            if (!found) phoneTypes.push({ id: item.title, value: item.title })
            this.getData('phones').push(
              this.formBuilder.group({
                value: [moblie],
                format: [item.format],
                title: [item.title],
                key: [item.key.toUpperCase()],
                admin:[item.admin]
              })
            )
            if(item.key =='RU')
            {
              setTimeout(()=>{
              $(".selected-flag .iti-flag").removeClass("kz")
            },100)

            }
            if(item.key =='RU')
            {
              setTimeout(()=>{
                $(".selected-flag .iti-flag").removeClass("kz")
                $(".selected-flag .iti-flag").removeClass("us")
                $(".selected-flag .iti-flag").addClass("ru")
              },1000)
            }
            
            
            isPhone = true
          } else if (item.format == 'LINK') {
            const found = linkTypes.some(el => el.id === item.title)
            if (!found) linkTypes.push({ id: item.title, value: item.title })
            this.getData('links').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isLink = true
          } else if (item.format == 'UNIVERSITY') {
            this.getData('education').removeAt(0)

            let education = item.value.split('$$$')
            this.getData('education').push(
              this.formBuilder.group({
                format: [item.format],
                title: [item.title],
                key: [''],
                university: [education[0]],
                degree: [education[1]],
                study: [education[2]],
                startYear: [education[3]],
                endYear: [education[4]],
                admin:[item.admin]
              })
            )
          } else if (item.format == 'GENERIC') {
            const found = customTypes.some(el => el.id === item.title)
            if (!found) customTypes.push({ id: item.title, value: item.title })
            this.getData('custom').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isCustom = true
          } else if (item.format == 'LOCATION') {
            const found = addressTypes.some(el => el.id === item.title)
            if (!found) addressTypes.push({ id: item.title, value: item.title })
            let location = item.value.split('$$$')
            this.getData('address').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                street: [location[0]],
                street1: [location[1]],
                city: [location[3]],
                state: [location[4]],
                country: [location[5]],
                postCode: [location[2]],
                key: [''],
                admin:[item.admin]
              })
            )
            isAddress = true
          }

          if (item.format == 'JOB_TITLE') {
            this.profileForm.patchValue({
              jobTitle: item.value
            })
          }
          if (item.format == 'ORGANIZATION' && item.title.toLowerCase()=='company') {
            this.profileForm.patchValue({
              company: item.value
            })
          }
          if (item.format == 'ORGANIZATION' && item.title.toLowerCase()=='university') {
            this.profileForm.patchValue({
              university: item.value
            })
          }
          // if (item.title == 'Facebook') {
          //   this.profileForm.patchValue({
          //     facebook: item.value
          //   })
          // }
          // if (item.title == 'Twitter') {
          //   this.profileForm.patchValue({
          //     twitter: item.value
          //   })
          // }
          // if (item.title == 'Linked In') {
          //   this.profileForm.patchValue({
          //     linkedIn: item.value
          //   })
          // }
          // if (item.title == 'TikTok') {
          //   this.profileForm.patchValue({
          //     ticktok: item.value
          //   })
          // }
          // if (item.title == 'Instagram') {
          //   this.profileForm.patchValue({
          //     instagram: item.value
          //   })
          // }
          // if (item.title == 'Snapchat') {
          //   this.profileForm.patchValue({
          //     snapchat: item.value
          //   })
          // }

          if (item.title == 'Facebook') {
            // this.profileForm.patchValue({
            //   facebook: item.value
            // })
            // const found = linkTypes.some(el => el.id === item.title)
            // if (!found) linkTypes.push({ id: item.title, value: item.title })
            
            this.getData('facebook').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isFacebook = true
          }
          if (item.title == 'Twitter') {
            // this.profileForm.patchValue({
            //   twitter: item.value
            // })
            this.getData('twitter').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isTwitter = true
          }
          if (item.title == 'Linked In') {
            // this.profileForm.patchValue({
            //   linkedIn: item.value
            // })
               // })
               this.getData('linkedIn').push(
                this.formBuilder.group({
                  value: [item.value],
                  format: [item.format],
                  title: [item.title],
                  key: [''],
                  admin:[item.admin]
                })
              )
              isLinkedIn = true
          }
          if (item.title == 'TikTok') {
            // this.profileForm.patchValue({
            //   ticktok: item.value
            // })
            this.getData('tiktok').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isTiktok = true
          }
          if (item.title == 'Instagram') {
            // this.profileForm.patchValue({
            //   instagram: item.value
            // })
            this.getData('instagram').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isInstagram = true
          }
          if (item.title == 'Snapchat') {
            // this.profileForm.patchValue({
            //   snapchat: item.value
            // })
            this.getData('snapchat').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: [''],
                admin:[item.admin]
              })
            )
            isSnapchat = true
          }
          if (item.format == 'DEPARTMENT') {
            this.profileForm.patchValue({
              department: item.value
            })
          }
        })
      if (isEmail) {
        this.getData('emails').removeAt(0)
      }
      if (isPhone) {
        this.getData('phones').removeAt(0)
      }
      if (isLink) {
        this.getData('links').removeAt(0)
      }
      if (isCustom) {
        this.getData('custom').removeAt(0)
      }
      if (isAddress) {
        this.getData('address').removeAt(0)
      }
      if (isLinkedIn) {
        this.getData('linkedIn').removeAt(0)
      }
      if (isInstagram) {
        this.getData('instagram').removeAt(0)
      }
      if (isSnapchat) {
        this.getData('snapchat').removeAt(0)
      }
      if (isTwitter) {
        this.getData('twitter').removeAt(0)
      }
      if (isTiktok) {
        this.getData('tiktok').removeAt(0)
      }
      if (isFacebook) {
        this.getData('facebook').removeAt(0)
      }
      this.profileForm.patchValue({
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      })
    })
    setTimeout(()=>{
      this.enableAutoCountrySelect = false
    },2000)
   
  }
  //   get emails(): FormArray {
  //     return this.profileForm.get('emails') as FormArray;
  //  }
  updateMember (memberObj) {

    memberObj.profileId = this.userData.profiles[0].id
    memberObj.userId = this.userId
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateProfileMemberMutution,
        variables: memberObj
        // update: (proxy, { data: {LQQHPRFSLK} }:any) => {
        //   let data: any = proxy.readQuery({ query:profilesQuery });
        //   console.log(data)
        //   const foundIndex = data.users.findIndex(x => x.userId == memberObj.userId);
        //   data.users[foundIndex].profiles[0] = LQQHPRFSLK;
        //   proxy.writeQuery({ query: profilesQuery, data });
        //   data = proxy.readQuery({ query:profilesQuery,variables:{userIds: [memberObj.userId]} });
        //   data.users[foundIndex].profiles[0] = LQQHPRFSLK;

        //   proxy.writeQuery({ query: profilesQuery, data });
        // }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.dDJrSRUutB) {
            if (this.profilePic) {
              this.fileUploadToServer(data.dDJrSRUutB.displayImagePutURL)
            } else {
              this.translate
                .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
              this.router.navigate(['/members'])
            }
          
          }
        },
        error => {
          this.loading = false
          // this.toastr.info("Already email/phone number exists.");
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  createEmpFormGroup () {
    return this.formBuilder.group({
      empName: ['', [Validators.required]],
      age: ['', [Validators.required, Validators.min(21)]],
      skill: ['', [Validators.required]]
    })
  }

  removeBlock (idx: number, type) {
    this.getData(type).removeAt(idx)
  }

  createOfficialProfile (memberObj) {
    this.loading = true
    this.apollo
      .mutate({
        mutation: createProfileMemberMutution,
        variables: memberObj,
        update: (proxy, { data: { QJTHNWOMEA } }: any) => {
          // let data: any = proxy.readQuery({ query:profilesQuery });
          // const foundIndex = data.users.findIndex(x => x.userId == memberObj.userId);
          // data.users[foundIndex].profiles.push(QJTHNWOMEA[0]);
          // proxy.writeQuery({ query: profilesQuery, data });
          // data = proxy.readQuery({ query:profilesQuery,variables:{userIds: [memberObj.userId]} });
          // console.log(data)
          // data.users[0].profiles.push(QJTHNWOMEA[0]);
          // proxy.writeQuery({ query: profilesQuery, data });
        }
      })
      .subscribe(
        ({ data }) => {
          this.loading = false
          //console.log(data, 'data')

          this.store.dispatch(new ProfileUpdate(this.url))
          this.translate
          .get('MESSAGES.PROFILE_CREATED_SUCCESSFULLY')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
          this.router.navigate(['/members'])

        },
        error => {
          alert(error)
          this.loading = false
          console.log('there was an error sending the mutation', error)
        }
      )
  }

  openModal (type, index: number) {
    const modalRef = this.modalService.open(CreateNewLabelComponent)
    modalRef.componentInstance.newLabel = ''
    modalRef.result.then(
      result => {
        if (result) {
          if (type === 'address') {
            if (result != 'cross-click') {
              addressTypes.push({
                id: result,
                value: result
              })
            } else {
              result = addressTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'links') {
            if (result != 'cross-click') {
              linkTypes.push({
                id: result,
                value: result
              })
            } else {
              result = linkTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }

          if (type === 'custom') {
            if (result != 'cross-click') {
              customTypes.push({
                id: result,
                value: result
              })
            } else {
              result = customTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'phones') {
            if (result != 'cross-click') {
              phoneTypes.push({
                id: result,
                value: result
              })
            } else {
              result = phoneTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'emails') {
            if (result != 'cross-click') {
              emailTypes.push({
                id: result,
                value: result
              })
            } else {
              result = emailTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
        }
      },
      error => {
        // alert(234)
      }
    )
  }

  get f () {
    return this.profileForm.controls
  }
  get emails (): FormArray {
    return this.profileForm.get('emails') as FormArray
  }

  getData (type): FormArray {
    return this.profileForm.get(type) as FormArray
  }
  addEmails () {
    let fg = this.helper.createEmailFormGroup()
    this.getData('emails').push(fg)
  }
  addPhones () {
    const total = this.f.phones.value.length
    if (this.f.phones.value[total - 1].value != '') {
      let fg = this.helper.createPhoneFormGroup()
      this.getData('phones').push(fg)
    }
  }

  addAddress () {
    let fg = this.helper.createAddressFormGroup()
    this.getData('address').push(fg)
  }
  addLinks () {
    let fg = this.helper.createLinkFormGroup()
    this.getData('links').push(fg)
  }
  addCustom () {
    let fg = this.helper.createCustomFromGroup()
    this.getData('custom').push(fg)
  }
  addCustomeLabel (eve, type, index) {
    eve.srcElement.blur()

    // return
    if (eve.target.value == 'new') {
      eve.preventDefault()
      this.openModal(type, index)
    }
  }
  onSelectFile (event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0]) // read file as data url
      this.profilePic = event.target.files[0]
      // this.fileUploadToServer(event.target.files[0])
      //this.profileImage = event.target.files[0]
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result

        // console.log(this.url)
      }
    }
  }
  checkNumber (event) {
    // console.log(event,'event')
    // if(event && event.number && event.number.charAt(0)== "0"){
    //   console.log(event.number)
    //   return false
    // }
    // if(i==0 && this.role!="ADMIN")
    // {
    //   return false
    // }
    //this.elementRef.nativeElement.querySelector('.selected-flag').addEventListener('click', this.onClick.bind(this))
  }
  fileUploadToServer (url) {
    this.store.dispatch(new ProfileUpdate(this.url))

    let headers = new HttpHeaders({
      'Content-Type': 'image/jpeg'
    })
    let options = {
      headers: headers
    }
    this.http.put(url, this.profilePic, options).subscribe(
      res => {

        // handle success
        //reset file input
        this.translate
        .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
        .subscribe((res: string) => {
          this.toastr.info(res)
        })
        window.location.href = '/members';
        // this.toastr.info('Profile updated successfully.')
        // this.router.navigate(['/members'])
      },
      err => {}
    )
  }

  getValidUrl = (url = '') => {
    let myWindow = window as any
    let newUrl = myWindow.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, '')

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`
    }

    return newUrl
  }
  enableDisablePhone() {
    this.profileForm.get('phones').enable()
  }
 
  createNewCompany(name){
    this.apollo.mutate({
      mutation: createCompanyMutation,
      variables:  {
        name:name,
        orgId:this.accountInfo.organizationId
      }
    }).subscribe(({ data,loading,error }:any) => {
  
       },
       err => {
          console.log(err)
       }
    );
  }
  
  addTagPromise(name) {
    return new Promise((resolve) => {
        this.loading = true;
        // Simulate backend call.
        setTimeout(() => {

            // resolve({ id: this.companiesArray+1, name: name, valid: true });
            resolve(name);
            this.loading = false;
        }, 1000);

    })
}
  onSearchChange(eve){
    if((eve.key=="0" || eve.which ==48) && eve.target.value.length == 0){
      return false
    }
   return true
  }
  onBlurEvent(eve){
    let enterEmail = eve.target.value
     let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
     if (enterEmail.match(validRegex)) {
      let domain = this.helperService.getTheDomain(eve.target.value)
      if(!this.domainsArray.includes(domain) && !this.commonDomains.includes(domain)){
        this.domainsArray.push(domain)
      }
      
      if(!this.f.company.value && this.domainsArray[0]){
        this.profileForm.patchValue({
          company: this.domainsArray[0]
        })
       }
     }
  }
  addFacebook () {
    let fg = this.helper.createFacebookFormGroup()
    this.getData('facebook').push(fg)
  }
  addTwitter () {
    let fg = this.helper.createTwitterFormGroup()
    this.getData('twitter').push(fg)
  }
  addSnapchat () {
    let fg = this.helper.createSnapchatFormGroup()
    this.getData('snapchat').push(fg)
  }
  addInstagram () {
    let fg = this.helper.createInstagramFormGroup()
    this.getData('instagram').push(fg)
  }
  addTiktok () {
    let fg = this.helper.createTikTokFormGroup()
    this.getData('tiktok').push(fg)
  }
  addLinkedIn () {
    let fg = this.helper.createLinkedInFormGroup()
    this.getData('linkedIn').push(fg)
  }

  getTheCompanyValue1($event,all=false){
    let inputObj:any = {
      name:$event
    }
    if(all){
      inputObj = {}
    }
    this.companyQuery = this.apollo.watchQuery({
      query: getCompanyQuery,
      fetchPolicy:"network-only",
      variables: inputObj,
    })
     this.companyQuery.valueChanges.subscribe(({ data }) => {
      if(all){
        this.allCompanies = data.getCompany
      }
      else{
        this.companiesArray = data.getCompany
      }
      let uniq = {}
      this.companiesArray=this.companiesArray.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
      if(all){
        this.allCompanies=this.allCompanies.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
        this.allCompanies = this.allCompanies.map(element => {
          element.name =element.name.toLowerCase()
          element.name = element.name.replace(/['"]+/g, '')
          element.name = element.name.replace(/["']+/g, '')
          return element
        });
        
      } 
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
     }

    })
  }
  getTheCompanyValue(term){
      if(!term){
        return false
      }
      this.http
      .get<any>(`https://api.opencorporates.com/v0.4/companies/search?q=${term}&format=json`)
      .subscribe((data: any) => {
        this.companiesArray = data.results.companies.map(element => {
           element.name =element.company.name.toLowerCase()
           element.name = element.name.replace(/['"]+/g, '')
           element.name = element.name.replace(/["']+/g, '')
           return element
        });
        console.log(this.companiesArray)
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
       }
      },err=>{
        this.getTheCompanyValue1(term)
      })
  }
   onKeySearch(event: any) {
    clearTimeout(this.timeout);
    let $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          console.log(event)
          $this.getTheCompanyValue(event);
        }
      }, 500);
  }
}
