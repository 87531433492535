<app-loading *ngIf="loading"></app-loading>

<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3 pb-5 my-3 ">
        <app-account></app-account>
        <!-- <h4 class="pb-5 innerTitle">Official Profile</h4> -->
        <!-- Propeller Bootstrap datetimepicker -->
        <div class="row justify-content-center px-5">
          <div class="col-md-12 p-lg-2">
            <div class="card px-lg-3 py-5 ">
              <div class=" d-flex justify-content-center">
                <form
                  class="membersBlk  align-items-start col-lg-11 px-0 px-lg-2"
                  [formGroup]="profileForm"
                  (ngSubmit)="saveMember()"
                >
                  <div class="text-center pb-4">
                    <label class="cameraBlock mr-0 position-relative cursor-pointer" for="profilePic">
                      <input
                        type="file"
                        class="d-none"
                        id="profilePic"
                        (change)="onSelectFile($event)"
                        #file
                        accept="image/*"
                      />
                      <img
                        src="{{ url }}"
                        (error)="img.src = 'assets/default.jpg'"
                        class="cameraIcon"
                        alt="Camera Icon"
                        #img
                      /> 
                      <img src="assets/edit-profile.png" class="smallIcon position-absolute" />
                      </label
                    ><br />
                    <h5 class="text-capitalize">
                      {{ this.f.firstName.value }} {{ this.f.lastName.value }}
                    </h5>
                    <input
                      type="text"
                      formControlName="firstName"
                      class="d-none "
                      [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                      [readonly]="role != 'ADMIN'"
                    />
                    <input
                      type="text"
                      formControlName="lastName"
                      class="d-none"
                      [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"

                      [readonly]="role != 'ADMIN'"
                    />
                  </div>
                  <div class="d-flex flex-wrap w-100">
                    <!-- <div class="py-2 col-md-6 pr-5 d-flex">
					  <label class="text-gray noWeight border-bottom1 ">{{'PROFILE.FIRST_NAME' | translate}}</label>
					  <input type="text"   formControlName="firstName"  class="border-0" readonly /> 
				   </div>
				   <div class="py-2 col-md-6 pr-5 d-flex">
					  <label class="text-gray noWeight border-bottom1 ">{{'PROFILE.LAST_NAME' | translate}}</label>
					  <input type="text" formControlName="lastName" class="border-0" readonly  />
					 
				   </div> -->
                    <div class="py-2 col-md-6  d-flex">
                      <label class="text-gray noWeight border-bottom1 ">{{
                        'CREATE_MEMBER.DEPARTMENT' | translate
                      }}</label>
                      <input
                        type="text"
                        class="theme-input"
                        [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                        formControlName="department"
                        [readonly]="role != 'ADMIN'"
                        [title] = "role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'department'"
                      />
                    </div>
                    <div class="py-2 col-md-6 d-flex">
                      <label class="text-gray noWeight border-bottom1 ">{{
                        'CREATE_MEMBER.TITLE' | translate
                      }}</label>
                      <input
                        type="text"
                        class="theme-input"
                        [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                        formControlName="jobTitle"
                        [readonly]="role != 'ADMIN'"
                        [title] = "role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'title'"
                      />
                    </div>
                    <div
                      class="py-2 col-md-12 d-flex align-items-start cust-theme"
                    >
                      <label class="text-gray noWeight border-bottom1 ">{{
                        'COMMON.COMPANY' | translate
                      }}</label>
                      <!-- <input
                        type="text"
                        class="theme-input "
                        formControlName="company"
                        [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                        [readonly]="role != 'ADMIN'"
                        [title] = "role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'company'"
                      /> -->
                        <ng-select [items]="companiesArray"
                      [addTag]="addTagPromise"
                      bindLabel="name" 
                      bindValue="name" 
                      class="theme-input"
                      [loading]="loading"
                      [clearable]="false"
                      (search)	="onKeySearch($event.term)"
                      [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                      [readonly]="role != 'ADMIN'"
                      [title] = "role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'company'"
                      formControlName='company'>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm"> 
                        <ng-container  *ngIf="item.company">
                          <div class="text-capitalize">
                            {{ item.company.name }}
                          </div>
                            <small class="text-gray">{{ item.company && item.company.registered_address && item.company.registered_address.street_address}}</small>
                        </ng-container>
                        
                          <div class="text-gray" *ngIf="!item.company">{{item.name}}</div>
                      </ng-template>
                      
                      </ng-select>
                    </div>
                    <div
                    class="py-2 col-md-12 d-flex align-items-start"
                  >
                    <label class="text-gray noWeight border-bottom1 ">University</label>
                    <input
                      type="text"
                      class="theme-input "
                      formControlName="university"
                      [ngClass]="{ 'readonlyCls': role != 'ADMIN' }"
                      [readonly]="role != 'ADMIN'"
                      [title] = "role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'university'"
                    />
                  </div>
                    <div class="pb-3 col-md-12 clearfix mt-4">
                      <h6 class="label-head text-uppercase">
                        {{ 'PROFILE.EMAIL' | translate }}
                      </h6>
                      <div formArrayName="emails" class="d-flex1 align-items-start  row px-0">
                        <div
                          class=" col-sm-6  py-1"
                          *ngFor="
                            let email of f.emails['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="px-0 w-100">
                            <div class="d-flex align-items-center">
                              <label class="m-0 tit-blk">Title :</label>
                              <select
                                class="theme-select1 blueClr w-100 border-0 px-0"
                                formControlName="title"
                                (change)="addCustomeLabel($event, 'emails', i)"
                                [attr.disabled]="(email.value.admin && role != 'ADMIN') ? '':null"
                                [ngClass]="{ 'readonlyCls': email.value.admin && role != 'ADMIN' }"
                                [title] = "email.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'email'"
                              >
                                <option
                                  [value]="email.id"
                                  *ngFor="let email of emailTypes"
                                  >{{ email.value }}</option
                                >
                              </select>
                              <a
                                class="ml-5 text-danger"
                                *ngIf="i > 0 && (!email.value.admin ||  role == 'ADMIN')"
                                (click)="removeBlock(i, 'emails')"
                                ><i
                                  _ngcontent-pka-c5=""
                                  class="fa fa-times text-danger"
                                ></i
                              ></a>
                            </div>
                            <input
                              formControlName="value"
                              (blur)="onBlurEvent($event)"
                              class="theme-input"
                              maxLength="40"
                              [readonly]="email.value.admin  && role != 'ADMIN'"
                              [ngClass]="{ 'readonlyCls': email.value.admin && role != 'ADMIN' }"
                              [title] = "email.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'email'"

                            />

                            <label
                              *ngIf="
                                emails.controls[i].get('value').errors?.email
                              "
                              class="error text-danger"
                            >
                            {{
                              'MESSAGES.PLEASE_ENTER_VALID_EMAIL' | translate
                            }}
                            </label>
                          </div>
                        </div>
                        <div class="col-md-6 text-right ml-auto">
                          <a
                            class="theme-btn-2 float-right"
                            (click)="addEmails()"
                            >{{
                              'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate
                            }}</a
                            >
                        </div>
                      </div>
                      
                    </div>
                    <div class="pb-3 col-md-12  clearfix">
                      <h6 class="label-head text-uppercase">
                        {{ 'CREATE_MEMBER.PHONE' | translate }}
                      </h6>
                      <div formArrayName="phones" class="d-flex1 align-items-end row px">
                        <div
                          class=" col-sm-6  py-1"
                          *ngFor="
                            let phone of f.phones['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="px-0 w-100">
                            <div class="d-flex align-items-center">
                              <label class="m-0 tit-blk">Title :</label>
                              <select
                                class="theme-select1 blueClr w-100 border-0 px-0"
                                formControlName="title"
                                (change)="addCustomeLabel($event, 'phones', i)"
                                [attr.disabled]="(phone.value.admin && role != 'ADMIN') ? '':null"
                                [ngClass]="{ 'readonlyCls': phone.value.admin && role != 'ADMIN' }"
                                [title] = "phone.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'phone'"
                               
                              >
                                <option
                                  [value]="phone.id"
                                  *ngFor="let phone of phoneTypes"
                                  >{{ phone.value }}</option
                                >
                              </select>
                              <a
                                class="ml-5 text-danger"
                                *ngIf="i > 0"
                                (click)="removeBlock(i, 'phones')"
                                ><i
                                  _ngcontent-pka-c5=""
                                  class="fa fa-times text-danger"
                                ></i
                              ></a>
                            </div>
                            <ngx-intl-tel-input
                              [cssClass]="'custom theme-input w-100'"
                              [preferredCountries]="['us', 'gb']"
                              [enablePlaceholder]="false"
                              name="phone"
                              [searchCountryFlag]="true"
                              [separateDialCode]="'false'"
                              formControlName="value"
                              [enableAutoCountrySelect]="enableAutoCountrySelect"
                              [phoneValidation]="true"
                              [maxLength]="15"
                              (keypress)="onSearchChange($event)"
                              [readonly]="phone.value.admin  && role != 'ADMIN'"
                              [ngClass]="{ 'readonlyCls': phone.value.admin && role != 'ADMIN' }"
                              [title] = "phone.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'phone'"
                            ></ngx-intl-tel-input>
                          </div>
                        </div>
                        <div class="col-md-6 text-right ml-auto">
                          <a
                           class="theme-btn-2 float-right"
                           (click)="addPhones()"
                           >{{
                             'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate
                           }}</a
                           >
                       </div>
                      </div>
                     
                    </div>
                    <div class="pb-3 col-md-12 clearfix">
                      <h6 class="label-head text-uppercase">
                        {{ 'CREATE_MEMBER.SOCIAL_PROFILE' | translate }}
                      </h6>
                      <div class="d-flex w-100 px-0">
                        
                        <div class="tab-content w-100 pt-2 px-0">
                          <!-- <input
                            type="text"
                            class="theme-input tab-pane fade in active show"
                            id="facebook"
                            formControlName="facebook"
                            placeholder="Enter Facebook profileID"
                            
                          />
                          <input
                            type="text"
                            class="theme-input tab-pane fade"
                            id="twitter"
                            formControlName="twitter"
                            placeholder="Enter Twitter username"
                          />
                          <input
                            type="text"
                            class="theme-input tab-pane fade"
                            id="ticktok"
                            formControlName="ticktok"
                            placeholder="Enter or paste contact profile "
                          />
                          <input
                            type="text"
                            class="theme-input tab-pane fade"
                            id="linkedIn"
                            formControlName="linkedIn"
                            placeholder="Enter or paste LinkedIn profile link"
                          />
                          <input
                            type="text"
                            class="theme-input tab-pane fade"
                            id="instagram"
                            formControlName="instagram"
                            placeholder="Enter InstagramID"
                          />
                          <input
                          type="text"
                          class="theme-input tab-pane fade"
                          id="snapchat"
                          formControlName="snapchat"
                          placeholder="Enter or paste Snapchat profile link"
                        /> -->
                        <div formArrayName="facebook" class="d-flex1 row px-0 tab-pane fade in show active"  id="facebook">
                          <div
                          class=" col-sm-12  py-1 "
                          *ngFor="let link of f.facebook['controls']; let i = index"
                          [formGroupName]="i"
                          >
                          <div class="d-flex align-items-center">
                            
                           <input
                              type="text"
                              class="theme-input "
                              formControlName="value"
                              placeholder="Enter Facebook profileID"
                              [readonly]="f.facebook.value[i].admin  && role != 'ADMIN'"
                              [ngClass]="{ 'readonlyCls': f.facebook.value[i].admin   && role != 'ADMIN' }"
                              [title] = "f.facebook.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'Facebook'"
                              />
                           <a (click)="addFacebook()"   class="ml-3" *ngIf="i==0">
                           <img src="assets/plus.svg" />
                           </a>
                           <a
                           *ngIf="i > 0"
                           (click)="removeBlock(i, 'facebook')"
                           class="ml-3"
                           ><i class="fa fa-times text-danger"></i
                              ></a>
                          </div>
                          <div class="text-right pr-5 pb-1">
                           <a
                              href="https://facebook.com/{{f.facebook.value[i].value }}"
                              target="_blank"
                              *ngIf="socialType==1 && f.facebook.value[i]"
                              >https://facebook.com/{{ f.facebook.value[i].value }} </a
                              >
                          </div>
                         </div>
                         </div>
                         <div formArrayName="twitter" class="d-flex1 row px-0 tab-pane fade"  id="twitter">
                           <div
                           class=" col-sm-12  py-1 "
                           *ngFor="let link of f.twitter['controls']; let i = index"
                           [formGroupName]="i"
                           >
                           <div class="d-flex align-items-center">
                            <input
                             type="text"
                             class="theme-input "
                             formControlName="value"
                             placeholder="Enter Twitter username"
                             [readonly]="f.twitter.value[i].admin  && role != 'ADMIN'"
                             [ngClass]="{ 'readonlyCls': f.twitter.value[i].admin   && role != 'ADMIN' }"
                             [title] = "f.twitter.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'Twitter'"
                             />
                            <a (click)="addTwitter()"   class="ml-3" *ngIf="i==0">
                            <img src="assets/plus.svg" />
                            </a>
                            <a
                            *ngIf="i > 0"
                            (click)="removeBlock(i, 'twitter')"
                            class="ml-3"
                            ><i class="fa fa-times text-danger"></i
                             ></a>
                           </div>
                           <div class="text-right pr-5 pb-1">
                            <a
                             href="https://twitter.com/{{ f.twitter.value[i].value }}"
                             target="_blank"
                             *ngIf="socialType==3 && f.twitter.value[i]"
                             >https://twitter.com/{{ f.twitter.value[i].value }}</a
                             >
                           </div>
                         </div>
                         </div>
                         <div formArrayName="tiktok" class="d-flex1 row px-0 tab-pane fade"  id="tiktok">
                         <div
                         class=" col-sm-12  py-1 "
                         *ngFor="let link of f.tiktok['controls']; let i = index"
                         [formGroupName]="i"
                         >
                         <div class="d-flex align-items-center">
                          <input
                           type="text"
                           class="theme-input  "
                           formControlName="value"
                           placeholder="Enter or paste contact profile "
                           [readonly]="f.tiktok.value[i].admin  && role != 'ADMIN'"
                           [ngClass]="{ 'readonlyCls': f.tiktok.value[i].admin   && role != 'ADMIN' }"
                           [title] = "f.tiktok.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'Tiktok'"
                           />
                          <a (click)="addTiktok()"   class="ml-3" *ngIf="i==0">
                          <img src="assets/plus.svg" />
                          </a>
                          <a
                          *ngIf="i > 0"
                          (click)="removeBlock(i, 'tiktok')"
                          class="ml-3"
                          ><i class="fa fa-times text-danger"></i
                           ></a>
                         </div>
                         <div class="text-right pr-5 pb-1">
                          <a
                           href="{{ getValidUrl(f.tiktok.value[i].value) }}"
                           target="_blank"
                           *ngIf="socialType==5 && f.tiktok.value[i]"
                           >{{ getValidUrl(f.tiktok.value[i].value) }} </a
                           >
                         </div>
                         </div>
                         </div>
                         <div formArrayName="linkedIn" class="d-flex1 row px-0 tab-pane fade"  id="linkedIn">
                         <div
                         class=" col-sm-12  py-1 "
                         *ngFor="let link of f.linkedIn['controls']; let i = index"
                         [formGroupName]="i"
                         >
                         <div class="d-flex align-items-center">
                         <input
                          type="text"
                          class="theme-input "
                          formControlName="value"
                          placeholder="Enter or paste LinkedIn profile link"
                          [readonly]="f.linkedIn.value[i].admin  && role != 'ADMIN'"
                          [ngClass]="{ 'readonlyCls': f.linkedIn.value[i].admin   && role != 'ADMIN' }"
                          [title] = "f.linkedIn.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'LinkedIn'"
                          />
                         <a (click)="addLinkedIn()"   class="ml-3" *ngIf="i==0">
                         <img src="assets/plus.svg" />
                         </a>
                         <a
                         *ngIf="i > 0"
                         (click)="removeBlock(i, 'linkedIn')"
                         class="ml-3"
                         ><i class="fa fa-times text-danger"></i
                          ></a>
                         </div>
                         <div class="text-right pr-5 pb-1">
                         <a
                          href="{{ getValidUrl(f.linkedIn.value[i].value) }}"
                          target="_blank"
                          *ngIf="socialType==4 && f.linkedIn.value[i]"
                          >{{ getValidUrl(f.linkedIn.value[i].value) }} </a
                          >
                         </div>
                         </div>
                         </div>
                         <div formArrayName="instagram" class="d-flex1 row px-0 tab-pane fade"  id="instagram">
                         <div
                         class=" col-sm-12  py-1 "
                         *ngFor="let link of f.instagram['controls']; let i = index"
                         [formGroupName]="i"
                         >
                         <div class="d-flex align-items-center">
                         <input
                          type="text"
                          class="theme-input "
                          formControlName="value"
                          placeholder="Enter InstagramID"
                          [readonly]="f.instagram.value[i].admin  && role != 'ADMIN'"
                          [ngClass]="{ 'readonlyCls': f.instagram.value[i].admin   && role != 'ADMIN' }"
                          [title] = "f.instagram.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'Instagram'"
                          />
                         <a (click)="addInstagram()"   class="ml-3" *ngIf="i==0">
                         <img src="assets/plus.svg" />
                         </a>
                         <a
                         *ngIf="i > 0"
                         (click)="removeBlock(i, 'instagram')"
                         class="ml-3"
                         ><i class="fa fa-times text-danger"></i
                          ></a>
                         </div>
                         <div class="text-right pr-5 pb-1">
                         <a
                          href="https://instagram.com/{{ f.instagram.value[i].value }}"
                          target="_blank"
                          *ngIf="socialType==2 && f.instagram.value[i]"
                          >https://instagram.com/{{ f.instagram.value[i].value }} </a
                          >
                         </div>
                         </div>
                         </div>
                         <div formArrayName="snapchat" class="d-flex1 row px-0 tab-pane fade"  id="snapchat">
                         <div
                         class=" col-sm-12  py-1 "
                         *ngFor="let link of f.snapchat['controls']; let i = index"
                         [formGroupName]="i"
                         >
                         <div class="d-flex align-items-center">
                         <input
                          type="text"
                          class="theme-input  "
                          formControlName="value"
                          placeholder="Enter or paste Snapchat profile link"
                          [readonly]="f.snapchat.value[i].admin  && role != 'ADMIN'"
                          [ngClass]="{ 'readonlyCls': f.snapchat.value[i].admin   && role != 'ADMIN' }"
                          [title] = "f.snapchat.value[i].admin  && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'Snapchat'"
                          />
                         <a (click)="addSnapchat()"   class="ml-3" *ngIf="i==0">
                         <img src="assets/plus.svg" />
                         </a>
                         <a
                         *ngIf="i > 0"
                         (click)="removeBlock(i, 'snapchat')"
                         class="ml-3"
                         ><i class="fa fa-times text-danger"></i
                          ></a>
                         </div>
                         <div class="text-right pr-5 pb-1">
                         <a
                          href="{{ getValidUrl(f.snapchat.value[i].value) }}"
                          target="_blank"
                          *ngIf="socialType==6 && f.snapchat.value[i]"
                          >{{ getValidUrl(f.snapchat.value[i].value) }} </a
                          >&ensp;
                         </div>
                         </div>
                         </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center px-0 ">
                        <ul class="nav nav-tabs1 tabBlk pt-1 px-0 ">
                          <li class="active">
                            <a data-toggle="tab" href="#facebook" (click)="socialType=1" 
                            [class.socialSelect]="socialType == 1"
                              ><img src="assets/fb.png"
                            /></a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#instagram" (click)="socialType=2"
                            [class.socialSelect]="socialType == 2"
                              ><img src="assets/ins.png"
                            /></a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#twitter" (click)="socialType=3"
                            [class.socialSelect]="socialType == 3"
                              ><img src="assets/tw.png"
                            /></a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#linkedIn" (click)="socialType=4"
                            [class.socialSelect]="socialType == 4"
                              ><img src="assets/linkedin.png"
                            /></a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#tiktok" (click)="socialType=5"
                            [class.socialSelect]="socialType == 5"
                              ><img src="assets/tictok.png"
                            /></a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#snapchat" (click)="socialType=6"
                            [class.socialSelect]="socialType == 6"
                              ><img src="assets/snapchat.png"
                            /></a>
                          </li>
                        </ul>
                        <div class="flex-1 text-right" *ngIf="false">
                          <a
                          href="https://facebook.com/{{ f.facebook.value }}"
                          target="_blank"
                         *ngIf="socialType==1 && f.facebook.value"
                          >https://facebook.com/{{ f.facebook.value }} </a
                        >&ensp;
                        <a
                          href="https://twitter.com/{{ f.twitter.value }}"
                          target="_blank"
                          *ngIf="socialType==3 && f.twitter.value"
                          >https://twitter.com/{{ f.twitter.value }}</a
                        >&ensp;
                        <a
                          href="{{ getValidUrl(f.linkedIn.value) }}"
                          target="_blank"
                          *ngIf="socialType==4 && f.linkedIn.value"
                          >{{ getValidUrl(f.linkedIn.value) }} </a
                        >&ensp;
                        <a
                          href="https://instagram.com/{{ f.instagram.value }}"
                          target="_blank"
                          *ngIf="socialType==2 && f.instagram.value"
                          >https://instagram.com/{{ f.instagram.value }} </a
                        >&ensp;
                        <a
                          href="{{ getValidUrl(f.tiktok.value) }}"
                          target="_blank"
                          *ngIf="socialType==5 && f.tiktok.value"
                          >{{ getValidUrl(f.tiktok.value) }} </a
                        >&ensp;
                        <a
                        href="{{ getValidUrl(f.snapchat.value) }}"
                        target="_blank"
                        *ngIf="socialType==6 && f.snapchat.value"
                        >{{ getValidUrl(f.snapchat.value) }} </a
                      >&ensp;
                        </div>
                      
                     </div>
                    </div>
                    <div class="pb-3 col-md-12 clearfix">
                      <h6 class="label-head text-uppercase">
                        {{ 'CREATE_MEMBER.ADDRESS' | translate }}
                      </h6>
                      <div formArrayName="address" class="d-flex1 row px-0">
                        <div
                          class=" col-sm-12 "
                          *ngFor="
                            let address of f.address['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="d-flex col-md-6 align-items-center px-0">
                              <label class="m-0 tit-blk">Title :</label>
                            <select
                              formControlName="title"
                              class="theme-select1 blueClr w-100 border-0"
                              (change)="addCustomeLabel($event, 'address', i)"
                              [attr.disabled]="(address.value.admin && role != 'ADMIN') ? '':null"
                            [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                            [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                            >
                              <option
                                [value]="address.id"
                                *ngFor="let address of addressTypes"
                                >{{ address.value }}</option
                              >
                            </select>
                            <a
                              *ngIf="i > 0"
                              (click)="removeBlock(i, 'address')"
                              class="ml-5"
                              ><i class="fa fa-times text-danger"></i
                            ></a>
                          </div>
                          <div class="d-flex row">
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.STREET' | translate }}</label
                              >
                              <input
                                type="text"
                                class="theme-input"
                                maxlength="50"
                                formControlName="street"
                                [readonly]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{
                                  'CREATE_MEMBER.POSTCODE' | translate
                                }}</label
                              >
                              <input
                                type="text"
                                class="theme-input "
                                maxlength="50"
                                formControlName="postCode"
                                [readonly]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.STREET2' | translate }}</label
                              >
                              <input
                                type="text"
                                class="theme-input "
                                maxlength="10"
                                formControlName="street1"
                                [readonly]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.CITY' | translate }}</label
                              >
                              <input
                                type="text"
                                class="theme-input "
                                maxlength="50"
                                formControlName="city"
                                [readonly]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.STATE' | translate }}</label
                              >
                              <input
                                type="text"
                                class="theme-input "
                                maxlength="50"
                                formControlName="state"
                                [readonly]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{
                                  'CREATE_MEMBER.COUNTRY' | translate
                                }}</label
                              >
                              <ngx-select
                                name="country"
                                [multiple]="false"
                                [items]="countryList"
                                optionValueField="id"
                                optionTextField="text"
                                formControlName="country"
                                class="theme-select  countryDropdown"
                                [disabled]="address.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': address.value.admin && role != 'ADMIN' }"
                                [title] = "address.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'address'"
                              >
                                <ng-template
                                  ngx-select-option
                                  ngx-select-option-selected
                                  let-option
                                  let-text="text"
                                >
                                  <div class="d-flex align-items-center">
                                    {{ option.text }}
                                  </div>
                                </ng-template>
                                <ng-template
                                  ngx-select-option-not-found
                                  let-input
                                >
                                  {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
                                </ng-template>
                              </ngx-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ml-auto px-0">
                        <a class="theme-btn-2 float-right" (click)="addAddress()">
                          {{ 'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate }}
                        </a>
                      </div>
                     
                    </div>
                    <div class="pb-2 col-md-12 clearfix">
                      <h6 class="label-head text-uppercase">{{ 'CREATE_MEMBER.EDUCATION' | translate }}</h6>
                      <div formArrayName="education" class="d-flex1 row px-0">
                        <div
                          class=" col-sm-12 "
                          *ngFor="
                            let education of f.education['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="d-flex row w-100">
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{
                                  'CREATE_MEMBER.UNIVERSITY' | translate
                                }}</label
                              >
                              <input
                                type="text"
                                class="theme-input"
                                maxlength="50"
                                formControlName="university"
                                [readonly]="education.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': education.value.admin && role != 'ADMIN' }"
                                [title] = "education.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'education'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.DEGREE' | translate }}
                              </label>
                              <input
                                type="text"
                                class="theme-input"
                                formControlName="degree"
                                [readonly]="education.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': education.value.admin && role != 'ADMIN' }"
                                [title] = "education.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'education'"

                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label class="text-gray noWeight border-bottom1 "
                                >{{
                                  'CREATE_MEMBER.FIELD_OF_STUDY' | translate
                                }}
                              </label>
                              <input
                                type="text"
                                class="theme-input"
                                formControlName="study"
                                [readonly]="education.value.admin  && role != 'ADMIN'"
                                [ngClass]="{ 'readonlyCls': education.value.admin && role != 'ADMIN' }"
                                [title] = "education.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'education'"
                              />
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label
                                class="text-gray noWeight border-bottom1 "
                                >{{
                                  'CREATE_MEMBER.START_YEAR' | translate
                                }}</label
                              >

                              <select
                                class="theme-select"
                                formControlName="startYear"
                                [attr.disabled]="(education.value.admin && role != 'ADMIN') ? '':null"
                                [ngClass]="{ 'readonlyCls': education.value.admin && role != 'ADMIN' }"
                                [title] = "education.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'education'"
                              >
                              <option value=""></option>
                                <option
                                  value="{{ year.id }}"
                                  *ngFor="let year of years"
                                  >{{ year.text }}</option
                                >
                              </select>
                              <!--{{startYear}} -->
                              <!-- <ngx-select
								name="year"
								[multiple]="false"
								[items]="years"
								optionValueField="text"
								optionTextField="text"
								formControlName="startYear"
								class="theme-select  countryDropdown1  "
								>
								<ng-template
								  ngx-select-option
								  ngx-select-option-selected
								  let-option
								  let-text="text">
									<div class="d-flex align-items-center"> 
										{{option.text }}							 
									</div>
								 
								</ng-template>
								<ng-template ngx-select-option-not-found let-input>
								  "{{ input }}" not found
								</ng-template>
							  </ngx-select>
							  {{f.startYear |json}} -->
                            </div>
                            <div class="col-sm-6 py-2 d-flex">
                              <label class="text-gray noWeight border-bottom1 "
                                >{{ 'CREATE_MEMBER.END_YEAR' | translate }}
                              </label>
                              <!-- <ngx-select
								name="endYear"
								[multiple]="false"
								[items]="years"
								optionValueField="text"
								optionTextField="text"
								formControlName="endYear"
								class="theme-select  countryDropdown  "
						 
								>
								<ng-template
								  ngx-select-option
								  ngx-select-option-selected
								  let-option
								  let-text="text">
									<div class="d-flex align-items-center"> 
										{{option.text}}	
									</div>
								 
								</ng-template>
								<ng-template ngx-select-option-not-found let-input>
								  "{{ input }}" not found
								</ng-template>
							  </ngx-select> -->
                              <div class="w-100">
                                <select
                                  class="theme-select"
                                  formControlName="endYear"
                                  [attr.disabled]="(education.value.admin && role != 'ADMIN') ? '':null"
                                  [ngClass]="{ 'readonlyCls': education.value.admin && role != 'ADMIN' }"
                                  [title] = "education.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'education'"
                                >
                                <option value=""></option>
                                  <option
                                    value="{{ year.id }}"
                                    *ngFor="let year of years"
                                    >{{ year.text }}</option
                                  >
                                </select>
                                <label
                                  class="text-danger"
                                  *ngIf="
                                    f.education.value[0].startYear >
                                    f.education.value[0].endYear
                                  "
                                >
                                  {{ 'MESSAGES.END_YEAR_MUST_BE_GREATER_THAN_START_YEAR' | translate }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pb-3 col-md-12 clearfix">
                      <h6 class="label-head text-uppercase">
                        {{ 'CREATE_MEMBER.LINKS' | translate }}
                      </h6>
                      <div formArrayName="links" class="d-flex1 row px-0 align-items-end">
                        <div
                          class=" col-sm-6  py-1"
                          *ngFor="
                            let link of f.links['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="px-0 w-100">
                            <div class="d-flex align-items-center">
                              <label class="m-0 tit-blk">Title :</label>
                              <select
                                formControlName="title"
                                class="px-0 theme-select1 blueClr w-100 border-0"
                                (change)="addCustomeLabel($event, 'links', i)"
                                [attr.disabled]="(link.value.admin && role != 'ADMIN') ? '':null"
                                [ngClass]="{ 'readonlyCls': link.value.admin && role != 'ADMIN' }"
                                [title] = "link.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'link'"
                              >
                                <option
                                  [value]="link.id"
                                  *ngFor="let link of linkTypes"
                                  >{{ link.value }}</option
                                >
                              </select>
                              <a
                                *ngIf="i > 0 && (!link.value.admin ||  role == 'ADMIN')"
                                (click)="removeBlock(i, 'links')"
                                class="text-danger ml-5"
                                ><i class="fa fa-times"></i
                              ></a>
                            </div>
                            <input
                              type="text"
                              class="theme-input"
                              placeholder="Link"
                              formControlName="value"
                              [readonly]="link.value.admin  && role != 'ADMIN'"
                              [ngClass]="{ 'readonlyCls': link.value.admin && role != 'ADMIN' }"
                              [title] = "link.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'link'"
                              
                            />
                          </div>
                        </div>
                        <div class="col-md-6 text-right ml-auto">
                          <a class="theme-btn-2 float-right" (click)="addLinks()">
                          {{ 'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate }}
                          </a>
                        </div>
                      </div>
                      <!-- <a class="theme-btn-2 float-right" (click)="addLinks()">
                        {{ 'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate }}
                      </a> -->
                    </div>
                    <div class="pb-3 col-md-12  clearfix">
                      <h6 class="label-head text-uppercase">
                        {{ 'CREATE_MEMBER.OTHER_INFO' | translate }}
                      </h6>
                      <div formArrayName="custom" class="d-flex1 row px-0 align-items-end">
                        <div
                          class=" col-sm-6  py-1"
                          *ngFor="
                            let custom of f.custom['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <div class="px-0 w-100">
                              <div class="d-flex align-items-center">
                                <label class="m-0 tit-blk">Title :</label>
                              <select
                                class="px-0 theme-select1 blueClr w-100 border-0"
                                formControlName="title"
                                (change)="addCustomeLabel($event, 'custom', i)"
                                [attr.disabled]="(custom.value.admin && role != 'ADMIN') ? '':null"
                                [ngClass]="{ 'readonlyCls': custom.value.admin && role != 'ADMIN' }"
                                [title] = "custom.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'custom'"
                              >
                                <option
                                  [value]="custom.id"
                                  *ngFor="let custom of customTypes"
                                  >{{ custom.value }}</option
                                >
                              </select>
                              <a
                                *ngIf="i > 0 && (!custom.value.admin ||  role == 'ADMIN')"
                                (click)="removeBlock(i, 'custom')"
                                class="text-danger ml-5"
                                ><i class="fa fa-times"></i
                              ></a>
                            </div>
                            <input
                              type="text"
                              class="theme-input"
                              placeholder="custom"
                              formControlName="value"
                              [readonly]="custom.value.admin  && role != 'ADMIN'"
                              [ngClass]="{ 'readonlyCls': custom.value.admin && role != 'ADMIN' }"
                              [title] = "custom.value.admin && role != 'ADMIN' ? 'This is your Corporate profile, please ask admin for any changes' : 'custom'"
                              
                            />
                          </div>
                        </div>
                        <div  class="col-md-6 text-right ml-auto">
                          <a class="theme-btn-2 float-right" (click)="addCustom()">
                          {{ 'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate }}
                          </a>
                        </div>
                      </div>
                      <!-- <a class="theme-btn-2 float-right" (click)="addCustom()">
                        {{ 'CREATE_MEMBER.ADD_MORE_INFORMATION' | translate }}
                      </a> -->
                    </div>
                  </div>

                  <div class="py-2 text-center col-md-12">
                    <!-- <input
                      type="submit"
                      value="{{ (updateProfileStatus ? 'PROFILE.UPDATE_PROFILE': 'TEAMSPAGE.CREATE_PROFILE') | translate }}"
                      class="theme-btn"
                    /> -->
                    <input
                    type="submit"
                    value="Save Changes"
                    class="theme-btn"
                  />
                  </div>
                  <div class="py-2 text-center col-md-12 text-gray">
                    <a [routerLink]="['/my-profile']">{{
                      'PROFILE.CANCEL' | translate
                    }}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <app-create-new-label *ngIf="isOpen"></app-create-new-label>
      </div>
    </div>
  </div>
</div>
