import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
// import { UserComponent } from './user/user.component';
// import {UserComponent } from './user/user.component'
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component'
import { LoginComponent } from './shared/components/login/login.component';
import { OwnerRegistrationComponent } from './shared/components/owner-registration/owner-registration.component';
// import { CompanyVerificationComponent } from './src/app/shared/components/company-verification/company-verification.component';
import { OwnerVerificationComponent } from './shared/components/owner-verification/owner-verification.component';

import { OwnerLoginComponent } from './shared/components/owner-login/owner-login.component';
import { VerifyAndMergeComponent } from './shared/components/verify-and-merge/verify-and-merge.component'; // import our Google Analytics service
import { ForgotPasswordComponent } from '../app/user/components/forgot-password/forgot-password.component';
import { VerifyOtpComponent  } from '../app/user/components/verify-otp/verify-otp.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(mod => mod.UserModule)
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'login/:id',
    component:LoginComponent
  },
  {
    path:'registration',
    component:OwnerRegistrationComponent
  },
  {
    path:'company-verification',
    component:OwnerVerificationComponent
  },
  {
    path:'owner-login',
    component:OwnerLoginComponent
  },
  {
    path:'verifyAndMerge',
    component:VerifyAndMergeComponent
  },
  // {
  //   path:'mfa',
  //   component:VerifyMfaComponent
  // },
  { path: 'forgot-password',component: ForgotPasswordComponent,data: {title: 'HEADER.FORGOT_PASSWORD'}},
  { path: 'otp-verify',component: VerifyOtpComponent,data: {title: 'MESSAGES.OTP_VERIFY'}},

  { path: '**', component:PageNotFoundComponent }
  

]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
