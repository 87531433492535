import { Component, OnInit,ChangeDetectorRef } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  profilesQuery,
  createProfileMemberMutution,
  updateProfileMemberMutution,
  createPublicProfileMemberMutution,
  publicProfilesQuery,setPublicProfileMutution,
  generateBranchLink
} from '../../../services/user.gql'
import { Store } from '@ngrx/store'
import { publicProfileQuery } from '../../../services/user.gql'

import { Observable } from 'rxjs'

import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CreateNewLabelComponent } from '../../account/official-profile/create-new-label/create-new-label.component'
import {
  addressTypes,
  linkTypes,
  customTypes,
  emailTypes,
  phoneTypes
} from '../create-member/staticLabels'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { memberHelper } from '../create-member/member-helper'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  SearchCountryField,
  CountryISO
} from 'ngx-intl-tel-input'
 import {accountInfo} from '../../../services/user.gql'
import * as data from '../../../../shared/country.json'
import { TranslateService } from '@ngx-translate/core'
import { profileState,AppState } from '../../../../user/store/app.states'
import {  ProfileUpdate } from '../../../../user/store/actions/profile.actions'
import * as $ from 'jquery'

import {
  getCompanyQuery,createCompanyMutation
} from '../../../services/contacts.gql'
@Component({
  selector: 'app-open-profile',
  templateUrl: './open-profile.component.html',
  styleUrls: ['./open-profile.component.scss']
})
export class OpenProfileComponent implements OnInit {
  private query: QueryRef<any>
  private queryAc: QueryRef<any>
  private companyQuery: QueryRef<any>
  private publicProfileQuery: QueryRef<any>
  private setPublicProfileQuery:QueryRef<any>
  profileForm: FormGroup
  public years: any[] = []
  public userId: number
  public addressTypes: any[] = addressTypes
  public linkTypes: any[] = linkTypes
  public phoneTypes: any[] = phoneTypes
  public emailTypes: any[] = emailTypes
  public customTypes: any[] = customTypes
  public userData: any = []
  role:any 
  url: any = 'assets/default.jpg'
  public profilePic: any
  public countryList: any = []
  public isOpen = false
  separateDialCode = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  public countryData: any = data
  getProfile: Observable<any>
  loading:boolean =false;
  socialType =1
  updateProfileStatus:boolean = false
  enableAutoCountrySelect:boolean = true
  profileId:any 
  companiesArray:any =[]
  timeout: any = null;
  allCompanies:any =[]
  account:any ={}
  constructor (
    private apollo: Apollo,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private helper: memberHelper,
    private http: HttpClient,
    private translate: TranslateService,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef
    
  ) {
    this.role = localStorage.getItem('role')
    this.countryList = this.countryData ? this.countryData.default : []

    //this.countryList = data? data.default : []
    const year = new Date().getFullYear()
    for (var i = 1960; i <= year; i++) {
      this.years.push({
        id: i + 1,
        text: i + 1
      })
    }
    

  }
  ngOnInit () {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.id) {
        this.profileId = params.id
      }
      
    })
    this.profileForm = this.formBuilder.group({
      jobTitle: [''],
      company: [''],
      university: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      department: [''],
      facebook: this.formBuilder.array([this.helper.createFacebookFormGroup()]),
      twitter: this.formBuilder.array([this.helper.createTwitterFormGroup()]),
      ticktok: this.formBuilder.array([this.helper.createTikTokFormGroup()]),
      linkedIn: this.formBuilder.array([this.helper.createLinkFormGroup()]),
      instagram: this.formBuilder.array([this.helper.createInstagramFormGroup()]),
      snapchat: this.formBuilder.array([this.helper.createSnapchatFormGroup()]),
      education: this.formBuilder.array([
        this.helper.createEducationFormGroup()
      ]),
      address: this.formBuilder.array([this.helper.createAddressFormGroup()]),
      custom: this.formBuilder.array([this.helper.createCustomFromGroup()]),
      emails: this.formBuilder.array(
        [this.helper.createEmailFormGroup()],
        [Validators.email]
      ),
      phones: this.formBuilder.array([this.helper.createPhoneFormGroup()]),
      links: this.formBuilder.array([this.helper.createLinkFormGroup()]),
      description: [''],
      username: [''],
      employees: this.formBuilder.array(
        [this.createEmpFormGroup()],
        [Validators.required]
      )
    })
    this.getPublicprofile()
    
    this.getTheCompanyValue1('',true)
   
  }

  saveMember () {
    let memberObj: any = {}
    let dataArray: any = []
    if (
      this.f.education.value[0].startYear > this.f.education.value[0].endYear
    ) {
      return false
    }
    if (this.f.jobTitle.value != '') {
      const jobTitle = {
        title: 'Position',
        value: this.f.jobTitle.value,
        format: 'JOB_TITLE'
      }
      dataArray.push(jobTitle)
    }
    if (this.f.company.value != '') {
      const company = {
        title: 'Company',
        value: this.f.company.value,
        format: 'ORGANIZATION'
      }
      dataArray.push(company)
    }
    if (this.f.university.value != '') {
      const university = {
        title: 'University',
        value: this.f.university.value,
        format: 'ORGANIZATION'
      }
      dataArray.push(university)
    }
    // if (this.f.facebook.value != '') {
    //   dataArray.push({
    //     title: 'Facebook',
    //     value: this.f.facebook.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.twitter.value != '') {
    //   dataArray.push({
    //     title: 'Twitter',
    //     value: this.f.twitter.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.linkedIn.value != '') {
    //   dataArray.push({
    //     title: 'Linked In',
    //     value: this.f.linkedIn.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.ticktok.value != '') {
    //   dataArray.push({
    //     title: 'TikTok',
    //     value: this.f.ticktok.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.instagram.value != '') {
    //   dataArray.push({
    //     title: 'Instagram',
    //     value: this.f.instagram.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    // if (this.f.snapchat.value != '') {
    //   dataArray.push({
    //     title: 'Snapchat',
    //     value: this.f.snapchat.value,
    //     format: 'SOCIAL_MEDIA'
    //   })
    // }
    if (this.f.department.value != '') {
      dataArray.push({
        title: 'Department',
        value: this.f.department.value,
        format: 'DEPARTMENT'
      })
    }

    this.f.address.value.forEach(function (address, i) {
      if (
        address.street1 != '' ||
        address.street != '' ||
        address.city != '' ||
        address.state != ''
      ) {
        let addValue = `${address.street}$$$${address.street1}$$$${address.postCode}$$$${address.city}$$$${address.state}$$$${address.country}`
        dataArray.push({
          title: address.title,
          value: addValue,
          format: 'LOCATION'
        })
      }
    })
    this.f.education.value.forEach(function (edu, i) {
      if (
        edu.startYear != '' ||
        edu.endYear != '' ||
        edu.study != '' ||
        edu.university != '' ||
        edu.degree != ''
      ) {
        let eduValue = `${edu.university}$$$${edu.degree}$$$${edu.study}$$$${edu.startYear}$$$${edu.endYear}`
        dataArray.push({
          title: 'Education',
          value: eduValue,
          format: 'UNIVERSITY'
        })
      }
    })
    this.f.phones.value.forEach(function (phone1, i) {
      if (phone1.value) {
        if (
          phone1.value.number &&
          (phone1.value.number != '' || phone1.title != '')
        ) {
                  let key = phone1.value.countryCode

          let number = phone1.value.internationalNumber.split('-').join('')
          number = number.split(' ').join('')
          //console.log(phone1.value.internationalNumber)
          dataArray.push({
            title: phone1.title,
            key: key.toUpperCase(),
            value: number,
            format: phone1.format
          })
        }
      }
    })
    this.f.emails.value.forEach(function (email, i) {
      if (email.value != '') {
      email.value = email.value.toLocaleLowerCase()
        dataArray.push(email)
      }
    })
    this.f.links.value.forEach(function (link, i) {
      if (link.value != '') {
        dataArray.push(link)
      }
    })
    this.f.facebook.value.forEach(function (link, i) {
      if (link.value != '') {
        dataArray.push(link)
      }
    })
    this.f.twitter.value.forEach(function (link, i) {
      if (link.value != '') {
        dataArray.push(link)
      }
    })
    this.f.ticktok.value.forEach(function (link, i) {
      if (link.value != '') {
        dataArray.push(link)
      }
    })
    this.f.linkedIn.value.forEach(function (link, i) {
      if (link.value != '') {
        dataArray.push(link)
      }
    })

    this.f.instagram.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        dataArray.push(cutm)
      }
    })
    this.f.snapchat.value.forEach(function (cutm, i) {
      if (cutm.value != '') {
        dataArray.push(cutm)
      }
    })

    // dataArray= dataArray.concat(this.f.emails.value)
    //dataArray =dataArray.concat(this.f.phones.value)
    //dataArray= dataArray.concat(this.f.custom.value)
    //  dataArray =dataArray.concat(this.f.links.value)
    memberObj.profileId = this.profileId
      ? this.profileId
      : ''
    memberObj.userId = this.userId
    memberObj.data = dataArray
//console.log(memberObj)
    // return
    if(this.f.company.value && !this.allCompanies.some(x => x.name.toLowerCase() == this.f.company.value.toLowerCase()))
    {
      this.createNewCompany(this.f.company.value)
    }
    if (memberObj.profileId) {
      this.updateMember(memberObj)
      this.updatePublicProfileInfo(memberObj.profileId)
      return
    }
    this.createOfficialProfile(memberObj)
  }

  getUserDetails (id=null,type=null) {
    let inputObj = {

    }
    if(id){
      inputObj= {
        id:id
      }
    }
    if(type){
      inputObj= {
        type:'OFFICIAL'
      }
    }
    this.query = this.apollo.watchQuery({
      query: publicProfilesQuery,
      variables:inputObj,
     fetchPolicy:'network-only'
    })
    this.query.valueChanges.subscribe(({ data }) => {
      this.userData = []
      this.userData = data
      let emailArr = []

      let isEmail, isAddress, isLink, isCustom, isPhone,isFacebook, isTwitter, isSnapchat, isInstagram, isLinkedIn, isTickTok
      if(this.userData.profiles.length > 0)
      {
          this.updateProfileStatus =true
      }
      else {
        let email = localStorage.getItem('email') ? localStorage.getItem('email') : ""
        let phone = localStorage.getItem('phone') ? localStorage.getItem('phone') : ""
        
        this.getData('emails').removeAt(0)
      
        this.getData('emails').push(
          this.formBuilder.group({
            value: [email, [Validators.required, Validators.email]],
            format: ['EMAIL'],
            title: ['Professional'],
            key:
             ['']
          })
        )
        if(phone != "null")
        {
          this.getData('phones').removeAt(0)
          this.getData('phones').push(
            this.formBuilder.group({
              value: ['+' + phone],
              format: ['PHONE_NUMBER'],
              title: ['Professional'],
              key: ['']
            })
          )
        }
     
      }
      this.userData.profiles[0] &&
        this.userData.profiles[0].data.forEach((item, index) => {
          delete item.__typename
          if (this.userData.profiles[0]) {
            this.url = this.userData.profiles[0].displayImageURL
          }
          if (item.format == 'EMAIL') {
            const found = emailTypes.some(el => el.id === item.title)
            if (!found) emailTypes.push({ id: item.title, value: item.title })
            this.getData('emails').push(
              this.formBuilder.group({
                value: [item.value, [Validators.required, Validators.email]],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isEmail = true
          } else if (item.format == 'PHONE_NUMBER') {
            let moblie = item.value
            const found = phoneTypes.some(el => el.id === item.title)
            if (!found) phoneTypes.push({ id: item.title, value: item.title })
            this.getData('phones').push(
              this.formBuilder.group({
                value: [moblie],
                format: [item.format],
                title: [item.title],
                key: [item.key]
              })
            )
            if(item.key =='RU')
            {
              setTimeout(()=>{
              $(".selected-flag .iti-flag").removeClass("kz")
            },100)

            }
            if(item.key =='RU')
            {
              setTimeout(()=>{
                $(".selected-flag .iti-flag").removeClass("kz")
                $(".selected-flag .iti-flag").removeClass("us")
                $(".selected-flag .iti-flag").addClass("ru")
              },1000)
            }
            
            
            isPhone = true
          } else if (item.format == 'LINK') {
            const found = linkTypes.some(el => el.id === item.title)
            if (!found) linkTypes.push({ id: item.title, value: item.title })
            this.getData('links').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isLink = true
          } else if (item.format == 'UNIVERSITY') {
            this.getData('education').removeAt(0)

            let education = item.value.split('$$$')
            this.getData('education').push(
              this.formBuilder.group({
                format: [item.format],
                title: [item.title],
                key: [''],
                university: [education[0]],
                degree: [education[1]],
                study: [education[2]],
                startYear: [education[3]],
                endYear: [education[4]]
              })
            )
          } else if (item.format == 'GENERIC') {
            const found = customTypes.some(el => el.id === item.title)
            if (!found) customTypes.push({ id: item.title, value: item.title })
            this.getData('custom').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isCustom = true
          } else if (item.format == 'LOCATION') {
            const found = addressTypes.some(el => el.id === item.title)
            if (!found) addressTypes.push({ id: item.title, value: item.title })
            let location = item.value.split('$$$')
            console.log(JSON.stringify(location),'locat')
            this.getData('address').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                street: [location[0]],
                street1: [location[1]],
                city: [location[3]],
                state: [location[4]],
                country: [location[5]],
                postCode: [location[2]],
                key: ['']
              })
            )
            isAddress = true
          }

          if (item.format == 'JOB_TITLE') {
            this.profileForm.patchValue({
              jobTitle: item.value
            })
          }
          if (item.format == 'ORGANIZATION' && item.title.toLowerCase()=='company') {
            this.profileForm.patchValue({
              company: item.value
            })
          }
          if (item.format == 'ORGANIZATION' && item.title.toLowerCase()=='university') {
            this.profileForm.patchValue({
              university: item.value
            })
          }
          if (item.title == 'Facebook') {
            // this.profileForm.patchValue({
            //   facebook: item.value
            // })
            // const found = linkTypes.some(el => el.id === item.title)
            // if (!found) linkTypes.push({ id: item.title, value: item.title })
            
            this.getData('facebook').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isFacebook = true
          }
          if (item.title == 'Twitter') {
            // this.profileForm.patchValue({
            //   twitter: item.value
            // })
            this.getData('twitter').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isTwitter = true
          }
          if (item.title == 'Linked In') {
            // this.profileForm.patchValue({
            //   linkedIn: item.value
            // })
               // })
               this.getData('linkedIn').push(
                this.formBuilder.group({
                  value: [item.value],
                  format: [item.format],
                  title: [item.title],
                  key: ['']
                })
              )
              isLinkedIn = true
          }
          if (item.title == 'TikTok') {
            // this.profileForm.patchValue({
            //   ticktok: item.value
            // })
            this.getData('ticktok').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isTickTok = true
          }
          if (item.title == 'Instagram') {
            // this.profileForm.patchValue({
            //   instagram: item.value
            // })
            this.getData('instagram').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isInstagram = true
          }
          if (item.title == 'Snapchat') {
            // this.profileForm.patchValue({
            //   snapchat: item.value
            // })
            this.getData('snapchat').push(
              this.formBuilder.group({
                value: [item.value],
                format: [item.format],
                title: [item.title],
                key: ['']
              })
            )
            isSnapchat = true
          }
          if (item.format == 'DEPARTMENT') {
            this.profileForm.patchValue({
              department: item.value
            })
          }
        })
      if (isEmail) {
        this.getData('emails').removeAt(0)
      }
      if (isPhone) {
        this.getData('phones').removeAt(0)
      }
      if (isLink) {
        this.getData('links').removeAt(0)
      }
      if (isCustom) {
        this.getData('custom').removeAt(0)
      }
      if (isAddress) {
        this.getData('address').removeAt(0)
      }
      if (isLinkedIn) {
        this.getData('linkedIn').removeAt(0)
      }
      if (isInstagram) {
        this.getData('instagram').removeAt(0)
      }
      if (isSnapchat) {
        this.getData('snapchat').removeAt(0)
      }
      if (isTwitter) {
        this.getData('twitter').removeAt(0)
      }
      if (isTickTok) {
        this.getData('ticktok').removeAt(0)
      }
      if (isFacebook) {
        this.getData('facebook').removeAt(0)
      }
      // console.log(this.userData)
      this.profileForm.patchValue({
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        username: id ? this.userData.profiles[0] && this.userData.profiles[0].title : this.f.username.value
      })
    })
    setTimeout(()=>{
      this.enableAutoCountrySelect = false
    },2000)
   
  }

  updateMember (memberObj) {

    memberObj.profileId = this.userData.profiles[0].id
    memberObj.userId = this.userId
    memberObj.title = this.f.username.value
    this.loading = true
    this.apollo
      .mutate({
        mutation: updateProfileMemberMutution,
        variables: memberObj
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.dDJrSRUutB) {
            if (this.profilePic) {
              this.fileUploadToServer(data.dDJrSRUutB.displayImagePutURL)
            } else {
              this.translate
                .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
              this.router.navigate(['/member-details'])
            }
          
          }
        },
        error => {
          this.loading = false
          // this.toastr.info("Already email/phone number exists.");
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  createEmpFormGroup () {
    return this.formBuilder.group({
      empName: ['', [Validators.required]],
      age: ['', [Validators.required, Validators.min(21)]],
      skill: ['', [Validators.required]]
    })
  }

  removeBlock (idx: number, type) {
    this.getData(type).removeAt(idx)
  }

  createOfficialProfile (memberObj) {
    this.loading = true
    memberObj.title = this.f.username.value
    this.apollo
      .mutate({
        mutation: createPublicProfileMemberMutution,
        variables: memberObj,
        update: (proxy, { data: { YFvPnwQbVm } }: any) => {
        }
      })
      .subscribe(
        ({ data }:any) => {
          this.loading = false
          
          //console.log(data, 'data')
          this.updatePublicProfileInfo(data.YFvPnwQbVm.profileId)
          this.createBranchLink(data.YFvPnwQbVm.profileId)
          this.store.dispatch(new ProfileUpdate(this.url))
          this.translate
          .get('MESSAGES.PROFILE_CREATED_SUCCESSFULLY')
          .subscribe((res: string) => {
            this.toastr.info(res)
          })
          if (this.profilePic) {
            this.fileUploadToServer(data.YFvPnwQbVm.displayImagePutURL)
          }
          this.router.navigate(['/member-details'])

        },
        error => {
          alert(error)
          this.loading = false
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  createBranchLink(profileId){
    this.apollo
      .mutate({
        mutation: generateBranchLink,
        variables: {
          profileId:profileId
        }
      })
      .subscribe(
        ({ data, loading, error }: any) => {
          this.loading = false
          if (data.kbtsEXRVpv) {
            //link generated successfully.
          } 
        },
        error => {
          this.loading = false
        }
      )
  }

  openModal (type, index: number) {
    const modalRef = this.modalService.open(CreateNewLabelComponent)
    modalRef.componentInstance.newLabel = ''
    modalRef.result.then(
      result => {
        if (result) {
          if (type === 'address') {
            if (result != 'cross-click') {
              addressTypes.push({
                id: result,
                value: result
              })
            } else {
              result = addressTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'links') {
            if (result != 'cross-click') {
              linkTypes.push({
                id: result,
                value: result
              })
            } else {
              result = linkTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }

          if (type === 'custom') {
            if (result != 'cross-click') {
              customTypes.push({
                id: result,
                value: result
              })
            } else {
              result = customTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'phones') {
            if (result != 'cross-click') {
              phoneTypes.push({
                id: result,
                value: result
              })
            } else {
              result = phoneTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
          if (type === 'emails') {
            if (result != 'cross-click') {
              emailTypes.push({
                id: result,
                value: result
              })
            } else {
              result = emailTypes[0].id
            }
            this.getData(type)
              .at(index)
              .patchValue({ title: result })
          }
        }
      },
      error => {
        // alert(234)
      }
    )
  }

  get f () {
    return this.profileForm.controls
  }
  get emails (): FormArray {
    return this.profileForm.get('emails') as FormArray
  }

  getData (type): FormArray {
    return this.profileForm.get(type) as FormArray
  }
  addEmails () {
    let fg = this.helper.createEmailFormGroup()
    this.getData('emails').push(fg)
  }
  addPhones () {
    const total = this.f.phones.value.length
    if (this.f.phones.value[total - 1].value != '') {
      let fg = this.helper.createPhoneFormGroup()
      this.getData('phones').push(fg)
    }
  }

  addAddress () {
    let fg = this.helper.createAddressFormGroup()
    this.getData('address').push(fg)
  }
  addLinks () {
    let fg = this.helper.createLinkFormGroup()
    this.getData('links').push(fg)
  }
  addCustom () {
    let fg = this.helper.createCustomFromGroup()
    this.getData('custom').push(fg)
  }
  addCustomeLabel (eve, type, index) {
    eve.srcElement.blur()

    // return
    if (eve.target.value == 'new') {
      eve.preventDefault()
      this.openModal(type, index)
    }
  }
  onSelectFile (event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0]) // read file as data url
      this.profilePic = event.target.files[0]
      // this.fileUploadToServer(event.target.files[0])
      //this.profileImage = event.target.files[0]
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result

        // console.log(this.url)
      }
    }
  }
  checkNumber (i) {
    if(i==0 && this.role!="ADMIN")
    {
      return false
    }
    //this.elementRef.nativeElement.querySelector('.selected-flag').addEventListener('click', this.onClick.bind(this))
  }
  fileUploadToServer (url) {
    this.store.dispatch(new ProfileUpdate(this.url))

    let headers = new HttpHeaders({
      'Content-Type': 'image/jpeg'
    })
    let options = {
      headers: headers
    }
    this.http.put(url, this.profilePic, options).subscribe(
      res => {

        // handle success
        //reset file input
        this.translate
        .get('MESSAGES.PROFILE_UPDATED_SUCCESSFULLY')
        .subscribe((res: string) => {
          this.toastr.info(res)
        })
        window.location.href = '/member-details';
        // this.toastr.info('Profile updated successfully.')
        // this.router.navigate(['/member-details'])
      },
      err => {}
    )
  }

  getValidUrl = (url = '') => {
    let myWindow = window as any
    let newUrl = myWindow.decodeURIComponent(url)
    newUrl = newUrl.trim().replace(/\s/g, '')

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`
    }

    return newUrl
  }
  enableDisablePhone() {
    this.profileForm.get('phones').enable()
  }
  getPublicprofile(){
    this.loading = true
    this.publicProfileQuery =  this.apollo.watchQuery({
      query: publicProfileQuery,
      fetchPolicy:"network-only"
    })
     this.publicProfileQuery.valueChanges.subscribe(result => {
      if (result.data.publicProfile) {
        // alert()
        let {username,description,title} = result.data.publicProfile
        // username = username.
        this.profileForm.patchValue({
          // username:title,
          description:description
        })
        this.profileId = result.data.publicProfile.profileId
        this.getUserDetails(result.data.publicProfile.profileId)
        
      }
      else{
        // this.getUserDetails('','OFFICIAL')
        /** Account Info ***/
          this.queryAc = this.apollo.watchQuery({
            query: accountInfo,
            errorPolicy:"all",
            
          })
          this.queryAc.valueChanges.subscribe((result):any => {
            this.account = result.data.accountInformation
          let fullName =`${result.data.accountInformation.firstName}${result.data.accountInformation.lastName}`
          fullName = fullName.replace(/\s/g, "");
          this.profileForm.patchValue({
            username:`${fullName}`,
            })
          },error => {
            this.loading = false
          })
      
      }
      this.loading = false
    },error => {
      this.loading = false
    })
  }
  updatePublicProfileInfo(id){
      this.apollo
      .mutate({
        mutation: setPublicProfileMutution,
        variables:{
          profileId:id,
          username:this.f.username.value,
          status:'',
          description: this.f.description.value
        },
        update: (proxy, { data: { QJTHNWOMEA } }: any) => {
         
        }
      })
      .subscribe(
        ({ data }) => {

    },error => {
      this.loading = false
    })
  }
   goToBack(){
   this.router.navigate(['/member-details'])
  }
  checkSpaces(ev){
    console.log(ev)
  }
  onSearchChange(eve){
    if((eve.key=="0" || eve.which ==48) && eve.target.value.length == 0){
      return false
    }
   return true
  }
  addFacebook () {
    let fg = this.helper.createFacebookFormGroup()
    this.getData('facebook').push(fg)
  }
  addTwitter () {
    let fg = this.helper.createTwitterFormGroup()
    this.getData('twitter').push(fg)
  }
  addSnapchat () {
    let fg = this.helper.createSnapchatFormGroup()
    this.getData('snapchat').push(fg)
  }
  addInstagram () {
    let fg = this.helper.createInstagramFormGroup()
    this.getData('instagram').push(fg)
  }
  addTiktok () {
    let fg = this.helper.createTikTokFormGroup()
    this.getData('ticktok').push(fg)
  }
  addLinkedIn () {
    let fg = this.helper.createLinkedInFormGroup()
    this.getData('linkedIn').push(fg)
  }
  getTheCompanyValue1($event,all=false){
    let inputObj:any = {
      name:$event
    }
    if(all){
      inputObj = {}
    }
    this.companyQuery = this.apollo.watchQuery({
      query: getCompanyQuery,
      fetchPolicy:"network-only",
      variables: inputObj,
    })
     this.companyQuery.valueChanges.subscribe(({ data }) => {
      if(all){
        this.allCompanies = data.getCompany
      }
      else{
        this.companiesArray = data.getCompany
      }
      this.companiesArray=this.companiesArray.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
      if(all){
        this.allCompanies=this.allCompanies.filter((v,i,a)=>a.findIndex(t=>(t.name == v.name))==i)
        this.allCompanies = this.allCompanies.map(element => {
          element.name =element.name.toLowerCase()
          element.name = element.name.replace(/['"]+/g, '')
          element.name = element.name.replace(/["']+/g, '')
          return element
        });
        
      } 
      if (!this.cd['destroyed']) {
        this.cd.detectChanges();
     }

    })
  }
  getTheCompanyValue(term){
      if(!term){
        return false
      }
      this.http
      .get<any>(`https://api.opencorporates.com/v0.4/companies/search?q=${term}&format=json`)
      .subscribe((data: any) => {
        this.companiesArray = data.results.companies.map(element => {
           element.name =element.company.name.toLowerCase()
           element.name = element.name.replace(/['"]+/g, '')
           element.name = element.name.replace(/["']+/g, '')
           return element
        });
        console.log(this.companiesArray)
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
       }
      },err=>{
        this.getTheCompanyValue1(term)
      })
  }
   onKeySearch(event: any) {
    clearTimeout(this.timeout);
    let $this = this;
      this.timeout = setTimeout(function () {
        if (event.keyCode != 13) {
          console.log(event)
          $this.getTheCompanyValue(event);
        }
      }, 500);
  }
   
  addTagPromise(name) {
    return new Promise((resolve) => {
        this.loading = true;
        // Simulate backend call.
        setTimeout(() => {
            resolve(name);
            this.loading = false;
        }, 1000);

    })
  }
  createNewCompany(name){
    this.apollo.mutate({
      mutation: createCompanyMutation,
      variables:  {
        name:name,
        orgId:this.account.organizationId,
        website:""
      }
    }).subscribe(({ data,loading,error }:any) => {
  
       },
       err => {
          console.log(err)
       }
    );
  }
  
}
