
<div class="grayBg container-cs aboutBlk">
    <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body ">
          <div class="text-center" *ngIf="!showVerification && !showRegistration">
            <h4>Welcome to Staytouch</h4>
            <p>Get started in three simple steps</p>
          </div>
          <div class="text-center" *ngIf="showVerification">
            <h4>Please enter the One-Time  Password to verify your account </h4>
            <p class="text-gray pt-1">A One-Time  Password has been sent to {{loginPhone.value.dialCode}}{{loginPhone.value.number}}</p>
          </div>
          <div class="text-center pb-4" *ngIf="showRegistration">
            <h4>Registration </h4>
            
          </div>
          
          <div *ngIf="errorMessage">
            <div class="text-danger p-3" role="alert">
              {{errorMessage}}
            </div>
          </div>
          <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="getLoginDetails()">
            <div *ngIf="showVerification"
            class="form-label-group"
          >
            <!-- <label for="otpCode" class="text-center  mb-0"
              >{{ 'MESSAGES.ENTER_THE_CODE' | translate }}
            </label> -->
            <!-- <input
              type="text"
              maxlength="6"
              id="otpCode"
              formControlName="loginOtpCode"
              class="otpInput"
            /> -->
            <input type="text" maxlength="6" id="otpCode" formControlName="loginOtpCode" class="form-control custominp" >
            <!-- <label for="inputEmail">Email address</label> -->
          </div>
          <!-- <div *ngIf="submitted && f.otpCode.errors" class="text-danger">
            <div *ngIf="f.otpCode.errors.required">
              {{ 'HEADER.otpCode' | translate }}
            </div>
            <div *ngIf="f.otpCode.errors.minlength">
              {{ 'HEADER.otpCode' | translate }}
            </div>
          </div> -->
          <!-- <div *ngIf="showRegistration">
            <div class="form-label-group">
              <label for="name">Full name</label>
              <input type="text" id="name" formControlName="name" class="form-control custominp" >
            </div>
           
          </div> -->
          
          <div *ngIf="showRegistration" class="regBlk">
            <div class="">
              <label for="organization"> Company name</label>
              <input type="text" id="organization" formControlName="organization" class="form-control custominp" />
            </div>
            <div >
              <label for="organization"> Company website</label>
              <input type="text" id="website" formControlName="website" class="form-control custominp" />
            </div>
            <div class="">
              <label for="inputEmail">Email address</label>
              <input type="email" id="inputEmail" formControlName="loginEmail" class="form-control custominp" >
            </div>
            <div class="" *ngIf="!showRegistration && !showVerification">
			    	  <label class="pt-2">Enter your phone number</label>
                <ngx-intl-tel-input 
                [cssClass]="'form-control custominp'" 
                [preferredCountries]="['us', 'gb']" 
                [enablePlaceholder]="false"
                [enableAutoCountrySelect]="true"
                name="loginPhone"
                id="loginPhone" 
                [searchCountryFlag]="true"
                [separateDialCode]="'false'"
                [maxLength]="15"
                formControlName="loginPhone">
              </ngx-intl-tel-input>
              </div>
              <div class="">
                <label for="name">Office Phone</label>
                <ngx-intl-tel-input 
                [cssClass]="'form-control custominp'" 
                [preferredCountries]="['us', 'gb']" 
                [enablePlaceholder]="false"
                [enableAutoCountrySelect]="true"
                name="loginPhone"
                id="loginPhone" 
                [searchCountryFlag]="true"
                [separateDialCode]="'false'"
                [maxLength]="15"
                formControlName="loginPhone">
              </ngx-intl-tel-input>
              </div>
            <div >
              <label for="organization">  Owner Name</label>
              <input type="text" id="website" formControlName="website" class="form-control custominp" />
            </div>
            <div >
              <label for="organization">Authorised plan</label>
              <select  class="form-control ">
                  <option>Select</option>
                  <option>Plan A</option>
                  <option>Plan B</option>
                  <option>Plan C</option>
              </select>
            </div>
            <div >
              <label for="organization">Authorised number of users</label>
              <input type="text" id="website" formControlName="website" class="form-control custominp" />

            </div>
            <div class="">
              <label for="inputEmail">Adress</label>
              <textarea  formControlName="loginEmail" class="form-control custominp" ></textarea>
            </div>
          </div>


          <!-- <div *ngIf="showRegistration">
            <div class="">
              <label for="password"> Password</label>
              <input type="text" id="password" formControlName="password" class="form-control custominp" >
            </div>
           
          </div> -->

          <div *ngIf="showRegistration">
           
            <!-- <div *ngIf="submitted && loginEmail.value==''" class="alert alert-danger">
              Email is required.
            </div>
            <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)"
                class="alert alert-danger">
              <div *ngIf="loginEmail.errors.required">
                Email is required.
              </div>
              <div *ngIf="loginEmail.errors.email">
                Email is not valid.
              </div>
            </div> -->
          </div>

           
            <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" [disabled]="!loginForm.valid">Submit</button>
            <div class="d-flex justify-content-center text-center pt-2">
               <a *ngIf="showVerification" (click)="withEmail=true" class=""> Resend Code?</a>
              <!--a *ngIf="withEmail" (click)="withEmail=false"> Signup with phone number?</a> -->
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  
