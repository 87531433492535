<div class="row justify-content-center">
  <div class="col-md-12 p-2">
    <div class="card p-md-5 py-5 my-3">
      <div class="d-sm-flex justify-content-between w-100">
        <ul
          class="list-inline listInline py-2 pl-0 d-flex mb-3   col-md-12 nav nav-tabs"
        >
          <!-- <li class="nav-item" ><a class="textColor active" data-toggle="tab"  href="#myAccount">My Account</a></li> -->
          <li class="nav-item">
            <a class="textColor active " data-toggle="tab" href="#settings">
              {{ 'HEADER.SETTINGS' | translate }}</a
            >
          </li>
          <li class="nav-item px-5">
            <a class="textColor   " data-toggle="tab" href="#help">
              {{ 'HEADER.HELP' | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a class="textColor  " data-toggle="tab" href="#legal">
              {{ 'HEADER.LEGAL' | translate }}</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div class="tab-pane   container  fade" id="myAccount">
          <!-- <div class="d-flex justify-content-center w-100">
                          <app-update-account>
                          </app-update-account>
                       </div> -->
        </div>
        <div class="tab-pane container fade  active show" id="settings">
          <div class="d-flex justify-content-between pt-3">
            <p class="font-weight-bold mb-0 ">
              {{ 'HEADER.NEWSLETTERS' | translate }}
            </p>
            <div class="form-group">
              <span class="switch switch-sm">
                <ng-container *ngIf="settings.newsletter == 'false'">
                  <input
                    type="checkbox"
                    [checked]="false"
                    class="switch"
                    id="switch-sm"
                    (change)="updateNewsletter($event)"
                  />
                </ng-container>
                <ng-container *ngIf="settings.newsletter == 'true'">
                  <input
                    type="checkbox"
                    [checked]="true"
                    class="switch"
                    id="switch-sm"
                    (change)="updateNewsletter($event)"
                  />
                </ng-container>

                <label for="switch-sm"></label>
              </span>
            </div>
          </div>
        </div>
        <div class="tab-pane container fade" id="help">
          <h6 class="">
            {{ 'HEADER.PLEASE_SEND_QUESTIONS_OR_IDEAS_TO' | translate }}
            <a style="color: #ce0606;">support@staytouch.com</a>
            {{ 'HEADER.AND_WE_WILL_REPLY_AS_SOON_AS_POSSIBLE' | translate }}.
          </h6>
        </div>
        <div class="tab-pane container fade" id="legal">
          <ul class="list-inline m-0 p-0">
            <li>
              <a href="https://www.staytouch.com/termsEU.php?type=b" target="_blank" id="terms"
                >{{ 'FOOTER.TERMS_OF_SERVICE' | translate }}
              </a>
            </li>
            <li>
              <a href="https://www.staytouch.com/privacypolicyEU.php?type=b" target="_blank"
                >{{ 'FOOTER.PRIVACY_POLICY' | translate }}
              </a>
            </li>
            <li>
              <ul class="m-0 pl-3">
                <li>{{ 'HEADER.PATENT_PENDING' | translate }}</li>
                <li>
                  {{
                    'HEADER.STAYTOUCH_IS_A_TRADEMARK_OF_GG_TECHNOLOGIES_INC'
                      | translate
                  }}.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
