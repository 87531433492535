import { Component, OnInit } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import { accountInfo } from '../../../services/user.gql'

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss']
})
export class MyTeamsComponent implements OnInit {
  private query: QueryRef<any>
  loading:any = true
  teams: [] = []
  constructor (private apollo: Apollo) {}

  ngOnInit () {
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      errorPolicy:"all",
      fetchPolicy:"network-only"
      
    })
    this.query.valueChanges.subscribe(({ data }: any) => {
      this.loading = false
      this.teams = data.accountInformation.teams
    },err=>{
      this.loading = false
    })
  }
}
