import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError, of } from 'rxjs'
import { environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})

export  class RefreshTokenService {
  constructor(private http: HttpClient) {}
//   hello(){
//     console.log('je')
//   }
   hello = new Promise(function(resolve, reject) {
    setTimeout(function() {
        resolve('I promise to return this after 1 second!');
      }, 1000);
    });
    
    static saveURL(): any {
      console.log('rmaya')
    return 1
  }
  requestRefreshToken(token: string) {
    let userInfo = {
        "token": environment.AUTH_TOKEN,
        "identityProvider": "STAYTOUCH",
        "credentials": {
        "oldToken": token,
        "email":"ramya@krify.net",
        "password":"1234567",
       
        },
         "__ST_TKT": "90eab6f6971b3c3096c0812c356289ee"
    }
    //return this.http.post<any>(`${environment.AUTH_URL}/signInB2B`, userInfo).subscribe(data => {
    return this.http.post<any>(`${environment.AUTH_URL}f446ea68bf2ad36f3f6f64f859bc0229`, userInfo).subscribe(data => {
        console.log(data,'data')
         
      },(error) => {
        console.log('there was an error sending the query', error);
        
      });
    
  }
}
