import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import {
  addTeamMutation,
  membersQuery,
  teamsQuery,
  teamsRemoveMemberMutation,
  teamsAddMemberMutation,
  membersBasicQuery,
  updateTeamMutation
} from '../../../services/admin.gql'
import { ToastrService } from 'ngx-toastr'
import { ElementRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss']
})
export class AddTeamComponent implements OnInit {
  @Input() userId: any
  @Input() teamUpdate: any
  @Input() isEditOrAdd: boolean
  @Output() onHide: EventEmitter<any> = new EventEmitter()
  myElement: ElementRef
  public ngxMembersValue: any = []
  public ngxDisabled = false
  public name = ''
  private query: QueryRef<any>
  public members: any
  public membersOfTeam: any
  public users: any = []
  public addTeamForm: any
  public addOrSaveTeam: string = 'TEAMSPAGE.SAVE_TEAM'
  public loading: boolean = false
  public removedArrayIds:any =[]
	query$ : any
  permissionsRes:any ={}
  constructor (
    private apollo: Apollo,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private helperService:HelperService

  ) {
    this.loading = true
    //console.log(this.teamUpdate)
     this.userId = localStorage.getItem("id")
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      // console.log(data)
      this.loading = false
      this.userId = data.accountInformation.id
      //alert(this.userId)
      
    })
   
  }
  ngOnInit () {
    // querying the members list  
    this.query = this.apollo.watchQuery({
      query: membersQuery,
      variables: {},
      fetchPolicy:"network-only"
    })

    this.query$ = this.query.valueChanges.subscribe(result => {
      if (result.data.members) {
        this.members = result.data.users
      }
    })
  }

  saveTeam () {
    // if (!this.isEditOrAdd) {
    //   console.log('update team')
    //   return
    // }
    this.loading = true

    let teamObj = {
      name: this.name,
      memberIds: this.ngxMembersValue
    }
    if (this.teamUpdate.teamId) {
      this.updateTeam(teamObj)

      return false
    }
    //mutating the data to delete
    this.apollo
      .mutate({
        mutation: addTeamMutation,
        variables: teamObj,
        // errorPolicy:'ignore',
        // update: (proxy, { data: { XTFPGBIOGK } }: any) => {
        //   // Read the data from our cache for this query.
        //   const data: any = proxy.readQuery({ query: teamsQuery })
        //   console.log(data, 'data')
        //   data.teams.unshift(XTFPGBIOGK)
        //   console.log(data, 'data2')

        //   proxy.writeQuery({ query: teamsQuery, data })
        // }
      })
      .subscribe(
        ({ data }:any) => {
          // this.loading = false
          if(data.XTFPGBIOGK && data.XTFPGBIOGK.teamId){
            alert('Team created successfully.')
            this.closeAddTeam(data.XTFPGBIOGK.teamId)
          }
          else{
            this.loading = false
            alert('Unable to create team. Team name already exists.')
          }
         
          // this.loading = true
          // this.toastr.info('Team created successfully.')
          // this.translate
          //   .get('MESSAGES.TEAM_CREATED_SUCCESSFULLY')
          //   .subscribe((res: string) => {
          //     this.toastr.info(res)
          //     this.loading = false

          //   })
          // console.log('got data', data);
        },
        error => {
          this.loading = false
          alert(error)
          alert('Unable to create team. Team name already exists.')
          console.log('there was an error sending the mutation', error)
        }
      )
    // window.location.reload()
  }

  closeAddTeam (teamId=0) {
    this.onHide.emit(teamId)
  }

  updateTeam (teamObj) {
    teamObj.teamId = this.teamUpdate.teamId
    if(this.removedArrayIds.length>0)
    {
        this.removeMemberAdd()
    }
    this.apollo
      .mutate({
        mutation: updateTeamMutation,
        variables: teamObj,
        // update: (proxy, { data: { AFFOUFBOXK } }: any) => {
        //   // console.log(loading)
        //   // Read the data from our cache for this query.
        //   const data: any = proxy.readQuery({ query: teamsQuery })
        //   const foundIndex = data.teams.findIndex(
        //     x => x.teamId == teamObj.teamId
        //   )
        //   data.teams[foundIndex] = AFFOUFBOXK
        //   // data.teams.push(AFFOUFBOXK);
        //   proxy.writeQuery({ query: teamsQuery, data })
        // }
      })
      .subscribe(
        ({ data, loading }: any) => {
          this.loading = false
          alert("Team updated successfully.")
          // this.translate
          //   .get('MESSAGES.TEAM_UPDATED_SUCCESSFULLY')
          //   .subscribe((res: string) => {
          //     this.toastr.info(res)
          //   })
          this.closeAddTeam(this.teamUpdate.teamId)
          //console.log('got data', data)
        },
        error => {
          //console.log('there was an error sending the mutation', error)
        }
      )
  }
  ngAfterViewChecked()
  {
    setTimeout(()=>{
      const elmnt1:any = document.getElementsByClassName(`ngx-select__search`)[0] as HTMLElement
      if(elmnt1)
      {
        elmnt1.placeholder = "+ Add More Members..";
      }
    },100)
  }
  ngOnChanges () {
    //console.log(this.teamUpdate, 'teamDetails')
    if (this.teamUpdate.teamId) {
      this.ngxMembersValue = this.teamUpdate.memberIds
      this.name = this.teamUpdate.name
    }
    else{
      this.ngxMembersValue = this.userId
    }
    // if (this.isEditOrAdd) {
    //   this.name = ''
    //   this.ngxMembersValue = []
    //   return
    // }
   
    this.addOrSaveTeam = this.teamUpdate.teamId
      ? 'TEAMSPAGE.UPDATE_TEAM'
      : 'TEAMSPAGE.ADD_TEAM'
    this.loading = true
    this.query = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        memberIds: this.teamUpdate.memberIds
      }
    })

    this.query$ = this.query.valueChanges.subscribe(
      result => {
        this.loading = false
        this.members = result.data.users
        //if (this.teamUpdate != undefined || this.teamUpdate != null) {
        // this.name = this.teamUpdate.name
        //this.membersOfTeam = this.members.filter(m => m.teams.some(t => t.id === this.teamUpdate.id))
        //this.ngxMembersValue = this.membersOfTeam.map(t => t.id)
        //}
      },
      error => {
        this.loading = false
        //console.log(error, 'error')
      }
    )
  }

  
  addMoreMembers(){
    this.translate
    .get('MESSAGES.CHANGES_THAT_YOU_MADE_MAY_NOT_BE_SAVED')
    .subscribe((res: string) => {
      if (!confirm(res)) {
        return false
      }
      this.router.navigate(['/create-member'])

    }) 
  }
 
  teamMemberAdd (ev) {
    const elmnt1:any = document.getElementsByClassName(`ngx-select__search`)[0] as HTMLElement
    elmnt1.placeholder = "+ Add More Members..";
    if(!this.teamUpdate.teamId)
    {
      return false
    }
    let teamObj = {
      teamId: this.teamUpdate.teamId,
      memberIds: [ev]
    }
    this.apollo
      .mutate({
        mutation: teamsAddMemberMutation,
        variables: teamObj,
        // update: (proxy, { data: { MPYVMDLKYI } }: any) => {
        //   // Read the data from our cache for this query.
        //   const data: any = proxy.readQuery({ query: teamsQuery })
        //   const foundIndex = data.teams.findIndex(
        //     x => x.teamId == teamObj.teamId
        //   )
        //   data.teams[foundIndex] = MPYVMDLKYI
        //   proxy.writeQuery({ query: teamsQuery, data })
        // }
      })
      .subscribe(
        ({ data }) => {
          //console.log('got data', data);
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  removeMemberAdd () {
    let teamObj = {
      teamId: this.teamUpdate.teamId,
      memberIds: this.removedArrayIds
    }
    this.apollo
      .mutate({
        mutation: teamsRemoveMemberMutation,
        variables: teamObj,
        update: (proxy, { data: { SSYEYCOLZJ } }: any) => {
          // Read the data from our cache for this query.
          const data: any = proxy.readQuery({ query: teamsQuery })
          const foundIndex = data.teams.findIndex(
            x => x.teamId == teamObj.teamId
          )
          data.teams[foundIndex] = SSYEYCOLZJ
          proxy.writeQuery({ query: teamsQuery, data })
        }
      })
      .subscribe(
        ({ data }) => {
          // console.log('got data', data);
        },
        error => {
          console.log('there was an error sending the mutation', error)
        }
      )
  }
  removeMemberAddFun(ev)
  {
    this.removedArrayIds.push(ev)
    console.log(this.removedArrayIds)
  }
  ngOnDestroy () {
    this.isEditOrAdd = false
  	this.query$ ? this.query$.unsubscribe() : "";
  }
}
