<app-main-loading *ngIf="loading"></app-main-loading>

<div class="grayBg container-cs aboutBlk">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <h5 class="card-title text-center">{{ 'HEADER.BUSINESS_LOGIN' | translate }}</h5>
          <p class="text-center pb-2">Corporate login for business account holders.</p>
          <div *ngIf="errorMessage">
            <div class="text-danger p-3" role="alert">
              {{errorMessage}}
            </div>
          </div>
          <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="getLoginDetails()"  *ngIf="!show2MFa">
          <div *ngIf="withEmail">
            <div class="form-label-group1 pb-3">
              <input type="email" id="inputEmail" formControlName="loginEmail" class="form-control custominp" placeholder="Business email" autocomplete="new-password" >
              <!-- <label for="inputEmail">Email address</label> -->
            </div>
            <div *ngIf="submitted && loginEmail.value==''" class="alert alert-danger">
              Email is required.
            </div>
            <div *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)"
                class="alert alert-danger">
              <div *ngIf="loginEmail.errors.required">
                Email is required.
              </div>
              <div *ngIf="loginEmail.errors.email">
                Email is not valid.
              </div>
            </div>
          </div>

              <div class="form-label-group1 pb-3" *ngIf="!withEmail">
                <ngx-intl-tel-input 
                [cssClass]="'form-control custominp'" 
                [preferredCountries]="['us', 'gb']" 
                [enablePlaceholder]="false"
                [enableAutoCountrySelect]="false"
                name="loginPhone"
                id="loginPhone" 
                [searchCountryFlag]="true"
                [separateDialCode]="'false'"
                [maxLength]="15"
                [phoneValidation]="true"
                formControlName="loginPhone">
              </ngx-intl-tel-input>
              </div>
              <div >
                <div class="alert alert-danger" *ngIf="submitted && loginPhone.value && loginPhone.value.number.length < 10">
                  Phone number minimum length should be 10 characters
                </div>
                <div class="alert alert-danger" *ngIf="submitted && loginPhone.value && loginPhone.value.number.length >15">
                  Phone number maximum length should be 15 characters
                </div>
            </div>
            <div class="form-label-group1 pb-3">
              <input type="password" id="inputPassword" formControlName="loginPassword" class="form-control custominp" placeholder="{{ 'HEADER.PASSWORD' | translate }}" appAppPassword  autocomplete="new-password"  />
              <!-- <label for="inputPassword">Password</label> -->
            </div>
            <div *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)"
                class="alert alert-danger">
  
              <div *ngIf="loginPassword.errors.required">
                Password is required.
              </div>
             
            </div>
            <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" [disabled]="!loginForm.valid">{{ 'HEADER.SIGNIN' | translate }}</button>
            <div class="d-flex justify-content-end text-right pt-2">
              <a *ngIf="!withEmail" (click)="withEmail=true" class=""> With email?</a>
              <!-- <a *ngIf="withEmail" (click)="withEmail=false"> With phone number?</a> -->
              <a  [routerLink]="['/forgot-password']">{{ 'HEADER.FORGOT_PASSWORD' | translate }}?</a>
            </div>
            <hr/>
            <p class="w-100 d-block text-center">Please email us at <a  href="mailto:sales@staytouch.com">sales@staytouch.com</a> to enquire</p>

            
          </form>

          <form class="form-signin"  (ngSubmit)="verifyGoogleCode()" *ngIf="show2MFa">
              <div class="form-label-group1 pb-3">
                <input type="text" id="inputPassword" name="authcode" [(ngModel)]="authcode" class="form-control custominp" placeholder="Enter Google Authenticator Code"   autocomplete="off"  />
                <!-- <label for="inputPassword">Password</label> -->
              </div>
              <div class="alert alert-danger" *ngIf="errorMessage">
                <div >
                  Invalid code.
                </div>
               
              </div>
              <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit" [disabled]="!authcode">Verify</button>
              <div class="d-flex justify-content-end text-right pt-2">
                <a  (click)="clearLogin()">Back to Login ?</a>
              </div>
              <hr/>
              <p class="w-100 d-block text-center"> Please email us at <a  href="mailto:sales@staytouch.com">sales@staytouch.com</a> to enquire</p>
  
              
            </form>
        </div>
      </div>
    </div>
  </div>
  
