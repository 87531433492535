<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2 py-4 ">
    <div class="card py-md-3 py-3 px-3 ">
        <div  class=" d-flex justify-content-between text-center pt-2 pb-3  grid-st-center1 align-items-center flex-wrap">
            <h5>Order Analytics</h5>
           

        </div>
        <div class="d-flex row align-items-end">
            <div class="col-md-4 cust-theme">
                <label>Select Supplier</label>
                <ng-select [items]="suppliersList"
                bindLabel="supplierName" 
                bindValue="supplierName"
                class="theme-select col-md-4 pr-0 "
                [clearable]="false"
                [(ngModel)]="supplierName"
                placeholder ="Select supplier"
                ></ng-select>
            </div>
            <div class="col-md-3">
            <label>Start Date</label>
            <div class="input-group">
                <input class="theme-input" placeholder="Enter start date"
                       name="dp" [(ngModel)]="startDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
              </div>
            </div>
            <div class="col-md-3">
              <label>End Date</label>
              <div class="input-group">
                <input class="theme-input" placeholder="Enter end date"
                       name="dp" [(ngModel)]="endDate" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()">
              </div>
            </div>
            <div class="col-md-2">
                <a class="theme-btn" (click)="filterData()">Search</a>
            </div>
        </div>
        <div  class="text-center pt-3 pb-2  ">
            <ul  class="tab-block-cs list-inline d-inline-block">
                <li  class="list-inline-item mr-0" (click)="showOrders(1)">
                    <a [ngClass]="selectedType==1? 'active-link-tab':''" class="" >Sold</a>
               </li>
                <li  class="list-inline-item mr-0" (click)="showOrders(2)"><a [ngClass]="selectedType==2? 'active-link-tab':''" >Orders</a></li>
            </ul>
       </div>
        <div class="table-responsive py-2">
            <table class="table table-hover js-basic-example dataTable table-custom m-b-0">
                <thead>
                    <tr>    
                       
                        <th>Image</th>  
                        <th>Category</th>                                 
                        <th>Taj Number</th>
                        <th>Displayed Selling Price </th>
                        <th>Final Selling Price</th>
                        <th>Gold Color</th>
                        <th>Size</th>
                        <th >Customer Name</th>
                        <th>{{ selectedType==1 ? 'Sale Number' :'Order Number' }}</th>
                        <th>Phone</th>
                        <th style="width: 130px;">Date</th>
                    </tr>
                </thead>
                <tbody >
                    <tr class="cursor-pointer" *ngFor="let customer of customers| paginate: { itemsPerPage: itemsPerPage , currentPage: p, totalItems: totalItems } ;let i=index " (click)="viewOrder(customer.id)" >
                       
                        <td   
                            style="width: 70px;"  >
                             <img  width="70px" style="object-fit: contain;" height="70px" onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 80px;object-fit: contain;'" class="img-fluid1 mx-auto d-block img-p" src="{{customer.product.photos[0] && customer.product.photos[0].signedGetURL}}" alt=""></td>
                        <td  >{{customer.product.productCategory.name}}</td>
                        <td   class="project-title" >
                            {{customer.product.tajNumber}}
                        </td>
                        <td class="project-title"   >
                            {{customer.product.displayedSellingPrice}}
                        </td>
                        <td class="project-title"   >
                            {{customer.effectiveSalesPrice ? customer.effectiveSalesPrice : customer.actualSellingPrice}}  
                        </td>
                        <td> {{customer.ringSize}}</td>                         
                        <td> {{customer.goldColor}}</td>                         
                        <td>
                            {{customer.firstName}} {{customer.lastName}}
                        </td>
                        <td>
                            {{ customer.actionType ==  'ORDER' ? customer.customerOrderNumber : customer.customerOrderNumber }}
                        </td>
                      
                        <td   >
                            {{customer.phone}}
                        </td>
                       
                        <td class="project-actions" style="width: 130px;"  >
                            {{ customer.updatedAt  | amDateFormat: 'DD-MM-YYYY' }} 
                        </td>
                       
                        
                    </tr>
                    <tr *ngIf="!loading && customers.length==0">
                        <td colspan="11" class="text-center">
                            No orders .
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <pagination-controls (pageChange)="getPage(p = $event)"></pagination-controls>
        </div>
        
    </div>
  </div>
</div>

