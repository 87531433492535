import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppState, selectMemberAuth } from './store/app.states'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { MemberUser } from './models/member-user'
import { TranslateService } from '@ngx-translate/core';
import { Apollo, QueryRef } from 'apollo-angular'
import * as moment from 'moment';
import {SyncService} from '../user/services/sync.service'

import {
  PushNotificationsService
} from './services/push.notification.service';
import {reminderContactssQuery} from '../user/services/contacts.gql'
import {GoogleAnalyticsService} from "../shared/google-analytics.service"; // import our analytics service
import { HelperService } from './services/helper.service'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  crYear:any =new Date();
  getState: Observable<any>
  isAuthenticated: boolean = null
  errorMessage: string | null
  user: MemberUser = null
  timer:any
  sidebarToggle:any = false
  isMeetingsOpen:boolean = false
  isTeamsOpen:boolean = false
  toggle:boolean = false
  isUserAllow:boolean = true

  private queryRe: QueryRef<any>

  private title1: string = 'Browser Push Notifications!';
  idtype:any

  public url1
  public isAdmin: boolean = false
  role:any = ""
  permissionsRes:any ={}
  permissions:any = ""
  accountInfo:any ={}
  userRole:any
 constructor(private apollo: Apollo,private titleService: Title, private router: Router,
    private activatedRoute: ActivatedRoute,private store: Store<AppState>, private translate: TranslateService,private _notificationService: PushNotificationsService, public googleAnalyticsService: GoogleAnalyticsService,private syncService:SyncService, private helperService: HelperService) {
      this.permissionsRes =  this.helperService.getAccountInforamtion()
      this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      this.permissions = this.helperService.getPermissions(data)
        this.accountInfo = data.accountInformation
        this.userRole = this.permissions.role 
        if(data.accountInformation.role  == 'SALES'){
          this.isUserAllow = false
         
         }
      })
      this._notificationService.requestPermission();

      this.getState = this.store.select(selectMemberAuth)
        /** Get today reminders **/
           let role = localStorage.getItem('role')
           if(role == 'ADMIN')
           {
             this.role= 'B2B Admin'
           }
           else
           {
             this.role= 'B2B User'
           }
           
    }
    title:string ='Products'

    async ngOnInit() {
    //  this.scroll()
      $(document).keyup(function(e) {

        if (e.keyCode == 27) { // escape key maps to keycode `27`
           $('.dropdown-menu').removeClass('show')
       }
    });
    $(document).on('click',".nav-item-cs",()=>
    {
      $(".nav-item .nav-link ").addClass("collapsed")
      $(".nav-item .collapse").removeClass("show")
      $(".navbar-nav").removeClass("mobile-nav")
      this.toggle = !this.toggle

    })
    $(document).on('click',".collapse-item",()=>
    {

      $(".navbar-nav").removeClass("mobile-nav")
      this.toggle = !this.toggle

    })
      /** Get today reminders **/
      // var t = new Date("Wed Jun 03 2020 16:12:06 GMT+0530 (India Standard Time)").getTime() - (new Date()).getTime();
      // console.log(t)
    
      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
        )
        .subscribe(() => {
          var rt = this.getChild(this.activatedRoute)
          rt.data.subscribe(data => {
            // console.log(this.router.url);
            if( this.router.url.includes(`conference-details/2`) || this.router.url.includes(`participants-list/2`) || this.router.url.includes(`meeting-notes/2`))
            {
              this.title= "Conference"
            }
            else if( this.router.url.includes(`meetings/notes`))
            {
              this.title= "Add Meeting Notes"
            }
            else {
              this.title=data.title
            }
            this.idtype = data.idtype
            // this.title=data.title
            //data.title = $localize`:@@title:Dashboard`;
          this.translate.get(data.title).subscribe(name=>{
            this.titleService.setTitle(`StayTouch | ${name}`)})
            }); 
        })

        this.getState.subscribe((state) => {
          this.isAuthenticated = state.memberAuth.isAuthenticated; 
          if(this.isAuthenticated)
          {
            // this.getRemindersInfo()
            /************** */
            setInterval(()=>{
                this.getTheSyncDetails()
            },15*60*1000)
          }
          this.user = state.memberAuth.user;
          this.errorMessage = state.memberAuth.errorMessage;
        });

        this.url1 = this.router.url
        if (localStorage.getItem('role') === 'ADMIN') {
          this.isAdmin = true
        }
    }
   getRemindersInfo(){
    this.queryRe = this.apollo.watchQuery({
      query: reminderContactssQuery,
      variables:{
        reminderDate: moment().format("YYYY-MM-DD")
      },
      fetchPolicy:"network-only"
      
    })
    this.queryRe.valueChanges.subscribe(({data}:any) => {
      // this.remindersData = data.contacts
      data.contacts.forEach(element => {
        var t = new Date(element.reminderDate).getTime() - (new Date()).getTime();
        let reminderTime = t - 300000
        if(reminderTime > 0)
        {
          //console.log(element)
          let dateTime = moment(element.reminderDate).format('HH:mm')
         
            setTimeout(() => {
              let name = `${element.firstName} ${element.lastName}`
              this.notify(element.reminderMessage,name,dateTime)
            }, reminderTime)
          
         
        }
       
      });
    })
   }
    getChild(activatedRoute: ActivatedRoute) {
      if (activatedRoute.firstChild) {
        return this.getChild(activatedRoute.firstChild);
      } else {
        return activatedRoute;
      }
    }
    
    onActivate(event) {
      // alert(1)
        setTimeout(()=>{
          const elmnt = document.getElementById('content') as HTMLElement
          if(elmnt)
          {
            elmnt.scrollIntoView()
          }
        },100)
    }
   
    notify(message,name,dateTime) {
      let data: Array < any >= [];
      data.push({
          'title': `Reminder - ${message}`,
          'alertContent': `Today you have a ${message} with ${name} at ${dateTime}`
      });
      this._notificationService.generateNotification(data);
    }
    isSelected (route: string): boolean {
      return route === this.router.url
    }
    getClass () {
      let className = '123'
      // className =  'active-link'
      let pathname1 = window.location.pathname.split('/')
  
      if (
        [
          'contacts',
          'all-contacts',
          'contact-details',
          'contact-notes',
          'history',
          'contact-meetings'
        ].indexOf(pathname1[1]) > -1
      ) {
        className = 'active-link'
      }
      return className
    }
    getMeetingClass () {
      let className = '123'
      let pathname1 = window.location.pathname.split('/')
      if (
        [
          'meetings',
          'meeting-notes',
          'create-meeting',
          'calender-meetings'
        ].indexOf(pathname1[1]) > -1
      ) {
        className = 'active-link'
      }
      return className
    }
    getConferenceClass () {
      let className = '123'
      let pathname1 = window.location.pathname.split('/')
      if (
        [
          'conference-list',
          'join-conference',
          'create-conference',
          'participants-list',
          'conference-details',
          'messages'
        ].indexOf(pathname1[1]) > -1
      ) {
        className = 'active-link'
      }
      return className
    }
    getAccountClass () {
      let className = '123'
      let pathname1 = window.location.pathname.split('/')
      if (
        ['my-profile', 'my-teams', 'reset-password', 'update-account','merge-account','zoom-connect'].indexOf(
          pathname1[1]
        ) > -1
      ) {
        className = 'active-link'
      }
      // console.log(className)
      return className
    }
    getMembersClass () {
      // alert()
      let className = '123'
      let pathname1 = window.location.pathname.split('/')
      // console.log(pathname1)
  
      if (
        ['create-member', 'members', 'official-profile'].indexOf(pathname1[1]) >
        -1
      ) {
        // console.log('cdsfsdfds  ')
  
        className = 'active-link'
      }
      // console.log('cdsfsdfds  123')
  
      return className
    }
    public isLinkActive (url: any): boolean {
      const queryParamsIndex = this.router.url.indexOf('?')
      // console.log(this.router.url)
      // console.log(url)
      
      let baseUrl: any =
        queryParamsIndex === -1
          ? this.router.url
          : this.router.url.slice(0, queryParamsIndex)
      baseUrl = baseUrl.split('/')
      // console.log(`${url}`)
      if(baseUrl[1] == 'participants-list')
      {
        return url.includes(`/${baseUrl[1]}/${baseUrl[2]}`)
      }
      if(baseUrl[1] == 'conference-details')
      {
        return url.includes(`/${baseUrl[1]}/${baseUrl[2]}`)
      }
       if(baseUrl[1] == 'join-conference')
      {
        return url.includes(`/${baseUrl[1]}/${baseUrl[2]}`)
      }
      if(baseUrl[1] == 'all-user-contacts')
      {
        return url.includes(`/${baseUrl[1]}/${baseUrl[2]}`)
      }
      if(baseUrl[1] == 'meeting-notes')
      {
        return url.includes(`/${baseUrl[1]}/${baseUrl[2]}`)
      }
      return url.includes(`/${baseUrl[1]}`)
    }
    SendEvent(eventCategory,eventLabel,eventAction,eventValue) {
      this.googleAnalyticsService.eventEmitter(eventCategory,eventLabel,eventAction,eventValue);
    }
    clearLocalData(){
      localStorage.removeItem("meetingInfo_zoom")
    }

    getTheSyncDetails()
    {
      this.syncService.getSyncSettings().valueChanges.subscribe(({ data }: any) => {
        data.syncSettings.forEach((item,i)=>{
            if(item.serviceProvider == 'SALESFORCE')
            {
              if(item.accessToken && item.refreshToken)
              {
                this.syncService.getSalesforceRefreshToken(item).subscribe((response:any) => {
                  if(response.access_token)
                  {
                    let {access_token} = response
                    let {username,refreshToken} = item
                    localStorage.setItem("saccessToken",access_token)
                    this.syncService.updateSyncSetting(access_token, refreshToken,'',username,'ENABLED','','SALESFORCE').subscribe((response:any) => {
  
                    })
                  }
                })
              }
            }
  
        })
      })
    }
    toggleSideMenu(){
      this.isTeamsOpen = false
      this.isMeetingsOpen = false
    }

    toggleChanged(event)  {
      console.log(event,'event')
      this.toggle = event
    }
}
