<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
   <div class="col-md-12  px-0">
      <div class="col-md-12 p-2">
         <div class="card p-md-1 py-3 my-3 ">
            <div class="py-4 mx-2">
               <ol class="breadcrumb px-3">
                  <li class="breadcrumb-item"><a (click)="gotoOrders()" >Cusomers</a></li>
                  <li class="breadcrumb-item active"  aria-current="page">Create Customer
                  </li>
                 
                  <li
                     class="rightIcon ml-auto"   (click)="gotoOrders()"
                     ><img src="assets/back.svg?x=1" class="arrowIconn"> {{'COMMON.BACK' | translate}}</li>
               </ol>
               <form  [formGroup]="orderForm"
               (ngSubmit)="saveForm()" class="d-flex flex-wrap small-picker">
               <div class="col-md-12 m-auto  py-0" >
                  <div class="d-flex w-100 pt-1 flex-wrap"  >
                     <div class="col-md-12 px-0 d-flex flex-wrap">
                        <div class="divBlk col-md-6" >
                           <label for="firstName"> First Name<span class="man-star d-none">*</span></label>
                           <input type="text" maxlength="150" id="firstName" formControlName="firstName" name="firstName" class="form-control custominp"/>
                        </div>
                        <div class="divBlk col-md-6">
                           <label for="lastName"> Last name</label>
                           <input type="text" maxlength="150" id="lastName" formControlName="lastName" name="lastName" class="form-control custominp"/>
                        </div>
                        <div class="divBlk col-md-6">
                           <label for="phone"> Phone Number<span class="man-star d-none">*</span></label>
                           <input type="text" maxlength="150" id="phone" formControlName="phone" name="phone" class="form-control custominp"/>
                        </div>
                        <div class="divBlk col-md-6">
                           <label for="email"> Email<span class="man-star d-none">*</span></label>
                           <input type="text" maxlength="150" id="email" formControlName="email" name="email" class="form-control custominp"/>
                        </div>
                        <div class="divBlk col-md-6">
                           <label for="city">City<span class="man-star d-none">*</span></label>
                           <input type="text" maxlength="50" id="city" formControlName="city" name="city" class="form-control custominp"/>
                        </div>
                     </div>
                     <div class="divBlk pt-3 col-md-12 text-center" >
                        <input type="submit" class="btn btn-primary mr-2"  value="Create Customer"  />
                     </div>
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>