<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center ">
<div class="col-md-12  px-0">
   <div class="col-md-12 p-2">
      <div class="card p-md-1 py-3 my-3 ">
         <div class="py-4 mx-2">
            <ol class="breadcrumb px-3">
               <li class="breadcrumb-item"><a  (click) ="goToBack()">Products</a></li>
               <li class="breadcrumb-item active" *ngIf="!loading && !productId"  aria-current="page">Add Product</li>
               <li class="breadcrumb-item active" *ngIf="!loading && productId"  aria-current="page">
                  Update Product
               </li>
               <li
                  class="rightIcon ml-auto" (click) ="goToBack()"
                  ><img src="assets/back.svg?x=1" class="arrowIconn"> {{'COMMON.BACK' | translate}}</li>
            </ol>
            <form  [formGroup]="productForm"
            (ngSubmit)="saveProduct()" class="d-flex flex-wrap small-picker">
            <div class="col-md-12 m-auto  py-0" >
               <!-- <h5 class="pl-2">Add Product</h5> -->
               <div class="d-flex w-100 pt-1 flex-wrap"  >
                  <!-- First Block -->
                  <div class="col-md-6 px-0">
                     <div class="divBlk  ">
                        <div class="imgBlock">
                           <h6 class="px-2 pt-4 col-md-12 pb-3"> Upload Up To 5 Photos</h6>
                           <div class="d-flex justify-content-between" >
                              <div class="m-2">
                                 <div  class=" d-flex align-items-center pb-2">
                                    <span>{{variantImages && variantImages.length}}/5</span>
                                 </div>
                              </div>
                              <div>
                                 <img id="blah2" src="{{variantImages[urlIndex] 
                                    ? variantImages[urlIndex].url :'assets/upload.png'}}" width="150px" height="150px" style="object-fit: contain;"  onError="this.src='assets/upload.png'" class="profilepic2 img-preview cursor-pointer object-fit-contain">
                              </div>
                              <div></div>
                           </div>
                           <div class="divBlk d-flex pt-3 ">
                              <div class="block1 img-container " *ngFor="let url of variantImages;let i=index">
                                 <div class="parent2 position-relative">
                                    <label class="snap-images ">
                                    <img id="blah2" src="{{url.url?url.url:'assets//upload.png'}}" (click)="urlIndex=i" width="100%" height="80px" class="profilepic2 img-preview object-fit-contain" style="object-fit: contain;"  >
                                    <input type="file" id="imageId{{i}}" multiple class="upload2 hide gallery-img-file accept-image d-none" (change)="detectFiles($event,i)" *ngIf="!url.variant_id" accept="image/*">
                                    </label>
                                    <div class="child2">
                                       <span class="fa fa-times removebt" (click)="removeImg(i,url.id)"></span>
                                    </div>
                                 </div>
                              </div>
                              <label class="add-image cursor-pointer" *ngIf="variantImages.length<5" id="add-image" (click)="addImage(true);" >
                              <div class="circle-o plusSign  "><span>+</span></div>
                              <h6 class="text-center w-100">Add New</h6>
                              </label>
                           </div>
                        </div>
                     </div>
                     <div class="d-flex col-md-12 justify-content-end">
                        <div class="d-inline">
                           <div class="color-sheet d-block">
                              <div class="d-inline1"><span class="green-blk"></span> - 15% discount</div>
                              <div class="d-inline1"><span class="red-blk"></span> - 10% discount</div>
                            </div>
                        </div>
                     </div>
                    
                        
                     <div class="divBlk " >

                        <label for="productCategory"> Product category<span class="man-star d-none">*</span></label>
                        <!-- <input type="text" maxlength="50" id="brand_name" formControlName="productCategory" name="brand_name" class="form-control custominp"/> -->
                        <select class="form-control" formControlName="productCategory">
                           <option value="{{category.id}}" *ngFor="let category of categoriesList">{{category.name}}</option>
                        </select>
                        <!-- <div *ngIf="submitted && !f.productCategory.value" class="text-danger">
                           Product category is required
                        </div> -->
                     </div> 
                     <div class="divBlk ">
                        <label for="productName"> Product name</label>
                        <input type="text" maxlength="150" id="title" formControlName="productName" name="productName" class="form-control custominp"/>
                        <!-- <div *ngIf="submitted && !f.productName.value"  class="text-danger">
                           <div *ngIf="!f.productName.value">
                              Product name required
                           </div>
                        </div>-->
                     </div>


                     <div class="divBlk " >
                        <label for="supplierInvoiceDate">  Supplier invoice date <span class="man-star d-none">*</span></label>
                        <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                           <input
                           class=" form-control w-100"
                           type="text"
                           value=""
                           id="supplierInvoiceDate"
                           formControlName="supplierInvoiceDate"
                           ngbDatepicker
                           #d2="ngbDatepicker"
                           [placement]="placement"
                           [positionTarget]="buttonEl"
                           (click)="d2.toggle()"
                           />
                        </div>
                       <!-- <div *ngIf="submitted && !f.supplierInvoiceDate.value"  class="text-danger">
                           <div *ngIf="!f.supplierInvoiceDate.value">
                              Supplier invoice date is required
                           </div>
                        </div> -->
                     </div>
                     <div class="divBlk ">
                        <label for="supplierName"> Supplier name<span class="man-star d-none">*</span></label>
                        <input type="text" maxlength="150" id="title" formControlName="supplierName" name="supplierName" class="form-control custominp"
                       
                        
                           />
                       <!-- <div *ngIf="submitted && !f.supplierName.value"  class="text-danger">
                           <div *ngIf="!f.supplierName.value">
                              Supplier name is required
                           </div>
                        </div> -->
                     </div>


                     
                     <div class="divBlk ">
                        <label for="supplierInvoiceNumber"> Supplier invoice number<span class="man-star d-none">*</span></label>
                        <input type="text" maxlength="150" id="supplierInvoiceNumber" formControlName="supplierInvoiceNumber" name="supplierInvoiceNumber" class="form-control custominp"
                       
                        />
                        <!-- <div *ngIf="submitted && !f.supplierInvoiceNumber.value"  class="text-danger">
                           <div *ngIf="!f.supplierInvoiceNumber.value">
                              Supplier invoice number is required
                           </div>
                        </div> -->
                     </div>
                     <div class="divBlk ">
                        <label for="designNumber"> Design number<span class="man-star d-none">*</span></label>
                        <input type="text" maxlength="50" id="designNumber" formControlName="designNumber" name="designNumber" class="form-control custominp"/>
                        <!-- <div *ngIf="submitted && !f.designNumber.value"  class="d-inline-block text-danger">
                           <div *ngIf="!f.designNumber.value">
                              Design number is required
                           </div>
                        </div> -->
                     </div>
                     <div class="divBlk ">
                        <label for="tajNumber">Taj number<span class="man-star">*</span></label>
                        <input type="text" maxlength="50" id="tajNumber" formControlName="tajNumber" name="tajNumber" class="form-control custominp"/>
                        <div *ngIf="submitted && !f.tajNumber.value"  class="d-inline-block text-danger">
                           <div *ngIf="!f.tajNumber.value">
                              Taj Number number is required
                           </div>
                        </div>
                     </div>
                     <div class="divBlk " >
                        <label for="cost"> Cost in USD<span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="cost" name="cost" formControlName="costUSD" class="form-control custominp" (change)="setSellingPrice()" (input)="currencyConverter(1)" 
                          
                           />
                           <span class="currency">USD</span>
                        </div>
                     </div>
                     <div class="divBlk " >
                        <label for="usdAedFx"> USD/AED FX <span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="usdAedFx" name="usdAedFx" formControlName="usdAedFx" class="form-control custominp"  (input)="setAed()"
                          
                            />
                        </div>
                     </div>   
                     <div class="divBlk " >
                        <label for="costAED"> Cost in AED <span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="cost" name="cost" formControlName="costAED" class="form-control custominp my-2"  (change)="setSellingPrice()" (input)="currencyConverter(2)"
                          
                            />
                           <span class="currency">AED</span>
                        </div>
                     </div>
                     <div class="divBlk " >
                        <label for="aedMadFx"> AED/MAD FX <span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" (input)="setMad()" id="aedMadFx" name="aedMadFx" formControlName="aedMadFx" class="form-control custominp"
                          
                             />
                        </div>
                     </div>
                     <div class="divBlk " >
                        <label for="cost"> Cost in MAD<span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="cost" name="cost" formControlName="costMAD" class="form-control custominp" (change)="setSellingPrice()" (input)="currencyConverter(3)"
                          
                            />
                           <span class="currency">MAD</span>
                        </div>
                      <!--   <div *ngIf="submitted && !f.costUSD.value"  class="text-danger d-inline-block">
                           Cost is required
                        </div> -->
                     </div>

                     <div class="divBlk " >
                        <label for="margin"> Margin <span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="margin" name="margin" formControlName="margin" class="form-control custominp"  (input)="setSellingPrice()"
                          
                           />
                           <span class="currency">%</span>
                        </div>
                       <!-- <div *ngIf="submitted && !f.margin.value"  class="text-danger d-inline-block">
                           Margin is required
                        </div> -->
                     </div>
                     <div class="divBlk " >
                        <label for="displayedSellingPriceInMAD"> Displayed selling price <span class="man-star d-none">*</span></label>
                        <div class="position-relative">
                           <input type="text" id="displayedSellingPriceInMAD" name="displayedSellingPriceInMAD" formControlName="displayedSellingPriceInMAD" class="form-control custominp" (input)="setMargin();getDiscountPrice();getImpliedMargin()" />
                           <span class="currency">MAD</span>
                        </div>
                      <!--  <div *ngIf="submitted && !f.displayedSellingPriceInMAD.value"  class="text-danger d-inline-block">
                           Selling Price is required
                        </div> -->
                        <!-- <input type="text" id="displayedSellingPriceInMAD" name="displayedSellingPriceInMAD" formControlName="displayedSellingPriceInMAD"   class="form-control custominp" /> -->
                     </div>
                     <div class="divBlk " >
                        <label for="maximumDiscountAllowed">Maximum discount allowed</label>
                        <div class="position-relative">
                           <input type="text" id="maximumDiscountAllowed" name="maximumDiscountAllowed" formControlName="maximumDiscountAllowed" class="form-control custominp" (input)="getDiscountPrice();getImpliedMargin()" />
                           <span class="currency">%</span>
                        </div>
                        <!-- <input type="text" saveProjectid="maximumDiscountAllowed" name="maximumDiscountAllowed" formControlName="maximumDiscountAllowed"  class="form-control custominp" /> -->
                     </div>
                     <div class="divBlk " >
                        <label for="minimumSellingPriceInMAD">Price after discount </label>

                        <div class="position-relative">
                           <input type="text" id="minimumSellingPriceInMAD" name="minimumSellingPriceInMAD" formControlName="minimumSellingPriceInMAD" (input)="getImpliedMargin()" class="form-control custominp" />
                           <span class="currency">MAD</span>
                          <!-- <div *ngIf="submitted && !f.minimumSellingPriceInMAD.value"  class="text-danger d-inline-block">
                           Minimum Selling Price is required
                        </div> -->
                        </div>
                     </div>
                    
                     <!-- <div class="divBlk ">
                        <label for="itemNumber"> Item number<span class="man-star d-none">*</span></label>
                        <input type="text" maxlength="350" id="subject" formControlName="itemNumber" name="subject" class="form-control custominp" />
                        <div *ngIf="submitted && !f.itemNumber.value"  class="text-danger d-inline-block">
                           <div *ngIf="!f.itemNumber.value">
                              Item Number is required
                           </div>
                        </div>
                     </div> -->
                     <!-- <div class="divBlk ">
                        <label for="description"> Product description<span class="man-star d-none">*</span></label>
                        <textarea  id="description" rows="5" maxlength="" name="description" formControlName="description" class="form-control custominp" ></textarea>
                        <div *ngIf="submitted && !f.description.value"  class="text-danger d-inline-block">
                           Description is required
                        </div>
                     </div> -->
                  </div>
                  <div class="col-md-6 px-0">
                     <div class="divBlk " >
                        <label for="impliedMinimumSellingPriceMargin"> Margin after discount</label>
                        <div class="position-relative">
                           <input type="text" id="impliedMinimumSellingPriceMargin" name="impliedMinimumSellingPriceMargin" formControlName="impliedMinimumSellingPriceMargin" class="form-control custominp"
                          
                            />
                           <span class="currency">%</span>
                        </div>
                       
                     </div>
                     <!-- <div class="divBlk " >
                        <label for="sellingPrice"> Final selling price</label>
                        <input type="text" id="sellingPrice"  name="sellingPrice" formControlName="sellingPrice" class="form-control custominp" />
                        </div> -->
                     
                     <div class="divBlk " >
                        <label for="stockQuantity"> Number of items <span class="man-star d-none">*</span></label>
                        <!-- <input type="text" id="boostProduct" name="boostProduct" class="form-control custominp" value=""> -->
                        <input type="text" id="stockQuantity" name="stockQuantity" formControlName="stockQuantity" class="form-control custominp" />
                       <!-- <div *ngIf="submitted && !f.stockQuantity.value"  class="text-danger pl-2">
                           <div *ngIf="!f.stockQuantity.value">
                              Quantity is required.
                           </div> 
                        </div> -->
                     </div>
                     <div class="divBlk " >
                        <label for="dateImport">StayTouch Import date</label>
                        <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                           <input
                           class=" form-control w-100"
                           type="text"
                           value=""
                           id="dateImport"
                           formControlName="dateImport"
                           ngbDatepicker
                           #d="ngbDatepicker"
                           [placement]="placement"
                           [positionTarget]="buttonEl"
                           (click)="d.toggle()"
                           />
                        </div>
                     <!-- <div class="divBlk " style="padding-top: 22px;">
                        <label for="title"> Colored stones type 1 <span class="man-star d-none">*</span></label>
                        <select class="form-control" formControlName="productCategory">
                           <option value="{{category.id}}" *ngFor="let category of coloredStonesTyeps">{{category}}</option>
                        </select>
                        <div *ngIf="submitted && !f.productCategory.value"  class="text-danger">
                           <div *ngIf="!f.productCategory.value">
                              Product category is required
                           </div>
                        </div>
                     </div> -->
                    
                  </div>
                        <div class="divBlk " >
                           <label for="goldColor"> Gold Color<span class="man-star d-none">*</span></label>
                           <select class="form-control" formControlName="goldColor">
                              <option value="{{category}}" *ngFor="let category of goldColors">{{category}}</option>
                           </select>
                         <!--  <div *ngIf="submitted && !f.goldColor.value"  class="text-danger">
                              <div *ngIf="!f.goldColor.value">
                                Gold is required
                              </div>
                           </div> -->
                        </div>
                        <div class="divBlk " >
                           <label for="goldWeight"> Gold weight<span class="man-star d-none">*</span> </label>
                           <div class="position-relative">
                              <input type="text" id="goldWeight" name="cosgoldWeightt" formControlName="goldWeight" class="form-control custominp" />
                              <span class="currency"> grams</span>
                            <!--  <div *ngIf="submitted && !f.goldWeight.value"  class="text-danger">
                              <div *ngIf="!f.goldWeight.value">
                                Gold weight is required
                              </div>  
                           </div> -->
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="goldPriceUsd"> Gold Price<span class="man-star d-none">*</span> </label>
                           <div class="position-relative">
                              <input type="text" id="goldPriceUsd" name="goldPriceUsd" formControlName="goldPriceUsd" class="form-control custominp" />
                              <span class="currency"> USD</span>
                        
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="goldInvoiceNumber"> Gold Invoice Number</label>
                           <div class="position-relative">
                              <input type="text" id="goldInvoiceNumber" name="goldInvoiceNumber" formControlName="goldInvoiceNumber" class="form-control custominp"  />
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="diamondWeight"> Diamond weight<span class="man-star d-none">*</span> </label>
                           <div class="position-relative">
                              <input type="text" id="diamondWeight" name="diamondWeight" formControlName="diamondWeight" class="form-control custominp"  />
                              <span class="currency"> ct</span>
                            <!--  <div *ngIf="submitted && !f.diamondWeight.value"  class="text-danger">
                              <div *ngIf="!f.diamondWeight.value">
                                Diamond weight is required
                              </div> 
                           </div> -->
                           </div>
                        </div>
                       
                        <div class="divBlk " >
                           <label for="cost"> Number of diamond stones</label>
                           <input type="text" id="numberOfDiamondStones" name="numberOfDiamondStones" formControlName="numberOfDiamondStones" class="form-control custominp"  />
                          
                        </div>
                        <div class="divBlk " >
                           <label for="diamondPriceUsd"> Diamond Price <span class="man-star d-none">*</span> </label>
                           <div class="position-relative">
                              <input type="text" id="diamondPriceUsd" name="diamondPriceUsd" formControlName="diamondPriceUsd" class="form-control custominp"  />
                              <span class="currency"> USD</span>
                          
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesType1"> Colored stones type 1 </label>
                           <select class="form-control" formControlName="coloredStonesType1">
                              <option value="{{category}}" *ngFor="let category of coloredStonesTyeps">{{category}}</option>
                           </select>
                         
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesWeight1"> Colored stones weight 1</label>
                           <div class="position-relative">
                              <input type="text" id="coloredStonesWeight1" name="coloredStonesWeight1" formControlName="coloredStonesWeight1" class="form-control custominp"  />
                              <span class="currency"> ct</span>
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesType2"> Colored stones type 2 </label>
                           <select class="form-control" formControlName="coloredStonesType2">
                              <option value="{{category}}" *ngFor="let category of coloredStonesTyeps">{{category}}</option>
                           </select>
                           
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesWeight2"> Colored stones weight 2</label>
                           <div class="position-relative">
                              <input type="text" id="coloredStonesWeight2" name="coloredStonesWeight2" formControlName="coloredStonesWeight2" class="form-control custominp"  />
                              <span class="currency"> ct</span>
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesType3"> Colored stones type 3 </label>
                           <select class="form-control" formControlName="coloredStonesType3">
                              <option value="{{category}}" *ngFor="let category of coloredStonesTyeps">{{category}}</option>
                           </select>
                           
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesWeight3"> Colored stones weight 3</label>
                           <div class="position-relative">
                              <input type="text" id="coloredStonesWeight3" name="coloredStonesWeight3" formControlName="coloredStonesWeight3" class="form-control custominp"  />
                              <span class="currency"> ct</span>
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="coloredStonesPriceUsd"> Colored Stones Price</label>
                           <div class="position-relative">
                              <input type="text" id="coloredStonesPriceUsd" name="coloredStonesPriceUsd" formControlName="coloredStonesPriceUsd" class="form-control custominp"  />
                              <span class="currency"> USD</span>
                           </div>
                        </div>
                        <div class="divBlk " >
                           <label for="makingChargesUsd">  Making Charges</label>
                           <div class="position-relative">
                              <input type="text" id="makingChargesUsd" name="makingChargesUsd" formControlName="makingChargesUsd" class="form-control custominp"  />
                              <span class="currency"> USD</span>
                           </div>
                        </div>
                       
                        <div class="divBlk " >
                           <label for="size">Product size</label>
                           <input type="text" id="size" name="size" formControlName="size" class="form-control custominp" />
                        </div>
                        <div class="divBlk " >
                           <label for="comment"> Comment</label>
                           <input type="text" id="comment" name="comment"  formControlName="comment" class="form-control custominp" />
                        </div>
                        <div class="divBlk " >
                           <label for="productLocation"> Product location <span class="man-star d-none">*</span></label>
                           <!-- <input type="text" id="productLocation" name="productLocation"  formControlName="productLocation" class="form-control custominp" /> -->
                           <select class="form-control" formControlName="productLocation">
                              <option value="{{category}}" *ngFor="let category of locationArray">{{category}}</option>
                           </select>
                        <!-- <div *ngIf="submitted && !f.productLocation.value"  class="text-danger">
                              <div *ngIf="!f.productLocation.value">
                                Product location is required
                              </div>
                           </div> -->
                        </div>
                        <!-- 
                           <div class="divBlk ">
                             <label for="company"> Company <span class="man-star d-none">*</span></label>
                             <input type="text" id="company" name="company" formControlName="company" class="form-control custominp" />
                           
                           </div>
                           <div *ngIf="submitted && !f.company.value"  class="text-danger pl-2">
                             <div *ngIf="!f.company.value">
                                Company is required.
                             </div>
                           </div> -->
                        <!-- <div class="divBlk " >
                           <label for="otherFieldX"> Other field X</label>
                           <input type="text" id="otherFieldX" name="otherFieldX" formControlName="otherFieldX" class="form-control custominp" />
                           </div>
                           <div class="divBlk " >
                           <label for="otherFieldY"> Other field Y</label>
                           <input type="text" saveProjectid="otherFieldY" name="otherFieldY" formControlName="otherFieldY" class="form-control custominp" />
                           </div> -->
                        <!-- <div class="divBlk " >
                           <label for="other"> Other</label>
                           <input type="text" id="links" name="other" formControlName="other" class="form-control custominp" />
                           </div> -->
                        <!-- <div class="divBlk " >
                           <label for="dateImport"> Date of import</label>
                           
                           <div class="col-md-12 px-0 col-lg-12 d-flex w-100">
                            <input
                            class=" form-control w-100"
                            type="text"
                            value=""
                            id="dateImport"
                            formControlName="dateImport"
                            ngbDatepicker
                            #d="ngbDatepicker"
                            [placement]="placement"
                            [positionTarget]="buttonEl"
                            (click)="d.toggle()"
                            />
                           
                           </div>
                           </div> -->
                        <!-- <div class="divBlk " >
                           <label for="links"> QR Code </label>
                           <input type="text" id="links" name="links" formControlName="links" class="form-control custominp" />
                           </div> -->
                        <div class="divBlk pt-3" >
                           <label for="links"> Assign to team members </label>
                           <!-- <input type="text" id="boostProduct" name="boostProduct" class="form-control custominp" value=""> -->
                           <div class="teams-dp inner-addon right-addon position-relative">
                              <img
                                 src="assets/icon_search.svg"
                                 alt="Plus"
                                 height="20px"
                                 class="position-absolute"
                                 style="top: 8px;right: 10px;z-index:1"
                                 />
                              <ngx-select
                              name="users"
                              [allowClear]="true"
                              [multiple]="true"
                              [keepSelectedItems]="false"
                              [items]="members"
                              optionValueField="userId"
                              optionTextField="firstName"
                              formControlName="users"
                              placeholder="+ Invite Members"
                              class="theme-select team-select"
                              >
                              <ng-template
                                 ngx-select-option
                                 ngx-select-option-selected
                                 let-option
                                 let-text="text"
                                 >
                                 <div class="d-flex text-left dropdown-custom">
                                    <img
                                       src="assets/default.jpg"
                                       class="borderImage1 border-501"
                                       height="25px"
                                       width="auto"
                                       />
                                    <div>
                                       <p class="mb-0">{{option.data.firstName}} {{option.data.lastName}}</p>
                                    </div>
                                 </div>
                              </ng-template>
                              <ng-template ngx-select-option-not-found let-input>
                                 {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
                              </ng-template>
                              </ngx-select>
                           </div>
                        </div>
                        <!-- <div class="text-right pt-3 pr-1">
                           <a href="#" style="text-decoration: underline;">Add More Labels</a>
                           
                           </div> -->
                        <!-- form-row Variant 1 end.// -->
                     <!-- Second Block -->
                  </div>
                  <div class="divBlk pt-3 col-md-12 text-center" >
                     <input type="submit" class="btn btn-primary mr-2"  value="Save Product" *ngIf="!productId" />
                     <input type="submit" class="btn btn-primary" value="Update Product" *ngIf="productId" /> 
                  </div>
               </div>
            </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
