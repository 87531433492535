import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router,ActivatedRoute,Params } from "@angular/router"
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { MemberUser } from '../../../user/models/member-user'
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AppState, selectMemberAuth } from '../../../user/store/app.states'
import { MemberLogIn, MemberLogOut, MemberStatus } from '../../../user/store/actions/member.auth.actions'
import {SyncService} from '../../../user/services/sync.service'
import { accountInfo } from '../../../user/services/user.gql'
import { Apollo, QueryRef } from 'apollo-angular'
import { LoginService } from '../../../user/services/login-service';
import { HelperService } from '../../../user/services/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = null
  loading:boolean = false
  getState: Observable<any>
  isAuthenticated: boolean = null
  errorMessage: string | null
  withEmail:boolean = true
  user: MemberUser = null
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  submitted:boolean = false
  syncUpdateStaus:boolean = false
  accountInformation:any ={}
  private query: QueryRef<any>
  private query$:any
show2MFa:any 
authcode:any =''
checkedStatus:boolean = false
id:any
  getLoginDetails() {
    this.submitted = true;
    let phonenumber =''
    // stop here if form is invalid
    if(this.withEmail)
    {
      if(this.loginEmail.value == '')
      {
        return
      }
    }
    if(!this.withEmail)
    {
      if(this.loginPhone.value == '')
      {
        return
      }
      phonenumber =`${this.loginPhone.value.dialCode}${this.loginPhone.value.number}`
      phonenumber = phonenumber.split('-').join('')
      phonenumber = phonenumber.split(' ').join('')
    }
    if(this.loginForm.invalid) {
        return;
    }
    this.loading = true
    this.user = new MemberUser({id: 1, email: this.loginEmail.value, password: this.loginPassword.value  ,
    phone:phonenumber})
  	const payload = {
      email: this.user.email,
      password: this.user.password,
      phone: this.user.phone
    };
    this.store.dispatch(new MemberLogIn(payload))
  }

  constructor(private store: Store<AppState>, private router: Router, private syncService:SyncService,private apollo: Apollo,private _loginService: LoginService,private helperService:HelperService, private activatedRoute:ActivatedRoute) { 
    
  	this.getState = this.store.select(selectMemberAuth)
    this.store.dispatch(new MemberStatus)

    this.activatedRoute.params.subscribe((params: Params) => {
      
      if (params.id) {
        this.id = params.id
      }
      // window.history.pushState({}, document.title, "/search" );
    })
  }

  ngOnInit() {
    this.errorMessage = ''
    this.loginForm = new FormGroup({
      loginEmail: new FormControl('', [Validators.email]),	  	// loginEmail: new FormControl(''),
	  	// loginPassword: new FormControl('',Validators.minLength(5)),
	  	loginPhone: new FormControl('',[Validators.minLength(10),Validators.maxLength(15)]),
	  	loginPassword: new FormControl('', [Validators.required]),
      isRememberMe: new FormControl(false),
      
    })

  	this.getState.subscribe((state) => {
      this.loading = false
      let expiry = localStorage.getItem('expiryTime')
      let role = localStorage.getItem('role')
      // alert()
      if (state.memberAuth.isAuthenticated) {
        setTimeout(()=>{

          if(localStorage.getItem('isTempPassword') == 'true')
          {
            // alert(3)
            localStorage.setItem('verified',window.btoa("true"))
            this.router.navigate(['/reset-password'])
            return false
          }
          else if(localStorage.getItem('b2bPhoneVerified')== "true")
          {
            this.getTheAccountInfo()
            //get and update the salesforce oauth token
            // if(!this.syncUpdateStaus)
            // {
            //   this.syncUpdateStaus = true
            //   this.getTheSyncDetails()
            // }
            
            
            return false
          }
          else if(localStorage.getItem('role') == "ADMIN" && localStorage.getItem('b2bPhoneVerified') == "false" && localStorage.getItem("phone")!='null')
          {
            this.router.navigate(['/company-verification'])
          }
          else
          {
            if(localStorage.getItem('token'))
            {
              //get and update the salesforce oauth token
              // if(!this.syncUpdateStaus)
              // {
              //   this.syncUpdateStaus = true
              //   this.getTheSyncDetails()
              // }
              this.getTheAccountInfo()
              // this.router.navigate(['/products'])
            }
          }
        })
      }
      else{
        // console.log('coming else')
      }
      this.isAuthenticated = state.memberAuth.isAuthenticated;
      this.user = state.memberAuth.user;
      if(this.loginEmail.value)
      {
        this.errorMessage = state.memberAuth.errorMessage;
      }
     
      // if(this.withEmail && this.loginEmail) 
      // {
      //   this.errorMessage = state.memberAuth.errorMessage;
      // }
      // else if(!this.withEmail) {
      //   this.errorMessage = 'Incorrect phone number and/or password.'
      // }
      // if(this.loginEmail)
      // {
      //   this.errorMessage = state.memberAuth.errorMessage;
      // }
    });
  }
  get loginEmail() { return this.loginForm.get('loginEmail') }
  get loginPassword() { return this.loginForm.get('loginPassword') }
  get loginPhone() { return this.loginForm.get('loginPhone') }

  getTheSyncDetails()
  {
    // if(!this.syncUpdateStaus)
    // {
    //   return false
    // }
    // this.syncUpdateStaus= false 
    this.syncService.getSyncSettings().valueChanges.subscribe(({ data }: any) => {
      data.syncSettings.forEach((item,i)=>{
          if(item.serviceProvider == 'SALESFORCE')
          {
            if(item.accessToken && item.refreshToken)
            {
              this.syncService.getSalesforceRefreshToken(item).subscribe((response:any) => {
                if(response.access_token)
                {
                  let {access_token} = response
                  let {username,refreshToken} = item
                  localStorage.setItem("saccessToken",access_token)
                  this.syncService.updateSyncSetting(access_token, refreshToken,'',username,'ENABLED','','SALESFORCE').subscribe((response:any) => {

                  })
                }
              })
            }
          }

      })
    })
  }

  getTheAccountInfo(){
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      variables: {},
      fetchPolicy:"network-only"
    })
    this.query$ = this.query.valueChanges.subscribe(({ data }: any) => {
      this.loading=false
      this.accountInformation = data.accountInformation
      // console.log(this.accountInformation)
      const {email, firstName, lastName, id} = this.accountInformation
      localStorage.setItem('email',email)
      localStorage.setItem('firstName',firstName)
      localStorage.setItem('lastName',lastName)
      localStorage.setItem('id',id)
     if(!this.checkedStatus)
     {
        this.checkedStatus = true
        let permissions = this.helperService.getPermissions(data)
        localStorage.setItem('verified',window.btoa("false"))

        if(localStorage.getItem('role') != "ADMIN" && (!this.accountInformation.twoFactor || permissions && permissions.company.mfa_compulsory == "false"))
        {
          localStorage.setItem('verified',window.btoa("true"))
          if(this.id){
            this.router.navigate(['/search',this.id])
          }
          else{
            this.router.navigate(['/products'])
          }
          
        }
        else if(localStorage.getItem('role') == "ADMIN" && !this.accountInformation.twoFactor)
        {
          localStorage.setItem('verified',window.btoa("true"))
          if(this.id){
            this.router.navigate(['/search',this.id])
          }
          else{
            this.router.navigate(['/products'])
          }
        }
        else{
          this.show2MFa = true
        }
     }
     if(!this.syncUpdateStaus)
     {
       this.syncUpdateStaus = true
       this.getTheSyncDetails()
     }
    
    })
  }
  verifyGoogleCode(){
      this._loginService.verifyAuth(this.authcode).subscribe((data) => {
        const result = data.body
        if (result['status'] === 200) {
          this.router.navigate(['/products'])
          localStorage.setItem('verified',window.btoa("true"))        
        } else {
          this.errorMessage = result['message'];
        }
      });
  
  }
  clearLogin(){
    localStorage.clear()
    this.show2MFa= false
  }
}
