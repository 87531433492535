import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { UserModule } from './user/user.module'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// language transaltion module
import { NgxSelectModule } from 'ngx-select-ex'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
 import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
 import { DragDropModule} from '@angular/cdk/drag-drop';
import { NgDragDropModule } from 'ng-drag-drop';
import { HttpClient} from '@angular/common/http'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { NgxIntlTelInputModule } from './internal-modules/ngx-intl-tel-input'
import { NgSelectModule,NgSelectConfig,ɵs } from '@ng-select/ng-select';

import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { GraphQLModule } from '../app/shared/graphql.module'
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { LoginComponent } from './shared/components/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';

import { OwnerRegistrationComponent } from './shared/components/owner-registration/owner-registration.component';
// import { SentryErrorHandler } from '../app/user/services/error.service';
import { OwnerVerificationComponent } from './shared/components/owner-verification/owner-verification.component';
import { OwnerLoginComponent } from './shared/components/owner-login/owner-login.component';
// import { CompanyVerificationComponent } from './src/app/shared/components/company-verification/company-verification.component';
import {GoogleAnalyticsService} from "./shared/google-analytics.service";
import { VerifyAndMergeComponent } from './shared/components/verify-and-merge/verify-and-merge.component';
import { MainLoadingComponent } from './shared/components/main-loading/main-loading.component'; // import our Google Analytics service

// import { AngMusicPlayerModule } from  'ang-music-player';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/user/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    OwnerRegistrationComponent,
    OwnerVerificationComponent,
    OwnerLoginComponent,
    VerifyAndMergeComponent,
    MainLoadingComponent,
    // VerifyMfaComponent,
    // CompanyVerificationComponent,
    // ButtonDirective
    //AppPasswordDirective,
    // LoadingComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    // NgOptionHighlightModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    // AdminModule,
    UserModule,
    GraphQLModule,
    MalihuScrollbarModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    // TranslateModule.forRoot({})
    // AngMusicPlayerModule,


    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      // isolate:false
    })

  ],
  providers: [ 
    GoogleAnalyticsService,
    NgSelectConfig,
    // ɵs
    // {
    // provide: ErrorHandler,
    // useClass: SentryErrorHandler,
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
