// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment:any = {
  production: false,
  // BASE_URL: 'https://api.staytouch.com/v2.1/users/graphql',
  // AUTH_URL: 'https://api.staytouch.com/v2.1/public/',
  BASE_URL: 'https://api.staytouch.com/v2.1/users/graphql',
  AUTH_URL: 'https://api.staytouch.com/v2.1/public/',
  AUTH_TOKEN: 'vf7P7GwvN0l91hfOoABioEZ59NoME9CG',
  xsecuritytoken: "JzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ikpva",
  xframetoken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
  secretkey:"$taytoush@12345$",
  firebase: {
    apiKey: "AIzaSyCDSSkvMHy4Q3udMYjkbR1L3VcsJqg_cXQ",
    authDomain: "staytouch-prod.firebaseapp.com",
    databaseURL: "https://staytouch-prod.firebaseio.com",
    projectId: "staytouch-prod",
    storageBucket: "staytouch-prod.appspot.com",
    messagingSenderId: "646309031313",
    appId: "1:646309031313:web:03980c84d297c56d04be30",
    measurementId: "G-EE4ZJMJRT3"
  },
  ZOOM_URL: "https://meeting.staytouch.com/api",
  // ZOOM_URL: "http://localhost:4000",
  //API_URL: "http://localhost:4000",
   API_URL: "https://meeting.staytouch.com/api",
  // ZOOM_URL: "https://meeting.staytouch.com/api",
  ZOOM_REDIRECT_URL:"https://localhost:4200/zoom-connect",
  // ZOOM_REDIRECT_URL:"https://corporate.staytouch.com/zoom-connect",
  // ZOOM_REDIRECT_URL:"http://localhost:4000",
  OUTLOOK_REDIRECT_URL:"http://localhost:4200/syncronizations",
  // OUTLOOK_REDIRECT_URL:"https://meeting.staytouch.com/api",
  OUTLOOK_URL: "https://meeting.staytouch.com/api",
 // OUTLOOK_URL: "http://localhost:4000",
  OUTLOOK_CLIENTID: "361adb98-77c8-44b4-8f15-23e845ba80c2",
  OUTLOOK_SCOPE: "openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite%20Calendars.Read%20Calendars.Read.Shared%20Calendars.ReadWrite.Shared%20offline_access%20User.Read%20Contacts.Read%20Contacts.Read.Shared%20Contacts.ReadWrite%20Contacts.ReadWrite.Shared%20User.ReadBasic.All",
  OUTLOOK_IMPORT_REDIRECTION_URL:  "https://localhost:4200/import-contacts",


  // OUTLOOK_SCOPE: "openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite",


  TEAMS_REDIRECT_URL: "https://localhost:4200/syncronizations",
  TEAMS_URL: "http://localhost:4000",
  TEAMS_CLIENTID: "77bf8215-edac-46b7-98eb-7ee7b51c0c16",
  TEAMS_SCOPE: "openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite",

  GOOGLE_ENDPOINT : 'https://accounts.google.com/o/oauth2/auth?',
  GOOGLE_CLIENT_ID : '787250207098-ppja89j2eoupt74l1fno8tapv9q3uu09.apps.googleusercontent.com',
  GOOGLE_SCOPE : "email profile openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/calendar.events",
  GOOGLE_IMPORT_REDIRECTION_URL:  "https://localhost:4200/import-contacts",


  OFFICE365_REDIRECT_URL: "http://localhost:4200/syncronizations",
  OFFICE365_URL: "https://meeting.staytouch.com/api",
  OFFICE365_CLIENTID: "361adb98-77c8-44b4-8f15-23e845ba80c2",
  OFFICE365_SECRET_KEY: "-v1f8xyTf.U6P9b5_c85liS.11S.d_pQw4",
  OFFICE365_SCOPE: "openid%20profile%20offline_access%20User.Read%20Calendars.ReadWrite%20Contacts.ReadWrite%20Calendars.Read%20Calendars.Read.Shared%20Calendars.ReadWrite.Shared%20offline_access%20User.Read%20Contacts.Read%20Contacts.Read.Shared%20Contacts.ReadWrite%20Contacts.ReadWrite.Shared%20User.ReadBasic.All",
  CWG_ID: "113",
  HAVEA_ID: "219"


  // CWG_ID: "176"
  // CWG_ID: "175"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
