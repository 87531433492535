<div class="row justify-content-center " >
    <div class="col-md-12 p-2">
        <div class="row my-3">

     
            <div class="col-xl-4 col-md-6 mb-4" >
              <div class="card border-left-primary shadow h-100 ">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="text-xs st-font1 h6 font-weight-bold text-primary  mb-4 w-100">Number of Members</div>

                    <div class="col mr-2">
                      <div class="h4 mb-0 font-weight-bold text-gray-800">{{totalNumberOfUsers}}</div>
                    </div>
                    <div class="col-auto">
                      <svg style="color: #0C77EB;width:45px;height:auto;" aria-hidden="true" class="text-primary" focusable="false" data-prefix="fal" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-users fa-w-20 fa-7x"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z" class=""></path></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 mb-4">
              <div class="card border-left-primary shadow h-100 ">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="text-xs st-font1 font-weight-bold text-primary  mb-4 w-100 h6">Contacts (Average)</div>

                    <div class="col mr-2">
                      <div class="h4 mb-0 font-weight-bold text-gray-800">{{totalContactsCount}}</div>
                    </div>
                    <div class="col-auto">
                      <svg style="color: #09BDE3;width:45px;height:auto;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="address-card" class="svg-inline--fa fa-address-card fa-w-18" role="img" viewBox="0 0 576 512" ><path fill="currentColor" d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H48V80h480v352zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2zM360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         
          
            <div class="col-xl-4 col-md-6 ">
              <div class="card shadow mb-4 ">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">General Information</h6>
                </div>
                <div class="card-body py-3">
                  <h6><b>Account Status</b> - {{account && account.accountStatus}}</h6>
                  <h6 class=" pt-1"><b>Current Plan</b> - {{account && account.organizationPlan}}</h6>
                  <h6 class=" pt-1 font-weight-bold"
                  *ngIf="isAdmin || (permissions && permissions.account.usage_storage_overall == 'true')"
                  >Space -  <span class="w-100 font-weight-normal" *ngIf="account">{{totalSpaceInGb}} GB /{{totalSpaceAllocated}} GB ({{totalspacePercentage}}% )
                  </span></h6>
                 
                  <div class="progress  mt-1">
                  <div  class="progress-bar" role="progressbar" [ngStyle]="{'width':totalspacePercentage + '%'}"   aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
            
          
          </div>
          <div class="d-flex flex-wrap">

          

            

            <div class="col-md-12 px-0">
              <div class="card  mb-4 h-100 ">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">MEMBERS INFORMATION</h6>
                </div>
                <div class="card-body">
                    <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered hover">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th> Total Contacts</th>
                          <th *ngIf="isAdmin || (permissions && permissions.account.usage_storage_per_member == 'true')" >Space Usage</th>
                          <th *ngIf="isAdmin || (permissions && permissions.account.most_commonly_used_features == 'true')">Most Used Feature</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="members.length>0">
                        <tr *ngFor="let member of members">
                          <td>{{member.fullName}}</td>
                          <td>{{member.contactCount}}</td>
                          <ng-container *ngIf="isAdmin || (permissions && permissions.account.usage_storage_per_member == 'true')" >
                            <td *ngIf="member.spaceInKb < 1000">{{roundTheValue(member.spaceInKb)}} KB</td>
                            <td *ngIf="member.spaceInKb >= 1000 && member.spaceInKb < 1000000">{{roundTheValue(member.spaceInKb * 0.001)}} MB</td>
                            <td *ngIf="member.spaceInKb >= 1000000 ">{{roundTheValue(member.spaceInKb * 0.000001)}} GB</td>
                         </ng-container>
                          <td  *ngIf="isAdmin || (permissions && permissions.account.most_commonly_used_features == 'true')">{{member.mostUsedFeature}}</td>
                       </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
            <div class="col-md-12 px-0 d-flex">
              <div class="col-md-8 mt-4"  *ngIf="isAdmin || (permissions && permissions.account.company_performance == 'true')">
                <div class="card  mb-4 h-100 ">
                  <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary text-uppercase">Performance</h6>
                  </div>
                  <div class="card-body">
                      <!-- <canvas baseChart 
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [legend]="barChartLegend"
                      [chartType]="barChartType">
                    </canvas> -->
                    <div class="card-body">
                      <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered hover">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th> Meetings</th>
                            <th> Contacts</th>
                        
                          </tr>
                        </thead>
                        <tbody *ngIf="performanceInfo && performanceInfo.length>0">
                          <tr *ngFor="let company of performanceInfo">
                            <td>{{company.companyName}}</td>
                            <td>{{company.numberofMeetings}}</td>
                            <td>{{company.numberOfContacts}}</td>
                           
                         </tr>
                        </tbody>
                      </table>
                  </div>
                  </div>
                </div>
              
              
            
              </div>
              <div class="col-md-4 mt-4 pr-0"  *ngIf="isAdmin || (permissions && permissions.account.company_performance == 'true')">
                <div class="card  mb-4 h-100 ">
                  <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary text-uppercase">Other Information</h6>
                  </div>
                  <div class="card-body" *ngIf="account">
                    <h6 *ngIf="isAdmin || (permissions && permissions.account.total_number_of_seats == 'true')" ><b>Total number of seats</b> - {{ account.organizationUsers}}</h6>
                    <h6 *ngIf="isAdmin || (permissions && permissions.account.seats_outstanding == 'true')"><b>Seats outstanding</b> - {{account.organizationUsers - totalNumberOfUsers}}</h6>


                  </div>
                </div>
              
              
            
         
              </div>
            </div>
            <!-- <div class="col-md-4 pr-0">
              <div class="card  mb-4 h-100">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">STATISTICS</h6>
                </div>
                <div class="card-body">
                 
                 
                </div>
              </div>
            </div> -->
        </div>

    </div>
</div>

