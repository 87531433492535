  import gql from 'graphql-tag';

export const contactsQuery = gql`
query contacts($limit: Int, $offset: Int,$id:[ID], $userIdOfContact:[ID!],$keywords: String,$hashTags: String,$searchByName: String,$company:String , $reminderDate:String,$orderByName:ContactSortOrder,$userId: String,$userIds: [ID],$sortByCompany:ContactSortOrder,$onlyNonApp:Boolean){
    contacts(limit: $limit,offset: $offset, id:$id, userIdOfContact:$userIdOfContact,keywords:$keywords,hashTags:$hashTags,searchByName:$searchByName, company:$company, reminderDate:$reminderDate,orderByName:$orderByName,userId: $userId,userIds:$userIds,sortByCompany:$sortByCompany,onlyNonApp:$onlyNonApp){
      id
      company
      userIdOfContact
      firstName
      lastName
      nonAppContact
      pending
      keywords
      hashTags
      displayImageURL
      displayImagePutURL
      rating
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
      contactReceiverStatus
      importService
      manuallyCreated
      googleTag
      sent{
        profileId
        profileVersion
      }
      importedData { key title value format }
      received  {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      hubspotId
      googleId
      connectionsCount
      meetingCount
      category
      interests
      family
      skip
    }
  }
`;

export const pendingRequestsQuery = gql`
query contacts{
    contacts(pending:true){
      id
      company
      userIdOfContact
      firstName
      lastName
      favorite
      nonAppContact
      pending
      createdAt
      updatedAt
      importService
       importedData { key title value format }
       received {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      sent{
        profileId
        profileVersion
      }
    }
  }
`;
export const getAllContactIds = gql`
query contacts($userId:String,$userIds:[ID]){
    contacts(userId:$userId,userIds:$userIds){
      id
      userIdOfContact
      importService
      importId
    }
  }
`;

export const contactDetailsQuery = gql`
query contacts($userIdOfContact: [ID!], $id :[ID],$limit: Int, $offset: Int){
    contacts(userIdOfContact:$userIdOfContact,id:$id, limit:$limit, offset: $offset){
      id
      company
      userIdOfContact
      firstName
      lastName
      nonAppContact
      pending
      keywords
      hashTags
      displayImageURL
      displayImagePutURL
      rating
      sent{
        profileId
        profileVersion
      }
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
      contactReceiverStatus
      manuallyCreated
      importService
      importedData { key title value format }
       received {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      category
      interests
      family
    }
  }
`;

export const createcontactMutation = gql`
mutation XBVCWTVILM($firstName: String!, $lastName: String!,$data:[UserDataInput],$rating:Int,$keywords: String,$hashTags: String,$reminder :Boolean,$reminderMessage :String ,$reminderDate : DateTime,$reminderFrequency:reminderFrequencyDuration,$favorite:Boolean,$hubspotId: String,$googleId: String,$googleTag: String,$category:String,$interests:String,$family:String,$skip:Boolean) {
  XBVCWTVILM(nonAppContact:{firstName: $firstName,lastName: $lastName, manuallyCreated:true,data:$data,rating:$rating,keywords: $keywords, hashTags: $hashTags,reminder: $reminder,reminderMessage:$reminderMessage,reminderDate : $reminderDate,reminderFrequency:$reminderFrequency,favorite:$favorite,hubspotId: $hubspotId,googleId: $googleId,googleTag:$googleTag,category:$category,interests:$interests,family:$family,skip:$skip}){
    id
    company
    userIdOfContact
    firstName
    lastName
    nonAppContact
    pending
    keywords
    hashTags
    displayImageURL
    displayImagePutURL
    rating
    hubspotId
    googleId
    sent{
      profileId
      profileVersion
    }
    reminder
    reminderMessage
    reminderDate
    reminderFrequency
    contactReceiverStatus
    manuallyCreated
    importService
    googleTag
    importedData { key title value format }
    received {
      displayImageURL
      data{
        key
        format
        value
        title
      }
    }
    category
    interests
    family

    }
  }
`;

export const updatecontactMutation = gql`
mutation NJVXHBHRMM($firstName: String, $lastName: String,$data:[UserDataInput],$id: ID!,$rating:Int,$keywords: String,$hashTags: String,$reminder :Boolean,$reminderMessage :String ,$reminderDate : DateTime,$reminderFrequency:reminderFrequencyDuration,$hubspotId: String,$googleId: String,$googleTag: String,$category:String,$interests:String,$family:String,$skip:Boolean){
  NJVXHBHRMM(nonAppContact:{firstName: $firstName,lastName: $lastName, manuallyCreated:true,data:$data,id:$id,rating:$rating,keywords: $keywords, hashTags: $hashTags,reminder: $reminder,reminderMessage:$reminderMessage,reminderDate : $reminderDate,reminderFrequency:$reminderFrequency,hubspotId: $hubspotId,googleId: $googleId,googleTag:$googleTag,category:$category,interests:$interests,family:$family,skip:$skip}){
    id
    company
      userIdOfContact
      firstName
      lastName
      nonAppContact
      pending
      keywords
      hashTags
      displayImageURL
      displayImagePutURL
      rating
      sent{
        profileId
        profileVersion
      }
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
      contactReceiverStatus
      manuallyCreated
      importService
      importedData  { key title value format }
      googleTag
      skip
      received {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      hubspotId
      googleId
      category
      interests
      family
    }
  }
`;

export const convertToAppContactMutation = gql`
mutation FIQMDLMJOO($nonAppContactId: ID!, $sharedProfileIds: [ID!]!,$shareByEmail:String,$shareBySMS:String){
  FIQMDLMJOO(nonAppContactId: $nonAppContactId,sharedProfileIds: $sharedProfileIds,shareByEmail:$shareByEmail,shareBySMS:$shareBySMS){
    id
    company
    userIdOfContact
    firstName
    lastName
    nonAppContact
    pending
    keywords
    hashTags
    displayImageURL
    displayImagePutURL
    rating
    sent{
      profileId
      profileVersion
    }
    reminder
    reminderMessage
    reminderDate
    reminderFrequency
    contactReceiverStatus
    manuallyCreated
    importService
    importedData { key title value format }
     received {
      displayImageURL
      data{
        key
        format
        value
        title
      }
    }
    category
    interests
    family
    }

  }
`;

export const updateAppContactMutation = gql`
mutation HJUQYJNENV($id: ID!,$rating:Int,$sharedProfileIds:[ID!],$keywords: String,$hashTags: String,$reminder :Boolean,$reminderMessage :String ,$reminderDate : DateTime,$reminderFrequency:reminderFrequencyDuration,$category:String,$interests:String,$family:String){
  HJUQYJNENV(contact:{id:$id,rating:$rating,receiveNotification: false, sharedProfileIds:$sharedProfileIds,keywords: $keywords, hashTags: $hashTags,reminder: $reminder,reminderMessage:$reminderMessage,reminderDate : $reminderDate,reminderFrequency:$reminderFrequency,category:$category,interests:$interests,family:$family}){
    id
    company
      userIdOfContact
      firstName
      lastName
      nonAppContact
      pending
      keywords
      hashTags
      displayImageURL
      displayImagePutURL
      rating
      sent{
        profileId
        profileVersion
      }
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
      contactReceiverStatus
      manuallyCreated
      importedData  { key title value format }
      importService
      received {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      category
      interests
      family
    }
  }
`;



export const createAppContactMutation = gql`
mutation TNTVQGQNZQ($rating:Int,$sharedProfileIds:[ID!],$keywords: String,$hashTags: String,$reminder :Boolean,$reminderMessage :String ,$reminderDate : DateTime,$reminderFrequency:reminderFrequencyDuration,$receiverId:ID!,$category:String,$interests:String,$family:String){
  TNTVQGQNZQ(contact:{rating:$rating,receiveNotification: false, sharedProfileIds:$sharedProfileIds,keywords: $keywords, hashTags: $hashTags,reminder: $reminder,reminderMessage:$reminderMessage,reminderDate : $reminderDate,reminderFrequency:$reminderFrequency,receiverId:$receiverId,category:$category,interests:$interests,family:$family}){
    id
    company
      userIdOfContact
      firstName
      lastName
      nonAppContact
      pending
      keywords
      hashTags
      displayImageURL
      displayImagePutURL
      rating
      sent{
        profileId
        profileVersion
      }
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
      contactReceiverStatus
      manuallyCreated
      importService
      importedData  { key title value format }
      received {
        displayImageURL
        data{
          key
          format
          value
          title
        }
      }
      category
      interests
      family
    }
  }
`;



export const contactNotesQuery = gql`
query contactNotes($userIdOfContact: ID!){
  contactNotes(userIdOfContact: $userIdOfContact){
    id
    type
    userId
    eventId
    profileId
    nonAppContactId
    userIdOfContact
    contents
    size
    signedPutURL
    signedGetURL
    createdAt
  }
}
` 
export const createImageNoteMutation= gql`
mutation  LRWMQOZELA($userIdOfContact: ID,$eventId: ID,$numberOfImages:Int){
  LRWMQOZELA( userIdOfContact: $userIdOfContact,eventId:$eventId,numberOfImages:$numberOfImages){
    id
    type
    userId
    eventId
    profileId
    nonAppContactId
    userIdOfContact
    filename
    extension
    updatedAt
    isTranscribed
    transcribedText
    transcribedLanguage
    transcribedTime
    audioTitle
    contents
    size
    signedPutURL
    signedGetURL
    createdAt
  }
}`

export const createAudioNoteMutation= gql`
mutation  FXRYHAFUHV($userIdOfContact: ID,$eventId: ID,$numberOfAudioClips:Int, $transcribe: Boolean){
  FXRYHAFUHV( userIdOfContact: $userIdOfContact,eventId:$eventId,numberOfAudioClips:$numberOfAudioClips,transcribe:$transcribe){
    id
    type
    userId
    eventId
    profileId
    nonAppContactId
    userIdOfContact
    filename
    extension
    updatedAt
    isTranscribed
    transcribedText
    transcribedLanguage
    transcribedTime
    audioTitle
    contents
    size
    signedPutURL
    signedGetURL
    createdAt
  }
}`


export const createFileNoteMutation= gql`
mutation  createFileNote($userIdOfContact: ID,$eventId: ID,$numberOfAudioClips:Int, $fileName: String,$extension:String){
  createFileNote( userIdOfContact: $userIdOfContact,eventId:$eventId,numberOfAudioClips:$numberOfAudioClips,extension:$extension,fileName:$fileName){
    id
    type
    userId
    eventId
    profileId
    nonAppContactId
    userIdOfContact
    filename
    extension
    updatedAt
    isTranscribed
    transcribedText
    transcribedLanguage
    transcribedTime
    audioTitle
    contents
    size
    signedPutURL
    signedGetURL
    createdAt
  }
}`


export const updateAttachmentMutation= gql`
mutation  updateAttachment($attachmentId: ID,    $filename: String
  $extension: String,
  $audioTitle: String,
  $transcribedText: String,
  $isTranscribed: Boolean,
  $transcribedLanguage: String,
  $transcribedTime: DateTime){
  updateAttachment( attachmentId: $attachmentId,attachment:{
    filename: $filename,
    extension: $extension
    audioTitle: $audioTitle
    transcribedText: $transcribedText
    isTranscribed: $isTranscribed
    transcribedLanguage: $transcribedLanguage
    transcribedTime: $transcribedTime
  })
}`
export const createTextNoteMutation= gql`
mutation  GMAYDKOKIZ($userIdOfContact: ID,$eventId: ID,$contents: String){
  GMAYDKOKIZ( userIdOfContact: $userIdOfContact,eventId:$eventId, contents:$contents){
    id
    contents
    updatedAt
    isTranscribed
    transcribedText
    transcribedLanguage
    transcribedTime
    audioTitle
  
  }
}`

export const deleteNonAppContact = gql`
mutation DLZWRNPAFG($nonAppContactIds: [ID!]! ){
  DLZWRNPAFG(nonAppContactIds: $nonAppContactIds){
    id
  }
}
`
export const deletAppContactMutation =  gql `
mutation KYDGDRKIOF($userIdOfContact:ID!,$deleteType:ContactDeleteType!){
  KYDGDRKIOF(appContact: {
    userIdOfContact:$userIdOfContact,deleteType:$deleteType
  }){
    id
  }
}`;
export const deleteAttachmentMutation = gql `
mutation EIFKBOAPHT($id: [ID!]!){
  EIFKBOAPHT(id: $id){
    id
  }
}
`

export const createNonAppContactImage =  gql`
mutation GKNBGARYMB($nonAppContactId:ID!){
  GKNBGARYMB(nonAppContactId: $nonAppContactId){
    id
    type
    eventId
    profileId
    nonAppContactId
    userIdOfContact
    signedPutURL
    signedGetURL
  }
}`

export const createConferenceContactMutation =  gql`
mutation ZTTHZHGJDB($eventId:ID!, $participantIds: [ID!]!){
  ZTTHZHGJDB(conferenceContact: {
    eventId: $eventId
    participantIds: $participantIds
  }){
    id
    userIdOfContact
    firstName
    lastName
    favorite
    receiveNotification
    nonAppContact
    manuallyCreated
    pending
    importedData  { key title value format }
    received {
      displayImageURL
      data{
        key
        format
        value
        title
      }
    }
    convertedToAppContact
  }
}`

export const acceptAppContactMutation =  gql`
mutation MMNMGEOKHR($userIdOfContact:ID!){
  MMNMGEOKHR(userIdOfContact: $userIdOfContact){
      id
    userIdOfContact
}
}`
export const rejectAppContactMutation =  gql`
mutation PKREWCPZJV($userIdOfContact:ID!){
  PKREWCPZJV(userIdOfContact: $userIdOfContact){
      id
}
}`

export const deleteContactGroup = gql`
  mutation PTHTTWFFNI($id:[ID!]!) {
    PTHTTWFFNI(groupIds:$id) 
  }
`;



export const updateContactGroup = gql`
mutation NENECEJUGB($name: String!, $contactIds:[ID!],$contactGroupId:ID!) {
  NENECEJUGB(contactGroup:{name: $name, contactIds: $contactIds,contactGroupId:$contactGroupId}) {
    contactGroupId
    name
    contactIds
    createdAt
    updatedAt
  }
}
`;


export const contactGroups = gql`
query contact_groups {
  contact_groups{
    contactGroupId
    name
    contactIds
  
  }
}
`;

export const contactGroupAddContactMutation = gql`
mutation ZOOFDUIWHZ($contactGroupId:ID!,$contactIds:[ID!]!){
  ZOOFDUIWHZ(contactGroup: {
    contactGroupId:$contactGroupId,
    contactIds: $contactIds
  }){
    contactGroupId
    name
    contactIds
    createdAt
    updatedAt
  }
}`

export const contactGroupRemoveContactMutation = gql`
mutation CKHFTABDII($contactGroupId:ID!,$contactIds:[ID!]!){
  CKHFTABDII(contactGroup: {
    contactGroupId:$contactGroupId,
    contactIds: $contactIds
  }){
    contactGroupId
    name
    contactIds
    createdAt
    updatedAt
  }
}`


export const contactsLessInfoQuery = gql`
query contacts($limit: Int, $offset: Int,$id:[ID], $userIdOfContact:[ID!],$keywords: String,$hashTags: String,$searchByName: String,$company:String ,$orderByRating:ContactSortOrder,$userId:String){
  contacts(limit: $limit,offset: $offset, id:$id, userIdOfContact:$userIdOfContact,keywords:$keywords,hashTags:$hashTags,searchByName:$searchByName, company:$company,orderByRating:$orderByRating,userId:$userId){

    id
    company
    userIdOfContact
    firstName
    lastName
    importService
    importId
    googleId
    googleTag
    hubspotId
    received{
      profileVersion
      profileId
      data{
        key
        title
        value
        format
      }
      displayImageURL
    }
    importedData  { key title value format }
    rating
    displayImageURL
    nonAppContact
  }
}`
export const reminderContactssQuery = gql`
query contacts($limit: Int, $offset: Int,$id:[ID], $userIdOfContact:[ID!],$keywords: String,$hashTags: String,$searchByName: String,$company:String , $reminderDate:String){
    contacts(limit: $limit,offset: $offset, id:$id, userIdOfContact:$userIdOfContact,keywords:$keywords,hashTags:$hashTags,searchByName:$searchByName, company:$company, reminderDate:$reminderDate){
      id
      userIdOfContact
      firstName
      lastName
      reminder
      reminderMessage
      reminderDate
      reminderFrequency
     
      
    }
  }
`;


export const importVCardContactsMutation =  gql`
mutation ZTEDWTJDAT($data:String){
  ZTEDWTJDAT(data: $data)
}`


export const importNonAppContactMutation =  gql`
mutation QQTWPUVLOA($jobId:String!, $service: ImportService!, $contacts : [ImportContactInput!]!){
  QQTWPUVLOA(jobId: $jobId, service: $service, contacts: $contacts){
    jobId
    jobType
    totalTasks
    successfulTasks
    failedTasks
    pendingTasks
  }
}`

export const getJobStatusQuery = gql`
query getJobStatus($jobId:String!){
  getJobStatus(jobId: $jobId,, jobType: "IMPORT_NON_APP_CONTACT"){
    jobId
    jobType
    totalTasks
    successfulTasks
    failedTasks
    pendingTasks
    }
  }
`;


export const pendingContactRequestsQuery = gql`
query contacts{
    contacts(pending:true){
      id
    }
  }
`;


export const getCompanyQuery = gql`
query  getCompany($name: String){
  getCompany(name: $name) {
    id
    name
    website
  }
}
`;


export const createCompanyMutation = gql`
mutation  LQODCXPIZG($name:String!, $orgId: ID!,$website: String){
  LQODCXPIZG(company:{
    name: $name, orgId:$orgId, website:$website  })} `


export const addContactGroup = gql`
mutation JILMEPQKFJ($name: String!, $contactIds:[ID!]) {
  JILMEPQKFJ(contactGroup:{name: $name, contactIds: $contactIds}) {
    contactGroupId
    name
    contactIds
    createdAt
    updatedAt
  }
}
`;
export const mergeCompanyMutation = gql`
mutation mergeCompany($company: String!, $contactIds:[String]) {
  mergeCompany(company: $company, contactIds: $contactIds) 
}
`;

export const deleteCompanyMutation = gql`
mutation deleteCompany($id: String!) {
  deleteCompany(id: $id) 
}
`;
