<!-- The addContact Group modal -->
<div class="modal" id="qrCodeModal">
        <div class="modal-dialog  modal-dialog-centered">
           <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                 <h5 class="px-4">QR Code</h5>
                 <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
              </div>
              <!-- Modal body -->
              <div class="modal-body pt-2 px-5 ">
                 <div class="row justify-content-center m-0" *ngIf="myAngularxQrCode">
                    <!-- {{myAngularxQrCode}} -->
                        <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                 </div>
              </div>
              <div id="print-section" class="p-3">
                 
              </div>
           </div>
        </div>
     </div>
