import { Component, OnInit, ViewChild,OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
import { ToastrService } from 'ngx-toastr'
import { teamsQuery, deleteTeamMutation,makeManagerMutation } from '../team.gql'
import { ElementRef } from '@angular/core'
import { membersBasicQuery } from '../../../services/admin.gql'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import {GoogleAnalyticsService} from "../../../../shared/google-analytics.service"; // import our analytics service
import { HelperService } from '../../../services/helper.service'
import { ItemsList } from '@ng-select/ng-select/lib/items-list'

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {

  @ViewChild('closebutton', { static: false }) closebutton
  @ViewChild('addTeamButton', { static: false }) addTeamButton
  @ViewChild('myElem', { static: false }) MyProp: ElementRef

  myElement: ElementRef
  editOrAdd: boolean = false
  teamDetails = {}
  currentState = null
  toggleAddTeam = false
  teams: any = []
  members: any = []
  ngxDisabled: boolean = false
  ngxMembersValue: any = []
  selectedTeamCreateProfile: string = '1'
  tNSortClicked: boolean = false
  isTeamNameSort: boolean = false
  nMSortClicked: boolean = false
  isNumberMembersSort: boolean = false
  isMemberSort: boolean = false
	query$:any
  private queryTm: QueryRef<any>
  private membersQuery: QueryRef<any>
  public teamId: any
  permissionsRes:any ={}
  permissions:any = ""
  isAdmin:boolean = false
  // modal form variables
  firstname = null
  lastname = null
  email = null
  phonenumber = null
  isDropdown: boolean = false
  public loading: boolean = true
  userRole:any = "Member"
  currentTeamId:any
  accountInfo:any = {}
  constructor (
    private apollo: Apollo,
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private translate: TranslateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private helperService:HelperService
  ) {
   
    this.isAdmin = this.helperService.isAdmin()
    router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        //console.log(val)
      }
    })
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state !== undefined) {

      this.currentState = this.router.getCurrentNavigation().extras.state
      //console.log(this.currentState,'te')
      if (this.currentState.toggleAddTeam == true) {
        this.toggleAddTeam = true

        // this.myElement.nativeElement.getElementById('addTeamDiv').scrollIntoView({behavior: 'smooth'});
        setTimeout(() => {
          const elmnt = document.getElementById('addTeamDiv') as HTMLElement
          elmnt.scrollIntoView()
        }, 500)
        //this.openAddTeam('','target');
      }
    }
    this.permissionsRes =  this.helperService.getAccountInforamtion()
    this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
      // console.log(data,'data')
      this.accountInfo = data.accountInformation
       this.permissions = this.helperService.getPermissions(data)
        // console.log(this.permissions)
       this.userRole = this.permissions.role
    })
  }
  /**************/
  ngOnInit () {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.teamId) {
        this.teamId = parseInt(params.teamId)
        this.teamsInfo(this.teamId)
      }
    })

    // this.getLocation()
  
  }
  openAddTeam (team, el: HTMLElement) {
    this.SendEvent('createTeam','teams','click',1)

    this.teamDetails = {}
    this.toggleAddTeam = !this.toggleAddTeam
    this.editOrAdd = true
    el.scrollIntoView({ behavior: 'smooth' })
  }

  closeAddTeam (teamId) {
    this.teamsInfo(this.teamId)
    if(teamId){
      this.currentTeamId = teamId
    } 
     this.toggleAddTeam = !this.toggleAddTeam
    this.editOrAdd = false
    const elmnt1 = document.getElementById(`accordion`) as HTMLElement
    if(elmnt1)
    {
      elmnt1.click()
      elmnt1.scrollIntoView()
    }
   
  }

  editTeam (team, el: HTMLElement) {
    this.toggleAddTeam = true
    this.editOrAdd = false
    //console.log(team, 'team')
    this.teamDetails = team
    el.scrollIntoView({ behavior: 'smooth' })
  }

  sortTeamName () {
    this.tNSortClicked = true
    this.nMSortClicked = false
    this.isTeamNameSort = !this.isTeamNameSort
    if (this.isTeamNameSort) {
      this.teams.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      this.teams.sort((a, b) => b.name.localeCompare(a.name))
    }
  }

  sortMemberName () {
    this.isMemberSort = !this.isMemberSort
    if (this.isMemberSort) {
      this.members.sort((a, b) => a.firstName.localeCompare(b.firstName))
    } else {
      this.members.sort((a, b) => b.firstName.localeCompare(a.firstName))
    }
  }

  sortNumberMembers () {
    this.tNSortClicked = false
    this.nMSortClicked = true

    this.isNumberMembersSort = !this.isNumberMembersSort
    if (this.isNumberMembersSort) {
      this.teams.sort((a, b) => a.memberIds.length - b.memberIds.length)
    } else {
      this.teams.sort((a, b) => b.memberIds.length - a.memberIds.length)
    }
  }

  /**************/

  getLocation () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const longitude = position.coords.longitude
        const latitude = position.coords.latitude
        this.callApi(longitude, latitude)
      })
    } else {
      console.log('No support for geolocation')
    }
  }

  callApi (Longitude: number, Latitude: number) {
    const url = `http://www.mapquestapi.com/geocoding/v1/address?key=FsW4zPNITAJCPYTPDSNTjt7sgKEdGGWj&location=${Latitude},
    ${Longitude}&includeRoadMetadata=true&includeNearestIntersection=true`
    this.http.get<any>(url).subscribe(
      data => {
        //console.log(data, 'data')
      },
      error => {}
    )
  }

 
  teamsInfo(teamId){
    this.queryTm = this.apollo.watchQuery({
      query: teamsQuery,
      variables: {
        teamId:teamId
      },
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

    this.query$ = this.queryTm.valueChanges.subscribe(
      ({ data, loading }) => {
        this.teams = data.teams
        this.loading = false
        if(this.teams[0])
        {
          this.getMemberDetails( this.teams[0]) 
        }
        // this.getMemberDetails( this.teams[0]) 
        // this.teams.sort((a, b) => a.title.localeCompare(b.title))
      },
      error => {
        this.loading = false
        console.log('there was an error sending the mutation', error)
      }
    )
  }
  inviteMember () {
    this.translate
      .get('MESSAGES.SUCCESSFULLY_INVITE_THE_MEMBER')
      .subscribe((res: string) => {
        this.toastr.info(res, null, {})
      })
    this.closebutton.nativeElement.click()
  }
  getMemberDetails (team) {
    console.log('com')
    this.toggleAddTeam = false;
    this.members=[]
    this.loading = true
    this.membersQuery = this.apollo.watchQuery({
      query: membersBasicQuery,
      variables: {
        userIds: team.memberIds
      },
      errorPolicy: 'all',
      fetchPolicy:"network-only"
    })

    this.membersQuery.valueChanges.subscribe(
      ({ data, loading }) => {
        //console.log(data)
        this.loading = false
        if (data.users) {
          this.members = data.users
          this.members &&
            this.members.forEach((user, j) => {
              //this.members[j].title='--'
              this.members[j].profilePic =  user.profiles[0] && user.profiles[0].displayImageURL
              user.profiles[0] &&
                user.profiles[0].data.forEach((item, i) => {
                  if (item.format == 'JOB_TITLE') {
                    this.members[j].title = item.value ? item.value : '-'
                  }
                  if (item.format == 'DEPARTMENT') {
                    this.members[j].department = item.value ? item.value : '-'
                  }
                 
                })
            })
            //console.log(this.members)
        }
      },
      error => {
        this.loading = false
      }
    )
  }
  deleteTeam (teamId) {
    this.translate
      .get('MESSAGES.ARE_YOU_SURE_DO_YOU_WANT_TO_DELETE_THIS_TEAM')
      .subscribe((res: string) => {
        if (!confirm(res)) {
          return false
        }
        else{
          this.apollo
          .mutate({
            mutation: deleteTeamMutation,
            variables: {
              teamIds: [parseInt(teamId)]
            },
            update: (proxy, { data: { teamsDelete } }: any) => {
              // console.log(teamsDelete)
              // Read the data from our cache for this query.
              let data: any = proxy.readQuery({ query: teamsQuery })
              data = data.teams.filter(item => item.teamId !== teamId)
              proxy.writeQuery({ query: teamsQuery, data: { teams: data } })
            }
          })
          .subscribe(
            ({ data }) => {
              this.toggleAddTeam = false
              this.translate
                .get('MESSAGES.TEAM_DELETED_SUCCESSFULLY')
                .subscribe((res: string) => {
                  this.toastr.info(res)
                })
            },
            error => {
              //  this.deleteTeam(teamId)
              this.toastr.info('Error while deleting the team.')
              //console.log('there was an error sending the mutation', error);
            }
          )
        }
    })
   
  }
  profileCreateSend(){
    
  }
  makeManager(teamId,memberId,manager,i){
    console.log(i)
    this.loading = true
    this.apollo
    .mutate({
      mutation: makeManagerMutation,
      variables: {
        teamId: teamId,
        memberId:memberId,
        manager:manager
      }
    })
    .subscribe(
      ({ data }) => {
        this.loading = false
        console.log(this.teams[i])
        if(manager){
          this.teams[i].managers.push(memberId)
        }
        else{
          this.teams[i].managers = this.teams[i].managers.splice(i,1)
        }
        console.log(this.teams[i].managers)
        this.toastr.info("Updated the user role.")

      },
      error => {
        this.loading = false
      }
    )
  }

  SendEvent(eventCategory,eventLabel,eventAction,eventValue) {
    this.googleAnalyticsService.eventEmitter(eventCategory,eventLabel,eventAction,eventValue);
  }
  isManger(managers,userId){
    return managers.includes(userId)
  }
 
  ngOnDestroy(){
    //this.query.unsubscribe();
    this.query$ ? this.query$.unsubscribe() : "";
  }

}
