import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transcribe-modal-container',
  templateUrl: './transcribe-modal-container.component.html',
  styleUrls: ['./transcribe-modal-container.component.scss']
})
export class TranscribeModalContainerComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }


  ngOnInit() {
  }
  passBack(type) {
    this.activeModal.close('Close click')
    this.passEntry.emit(type);
   }

}
