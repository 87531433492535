import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from "@angular/router"
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { MemberUser } from '../../../user/models/member-user'
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AppState, selectMemberAuth } from '../../../user/store/app.states'
import { MemberLogIn, MemberLogOut, MemberStatus } from '../../../user/store/actions/member.auth.actions'
@Component({
  selector: 'app-owner-login',
  templateUrl: './owner-login.component.html',
  styleUrls: ['./owner-login.component.scss']
})
export class OwnerLoginComponent implements OnInit {

  loginForm = null
  getState: Observable<any>
  isAuthenticated: boolean = null
  errorMessage: string | null
  withEmail:boolean = true
  user: MemberUser = null
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  submitted:boolean = false
  getLoginDetails() {
    this.submitted = true;
    let phonenumber =''
    // stop here if form is invalid
    if(this.withEmail)
    {
      if(this.loginEmail.value == '')
      {
        return
      }
    }
    if(!this.withEmail)
    {
      if(this.loginPhone.value == '')
      {
        return
      }
      phonenumber =`${this.loginPhone.value.dialCode}${this.loginPhone.value.number}`
    }
    if(this.loginForm.invalid) {
        return;
    }

    this.user = new MemberUser({id: 1, email: this.loginEmail.value, password: this.loginPassword.value  ,
    phone:phonenumber})
  	const payload = {
      email: this.user.email,
      password: this.user.password,
      phone: this.user.phone
    };
    this.store.dispatch(new MemberLogIn(payload))
  }

  constructor(private store: Store<AppState>, private router: Router) { 
  	this.getState = this.store.select(selectMemberAuth)
    this.store.dispatch(new MemberStatus)
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      loginEmail: new FormControl('', [Validators.email]),	  	// loginEmail: new FormControl(''),
	  	// loginPassword: new FormControl('',Validators.minLength(5)),
	  	loginPhone: new FormControl('',[Validators.minLength(10),Validators.maxLength(15)]),
	  	loginPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
      isRememberMe: new FormControl(false),
      
    })

  	this.getState.subscribe((state) => {
      console.log(state)
      let expiry = localStorage.getItem('expiryTime')
      let role = localStorage.getItem('role')
      if (state.memberAuth.isAuthenticated) {
		  this.router.navigate(['/products'])

      }
      this.isAuthenticated = state.memberAuth.isAuthenticated;
      this.user = state.memberAuth.user;
      if(this.withEmail)
      {
        this.errorMessage = state.memberAuth.errorMessage;
      }
      else if(!this.withEmail) {
        this.errorMessage = 'Incorrect phone number and/or password.'
      }
    });
  }
  get loginEmail() { return this.loginForm.get('loginEmail') }
  get loginPassword() { return this.loginForm.get('loginPassword') }
  get loginPhone() { return this.loginForm.get('loginPhone') }

}
