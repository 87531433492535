<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3 pb-5 my-3 ">
        <app-account></app-account>
        <app-loading *ngIf="loading"></app-loading>

        <div class="col-sm-12 col-md-12 col-lg-12  pt-3">
          <!-- <h5 class="card-title text-center"> {{'HEADER.RESET_PASSWORD' | translate}}</h5> -->
          <div *ngIf="errorMessage">
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <form
            class="form-signin d-flex flex-wrap col-lg-5 col-md-8 mx-auto"
            [formGroup]="resetPasswordForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="form-label-group1 col-md-12 px-0">
              <label _ngcontent-aru-c9="" for="inputEmail">{{ 'HEADER.OLD_PASSWORD' | translate }}</label>
              <div class="position-relative">
                <input
                  type="password"
                  id="oldPassword"
                  formControlName="oldPassword"
                  class="form-control custominp1"
                
                  autocomplete="off"
                  appAppPassword
                />
              </div>
             
              <div *ngIf="submitted && f.oldPassword.errors" class="text-danger pl-3 pt-1">
                <div *ngIf="f.newPassword.errors.required">
                  {{ 'HEADER.PASSWORD_IS_REQUIRED' | translate }}
                </div>
                <div *ngIf="f.newPassword.errors.minlength">
                  {{
                    'HEADER.PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate
                  }}
                </div>
              </div>
            </div>
           
            <div class="form-label-group1 col-md-12 my-3 px-0">
              <label _ngcontent-aru-c9="" for="inputEmail">{{ 'HEADER.NEW_PASSWORD' | translate }}</label>
              <div class="position-relative">
              <input
                type="password"
                id="newPassword"
                formControlName="newPassword"
                class="form-control custominp1"
                autocomplete="off"
                appAppPassword
              />
              </div>
              <!-- <label for="inputEmail">Email address</label> -->
              <div *ngIf="submitted && f.newPassword.errors" class="text-danger pl-3 pt-1">
                <div *ngIf="f.newPassword.errors.required">
                  {{ 'HEADER.PASSWORD_IS_REQUIRED' | translate }}
                </div>
                <div *ngIf="f.newPassword.errors.minlength">
                  {{
                    'HEADER.PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate
                  }}
                </div>
              </div>
            </div>
           
            <div class="form-label-group1 col-md-12 px-0">
              <label _ngcontent-aru-c9="" for="inputEmail">{{ 'HEADER.CONFIRM_PASSWORD' | translate }}</label>
              <div class="position-relative">
              <input
                type="password"
                id="confirmPassword"
                formControlName="confirmPassword"
                class="form-control custominp1"
                appAppPassword
              />
              </div>
              <!-- <label for="inputPassword">Password</label> -->
              <div
              *ngIf="submitted && f.confirmPassword.errors"
              class="text-danger pl-3 pt-1"
            >
              <div *ngIf="f.confirmPassword.errors.required">
                {{ 'HEADER.CONFIRM_PASSWORD_IS_REQUIRED' | translate }}
              </div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">
                {{ 'HEADER.PASSWORDS_MUST_MATCH' | translate }}
              </div>
            </div>
            </div>
             <div class="text-center col-md-12 px-0 py-3">
              <button
                class="btn btn-lg btn-primary btn-block text-uppercase"
                type="submit"
              >
                {{ 'HEADER.UPDATE_PASSWORD' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
