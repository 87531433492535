import { Component, OnInit,OnDestroy } from '@angular/core'
import { Apollo, QueryRef } from 'apollo-angular'
import { performanceAnalyticsQuery } from '../../../services/user.gql'
import {  Subject } from 'rxjs';
var moment = require('moment-timezone')

@Component({
  selector: 'app-company-meetings',
  templateUrl: './company-meetings.component.html',
  styleUrls: ['./company-meetings.component.scss']
})
export class CompanyMeetingsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
 // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
   dtTrigger = new Subject<any>();
  public member: any={}
  meetings: any
  userId: number
  private query: QueryRef<any>
  meetingId: number
  isOpen: boolean
  contacts: any =[]
  loading: boolean = false
  url: any = ''
  meetingsCount = 0
  contactsCount = 0
  query$:any
  queryPr$:any
  meetingQuery$:any
  contactsQuery$:any
  allMeetingQuery$:any
  performanceInfo:any
  members:any =[]

  constructor (
    private apollo: Apollo,
  ) {
    
  }

  ngOnInit () {
    /** Get today reminders **/
    this.performanceAnalytics()
  }

  
 
  
  
  performanceAnalytics(){
    this.loading
    let contactsArray:any =[]
    let meetingsArray:any = []
    this.query = this.apollo.watchQuery({
      query: performanceAnalyticsQuery,
      fetchPolicy:"network-only"
    })
    this.query.valueChanges.subscribe(result => {
      this.performanceInfo = result.data.performanceAnalytics
      this.performanceInfo.forEach(element => {
        // this.barChartLabels.push(element.companyName)
        contactsArray.push(element.numberOfContacts)
        this.meetingsCount = this.meetingsCount + element.numberOfContacts
        this.contactsCount = this.contactsCount + element.numberofMeetings
        // meetingsArray.push(element.numberofMeetings)
        // meetingsArray.push(8)
      });
      

      this.loading = false
    },error => {
      this.loading = false
    })
  }

 
  
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : "";
    this.queryPr$ ? this.queryPr$.unsubscribe() : "";
    this.meetingQuery$ ? this.meetingQuery$.unsubscribe() : "";
    this.contactsQuery$ ? this.contactsQuery$.unsubscribe() : "";
    this.allMeetingQuery$ ? this.allMeetingQuery$.unsubscribe() : "";
  }


}
