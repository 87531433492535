
<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center" >
  <div class="col-md-12 p-2 py-4 ">
    <div class="card p-md-3 py-3  ">
       <div class="d-flex justify-content-between align-items-center pb-3 px-2">
         <h4 > {{ orderInfo && orderInfo.actionType ==  'ORDER' ? 'Order Details' : 'Sale Details' }}</h4>
            <div class=" " >
            
              <a (click)="gotoOrders()" class=" ml-3 pt-2 default-color" title="Edit" ><img _ngcontent-gmb-c13="" class="arrowIconn" src="assets/back.svg"> Back </a>
            </div>
       </div>
       
       
               <div class="card">
                  <div class="row">
                     <aside class="col-sm-5 border-right">
                        <article class="gallery-wrap">
                          
                           <div *ngIf="galleryImages" class="d-flex ml-3 py-3">
                              <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
                           </div>
                        </article>
                        <!-- gallery-wrap .end// -->
                     </aside>
                     <aside class="col-sm-7" *ngIf="orderInfo">
                        <article class="card-body px-2 p-md-5">
                         
                           <dl class="param param-feature" *ngIf="orderInfo.product && orderInfo.product.productName">
                              <dt>Product name</dt>
                              <dd class="text-capitalize" >{{orderInfo.product.productName}}</dd>
                           </dl>
                         <!-- item-property-hor .// -->
                         <dl class="param param-feature" *ngIf="orderInfo.product.productCategory && orderInfo.product.productCategory.name">
                           <dt>Product category</dt>
                           <dd  >{{orderInfo.product.productCategory.name}}</dd>
                        </dl>
                           <!-- item-property-hor .// -->
                           <dl class="param param-feature">
                              <dt>Taj number</dt>
                              <dd >{{orderInfo.product.tajNumber}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.customerOrderNumber">
                              <dt>{{ orderInfo.actionType ==  'ORDER' ? 'Order number' : 'Sale number' }}</dt>
                              <dd >{{orderInfo.customerOrderNumber}}</dd>
                           </dl>
                           <dl class="param param-feature">
                              <dt>Customer name</dt>
                              <dd >{{orderInfo.firstName}} {{orderInfo.lastName}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.email">
                              <dt>Email</dt>
                              <dd > {{orderInfo.email}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.phone">
                              <dt>Phone number</dt>
                              <dd>{{orderInfo.phone}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.updatedAt">
                              <dt>{{ orderInfo.actionType ==  'ORDER' ? 'Order date' : 'Sale date' }}</dt>
                              <dd>{{ orderInfo.updatedAt  | amDateFormat: 'DD-MM-YYYY' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.city">
                              <dt>City</dt>
                              <dd >{{orderInfo.city}}</dd>
                           </dl>
                        
                           <dl class="param param-feature" *ngIf="orderInfo.ringSize">
                              <dt> Size</dt>
                              <dd >{{orderInfo.ringSize}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.goldColor">
                              <dt>Gold Color</dt>
                              <dd >{{orderInfo.goldColor}}</dd>
                           </dl>
                           <!-- <dl class="param param-feature" *ngIf="orderInfo.quantity">
                              <dt>Quantity</dt>
                              <dd >{{orderInfo.quantity}}</dd>
                           </dl> -->
                           <dl class="param param-feature" *ngIf="orderInfo.actualSellingPrice">
                              <dt>Actual selling price</dt>
                              <dd >{{orderInfo.actualSellingPrice}} MAD</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.actualSellingPrice">
                              <dt>Avoir / Held Amount</dt>
                              <dd >{{orderInfo.heldAmount ? orderInfo.heldAmount : 0}} MAD</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.actualSellingPrice">
                              <dt>Amount to be collected</dt>
                              <dd >
                                 {{orderInfo.amountToCollect ? orderInfo.amountToCollect : orderInfo.actualSellingPrice }} MAD
                              </dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.paymentMethod">
                              <dt>Payment method</dt>
                              <dd >{{orderInfo.paymentMethod}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.cashAmount && (orderInfo.paymentMethod == 'CASH' ||  orderInfo.paymentMethod == 'CHEQUE_AND_CASH')">
                              <dt>Cash amount</dt>
                              <dd >{{orderInfo.cashAmount}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.numberOfCheques && (orderInfo.paymentMethod == 'CHEQUE' ||  orderInfo.paymentMethod == 'CHEQUE_AND_CASH')">
                              <dt>Number of cheques</dt>
                              <dd >{{orderInfo.numberOfCheques}}</dd>
                           </dl> 
                           <ng-container *ngIf="orderInfo.paymentMethod == 'CHEQUE' ||  orderInfo.paymentMethod == 'CHEQUE_AND_CASH'">
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate1">
                                 <dt>Cheque date 1</dt>
                                 <dd >  {{ orderInfo.chequeDate1  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate1Amount">
                                 <dt>Cheque Amount 1</dt>
                                 <dd >  {{ orderInfo.chequeDate1Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate2">
                                 <dt>Cheque date 2</dt>
                                 <dd >  {{ orderInfo.chequeDate2  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate2Amount">
                                 <dt>Cheque Amount 2</dt>
                                 <dd >  {{ orderInfo.chequeDate2Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate3">
                                 <dt>Cheque date 3</dt>
                                 <dd >  {{ orderInfo.chequeDate3  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl>
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate3Amount">
                                 <dt>Cheque Amount 3</dt>
                                 <dd >  {{ orderInfo.chequeDate3Amount }} </dd>
                              </dl>  
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate4">
                                 <dt>Cheque date 4</dt>
                                 <dd >  {{ orderInfo.chequeDate4  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate4Amount">
                                 <dt>Cheque Amount 4</dt>
                                 <dd >  {{ orderInfo.chequeDate4Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate5">
                                 <dt>Cheque date 5</dt>
                                 <dd >  {{ orderInfo.chequeDate5  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate5Amount">
                                 <dt>Cheque Amount 5</dt>
                                 <dd >  {{ orderInfo.chequeDate5Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate6">
                                 <dt>Cheque date 6</dt>
                                 <dd >  {{ orderInfo.chequeDate6  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate6Amount">
                                 <dt>Cheque Amount 6</dt>
                                 <dd >  {{ orderInfo.chequeDate6Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate7">
                                 <dt>Cheque date 7</dt>
                                 <dd >  {{ orderInfo.chequeDate7  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate7Amount">
                                 <dt>Cheque Amount 7</dt>
                                 <dd >  {{ orderInfo.chequeDate7Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate8">
                                 <dt>Cheque date 8</dt>
                                 <dd >  {{ orderInfo.chequeDate8  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate8Amount">
                                 <dt>Cheque Amount 8</dt>
                                 <dd >  {{ orderInfo.chequeDate8Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate9">
                                 <dt>Cheque date 9</dt>
                                 <dd >  {{ orderInfo.chequeDate9  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate9Amount">
                                 <dt>Cheque Amount 9</dt>
                                 <dd >  {{ orderInfo.chequeDate9Amount }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate10">
                                 <dt>Cheque date 10</dt>
                                 <dd >  {{ orderInfo.chequeDate10  | amDateFormat: 'DD-MM-YYYY' }} </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.chequeDate10Amount">
                                 <dt>Cheque Amount 10</dt>
                                 <dd >  {{ orderInfo.chequeDate10Amount }} </dd>
                              </dl> 
                             
                           
                            
                              
                           </ng-container>
                           <ng-container >
                                
                              <dl class="param param-feature" *ngIf="orderInfo.sellingPrice">
                                 <dt>Selling price </dt>
                                 <dd >{{ orderInfo.sellingPrice}} MAD</dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.advanceAmount">
                                 <dt>Advance amount </dt>
                                 <dd >{{ orderInfo.advanceAmount}} MAD </dd>
                              </dl> 
                              <dl class="param param-feature" *ngIf="orderInfo.remainingDueAmount">
                                 <dt>Implied remaining amount due</dt>
                                 <dd >{{ orderInfo.remainingDueAmount}} MAD</dd>
                              </dl> 
                           </ng-container>
                           <dl class="param param-feature" *ngIf="orderInfo.description">
                              <dt>Description</dt>
                              <dd >{{ replaceST(orderInfo.description)}}</dd>
                           </dl> 
                           <dl class="param param-feature" *ngIf="orderInfo.additionalInformation">
                              <dt>Additional Information </dt>
                              <dd >{{ orderInfo.additionalInformation}}</dd>
                           </dl> 

                           <dl class="param param-feature" *ngIf="orderInfo.additionalDiscount">
                              <dt>Additional discount</dt>
                              <dd >{{orderInfo.additionalDiscount}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="orderInfo.effectiveSalesPrice">
                              <dt>Final selling price</dt>
                              <dd >{{orderInfo.effectiveSalesPrice}}</dd>
                           </dl>
                           <!-- <dl class="param param-feature">
                              <dt>Color coding of price</dt>
                              <dd >{{orderInfo.status }}</dd>
                           </dl>  -->

                           <!-- <dl class="param param-feature">
                              <dt>Design number</dt>
                              <dd >1</dd>
                           </dl>  -->
                           
                         
                           <!-- row.// -->
                           <hr>
                         
                        </article>
                        <!-- card-body.// -->
                     </aside>
                     <!-- col.// -->
                  </div>
                  <!-- row.// -->
                  <h5 class="pt-2 px-2 mb-0" *ngIf="orderInfo && orderInfo.purchasedProducts.length > 0">Product details</h5>
                  <div *ngIf="orderInfo && orderInfo.purchasedProducts.length > 0" class="p-2 table-responsive">
                     <table class="table table-bordered m-0">
                        <thead>
                           <tr>
                              <!-- Set columns width -->
                              <th class=" py-3 px-4" style="min-width: 200px;">Product Name</th>
                              <th class=" py-3 px-4" style="width: 200px;">Price</th>
                              <th class=" py-3 px-4" style="width: 120px;">Quantity</th>
                              <th class=" py-3 px-4" style="width: 200px;">Total</th>
                              <th class=" py-3 px-4" style="width: 200px;">Final Selling Price</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let product of orderInfo.purchasedProducts;let i=index">
                              <td class="p-2">
                                 <div class="media align-items-center">
                                    <img onerror="this.src='assets/product.png';style='border: 1px solid #d3d3d34f;width: 70px;'" class="img-fluid1 mx-auto d-block img-p" src="{{product.photos[0] && product.photos[0].signedGetURL}}" width="70px" height="70px" style="object-fit: contain;" />
                                    <div class="media-body px-2">
                                       <a href="#" class="d-block text-dark">{{product.productName}}</a>
                                       <small>
                                       </small>
                                    </div>
                                 </div>
                              </td>

                              <td class=" font-weight-semibold align-middle p-2">{{product.displayedSellingPrice}} MAD</td>
                              <td class="align-middle p-2">
                                 {{orderInfo.cartString && orderInfo.cartString[i].qty ? orderInfo.cartString[i].qty :1 }}</td>
                              <td class=" font-weight-semibold align-middle p-2">{{(orderInfo.cartString && orderInfo.cartString[i].qty ? orderInfo.cartString[i].qty :1 )* product.displayedSellingPrice}} MAD</td>
                              <td class=" font-weight-semibold align-middle p-2"> {{orderInfo.cartString && orderInfo.cartString[i].finalSellingPrice ? orderInfo.cartString[i].finalSellingPrice +'MAD' :'' }} </td>
                           </tr>
                          
                        </tbody>

                     </table>
                  </div>
               </div>
               <!-- card.// -->
            
         <!-- Content Row -->
    </div>
  </div>
</div>
<!-- </div> -->
