import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { importVCardContactsMutation,importNonAppContactMutation,contactsLessInfoQuery,getJobStatusQuery } from '../../../services/contacts.gql'
import { Apollo, QueryRef } from 'apollo-angular'
import * as vCard from 'vcard-parser'
import * as Papa from 'papaparse'
import { StyleSheet } from 'sheetjs';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {SyncService} from '../../../../user/services/sync.service'
import { Router, ActivatedRoute, Params } from '@angular/router'
import {accountInfo} from '../../../services/user.gql'
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms'
import * as XLSX from 'xlsx';

import {
  createProductArrayMutation,categoryQuery,updateProductMutution,createProductImageMutution,productsQuery,refreshImagesMutution
  } from '../../../services/products.gql'
import { assertScalarType } from 'graphql';
  const moment = require('moment-timezone')

@Component({
  selector: 'app-import-product',
  templateUrl: './import-product.component.html',
  styleUrls: ['./import-product.component.scss']
})
export class ImportProductComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInput

  loading:boolean = false
  buttonStatus:boolean=false
  items:any=[]
  syncSettings:any=[]
  products:any=[]
  selectedContacts:any=[]
  private query: QueryRef<any>
  query$:any

  private queryJob: QueryRef<any>
  queryJob$:any
  staytouchContacts:any=[]
  selectedFiles:any
  tempContacts:any=[]
  isFilter:boolean = false
  public accountInfo :any
  private queryAc:QueryRef<any>
  activeTab:any = "csv"
  outlookImportStatus:boolean=false
  googleImportStatus:boolean=false
  csvImportStatus:boolean =  false
  syncForm: FormGroup;
  showTable:boolean= false
  csvobjFields:any=[]
  excelImportStatus:boolean = false
  isExcelTab:boolean = true
  contactFields = [
    {
      id:1,
      name:"productName",
      displayName: "Product Name"
    },
    {
      id:2,
      name:"productCategory",
      displayName: "Product Category"
    },
    {
      id:3,
      name:"designNumber",
      displayName: "Design Number"
    },
    // {
    //   id:4,
    //   name:"itemNumber",
    //   displayName: "Item Number"
    // },
    // {
    //   id:5,
    //   name:"cost",
    //   displayName: "cost"
    // },
    {
      id:6,
      name:"costUSD",
      displayName: "cost in USD"
    },
    {
      id:7,
      name:"costMAD",
      displayName: "cost in MAD"
    },
    {
      id:8,
      name:"costAED",
      displayName: "cost in AED"
    },
    {
      id:9,
      name:"margin",
      displayName: "Margin"
    },
    // {
    //   id:10,
    //   name:"displayedSellingPrice",
    //   displayName: "Display Selling Price"
    // },
    // {
    //   id:11,
    //   name:"discount",
    //   displayName: "discount"
    // },
    // {
    //   id:12,
    //   name:"sellingPrice",
    //   displayName: "Selling Price"
    // },
    // {
    //   id:13,
    //   name:"sellingPriceMargin",
    //   displayName: "selling Price Margin"
    // },
    // {
    //   id:14,
    //   name:"description",
    //   displayName: "description"
    // },
    // {
    //   id:15,
    //   name:"company",
    //   displayName: "company"
    // },
    // {
    //   id:16,
    //   name:"currency",
    //   displayName: "currency"
    // },
    {
      id:17,
      name:"stockQuantity",
      displayName: "Number of items "
    },
    {
      id:18,
      name:"supplierName",
      displayName: "Supplier name"
    },
    {
      id:19,
      name:"supplierInvoiceNumber",
      displayName: "Supplier invoice number"
    },
    {
      id:20,
      name:"tajNumber",
      displayName: "Taj number"
    },
    // {
    //   id:21,
    //   name:"currency",
    //   displayName: "Margin"
    // },
    {
      id:22,
      name:"displayedSellingPrice",
      displayName: "Displayed selling price in MAD"
    },
    {
      id:23,
      name:"maxAllowed",
      displayName: "Maximum discount allowed"
    },
    {
      id:24,
      name:"minSellingPrice",
      displayName: "Minimum selling price in MAD"
    },

    {
      id:25,
      name:"sellingPriceMargin",
      displayName: "Implied minimum selling price margin"
    },
    {
      id:26,
      name:"dateImport",
      displayName: "Import date"
    },
    {
      id:27,
      name:"goldColor",
      displayName: "Gold color"
    },
     {
      id:28,
      name:"goldWeight",
      displayName: "Gold weight"
    },
    
    {
      id:29,
      name:"noDiamondStones",
      displayName: "Number of diamond stones"
    },
    
    {
      id:30,
      name:"coloredStonesTypeOne",
      displayName: "Colored stones type 1"
    },
    
    {
      id:31,
      name:"coloredStonesWeightOne",
      displayName: "Colored stones weight 1"
    },
    
    {
      id:32,
      name:"coloredStonesTypeTwo",
      displayName: "Colored stones type 2"
    },
      {
      id:33,
      name:"coloredStonesWeightTwo",
      displayName: "Colored stones weight 2"
    },
    
    {
      id:34,
      name:"size",
      displayName: "Size"
    },
    {
      id:35,
      name:"comment",
      displayName: "Comment"
    },
    {
      id:36,
      name:"productLocation",
      displayName: "Product location"
    },{
      id:37,
      name:"cdnImages",
      displayName: "Images"
    },
    {
      id:38,
      name:"diamondWeight",
      displayName: "Diamond weight"
    },
    
    
    
    // {
    //   id:18,
    //   name:"imageUrls",
    //   displayName: "Product Image"
    // },

  ];
  constructor(  private apollo: Apollo, private syncService: SyncService, public router: Router, private activatedRoute:ActivatedRoute, private fb:FormBuilder) {
    this.syncForm = this.fb.group({
      quantities: this.fb.array([]) ,
    });
  }

  ngOnInit() {
  //  this.loading = true
    
    // this.importGoogleContacts()
    this.activatedRoute.queryParams.subscribe(params => {
      let parm = params.code
     
      if (params.code) {

        if(params.state=="12345")
        {
          this.activeTab="outlook"
        }
        else  if(params.state=="4"){
          this.activeTab="google"
        }
      
          
      }
    });
  }

  quantities() : FormArray {
    return this.syncForm.get("quantities") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      stField: '',
      sfField: '',
    })
  }
   
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
   
  removeQuantity(i:number) {
    this.quantities().removeAt(i);
  }
  getAccountInfo()
  {
    this.queryAc = this.apollo.watchQuery({
      query: accountInfo
    })
    this.queryAc.valueChanges.subscribe(({ data }) => {
      this.accountInfo =  data.accountInformation
    },error => {
      this.loading = false
    })
  }

 
  public changeListener(files: FileList,type){
    this.selectedFiles = files
    if(type == 'csv' && files && files.length > 0) {
        let file : File = files.item(0); 
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
        let csv: string = reader.result as string;
        if(type == 'csv'){
          this.parseCsvData(file)
        }
      }
    }
    else{
       this.parseExcel(files);
    }
   
  }


  parseCsvData(file)
  {
    this.loading = true
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result,file):any => {
        this.products = result.data
        this.tempContacts = this.products
        this.items = result.meta.fields
        this.csvobjFields= this.items.map((field)=>{
          return {
            name: field,
            mappedName:''
          }
        })
        this.loading = false
        this.showTable = true
      }
    });
  }

  parseExcel(files){
    this.loading =true
    let file : File = files.item(0); 
    let reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
  reader.onload = (e: any) => {
    /* create workbook */
    const binarystr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

    /* selected the first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    /* save data */
    //Fetch the name of First Sheet.
    var firstSheet = wb.SheetNames[0];
  
    // var excelRows = XLSX.utils.s(wb.Sheets[firstSheet]);
    const range = XLSX.utils.decode_range(ws['!ref'])
    for(let c = range.s.c; c <= range.e.c; c++) {
      const header = XLSX.utils.encode_col(c) + '1'
      this.csvobjFields.push({
          name: [ ws[header].v ],
          mappedName:''
      })

    }
    this.showTable = true
    const data = XLSX.utils.sheet_to_json(ws,{raw:false,defval:""}); // to get 2d array pass 2nd parameter as object {header: 1}
    this.products = data
    this.loading = false

  }
  }

 

  importProducts(productsArray:any,service){
    this.loading =true
    let batchContacts:any = Math.ceil(productsArray.length/50)
    var i,j,temparray,chunk = 50;
    // for (let i = 0, p = Promise.resolve(); i < batchContacts; i++) {
    //     p = p.then(_ => new Promise(resolve => {
    //       temparray = productsArray.slice(i*chunk,i*chunk+chunk);

          this.apollo
          .mutate({
            mutation: createProductArrayMutation,
            variables: {
              createProductInput:productsArray
            },
            errorPolicy:"all"
          })
          .subscribe(
            ({ data,errors }:any) => {
              // resolve();
              if(data){
                this.refreshProducts()
              }
              else{
                alert("Unable to import products successfully. Please check the text format in excel file")
                this.loading = false
                this.buttonStatus = false
              }
              
             
             
            },
            error => {
              // resolve();
              alert("Unable to import products successfully.")
             this.loading = false
             this.buttonStatus = false
             
            }
          )
        // }
           
        // ));
    // }
   

  }

  refreshProducts(){
    this.apollo.mutate({
      mutation: refreshImagesMutution,
      errorPolicy:"all"
      })
      .subscribe(
      ({ data,errors }:any) => {
        setTimeout(() => {
          alert("Products imported successfully.")
          this.router.navigate(['/products',1,1])
        }, 1000);
      })
  }

  syncAllFields(){
    this.buttonStatus=true;
    let staytouchFieldArray = this.f.quantities.value
    
    //convert
     var result = {};
     for (let i = 0; i < staytouchFieldArray.length; i++) {
       result[staytouchFieldArray[i].sfField] = staytouchFieldArray[i].stField;
     }
    //  console.log(this.products,'products')
     this.loading = true
     let productsArray:any =[]
     let errorFields:any =[]
       this.products.forEach((item,i)=>{
         let dataArray = []
         let productObj:any = {
         }

         productObj.company = ''
         productObj.company = ''
         productObj.currency = "MAD"
         productObj.itemNumber = ""
         productObj.designNumber = ""
         productObj.maxAllowed = 0
         productObj.discount=0
         productObj.usdAedFx = 3.67
         productObj.aedMadFx = 2.7
         let j=1
        //  console.log(item.coloredStonesWeightOne)

         for (let key in item) {
           if (j==1 && item[key]) {

            productObj.cdnImages = item[key]
          }
          if (j==2 && item[key]) {
            productObj.productCategory = item[key] //TEXT
             if(this.checkTheString(item[key])){
                if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
           if (j==3 && item[key]) {
             productObj.productName = item[key] //TEXT
             if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
           }
           if (j==4 && item[key]) {
            productObj.stockQuantity = parseInt(item[key])
           }
           if (j==5 && item[key]) {
            productObj.tajNumber = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==6 && item[key]) {
            productObj.goldColor = item[key].toUpperCase() //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==7 && item[key]) {
            // item[key] =item[key].toFixed(3)
            productObj.goldWeight = parseFloat(item[key])
            // productObj.goldWeight  = productObj.goldWeight.toFixed(3)
          }
          if (j==8 && item[key]) {
            // item[key] =item[key].toFixed(3)
            productObj.diamondWeight =parseFloat(item[key])
            // productObj.diamondWeight =   productObj.diamondWeight.toFixed(3)
          }
          if (j==9 && item[key]) {
            productObj.noDiamondStones = parseInt(item[key])
          }
          if (j==10 && item[key]) {
            productObj.coloredStonesTypeOne = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==11 && item[key]) {
            productObj.coloredStonesWeightOne = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==12 && item[key]) {
            // console.log(item[key],'j value', j,key)
            productObj.coloredStonesTypeTwo = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==13 && item[key]) {
            productObj.coloredStonesWeightTwo = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==14 && item[key]) {
            productObj.productLocation = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }

          if (j==15 && item[key]) {
            productObj.comment = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==16 && item[key]) {
            productObj.size = item[key].toString() //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==17 && item[key]) {
            productObj.supplierInvoiceDate = item[key]
                    //  }
                let dateImport = moment
                .tz(moment(item[key],"YYYY-MM-DD"), moment.tz.guess())
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          }
          if (j==18 && item[key]) {
            productObj.supplierInvoiceNumber = item[key].toString() //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
          if (j==19 && item[key]) {
            productObj.supplierName = item[key] ? item[key].trim(): "" //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }
         
          if (j==20 && item[key]) {
            productObj.designNumber = item[key] //TEXT
            if(this.checkTheString(item[key])){
              if(!errorFields.includes(key)){
                errorFields.push(key)
              }
            }
          }

          if (j==21 && item[key]) {
            productObj.costUsd = parseFloat(item[key])
          }
           if (j==22 && item[key]) {
            productObj.usdAedFx  = parseFloat(item[key])
          }
          if (j==23 && item[key]) {
            productObj.costAed = parseFloat(item[key])
          }
          if (j==24 && item[key]) {
            productObj.aedMadFx = parseFloat(item[key])
          }
          if (j==25 && item[key]) {
            productObj.costMad = parseFloat(item[key])
          }
          
           
           if (j==26 && item[key]) {
             item[key] =item[key].replace('%','')
             productObj.margin = parseFloat(item[key])
           }
           if (j==27 && item[key]) {             
             productObj.displayedSellingPrice = parseFloat(item[key]) //selling price
           } 
           if (j==28 && item[key]) {
            item[key] =item[key].replace('%','')              
            productObj.discount = parseInt(item[key]) //discount
          }
          
          //  if (j==28) {
          //    productObj.sellingPrice = parseFloat(item[key])
          //  }
          if (j==29 && item[key]) {
            productObj.minSellingPrice = parseFloat(item[key]) //selling price
          }
           if (j==31 && item[key]) {
             item[key] =item[key].replace('%','')         
             productObj.sellingPriceMargin = parseFloat(item[key])
           }
           if (j==30 && item[key]) {
            item[key] =item[key].replace('%','')         
            productObj.maxAllowed = parseFloat(item[key])
          }




        
        if (j==32 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.coloredStonesWeight3 = item[key] //TEXT
          if(this.checkTheString(item[key])){
            if(!errorFields.includes(key)){
                errorFields.push(key)
              }
          }
        }
        if (j==33 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.coloredStonesType3 = item[key] //TEXT
          if(this.checkTheString(item[key])){
            if(!errorFields.includes(key)){
                errorFields.push(key)
              }
          }
        }
        if (j==34 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.diamondPriceUsd = parseFloat(item[key])
          
        }
        if (j==35 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.coloredStonesPriceUsd = parseFloat(item[key])
        }
        if (j==36 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.goldPriceUsd = parseFloat(item[key])
        }
        if (j==37 && item[key]) {
          item[key] =item[key].replace('%','')         
          productObj.makingChargesUsd = parseFloat(item[key])
        }
        if (j==38 && item[key]) {
          productObj.goldInvoiceNumber= item[key] 
          if(this.checkTheString(item[key])){ //TEXT
            if(!errorFields.includes(key)){
                errorFields.push(key)
              }
          }
        }






           j++;
         }
         
         if(!productObj.costAed || productObj.costAed == null){
           if(productObj.usdAedFx && productObj.costUsd){
            productObj.costAed= Math.round(productObj.usdAedFx * productObj.costUsd)
           }
         
         }
         if(!productObj.maxAllowed || productObj.maxAllowed == null){
          if(productObj.discount){
           productObj.maxAllowed= productObj.discount
          }
        
        }
         if(!productObj.costMad || productObj.costMad == null){
          if(productObj.aedMadFx && productObj.costAed){
            productObj.costMad= Math.round(productObj.aedMadFx * productObj.costAed)
          }
         }
        
         if(!productObj.displayedSellingPrice){
          if(productObj.margin && productObj.costMad){
           productObj.displayedSellingPrice = parseFloat(productObj.costMad) / (1- (productObj.margin/100))
          }
           
         }
         if(!productObj.minSellingPrice){
          if(productObj.displayedSellingPrice && productObj.discount){
            let discuountPrice = productObj.displayedSellingPrice - (productObj.displayedSellingPrice *   productObj.discount / 100)
            productObj.minSellingPrice = discuountPrice
          }
        }
       
        if(!productObj.sellingPriceMargin){
          // let margin =   parseFloat( productObj.displayedSellingPrice) -parseFloat(productObj.minSellingPrice)  
          if(productObj.minSellingPrice && productObj.costMad){
            let margin = ((productObj.minSellingPrice - productObj.costMad) / productObj.minSellingPrice)*100

            productObj.sellingPriceMargin = margin
          }
        }
        //  if(!productObj.sellingPriceMargin){
        //    productObj.sellingPriceMargin = productObj.margin
        //  }
   let dateImport = moment
   .tz(moment(), moment.tz.guess())
   .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
         productObj.otherFieldX= ""
         productObj.otherFieldY= ""
         productObj.other= ""
         productObj.dateImport= dateImport
         productObj.status = true
         productsArray.push(productObj)

       })
       if(errorFields.length > 0){
         if(errorFields.length ==1){
          alert(`Unable to upload the products. Please check the column name ${errorFields.toString()} have double quotes\("\) or \\`)
         }
         else{
          alert(`Unable to upload the products. Please check the column names ${errorFields.toString()} having double quotes\("\) or \\`)
         }
         this.loading = false
         this.buttonStatus = false
         return false
       }
       
      //  return
console.log(productsArray,'productsArray')
// return
       this.importProducts(productsArray,"CSV")
       // return
   
   }
  

  syncAllFieldsOld(){
     let staytouchFieldArray = this.f.quantities.value
     //convert
      var result = {};
      for (let i = 0; i < staytouchFieldArray.length; i++) {
        result[staytouchFieldArray[i].sfField] = staytouchFieldArray[i].stField;
      }
      let productsArray:any =[]
        this.products.forEach((item,i)=>{
          let dataArray = []
          let productObj:any = {
          }

          productObj.company = ''
          productObj.company = ''
          productObj.currency = "MAD"
          productObj.itemNumber = ""
          productObj.designNumber = ""
          productObj.maxAllowed = 0
          productObj.discount=0
          for (var key in item) {
            if (result[key]=='productName') {
              productObj.productName = item[key]
            }
            if (result[key]=='productCategory') {
              productObj.productCategory = item[key]
            }
            if (result[key]=='designNumber') {
              productObj.designNumber = item[key]
            }
            // if (result[key]=='itemNumber') {
            //   productObj.itemNumber = item[key]
            // }
            // if (result[key]=='cost') {
            //   productObj.cost = parseFloat(item[key])
            // }
            if (result[key]=='"costUSD"') {
              productObj.costUsd = parseFloat(item[key])
            }
            if (result[key]=='costMAD') {
              productObj.costMad = parseFloat(item[key])
            }
            if (result[key]=='costAED') {
              productObj.costAed = parseFloat(item[key])
            }
            if (result[key]=='margin') {
              item[key] =item[key].replace('%','')
              productObj.margin = parseFloat(item[key])
            }
            if (result[key]=='displayedSellingPrice') {
              productObj.displayedSellingPrice = parseFloat(item[key])
            } 
            if (result[key]=='sellingPrice') {
              productObj.sellingPrice = parseFloat(item[key])
            }
            if (result[key]=='sellingPriceMargin') {
              item[key] =item[key].replace('%','')         
              productObj.sellingPriceMargin = parseFloat(item[key])
            }
            
            if (result[key]=='supplierName') {
              productObj.supplierName = item[key] ? item[key].trim() : ""
            }
            if (result[key]=='supplierInvoiceNumber') {
              productObj.supplierInvoiceNumber = item[key].toString()
            }
            if (result[key]=='tajNumber') {
              productObj.tajNumber = item[key]
            }
            if (result[key]=='maxAllowed') {
              item[key] =item[key].replace('%','')         
              productObj.maxAllowed = parseFloat(item[key])
            }
            if (result[key]=='minSellingPrice') {
              productObj.minSellingPrice = parseFloat(item[key])
            }
            if (result[key]=='goldColor') {
              productObj.goldColor = item[key].toUpperCase()
            }
            if (result[key]=='goldWeight') {
              productObj.goldWeight = parseFloat(item[key].toFixed(3))
            }
            if (result[key]=='diamondWeight') {
              productObj.diamondWeight = parseFloat(item[key].toFixed(3))
            }
            if (result[key]=='noDiamondStones') {
              productObj.noDiamondStones = item[key]
            }
            if (result[key]=='coloredStonesTypeOne') {
              productObj.coloredStonesTypeOne = item[key]
            }
            if (result[key]=='coloredStonesWeightOne') {
              productObj.coloredStonesWeightOne = parseFloat(item[key])
            }
            if (result[key]=='coloredStonesTypeTwo') {
              productObj.coloredStonesTypeTwo = item[key]
            }
            if (result[key]=='coloredStonesWeightTwo') {
              productObj.coloredStonesWeightTwo = parseFloat(item[key])
            }
            if (result[key]=='size') {
              productObj.size = item[key].toString()
            }
            if (result[key]=='comment') {
              productObj.comment = item[key]
            }
            if (result[key]=='productLocation') {
              productObj.productLocation = item[key]
            }
            // if (result[key]=='description') {
            //   productObj.description = item[key]
            // }
            // if (result[key]=='company') {
            //   productObj.company = item[key]
            // }
            if (result[key]=='stockQuantity') {
              productObj.stockQuantity = parseInt(item[key])
            }
            if (result[key]=='discount') {
              item[key] =item[key].replace('%','')              
              productObj.discount = parseInt(item[key])
            }
            if (result[key]=='cdnImages') {
              productObj.cdnImages = item[key]
            }
            
            // if (result[key]=='currency') {
            //   productObj.currency = item[key]
            // }
          }
          // if(productObj.currency.toLowerCase() == 'usd'){
          //   let mad = 8.92575 
          //   let aed = 3.6725 
          //   let usd = 1
          //   productObj.costMad = mad * productObj.cost
          //   productObj.costAed = aed * productObj.cost
          // }
          // if(productObj.currency.toLowerCase() == 'mad'){
          //   let mad = 1 
          //   let aed = 0.413
          //   let usd = 0.112
          //   productObj.costMad = mad * productObj.cost
          //   productObj.costAUsd = usd * productObj.cost
          // }
          // if(productObj.currency.toLowerCase() == 'aed'){
          //   let mad = 2.42 
          //   let aed = 1
          //   let usd = 0.272
          //   productObj.costUsd = usd * productObj.cost
          //   productObj.costMad = mad * productObj.cost
          // }
          if(!productObj.minSellingPrice){
            productObj.minSellingPrice = productObj.displayedSellingPrice
          }
          if(!productObj.sellingPriceMargin){
            productObj.sellingPriceMargin = productObj.margin
          }
    let dateImport = moment
    .tz(moment(), moment.tz.guess())
    .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          productObj.otherFieldX= ""
          productObj.otherFieldY= ""
          productObj.other= ""
          productObj.dateImport= dateImport
          productObj.status = true
          productsArray.push(productObj)

        })

// return
        this.importProducts(productsArray,"CSV")
        // return
    
    }
    get f () {
      return this.syncForm.controls
    }
    goBack(){
      this.router.navigate(['/products',1,1])
    }
    checkTheString(str){
      if(str.indexOf('"') >= 0 || str.indexOf('/') >= 0 ){
        return true
      }
      // if(str.indexOf('\\') >= 0){
      //   return "\\" 
      // }
      return false
    }
}
