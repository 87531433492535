
<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center" >
  <div class="col-md-12 p-2 py-4 ">
    <div class="card p-md-3 py-3  ">
       <div class="d-flex justify-content-between align-items-center pb-3">
         <h4 >Product Details</h4>
         <!-- <a [routerLink]="['/create-project',projectId]" class="theme-btn-xs" >Edit</a> -->
            <div class=" " >
            
              <div  (click)="pageBack(page)" *ngIf="routeType==1" class=" ml-3 pt-2 default-color cursor-pointer" title="Edit" ><img _ngcontent-gmb-c13="" class="arrowIconn" src="assets/back.svg"> Back </div>
              <a [routerLink]="['/product-enquires']" *ngIf="routeType==2" class=" ml-3 pt-2 default-color cursor-pointer" title="Edit" ><img _ngcontent-gmb-c13="" class="arrowIconn" src="assets/back.svg"> Back </a>
            </div>
       </div>
       
       
               <div class="card">
                  <div class="row">
                     <aside class="col-sm-5 border-right">
                        <article class="gallery-wrap">
                          
                           <div *ngIf="galleryImages" class="d-flex ml-3 py-3">
                              <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
                           </div>
                        </article>
                        <!-- gallery-wrap .end// -->
                     </aside>
                     <aside class="col-sm-7" *ngIf="productInfo">
                        <article class="card-body p-5">
                           <!--<div class="text-right productIcons">
                               <a [routerLink]="['/admin/add-product/',productData.product_id]" class="cursor-pointer btn default-color " title="Edit">
                              Edit
                              </a>
                              <a (click)="deleteProduct()" class="cursor-pointer btn default-color " title="Delete">
                              Delete
                              </a>
                           </div> -->
                           <h3 class="title text-capitalize">{{productInfo.productName}}</h3>
                          
                           <p class="price-detail-wrap" *ngIf="productInfo.minSellingPrice && productInfo.maxAllowed !=0 && isUserAllow"> 
                              <span class="price h4 text-warning"> 
                                 <span class="num">{{roundToNear(productInfo.displayedSellingPrice)}} 
                                    <span class="currency">MAD</span>
                                 </span>
                              </span> 
                             <!-- <span class="pl-2" style="text-decoration: line-through;"> {{roundToNear(productInfo.displayedSellingPrice)}} MAD</span> 
                              <label class="px-1">({{productInfo.maxAllowed}}%)</label> -->
                           </p>
                           <p class="price-detail-wrap" *ngIf="!productInfo.minSellingPrice || productInfo.maxAllowed ==0 && isUserAllow"> 
                              <span class="price h4 text-warning"> 
                                  <span class="num">{{roundToNear(productInfo.displayedSellingPrice)}} <span class="currency">MAD</span></span>
                               </span>
                           </p>
                           <!-- price-detail-wrap .// -->
                           <!-- <dl class="item-property">
                              <dt>Description</dt>
                              <dd>
                                 <p>{{productInfo.description}} </p>
                              </dd>
                           </dl> -->
                         <!-- item-property-hor .// -->
                         <dl class="param param-feature" *ngIf="productInfo.productCategory && productInfo.productCategory.name">
                           <dt>Product Category</dt>
                           <dd *ngIf="productInfo.productCategory" >{{productInfo.productCategory.name}}</dd>
                        </dl>
                           <!-- item-property-hor .// -->
                           <!--<dl class="param param-feature" *ngIf="productInfo.id">
                              <dt>Product Id</dt>
                              <dd >{{productInfo.id}}</dd>
                           </dl>-->
                           <!-- <dl class="param param-feature">
                              <dt>Item Number</dt>
                              <dd >{{productInfo.itemNumber}}</dd>
                           </dl> -->
                           <dl class="param param-feature"  >
                              <dt>Inventory
                              </dt>
                              <dd >{{productInfo.stockQuantity ? productInfo.stockQuantity : 0 }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.tajNumber">
                              <dt>Taj number</dt>
                              <dd >{{productInfo.tajNumber ? productInfo.tajNumber : '-' }}</dd>
                           </dl>
                           
                         
                           <dl class="param param-feature" *ngIf="isUserAllow && productInfo.supplierName">
                              <dt>Supplier name</dt>
                              <dd >{{productInfo.supplierName}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.supplierInvoiceNumber">
                              <dt>Supplier invoice number</dt>
                              <dd >{{productInfo.supplierInvoiceNumber}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.supplierInvoiceDate && !productInfo.supplierInvoiceDate.includes('000')">
                              <dt>Supplier invoice date</dt>
                              <dd >{{productInfo.supplierInvoiceDate | amDateFormat: 'DD-MM-YYYY'}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.designNumber">
                              <dt>Design Number</dt>
                              <dd >{{productInfo.designNumber}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.costUsd">
                              <dt>Cost in USD</dt>
                              <dd >{{productInfo.costUsd}}</dd>
                           </dl>
                         
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.usdAedFx">
                              <dt>USD/AED FX</dt>
                              <dd >{{productInfo.usdAedFx}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.costAed">
                              <dt>Cost in AED</dt>
                              <dd >{{productInfo.costAed}}</dd>
                           </dl>
                           
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.aedMadFx">
                              <dt>AED/MAD FX</dt>
                              <dd >{{productInfo.aedMadFx}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && productInfo.costMad">
                              <dt>Cost in MAD</dt>
                              <dd >{{productInfo.costMad}}</dd>
                           </dl>
                        
                           <dl class="param param-feature" *ngIf=" isUserAllow && (productInfo.margin || productInfo.margin==0) ">
                              <dt>Gross margin</dt>
                              <dd >{{productInfo.margin}} %</dd>
                           </dl>
                           <dl class="param param-feature" >
                              <dt>Displayed selling price </dt>
                              <dd >{{roundToNear(productInfo.displayedSellingPrice)}} MAD</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.maxAllowed " >
                              <dt>Maximum discount allowed
                              </dt>
                              <dd >{{productInfo.maxAllowed}} %</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.minSellingPrice">
                              <dt>Price after discount</dt>
                              <dd >{{round(productInfo.minSellingPrice)}} MAD</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf=" isUserAllow && (productInfo.sellingPriceMargin || productInfo.sellingPriceMargin ==0)" >
                              <dt>Margin after discount</dt>
                              <dd >{{round(productInfo.sellingPriceMargin)}} %</dd>
                           </dl>
                            <!-- <dl class="param param-feature" *ngIf="productInfo.stockQuantity">
                              <dt>Quantity</dt>
                              <dd >{{productInfo.stockQuantity}}</dd>
                           </dl> -->
                           <dl class="param param-feature" *ngIf="productInfo.dateImport && !productInfo.dateImport.includes('000')">
                              <dt>StayTouch Import date</dt>
                              <dd >{{productInfo.dateImport | amDateFormat: 'DD-MM-YYYY' }}</dd>
                           </dl> 
                           <!-- <dl class="param param-feature">
                              <dt>Prodcut status</dt>
                              <dd >{{productInfo.status ? 'Active' : 'Inactive'}}</dd>
                           </dl> -->
                           <!-- <dl class="param param-feature">
                              <dt>Stock Availability</dt>
                              <dd >{{productInfo.stockQuantity > 0 ? 'In Stock' : 'Out of stock'}}</dd>
                           </dl> -->
                           
                           <!-- <dl class="param param-feature">
                              <dt>Price</dt>
                              <dd >{{productInfo.stockQuantity > 0 ? 'In Stock' : 'Out of stock'}</dd>
                           </dl>  -->


                           <!-- <dl class="param param-feature">
                              <dt>Color coding of price</dt>
                              <dd >{{productInfo.status }}</dd>
                           </dl>  -->

                           <!-- <dl class="param param-feature">
                              <dt>Design number</dt>
                              <dd >1</dd>
                           </dl>  -->
                          

                           <dl class="param param-feature" *ngIf="productInfo.goldColor">
                              <dt>Gold color </dt>
                              <dd >{{productInfo.goldColor  }} </dd>
                           </dl>

                           <dl class="param param-feature" *ngIf="productInfo.goldWeight && productInfo.goldWeight != 'null' ">
                              <dt>Gold weight </dt>
                              <dd >{{productInfo.goldWeight ? fixedTo(productInfo.goldWeight): '-' }} grams</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="isUserAllow &&  productInfo.goldPriceUsd && productInfo.goldPriceUsd != 'null' ">
                              <dt>Gold Price (in USD)</dt>
                              <dd >{{productInfo.goldPriceUsd ? productInfo.goldPriceUsd: '-' }} </dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="isUserAllow &&  productInfo.goldInvoiceNumber && productInfo.goldInvoiceNumber != 'null' && productInfo.goldInvoiceNumber != ' '">
                              <dt>Gold Invoice Number </dt>
                              <dd >{{productInfo.goldInvoiceNumber ? productInfo.goldInvoiceNumber: '-' }} </dd>
                           </dl>

                           <dl class="param param-feature" *ngIf="productInfo.diamondWeight && productInfo.diamondWeight != 'null'">
                              <dt>Diamond weight </dt>
                              <dd >{{productInfo.diamondWeight ? fixedTo(productInfo.diamondWeight): '-'  }} ct</dd>
                           </dl>

                           <dl class="param param-feature" *ngIf="productInfo.noDiamondStones && productInfo.noDiamondStones != 'null'">
                              <dt>Number of diamond stones </dt>
                              <dd >{{productInfo.noDiamondStones ? productInfo.noDiamondStones : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="isUserAllow && productInfo.diamondPriceUsd && productInfo.diamondPriceUsd != 'null'">
                              <dt>Diamond Price (in USD)</dt>
                              <dd >{{productInfo.diamondPriceUsd ? productInfo.diamondPriceUsd : '-' }} </dd>
                           </dl>

                           <dl class="param param-feature" *ngIf="  productInfo.coloredStonesTypeOne && productInfo.coloredStonesTypeOne != 'null'">
                              <dt>Colored stones type 1 </dt>
                              <dd >{{productInfo.coloredStonesTypeOne ? productInfo.coloredStonesTypeOne : '-'}}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.coloredStonesWeightOne && productInfo.coloredStonesWeightOne != 'null'">
                              <dt>Colored stones weight 1 (in ct)</dt>
                              <dd >{{productInfo.coloredStonesWeightOne ? productInfo.coloredStonesWeightOne : '-' }}</dd>
                           </dl>

                           <dl class="param param-feature" *ngIf="productInfo.coloredStonesTypeTwo && productInfo.coloredStonesTypeTwo != 'null'">
                              <dt>Colored stones type 2</dt>
                              <dd >{{productInfo.coloredStonesTypeTwo ? productInfo.coloredStonesTypeTwo : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.coloredStonesWeightTwo  && productInfo.coloredStonesWeightTwo != 'null'">
                              <dt>Colored stones weight 2 (in ct)</dt>
                              <dd >{{productInfo.coloredStonesWeightTwo ? productInfo.coloredStonesWeightTwo : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.coloredStonesType3 && productInfo.coloredStonesType3 != 'null'">
                              <dt>Colored stones type 3</dt>
                              <dd >{{productInfo.coloredStonesType3 ? productInfo.coloredStonesType3 : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.coloredStonesWeight3  && productInfo.coloredStonesWeight3 != 'null' && productInfo.coloredStonesWeight3 != ' '">
                              <dt>Colored stones weight 3 (in ct)</dt>
                              <dd >{{productInfo.coloredStonesWeight3 ? productInfo.coloredStonesWeight3 : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="isUserAllow && productInfo.coloredStonesPriceUsd  && productInfo.coloredStonesPriceUsd != 'null'">
                              <dt>Colored Stones Price (in USD)</dt>
                              <dd >{{productInfo.coloredStonesPriceUsd ? productInfo.coloredStonesPriceUsd : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="isUserAllow &&  productInfo.makingChargesUsd  && productInfo.makingChargesUsd != 'null'">
                              <dt>Making Charges (in USD)</dt>
                              <dd >{{productInfo.makingChargesUsd ? productInfo.makingChargesUsd : '-' }}</dd>
                           </dl>


                           <dl class="param param-feature" *ngIf="productInfo.size && productInfo.size != 'null'">
                              <dt>Product size</dt>
                              <dd >{{productInfo.size ? productInfo.size : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.comment && productInfo.comment != 'null'">
                              <dt>Comment</dt>
                              <dd >{{productInfo.comment ? productInfo.comment : '-' }}</dd>
                           </dl>
                           <dl class="param param-feature" *ngIf="productInfo.productLocation && productInfo.productLocation != 'null'">
                              <dt>Product Location</dt>
                              <dd >{{productInfo.productLocation ? productInfo.productLocation : '-' }}</dd>
                           </dl>
                         
                         
                           <!-- row.// -->
                           <hr>
                         
                        </article>
                        <!-- card-body.// -->
                     </aside>
                     <!-- col.// -->
                  </div>
                  <!-- row.// -->
               </div>
               <!-- card.// -->
            
         <!-- Content Row -->
    </div>
  </div>
</div>
<!-- </div> -->
