import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
//import { NgxIntlTelInputModule } from './../internal-modules/ngx-intl-tel-input'
import { NgxSelectModule } from 'ngx-select-ex'
import { ToastrModule } from 'ngx-toastr';
import { Routes, RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { QRCodeModule } from 'angularx-qrcode';
// import { GraphQLModule } from '../admin/graphql.module'
import { LoadingComponent } from '../shared/components/loading/loading.component'
import { TextLoadingComponent } from '../shared/components/text-loading/text-loading.component'

//import { ImagePreloadDirective } from './directives/app-image.directive'
import { AppPasswordDirective } from '../app-password.directive';
// import { AngMusicPlayerModule } from  'ang-music-player';

@NgModule({
	declarations:[
		LoadingComponent,
		//ImagePreloadDirective,
		AppPasswordDirective,
		TextLoadingComponent
	],
	imports:[
		// AngMusicPlayerModule,
	    CommonModule,
	    FormsModule,
	    ReactiveFormsModule,
		NgxIntlTelInputModule,
		NgxSelectModule,
		MomentModule,
		HttpClientModule,
		
		// LoadingComponent
		// GraphQLModule,
		// MalihuScrollbarModule.forRoot(),
		// QRCodeModule,
		// NgbModule,
		// ToastrModule.forRoot() // ToastrModule added
	],
	exports:[
		// AngMusicPlayerModule,
		CommonModule,
	    FormsModule,
	    ReactiveFormsModule,
		NgxIntlTelInputModule,
		NgxSelectModule,
		MomentModule,
		HttpClientModule,
		LoadingComponent,
		TextLoadingComponent,
		AppPasswordDirective,
		
		//GraphQLModule,
	
		// QRCodeModule,
		// NgbModule,


	]
	
})
export class SharedModule{}
