import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { relativeTimeRounding } from 'moment'
import { HelperService } from './helper.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class PermissionsAuthGuard implements CanActivate {

    permissionsRes:any ={}
    permissions:any = ""
    constructor(
        private router: Router,
        private helperService:HelperService

    ) {

       // this.getState = this.store.select(selectMemberAuth)
    	// this.store.dispatch(new MemberStatus)
      }
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):any {
        return new Promise((resolve) => {
            let isAdmin = this.helperService.isAdmin()
            let currentUrl:any = window.location.pathname.split('/')
            if(isAdmin){
                resolve(true)
            }
            else{
               
                this.permissionsRes =   this.helperService.getAccountInforamtion()
                this.permissionsRes.valueChanges.subscribe(({ data }: any) => {
                this.permissions = this.helperService.getPermissions(data)
                // console.log(currentUrl[1])
                // console.log(this.permissions.contacts_and_meetings.meeting_notes)
                if(['all-contacts','create-contact','import-contacts'].indexOf(currentUrl[1]) > -1 && this.permissions.contacts_and_meetings.contacts == 'false')
                {
                    // alert()
                    resolve(false)
                }
                if(['contacts'].indexOf(currentUrl[1]) > -1 && this.permissions.contacts_and_meetings.contact_add_group_list == 'false')
                {
                    // alert()
                    resolve(false)
                }

                else if(['create-member','members','view-member','official-profile','view-profile'].indexOf(currentUrl[1]) > -1 && this.permissions.company.add_members_to_teams == 'false')
                {
                    resolve(false)
                }
                
                else if(['teams'].indexOf(currentUrl[1]) > -1 && this.permissions.company.create_teams_and_departments == 'false')
                {
                    resolve(false)
                }
                
                else if(['meetings','create-conference','create-meeting','conference-list'].indexOf(currentUrl[1]) > -1 && this.permissions.contacts_and_meetings.meetings == 'false')
                {
                    resolve(false)
                }
                else if(currentUrl[1] == 'zoom-connect' && this.permissions.contacts_and_meetings.integration_per_company_settings == 'false')
                {
                    resolve(false)
                }
                // else if(currentUrl[1] == '/syncronizations' && this.permissions.contacts_and_meetings.sync_per_company_settings == 'false')
                // {
                //     resolve(false)
                // }
                else if(['meeting-notes'].indexOf(currentUrl[1]) > -1 && this.permissions.contacts_and_meetings.meeting_notes == 'false')
                {
                    resolve(false)
                }
                
                else{
                    resolve(true)
                }
            })
    
            }
            
        }).then((result)=>{
            if(result == false){
                this.router.navigate(['/members']);
            }
            return result
        })
       
    }


}
