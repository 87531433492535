import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'
import {
  addressTypes,
  linkTypes,
  customTypes,
  emailTypes,
  phoneTypes
} from './staticLabels'

@Injectable({
  providedIn: 'root'
})
export class memberHelper {
  constructor (private formBuilder: FormBuilder) {}
  createEmailFormGroup () {
    return this.formBuilder.group({
      value: ['', [Validators.required, Validators.email]],
      format: ['EMAIL'],
      title: [emailTypes[0].id],
      key: ['']
    })
  }
  createPhoneFormGroup () {
    return this.formBuilder.group({
      value: ['', [Validators.required]],
      format: ['PHONE_NUMBER'],
      title: [phoneTypes[0].id],
      key: ['']
    })
  }
  createLinkFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['LINK'],
      title: [linkTypes[0].id],
      key: ['']
    })
  }
  createEducationFormGroup () {
    return this.formBuilder.group({
      // value	: [''],
      format: ['UNIVERSITY'],
      title: ['Education'],
      key: [''],
      university: [''],
      degree: [''],
      study: [''],
      startYear: [''],
      endYear: ['']
    })
  }
  createCustomFromGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['GENERIC'],
      title: [customTypes[0].id],
      key: ['']
    })
  }
  createAddressFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['LOCATION'],
      title: [addressTypes[0].id],
      street: [''],
      street1: [''],
      city: [''],
      state: [''],
      country: [''],
      postCode: [],
      key: ['']
    })
  }
  createEmpFormGroup () {
    return this.formBuilder.group({
      empName: ['', [Validators.required]],
      age: ['', [Validators.required, Validators.min(21)]],
      skill: ['', [Validators.required]]
    })
  }
  createFacebookFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['Facebook'],
      key: ['']
    })
  }
  createTikTokFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['TikTok'],
      key: ['']
    })
  }
  createInstagramFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['Instagram'],
      key: ['']
    })
  }
  createSnapchatFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['Snapchat'],
      key: ['']
    })
  }
  createLinkedInFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['Linked In'],
      key: ['']
    })
  }
  createTwitterFormGroup () {
    return this.formBuilder.group({
      value: [''],
      format: ['SOCIAL_MEDIA'],
      title: ['Twitter'],
      key: ['']
    })
  }
}
