<div class="row justify-content-center ">
  <div class="col-md-12  px-0">
    <div class="col-md-12 p-2">
      <div class="card px-md-3 pb-5 my-3 ">
        <app-account></app-account>
        <!-- <h4 class="pb-5 innerTitle">My Teams</h4> -->
        <div class=" d-flex justify-content-center1 pt-3">
          <div class="col-lg-5 col-md-8">
            <h6 class="text-uppercase text-defaultClr default-color pb-1">
              {{ 'TEAMSPAGE.TEAM_NAME' | translate }}
            </h6>
            <ng-container *ngIf="!loading">
              <ul class="m-0 p-0 temsBlk" *ngFor="let team of teams">
                <li class="d-sm-flex justify-content-between">
                  <p>{{ team.name }}</p>
                  <span class="text-gray-app">
                    {{
                      'TEAMSPAGE.N_MEMBERS'
                        | translate: { members_count: team.memberIds.length }
                    }}
                  </span>
                </li>
              </ul>
            </ng-container>
            
            <h6 *ngIf="!loading && teams.length === 0">
              {{ 'TEAMSPAGE.NO_TEAMS_ARE_FOUND' | translate }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
