<!-- Propeller Bootstrap datetimepicker -->
<!-- <div class="grayBg container-cs aboutBlk"> -->
<!-- <h2 class="font-weight-bold pb-3">Members</h2> -->
<!-- <app-sub-menu></app-sub-menu> -->
<app-loading *ngIf="loading"></app-loading>
<div class="row justify-content-center">
  <div class="col-md-12 p-2 py-4 ">
    <div class="card p-md-5 py-5  ">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a  [routerLink]="['/members']">{{ 'SUBMENU.MEMBERS' | translate }}</a></li>
          <li class="breadcrumb-item active" *ngIf="!userId" aria-current="page">Add</li>
          <li class="breadcrumb-item active" *ngIf="userId" aria-current="page">Update</li>
          <li class="breadcrumb-item active" aria-current="page">{{f.first_Name.value}}</li>
          <li
         class="rightIcon ml-auto" [routerLink]="['/view-member', userId]" *ngIf="userId &&  userData.profiles && userData.profiles.length > 0"
          ><img src="assets/back.svg?x=1" class="arrowIconn"  /> 
          Back </li
        >
        <!-- <li
        class="rightIcon ml-auto" [routerLink]="['/official-profile', userId]" *ngIf="userId && userData.profiles.length ==0"
         >Create Official Profile <img src="assets/right-arrow.png" class="pl-2" /></li
       > -->
        </ol>
      
      </nav>
      <div class=" d-flex justify-content-center">
        <form
          class="membersBlk  align-items-start"
          [formGroup]="memberForm"
          (ngSubmit)="saveMember1()"
        >
          <div class="d-flex flex-wrap w-100">
            <div class="py-4 col-lg-6 pr-5 d-flex">
              <label class="text-gray noWeight border-bottom1 ">{{
                'PROFILE.FIRST_NAME' | translate
              }}</label>
              <div class="w-100">
                <input
                  type="text"
                  class="theme-input"
                  formControlName="first_Name"
                  class="theme-input"
                />
                <div
                  *ngIf="submitted1 && f.first_Name.errors"
                  class="text-danger"
                >
                  <div *ngIf="f.first_Name.errors.required">
                    First name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4 col-lg-6 pr-5 d-flex">
              <label class="text-gray noWeight border-bottom1 ">{{
                'PROFILE.LAST_NAME' | translate
              }}</label>
              <div class="w-100">
                <input
                  type="text"
                  class="theme-input"
                  formControlName="lastName"
                  class="theme-input"
                />
                <div
                  *ngIf="submitted1 && f.lastName.errors"
                  class="text-danger"
                >
                  <div *ngIf="f.lastName.errors.required">
                    Last name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4 col-lg-6 pr-5 d-flex">
              <label class="text-gray noWeight border-bottom1 ">{{
                'PROFILE.EMAIL' | translate
              }}</label>
              <div class="w-100">
                <input
                  type="text"
                  class="theme-input"
                  formControlName="email"
                  pattern="[a-zA-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                />

                <div *ngIf="submitted1 && f.email.errors" class="text-danger">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div
                    *ngIf="f.email.errors?.pattern"
                    class="error text-danger"
                  >
                    Please enter valid email
                  </div>
                </div>
              </div>
            </div>
            <div class="py-4 col-lg-6 pr-5 d-flex">
              <label class="text-gray noWeight border-bottom1 ">{{
                'CREATE_MEMBER.PHONE' | translate
              }}</label>
              <div class="w-100">
                <!-- <input type="text" class="theme-input"  formControlName="phone" pattern="^[0-9]{4,20}$"  /> -->

                <ngx-intl-tel-input
                  [cssClass]="'custom theme-input w-100'"
                  [preferredCountries]="['us', 'gb']"
                  [enablePlaceholder]="false"
                  [enableAutoCountrySelect]="enableAutoCountrySelect"
                  name="phone"
                  [searchCountryFlag]="true"
                  [separateDialCode]="'false'"
                  formControlName="phone"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  (keypress)="onSearchChange($event)"
                ></ngx-intl-tel-input>
                <!-- <div *ngIf="submitted1 && f.phone.errors" class="text-danger">
                  <div *ngIf="f.phone.errors.required">
                    Phone number is required
                  </div>
                  <div
                    *ngIf="f.phone.errors?.pattern"
                    class="error text-danger"
                  >
                    Please enter valid phone number
                  </div>
                </div> -->
              </div>
            </div>
            <div class="py-4 col-lg-6 ">
              <h5 class="mem1 mb-0 pb-3 theme-color ">
                {{ 'SUBMENU.TEAMS' | translate }}
              </h5>
              <div class="inner-addon right-addon position-relative pt-2">
                <!-- <ngx-select
                    name="participants"
                    [allowClear]="true"
                    [multiple]="true"
                    [keepSelectedItems]="false"
                    [items]="teams"
                    optionValueField="teamId"
                    optionTextField="teamId"
                    formControlName="ngxTeamValue12"
                    placeholder="{{'SUBMENU.TEAMS' | translate}}"
                    (remove)="removeMemberAdd($event)"
					class="theme-select"> -->
                <ngx-select
                  name="participants"
                  [allowClear]="true"
                  [multiple]="true"
                  [keepSelectedItems]="false"
                  [items]="teams"
                  optionValueField="teamId"
                  optionTextField="name"
                  formControlName="ngxTeamValue"
                  placeholder="{{ 'SUBMENU.TEAMS' | translate }}"
                  class="theme-select team-select"
                  (remove)="removeMemberAdd($event)"
                  (select)="teamsAdd($event)"
                >
                  <ng-template
                    ngx-select-option
                    ngx-select-option-selected
                    let-option
                    let-text="text"
                    let-i = index
                  >
                  <!-- <h6 *ngIf="i==0" class="selectedCls"> Selected Teams</h6> -->
                  <!--  -->
                    <div class="d-flex dropdown-custom align-items-center flex-wrap w-100">
                      <div class="d-flex flex-wrap col-md-12 selectedCls-cs px-0" *ngIf="i==0" >
                        <h6 class="col-md-6 pl-2 default-color">Name</h6>
                        <h6 class="col-md-6 default-color">Role</h6>
                        <h5></h5>
                      </div>
                      <div class="d-flex col-md-12 px-0">
                        <div class="pl-2 col-md-6">
                          <!-- <p [innerHtml]="text" class="mb-0"></p> -->
                          <div class="text-gray1 optionClr">
                            {{ option.data.name }}
                          </div>
                        </div>
                        <div class="col-md-6 selectedCls-cs">
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" [checked]="checkManagerOrNot(option.data.teamId)" (click)="makeManager($event,option.data.teamId,i)"  value="Manager" name="optradio{{i}}">Manager
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" [checked]="!checkManagerOrNot(option.data.teamId)" (click)="makeManager($event,option.data.teamId,i)"  value="Member" name="optradio{{i}}">Member
                            </label>
                          </div>
                        </div>
                      </div>
                     
                    
                    </div>
                  </ng-template>

                  <ng-template ngx-select-option-not-found let-input>
                    {{ 'FORM.NOT_FOUND' | translate: { input: input } }}
                  </ng-template>
                </ngx-select>

                <!-- <ngx-select
					name="participants"
              [allowClear]="true"
              [multiple]="true"
              [keepSelectedItems]="false"
              [items]="teams"
              optionValueField="value"
              optionTextField="name"
              [disabled]="ngxDisabled"
			  formControlName="ngxTeamValue12"

              placeholder="{{ 'TEAMSPAGE.PARTICIPANTS' | translate}}"
              class="theme-select">
                    <ng-template
                      ngx-select-option
                      ngx-select-option-selected
                      let-option
                      let-text="text">
                      <div class="d-flex text-left dropdown-custom">
                        <div>
							{{option | json}}
                          <p [innerHtml]="text" class="mb-0" style="color:black;"></p>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ngx-select-option-not-found let-input>
                      "{{ input }}" not found
                    </ng-template>
                  </ngx-select> -->
                <img
                  src="assets/icon_search.svg"
                  alt="Plus"
                  class="position-absolute searchIcon"
                />
              </div>
              <p class="mems py-4">
                <a
                class="theme-btn1"
                  (click)="addMoreTeams()"
                  style="text-decoration:underline;"
                  >{{ 'CREATE_MEMBER.ADD_MORE_TEAMS' | translate }}</a
                >
               
              </p>
            </div>

            <div class="py-4 col-lg-6 " *ngIf="isAdmin || (permissions && permissions.company.create_admin_manager_member == 'true')">
              <h5 class="mem1 mb-0 pb-3 theme-color ">
                Select user role
              </h5>
              <div class="">
                <select  class="theme-select form-control pb-0 " formControlName="role" name="role" id="role"  style="padding-left:2px !important;">
                  <option value="ADMIN" >Admin</option>
                  <!-- <option value="MANAGER">Manager</option> -->
                  <option value="MEMBER">Member</option>
                  <option value="SALES">Sales</option>
               </select>
               <div *ngIf="submitted1 && f.role.errors" class="text-danger">
                <div *ngIf="f.role.errors.required">Role required.</div>
               
              </div>
              </div>
              
            </div>
            <div class="py-4 col-md-6 d-md-none" style="align-self:flex-end;">
              <p class="mems py-4 text-right">
                  <!-- <a
                  class="theme-btn"  [routerLink]="['/official-profile', userId]"
                  >{{ 'MESSAGES.VIEW_OFFICIAL_PROFILE' | translate }}</a
                > -->
              </p>
            </div>
          </div>
          <div class="py-2 text-center col-md-12">
            <input
              type="submit"
              value="Save "
              class="theme-btn"
              *ngIf="!userId"
            />
            <input
            type="submit"
            value="Save Changes"
            class="theme-btn"
            *ngIf="userId"
          />
          </div>
          
          <div class="py-2 text-center col-md-12 text-gray">
            <a [routerLink]="['/members']">{{
              'PROFILE.CANCEL' | translate
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<app-create-new-label *ngIf="isOpen"></app-create-new-label>
