import { Component, OnInit,OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
import { accountInfo, performanceAnalyticsQuery } from '../../../services/user.gql'
import {
  contactsQuery,
} from '../../../services/contacts.gql'
import { HelperService } from '../../../services/helper.service'
import {  Subject } from 'rxjs';

import {
  membersBasicQuery2,
} from '../../../services/admin.gql'
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

const moment = require('moment-timezone')

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
 // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
   dtTrigger = new Subject<any>();
  public member: any={}
  contactsGraph: boolean= true
  reveneGraph: boolean
  meetings: any
  dailyPlanner: any
  meetingsSummary: any = {}
  meetingsSummaryTemp: any = []
  userId: number
  private query: QueryRef<any>
  private queryU: QueryRef<any>
  private queryRe: QueryRef<any>
  meetingId: number
  isOpen: boolean
  contacts: any =[]
  dailyContacts: any = []
  summaryContacts: any = []
  remindersData: any = []
  loading: boolean = false
  url: any = ''
  meetingsCount = 0
  contactsCount = 0
  todayDate:any = moment()
  config = {
    suppressScrollX: true
  }
  attendedGraph12:any= 1
  isRating:boolean = false
  query$:any
  queryPr$:any
  meetingQuery$:any
  contactsQuery$:any
  allMeetingQuery$:any
  performanceInfo:any
  members:any =[]


  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor (
    private apollo: Apollo,
    private router: Router,
    private helperService: HelperService,
    private calendar: NgbCalendar, public formatter: NgbDateParserFormatter
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.contactsGraph = true
    this.meetings = []
    this.dailyPlanner = []
    // this.meetingsSummary = []
    this.helperService.getAccountInforamtion()
  }

  ngOnInit () {
    this.dtOptions = {
      pageLength: 10
    };
    this.loading = true

    /** Get today reminders **/
    this.queryRe = this.apollo.watchQuery({
      query: contactsQuery,
      variables: {
        reminderDate: moment().format('YYYY-MM-DD')
      },
      fetchPolicy:"network-only",
      errorPolicy:"all"
    })
    this.queryRe.valueChanges.subscribe(({ data }: any) => {
      this.remindersData = data.contacts
    },error => {
      this.loading = false
    })
    /** Get today reminders **/

    this.performanceAnalytics()
    /** Account Info ***/
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      errorPolicy:"all",
    
      
    })
    this.query$ = this.query.valueChanges.subscribe((result):any => {
      // this.loading= false
      this.member  = {...this.member}

      this.member = {... this.member ,...result.data.accountInformation}

    },error => {
      this.loading = false
    })

    // /** Profile Info **/
    this.getMembersInfo()
    
  }

  onImageError (img) {
    img.src = 'assets/default.jpg'
  }
  returnZero() {
    return 0
  }
 
  showMeetingInfo(meeting){
    let {isConference,id} = meeting
    if(isConference)
    {
      this.router.navigate(['/conference-details',2, id])

    }
    else {
      this.router.navigate(['/conference-details',1, id])

    }
  }
  calculateHeight(itemValue, totalCount){
    return 85/(totalCount/itemValue);
  }
  performanceAnalytics(){
    this.loading
    let contactsArray:any =[]
    let meetingsArray:any = []
    this.query = this.apollo.watchQuery({
      query: performanceAnalyticsQuery,
      fetchPolicy:"network-only"
    })
    this.query.valueChanges.subscribe(result => {
      this.performanceInfo = result.data.performanceAnalytics;
      let tempInfo = [...this.performanceInfo];
      let tempData = [];
      tempInfo.map((obj,i)=>{
        if(tempData.length==0){
          tempData.push(obj);
        }
        else{
          let index = tempData.findIndex((temp)=> temp.companyName == obj.companyName)
          if(index==-1){
            tempData.push(obj);
          }
        }
      })
      this.performanceInfo = [...tempData];

      this.performanceInfo.forEach(element => {
        // this.barChartLabels.push(element.companyName)
        contactsArray.push(element.numberOfContacts)
        this.meetingsCount = this.meetingsCount + element.numberOfContacts
        this.contactsCount = this.contactsCount + element.numberofMeetings
        // meetingsArray.push(element.numberofMeetings)
        // meetingsArray.push(8)
      });

      
      this.loading = false
    },error => {
      this.loading = false
    })
  }

  getMembersInfo(){
     // querying the members list
     this.queryU = this.apollo.watchQuery({
      query: membersBasicQuery2,
      variables: {
        limit: 5,
        offset: 0,
        sortByDate: 'DESC'
      },
      fetchPolicy: 'network-only'
    })

    this.queryU.valueChanges.subscribe(({ data }: any) => {
      this.members = data.users
    },error=>{
      this.loading = false
    })
  }
  
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : "";
    this.queryPr$ ? this.queryPr$.unsubscribe() : "";
    this.meetingQuery$ ? this.meetingQuery$.unsubscribe() : "";
    this.contactsQuery$ ? this.contactsQuery$.unsubscribe() : "";
    this.allMeetingQuery$ ? this.allMeetingQuery$.unsubscribe() : "";
  }

}
