import { Component, OnInit,OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo, QueryRef } from 'apollo-angular'
// import { Observable } from 'rxjs'
// import { AppState, selectMemberAuth } from '../../store/app.states'
import { ToastrService } from 'ngx-toastr'
import { accountInfo, profilesQuery } from '../../services/user.gql'
import { publicProfileQuery } from '../../services/user.gql'

import { meetingsQuery, meetingsQueryOnlyIDS, } from '../../services/meeting.gql'
import {
  contactDetailsQuery,
  contactsQuery,
  contactsLessInfoQuery
} from '../../services/contacts.gql'
import { HelperService } from '../../services/helper.service'
import { TranslateService } from '@ngx-translate/core'
var moment = require('moment-timezone')



// const inviteOtherUsers = gql`
// mutation inviteOtherUsers ($userId: Int) {
//   inviteOtherUsers(userId:$userId)
// }
// `;
@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit,OnDestroy {
  public member: any={}
  attendedGraph: boolean= true
  reveneGraph: boolean
  meetings: any
  dailyPlanner: any
  meetingsSummary: any = {}
  meetingsSummaryTemp: any = []
  userId: number
  private query: QueryRef<any>
  private meetingQuery: QueryRef<any>
  private contactsQuery: QueryRef<any>
  private queryPr: QueryRef<any>
  private allMeetingQuery: QueryRef<any>
  private queryRe: QueryRef<any>
  private publicProfileQuery: QueryRef<any>
  
  meetingId: number
  isOpen: boolean
  contacts: any =[]
  attendedMeetings: any = []
  scheduleMeetings: any = []
  dailyContacts: any = []
  summaryContacts: any = []
  remindersData: any = []
  loading: boolean = false
  url: any = ''
  attendCount = 0
  scheduleCount = 0
  todayDate:any = moment()
  config = {
    suppressScrollX: true
  }
  attendedGraph12:any= 1
  isRating:boolean = false
  query$:any
  queryPr$:any
  meetingQuery$:any
  contactsQuery$:any
  allMeetingQuery$:any
  publicProfile:any = {}
  noPublicProfile:boolean = true
  
  constructor (
    private apollo: Apollo,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private helperService: HelperService
  ) {
    this.attendedGraph = true
    this.meetings = []
    this.dailyPlanner = []
    // this.meetingsSummary = []
    this.helperService.getAccountInforamtion()
  }

  ngOnInit () {
    this.loading = true

    /** Get today reminders **/
    this.queryRe = this.apollo.watchQuery({
      query: contactsQuery,
      variables: {
        reminderDate: moment().format('YYYY-MM-DD')
      },
      fetchPolicy:"network-only",
      errorPolicy:"all"
    })
    this.queryRe.valueChanges.subscribe(({ data }: any) => {
      this.remindersData = data.contacts
    },error => {
      this.loading = false
    })
    /** Get today reminders **/

    /** Get daily meetings */
    this.getMeetings(true, false)

    /***Get latest Meetings */
    this.getMeetings(false, true)
    /**Get latest Contacts */
    this.getContacts(true, 0, false)

    /** Get all meetings */
    this.getAllYearMeetings()
    this.getPublicprofile()
    /** Account Info ***/
    this.query = this.apollo.watchQuery({
      query: accountInfo,
      errorPolicy:"all",
      
    })
    this.query$ = this.query.valueChanges.subscribe((result):any => {
      // this.loading= false
      this.member  = {...this.member}

      this.member = {... this.member ,...result.data.accountInformation};
      this.getProfileInfo()

    },error => {
      this.loading = false
    })

    // /** Profile Info **/

    
  }
  getProfileInfo(){
    this.queryPr = this.apollo.watchQuery({
      query: profilesQuery,
      // fetchPolicy:"no-cache",
      fetchPolicy:"network-only",
      errorPolicy:"all",
    })

    this.queryPr$ = this.queryPr.valueChanges.subscribe(({ data }:any) => {
      if (data.profiles[0]) {
        this.url = data.profiles[0].displayImageURL;
        this.url += '?random+\=' + Math.random();
      }
      data.profiles[0] &&
        data.profiles[0].data.forEach((item, index) => {
          // this.member  = {...this.member}
          if (this.member) {
            if (item.format == 'JOB_TITLE') {
              this.member.title = item.value ? item.value : ''
            }
            if (item.format == 'DEPARTMENT') {
              this.member.department = item.value ? item.value : ''
            }
          }
          this.member.url= this.url

        })
      this.loading = false    
    },error => {
      this.loading = false
    })
  }
  onImageError (img) {
    img.src = 'assets/default.jpg'
  }
   returnZero() {
    return 0
    }
  getAllYearMeetings () {
    let afterDt =  moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD HH:mm')
    let beforeDt = moment().add(6, 'month').startOf('month').format('YYYY-MM-DD HH:mm')
    let meetingsObj = {
      after: moment.tz(afterDt, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      before:moment.tz(beforeDt, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
    this.loading = true
    this.allMeetingQuery = this.apollo.watchQuery({
      query: meetingsQueryOnlyIDS,
      variables: meetingsObj,
      fetchPolicy: 'network-only',
      errorPolicy:"all"
    })
    let monthObj1 :any= {}
    let monthObj2 :any= {}
    for (let i = 0; i < 6; i++) {
      monthObj2[
        moment()
          .add(i, 'month')
          .startOf('month')
          .format('MMM')
      ] = 0
    }
    
    var months = ["Sep", "Oct", "Nov", "Dec","Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug" ];

    for (let i = 5; i >0; i--) {
      monthObj1[
        moment()
          .subtract(i, 'month')
          .startOf('month')
          .format('MMM')
      ] = 0
    }
  // let new_obj:any= {}
  //   let rev_obj:any = Object.keys(monthObj1).reverse();
  //   rev_obj.forEach(function(i) { 
  //     new_obj[i] = monthObj1[i];
  //   })
    this.allMeetingQuery$ = this.allMeetingQuery.valueChanges.subscribe(({ data }: any) => {
      if (data.events && data.events.length > 0) {
        var [m1, m2] = data.events.reduce(
          (acc, elem) => {
            if (moment(elem.startTime).format() <= moment().format()) {
              if (moment(elem.startTime).format('MMM') in acc[0]) {
                acc[0][moment(elem.startTime).format('MMM')]++
              } else {
                acc[0][moment(elem.startTime).format('MMM')] = 1
              }
              this.attendCount = this.attendCount + 1
            } else {
              if (moment(elem.startTime).format('MMM') in acc[1]) {
                acc[1][moment(elem.startTime).format('MMM')]++
              } else {
                acc[1][moment(elem.startTime).format('MMM')] = 1
              }
              this.scheduleCount = this.scheduleCount + 1
            }
            return acc
          },
          [monthObj1, monthObj2]
        )

        // var key = Object.keys(monthObj1) 
        //     .sort(function order(key1, key2) { 
        //         if (key1 < key2) return -1; 
        //         else if (key1 > key2) return +1; 
        //         else return 0; 
        //     }); 

       var new_obj1 = {};
      //  var new_obj2 = {};

        Object.keys(monthObj1)
        .sort(function(a, b) {
            return months.indexOf(a) - months.indexOf(b);
        })
        .forEach(function(key) {
          new_obj1[key] = monthObj1[key];
        });

        monthObj1 = new_obj1;


        // Object.keys(monthObj2)
        // .sort(function(a, b) {
        //     return months.indexOf(a) - months.indexOf(b);
        // })
        // .forEach(function(key) {
        //   new_obj2[key] = monthObj2[key];
        // });

      //   this.dailyPlanner = monthObj1.data.events.sort((a,b)=>{
      //     a = new Date(a.startTime)
      //     b = new Date(b.startTime)
      //    return b - a
      //  })
        // monthObj2 = new_obj2;
        setTimeout(()=>{
          this.attendedMeetings = monthObj1
          this.scheduleMeetings = monthObj2
        },100)
       
      }
      // this.loading= false
    })
  }
  getMeetings (isDaily, latest) {
    this.loading = true
    let meetingsObj
    let beforeDt = moment().startOf('day').format('YYYY-MM-DD HH:mm')
    meetingsObj = {
      offset: 0,
      //sortByTime:true,
      descending:true,
      after:moment.tz(beforeDt, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }
    if (isDaily) {
      // Daily meeting info
      let afterDt =  moment().startOf('day').format('YYYY-MM-DD HH:mm')
      let beforeDt = moment().endOf('day').format('YYYY-MM-DD HH:mm')
      meetingsObj = {
        after: moment.tz(afterDt, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        before:  moment.tz(beforeDt, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        //sortByTime:true,
        v: Math.random(),
      }
    }
    this.meetingQuery = this.apollo.watchQuery({
      query: meetingsQueryOnlyIDS,
      variables: meetingsObj,
      fetchPolicy: 'network-only',
      errorPolicy:"all"
    })
    this.meetingQuery$ = this.meetingQuery.valueChanges.subscribe(result => {
      // this.loading= false
      if (result.data.events) {
        this.meetings = result.data.events
        if (isDaily) {

          // this.dailyPlanner = result.data.events
          this.dailyPlanner =result.data.events.sort((a,b)=>{
             a = new Date(a.startTime)
             b = new Date(b.startTime)
            return b - a
          })

          // return false
        }

        if (!isDaily && latest) {
          this.meetingsSummaryTemp = result.data.events
        }
        let userIds1: any = []
        result.data.events.forEach((item, i) => {
          item.participants.forEach((contact, i) => {
            if (contact && contact.id) {
              //id
              userIds1.push(contact.id)
            }
          })
        })
        if (userIds1.length > 0) {
          this.getContacts(false, userIds1, isDaily)
        } else {
          this.getMeetingSummary([])
        }
      }
    },error => {
      this.loading = false
    })
  }

  getLatestContacts () {}
  getContacts (latest, userIds: any = 0, isDaily) {
    let contactObj
    if (latest) {
      contactObj = {
        limit: 3,
        offset: 0
      }
    } else {
      contactObj = {
        userIdOfContact: userIds
      }
    }
    this.loading = true
    this.contactsQuery = this.apollo.watchQuery({
      query: contactsLessInfoQuery,
      variables: contactObj,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    })
    this.contactsQuery$ = this.contactsQuery.valueChanges.subscribe(result => {
      this.loading = false
      if (result.data.contacts) {
        let contastList = result.data.contacts
        if (contastList.length > 0) {
        }
        if (!isDaily && latest) {
          this.contacts = contastList
        } else if (isDaily && !latest) {
          this.dailyContacts = contastList
          this.dailyPlanner.forEach((item, i) => {
            item.participants.forEach((contact1, j) => {
              if (contact1 && contact1.id) {
                let contactInfo = this.dailyContacts.find(
                  x => x.userIdOfContact == contact1.id
                )
                  if(contactInfo)
                  {
                    if (!item.formatedParticipants) {
                      item.formatedParticipants = []
                    }
                    item.formatedParticipants[j] = contactInfo
                  }
                  
              }

              // acc[1][meetingDate][i].push(contact.id)
            })
            this.dailyPlanner[i].item = item
          })
          this.dailyContacts = this.contacts
        } else if (!isDaily && !latest) {
          this.getMeetingSummary(contastList)
          // this.summaryContacts = this.contacts
        }
      }
    },error => {
      this.loading = false
    })
  }
  filterByRating(isFilter=false){
    this.loading = true
    this.isRating =isFilter
    let contactObj
    if(isFilter)
    {
      contactObj= {
        limit:3,
        orderByRating:"DESC"
      }
    }
    else {
      contactObj= {
        limit:3
      }
    }
    this.contactsQuery = this.apollo.watchQuery({
      query: contactsLessInfoQuery,
      variables: contactObj,
      errorPolicy: 'all',
      fetchPolicy: 'network-only'
    })
    this.contactsQuery$ = this.contactsQuery.valueChanges.subscribe(result => {
      this.loading = false

      // this.contacts = result.data.contacts

    },error => {
      this.loading = false
    })
  }
  getMeetingSummary1 (contastList) {
    var newarray = this.meetingsSummaryTemp.reduce((acc, item) => {
      let meetingDate = moment(item.startTime).format('DD-MM-YYYY')
      if (acc[meetingDate]) {
        // acc[meetingDate].push(item)
      } else {
        acc[meetingDate] = []
        // acc[meetingDate].push(item)
      }
      item.participants.forEach((contact1, j) => {
        acc[meetingDate]
        let contactInfo = contastList.find(
          x => x.userIdOfContact == contact1.id
        )
        if (!item.formatedParticipants) {
          item.formatedParticipants = []
        }
        item.formatedParticipants[j] = contactInfo
        if (contactInfo) {
          if (acc[meetingDate][contactInfo.company]) {
          } else {
            acc[meetingDate][contactInfo.company] = []
          }
          if (
            !acc[meetingDate][contactInfo.company].some(
              x => x.id == contactInfo.id
            )
          ) {
            acc[meetingDate][contactInfo.company].push(contactInfo)
          }
          // acc[meetingDate][contactInfo.company].push(contactInfo)
        }
      })
      return acc
    }, [])
    // this.meetingsSummary = newarray
   
  }
  getMeetingSummary (contastList) {
    // this.meetingsSummaryTemp =this.meetingsSummaryTemp.sort(function(a,b){return b.startTime.getTime() - a.startTime.getTime()})
    this.meetingsSummaryTemp = this.meetingsSummaryTemp.sort((a,b)=>{
      a = new Date(a.startTime)
      b = new Date(b.startTime)
     return a - b
   })
    var newarray = this.meetingsSummaryTemp.reduce((acc, item) => {
      let meetingDate = moment(item.startTime).format('DD-MM-YYYY')
      if (acc[meetingDate]) {
      } else {
        acc[meetingDate] = []
      }
      

      item.participants.forEach((contact1, j) => {
        // acc[meetingDate]
        let contactInfo = contastList.find(
          x => x.userIdOfContact == contact1.id
        )
        item.participants[j].company = contactInfo && contactInfo.company ? contactInfo.company : ''
        item.participants[j].department = contactInfo && contactInfo.department ? contactInfo.department : ''
        item.participants[j].title = contactInfo && contactInfo.title ? contactInfo.title : ''
      })
      
      acc[meetingDate].push(item)
      return acc
    }, [])
    this.meetingsSummary = newarray
  }
  showMeetingInfo(meeting){
    let {isConference,id} = meeting
    if(isConference)
    {
      this.router.navigate(['/conference-details',2, id])

    }
    else {
      this.router.navigate(['/conference-details',1, id])

    }
  }
  calculateHeight(itemValue, totalCount){
    return 85/(totalCount/itemValue);
  }
  getPublicprofile(){
    this.publicProfileQuery =  this.apollo.watchQuery({
      query: publicProfileQuery,
      fetchPolicy:'network-only'
    })
     this.publicProfileQuery.valueChanges.subscribe(result => {
       this.noPublicProfile = true
      if (result.data.publicProfile) {
		this.publicProfile = result.data.publicProfile
        this.noPublicProfile = false
      }
    },error => {
      this.loading = false
    })
  }
    ngOnDestroy(){
    this.query$ ? this.query$.unsubscribe() : "";
    this.queryPr$ ? this.queryPr$.unsubscribe() : "";
    this.meetingQuery$ ? this.meetingQuery$.unsubscribe() : "";
    this.contactsQuery$ ? this.contactsQuery$.unsubscribe() : "";
    this.allMeetingQuery$ ? this.allMeetingQuery$.unsubscribe() : "";
  }
}
