import { createReducer, on } from '@ngrx/store';
import { ProfileActionTypes,All } from '../actions/profile.actions';

export const initialState: any = {
  profilePic: null,
 
};


export function reducer(state = initialState, action: All): any {
  switch (action.type) {
    case ProfileActionTypes.PROFILE_UPDATE: {
      return {
        ...state,
        profilePic:action.payload
      }
    }
   
    default: {
      return state;
    }
  }
}
